import { Page } from '@frontend/components/misc/layout';
import TopBar from '@frontend/components/misc/top-bar/TopBar';
import { RetrieveUser } from '@frontend/services/users'
import React, { useEffect, useState } from 'react'
import { IUser } from '@common.abstractions'
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { int } from '@common.abstractions';
import { toast } from 'react-toastify';
import { cleanPhoneNumber } from '@common.tools';
import Button from '@frontend/components/misc/button/Button';
import { useNavigate } from 'react-router-dom-v5-compat';

export default function UpdateUser() {
    const navigate= useNavigate()
    const [user, setUser] = useState<IUser>();
    const params = useParams<{ userId: string }>();
    const [userId] = useState<int>(int.parse(params.userId));
    const {
        handleSubmit,
        register,

        formState: {
            errors,
        },
    } = useForm();

    const loadUser = async () => {
        const response = await RetrieveUser(userId);

        if (!response) {
            toast.error('Could not load user');
            return;
        }

        setUser(response);
    }
    
    const editUser = (args: any) => alert("Not currently supported") 

    // const editUser = async (values: any) => {

    //     const phoneNumber = cleanPhoneNumber(values.phone);
    //     if (!phoneNumber) {
    //         toast.error('Phone number Invalid')
    //         return;
    //     }

    //     const userParams: IUpdateUserParams = {
    //         firstname: values.firstName,
    //         lastname: values.lastName,
    //         phone: phoneNumber
    //     }

    //     const editUserResponse = await UpdateCompanyUser(userId, userParams);

    //     if (!editUserResponse) {
    //         toast.error('Something went wrong');
    //         return;
    //     }
    //     toast.success('User Saved');
    //     navigate(-1);
    // }

    useEffect(() => {
        loadUser();
    }, []);

    return (
        <Page>
            <TopBar title={user ? `${user.firstname} ${user.lastname}` : "User"}></TopBar>
            <form onSubmit={handleSubmit(editUser)} className='pb-40'>
                <div className='form_slide'>
                    <h2 className='text-gray-500 text-2xl mb-6'>User Information</h2>
                    <div className='form_group'>
                        <div>
                            <label>First Name</label>
                            <input
                                className={`form-control ${errors.firstName && "form_outline_error"}`}
                                type='text'
                                placeholder='First name'
                                defaultValue={user?.firstname}
                                {...register('firstName', { required: 'Required' })} />
                            {errors.firstName && <div className='form_input_error'>{errors.firstName.message?.toString()}</div>}
                        </div>
                        <div>
                            <label>Last Name</label>
                            <input
                                className={`form-control ${errors.lastName && "form_outline_error"}`}
                                type='text'
                                placeholder='Last name'
                                defaultValue={user?.lastname}
                                {...register('lastName', { required: 'Required' })} />
                            {errors.lastName && <div className='form_input_error'>{errors.lastName.message?.toString()}</div>}
                        </div>
                    </div>
                    {/* <div className='form_group'>
                        <label>Phone Number</label>
                        <input
                            className={`form-control ${errors.phone && "form_outline_error"}`}
                            type="tel"
                            placeholder="Company Phone Number"
                            name="phone"
                            defaultValue={user?.phone}
                            ref={register({
                                required: "Required",
                                validate: (val) => cleanPhoneNumber(val) !== undefined,
                            })}
                        />
                        {errors.phone && errors.phone.type === "required" && <div className="form_input_error">Required</div>}
                        {errors.phone && errors.phone.type === "validate" && <div className="form_input_error">Phone number is invalid</div>}
                    </div> */}
                </div>

                <Button className="w-96 mt-16" style="solid" type="submit" color="blue">Save Changes</Button>


            </form>

        </Page>
    );
}
