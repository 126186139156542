import { useParams } from "react-router-dom";
import { CreateUser } from "../../../services/users";
import { toast } from "react-toastify";
import { int, IUser } from "@common.abstractions";
import { useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

export default function CreateCompanyUser() {
    const navigate= useNavigate();
    const params = useParams<{ companyId: string }>();
    const [companyId] = useState<int>(int.parse(params.companyId))
    const [email, setEmail] = useState<string>("");

    interface ILooseObject {
        [key: string]: any;
    }
    const [formError, setFormError] = useState<ILooseObject>({ error: {} });

    const handleEmailChange = (value: IUser["email"]) => {
        let tempFormError = { ...formError };

        if (value) {
            if (tempFormError.error) {
                delete tempFormError.error.email;
                if (Object.keys(tempFormError.error).length === 0) {
                    delete tempFormError.error;
                }
            }
        } else {
            tempFormError.error
                ? (tempFormError.error.email = "required")
                : (tempFormError = {
                      error: { email: "required" },
                  });
        }

        setFormError(tempFormError);
        setEmail(value);
    };

    const validateForm = () => {
        let tempFormError: ILooseObject = {};
        
        if (email.length === 0) {
            tempFormError.error ? (tempFormError.error.email = "required") : (tempFormError = { error: { error: "required" } });
        }
        setFormError(tempFormError);

        if (tempFormError.error) return false; //theres an error
        return true; //no errors
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            // const newUser: ICreateUserParams = { firstname, lastname, email, phone, grants: [
            //     Grant.Lead.READ,
            //     Grant.Lead.CREATE,
            //     Grant.Lead.UPDATE,
            //     Grant.Report.READ,
            //     Grant.Order.READ,
            //     Grant.Order.CREATE,
            //     Grant.Order.UPDATE,
            //     Grant.Company.READ,
            //     Grant.Company.UPDATE,
            //     Grant.User.READ,
            //     Grant.Product.READ,
            //     Grant.QuoteRequest.READ,
            //     Grant.Contact.READ
            // ] };

            const newUserRes = await CreateUser(email, companyId);

            if (newUserRes) {
                toast("User Created");

                setTimeout(() => {
                    navigate(-1);
                }, 2000)
                return;
            }

            toast.error("Error creating user");
        }
    };

    return (
        <div className="page">
            <div className="top_bar">
                <div className="left">
                    <h1 className="title">Create User</h1>
                </div>
                <div className="right">
                    <div className="button primary" onClick={handleSubmit}>Save</div>
                </div>
            </div>
            <div className="flex-column"></div>
            <form>
                {/* User Info */}
                
                <div className="form_group">
                    <div>
                        <label>EverLights Account Email (From EverLights App)</label>
                        <input
                            onChange={(e) => handleEmailChange(e.target.value)}
                            value={email}
                            placeholder="required"
                            className={formError.error && formError.error.email ? "form_outline_error" : ""}
                        />
                        {formError.error && formError.error.email ? <div className="form_input_error">{formError.error.email}</div> : null}
                    </div>
                </div>
            </form>
        </div>
    );
}
