import React from 'react'

type daysSinceLastSaleProps = {
    data: {
        companyName: string;
        days: number;
    }[] | undefined,
}

export default function DaysSinceLastSale(props: daysSinceLastSaleProps) {
    return (
        <div className="p-5 shadow-md hover:shadow-lg duration-theme h-auto opacity-70 hover:opacity-100" >
            <h2 className="text-gray-500 mb-3">Days Since Last Sale </h2>
            {props.data?.map((el, i) => (
                <div key={i} className={`flex flex-col border-gray-200 border-b`}>
                    <div className="flex justify-between py-2">
                        <div className="text-gray-600">{el.companyName}</div>
                        <div className="flex">
                            <div className="inline-flex items-center w-14">
                                <span className="ml-1 text-gray-500">{el.days}</span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div >
    )
}
