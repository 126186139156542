import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { RetrieveLeads, RetrieveLeadsCount } from "../../../services/leads";

import AuthContext from "../../../contexts/AuthContext";
import { Loading } from "@frontend/components/misc/loading";
import { Scope, ILead, int, IQuoteRequest } from "@common.abstractions";
import { formatBuildingType, formatInstallationType, formatProductLine, getGoogleMapsUrl } from "@common.tools";
import { Equals, Exists, Not } from "@common.api";
import { TableHeader, TableRow, TableRowWrapper, TableTabBar } from "@frontend/components/misc/table";
import { Page } from "@frontend/components/misc/layout/Page";
import InfiniteScroll from "react-infinite-scroll-component";
import Button from "@frontend/components/misc/button/Button";

enum Tab {
    Unopened = 'Unopened',
    Opened = 'Opened',
    Expired = 'Deleted',
    Purchased = 'Purchased',
    All = 'Total',
}

export default function CompanyLeads(props: { companyId: int; searchCriteria: string }) {
    const authContext = React.useContext(AuthContext);
    const { companyId, searchCriteria } = props;

    const [displayLeads, setDisplayLeads] = React.useState<ILead[]>();
    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.All);
    const allLeads = React.useRef<ILead[]>();
    const unopenedLeads = React.useRef<ILead[]>();
    const openedLeads = React.useRef<ILead[]>();
    const closedLeads = React.useRef<ILead[]>();
    const lostLeads = React.useRef<ILead[]>();

    const [unopendCount, setUnopendCount] = React.useState<number>();
    const [viewedCount, setViewedCount] = React.useState<number>();
    const [closedCount, setClosedCount] = React.useState<number>();
    const [allCount, setAllCount] = React.useState<number>();
    const [loadingLeads, setLoadingLeads] = useState<boolean>(false);

    const cursor = useRef<string>();

    const loadLeads = async (num: number) => {
        setLoadingLeads(true);
        console.log(`Loading ${num} more leads`)

        if (allLeads.current?.length && !cursor.current) {
            console.log("no more leads")
            return;
        } // We've loaded all of them.

        let tempLeads = await RetrieveLeads({
            filters: {
                company: { id: Equals(companyId) },
            },
            relations: ["quoteRequest", "quoteRequest.contact", 'orders']
        },
            {
                direction: 'forward',
                count: num,
                cursor: cursor.current
            });

        if (!tempLeads) {
            console.warn("No response from load leads call");
            setLoadingLeads(false);
            return;
        }

        if (!tempLeads.pagination) {
            console.error("Response missing pagination information");
            return;
        }

        cursor.current = tempLeads.pagination.end_cursor;

        const retrievedLeads = tempLeads.values

        retrievedLeads.sort((a, b) => {
            const varA = new Date(a.createdAt).getTime();
            const varB = new Date(b.createdAt).getTime();
            return varB - varA;
        });

        const all: ILead[] = [...(allLeads.current ?? []), ...retrievedLeads]; // may duplicate rows in rare instances. TODO: Fix this eventually
        const unopened: ILead[] = unopenedLeads.current ?? [];
        const opened: ILead[] = openedLeads.current ?? [];
        const closed: ILead[] = closedLeads.current ?? [];
        const lost: ILead[] = lostLeads.current ?? [];

        retrievedLeads.forEach((lead) => {
            if (!lead.viewedDate) {
                unopened.push(lead);
            }
            if (lead.vConverted) {
                closed.push(lead);
            }
            if (lead.manualMarkLostDate) {
                lost.push(lead);
            }
            if (lead.viewedDate && !lead.manualMarkLostDate && !lead.vConverted) {
                opened.push(lead);
            }
        });

        allLeads.current = all;
        unopenedLeads.current = unopened;
        openedLeads.current = opened;
        closedLeads.current = closed;
        lostLeads.current = lost;

        setDisplayedLeadsFromTab(selectedTab);
        setLoadingLeads(false);
    };

    const loadCounts = async () => {
        const unopend = await RetrieveLeadsCount({
            viewedDate: Not(Exists()),
            company: {
                id: Equals(companyId)
            }

        });

        const viewed = await RetrieveLeadsCount({
            viewedDate: Exists(),
            vConverted: Not(Equals(true)),
            manualMarkLostDate: Not(Exists()),
            company: {
                id: Equals(companyId)
            }

        });

        const lost = await RetrieveLeadsCount({
            manualMarkLostDate: Exists(),
            company: {
                id: Equals(companyId)
            }

        });
        const closed = await RetrieveLeadsCount({
            vConverted: Equals(true),
            company: {
                id: Equals(companyId)
            }

        });

        const all = await RetrieveLeadsCount({
            company: {
                id: Equals(companyId)
            }
        });

        setAllCount(all);
        setClosedCount(closed);
        setViewedCount(viewed);
        setUnopendCount(unopend);
    };

    const setDisplayedLeadsFromTab = (tab: Tab) => {
        switch (tab) {
            case Tab.All:
                setDisplayLeads(allLeads.current);
                break;
            case Tab.Unopened:
                setDisplayLeads(unopenedLeads.current);
                break;
            case Tab.Opened:
                setDisplayLeads(openedLeads.current);
                break;
            case Tab.Purchased:
                setDisplayLeads(closedLeads.current);
                break;
        }
    };

    const getLeadStatus = (lead: ILead) => {
        if (lead.vConverted) {
            const recentPurchase = lead.orders![0];
            if (recentPurchase.invoiceProcessedDate) {
                const purchasedDate = new Date(recentPurchase.invoiceProcessedDate).toLocaleDateString();

                return `Converted (${purchasedDate})`
            }
            return 'Converted'
        }
        if (lead.deletedAt) return "Expired";
        if (!lead.viewedDate) return "Unopened";
        if (!((lead.deletedAt || !lead.vConverted) && lead.orders?.length)) return "In Progress";
        if (lead.viewedDate) return "Opened";
        return "Unknown";
    };

    const searchFilter = (entity: ILead) => {
        const s = searchCriteria.toLowerCase();
        const address = (entity.quoteRequest as IQuoteRequest).address;
        const contact = (entity.quoteRequest as IQuoteRequest).contact;
        if (
            searchCriteria === "" ||
            contact?.email.toLowerCase().includes(s) ||
            contact?.phone.toLowerCase().includes(s) ||
            contact?.firstname.toLowerCase().includes(s) ||
            contact?.lastname.toLowerCase().includes(s) ||
            (contact?.firstname.toLowerCase() + " " + contact?.lastname.toLowerCase()).includes(s) ||
            address.city.toLowerCase().includes(s) ||
            address.zip.toLowerCase().includes(s) ||
            address.streetAddress.toLowerCase().includes(s) ||
            address.state.toLowerCase().includes(s)
        ) {
            return true;
        }
        return false;
    };

    const setLeadSource = (lead: ILead) => {
        if (lead.quoteRequest?.origin === 'dealer_manual_entry') {
            return 'Manual Entry'
        }
        return 'Everlights'
    }

    useEffect(() => {
        cursor.current = undefined;
        loadLeads(20);
        loadCounts();
    }, []);

    useEffect(() => {
        setDisplayedLeadsFromTab(selectedTab);
    }, [selectedTab]);

    return (
        <Page>
            <TableTabBar
                tabOptions={{
                    tabs: [
                        { name: Tab.All, quantity: allCount },
                        { name: Tab.Unopened, quantity: unopendCount },
                        { name: Tab.Opened, quantity: viewedCount },
                        { name: Tab.Purchased, quantity: closedCount },
                        // { name: Tab.Expired, quantity: expiredCount },
                    ],
                    active: selectedTab,
                    onClick: setSelectedTab
                }}
            />

            <div
                id="table-scroll-div"
                className={`h-full w-full max-w-full overflow-auto relative`}
            >
                <InfiniteScroll
                    dataLength={displayLeads?.length ?? 0} //This is important field to render the next data
                    next={() => loadLeads(20)}
                    hasMore={!!cursor.current}
                    loader={
                        <p style={{ textAlign: "center" }}>
                            {loadingLeads
                                ? <b>Loading more leads...</b>
                                : <Button
                                    className="mx-auto my-8"
                                    type="button"
                                    color="blue"
                                    style="outline"
                                    rounded
                                    onClick={() => loadLeads(20)}
                                >
                                    Load More
                                </Button>
                            }
                        </p>
                    }
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            <b>No more leads</b>
                        </p>
                    }
                    scrollThreshold={"80px"}
                    scrollableTarget="table-scroll-div"
                >
                    <table className="text-left w-full relative border-collapse flex-grow">
                        <TableHeader
                            data={[
                                { title: 'Source' },
                                { title: 'Assigned' },
                                { title: 'Status' },
                                { title: 'Contact' },
                                { title: 'Address' },
                                { title: 'Building' },
                                { title: 'Install' },
                                { title: 'Products' }
                            ]}
                        />
                        <TableRowWrapper>
                            {displayLeads?.length
                                ? displayLeads.map(
                                    (lead, i) =>
                                        searchFilter(lead) && (
                                            <TableRow
                                                key={i}
                                                data={[
                                                    { text: { title: setLeadSource(lead) } },
                                                    { text: { title: new Date(lead.createdAt).toLocaleDateString() } },
                                                    { text: { title: getLeadStatus(lead) } },
                                                    {
                                                        text: {
                                                            title: authContext?.hasAnyGrant(Scope.Contact.READ_ANY) ? (
                                                                <Link className="link" to={`/contacts/${lead.quoteRequest?.contact?.id}`}>
                                                                    {`${lead.quoteRequest?.contact?.firstname} ${lead.quoteRequest?.contact?.lastname}`}
                                                                </Link>
                                                            ) : (
                                                                <div>{`${lead.quoteRequest?.contact?.firstname} ${lead.quoteRequest?.contact?.lastname}`}</div>
                                                            )
                                                        }
                                                    },
                                                    {
                                                        text: {
                                                            title: <a className="link" target="_blank" href={getGoogleMapsUrl(lead!.quoteRequest!.address)} >
                                                                {lead.quoteRequest?.address.streetAddress}, {lead.quoteRequest?.address.city}, {lead.quoteRequest?.address.state}
                                                            </a>
                                                        }
                                                    },
                                                    {
                                                        text: { title: lead.quoteRequest?.buildingType ? formatBuildingType(lead.quoteRequest?.buildingType) : "" }
                                                    },
                                                    {
                                                        text: { title: lead.quoteRequest?.installationType ? formatInstallationType(lead.quoteRequest?.installationType) : "" }
                                                    },
                                                    { text: { title: lead.quoteRequest?.productLines ? lead.quoteRequest?.productLines.map((pl) => formatProductLine(pl)).join(", ") : "" } },
                                                    {
                                                        text: {
                                                            title: <Link to={`/quoteRequests/${lead.quoteRequest?.id}`}> View Quote </Link>,
                                                            color: 'blue'
                                                        }
                                                    }
                                                ]}
                                            />
                                        )
                                )
                                : <></>
                            }
                        </TableRowWrapper>
                    </table>
                </InfiniteScroll>
            </div>

            {displayLeads ? (
                displayLeads.length ? null : (
                    <div className="text-gray-500 inset-center top-72">No leads</div>
                )
            ) : (
                <Loading center />
            )}

        </Page>
    );
}