import { MarkImageDeleted } from "@frontend/services/upload-warranty-image";
import React from "react";
import { useEffect, useState } from "react";
import { IFileUploader } from "../../../lib/uploader";

export const ImageUpload = (props: { fileUploader: IFileUploader, file: File }) => {
    const fileUploader = props.fileUploader;
    const file = props.file;
    const [started, setStarted] = useState(fileUploader.started);
    const [uploadPercent, setUploadPercent] = useState(fileUploader.uploadPercent);
    const [completed, setCompleted] = useState(fileUploader.completed);
    const [cancelled, setCancelled] = useState(fileUploader.cancelled);
    const [failed, setFailed] = useState(fileUploader.failed);

    useEffect(() => {
        fileUploader.addOnStartedListener(() => setStarted(true));
        fileUploader.addOnProgressListener(percent => setUploadPercent(percent));
        fileUploader.addOnCompletedListener(() => setCompleted(true));
        fileUploader.addOnCancelledListener(() => setCancelled(true));
        fileUploader.addOnFailListener(() => setFailed(true));
    }, [])

    if (cancelled) return null;

    if (completed) return (
        <div className="d-inline-block relative bg-light border rounded border-success m-2 m-0 bg-dark text-center" style={{ width: '10rem', height: '10rem', borderWidth: 'thick' }}>
            <img className="" style={{ width: '100%', height: '100%', objectFit: 'scale-down' }} src={URL.createObjectURL(file)} />
            <div
                className="btn py-0 px-2 text-white"
                style={{ position: 'absolute', top: ".2rem", right: ".2rem", backgroundColor: "rgba(255, 0, 0, 0.7)" }}
                onClick={() => {
                    fileUploader.cancel()
                    MarkImageDeleted(fileUploader.id)
                }}
            >X</div>
        </div>
    );
    if (failed) return (
        <div className="d-inline-block relative bg-light border rounded border-danger m-2 m-0 bg-dark text-center" style={{ width: '10rem', height: '10rem', borderWidth: 'thick' }}>
            <img className="" style={{ filter: 'brightness(40%)', width: '100%', height: '100%', objectFit: 'scale-down' }} src={URL.createObjectURL(file)} />
            <span className="d-block text-white" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Failed</span>
            <div
                className="btn py-0 px-2 text-white"
                style={{ position: 'absolute', top: ".2rem", right: ".2rem", backgroundColor: "rgba(255, 0, 0, 0.7)" }}
                onClick={() => fileUploader.cancel()}
            >X</div>
        </div>
    );
    if (!started) return (
        <div className="d-inline-block relative bg-light border rounded border-secondary m-2 m-0 bg-dark text-center" style={{ width: '10rem', height: '10rem', borderWidth: 'thick' }}>
            <img className="" style={{ filter: 'brightness(40%)', width: '100%', height: '100%', objectFit: 'scale-down' }} src={URL.createObjectURL(file)} />
            <span className="d-block text-white" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Pending...</span>
            <div
                className="btn py-0 px-2 text-white"
                style={{ position: 'absolute', top: ".2rem", right: ".2rem", backgroundColor: "rgba(255, 0, 0, 0.7)" }}
                onClick={() => fileUploader.cancel()}
            >X</div>
        </div>
    );

    return (
        <div className="d-inline-block relative bg-light border rounded border-primary m-2 m-0 bg-dark text-center" style={{ width: '10rem', height: '10rem', borderWidth: 'thick' }}>
            <img className="" style={{ filter: 'brightness(40%)', width: '100%', height: '100%', objectFit: 'scale-down' }} src={URL.createObjectURL(file)} />
            <span className="d-block text-white" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Uploading... {uploadPercent}%</span>
            <div
                className="btn py-0 px-2 text-white"
                style={{ position: 'absolute', top: ".2rem", right: ".2rem", backgroundColor: "rgba(255, 0, 0, 0.7)" }}
                onClick={() => fileUploader.cancel()}
            >X</div>
        </div>
    );
}
