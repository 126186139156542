import { GeoPoint, IBooking, IContractorJob, IQuoteRequest } from ".";
import { ICompany } from "./company";
import { int, float } from "./primitives";
import { IUser } from "./user";
import { IPostInstall, IRepairCheckList } from "./post-install";

export enum RepairType {
    WARRRANTY = 'Warranty',
    NON_WARRANTY = 'Non-Warranty',
}

export interface IUpdateRepairParams {
    openBalance?: int;
    stripeId?: string;
    repairDate?: Date | null;
    invoiceId?: string | null;
    total?: int;
    jobCompletedDate?: Date | null;
    hubspotDealId?: string | null;
    notes?: string | null;
    channelColor?: string | null;
}

export interface ICreateRepairParams {
    transactionDate: Date;
    pandaDocId: string | null;
    stripeId: string | null;
    branch: ICompany;
    repairRep: IUser | null;
    invoiceId: string | null;
    customer: {
        firstName: string;
        lastName: string | null;
        address: string;
        email: string;
        phone: string;
    };
    validatedAddress: string | null;

    total: int;
    subtotal?: int;
    openBalance?: int;

    repairDate: Date | null;
    createdAt: Date | undefined;

    quoteRequest: IQuoteRequest | null;
    typeOfLights?: string | null;
    ftSold?: int | null;

    rawDocument: any | null; // Use Interface?
    bookings?: IBooking[];
    type: RepairType | null;
}

export interface IRepair {
    id: int;
    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;

    transactionDate: Date;
    pandaDocId: string | null;
    stripeId: string | null;
    invoiceId: string | null;
    branch: ICompany;
    repairRep: IUser | null;
    customer: {
        firstName: string;
        lastName: string | null;
        address: string;
        geoPoint?: GeoPoint
        email: string;
        phone: string;
    };
    validatedAddress: string | null;

    total: int;
    repairDate: Date | null;

    ftSold?: int | null;
    postInstalls: IPostInstall[]
    rawDocument: any; // Use Interface?

    jobCompletedDate: Date | null;
    notes: string | null;
    channelColor: string | null;
    typeOfLights: string | null;

    checkList: IRepairCheckList[];
    bookings: IBooking[];
    type: RepairType | null;
}
