import { DimensionalUnit, ICreateProductParams, ICreateProductVariantParams, WeightUnit } from "@common.abstractions";
import { useState } from "react";
import "../create-product/_createProduct.scss";

export default function VariantProduct(props: {
    combo: any;
    index: number;
    mainProduct: ICreateProductParams;
    updateVariant: (i: number, value: string | number | boolean, property: keyof ICreateProductVariantParams) => void;
}) {
    const { combo, index, mainProduct, updateVariant } = props;
    const [productTitle, setProductTitle] = useState<string>(mainProduct.title);
    const [productDescription, setProductDescription] = useState<string>(mainProduct.description ?? "");
    const [productWeightUnit, setProductWeightUnit] = useState<WeightUnit>(mainProduct.weightUnit);
    const [productDimUnit, setProductDimUnit] = useState<DimensionalUnit>(mainProduct.dimUnit);
    const [productWeight, setProductWeight] = useState<number>(mainProduct.weight);
    const [productHeight, setProductHeight] = useState<number>(mainProduct.height);
    const [productWidth, setProductWidth] = useState<number>(mainProduct.width);
    const [productLength, setProductLength] = useState<number>(mainProduct.length);
    const [productRetailPrice, setProductRetailPrice] = useState<number>(mainProduct.retailPrice);
    const [productDeclaredValue, setProductDeclaredValue] = useState<number>(mainProduct.declaredValue);
    const [productListed, setProductListed] = useState<boolean>(mainProduct.listed);

    const handleProductListedChange = (value: boolean) => {
        setProductListed(!value);
        updateVariant(index, value, "listed");
    };
    const handleProductDescriptionChange = (value: string) => {
        setProductDescription(value);
        updateVariant(index, value, "description");
    };
    const handleProductWeightUnitChange = (value : WeightUnit) => {
        setProductWeightUnit(value);
        updateVariant(index, value, "weightUnit");
    };
    const handleProductWeightChange = (value: number) => {
        setProductWeight(value);
        updateVariant(index, value, "weight");
    };
    const handleProductDimUnitChange = (value: DimensionalUnit) => {
        setProductDimUnit(value);
        updateVariant(index, value, "dimUnit");
    };
    const handleProductHeightChange = (value: number) => {
        setProductHeight(value);
        updateVariant(index, value, "height");
    };
    const handleProductWidthChange = (value: number) => {
        setProductWidth(value);
        updateVariant(index, value, "width");
    };
    const handleProductLengthChange = (value: number) => {
        setProductLength(value);
        updateVariant(index, value, "length");
    };
    const handleRetailPriceChange = (value: number) => {
        setProductRetailPrice(value);
        updateVariant(index, Math.trunc(value * 100), "retailPrice");
    };
    const handleProductDeclaredValueChange = (value: number) => {
        setProductDeclaredValue(value);
        updateVariant(index, Math.trunc(value * 100), "declaredValue");
    };

    return (
        <div className="form_group scrollX">
            <h2 className="title">{`${index + 1}`}</h2>
            {combo.map((el: any, i: number) => {
                return (
                    <div key={`Combinations ${i}`}>
                        <label className="disabled">{el.name}</label>
                        <input className="disabled" readOnly value={el.value} />
                    </div>
                );
            })}
            <div className="checkbox_wrapper">
                <label>Listed?</label>
                <input
                    className="variant_checkbox_row"
                    type="checkbox"
                    onChange={(e) => handleProductListedChange(Boolean(e.target.checked))}
                    defaultChecked={productListed}
                />
            </div>
            <div>
                <label>Variation Description</label>
                <input type="text" placeholder="not required" value={productDescription} onChange={(e) => handleProductDescriptionChange(e.target.value)} />
            </div>
            <div>
                <label>Weight Unit</label>
                <select
                    required={true}
                    name="weightUnit"
                    value={productWeightUnit}
                    onChange={(e) => handleProductWeightUnitChange(e.target.value as WeightUnit)}
                >
                    <option value="oz">oz</option>
                    <option value="kg">kg</option>
                    <option value="lb">lb</option>
                    <option value="g">g</option>
                </select>
            </div>
            <div>
                <label>Weight</label>
                <input type="number" placeholder="not required" value={productWeight} onChange={(e) => handleProductWeightChange(Number(e.target.value))} />
            </div>
            <div>
                <label>Dimensions Unit</label>
                <select
                    name="dimUnit"
                    required={true}
                    value={productDimUnit}
                    placeholder="Dimension Unit"
                    onChange={(e) => handleProductDimUnitChange(e.target.value as DimensionalUnit)}
                >
                    <option value="in">in</option>
                    <option value="cm">cm</option>
                </select>
            </div>
            <div>
                <label>Length</label>
                <input type="number" placeholder="not required" value={productLength} onChange={(e) => handleProductLengthChange(Number(e.target.value))} />
            </div>
            <div>
                <label>Height</label>
                <input type="number" placeholder="not required" value={productHeight} onChange={(e) => handleProductHeightChange(Number(e.target.value))} />
            </div>
            <div>
                <label>Width</label>
                <input type="number" placeholder="not required" value={productWidth} onChange={(e) => handleProductWidthChange(Number(e.target.value))} />
            </div>
            <div>
                <label>Retail Price</label>
                <input type="number" placeholder="not required" value={productRetailPrice} onChange={(e) => handleRetailPriceChange(Number(e.target.value))} />
            </div>
            <div>
                <label>Declared Value</label>
                <input
                    type="number"
                    placeholder="not required"
                    value={productDeclaredValue}
                    onChange={(e) => handleProductDeclaredValueChange(Number(e.target.value))}
                />
            </div>
        </div>
    );
}
