import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { RetrieveLeads } from "../../../services/leads";
import { BadgeStatus, TabBar } from "../../misc/tab-bar/TabBar";

import AuthContext from "../../../contexts/AuthContext";

import { Loading } from "../../../components/misc/loading";
import { Address, Scope, IContact, ILead, int, IOrder, IQuoteRequest } from "@common.abstractions";
import { formatBuildingType, formatInstallationType, formatProductLine, GetDate, getGoogleMapsUrl, handleSort } from "@common.tools";
import Select from "react-select";
import { Between } from "@common.api";
import EverlightsLogoIconOnly from "../../../assets/LogoColor.png";
import ReactTooltip from "react-tooltip";

enum View {
    Unopened,
    Opened,
    InProgress,
    Expired,
    Revoked,
    Purchased,
    Lost,
    All,
}

export default function CompanyLeads(props: { companyId: int, searchCriteria: string }) {
    const {companyId, searchCriteria} = props;
    const authContext = useContext(AuthContext);
    const [leads, setLeads] = useState<ILead[]>();
    const [filteredLeads, setFilteredLeads] = useState<ILead[]>();
    const [isLoading, setLoading] = useState<boolean>(true);

    const [selectedView, setSelectedView] = useState(View.All);

    const [unopenedCount, setUnopenedCount] = useState<number>();
    const [openedCount, setOpenedCount] = useState<number>();
    const [purchasedCount, setPurchasedCount] = useState<number>();
    const [lostCount, setLostCount] = useState<number>();
    const [totalCount, setTotalCount] = useState<number>();

    const [timeFrame, setTimeFrame] = useState<{
        start: Date;
        end: Date;
        name: string
    }>({ start: GetDate.nDaysAgo(30), end: GetDate.tomorrow(), name: "last30Days" });
    
    const cursor = useRef<string>();
    
    const loadLeads = async (num:number) => {
        if (!timeFrame.start) {
            let tempLeads = await RetrieveLeads({}
                , {
                    direction: 'forward',
                    count: num,
                    cursor: cursor.current
                });

            if (tempLeads) {
                tempLeads.values.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                setLeads(tempLeads.values);
                setFilteredLeads(tempLeads.values);
                getCounts(tempLeads.values);
            } else {
                setLeads([]);
                setFilteredLeads([]);
                getCounts([]);
            }
        } else {
            let tempLeads = await RetrieveLeads({
                filters: {
                    createdAt: Between(timeFrame.start, timeFrame.end),
                },
            });

            if (tempLeads) {
                tempLeads.values.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                setLeads(tempLeads.values);
                setFilteredLeads(tempLeads.values);
                getCounts(tempLeads.values);
            } else {
                setLeads([]);
                setFilteredLeads([]);
                getCounts([]);
            }
        }

        setLoading(false);
    };

    const filterLeads = (view: View) => {
        setSelectedView(view);

        switch (view) {
            case View.All:
                setFilteredLeads(leads);
                break;
            case View.Unopened:
                setFilteredLeads(leads?.filter((lead) => !lead.viewedDate));
                break;
            case View.Opened:
                setFilteredLeads(leads?.filter((lead) => lead.viewedDate));
                break;
            case View.InProgress:
                setFilteredLeads(leads?.filter((el) => (!el.deletedAt || !el.vConverted) && el.orders?.length));
                break;
            case View.Expired:
                setFilteredLeads(leads?.filter((lead) => lead.deletedAt));
                break;
            case View.Purchased:
                setFilteredLeads(leads?.filter((lead) => (lead.vConverted)));
                break;
            case View.Lost:
                setFilteredLeads(leads?.filter((lead) => (lead.manualMarkLostDate)));
                break;
        }
    };

    const searchFilter = (entity: IQuoteRequest) => {
        const s = searchCriteria.toLowerCase();
        const contact = entity.contact as IContact;
        const address = entity.address as Address;
        if (
            searchCriteria === "" ||
            (contact.firstname + " " + contact.lastname).toLowerCase().includes(s) ||
            contact.phone.toLowerCase().includes(s) ||
            address.city.toLowerCase().includes(s) ||
            address.zip.toLowerCase().includes(s) ||
            address.streetAddress.toLowerCase().includes(s) ||
            address.state.toLowerCase().includes(s)
        ) {
            return true;
        }
        return false;
    };

    const getCounts = (leads: ILead[]) => {
        setTotalCount(leads.length);
        setUnopenedCount(leads?.filter((lead) => !lead.viewedDate).length);
        setOpenedCount(leads?.filter((lead) => lead.viewedDate).length);
        setPurchasedCount(leads?.filter((lead) => (lead.vConverted)).length);
        setLostCount(leads?.filter((lead) => (lead.manualMarkLostDate)).length);
    };

    useEffect(() => {
        cursor.current = undefined;
        loadLeads(20);
    }, [timeFrame]);

    useEffect(() => filterLeads(selectedView), [selectedView]);

    const getLeadStatus = (lead: ILead) => {
        if (lead.vConverted) return "Converted";
        if (lead.deletedAt) return "Expired";
        if (lead.manualMarkLostDate) return "Lost";
        if (!((lead.deletedAt || !lead.vConverted) && lead.orders?.length)) return "In Progress";
        if (lead.viewedDate) return "Opened";
        if (!lead.viewedDate) return "Unopened";
        return "Unknown";
    };

    return (
        <div className="flex flex-col w-full">
            <div className="flex justify-between items-center">
                <div className="summary_wrapper tabs flex-grow">
                    <TabBar
                        values={[
                            ["Total", View.All, { status: BadgeStatus.Info, count: totalCount }],
                            ["Unopened", View.Unopened, { status: BadgeStatus.Warn, count: unopenedCount }],
                            ["Opened", View.Opened, { status: BadgeStatus.Good, count: openedCount }],
                            ["Closed", View.Purchased, { status: BadgeStatus.Good, count: purchasedCount }],
                            ["Lost", View.Lost, { status: BadgeStatus.Warn, count: lostCount }],
                        ]}
                        selected={selectedView}
                        onTabClicked={(tab) => filterLeads(tab)}
                    />
                </div>

                <Select
                    className="quotes_date_dropdown"
                    defaultValue={{
                        value: {
                            start: GetDate.nDaysAgo(30),
                            end: GetDate.tomorrow(),
                            name: "last30Days",
                        },
                        label: "Last 30 Days",
                    }}
                    options={[
                        {
                            value: {
                                start: GetDate.january(),
                                end: GetDate.tomorrow(),
                                name: "yearToDate",
                            },
                            label: "Year to Date",
                        },
                        {
                            value: {
                                start: GetDate.nDaysAgo(30),
                                end: GetDate.tomorrow(),
                                name: "last30Days",
                            },
                            label: "Last 30 Days",
                        },
                        {
                            value: {
                                start: GetDate.currentMonth(),
                                end: GetDate.tomorrow(),
                                name: "monthToDate",
                            },
                            label: "Month to Date",
                        },
                        {
                            value: {
                                start: GetDate.everlightsBirth(),
                                end: GetDate.tomorrow(),
                                name: "allTime",
                            },
                            label: "All Time",
                        },
                        {
                            value: {
                                start: GetDate.january(),
                                end: GetDate.february(),
                                name: "january",
                            },
                            label: "January",
                        },
                        {
                            value: {
                                start: GetDate.february(),
                                end: GetDate.march(),
                                name: "february",
                            },
                            label: "February",
                        },
                        {
                            value: {
                                start: GetDate.march(),
                                end: GetDate.april(),
                                name: "march",
                            },
                            label: "March",
                        },
                        {
                            value: {
                                start: GetDate.april(),
                                end: GetDate.may(),
                                name: "april",
                            },
                            label: "April",
                        },
                        {
                            value: {
                                start: GetDate.may(),
                                end: GetDate.june(),
                                name: "may",
                            },
                            label: "May",
                        },
                        {
                            value: {
                                start: GetDate.june(),
                                end: GetDate.july(),
                                name: "june",
                            },
                            label: "June",
                        },
                        {
                            value: {
                                start: GetDate.july(),
                                end: GetDate.august(),
                                name: "july",
                            },
                            label: "July",
                        },
                        {
                            value: {
                                start: GetDate.august(),
                                end: GetDate.september(),
                                name: "august",
                            },
                            label: "August",
                        },
                        {
                            value: {
                                start: GetDate.september(),
                                end: GetDate.october(),
                                name: "september",
                            },
                            label: "September",
                        },
                        {
                            value: {
                                start: GetDate.october(),
                                end: GetDate.november(),
                                name: "october",
                            },
                            label: "October",
                        },
                        {
                            value: {
                                start: GetDate.november(),
                                end: GetDate.december(),
                                name: "november",
                            },
                            label: "November",
                        },
                        {
                            value: {
                                start: GetDate.december(),
                                end: GetDate.nextJanuary(),
                                name: "december",
                            },
                            label: "December",
                        },
                    ]}
                    onChange={(val) => setTimeFrame(val!.value)}
                />
            </div>
            {!isLoading ? (
                filteredLeads?.length ? (
                    <>
                        <div className="row header">
                            <div className="max-w-date_text">
                                Source
                                {/* <FontAwesomeIcon icon={faSort} /> */}
                            </div>
                            <div className="max-w-date_text" onClick={() => setLeads(handleSort(["company"], filteredLeads))}>
                                Assigned
                                {/* <FontAwesomeIcon icon={faSort} /> */}
                            </div>
                            <div className="max-w-date_text" onClick={() => setLeads(handleSort(["company"], filteredLeads))}>
                                Submitted
                                {/* <FontAwesomeIcon icon={faSort} /> */}
                            </div>
                            <div onClick={() => setLeads(handleSort(["viewedDate"], filteredLeads))}>
                                Status
                                {/* <FontAwesomeIcon icon={faSort} /> */}
                            </div>
                            <div>
                                {/*onClick={() => setLeads(handleSort(["firstname", "lastname"], leads, ["quoteRequest", "contact"]))}*/}
                                Contact
                                {/* <FontAwesomeIcon icon={faSort} /> */}
                            </div>
                            <div>
                                {/*onClick={() => setLeads(handleSort(["state", "city", "streetAddress"], leads, ["quoteRequest", "address"]))}*/}
                                Address
                                {/* <FontAwesomeIcon icon={faSort} /> */}
                            </div>
                            <div>
                                {/*onClick={() => setLeads(handleSort(["buildingType"], leads, ["quoteRequest"]))}*/}
                                Building
                                {/* <FontAwesomeIcon icon={faSort} /> */}
                            </div>
                            <div>
                                {/*onClick={() => setLeads(handleSort(["installationType"], leads, ["quoteRequest"]))}*/}
                                Install
                                {/* <FontAwesomeIcon icon={faSort} /> */}
                            </div>
                            <div>
                                {/*onClick={() => setLeads(handleSort(["productLines"], leads, ["quoteRequest"]))}*/
                                /*This is is hard because it's an array, not a string as the target*/}
                                Products
                                {/* <FontAwesomeIcon icon={faSort} /> */}
                            </div>
                            <div></div>
                        </div>
                        <div className="rows_wrapper">
                            {filteredLeads.map((lead) => {
                                if (lead.quoteRequest && lead.quoteRequest.contact && searchFilter(lead.quoteRequest)) {
                                    const quoteRequest = lead.quoteRequest;
                                    const contact = lead.quoteRequest.contact;
                                    return (
                                        <div className="row item" key={lead.id}>
                                            <div className="max-w-date_text">{lead.quoteRequest.origin === "website_form" ? "EverLights" : "Manual Entry"}</div>
                                            <div className="max-w-date_text">{new Date(lead.createdAt).toLocaleDateString()}</div>
                                            <div className="max-w-date_text">{new Date(lead.quoteRequest.createdAt).toLocaleDateString()}</div>
                                            <div>{getLeadStatus(lead)}</div>
                                            <div>
                                                {authContext?.hasAnyGrant(Scope.Contact.READ_ANY) ? (
                                                    <Link className="link" to={`/contacts/${contact.id}`}>
                                                        {`${contact.firstname} ${contact.lastname}`}
                                                    </Link>
                                                ) : (
                                                    <div>{`${contact.firstname} ${contact.lastname}`}</div>
                                                )}
                                            </div>
                                            <div className="pr-2">
                                                <a className="link" target="_blank" href={getGoogleMapsUrl(quoteRequest.address)}>
                                                    {quoteRequest.address.streetAddress}, {quoteRequest.address.city}, {quoteRequest.address.state}
                                                </a>
                                            </div>
                                            <div>{quoteRequest.buildingType ? formatBuildingType(quoteRequest.buildingType) : ""}</div>
                                            <div>{quoteRequest.installationType ? formatInstallationType(quoteRequest.installationType) : ""}</div>
                                            <div>{quoteRequest.productLines ? quoteRequest.productLines.map((pl) => formatProductLine(pl)).join(", ") : ""}</div>
                                            <div className="flex justify-center">
                                                <Link className="btn inline-flex bg-theme_blue text-white" to={`/leads/${lead.id}`}>
                                                    Quote Request
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                }

                                return <div className="inset-center text-gray-500">Failed to load</div>;
                            })}
                        </div>
                    </>
                ) : (
                    <div className="inset-center text-gray-500">No Leads</div>
                )
            ) : (
                <Loading center />
            )}
        </div>
    );
}
