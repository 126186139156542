import { Loading } from "../../misc/loading";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import QueryString from "qs";
import { RetrieveLeadCloseRates, RetrieveMOQs, RetrieveTotalSpent } from "@frontend/services/analytics";
import { formatMoney, GetDate } from "@common.tools";
import { LeadCloseRate, MOQ, TotalSpent } from "../components";
import ReactSwitch from "react-switch";
import BranchInstalls from "../../installs/details/branchInstalls";
import TopBar from "@frontend/components/misc/top-bar/TopBar";

export default function DashboardDetails() {
    const location = useLocation();
    const params = QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
    });

    const [filterEverlights, setFilterEverlights] = useState<boolean>(window.localStorage.getItem("filterEverlights") === "false" ? false : true);
    const [filterBestToWorst, setFilterBestToWorst] = useState<boolean>(window.localStorage.getItem("filterBestToWorst") === "false" ? false : true);
    const [titleSubstrLength, setTitleSubstrLength] = useState<number>(40);

    let initialTimeFrame: { start: Date; end: Date, name: string } = { start: GetDate.currentMonth(), end: GetDate.tomorrow(), name: 'monthToDate' };
    let initialTimeFrameLabel = "Month To Date";
    let initialDataType = { val: "Lead Close Rate" };

    if (params.time) {
        switch (params.time) {
            case "yearToDate":
                initialTimeFrame = { start: GetDate.january(), end: GetDate.tomorrow(), name: 'yearToDate' };
                initialTimeFrameLabel = "Year to Date";
                break;
            case "monthToDate":
                initialTimeFrame = { start: GetDate.currentMonth(), end: GetDate.tomorrow(), name: 'monthToDate' };
                initialTimeFrameLabel = "Month to Date";
                break;
            case "allTime":
                initialTimeFrame = { start: GetDate.everlightsBirth(), end: GetDate.tomorrow(), name: 'allTime' };
                initialTimeFrameLabel = "All Time";
                break;
            case '2023':
                initialTimeFrame = { start: GetDate.january(2023), end: GetDate.endOfYear(2023), name: '2023' };
                initialTimeFrameLabel = "2023";
                break;
            case "2022":
                initialTimeFrame = { start: GetDate.january(2022), end: GetDate.endOfYear(2022), name: '2022' };
                initialTimeFrameLabel = "2022";
                break;
            case "2021":
                initialTimeFrame = { start: GetDate.january(2021), end: GetDate.endOfYear(2021), name: '2021' };
                initialTimeFrameLabel = "2021";
                break;
            case "january":
                initialTimeFrame = { start: GetDate.january(), end: GetDate.february(), name: 'january' };
                initialTimeFrameLabel = "January";
                break;
            case "february":
                initialTimeFrame = { start: GetDate.february(), end: GetDate.march(), name: 'february' };
                initialTimeFrameLabel = "February";
                break;
            case "march":
                initialTimeFrame = { start: GetDate.march(), end: GetDate.april(), name: 'march' };
                initialTimeFrameLabel = "March";
                break;
            case "april":
                initialTimeFrame = { start: GetDate.april(), end: GetDate.may(), name: 'april' };
                initialTimeFrameLabel = "April";
                break;
            case "may":
                initialTimeFrame = { start: GetDate.may(), end: GetDate.june(), name: 'may' };
                initialTimeFrameLabel = "May";
                break;
            case "june":
                initialTimeFrame = { start: GetDate.june(), end: GetDate.july(), name: 'june' };
                initialTimeFrameLabel = "June";
                break;
            case "july":
                initialTimeFrame = { start: GetDate.july(), end: GetDate.august(), name: 'july' };
                initialTimeFrameLabel = "July";
                break;
            case "august":
                initialTimeFrame = { start: GetDate.august(), end: GetDate.september(), name: 'august' };
                initialTimeFrameLabel = "August";
                break;
            case "september":
                initialTimeFrame = { start: GetDate.september(), end: GetDate.october(), name: 'september' };
                initialTimeFrameLabel = "September";
                break;
            case "october":
                initialTimeFrame = { start: GetDate.october(), end: GetDate.november(), name: 'october' };
                initialTimeFrameLabel = "October";
                break;
            case "november":
                initialTimeFrame = { start: GetDate.november(), end: GetDate.december(), name: 'november' };
                initialTimeFrameLabel = "November";
                break;
            case "december":
                initialTimeFrame = { start: GetDate.december(), end: GetDate.nextJanuary(), name: 'december' };
                initialTimeFrameLabel = "December";
                break;
            case 'custom':
                initialTimeFrame = { start: new Date(params.customStart as string), end: new Date(params.customEnd as string), name: 'custom' }
                initialTimeFrameLabel = 'Custom Range'
                break;
            default:
                initialTimeFrame = { start: GetDate.currentMonth(), end: GetDate.tomorrow(), name: 'monthToDate' };
                initialTimeFrameLabel = "Month to Date";
        }
    }
    if (params.dataType) {
        switch (params.dataType) {
            case "Lead Close Rate":
                initialDataType = { val: params.dataType as string };
                break;
            case "Leads Contributed":
                initialDataType = { val: params.dataType as string };
                break;
            case "Total Spent":
                initialDataType = { val: params.dataType as string };
                break;
            case "In vs Out of Territory Sales":
                initialDataType = { val: params.dataType as string };
                break;
            case "Self Generated Closes":
                initialDataType = { val: params.dataType as string };
                break;
            case "MOQ":
                initialDataType = { val: params.dataType as string };
                break;
            default:
                initialDataType = { val: "Lead Close Rate" };
        }
    }

    const [timeFrame, setTimeFrame] = useState<{
        start: Date;
        end: Date;
        name: string
    }>(initialTimeFrame);
    const [dataType, setDataType] = useState<{ val: string }>(initialDataType);

    const handleEverlightsFilterToggle = (checked: boolean) => {
        setFilterEverlights(checked);
        if (checked) {
            window.localStorage.setItem("filterEverlights", "true");
        } else {
            window.localStorage.setItem("filterEverlights", "false");
        }
    };
    const handleBestToWorstFilterToggle = (checked: boolean) => {
        setFilterBestToWorst(checked);
        if (checked) {
            window.localStorage.setItem("filterBestToWorst", "true");
        } else {
            window.localStorage.setItem("filterBestToWorst", "false");
        }
    };

    return (
        <div className="page flex flex-col">
            <TopBar title="Company Data">
                <div className="mb-5 flex">
                    <Select
                        className={`quotes_date_dropdown`}
                        defaultValue={{
                            value: timeFrame,
                            label: initialTimeFrameLabel,
                        }}
                        options={[
                            {
                                value: {
                                    start: GetDate.january(),
                                    end: GetDate.tomorrow(),
                                    name: "yearToDate",
                                },
                                label: "Year to Date",
                            },
                            {
                                value: {
                                    start: GetDate.currentMonth(),
                                    end: GetDate.tomorrow(),
                                    name: "monthToDate",
                                },
                                label: "Month to Date",
                            },
                            {
                                value: {
                                    start: GetDate.everlightsBirth(),
                                    end: GetDate.tomorrow(),
                                    name: "allTime",
                                },
                                label: "All Time",
                            },
                            {
                                value: {
                                    start: GetDate.january(2023),
                                    end: GetDate.endOfYear(2023),
                                    name: "2023"
                                },
                                label: '2023'
                            },
                            {
                                value: {
                                    start: GetDate.january(2022),
                                    end: GetDate.endOfYear(2022),
                                    name: "2022"
                                },
                                label: '2022'
                            },
                            {
                                value: {
                                    start: GetDate.january(2021),
                                    end: GetDate.endOfYear(2021),
                                    name: "2021"
                                },
                                label: '2021'
                            },
                            {
                                value: {
                                    start: new Date(),
                                    end: new Date(),
                                    name: 'custom'
                                },
                                label: 'Custom Range'
                            },

                            {
                                value: {
                                    start: GetDate.january(),
                                    end: GetDate.february(),
                                    name: "January",
                                },
                                label: "January",
                            },
                            {
                                value: {
                                    start: GetDate.february(),
                                    end: GetDate.march(),
                                    name: "February",
                                },
                                label: "February",
                            },
                            {
                                value: {
                                    start: GetDate.march(),
                                    end: GetDate.april(),
                                    name: "March",
                                },
                                label: "March",
                            },
                            {
                                value: {
                                    start: GetDate.april(),
                                    end: GetDate.may(),
                                    name: "April",
                                },
                                label: "April",
                            },
                            {
                                value: {
                                    start: GetDate.may(),
                                    end: GetDate.june(),
                                    name: "May",
                                },
                                label: "May",
                            },
                            {
                                value: {
                                    start: GetDate.june(),
                                    end: GetDate.july(),
                                    name: "June",
                                },
                                label: "June",
                            },
                            {
                                value: {
                                    start: GetDate.july(),
                                    end: GetDate.august(),
                                    name: "July",
                                },
                                label: "July",
                            },
                            {
                                value: {
                                    start: GetDate.august(),
                                    end: GetDate.september(),
                                    name: "August",
                                },
                                label: "August",
                            },
                            {
                                value: {
                                    start: GetDate.september(),
                                    end: GetDate.october(),
                                    name: "September",
                                },
                                label: "September",
                            },
                            {
                                value: {
                                    start: GetDate.october(),
                                    end: GetDate.november(),
                                    name: "October",
                                },
                                label: "October",
                            },
                            {
                                value: {
                                    start: GetDate.november(),
                                    end: GetDate.december(),
                                    name: "November",
                                },
                                label: "November",
                            },
                            {
                                value: {
                                    start: GetDate.december(),
                                    end: GetDate.nextJanuary(),
                                    name: "December",
                                },
                                label: "December",
                            },
                        ]}
                        onChange={(val) => {
                            if (val?.value.name === 'custom') {
                                setTimeFrame({ start: timeFrame.start, end: timeFrame.end, name: 'custom' })
                                return;
                            }
                            setTimeFrame(val!.value)

                        }}
                    />

                    <Select
                        className="quotes_date_dropdown ml-5"
                        defaultValue={{
                            value: dataType,
                            label: dataType.val,
                        }}
                        options={[
                            // {
                            //     value: { val: "Leads Contributed" },
                            //     label: "Leads Contributed",
                            // },
                            {
                                value: { val: "Total Spent" },
                                label: "Total Spent",
                            },
                            {
                                value: { val: "Lead Close Rate" },
                                label: "Lead Close Rate",
                            },
                            {
                                value: { val: "MOQ" },
                                label: "MOQ",
                            },
                            // {
                            //     value: { val: "Lead Quality Score" },
                            //     label: "Lead Quality Score",
                            // },
                            // {
                            //     value: { val: "Branch Installs" },
                            //     label: "Branch Installs",
                            // },
                        ]}
                        onChange={(val) => setDataType(val!.value)}
                    />

                    <div className="flex flex-col items-end ml-6">
                        <div className="text-gray-500 text-sm">Everlights</div>
                        <ReactSwitch
                            offColor={"#aaa"}
                            onColor={"#3F87ED"}
                            boxShadow={"2px 2px 2px rgba(30,30,30,.2)"}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            width={40}
                            height={20}
                            onChange={(checked) => handleEverlightsFilterToggle(checked)}
                            checked={filterEverlights}
                        />
                    </div>
                    <div className="flex flex-col items-end ml-6">
                        <div className="text-gray-500 text-sm">Sort by Best</div>
                        <ReactSwitch
                            offColor={"#aaa"}
                            onColor={"#3F87ED"}
                            boxShadow={"2px 2px 2px rgba(30,30,30,.2)"}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            width={40}
                            height={20}
                            onChange={(checked) => handleBestToWorstFilterToggle(checked)}
                            checked={filterBestToWorst}
                        />
                    </div>
                </div>
            </TopBar>

            {timeFrame.name === 'custom' && dataType.val !== "MOQ" &&
                <div className="flex justify-end space-x-4">
                    <input
                        type="date"
                        className="flex border hover:border-input_hover text-center"
                        defaultValue={timeFrame.start.toISOString().split("T")[0]}
                        onBlur={(e) => {
                            const date = new Date(e.target.value).getTime();

                            if (isNaN(date)) {
                                return;
                            }
                            setTimeFrame({ start: new Date(date), end: timeFrame.end, name: 'custom' })
                        }}
                    />
                    <input
                        type="date"
                        className="flex border hover:border-input_hover text-center"
                        defaultValue={timeFrame.end.toISOString().split("T")[0]}
                        onBlur={(e) => {
                            const date = new Date(e.target.value).getTime();

                            if (isNaN(date)) {
                                return;
                            }
                            setTimeFrame({ start: timeFrame.start, end: new Date(date), name: 'custom' })
                        }}
                    />
                </div>
            }

            <div className="overflow-hidden h-full">
                {
                    dataType.val === "Lead Close Rate" && (
                        <LeadCloseRate timeFrame={timeFrame} titleSubstrLength={titleSubstrLength} filterEverlights={filterEverlights} filterBestToWorst={filterBestToWorst} detailedView={true} />
                    )
                }
                {
                    dataType.val === "Lead Close Rate" && (
                        <LeadCloseRate timeFrame={timeFrame} titleSubstrLength={titleSubstrLength} filterEverlights={filterEverlights} filterBestToWorst={filterBestToWorst} detailedView={true} />
                    )
                }
                {
                    dataType.val === "Leads Contributed" && (
                        null
                    )
                }
                {
                    dataType.val === "Total Spent" && (
                        <TotalSpent timeFrame={timeFrame} titleSubstrLength={titleSubstrLength} filterEverlights={filterEverlights} filterBestToWorst={filterBestToWorst} detailedView={true} />
                    )
                }
                {
                    dataType.val === "In vs Out of Territory Sales" && (
                        null
                    )
                }
                {
                    dataType.val === "Self Generated Closes" && (
                        null
                    )
                }
                {
                    dataType.val === "MOQ" && (
                        <MOQ timeFrame={timeFrame} titleSubstrLength={titleSubstrLength} filterEverlights={filterEverlights} filterBestToWorst={filterBestToWorst} detailedView={true} />
                    )
                }
            </div>
        </div>
    );
}
