import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LogoWithText from "../../assets/LogoWithText.png";

export default function ForgotPassword() {
    const [email, setEmail] = useState<string>("");

    const handleSubmit = (args: any) => alert("Not currently supported") 

    // const handleSubmit = async (e: any) => {
    //     e.preventDefault();
    //     console.log(email);
    //     if (!email) {
    //         toast.error("Please enter your email");
    //         return;
    //     }
    //     if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))) {
    //         toast.error("Please enter a valid email");
    //         return;
    //     }

    //     const [success, message] = await SendResetPasswordEmail(email);
    //     if (success) {
    //         toast(message);
    //     } else {
    //         toast.error(message);
    //     }
    // };

    return (
        <div className="h-screen relative">
            <div className="inset-center max-w-md flex flex-col items-center -my-10">
                <img src={LogoWithText} alt="Logo" />
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="form_group">
                        <div>
                            <label>Email</label>
                            <input className="form-control" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="required" />
                        </div>
                    </div>
                    <button onClick={(e) => handleSubmit(e)} className="btn bg-theme_blue text-white mt-6">
                        Reset Password
                    </button>

                    <div className="mx-auto text-gray-500 mt-4">
                        <Link to="/">
                            Login
                        </Link>
                    </div>
                </form>
            </div>
        </div>
    );
}
