import React from "react";

export default function Finances() {
    return (
        <div className="flex flex-col h-full max-h-full overflow-hidden">
            <div className="flex justify-between items-center mb-5">
                <div className="flex items-end">
                    <h1 className="text-2xl text-gray-500 uppercase">Finances</h1>
                </div>
                <div className="flex">button</div>
            </div>
        </div>
    );
}
