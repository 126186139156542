import React, { useState, useEffect, useRef } from 'react';
import { RetrieveQuestions, CreateInstallerCheckList, UpdatePostInstall } from '@frontend/services/post-install';
import { QuestionType, AnswerType, IQuestions, IAnswerOptions, int, ICreateInstallerCheckList } from '@common.abstractions';
import TopBar from '../misc/top-bar/TopBar';
import { toast } from 'react-toastify';
import { Equals } from '@common.api';
import Select from 'react-select';
import { useHistory, useParams } from 'react-router-dom';
// @ts-ignore
import { useTesseract } from 'react-tesseract';
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import JSConfetti from 'js-confetti';

export default function InstallerCheckListForm() {
    const history = useHistory();
    const { postInstallId } = useParams<{ postInstallId: string }>();

    const [questions, setQuestions] = useState<IQuestions[]>([]);
    const [dropdownFreeFormResponses, setDropdownFreeFormResponses] = useState<{ [key: number]: string }>({});
    const [userResponses, setUserResponses] = useState<{ question: string; answer: string }[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pageOneQuestions, setPageOneQuestions] = useState<IQuestions[]>([]);
    const [pageTwoQuestions, setPageTwoQuestions] = useState<IQuestions[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);

    const [imageUrl, setImageUrl] = useState<string>('');
    const [bridgeNumber, setBridgeNumber] = useState<string>('');
    const { recognize, result, isRecognizing } = useTesseract();
    const cropperRef = useRef<ReactCropperElement>(null);
    const jsConfetti = new JSConfetti();

    const handleCropAndRecognize = async () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            const croppedImageDataUrl = cropper.getCroppedCanvas().toDataURL();
            const response = await fetch(croppedImageDataUrl);
            const blob = await response.blob();

            try {
                await recognize(blob, {
                    language: 'eng',
                    tessedit_ocr_engine_mode: 1,
                    tessedit_pageseg_mode: 1,
                });
            } catch (err) {
                console.error("OCR error:", err);
            }
        }
    };

    const handleImageChange = (e: any) => {
        const file = e.target.files?.[0];
        if (file) {
            const url = URL.createObjectURL(file);
            setImageUrl(url);
        }
    };

    const loadQuestions = async () => {
        const response = await RetrieveQuestions({
            relations: ['answerOptions'],
            filters: {
                type: Equals(QuestionType.INSTALL),
                listed: Equals(true),
            }
        });

        if (!response) return toast.error('Failed to load questions');
        response.sort((a, b) => a.sortOrder - b.sortOrder);

        setPageOneQuestions(response.filter(q => q.page === 1))
        setPageTwoQuestions(response.filter(q => q.page === 2))
        setQuestions(response);
    }

    const handleDropdownFreeFormSelection = (questionId: int, selectedValue: string) => {
        setUserResponses((prevResponses) => {
            const selectedQuestion = questions.find((q) => q.id === questionId);

            // Remove any previous responses for the same question
            const updatedResponses = prevResponses.filter(response => response.question !== selectedQuestion?.text);

            const answer = selectedQuestion?.answerOptions?.find((option) => option.text === selectedValue);
            if (answer) {
                updatedResponses.push({
                    question: selectedQuestion!.text || '',
                    answer: answer.text,
                });
            }

            return updatedResponses;
        });

        setDropdownFreeFormResponses({
            ...dropdownFreeFormResponses,
            [questionId]: selectedValue,
        });
    }

    const handleDropdownSelection = (questionId: int, selectedValue: string) => {
        const selectedQuestion = questions.find((q) => q.id === questionId);

        setUserResponses((prevResponses) => {
            if (selectedQuestion) {
                const answer = selectedQuestion.answerOptions?.find((option) => option.text === selectedValue);
                if (answer) {
                    // Remove any previous responses for the same question
                    const updatedResponses = prevResponses.filter(response => {
                        return response.question !== selectedQuestion.text;
                    });

                    // Add the final response.
                    updatedResponses.push({
                        question: selectedQuestion.text,
                        answer: answer.text,
                    });

                    return updatedResponses;
                }
            }

            // For other values or if the answer is not found, no change in responses.
            return prevResponses;
        });
    }

    const handleFreeFormInput = (questionId: int, inputValue: string) => {
        setUserResponses((prevResponses) => {
            const selectedQuestion = questions.find((q) => q.id === questionId);

            if (selectedQuestion) {
                // Remove any previous responses for the same question
                const updatedResponses = prevResponses.filter(response => {
                    return response.question !== selectedQuestion.text;
                });

                // Add the final response.
                updatedResponses.push({
                    question: selectedQuestion.text,
                    answer: inputValue,
                });

                return updatedResponses;
            }

            // For other values, no change in responses.
            return prevResponses;
        });
    }

    const handleYesNoSelection = (questionId: int, selectedValue: string) => {
        setUserResponses((prevResponses) => {
            const updatedResponses = prevResponses.filter(response => {
                // Remove any previous responses for the same question
                return response.question !== questions.find(q => q.id === questionId)?.text;
            });

            updatedResponses.push({
                question: questions.find(q => q.id === questionId)?.text || '',
                answer: selectedValue,
            });

            return updatedResponses;
        });
    }

    const handleResponseSubmission = async () => {
        const params: ICreateInstallerCheckList = {
            postInstallId: int.parse(postInstallId),
            response: userResponses,
        }

        const installerCheckList = await CreateInstallerCheckList(params);
        if (!installerCheckList) return toast.error('Something went wrong');

        if (bridgeNumber) {
            await UpdatePostInstall(int.parse(postInstallId), { bridgeNumber: bridgeNumber });
        }

        setUserResponses([]);
        toast.success('Submission received');
        history.push('/installerTool');
    }

    const renderInput = (question: IQuestions) => {
        const containerStyle = 'w-full mb-2 flex flex-col items-center'

        switch (question.answerType) {
            case AnswerType.DD:
                return (
                    <div className={containerStyle} key={question.id} >
                        <Select
                            className='w-full sm:w-2/3 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                            onChange={(val) => handleDropdownSelection(question.id, val!.value)}
                            options={
                                question.answerOptions?.sort((a, b) => a.sortOrder - b.sortOrder)
                                    .map((option: IAnswerOptions) => ({
                                        value: option.text,
                                        label: option.text,
                                    }))
                            }
                        />
                    </div>
                );
            case AnswerType.DD_FF:
                return (
                    <div className={containerStyle}>
                        <Select
                            className='w-full sm:w-2/3 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                            onChange={(val) => {
                                const selectedValue = val?.value;
                                handleDropdownFreeFormSelection(question.id, selectedValue!);

                                if (selectedValue === 'Yes') {
                                    setUserResponses((prevResponses) => [
                                        ...prevResponses,
                                        {
                                            question: question.text,
                                            answer: 'Yes',
                                        },
                                    ]);
                                } else if (selectedValue === 'No') {
                                    const selectedQuestion = questions.find((q) => q.id === question.id);
                                    if (selectedQuestion) {
                                        const answer = selectedQuestion.answerOptions?.find((option) => option.text === selectedValue);
                                        if (answer) {
                                            setUserResponses((prevResponses) => [
                                                ...prevResponses,
                                                {
                                                    question: selectedQuestion.text,
                                                    answer: answer.text,
                                                },
                                            ]);
                                        }
                                    }
                                }
                            }}
                            options={[
                                {
                                    value: 'Yes',
                                    label: 'Yes',
                                },
                                {
                                    value: 'No',
                                    label: 'No',
                                }
                            ]}
                        />
                        {dropdownFreeFormResponses[question.id] === 'No' &&
                            <div className='w-full mt-2'>
                                <label className='text-sm block mb-1'>If not, why?</label>
                                <input
                                    className='w-full px-3 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                                    type="text"
                                    onBlur={(e) => handleFreeFormInput(question.id, e.target.value)}
                                />
                            </div>}
                    </div>
                );
            case AnswerType.FF:
                return (
                    <div className={containerStyle} key={question.id}>
                        <input
                            className='w-full sm:w-2/3 px-3 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                            type="text"
                            onBlur={(e) => handleFreeFormInput(question.id, e.target.value)}
                        />
                    </div>
                );
            case AnswerType.YN:
                return (
                    <div className={containerStyle} key={question.id}>
                        <div className="w-full sm:w-2/3 text-gray-700 bg-white border border-gray-300 rounded-md p-2">
                            <label className="flex items-center space-x-4">
                                <input
                                    type="radio"
                                    name={`yesno-${question.id}`}
                                    value="Yes"
                                    onChange={() => handleYesNoSelection(question.id, 'Yes')}
                                    className="form-radio text-blue-500"
                                />
                                <span>Yes</span>
                            </label>
                            <label className="flex items-center space-x-4">
                                <input
                                    type="radio"
                                    name={`yesno-${question.id}`}
                                    value="No"
                                    onChange={() => handleYesNoSelection(question.id, 'No')}
                                    className="form-radio text-blue-500"
                                />
                                <span>No</span>
                            </label>
                        </div>
                    </div>
                );
            case AnswerType.YN_FF:
                return (
                    <div className={containerStyle} key={question.id}>
                        <div className="w-full sm:w-2/3 text-gray-700 bg-white border border-gray-300 rounded-md p-2">
                            <label className="flex items-center space-x-4">
                                <input
                                    type="radio"
                                    name={`ynff-${question.id}`}
                                    value="Yes"
                                    onChange={() => {
                                        handleYesNoSelection(question.id, 'Yes');
                                        setDropdownFreeFormResponses((prevResponses) => ({
                                            ...prevResponses,
                                            [question.id]: 'Yes',
                                        }));
                                    }}
                                    className="form-radio text-blue-500"
                                />
                                <span>Yes</span>
                            </label>
                            <label className="flex items-center space-x-4">
                                <input
                                    type="radio"
                                    name={`ynff-${question.id}`}
                                    value="No"
                                    onChange={() => {
                                        handleYesNoSelection(question.id, 'No');
                                        setDropdownFreeFormResponses((prevResponses) => ({
                                            ...prevResponses,
                                            [question.id]: 'No',
                                        }));
                                    }}
                                    className="form-radio text-blue-500"
                                />
                                <span>No</span>
                            </label>
                        </div>

                        {dropdownFreeFormResponses[question.id] === 'No' && (
                            <div className="w-full mt-2">
                                <label className="text-sm block mb-1">If not, why?</label>
                                <input
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                                    type="text"
                                    onBlur={(e) => handleFreeFormInput(question.id, e.target.value)}
                                />
                            </div>
                        )}
                    </div>
                );

            default:
                return null;
        }
    };

    const renderActionButton = () => {
        if (page === 1) {
            return (
                <button
                    type="button"
                    onClick={navigateToNextPage}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md mt-4 w-full transition duration-300"
                >
                    Next
                </button>
            )
        }

        return (
            <button
                type="button"
                onClick={handleResponseSubmission}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-md mt-4 w-full transition duration-300"
            >
                Submit
            </button>
        )
    };

    const navigateToNextPage = () => {
        if (containerRef.current) {
            containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
        jsConfetti.addConfetti();
        setPage(page + 1);
    }

    useEffect(() => {
        loadQuestions();
    }, []);

    useEffect(() => {
        if (result) {
            setBridgeNumber(result.trim());
        }
    }, [result]);

    return (
        <div className='w-full h-full flex flex-col bg-gray-100'>
            <TopBar title="Installer Checklist" />
            <div className='flex flex-col justify-center items-center flex-1 overflow-y-auto px-2 py-4 md:p-4'>
                <div ref={containerRef} className="w-full max-w-lg mx-auto bg-white shadow-md rounded-lg p-4 md:p-6 overflow-y-auto">

                    {page === 1 &&
                        <>
                            {/* OCR Section */}
                            <div className="bg-gray-50 p-4 rounded-md shadow-sm mb-6">
                                <h2 className="text-lg md:text-xl font-semibold mb-4">Scan Bridge #</h2>
                                <div className="flex flex-col space-y-4">
                                    <input type="file" accept="image/*" capture="environment" onChange={handleImageChange} />

                                    {imageUrl && (
                                        <>
                                            <Cropper
                                                src={imageUrl}
                                                style={{ height: 400, width: '100%' }}
                                                initialAspectRatio={16 / 9}
                                                guides={false}
                                                ref={cropperRef}
                                                viewMode={1}
                                            />
                                            <button
                                                onClick={handleCropAndRecognize}
                                                disabled={isRecognizing}
                                                className={`bg-blue-500 text-white text-sm py-2 px-4 rounded-md transition duration-300 ${isRecognizing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
                                                    }`}
                                            >
                                                Crop & Recognize Text
                                            </button>
                                        </>
                                    )}

                                    {/* Display the recognized serial number */}
                                    <div className="flex flex-col mt-4">
                                        <label className="text-sm font-semibold mb-1">Bridge #</label>
                                        <input
                                            type="text"
                                            value={bridgeNumber}
                                            onChange={(e) => setBridgeNumber(e.target.value)}
                                            className="block w-full text-gray-900 border border-gray-300 rounded-lg bg-gray-100 p-2"
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Questions Section */}
                            {pageOneQuestions.map((question, index) => (
                                <div key={question.id} className={`mb-4 p-4 ${index % 2 === 0 ? 'bg-blue-50' : 'bg-gray-50'} rounded-md shadow-sm`}>
                                    <label className='block text-sm md:text-md font-semibold mb-2'>{question.text}</label>
                                    {renderInput(question)}
                                </div>
                            ))}
                        </>
                    }

                    {page === 2 &&
                        <>
                            {pageTwoQuestions.map((question, index) => (
                                <div key={question.id} className={`mb-4 p-4 ${index % 2 === 0 ? 'bg-blue-50' : 'bg-gray-50'} rounded-md shadow-sm`}>
                                    <label className='block text-sm md:text-md font-semibold mb-2'>{question.text}</label>
                                    {renderInput(question)}
                                </div>
                            ))}
                        </>
                    }

                    {/* Submit Button */}
                    {renderActionButton()}
                </div>
            </div>
        </div>
    );
};