import React, { useState, useEffect } from "react";
import { CreateQuoteRequest, RetrieveQuoteRequests, RetrieveQuoteRequestsCount } from "../../services/quote-requests";
import Select from "react-select";
import { Loading } from "../misc/loading";
import { Address, IContact, InstallationType, IQuoteRequest, IQuoteRequestFormCallParams, QuoteRequestOrigin } from "@common.abstractions";
import { exists, formatBuildingType, formatProductLine, GetDate, getGoogleMapsUrl, handleSort, last, max, USStates, where } from "@common.tools";
import { Between, Equals, Exists, IPaginatedResourceResponse, Not } from "@common.api";
import TopBar from "../misc/top-bar/TopBar";
import SearchBar from "../misc/search-bar/SearchBar";
import Button from "../misc/button/Button";
import { Table, TableHeader, TableRow, TableRowWrapper, TableTabBar } from "../misc/table";
import { Page } from "../misc/layout";
import { useForm } from "react-hook-form";
import { Modal } from "../misc/modal";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactLoading from "react-loading";

enum QuoteRequestTab {
    ALL = "ALL",
    PROCESSING = "Processing",
    INVALID = "Invalid",
    DIY = "DIY",
    UNSERVICEABLE = "Unserviceable",
    ASSIGNED = "Assigned",
    RETRACTED = "Retracted",
    CONFLICT = "Conflict",
}

export default function QuoteRequestsPage() {
    const [initialLoad, setInitialLoad] = useState<boolean>(false);

    const {
        handleSubmit,
        register,
        getValues,
        setError,
        reset,

        formState: {
            errors,
        },
    } = useForm({ mode: "onChange" });
    const [createQuoteModalOpen, setCreatequoteModalOpen] = useState<boolean>(false);

    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const [selectedTab, setSelectedTab] = useState(QuoteRequestTab.ALL);
    const [timeFrame, setTimeFrame] = useState<{
        start: Date;
        end: Date;
        name: string;
    }>({ start: GetDate.nDaysAgo(30), end: GetDate.tomorrow(), name: "last30Days" });

    const [displayedQuotes, setDisplayedQuotes] = React.useState<IQuoteRequest[]>([])
    const allQuotes = React.useRef<IQuoteRequest[]>([])
    const assignedQuotes = React.useRef<IQuoteRequest[]>([])
    const retractedQuotes = React.useRef<IQuoteRequest[]>([])
    const conflictQuotes = React.useRef<IQuoteRequest[]>([])
    const diyQuotes = React.useRef<IQuoteRequest[]>([])
    const invalidQuotes = React.useRef<IQuoteRequest[]>([])
    const processingQuotes = React.useRef<IQuoteRequest[]>([])
    const unservicableQuotes = React.useRef<IQuoteRequest[]>([])

    const cursor = React.useRef<string>();
    const [loadingQuotes, setLoadingQuotes] = useState<boolean>(false);

    const [allCount, setAllCount] = useState<number>();
    const [assignedCount, setAssignedCount] = useState<number>();
    const [retractedCount, setRetractedCount] = useState<number>();
    const [conflictCount, setConflictCount] = useState<number>();
    const [diyCount, setDIYCount] = useState<number>();
    const [invalidCount, setInvalidCount] = useState<number>();
    const [processingCount, setProcessingCount] = useState<number>();
    const [unserviceableCount, setUnserviceableCount] = useState<number>();

    const clearLoadedQuotes = () => {
        allQuotes.current = [];
        assignedQuotes.current = [];
        retractedQuotes.current = [];
        conflictQuotes.current = [];
        diyQuotes.current = [];
        invalidQuotes.current = [];
        processingQuotes.current = [];
        unservicableQuotes.current = [];
    }

    const clearCounts = () => {
        setAllCount(undefined)
        setAssignedCount(undefined)
        setRetractedCount(undefined)
        setConflictCount(undefined)
        setDIYCount(undefined)
        setInvalidCount(undefined)
        setProcessingCount(undefined)
        setUnserviceableCount(undefined)
    }

    const loadQuoteRequests = async (num: number) => {
        setLoadingQuotes(true);
        console.log(`Loading ${num} more leads`);

        if (allQuotes.current.length && !cursor.current) {
            console.log('no more quotes');
            return;
        }

        const response: IPaginatedResourceResponse<IQuoteRequest> | undefined = await RetrieveQuoteRequests(
            {
                filters: {
                    createdAt: Between(timeFrame.start, timeFrame.end)
                },
                relations: ['contact', 'leads', 'leads.company', 'autoAssignment', 'autoAssignment.captureCandidates', 'autoAssignment.exclusiveCandidates']
            },
            {
                direction: 'forward',
                count: num,
                cursor: cursor.current
            });

        if (!response) {
            console.warn('No response from load quotes call')
            setLoadingQuotes(false);
            return;
        }

        if (!response.pagination) {
            console.error('Response missing pagination information');
            return;
        }

        cursor.current = response.pagination.end_cursor;
        const retrievedQuotes = response.values;

        retrievedQuotes.sort((a, b) => {
            const varA = new Date(a.createdAt).getTime();
            const varB = new Date(b.createdAt).getTime();
            return varB - varA;
        })

        const all: IQuoteRequest[] = [...(allQuotes.current ?? []), ...retrievedQuotes]; // may duplicate rows in rare instances. TODO: Fix this eventually
        const assigned: IQuoteRequest[] = assignedQuotes.current ?? []
        const retracted: IQuoteRequest[] = retractedQuotes.current ?? [];
        const conflict: IQuoteRequest[] = conflictQuotes.current ?? [];
        const diy: IQuoteRequest[] = diyQuotes.current ?? [];
        const invalid: IQuoteRequest[] = invalidQuotes.current ?? [];
        const processing: IQuoteRequest[] = processingQuotes.current ?? [];
        const unservicable: IQuoteRequest[] = unservicableQuotes.current ?? [];

        retrievedQuotes.forEach((quote) => {
            if (quote.leads?.find((quote) => !quote.deletedAt)) {
                assigned.push(quote);
                return;
            }
            if (quote.installationType === InstallationType.DIY) {
                diy.push(quote);
                return;
            }
            if (!quote.address.geoPoint) {
                invalid.push(quote);
                return;
            }

            if (quote.autoAssignment) {
                const lastAutoAssignment = max(quote.autoAssignment, (a, b) => a.createdAt > b.createdAt);

                if (quote.leads) {
                    const lastLead = max(quote.leads, (a, b) => a.createdAt > b.createdAt)
                    if (lastLead?.deletedAt && lastAutoAssignment?.completedAt && lastAutoAssignment.completedAt < lastLead.deletedAt) {
                        retracted.push(quote);
                        return;
                    }
                }

                if (lastAutoAssignment?.captureCandidates && lastAutoAssignment.captureCandidates.length > 1 && lastAutoAssignment.exclusiveCandidates?.length !== 1) {
                    conflict.push(quote);
                    return;
                }

                if (!lastAutoAssignment?.completedAt) {
                    processing.push(quote);
                    return;
                }

                if (lastAutoAssignment && lastAutoAssignment.completedAt && !lastAutoAssignment.captureCandidates?.length) {
                    unservicable.push(quote);
                    return;
                }
            }
        })

        allQuotes.current = all;
        assignedQuotes.current = assigned;
        conflictQuotes.current = conflict;
        diyQuotes.current = diy;
        invalidQuotes.current = invalid;
        processingQuotes.current = processing;
        unservicableQuotes.current = unservicable;

        setDisplayedQuotesFromTab(selectedTab);
        setLoadingQuotes(false);
    }

    const loadCounts = async () => {
        const allCount = await RetrieveQuoteRequestsCount({
            createdAt: Between(timeFrame.start, timeFrame.end),
        })
        const assignedCount = await RetrieveQuoteRequestsCount({
            createdAt: Between(timeFrame.start, timeFrame.end),
            vStatus: "assigned"
        })

        const retractedCount = await RetrieveQuoteRequestsCount({
            createdAt: Between(timeFrame.start, timeFrame.end),
            vStatus: "retracted"
        })

        const conflictCount = await RetrieveQuoteRequestsCount({
            createdAt: Between(timeFrame.start, timeFrame.end),
            vStatus: "conflict"
        })

        const diyCount = await RetrieveQuoteRequestsCount({
            createdAt: Between(timeFrame.start, timeFrame.end),
            installationType: Equals(InstallationType.DIY)
        })

        const invalidCount = await RetrieveQuoteRequestsCount({
            createdAt: Between(timeFrame.start, timeFrame.end),
            vStatus: "invalid"
        })

        const processingCount = await RetrieveQuoteRequestsCount({
            createdAt: Between(timeFrame.start, timeFrame.end),
            vStatus: "processing"
        })

        const unservicableCount = await RetrieveQuoteRequestsCount({
            createdAt: Between(timeFrame.start, timeFrame.end),
            vStatus: "unserviceable"
        })

        setAllCount(allCount);
        setAssignedCount(assignedCount);
        setRetractedCount(retractedCount);
        setConflictCount(conflictCount);
        setDIYCount(diyCount);
        setInvalidCount(invalidCount);
        setProcessingCount(processingCount);
        setUnserviceableCount(unservicableCount);
    }

    const setDisplayedQuotesFromTab = async (tab: QuoteRequestTab) => {
        switch (tab) {
            case QuoteRequestTab.ALL:
                setDisplayedQuotes(allQuotes.current);
                break;
            case QuoteRequestTab.ASSIGNED:
                setDisplayedQuotes(assignedQuotes.current);
                break;
            case QuoteRequestTab.RETRACTED:
                setDisplayedQuotes(retractedQuotes.current);
                break;
            case QuoteRequestTab.CONFLICT:
                setDisplayedQuotes(conflictQuotes.current);
                break;
            case QuoteRequestTab.DIY:
                setDisplayedQuotes(diyQuotes.current);
                break;
            case QuoteRequestTab.INVALID:
                setDisplayedQuotes(invalidQuotes.current);
                break;
            case QuoteRequestTab.PROCESSING:
                setDisplayedQuotes(processingQuotes.current);
                break;
            case QuoteRequestTab.UNSERVICEABLE:
                setDisplayedQuotes(unservicableQuotes.current);
                break;
        }
    }

    const searchFilter = (entity: IQuoteRequest) => {
        const s = searchCriteria.toLowerCase();
        const contact = entity.contact as IContact;
        const address = entity.address as Address;
        if (
            searchCriteria === "" ||
            (contact.firstname + " " + contact.lastname).toLowerCase().includes(s) ||
            contact.phone.toLowerCase().includes(s) ||
            address.city.toLowerCase().includes(s) ||
            address.zip.toLowerCase().includes(s) ||
            address.streetAddress.toLowerCase().includes(s) ||
            address.state.toLowerCase().includes(s)
        ) {
            return true;
        }
        return false;
    };

    const createQuoteRequest = async (values: any) => {
        const params: IQuoteRequestFormCallParams = {
            id: null,
            email: values.email,
            firstname: values.firstname,
            lastname: values.lastname,
            phone: values.phone,
            city: values.city,
            country: "United States",
            state: values.state,
            streetAddress: values.streetAddress,
            zip: values.zip,
            sendEmail: true,
        };

        const response = await CreateQuoteRequest(params);
        if (!response) {
            toast.error("Unable to create Quote Request");
            return;
        }

        allQuotes.current = [response, ...allQuotes.current];

        toast("Added successfully.");
        loadCounts();
        closeCreateQuoteModal();
        setDisplayedQuotesFromTab(selectedTab);
    };

    const closeCreateQuoteModal = () => {
        setCreatequoteModalOpen(false);
    };

    useEffect(() => {
        cursor.current = undefined;
        clearLoadedQuotes();
        loadQuoteRequests(70);
        loadCounts();
    }, [timeFrame])

    useEffect(() => {
        setDisplayedQuotesFromTab(selectedTab);
    }, [selectedTab])

    return (
        <Page>
            <TopBar title="Quote Requests">
                <Button className="mr-2 text-sm" type="button" onClick={() => setCreatequoteModalOpen(true)} color="blue" style="outline" rounded>
                    Create Quote Request
                </Button>
                <SearchBar className="mx-8 w-96" search={setSearchCriteria} placeholder="Search Quote Requests" />
                <Select
                    className="quotes_date_dropdown"
                    defaultValue={{
                        value: {
                            start: GetDate.nDaysAgo(30),
                            end: GetDate.tomorrow(),
                            name: "last30Days",
                        },
                        label: "Last 30 Days",
                    }}
                    options={[
                        {
                            value: {
                                start: new Date(),
                                end: new Date(),
                                name: "custom",
                            },
                            label: "Custom Range",
                        },
                        {
                            value: {
                                start: GetDate.january(),
                                end: GetDate.tomorrow(),
                                name: "yearToDate",
                            },
                            label: "Year to Date",
                        },
                        {
                            value: {
                                start: GetDate.nDaysAgo(30),
                                end: GetDate.tomorrow(),
                                name: "last30Days",
                            },
                            label: "Last 30 Days",
                        },
                        {
                            value: {
                                start: GetDate.currentMonth(),
                                end: GetDate.tomorrow(),
                                name: "monthToDate",
                            },
                            label: "Month to Date",
                        },
                        {
                            value: {
                                start: GetDate.january(2023),
                                end: GetDate.endOfYear(2023),
                                name: "2023"
                            },
                            label: '2023'
                        },
                        {
                            value: {
                                start: GetDate.january(2022),
                                end: GetDate.endOfYear(2022),
                                name: "2022"
                            },
                            label: '2022'
                        },
                        {
                            value: {
                                start: GetDate.january(2021),
                                end: GetDate.endOfYear(2021),
                                name: "2021"
                            },
                            label: '2021'
                        },
                        {
                            value: {
                                start: GetDate.everlightsBirth(),
                                end: GetDate.tomorrow(),
                                name: "allTime",
                            },
                            label: "All Time",
                        },
                        {
                            value: {
                                start: GetDate.january(),
                                end: GetDate.february(),
                                name: "january",
                            },
                            label: "January",
                        },
                        {
                            value: {
                                start: GetDate.february(),
                                end: GetDate.march(),
                                name: "february",
                            },
                            label: "February",
                        },
                        {
                            value: {
                                start: GetDate.march(),
                                end: GetDate.april(),
                                name: "march",
                            },
                            label: "March",
                        },
                        {
                            value: {
                                start: GetDate.april(),
                                end: GetDate.may(),
                                name: "april",
                            },
                            label: "April",
                        },
                        {
                            value: {
                                start: GetDate.may(),
                                end: GetDate.june(),
                                name: "may",
                            },
                            label: "May",
                        },
                        {
                            value: {
                                start: GetDate.june(),
                                end: GetDate.july(),
                                name: "june",
                            },
                            label: "June",
                        },
                        {
                            value: {
                                start: GetDate.july(),
                                end: GetDate.august(),
                                name: "july",
                            },
                            label: "July",
                        },
                        {
                            value: {
                                start: GetDate.august(),
                                end: GetDate.september(),
                                name: "august",
                            },
                            label: "August",
                        },
                        {
                            value: {
                                start: GetDate.september(),
                                end: GetDate.october(),
                                name: "september",
                            },
                            label: "September",
                        },
                        {
                            value: {
                                start: GetDate.october(),
                                end: GetDate.november(),
                                name: "october",
                            },
                            label: "October",
                        },
                        {
                            value: {
                                start: GetDate.november(),
                                end: GetDate.december(),
                                name: "november",
                            },
                            label: "November",
                        },
                        {
                            value: {
                                start: GetDate.december(),
                                end: GetDate.nextJanuary(),
                                name: "december",
                            },
                            label: "December",
                        },
                    ]}
                    onChange={
                        (val) => {
                            if (val?.value.name === "custom") {
                                setTimeFrame({ start: timeFrame.start, end: timeFrame.end, name: 'custom' });
                                clearCounts()

                                return;
                            }
                            setTimeFrame(val!.value);
                            clearCounts()
                        }
                    }
                />
            </TopBar>

            {timeFrame.name === 'custom' &&
                <div className="flex justify-end space-x-4">
                    <input
                        type="date"
                        className="flex border hover:border-input_hover text-center"
                        defaultValue={timeFrame.start.toISOString().split("T")[0]}
                        onBlur={(e) => {
                            const date = new Date(e.target.value).getTime();

                            if (isNaN(date)) {
                                return;
                            }
                            setTimeFrame({ start: new Date(date), end: timeFrame.end, name: 'custom' })
                        }}
                    />
                    <input
                        type="date"
                        className="flex border hover:border-input_hover text-center"
                        defaultValue={timeFrame.end.toISOString().split("T")[0]}
                        onBlur={(e) => {
                            const date = new Date(e.target.value).getTime();

                            if (isNaN(date)) {
                                return;
                            }
                            setTimeFrame({ start: timeFrame.start, end: new Date(date), name: 'custom' })
                        }}
                    />
                </div>
            }

            <TableTabBar
                tabOptions={{
                    tabs: [
                        { name: QuoteRequestTab.ALL, quantity: allCount === undefined ? <ReactLoading height={15} width={15} type="spin" /> : allCount },
                        { name: QuoteRequestTab.ASSIGNED, quantity: assignedCount === undefined ? <ReactLoading height={15} width={15} type="spin" /> : assignedCount },
                        { name: QuoteRequestTab.RETRACTED, quantity: retractedCount === undefined ? <ReactLoading height={15} width={15} type="spin" /> : retractedCount },
                        { name: QuoteRequestTab.CONFLICT, quantity: conflictCount === undefined ? <ReactLoading height={15} width={15} type="spin" /> : conflictCount },
                        { name: QuoteRequestTab.DIY, quantity: diyCount === undefined ? <ReactLoading height={15} width={15} type="spin" /> : diyCount },
                        { name: QuoteRequestTab.INVALID, quantity: invalidCount === undefined ? <ReactLoading height={15} width={15} type="spin" /> : invalidCount },
                        { name: QuoteRequestTab.PROCESSING, quantity: processingCount === undefined ? <ReactLoading height={15} width={15} type="spin" /> : processingCount },
                        { name: QuoteRequestTab.UNSERVICEABLE, quantity: unserviceableCount === undefined ? <ReactLoading height={15} width={15} type="spin" /> : unserviceableCount },
                    ],
                    active: selectedTab,
                    onClick: setSelectedTab,
                }}
            />

            <div id="table-scroll-div" className="h-full w-full max-w-full overflow-auto relative">
                <InfiniteScroll
                    dataLength={displayedQuotes?.length ?? 0}
                    next={() => loadQuoteRequests(300)}
                    hasMore={!!cursor.current}
                    loader={
                        <p style={{ textAlign: "center" }}>
                            {loadingQuotes
                                ? <b>Loading more quotes...</b>
                                : <Button
                                    className="mx-auto my-8"
                                    type="button"
                                    color="blue"
                                    style="outline"
                                    rounded
                                    onClick={() => loadQuoteRequests(300)}
                                >
                                    Load More
                                </Button>
                            }
                        </p>
                    }
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            <b>No more quotes</b>
                        </p>

                    }
                    scrollThreshold={"80px"}
                    scrollableTarget={"table-scroll-div"}
                >
                    <table className="text-left w-full relative border-collapse flex-grow">
                        <TableHeader
                            data={[
                                { title: "Submitted" },
                                { title: "Source" },
                                { title: "Contact" },
                                { title: "Address" },
                                { title: "Owner" },
                                { title: "Building Type" },
                                { title: "Product Line" },
                                { title: "" },
                            ]}
                        />
                        <TableRowWrapper>
                            {displayedQuotes?.length
                                ? displayedQuotes.map(
                                    (quoteRequest, i) =>
                                        searchFilter(quoteRequest) && (
                                            <TableRow
                                                key={`quote request key ${i}`}
                                                data={[
                                                    { text: { title: new Date(quoteRequest.createdAt).toLocaleDateString() } },
                                                    {
                                                        text: {
                                                            title: quoteRequest.origin === QuoteRequestOrigin.DEALER_MANUAL_ENTRY ? "Manual Entry" : "EverLights",
                                                        },
                                                    },
                                                    {
                                                        text: {
                                                            title: quoteRequest.contact ? `${quoteRequest.contact.firstname} ${quoteRequest.contact.lastname}` : "",
                                                            subtitle: [
                                                                quoteRequest.contact ? quoteRequest.contact.email : undefined,
                                                                quoteRequest.contact ? quoteRequest.contact.phone : undefined,
                                                            ],
                                                        },
                                                    },
                                                    {
                                                        text: {
                                                            title: `${quoteRequest.address.streetAddress}, ${quoteRequest.address.city}, ${quoteRequest.address.state} ${quoteRequest.address.zip}`,
                                                        },
                                                    },
                                                    {
                                                        text: {
                                                            title: quoteRequest.leads?.find((el) => !el.deletedAt)?.company
                                                                ? quoteRequest.leads?.find((el) => !el.deletedAt)!.company!.name
                                                                : "No Owner",
                                                        },
                                                    },
                                                    {
                                                        text: {
                                                            title: quoteRequest.buildingType ? formatBuildingType(quoteRequest.buildingType) : "",
                                                        },
                                                    },
                                                    {
                                                        text: {
                                                            title: quoteRequest.productLines
                                                                ? quoteRequest.productLines.map((pl) => formatProductLine(pl)).join(", ")
                                                                : "",
                                                        },
                                                    },
                                                    {
                                                        text: {
                                                            title: "Quote Request",
                                                            color: "blue",
                                                            to: `quoteRequests/${quoteRequest.id}`
                                                        },
                                                    },
                                                ]}
                                            />
                                        )
                                )
                                : null}
                        </TableRowWrapper>
                    </table>

                </InfiniteScroll>
            </div>

            <Modal visible={createQuoteModalOpen} closeModal={closeCreateQuoteModal}>
                <form onSubmit={handleSubmit(createQuoteRequest)} className="flex flex-col w-full h-full">
                    <div className="flex justify-between w-full">
                        <div>
                            <h1 className="text-gray-500 text-uppercase text-2xl mb-5">New Quote Request</h1>
                        </div>
                        <div></div>
                    </div>
                    <div className="flex w-full mb-2">
                        <div className="flex-1 flex flex-col mr-5">
                            <label className="text-gray-500" htmlFor="firstname">
                                First name
                            </label>
                            <input
                                id="firstname"
                                placeholder="required"
                                className={`input ${errors.firstname && "border-red-500 b-1"}`}
                                type="text"
                                {...register('firstname', { required: true })}
                                autoComplete="given-name" />
                            {errors.firstname && errors.firstname.type === "required" && (
                                <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>
                            )}
                        </div>

                        <div className="flex-1 flex flex-col">
                            <label className="text-gray-500" htmlFor="lastname">
                                Last name
                            </label>
                            <input
                                id="lastname"
                                placeholder="required"
                                className={`input ${errors.lastname && "border-red-500 b-1"}`}
                                type="text"
                                {...register('lastname', { required: true })}
                                autoComplete="family-name" />
                            {errors.lastname && errors.lastname.type === "required" && (
                                <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>
                            )}
                        </div>
                    </div>

                    <div className="flex w-full mb-2">
                        <div className="flex-1 flex flex-col mr-5">
                            <label className="text-gray-500" htmlFor="phone">
                                Phone number
                            </label>
                            <input
                                id="phone"
                                placeholder="required"
                                className={`input ${errors.phone && "border-red-500 b-1"}`}
                                type="tel"
                                {...register('phone', {
                                    required: true,
                                    validate: (val) => val?.match(/\d+/g)?.join("")?.length === 10,
                                })}
                                autoComplete="tel" />
                            {errors.phone && errors.phone.type === "required" && <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>}
                            {errors.phone && errors.phone.type === "validate" && <div className="text-red-500 text-sm mt-1 ml-1">Phone number is invalid</div>}
                        </div>

                        <div className="flex-1 flex flex-col">
                            <label className="text-gray-500" htmlFor="email">
                                Email
                            </label>
                            <input
                                id="email"
                                placeholder="required"
                                className={`input ${errors.email && "border-red-500 b-1"}`}
                                type="email"
                                {...register('email', {
                                    required: true,
                                    pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                })}
                                autoComplete="email" />
                            {errors.email && errors.email.type === "required" && <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>}
                            {errors.phoemailne && errors.email?.type === "pattern" && (
                                <div className="text-red-500 text-sm mt-1 ml-1">Email address is invalid</div>
                            )}
                        </div>
                    </div>

                    <div className="flex w-full mb-2">
                        <div className="flex-1 flex flex-col mr-5">
                            <label className="text-gray-500" htmlFor="streetAddress">
                                Street address
                            </label>
                            <input
                                id="streetAddress"
                                placeholder="required"
                                className={`input ${errors.streetAddress && "border-red-500 b-1"}`}
                                type="text"
                                {...register('streetAddress', { required: true })}
                                autoComplete="street-address" />
                            {errors.streetAddress && errors.streetAddress.type === "required" && (
                                <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>
                            )}
                        </div>

                        <div className="flex-1 flex flex-col">
                            <label className="text-gray-500" htmlFor="city">
                                City
                            </label>
                            <input
                                id="city"
                                placeholder="required"
                                className={`input ${errors.city && "border-red-500 b-1"}`}
                                type="text"
                                {...register('city', { required: true })}
                                autoComplete="address-level2" />
                            {errors.city && errors.city.type === "required" && <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>}
                        </div>
                    </div>

                    <div className="flex w-full mb-8">
                        <div className="flex-1 flex flex-col mr-5">
                            <label className="text-gray-500" htmlFor="state">
                                State
                            </label>
                            <select
                                className={`input ${errors.state && "border-red-500 b-1"}`}
                                {...register('state', { required: true })}>
                                <option value={undefined} className="text-gray-500">
                                    --Select--
                                </option>
                                {USStates.map((el) => (
                                    <option value={el.abbr}>{el.name}</option>
                                ))}
                            </select>
                            {errors.state && <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>}
                        </div>

                        <div className="flex-1 flex flex-col">
                            <label className="text-gray-500" htmlFor="zip">
                                Postal Code
                            </label>
                            <input
                                id="zip"
                                placeholder="required"
                                className={`input ${errors.zip && "border-red-500 b-1"}`}
                                type="text"
                                {...register('zip', { required: true })}
                                autoComplete="postal-code" />
                            {errors.zip && errors.zip.type === "required" && <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>}
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <button type="button" className="mr-4 btn bg-gray-400 text-white mt-8" onClick={() => closeCreateQuoteModal()}>
                            Cancel
                        </button>
                        <button type="submit" className="btn bg-theme_blue text-white mt-8">
                            Save
                        </button>
                    </div>
                </form>
            </Modal>

            {displayedQuotes ? displayedQuotes.length ? null : <div className="text-gray-500 inset-center top-72">No Quote Requests</div> : <Loading center />}
        </Page>
    );
}
