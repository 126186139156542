import { CompanyType, ITotalSpent } from "@common.abstractions";
import { calcPercentage, formatMoney } from "@common.tools";
import { faArrowDown, faArrowUp, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RetrieveTotalSpent } from "@frontend/services/analytics";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

type Props = {
    timeFrame: {
        start: Date;
        end: Date;
    };
    titleSubstrLength: number;
    filterEverlights: boolean;
    filterBestToWorst: boolean;
    previewSize?: number;
    detailedView?: boolean;
    onClick?: () => void;
};

export default function TotalSpent(props: Props) {
    const { timeFrame, titleSubstrLength, filterEverlights, filterBestToWorst, previewSize, detailedView, onClick } = props;

    const [totalSpent, setTotalSpent] = useState<ITotalSpent[]>();
    const [filteredTotalSpent, setFilteredTotalSpent] = useState<ITotalSpent[]>();
    const [totalSpentTotals, setTotalSpentTotals] = useState<{ totalSpent: number; totalPercentTrend: number; trendGrowth: boolean }>();
    const [sortAsc, setSortAsc] = useState<boolean>(false);

    const sortByName = () => {
        if (sortAsc === false) {
            let filteredCompanies = filteredTotalSpent;

            filteredCompanies?.sort((a, b) => {
                let x = a.companyName.toLowerCase();
                let y = b.companyName.toLowerCase();
                if (x < y) {
                    return -1
                }
                if (x > y) {
                    return 1
                }
                return 0

            })

            setFilteredTotalSpent(filteredCompanies);
            setSortAsc(true)

            return;
        }

        let filteredCompanies = filteredTotalSpent;

        filteredCompanies?.sort((a, b) => {
            let x = a.companyName.toLowerCase();
            let y = b.companyName.toLowerCase();
            if (x < y) {
                return 1
            }
            if (x > y) {
                return -1
            }
            return 0
        })

        setSortAsc(false);
        setFilteredTotalSpent(filteredCompanies);
    }

    const loadTotalSpent = async () => {
        let response = await RetrieveTotalSpent(timeFrame);

        if (response) {
            let tempTotalSpentCurrent: number = 0;
            let tempTotalSpentPrevious: number = 0;
            let tempTotalPercentTrend: number = 0;
            let tempTrendGrowth: boolean;

            response.map((el) => {
                tempTotalSpentCurrent += el.current.totalSpent;
                tempTotalSpentPrevious += el.previous.totalSpent;
            });

            tempTotalSpentCurrent = Number(tempTotalSpentCurrent.toFixed(2));
            tempTotalSpentPrevious = Number(tempTotalSpentPrevious.toFixed(2));

            tempTotalPercentTrend = Math.abs(calcPercentage(Number((tempTotalSpentCurrent - tempTotalSpentPrevious).toFixed(2)), tempTotalSpentCurrent));

            tempTrendGrowth = tempTotalSpentCurrent - tempTotalSpentPrevious > 0 ? true : false;

            const tempTotalSpentTotals: { totalSpent: number, totalPercentTrend: number, trendGrowth: boolean } = {
                totalSpent: tempTotalSpentCurrent,
                totalPercentTrend: tempTotalPercentTrend,
                trendGrowth: tempTrendGrowth,
            };

            setTotalSpent(response);
            setTotalSpentTotals(tempTotalSpentTotals);

            if (!filterEverlights) {
                response = response.filter((el) => el.companyType !== CompanyType.BRANCH);
            }

            if (filterBestToWorst) {
                response.sort((a, b) => {
                    return b.current.totalSpent - a.current.totalSpent;
                });
            } else {
                response.sort((a, b) => {
                    return a.current.totalSpent - b.current.totalSpent;
                });
            }

            if (previewSize && !detailedView) {
                setFilteredTotalSpent(response.slice(0, previewSize));
            } else {
                setFilteredTotalSpent(response.slice());
            }
            return;
        }

        toast.error("Error loading Total Spent");
    };
    const sortTotalSpent = () => {
        if (!totalSpent) return;
        let tempTotalSpent = totalSpent;

        if (!filterEverlights) {
            tempTotalSpent = tempTotalSpent.filter((el) => el.companyType !== CompanyType.BRANCH);
        }

        if (filterBestToWorst) {
            tempTotalSpent = tempTotalSpent.sort((a, b) => {
                return b.current.totalSpent - a.current.totalSpent;
            });
        } else {
            tempTotalSpent = tempTotalSpent.sort((a, b) => {
                return a.current.totalSpent - b.current.totalSpent;
            });
        }

        if (previewSize && !detailedView) {
            setFilteredTotalSpent(tempTotalSpent.slice(0, previewSize));
        } else {
            setFilteredTotalSpent(tempTotalSpent.slice());
        }
    };

    useEffect(() => {
        loadTotalSpent();
    }, [timeFrame]);

    useEffect(() => {
        sortTotalSpent();
    }, [filterBestToWorst, filterEverlights]);

    return !detailedView ? (
        <div
            onClick={onClick}
            className="p-5 shadow-md hover:shadow-lg duration-theme h-60 opacity-70 hover:opacity-100 cursor-pointer"
        >
            <div className="text-gray-500 mb-3">Total Spent</div>
            <div className="flex items-end mb-4">
                <div className="text-3xl text-gray-700 font-bold">{totalSpentTotals?.totalSpent && formatMoney(totalSpentTotals?.totalSpent)}</div>
                <div className="flex items-center ml-2 ">
                    {totalSpentTotals?.trendGrowth ? (
                        <>
                            <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                            <span className="ml-1 text-theme_green">{totalSpentTotals?.totalPercentTrend}%</span>
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon className="text-xs text-theme_red" icon={faArrowDown} />
                            <span className="ml-1 text-theme_red">{totalSpentTotals?.totalPercentTrend}%</span>
                        </>
                    )}
                </div>
            </div>
            {filteredTotalSpent ? (
                filteredTotalSpent.map((el, i) => {
                    return (
                        <div key={el.companyId} className={`flex flex-col border-gray-200 ${i === filteredTotalSpent.length - 1 ? "" : "border-b"}`}>
                            <div className="flex justify-between py-2">
                                <div className="text-gray-600">
                                    {el.companyName.length > titleSubstrLength ? el.companyName.substring(0, titleSubstrLength) + " . . ." : el.companyName}
                                </div>
                                <div className="flex">
                                    <div className="inline-flex items-center mr-3 font-semibold text-gray-700">{formatMoney(el.current.totalSpent)}</div>
                                    <div className="inline-flex items-center w-14">
                                        {el.current.totalSpent - el.previous.totalSpent > 0 ? (
                                            <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                        ) : (
                                            <FontAwesomeIcon className="text-xs text-theme_red" icon={faArrowDown} />
                                        )}
                                        <span className="ml-1 text-gray-500">
                                            {Math.abs(
                                                calcPercentage(Number((el.current.totalSpent - el.previous.totalSpent).toFixed(2)), el.current.totalSpent)
                                            )}
                                            %
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div>Loading...</div>
            )}
        </div>
    ) : (
        <div className="h-full flex flex-col">
            <div className="text-gray-500 mb-3">Total Spent</div>
            <div className="flex items-end mb-4">
                <div className="text-3xl text-gray-700 font-bold">{totalSpentTotals?.totalSpent && formatMoney(totalSpentTotals?.totalSpent)}</div>
                <div className="flex items-center ml-2 ">
                    {totalSpentTotals?.trendGrowth ? (
                        <>
                            <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                            <span className="ml-1 text-theme_green">{totalSpentTotals?.totalPercentTrend}%</span>
                        </>
                    ) : (
                        <>
                            <FontAwesomeIcon className="text-xs text-theme_red" icon={faArrowDown} />
                            <span className="ml-1 text-theme_red">{totalSpentTotals?.totalPercentTrend}%</span>
                        </>
                    )}
                </div>
            </div>

            {filteredTotalSpent ? (
                <>
                    <div className="p-2 mb-4 flex border-b border-gray-300">
                        <div className="text-gray-600 text-lg w-80">Name
                            <FontAwesomeIcon className="cursor-pointer" icon={faSort} onClick={sortByName} />
                        </div>
                        <div className="text-gray-600 text-lg flex-1">Type</div>
                        <div className="text-gray-600 text-lg flex-1">Spent</div>
                    </div>
                    <div className="flex flex-col overflow-y-scroll h-full">
                        {filteredTotalSpent.map((el, i) => {
                            return (
                                <Link
                                    to={`/companies/${el.companyId}`}
                                    key={el.companyId}
                                    className={`flex flex-col hover:bg-gray-200 border-gray-100 duration-200 ${i === filteredTotalSpent.length - 1 ? "" : "border-b"
                                        }`}
                                >
                                    <div className="flex justify-between p-2">
                                        <div className="text-gray-600 w-80">
                                            {el.companyName.length > titleSubstrLength
                                                ? el.companyName.substring(0, titleSubstrLength) + " . . ."
                                                : el.companyName}
                                        </div>
                                        <div className="text-gray-600 flex-1 capitalize">{el.companyType}</div>
                                        <div className="flex flex-1">
                                            <div className="inline-flex items-center mr-3 font-semibold text-gray-700">
                                                {formatMoney(el.current.totalSpent)}
                                            </div>
                                            <div className="inline-flex items-center w-14">
                                                {el.current.totalSpent - el.previous.totalSpent > 0 ? (
                                                    <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                                ) : (
                                                    <FontAwesomeIcon className="text-xs text-theme_red" icon={faArrowDown} />
                                                )}
                                                <span className="ml-1 text-gray-500">
                                                    {(Math.abs(
                                                        calcPercentage(
                                                            Number((el.current.totalSpent - el.previous.totalSpent).toFixed(2)),
                                                            el.current.totalSpent
                                                        )
                                                    )).toFixed(2)}
                                                    %
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
}
