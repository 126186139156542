import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ManualMarkLeadConverted, ManualMarkLeadLost, ManualRecoverLostLead, MarkLeadViewed, RetrieveLead, SendThirdPartyContract } from "../../../services/leads";
import Viewer from "react-viewer";
import { CreateOrder } from "@frontend/services/orders";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import {
    BuildingType,
    Scope,
    ICompany,
    ILead,
    InstallationType,
    int,
    IOrder,
    IQuoteRequest,
    IUpdateQuoteRequestParams,
    IWarranty,
    ProductLine,
} from "@common.abstractions";
import { formatBuildingType, formatInstallationType, formatPhoneNumber, formatProductLine, getGoogleMapsUrl } from "@common.tools";
import AuthContext from "@frontend/contexts/AuthContext";
import { Page } from "@frontend/components/misc/layout/Page";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import { Table } from "@frontend/components/misc/table";
import Button from "@frontend/components/misc/button/Button";
import { RetrieveQuoteRequestImageBlob, UpdateQuoteRequest } from "@frontend/services/quote-requests";
import { MultiSelect } from "react-multi-select-component";
import { CreateWarranty } from "@frontend/services/warranty";

enum Tab {
    SUBMITTED_ORDERS = "Submitted Order",
    IMAGES = "Images",
    QUOTE_REQUEST = "Quote Request",
    WARRANTY = "Warranty"
}

export default function Lead() {
    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.QUOTE_REQUEST);
    const [editMode, setEditMode] = useState<boolean>();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const params = useParams<{ leadId: string }>();
    const [leadId] = useState<int>(int.parse(params.leadId));
    const [lead, setLead] = useState<ILead>();
    const [quoteRequest, setQuoteRequest] = React.useState<IQuoteRequest>();
    const [openOrder, setOpenOrder] = useState<IOrder>();
    const [closedOrders, setClosedOrders] = useState<IOrder[]>();
    const [noOpenOrders, setNoOpenOrders] = useState<boolean>(false);
    const [hasStripeId, setHasStripeId] = useState<boolean>(false);
    const [isLoading, setLoading] = React.useState<boolean>(true);
    const [imageContainer, setImageContainer] = useState<HTMLDivElement>();
    const [warranties, setWarranties] = useState<IWarranty[]>();
    const [openWarranty, setOpenWarranty] = useState<IWarranty>();
    const [closedWarranties, setClosedWarranties] = useState<IWarranty[]>();

    const [formBuildingType, setFormBuildingType] = useState<BuildingType>();
    const [formInstallationType, setFormInstallationType] = useState<InstallationType>();
    const [formProductLines, setFormProductLines] = useState<{ value: ProductLine; label: string }[]>();
    const [formQuotedAreas, setFormQuotedAreas] = useState<{ value: string; label: string }[]>();
    const [formAdditionalInfo, setFormAdditionalInfo] = useState<string>();
    const [images, setImages] = useState<{ src: any }[]>();

    function onImageContainer(val: HTMLDivElement) {
        if (!imageContainer && val) setImageContainer(val);
    }

    const loadLead = async () => {
        setLoading(true);
        const response = await RetrieveLead(leadId);

        setLoading(false);
        if (!response) return;

        const warranties = response.warranty?.filter((el) => !el.deletedAt)

        if (warranties) {
            const openWarranty = warranties.find((el) => !el.submittedDate);
            if (openWarranty) {
                setOpenWarranty(openWarranty);
            }
            const closedWarranties = warranties.filter((el) => el.submittedDate);
            closedWarranties?.sort((a, b) => {
                let x = new Date(a.createdAt).getTime();
                let y = new Date(b.createdAt).getTime();
                return x - y;
            })
            setClosedWarranties(closedWarranties);
        }

        setLead(response);
        setQuoteRequest(response.quoteRequest as IQuoteRequest);
        if (response.orders?.length) {
            const openOrder = (response.orders as IOrder[]).find((el) => !el.submittedDate);
            if (openOrder) {
                setOpenOrder(openOrder);
            }

            setClosedOrders(response.orders.filter((el) => el.submittedDate));
        }
        if (!response.viewedDate) {
            if (!response.viewedDate && response.company?.id === authContext?.user?.company?.id) {
                await MarkLeadViewed(leadId);
            }
        }

        if (response.quoteRequest?.productLines) {
            setFormProductLines(
                response.quoteRequest.productLines.map((el) => {
                    switch (el) {
                        case ProductLine.CLASSIC:
                            return {
                                value: ProductLine.CLASSIC,
                                label: "Classic",
                            };
                        case ProductLine.COLOR_CHANGING:
                            return {
                                value: ProductLine.COLOR_CHANGING,
                                label: "Color Changing",
                            };
                    }
                })
            );
        }
        if (response.quoteRequest?.quotedAreas) {
            setFormQuotedAreas(
                response.quoteRequest?.quotedAreas.map((el) => {
                    switch (el) {
                        case "front":
                            return {
                                value: "front",
                                label: "Front Side",
                            };
                        case "back":
                            return {
                                value: "back",
                                label: "Back Side",
                            };
                        case "right":
                            return {
                                value: "right",
                                label: "Right Side",
                            };
                        case "left":
                            return {
                                value: "left",
                                label: "Left Side",
                            };
                        case "other":
                            return {
                                value: "other",
                                label: "Other",
                            };
                        default:
                            return {
                                value: "",
                                label: "",
                            };
                    }
                })
            );
        }
        setFormAdditionalInfo(response.quoteRequest?.additionalInfo ?? undefined);
        setFormBuildingType(response.quoteRequest?.buildingType ?? undefined);
        setFormInstallationType(response.quoteRequest?.installationType ?? undefined);
        setHasStripeId(!!(response.company as ICompany).stripeCustomerId);
    };

    const handleGoToProductCatalog = () => {
        if (openOrder) {
            navigate(`/orders/${openOrder.id}/create`);
        }
    };

    const handleGoToWarrantyReview = (warrantyId: int) => {
        navigate(`/warranty/${warrantyId}`);
    }

    const handleGoToOrderReview = (orderId: int) => {
        if (closedOrders) {
            navigate(`/orders/${orderId}`);
            return;
        }
    };

    const handleWarrantyCreation = async () => {
        if (!openWarranty) {
            const tempWarranty = await CreateWarranty({ leadId: leadId });
            if (tempWarranty) {
                navigate(`/leads/${leadId}/warranty/${tempWarranty.id}`)
                return;
            }
        }
        toast.error("Couldn't create a new warranty, if problem persists, please call us.");
    }

    const goToOpenWarranty = () => {
        if (openWarranty) {
            navigate(`/leads/${leadId}/warranty/${openWarranty.id}`)
        }
    }

    const handleOrderCreation = async () => {
        if (!hasStripeId) {
            alert("Please contact us to get set up for portal payments");
            return;
        }

        if (!openOrder) {
            //if there is no Order without submit date, create order, and go to /orders/:order.id/create
            let tempOrder = await CreateOrder({ leadId: leadId });
            if (tempOrder) {
                navigate(`/orders/${tempOrder.id}/create`);
                return;
            }
            toast.error("Couldn't create a new order, if problem persists, please call us.");
        }
    };

    const handleManualMarkConverted = async () => {
        const response = await ManualMarkLeadConverted(leadId);

        if (!response) {
            toast.error("Couldn't mark as converted");
            return;
        }

        let tempCurrentLead = { ...lead } as ILead;
        tempCurrentLead!.vConverted = true;

        setLead(tempCurrentLead);

        toast("Lead Successfully Marked as Converted");
    };

    const handleManualMarkLost = async () => {
        const response = await ManualMarkLeadLost(leadId);

        if (!response) {
            toast.error("Couldn't mark as lost");
            return;
        }

        let tempCurrentLead = { ...lead } as ILead;
        tempCurrentLead!.manualMarkLostDate = new Date();

        setLead(tempCurrentLead);

        toast("Lead Successfully Marked as Lost");
    };

    const handleRecoverLead = async () => {
        const response = await ManualRecoverLostLead(leadId);

        if (!response) {
            toast.error("Couldn't recover lost lead");
            return;
        }

        let tempCurrentLead = { ...lead } as ILead;
        tempCurrentLead!.manualMarkLostDate = null;

        setLead(tempCurrentLead);

        toast("Lead Successfully Recovered");
    };

    const sendAcknowledgement = async () => {
        const response = await SendThirdPartyContract(leadId);
        if (response) {
            toast.success('3rd party acknowledgement sent');
            loadLead();
            return;
        }
        toast.success('3rd party contract sent');
        loadLead();
    }

    const setContractStatus = () => {
        if (lead?.customerContract?.createdAt && !lead.customerContract.signedDate) return `Contract sent ${new Date(new Date(lead.customerContract.createdAt).setDate(new Date(lead.customerContract.createdAt).getDate())).toLocaleDateString().split("T")[0]}`;
        else if (lead?.customerContract?.signedDate) return `Contract signed ${new Date(new Date(lead.customerContract.createdAt).setDate(new Date(lead.customerContract.createdAt).getDate())).toLocaleDateString().split("T")[0]}`
    }

    const updateQuoteRequest = async () => {
        let productLines: ProductLine[] | undefined = undefined;
        if (formProductLines) {
            productLines = formProductLines.map((el) => el.value);
        }
        let quotedAreas: string[] | undefined = undefined;
        if (formQuotedAreas) {
            quotedAreas = formQuotedAreas.map((el) => el.value);
        }

        const updateParams: IUpdateQuoteRequestParams = {
            buildingType: formBuildingType,
            installationType: formInstallationType,
            productLines,
            quotedAreas,
            additionalInfo: formAdditionalInfo,
        };

        const response = await UpdateQuoteRequest(lead!.quoteRequest!.id, updateParams);
        if (!response) {
            toast.error("Unable to update quote request");
            return;
        }

        setQuoteRequest(response);
        if (response.productLines) {
            setFormProductLines(
                response.productLines.map((el) => {
                    switch (el) {
                        case ProductLine.CLASSIC:
                            return {
                                value: ProductLine.CLASSIC,
                                label: "Classic",
                            };
                        case ProductLine.COLOR_CHANGING:
                            return {
                                value: ProductLine.COLOR_CHANGING,
                                label: "Color Changing",
                            };
                    }
                })
            );
        }
        if (response.quotedAreas) {
            setFormQuotedAreas(
                response.quotedAreas.map((el) => {
                    switch (el) {
                        case "front":
                            return {
                                value: "front",
                                label: "Front Side",
                            };
                        case "back":
                            return {
                                value: "back",
                                label: "Back Side",
                            };
                        case "right":
                            return {
                                value: "right",
                                label: "Right Side",
                            };
                        case "left":
                            return {
                                value: "left",
                                label: "Left Side",
                            };
                        case "other":
                            return {
                                value: "other",
                                label: "Other",
                            };
                        default:
                            return {
                                value: "",
                                label: "",
                            };
                    }
                })
            );
        }

        setEditMode(false);
        toast("Saved");
    };

    const cancelQuoteRequestEdit = () => {
        if (!quoteRequest) return;
        setFormBuildingType(quoteRequest.buildingType ? quoteRequest.buildingType : undefined);
        setFormInstallationType(quoteRequest.installationType ? quoteRequest.installationType : undefined);
        setFormProductLines(
            quoteRequest.productLines
                ? quoteRequest.productLines.map((el) => {
                    switch (el) {
                        case ProductLine.CLASSIC:
                            return {
                                value: ProductLine.CLASSIC,
                                label: "Classic",
                            };
                        case ProductLine.COLOR_CHANGING:
                            return {
                                value: ProductLine.COLOR_CHANGING,
                                label: "Color Changing",
                            };
                    }
                })
                : undefined
        );
        setFormQuotedAreas(
            quoteRequest.quotedAreas
                ? quoteRequest.quotedAreas.map((el) => {
                    switch (el) {
                        case "front":
                            return {
                                value: "front",
                                label: "Front Side",
                            };
                        case "back":
                            return {
                                value: "back",
                                label: "Back Side",
                            };
                        case "right":
                            return {
                                value: "right",
                                label: "Right Side",
                            };
                        case "left":
                            return {
                                value: "left",
                                label: "Left Side",
                            };
                        case "other":
                            return {
                                value: "other",
                                label: "Other",
                            };
                        default:
                            return {
                                value: "",
                                label: "",
                            };
                    }
                })
                : undefined
        );

        setFormAdditionalInfo(quoteRequest.additionalInfo ?? "");
        setEditMode(false);
    };

    const getRequestImages = async () => {
        if (images || !quoteRequest?.images) return;

        const imgs = [];

        for (const image of quoteRequest.images) {
            const res = await RetrieveQuoteRequestImageBlob(image.id);
            if (res) imgs.push(res);
        }

        setImages(imgs.map(i => { return { src: URL.createObjectURL(i) } }));
    };

    const showWarrantyBtn = () => {
        if (authContext?.hasAnyGrant(Scope.Warranty.CREATE)) {
            if (!openWarranty) {
                return (
                    <Button
                        className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4 whitespace-nowrap overflow-hidden text-xs sm:text-sm"
                        onClick={handleWarrantyCreation}
                        style="outline"
                        color="gray"
                        rounded
                    >
                        Create Warranty Claim
                    </Button>
                )
            }
            else {
                return (
                    <Button
                        className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4 whitespace-nowrap overflow-hidden text-xs sm:text-sm"
                        onClick={goToOpenWarranty}
                        style="outline"
                        color="gray"
                        rounded
                    >
                        Open Warranty
                    </Button>
                )
            }
        }
    }

    useEffect(() => {
        loadLead();
    }, []);

    useEffect(() => {
        if (selectedTab === Tab.IMAGES) getRequestImages();
    }, [selectedTab]);

    if (isLoading) return <div className="inset-center text-gray-500">Loading...</div>;

    if (!quoteRequest) return <div className="inset-center text-gray-500">Quote request not found!</div>;

    return (
        <Page>
            <TopBar
                title="Leads"
                subtitle={
                    quoteRequest?.contact
                        ? ` from ${quoteRequest.contact.firstname} ${quoteRequest.contact.lastname
                        }${lead?.company?.name
                            ? ", assigned to " + lead.company.name
                            : ""
                        }`
                        : undefined
                }
            >
                {lead && (
                    <>
                        {authContext?.hasAnyGrant(Scope.Lead.MANUAL_MARK_LOST) &&
                            !lead.vConverted &&
                            (lead.manualMarkLostDate ? (
                                <Button
                                    onClick={handleRecoverLead}
                                    type="button"
                                    style="outline"
                                    rounded
                                    color="yellow"
                                    className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4 whitespace-nowrap overflow-hidden text-xs sm:text-sm"
                                >
                                    Recover Lead
                                </Button>
                            ) : lead.orders?.find(
                                (el) => !el.deletedAt && el.invoiceProcessedDate
                            ) ? null : (
                                <Button
                                    onClick={handleManualMarkLost}
                                    type="button"
                                    style="outline"
                                    rounded
                                    color="yellow"
                                    className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4 whitespace-nowrap overflow-hidden text-xs sm:text-sm"
                                >
                                    Mark Lead as Lost
                                </Button>
                            ))}

                        {authContext?.hasAnyGrant(Scope.Lead.MANUAL_MARK_CONVERTED) &&
                            !lead.manualMarkLostDate &&
                            !lead.vConverted && (
                                <Button
                                    onClick={handleManualMarkConverted}
                                    type="button"
                                    style="outline"
                                    rounded
                                    color="blue"
                                    className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4 whitespace-nowrap overflow-hidden text-xs sm:text-sm"
                                >
                                    Manually Convert Lead
                                </Button>
                            )}

                        {!lead.customerContract ? (
                            <Button
                                onClick={sendAcknowledgement}
                                className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4 whitespace-nowrap overflow-hidden text-xs sm:text-sm"
                                style="outline"
                                color="green"
                                rounded
                            >
                                Send Acknowledgement
                            </Button>
                        ) : (
                            <h2 className="sm:w-auto mb-2 sm:mb-0 sm:mr-4 whitespace-nowrap overflow-hidden text-xs sm:text-sm text-theme_green italic">{setContractStatus()}</h2>
                        )}

                        {/* {showWarrantyBtn()} */}

                        {!openOrder ? (
                            <Button
                                onClick={handleOrderCreation}
                                type="button"
                                style="solid"
                                rounded
                                color="blue"
                                className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4 whitespace-nowrap overflow-hidden text-xs sm:text-sm"
                            >
                                Create Order
                            </Button>
                        ) : (
                            <Button
                                onClick={handleGoToProductCatalog}
                                type="button"
                                style="solid"
                                rounded
                                color="blue"
                                className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4 whitespace-nowrap overflow-hidden text-xs sm:text-sm"
                            >
                                Open Order
                            </Button>
                        )}
                    </>
                )}
            </TopBar>

            <Table
                tabOptions={{
                    tabs: [
                        { name: Tab.QUOTE_REQUEST },
                        { name: Tab.IMAGES },
                        { name: Tab.SUBMITTED_ORDERS },
                        { name: Tab.WARRANTY },
                    ],
                    active: selectedTab,
                    onClick: setSelectedTab,
                }}
            >
                <div
                    ref={onImageContainer}
                    className="relative h-full max-h-full z-0 p-8 bg-white overflow-y-auto"
                >
                    {selectedTab === Tab.IMAGES && (
                        <>
                            {imageContainer && images ? (
                                <>
                                    {
                                        images.length ? (
                                            <Viewer
                                                visible={true}
                                                images={images}
                                                container={imageContainer}
                                                noNavbar={true}
                                                noClose={true}
                                                zoomSpeed={0.6}
                                                noImgDetails={true}
                                                scalable={false}
                                                minScale={1}
                                                zIndex={5}
                                                className="w-full"
                                            />
                                        ) : (
                                            <div className="text-center w-full px-16 py-32 text-gray-500">
                                                No Images
                                            </div>
                                        )
                                    }
                                </>
                            ) : (
                                <div className="text-center w-full px-16 py-32 text-gray-500">
                                    Loading...
                                </div>
                            )}
                        </>
                    )}

                    {selectedTab === Tab.QUOTE_REQUEST && quoteRequest.contact && quoteRequest ? (
                        <div className="flex w-full h-full max-h-full overflow-y-auto">
                            <div className="flex flex-col h-full w-full m-0.5 max-h-full overflow-y-auto">
                                {editMode
                                    ? authContext?.hasAnyGrant(
                                        Scope.QuoteRequest.UPDATE,
                                        Scope.QuoteRequest.UPDATE_ANY
                                    ) && (
                                        <div className="mb-4 flex flex-col sm:flex-row">
                                            <Button
                                                className="w-full sm:w-40 mb-2 sm:mb-0 sm:mr-4"
                                                style="outline"
                                                type="button"
                                                rounded
                                                color="blue"
                                                onClick={() => updateQuoteRequest()}
                                            >
                                                Save Updates
                                            </Button>
                                            <Button
                                                className="w-full sm:w-40"
                                                style="outline"
                                                type="button"
                                                rounded
                                                color="blue"
                                                onClick={() => cancelQuoteRequestEdit()}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    )
                                    : authContext?.hasAnyGrant(
                                        Scope.QuoteRequest.UPDATE,
                                        Scope.QuoteRequest.UPDATE_ANY
                                    ) && (
                                        <Button
                                            className="w-full sm:w-40 mb-4"
                                            style="outline"
                                            type="button"
                                            rounded
                                            color="blue"
                                            onClick={() => setEditMode(true)}
                                        >
                                            Edit Quote Request
                                        </Button>
                                    )}
                                <div className="flex flex-col sm:flex-row my-2">
                                    <div className="flex-col w-full sm:w-1/2 mb-2 sm:mb-0">
                                        <label className="text-xs sm:text-sm text-gray-500">First Name</label>
                                        <div className="text-gray-600 text-sm sm:text-base">{quoteRequest.contact.firstname}</div>
                                    </div>
                                    <div className="flex-col w-full sm:w-1/2">
                                        <label className="text-xs sm:text-sm text-gray-500">Last Name</label>
                                        <div className="text-gray-600 text-sm sm:text-base">{quoteRequest.contact.lastname}</div>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row my-2">
                                    <div className="flex-col w-full sm:w-1/2 mb-2 sm:mb-0">
                                        <label className="text-xs sm:text-sm text-gray-500">Phone</label>
                                        <div className="text-gray-600 text-sm sm:text-base">{formatPhoneNumber(quoteRequest.contact.phone)}</div>
                                    </div>
                                    <div className="flex-col w-full sm:w-1/2">
                                        <label className="text-xs sm:text-sm text-gray-500">Email</label>
                                        <div className="text-gray-600 text-sm sm:text-base">{quoteRequest.contact.email}</div>
                                    </div>
                                </div>
                                <div className="flex flex-col my-2">
                                    <div className="flex-col">
                                        <label className="text-xs sm:text-sm text-gray-500">Address</label>
                                        <div className="text-gray-600 text-sm sm:text-base">
                                            <a
                                                className="text-blue-500 hover:text-blue-700"
                                                target="_blank"
                                                href={getGoogleMapsUrl(quoteRequest.address)}
                                            >
                                                {quoteRequest.address.streetAddress}, {quoteRequest.address.city}, {quoteRequest.address.state} {quoteRequest.address.zip}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col sm:flex-row my-2">
                                    <div className="flex-col w-full sm:w-1/2 mb-2 sm:mb-0">
                                        <label className="text-xs sm:text-sm text-gray-500">Building Type</label>
                                        <div className="text-gray-600 text-sm sm:text-base">
                                            {editMode ? (
                                                <select
                                                    defaultValue={formBuildingType}
                                                    className="input m-0.5 w-full sm:w-auto"
                                                    onChange={(e) => setFormBuildingType(e.target.value as BuildingType)}
                                                >
                                                    <option value={undefined}>Select</option>
                                                    <option value={BuildingType.COMMERCIAL}>{BuildingType.COMMERCIAL}</option>
                                                    <option value={BuildingType.RESIDENTIAL}>{BuildingType.RESIDENTIAL}</option>
                                                </select>
                                            ) : quoteRequest.buildingType ? (
                                                formatBuildingType(quoteRequest.buildingType)
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex-col w-full sm:w-1/2">
                                        <label className="text-xs sm:text-sm text-gray-500">Installation Type</label>
                                        <div className="text-gray-600 text-sm sm:text-base">
                                            {editMode ? (
                                                <select
                                                    defaultValue={formInstallationType}
                                                    className="input m-0.5 w-full sm:w-auto"
                                                    onChange={(e) => setFormInstallationType(e.target.value as InstallationType)}
                                                >
                                                    <option value={undefined}>Select</option>
                                                    <option value={InstallationType.DIY}>{InstallationType.DIY}</option>
                                                    <option value={InstallationType.PROFESSIONAL}>{InstallationType.PROFESSIONAL}</option>
                                                </select>
                                            ) : quoteRequest.installationType ? (
                                                formatInstallationType(quoteRequest.installationType)
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col my-2">
                                    <div className="flex-col">
                                        <label className="text-xs sm:text-sm text-gray-500">Product Lines</label>
                                        <div className="text-gray-600 text-sm sm:text-base">
                                            {editMode ? (
                                                <div className="flex w-full sm:w-72">
                                                    <div className="flex items-center w-full">
                                                        <MultiSelect
                                                            options={[
                                                                { label: "Classic", value: ProductLine.CLASSIC },
                                                                { label: "Color Changing", value: ProductLine.COLOR_CHANGING },
                                                            ]}
                                                            value={formProductLines ? formProductLines : []}
                                                            onChange={setFormProductLines}
                                                            hasSelectAll={false}
                                                            labelledBy={"Select"}
                                                            disableSearch={true}
                                                            className="w-full"
                                                        />
                                                    </div>
                                                </div>
                                            ) : quoteRequest.productLines ? (
                                                quoteRequest.productLines.map((el) => formatProductLine(el)).join(", ")
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col my-2">
                                    <div className="flex-col">
                                        <label className="text-xs sm:text-sm text-gray-500">Quoted Areas</label>
                                        <div className="text-gray-600 text-sm sm:text-base capitalize">
                                            {editMode ? (
                                                <div className="flex w-full sm:w-72">
                                                    <div className="flex items-center w-full">
                                                        <MultiSelect
                                                            options={[
                                                                { label: "Front Side", value: "front" },
                                                                { label: "Back Side", value: "back" },
                                                                { label: "Right Side", value: "right" },
                                                                { label: "Left Side", value: "left" },
                                                                { label: "Other", value: "other" },
                                                            ]}
                                                            value={formQuotedAreas ? formQuotedAreas : []}
                                                            onChange={setFormQuotedAreas}
                                                            hasSelectAll={false}
                                                            labelledBy={"Select"}
                                                            disableSearch={true}
                                                            className="w-full"
                                                        />
                                                    </div>
                                                </div>
                                            ) : quoteRequest.quotedAreas ? (
                                                quoteRequest.quotedAreas.map((el) => el).join(", ")
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col my-2">
                                    <div className="flex-col">
                                        <label className="text-xs sm:text-sm text-gray-500">Additional Info</label>
                                        <div className="text-gray-600 text-sm sm:text-base">
                                            {editMode ? (
                                                <textarea
                                                    className="input m-0.5 w-full sm:w-auto"
                                                    placeholder="optional"
                                                    value={formAdditionalInfo}
                                                    onChange={(e) => setFormAdditionalInfo(e.target.value)}
                                                />
                                            ) : quoteRequest.additionalInfo ? (
                                                quoteRequest.additionalInfo
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {selectedTab === Tab.SUBMITTED_ORDERS ? (
                        closedOrders?.length ? (
                            <div className="">
                                {closedOrders.map((el) => (
                                    <div
                                        className="flex py-2 px-10 pl-4 items-center m-2 shadow-md cursor-pointer"
                                        onClick={() => handleGoToOrderReview(el.id)}
                                    >
                                        <div className="mr-3 text-xs text-gray-600">
                                            Order Date:
                                        </div>
                                        <div className="text-gray-700">
                                            {new Date(el.submittedDate!).toLocaleDateString()}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="inset-center text-gray-500">
                                No Orders for this lead
                            </div>
                        )
                    ) : null}

                    {selectedTab === Tab.WARRANTY ? (
                        closedWarranties?.length ? (
                            <div className="">
                                {closedWarranties.map((el) => (
                                    <div
                                        className="flex py-2 px-10 pl-4 items-center m-2 shadow-md cursor-pointer"
                                        onClick={() => handleGoToWarrantyReview(el.id)}
                                    >
                                        <div className="mr-3 text-xs text-gray-600">
                                            Warranty Created:
                                        </div>
                                        <div className="text-gray-700">
                                            {new Date(el.createdAt!).toLocaleDateString()}
                                        </div>

                                        {el.approvedDate && (
                                            <a className="text-green-500 px-3 py-2">
                                                Approved
                                            </a>
                                        )}

                                        {el.rejectedDate && (
                                            <a className="text-red-500 px-3 py-2">
                                                Declined
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="inset-center text-gray-500">
                                No Warranties for this lead
                            </div>
                        )
                    ) : null}
                </div>
            </Table>
        </Page>
    );
}
