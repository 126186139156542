import axios from "axios";
import { BACKEND_URL } from "../environment";
import { Filters, Query, serializeQuery } from "@common.api";
import { ICreateOrderItemParams, int, IOrder, IOrderItem, IShippingAddress, IShippingLabelOrder, IUpdateOrderItemParams, PaymentMethodOption, PaymentMethodQuote, ShippingOption, ShippingQuote } from "@common.abstractions";

export async function CreateOrder(params: { leadId: int }): Promise<IOrder | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/orders`, params, { withCredentials: true });
        if (response.status === 201)
            return response.data as IOrder;

        console.log("Failed to create order with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Creating order threw an exception: ', err);
        return undefined;
    }
}

export async function RetrieveOrder(orderId: int): Promise<IOrder | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/orders/${orderId}`, { withCredentials: true });
        if (response.status === 200)

            return response.data as IOrder;

        console.log("Failed to retrieve order with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Retrieving order threw an exception: ', err);
        return undefined;
    }
}

export async function RetrieveOrders(query?: Query<IOrder>): Promise<IOrder[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/orders`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200)
            return (response.data as any[]).map(val => val as IOrder);

        console.log("Failed to retrieve orders with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Retrieving orders threw an exception: ', err);
        return undefined;
    }
}

export async function RetrieveDealerOrders(query?: Query<IOrder>): Promise<IOrder[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/orders/dealerOrders`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200) return response.data as IOrder[];

        console.log('Failed to retrieve dealer orders with code: ', response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Retrieving dealers orders threw an exception: ', err);
        return undefined;
    }
}

export async function RetrieveOrdersCount(filters: Filters<IOrder>): Promise<number | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/orders/count`, { params: { filters }, paramsSerializer: (val: any) => serializeQuery(val), withCredentials: true });
        if (response.status === 200) {
            return response.data;
        }

        console.log('Orders count failed to load with response code: ', response.status);
        return undefined;
    }
    catch (err: any) {
        console.log('Loading Orders count threw an exception: ', err);
        return undefined;
    }
}

export async function DealerOrdersCount(filters: Filters<IOrder>): Promise<number | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/orders/dealerOrders/count`, { params: { filters }, paramsSerializer: (val: any) => serializeQuery(val), withCredentials: true });
        if (response.status === 200) return response.data;

        console.log('Dealers orders count failed to load with response code: ', response.status);
        return undefined;
    }
    catch (err: any) {
        console.log('Loading dealers orders count threw an exception: ', err);
        return undefined
    }
}

export async function CreateOrderItem(orderId: int, quantity: int, productVariantId: int): Promise<IOrderItem | undefined> {
    try {
        const params = { quantity, productVariantId };
        const response = await axios.post(`${BACKEND_URL}/orders/${orderId}/items`, params, { withCredentials: true });
        if (response.status === 201)
            return response.data as IOrderItem;

        console.log("Failed to create order item with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Creating order item threw an exception: ', err);
        return undefined;
    }
}

export async function UpdateOrderItem(orderId: int, orderItemId: int, params: IUpdateOrderItemParams): Promise<IOrderItem | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/orders/items/${orderItemId}`, params, { withCredentials: true });
        if (response.status === 200)
            return response.data as IOrderItem;

        console.log("Failed to update order item with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Updating order item threw an exception: ', err);
    }
}

export async function DeleteOrderItem(orderId: int, orderItemId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/orders/items/${orderItemId}`, { withCredentials: true });
        console.log("response!!!", response);
        if (response.status === 204)
            return true

        console.log("Failed to delete order item with code: ", response.status, response.data);
        return false;
    }
    catch (err: any) {
        console.log('Updating order item threw an exception: ', err);
        return false;
    }
}

export async function GetShippingQuotes(orderId: int): Promise<ShippingQuote[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/orders/${orderId}/shippingQuotes`, { withCredentials: true });
        console.log("SHIPPING QUOTE RESPONSE");
        if (response.status === 200)
            return (response.data as object[]).map(obj => obj as ShippingQuote);

        console.log("Failed to get shipping quotes with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Getting shipping quotes threw an exception: ', err);
    }
}

export async function SetShipping(orderId: int, shippingOption: ShippingOption): Promise<ShippingQuote | undefined> {
    try {
        const response = await axios.put(`${BACKEND_URL}/orders/${orderId}/shipping`, shippingOption, { withCredentials: true });
        if (response.status === 200)
            return response.data as ShippingQuote;

        console.log("Failed to set shipping method with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Setting shipping option threw an exception: ', err);
    }
}


export async function SetShippingAddress(orderId: int, shippingAddressId: int): Promise<IShippingAddress | undefined> {
    try {
        const response = await axios.put(`${BACKEND_URL}/orders/${orderId}/shippingAddress`, { shippingAddressId }, { withCredentials: true });
        if (response.status === 200)
            return response.data as IShippingAddress;

        console.log("Failed to set shipping method with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Setting shipping option threw an exception: ', err);
    }
}

export async function GetPaymentMethodQuotes(orderId: int): Promise<PaymentMethodQuote[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/orders/${orderId}/paymentMethodQuotes`, { withCredentials: true });
        console.log("RESPONSE", response);
        if (response.status === 200)
            return (response.data as object[]).map(obj => obj as PaymentMethodQuote);

        console.log("Failed to get payment method with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Getting payment method quotes threw an exception: ', err);
    }
}

export async function SetPaymentMethod(orderId: int, paymentMethodOption: PaymentMethodOption): Promise<PaymentMethodQuote | undefined> {
    try {
        const response = await axios.put(`${BACKEND_URL}/orders/${orderId}/paymentMethod`, paymentMethodOption, { withCredentials: true });
        if (response.status === 200)
            return response.data as PaymentMethodQuote;

        console.log("Failed to update product with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Setting payment method threw an exception: ', err);
    }
}

export async function SubmitOrder(orderId: int): Promise<IOrder | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/orders/${orderId}/submit`, {}, { withCredentials: true });
        if (response.status === 200)
            return response.data as IOrder;

        console.log("Failed to submit order with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Submitting order threw an exception: ', err);
    }
}

export async function ProcessOrder(orderId: int): Promise<IOrder | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/orders/${orderId}/process`, null, { withCredentials: true });
        if (response.status === 200)
            return response.data as IOrder;

        console.log("Failed to process order with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Processing order threw an exception: ', err);
    }
}

export async function CreateShippingLabel(params: IShippingLabelOrder): Promise<boolean | undefined> {
    try {
        const response = await axios.put(`${BACKEND_URL}/orders/createShippingLabel`, params, { withCredentials: true });
        if (response.status === 201) return true;

        console.log("Failed to create shipment with code: ", response.status, response.data);
        return undefined;

    }
    catch (err: any) {
        console.log(err);
        return false;
    }
}

export async function DeleteOrder(orderId: int): Promise<IOrder | undefined> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/orders/${orderId}`, { withCredentials: true });
        if (response.status === 200) return response.data as IOrder;

        return undefined;
    } catch (error) {
        console.log(error);
        return undefined;
    }
}
