import { isDate } from "./utilities";

export function handleSort<T>(props: (keyof T)[], filteredArray: T[], params?: keyof T): T[] {
    let sorted = [...filteredArray];
    let i = 0;

    if (params) {
        while (props[i]) {
            const temp = (sorted as any).sort((a: any, b: any) => a[params][props[i]].localeCompare(b[params][props[i]]));
            sorted = temp;
            i++;
        }
    } else {
        while (props[i]) {
            if (typeof (sorted[0] as any)[props[i]] === "number") {
                const temp = (sorted as any).sort((a: any, b: any) => a[props[i]] - b[props[i]]);
                sorted = temp;
            } else if (isDate((sorted[0] as any)[props[i]])) {
                const temp = (sorted as any).sort((a: any, b: any) => a[props[i]]?.toLocaleString().localeCompare(b[props[i]]?.toLocaleString()));
                sorted = temp;
            } else if (typeof (sorted[0] as any)[props[i]] === "string") {
                const temp = (sorted as any).sort((a: any, b: any) => a[props[i]]?.localeCompare(b[props[i]]));
                sorted = temp;
            }
            i++;
        }
    }

    return sorted as T[];
}