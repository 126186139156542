import React, { useEffect, useState } from 'react'
import { ICreatePartnerGoal, int, IPartnerGoal, IUpdatePartnerGoal } from '@common.abstractions'
import { Page } from '../misc/layout/Page'
import Modal from '../misc/modal/Modal'
import TopBar from '../misc/top-bar/TopBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useForm } from 'react-hook-form'
import { formatWholeMoney } from '@common.tools'
import { toast } from 'react-toastify'
import { CreatePartnerGoal, DeletePartnerGoal, RetrieveParterGoals, RetrievePartnerGoalById, UpdatePartnerGoal } from '@frontend/services/goals'
import { Loading } from '../misc/loading'

export default function PartnerGoals() {
    const { handleSubmit, register } = useForm();

    const [partnerGoals, setPartnerGoals] = useState<IPartnerGoal[]>();
    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
    const [openPrevModal, setOpenPrevModal] = useState<boolean>(false);
    const [editModal, setEditModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [goalToUpdate, setGoalToUpdate] = useState<IPartnerGoal>();

    const openCreateGoalModal = () => setOpenCreateModal(true);
    const closeCreateGoalModal = () => setOpenCreateModal(false);

    const openPrevGoalModal = () => setOpenPrevModal(true);
    const closePrevGoalModal = () => setOpenPrevModal(false);

    const openEditModal = async (goalId: int) => {
        const selectedGoal = await RetrievePartnerGoalById(goalId);
        setGoalToUpdate(selectedGoal);
        setEditModal(true)
    }
    const closeEditModal = () => setEditModal(false);

    const retrieveGoals = async () => {
        setLoading(true);
        const goals = await RetrieveParterGoals();
        if (!goals) {
            console.log('Could not retrieve partner goals');
            return
        }

        goals.sort((a, b) => {
            const x = new Date(a.startDate).getTime();
            const y = new Date(b.startDate).getTime();
            return y - x
        });

        setPartnerGoals(goals);
        setLoading(false);
    }

    const createGoal = async (values: any) => {
        const goal = int.parse(values.goal.replaceAll(',', ''))
        const progress = int.parse(values.progress.replaceAll(',', ''));
        const ftSold = int.parse(values.ftSold.replaceAll(',', ''));

        const today = new Date();

        if (!partnerGoals?.length) {
            const firstDayMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDayMonth = new Date(new Date(firstDayMonth.getFullYear(), firstDayMonth.getMonth() + 1, 0).setHours(23, 59, 59, 0));

            const createParams: ICreatePartnerGoal = {
                goal: goal,
                startDate: firstDayMonth,
                endDate: lastDayMonth,
                progress: progress,
                ftSold: ftSold
            }

            const partnerGoal = await CreatePartnerGoal(createParams);
            if (!partnerGoal) {
                toast.error('Error creating goal');
                return;
            }
        }

        else {
            const currentGoal = partnerGoals[0];
            const currentGoalStart = new Date(currentGoal.startDate);

            const firstDayNextMonth = new Date(new Date(currentGoalStart.getFullYear(), currentGoalStart.getMonth() + 1, 1));
            const lastDayNextMonth = new Date(new Date(firstDayNextMonth.getFullYear(), firstDayNextMonth.getMonth() + 1, 0).setHours(23, 59, 59, 0));

            const createParams: ICreatePartnerGoal = {
                goal: goal,
                startDate: firstDayNextMonth,
                endDate: lastDayNextMonth,
                progress: progress,
                ftSold: ftSold
            }

            const partnerGoal = await CreatePartnerGoal(createParams);
            if (!partnerGoal) {
                toast.error('Error creating goal');
                return;
            }
        }

        toast.success('Goal Created');
        setOpenCreateModal(false);
        retrieveGoals();
    }

    const createPrevGoal = async (values: any) => {
        const goal = int.parse(values.goal.replaceAll(',', ''))
        const progress = int.parse(values.progress.replaceAll(',', ''));
        const ftSold = int.parse(values.ftSold.replaceAll(',', ''));

        if (partnerGoals?.length) {
            const firstGoal = partnerGoals[partnerGoals.length - 1];
            const firstGoalStart = new Date(firstGoal.startDate);

            const prevMonth = new Date(firstGoalStart.getFullYear(), firstGoalStart.getMonth() - 1, 1);
            const prevMonthFirst = new Date(prevMonth.getFullYear(), prevMonth.getMonth(), 1);
            const prevMonthLast = new Date(new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 0).setHours(23, 59, 59, 0));

            const createParams: ICreatePartnerGoal = {
                goal: goal,
                startDate: prevMonthFirst,
                endDate: prevMonthLast,
                progress: progress,
                ftSold: ftSold
            };

            const partnerGoal = await CreatePartnerGoal(createParams);
            if (!partnerGoal) {
                toast.error('Error creating goal');
                return
            }

            toast.success('Goal Created');
            setOpenPrevModal(false);
            retrieveGoals();
        }
    }

    const editGoal = async (values: any) => {
        const newGoalAmount = int.parse(values.goal.replaceAll(',', ''));
        const newGoalProgress = int.parse(values.progress.replaceAll(',', ''));
        const newFtSold = int.parse(values.ftSold.replaceAll(',', ''));

        const updateParams: IUpdatePartnerGoal = { goal: newGoalAmount, progress: newGoalProgress, ftSold: newFtSold };

        const updatedGoal = await UpdatePartnerGoal(goalToUpdate!.id, updateParams)
        if (!updatedGoal) {
            toast.error('Error updating goal');
            return;
        }

        toast.success('Goal updated');
        closeEditModal();
        retrieveGoals();
    }

    const deleteGoal = async (goalId: int) => {
        const confirm = window.confirm('Are you sure you want to delete this goal?');
        if (confirm) {
            await DeletePartnerGoal(goalId);
            toast.success('Goal deleted');
            retrieveGoals();
        }
    }

    useEffect(() => {
        retrieveGoals();
    }, [])

    if (loading) return <Loading center />

    return (
        <Page>
            <TopBar title='Partner Targets' />
            <div className='flex justify-center'>
                {partnerGoals?.length ? (
                    <div className='mx-2'>
                        <button
                            className='bg-blue-500 p-2 text-white rounded-lg shadow-lg hover:bg-blue-600'
                            onClick={openPrevGoalModal}
                        >
                            Prev Month
                        </button>
                    </div>
                ) : undefined}

                <div className='mx-2'>
                    <button
                        className='bg-blue-500 p-2 text-white rounded-lg shadow-lg hover:bg-blue-600'
                        onClick={openCreateGoalModal}
                    >
                        {partnerGoals?.length ? 'Next Month' : `Add goal for ${new Date().toLocaleDateString('en-us', { month: "short" })} `}
                    </button>
                </div>
            </div>

            {partnerGoals?.map((goal) => (
                <div key={goal.id} className='flex justify-center items-center mt-5'>
                    <div className='flex border-black border-solid border-2 rounded w-1/2 p-2 justify-evenly'>
                        <p>{new Date(goal.startDate).toLocaleDateString('en-us', { year: "numeric", month: "short" })}</p>
                        <p className='mx-4'>Target: {formatWholeMoney(goal.goal * 100)}</p>
                        <p className='mx-4'>Achieved: {goal.progress && formatWholeMoney(goal.progress * 100)} </p>
                        <p className='mx-4'>Percent: {goal.progress ? ((goal.progress / goal.goal) * 100).toFixed(2) : 0}%</p>
                        <p className='mx-4'>Ft Sold: {goal.ftSold ? goal.ftSold : 0}</p>
                        <button className='mx-4' onClick={() => openEditModal(goal.id)
                        }>
                            <FontAwesomeIcon icon={faEdit} color='rgb(255,189,28)' />
                        </button>
                        <button className='mx-4' onClick={() => deleteGoal(goal.id)}>
                            <FontAwesomeIcon icon={faTrash} color='rgb(255,0,0)' />
                        </button>
                    </div>
                </div>
            ))}

            {/* Create Modal */}
            <Modal visible={openCreateModal} closeModal={closeCreateGoalModal}>
                <form onSubmit={handleSubmit(createGoal)}>
                    <div className='text-center'>
                        <h1 className='text-lg'>Create Target</h1>
                        <div className='flex mt-4 justify-evenly'>
                            <label>Target Amount:</label>
                            <input
                                className='border-2 border-solid border-black text-center'
                                {...register('goal', { required: true })}>
                            </input>
                            <label>Progress:</label>
                            <input
                                className='border-2 border-solid border-black text-center'
                                {...register('progress', { required: true })}>
                            </input>
                            <label>Ft Sold:</label>
                            <input
                                className='border-2 border-solid border-black text-center'
                                {...register('ftSold', { required: true })}>
                            </input>
                            <button className='text-white bg-blue-500 rounded-lg p-2 shadow-lg' type='submit'>Submit</button>
                        </div>
                    </div>
                </form>
            </Modal>

            {/* Prev Goal Modal */}
            <Modal visible={openPrevModal} closeModal={closePrevGoalModal}>
                <form onSubmit={handleSubmit(createPrevGoal)}>
                    <div className='text-center'>
                        <h1 className='text-lg'>Create Prev Month Target</h1>
                    </div>
                    <div className='flex mt-4 justify-evenly'>
                        <label>Target Amount:</label>
                        <input
                            className='border-2 border-solid border-black text-center'
                            {...register('goal', { required: true })}>
                        </input>
                        <label>Progress:</label>
                        <input
                            className='border-2 border-solid border-black text-center'
                            {...register('progress', { required: true })}>
                        </input>
                        <label>Ft Sold:</label>
                        <input
                            className='border-2 border-solid border-black text-center'
                            {...register('ftSold', { required: true })}>
                        </input>
                        <button className='text-white bg-blue-500 rounded-lg p-2 shadow-lg' type='submit'>Submit</button>
                    </div>
                </form>
            </Modal>

            {/* Edit Modal */}
            <Modal visible={editModal} closeModal={closeEditModal}>
                <form onSubmit={handleSubmit(editGoal)}>
                    <div className='text-center'>
                        <h1 className='text-lg'>Edit Target</h1>
                    </div>
                    <div className='flex mt-4 justify-evenly'>
                        <label>Target Amount:</label>
                        <input
                            className='border-2 border-solid border-black text-center'
                            {...register('goal', { required: true })}
                            defaultValue={goalToUpdate?.goal}>
                        </input>
                        <label>Progress:</label>
                        <input
                            className='border-2 border-solid border-black text-center'
                            {...register('progress', { required: true })}
                            defaultValue={goalToUpdate?.progress}>
                        </input>
                        <label>Ft Sold:</label>
                        <input
                            className='border-2 border-solid border-black text-center'
                            {...register('ftSold', { required: true })}
                            defaultValue={goalToUpdate?.ftSold}>
                        </input>
                        <button className='text-white bg-blue-500 rounded-lg p-2 shadow-lg' type='submit'>Submit</button>
                    </div>
                </form>
            </Modal>
        </Page>
    );
}