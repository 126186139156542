import axios from 'axios';
import { BACKEND_URL } from '../environment';
import { ApiResponse_old } from './api-response';
import { Query, serializeQuery } from '@common.api';
import { IContact, ICreateContactParams, int } from '@common.abstractions';

axios.defaults.validateStatus = function () {
    return true;
};

export async function CreateContact(params: ICreateContactParams): Promise<ApiResponse_old<IContact> | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/contacts`, params, { withCredentials: true });
        if (response.status === 201)
            return {responseCode: response.status, responseContent: response.data as IContact};
        return {responseCode: response.status, responseContent: response.data};
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveContact(contactId: int): Promise<IContact | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/contacts/${contactId}`, { withCredentials: true });
        if (response.status === 200)
            return response.data as IContact;
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveContacts(query?: Query<IContact>): Promise<IContact[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/contacts`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200)
            return (response.data as any[]).map(val => val as IContact);
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveContactsCount(query?: Query<IContact>): Promise<number | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/contacts/count`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200)
            return response.data;
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function MarkInvalid(contactId: int): Promise<IContact | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/contacts/${contactId}`, { isInvalid: true }, { withCredentials: true });
        if (response.status === 200)
            return response.data as IContact;

        console.log("Failed to update contact status", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Update contact status threw an exception: ', err);
        return undefined;
    }
}

export async function MarkValid(contactId: int): Promise<IContact | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/contacts/${contactId}`, { isInvalid: false }, { withCredentials: true });
        if (response.status === 200)
            return response.data as IContact;

        console.log("Failed to update contact status", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Update contact status threw an exception: ', err);
        return undefined;
    }
}

export async function DeleteContact(contactId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/contacts/${contactId}`, { withCredentials: true });
        if (response.status === 204)
            return true;

        console.log("Failed to update contact status", response.status, response.data);
        return false;
    }
    catch (err: any) {
        console.log('Update contact status threw an exception: ', err);
        return false;
    }
}
