import React, {Dispatch, SetStateAction, useRef, useState} from "react";
import {toast} from "react-toastify";
import { IS3UploadResponse, IProductImage } from "@common.abstractions";
import {FileUploader, IFileUploader} from "@frontend/lib/uploader";
import {ImageUpload} from "@frontend/components/leads/create/ImageUpload";

const compareFiles = (a: File, b: File) => a.name === b.name && a.size === b.size && a.type === b.type;

interface ImageUploadState { uploader: IFileUploader; file: File }
interface Props {
    onImageUploadHandler: (filename: string) => Promise<IProductImage & { uploadUrl: string } | undefined>;
    setProductImage: (productImage: IProductImage | null) => Promise<void>;
    productImage: IProductImage | null;
    imageSrc: { src: any } | undefined;
}

const ProductImageUpload = (props: Props) => {
    const supportedFileTypes = ["image/jpeg", "image/png", "image/gif"];
    const imageUploadsRef = useRef<ImageUploadState>();
    const [imageUploadsState, setImageUploadsState] = useState<ImageUploadState>();
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    let {onImageUploadHandler, setProductImage, productImage, imageSrc} = props;

    const addImageUpload = (imageUpload: { uploader: IFileUploader; file: File }) => {
        setImageUploadsState(imageUpload);
    }

    const removeImageUpload = () => {
        setImageUploadsState(undefined);
    }

    const imageSelectedHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            imageSrc = undefined;
            let currentImage = document.getElementById("currentImage");
            if(currentImage) {
                currentImage.style.visibility = "hidden";
            }

            const rawFiles = Array.from(event.target.files);
            event.target.value = "";
            const files = rawFiles
                .filter((file) => supportedFileTypes.includes(file.type))
                .filter((file) => file.size <= 3000000);

            const bigFiles = rawFiles.filter((file) => file.size > 3000000);
            bigFiles.forEach((file) => toast.warn(`File: ${file.name} is too big!`, { autoClose: 5000 }));

            const unsupportedFiles = rawFiles.filter((file) => !supportedFileTypes.includes(file.type));
            unsupportedFiles.forEach((file) => toast.warn(`File: ${file.name} is not a supported file type.`, { autoClose: 5000 }));

            const uploads = files.map(async (file) => {
                const upload = await onImageUploadHandler(file.name);
                if (!upload) return undefined; // TODO: Handle failure
                setProductImage(upload);
                const uploader = new FileUploader(upload.id, file, upload.uploadUrl);
                return { uploader, file };
            });

            const confirmeduploads = (await Promise.all(uploads)).filter((val) => val !== undefined) as {
                uploader: FileUploader;
                file: File;
            }[];

            confirmeduploads.forEach((upload) => {
                upload.uploader.start();
                addImageUpload(upload);
                upload.uploader.addOnCancelledListener(() => removeImageUpload());
            });

            if(currentImage) {
                currentImage.style.visibility = "visible";
            }
        }
    };

    return (
        <>
            <label >
                Product Photo
            </label>
            <div className="text-center relative">
                <div id="currentImage" className="text-center py-10 border-gray-300 border mt-2 text-gray-500">
                    {imageSrc && <img  {...imageSrc}></img>}
                    {!imageSrc && imageUploadsState === undefined && "No Photo"}

                    {!imageSrc && imageUploadsState && <ImageUpload key={imageUploadsState?.uploader.id} fileUploader={imageUploadsState?.uploader} file={imageUploadsState?.file} /> }
                </div>

                <button type="button" className="btn bg-theme_gold text-white mt-3" onClick={() => hiddenFileInput!.current!.click()}>
                    Select Photo
                </button>

                <input type="file" accept="image/*" style={{ display: "none" }} ref={hiddenFileInput} onChange={imageSelectedHandler} />
            </div>
        </>
    )
}

export default ProductImageUpload