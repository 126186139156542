import { Address, int, IShippingAddress } from "@common.abstractions";
import { USStates } from "@common.tools";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading } from "@frontend/components/misc/loading";
import { Modal } from "@frontend/components/misc/modal";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import AuthContext from "@frontend/contexts/AuthContext";
import { CreateShippingAddress, DeleteShippingAddress, RetrieveCompany, RetrieveShippingAddresses, UpdateCompany } from "@frontend/services/companies";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function Addresses() {
    const authContext = useContext(AuthContext);
    const [shippingAddresses, setShippingAddresses] = useState<IShippingAddress[]>();
    const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [selectedShippingAddress, setSelectedShippingAddress] = useState<IShippingAddress>();

    const {
        register: registerCreate,
        handleSubmit: handleSubmitCreate,
        reset: resetCreate,
        formState: {
            errors: errorsCreate,
        },
    } = useForm<Omit<IShippingAddress, "company">>();

    const {
        register: registerEdit,
        handleSubmit: handleSubmitEdit,
        reset: resetEdit,
        setValue: setValueEdit,
        getValues: getValuesEdit,
        formState: {
            errors: errorsEdit,
        },
    } = useForm<Omit<IShippingAddress, "company">>();

    const loadAddresses = async () => {
        const response = await RetrieveShippingAddresses(authContext!.user!.company!.id);
        if (!response) {
            setShippingAddresses([]);
            return;
        }

        console.log("setting shpping address");
        setShippingAddresses(response);
    };
    const deleteAddress = async (shippingAddressId: int) => {
        const response = await DeleteShippingAddress(authContext!.user!.company!.id, shippingAddressId);
        if (!response) {
            toast.error("Error deleting shipping address");
            return;
        }

        setShippingAddresses([...shippingAddresses!.filter((el) => el.id !== shippingAddressId)]);
        toast("Shipping address added");
    };
    const createAddress = async (data: IShippingAddress) => {
        data.country = "United States";
        const response = await CreateShippingAddress(authContext!.user!.company!.id, data);
        if (!response) {
            toast.error("Error editing address");
            closeCreateModal();
            return;
        }

        setShippingAddresses([...shippingAddresses!, data]);
        toast("New shipping address added");
        closeCreateModal();
    };
    const editAddress = async (data: IShippingAddress) => {
        data.country = "United States";
        if (!(await DeleteShippingAddress(authContext!.user!.company!.id, selectedShippingAddress!.id))) {
            toast.error("Error editing shipping address");
            closeEditModal();
            return;
        }

        const createResponse = await CreateShippingAddress(authContext!.user!.company!.id, data);
        if (!createResponse) {
            toast.error("Error editing shipping address");
            closeEditModal();
            return;
        }

        setShippingAddresses([
            ...shippingAddresses!.map((el) => {
                if (el.id === selectedShippingAddress!.id) {
                    el = createResponse;
                }
                return el;
            }),
        ]);

        toast("New shipping address added");
        closeEditModal();
    };
    const closeCreateModal = async () => {
        resetCreate();
        setOpenCreateModal(false);
    };

    const setEditModalValues = async (shippingAddress: IShippingAddress) => {
        setSelectedShippingAddress(shippingAddress);
        setOpenEditModal(true);
        setValueEdit("streetAddress", shippingAddress.streetAddress);
        setValueEdit("city", shippingAddress.city);
        setValueEdit("state", shippingAddress.state);
        setValueEdit("zip", shippingAddress.zip);

        console.log("shipping addresss", shippingAddress)

        console.log(getValuesEdit());
    }
    const closeEditModal = async () => {
        resetEdit();
        setSelectedShippingAddress(undefined);
        setOpenEditModal(false);
    };

    useEffect(() => {
        loadAddresses();
    }, []);

    return (
        <div className="h-full w-full flex flex-col">
            <TopBar title="Shipping Addresses">
                <button type="button" className="btn bg-theme_blue text-white" onClick={() => setOpenCreateModal(true)}>
                    Add Shipping Address
                </button>
            </TopBar>

            {!shippingAddresses ? (
                <Loading center></Loading>
            ) : (
                shippingAddresses.map((el) => (
                    <div className="flex  text-gray-600 py-2  hover:text-gray-800 duration-200">
                        <div>
                            {el.streetAddress}, {el.city}, {el.state} {el.zip}, {el.country}
                        </div>
                        <div className="flex ml-4">
                            <div className="cursor-pointer" onClick={() => deleteAddress(el.id)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                        </div>
                        <div className="flex ml-4">
                            <div className="cursor-pointer" onClick={() => setEditModalValues(el)}>
                                <FontAwesomeIcon icon={faEdit} />
                            </div>
                        </div>
                    </div>
                ))
            )}

            <Modal visible={openCreateModal} closeModal={() => closeCreateModal()}>
                <form onSubmit={handleSubmitCreate(createAddress)}>
                    <div className="form_group">
                        <div>
                            <label>Street Address</label>
                            <input
                                className={`form-control ${errorsCreate.streetAddress && "form_outline_error"}`}
                                type="text"
                                placeholder="required"
                                {...registerCreate('streetAddress', { required: "Required" })} />
                            {errorsCreate.streetAddress && <div className="form_input_error">{errorsCreate.streetAddress.message}</div>}
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label>City</label>
                            <input
                                className={`form-control ${errorsCreate.city && "form_outline_error"}`}
                                type="text"
                                placeholder="required"
                                {...registerCreate('city', { required: "Required" })} />
                            {errorsCreate.city && <div className="form_input_error">{errorsCreate.city.message}</div>}
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label htmlFor="state">State</label>
                            <select
                                id="state"
                                className={`form-control ${errorsCreate.state && "is-invalid"}`}
                                {...registerCreate('state', { required: true, validate: (value) => value.length === 2 })}>
                                <option value={undefined} className="text-gray-500">
                                    --Select--
                                </option>
                                {USStates.map((el) => (
                                    <option value={el.abbr}>{el.name}</option>
                                ))}
                            </select>
                            {errorsCreate.state && errorsCreate.state.type === "required" && <div className="form_input_error">This field is required</div>}
                            {errorsCreate.state && <div className="form_input_error">{errorsCreate.state.message}</div>}
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label>Postal Code</label>
                            <input
                                className={`form-control ${errorsCreate.zip && "form_outline_error"}`}
                                type="text"
                                placeholder="required"
                                {...registerCreate('zip', { required: "Required" })} />
                            {errorsCreate.zip && <div className="form_input_error">{errorsCreate.zip.message}</div>}
                        </div>
                    </div>
                    <div className="flex justify-end mt-5">
                        <div>
                            <button type="submit" className="btn bg-theme_blue text-white">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </Modal>

            <Modal visible={openEditModal} closeModal={() => closeEditModal()}>
                <form onSubmit={handleSubmitEdit(editAddress)}>
                    <div className="form_group">
                        <div>
                            <label>Street Address</label>
                            <input
                                className={`form-control ${errorsEdit.streetAddress && "form_outline_error"}`}
                                type="text"
                                placeholder="required"
                                {...registerEdit('streetAddress', { required: "Required" })} />
                            {errorsEdit.streetAddress && <div className="form_input_error">{errorsEdit.streetAddress.message}</div>}
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label>City</label>
                            <input
                                className={`form-control ${errorsEdit.city && "form_outline_error"}`}
                                type="text"
                                placeholder="required"
                                {...registerEdit('city', { required: "Required" })} />
                            {errorsEdit.city && <div className="form_input_error">{errorsEdit.city.message}</div>}
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label htmlFor="state">State</label>
                            <select
                                id="state"
                                className={`form-control ${errorsEdit.state && "is-invalid"}`}
                                {...registerEdit('state', { required: true, validate: (value) => value.length === 2 })}>
                                <option value={undefined} className="text-gray-500">
                                    --Select--
                                </option>
                                {USStates.map((el) => (
                                    <option value={el.abbr}>{el.name}</option>
                                ))}
                            </select>
                            {errorsEdit.state && errorsEdit.state.type === "required" && <div className="form_input_error">This field is required</div>}
                            {errorsEdit.state && <div className="form_input_error">{errorsEdit.state.message}</div>}
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label>Postal Code</label>
                            <input
                                className={`form-control ${errorsEdit.zip && "form_outline_error"}`}
                                type="text"
                                placeholder="required"
                                {...registerEdit('zip', { required: "Required" })} />
                            {errorsEdit.zip && <div className="form_input_error">{errorsEdit.zip.message}</div>}
                        </div>
                    </div>
                    <div className="flex justify-end mt-5">
                        <div>
                            <button type="submit" className="btn bg-theme_blue text-white">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    );
}
