import axios from "axios";
import { BACKEND_URL } from "../environment";
import { IEmailPreferences, int, IUpdateEmailPreferencesParams } from "@common.abstractions";

export async function UpdateEmailPreferences(emailPreferencesId: int, params: IUpdateEmailPreferencesParams): Promise<IEmailPreferences | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/emailPreferences/${emailPreferencesId}`, params, { withCredentials: true });
        if (response.status === 200) return response.data as IEmailPreferences;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}