import React from "react";
import { Link } from "react-router-dom";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type TableTextColor = "red" | "green" | "blue" | "yellow" | "gray"

interface IProps {
    data: {
        text?: {
            title: any;
            subtitle?: (string | number | undefined)[];
            onClick?: any;
            color?: TableTextColor
            tooltip?: string;
            to?: any // change from href to to
        };
        contractSigned?: boolean;
    }[];
}

export function TableRow(props: IProps) {
    const { data } = props;

    return (
        <tr className={`leading-none shadow-sm text-xs sm:text-sm hover:bg-gray-50`}>
            {data.map((el, i) => (
                <React.Fragment key={`table row ${i}`}>
                    {el.text && (
                        <td className={`p-2 sm:p-4 leading-none`}>
                            <div className="flex flex-col sm:flex-row justify-between">
                                {el.text.to ? (
                                    <Link
                                        className={`
                                            ${el.text.color ? `text-${el.text.color}-500 bg-${el.text.color}-100 rounded-lg sm:rounded-3xl px-2 sm:px-3 py-1 sm:py-2 whitespace-nowrap text-center inline-block` : ""}
                                            ${el.text.onClick ? `cursor-pointer duration-200 whitespace-nowrap` : ""}
                                            ${el.text.color && el.text.onClick ? `hover:bg-${el.text.color}-200` : ""}
                                            text-gray-500
                                        `}
                                        onClick={el.text.onClick ? el.text.onClick : undefined}
                                        data-tip={el.text.tooltip ? el.text.tooltip : undefined}
                                        data-delay-show="200"
                                        data-background-color="#9ca3af"
                                        data-effect="solid"
                                        to={el.text.to}
                                    >
                                        {el.text.title}
                                    </Link>
                                ) : (
                                    <div
                                        className={`
                                            ${el.text.color ? `text-${el.text.color}-500 bg-${el.text.color}-100 rounded-lg sm:rounded-3xl px-2 sm:px-3 py-1 sm:py-2 whitespace-nowrap text-center inline-block` : ""}
                                            ${el.text.onClick ? `cursor-pointer duration-200 whitespace-nowrap` : ""}
                                            ${el.text.color && el.text.onClick ? `hover:bg-${el.text.color}-200` : ""}
                                            text-gray-500
                                        `}
                                        onClick={el.text.onClick ? el.text.onClick : undefined}
                                        data-tip={el.text.tooltip ? el.text.tooltip : undefined}
                                        data-delay-show="200"
                                        data-background-color="#9ca3af"
                                        data-effect="solid"
                                    >
                                        {el.text.title}
                                    </div>
                                )}
                                {el.contractSigned && (
                                    <div className="mt-2 sm:mt-0">
                                        <FontAwesomeIcon color="rgb(124,187,46)" icon={faCheck} />
                                    </div>
                                )}
                            </div>
                            {el.text.subtitle?.map((subtitleEl, index) => (
                                <div key={`text subtitle for the row ${index} ${i}`} className={`text-gray-400 text-xs`}>
                                    {subtitleEl}
                                </div>
                            ))}
                        </td>
                    )}
                </React.Fragment>
            ))}
        </tr>
    );
}