import { Page } from "@frontend/components/misc/layout";
import { Loading } from "@frontend/components/misc/loading";
import { AuthGoogleCalendar } from "@frontend/services/google-calendar";
import qs from "qs";
import React, { useEffect } from "react";

import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";

export default function GoogleAuthRedirect() {
    const location = useLocation();
    const navigate= useNavigate();

    const authenticateTokenOnBackend = async () => {
        const hash = location.hash;
        const code = qs.parse(hash)["#code"];
        if (code) {
            const response = await AuthGoogleCalendar(code as string);
            if (!response) {
                toast.error("Error linking google calendar account");
            } else {
                toast("Calendar linked successfully");
            }

            navigate("/account", {replace: true});
        } else {
            toast.error("Unable to link google calendar");
            navigate("/account", {replace: true});
        }
    };

    useEffect(() => {
        authenticateTokenOnBackend();
    }, []);

    return (
        <Page>
            <div className="fixed top-0 left-0 right-0 bottom-0 bg-white z-50" style={{ zIndex: 9999 }}>
                <div className="inset-center flex flex-col items-center justify-center text-gray-500 animate-pulse">
                    <div>Connecting Google Calendar...</div>
                    <Loading />
                </div>
            </div>
        </Page>
    );
}
