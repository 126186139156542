import { BookingType, IExternalBooking, IBooking } from "@common.abstractions";
import React, { useEffect, useMemo, useState } from "react";
import { RowData } from "../models/rowData";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export enum PositioningMode {
    HALF_AND_FULL_DAY = 'HALF_AND_FULL_DAY',
    HOURLY_SEGMENTS = 'HOURLY_SEGMENTS'
}

type Props = {
    row: RowData;
    multiDay?: { startsToday: boolean; endsToday: boolean; startingHour: number; endingHour: number };
    booking: IBooking;
    onBookingClick?: any;
    positioningMode: PositioningMode;
};

export default function Booking(props: Props) {
    const { row, booking, onBookingClick, multiDay, positioningMode } = props;

    const [bookingStart] = useState<Date>(new Date(booking.start));
    const [bookingEnd] = useState<Date>(new Date(booking.end));

    const [backgroundColor, setBackgroundColor] = useState<string>("hidden");
    const [backgroundColorHover, setBackgroundColorHover] = useState<string>("hidden");
    const [borderColor, setBorderColor] = useState<string>("border-transparent");
    const [textColor, setTextColor] = useState<string>("text-white");
    const [isHovered, setIsHovered] = useState(false);

    const DAY_START_HOUR = 8;
    const DAY_END_HOUR = 17;
    const TOTAL_SEGMENTS = DAY_END_HOUR - DAY_START_HOUR;

    const timeLabel = useMemo(() => {
        if (new Date(booking.start).getDay() !== new Date(booking.end).getDay()) {
            return `${new Date(booking.start).toLocaleDateString(undefined, {
                hour: "numeric",
                minute: "numeric",
                month: "short",
                day: "numeric",
            })} - ${new Date(booking.end).toLocaleDateString(undefined, { hour: "numeric", minute: "numeric", month: "short", day: "numeric" })}`;
        } else {
            return `${new Date(booking.start).toLocaleTimeString(undefined, {
                hour: "numeric",
                minute: "numeric",
            })} - ${new Date(booking.end).toLocaleTimeString(undefined, { hour: "numeric", minute: "numeric" })}`;
        }
    }, [booking]);

    // const startPosition = useMemo(() => {
    //     if (multiDay) {
    //         if (!multiDay.startsToday && !multiDay.endsToday) {
    //             return "0%";
    //         } else if (multiDay.startsToday && !multiDay.endsToday) {
    //             if (bookingStart.getHours() <= multiDay.startingHour) {
    //                 return "0%";
    //             } else {
    //                 const hours = bookingStart.getHours() - multiDay.startingHour;
    //                 const minutes = bookingStart.getMinutes();
    //                 const startPos = hours * 100 + (minutes / 60) * 100;
    //                 return startPos.toFixed(2) + "%";
    //             }
    //         } else if (!multiDay.startsToday && multiDay.endsToday) {
    //             return "0%";
    //         } else if (multiDay.startsToday && multiDay.endsToday) {
    //             //this one is the default. We shouldn't ever use this
    //         }
    //     } else {
    //         const minute = bookingStart.getMinutes();
    //         let position = (minute / 60) * 100;
    //         const startPosition = `${position.toFixed(2)}%`;
    //         return startPosition;
    //     }
    // }, [booking]);

    // const bookingWidth = useMemo(() => {
    //     if (multiDay) {
    //         if (!multiDay.startsToday && !multiDay.endsToday) {
    //             const hours = multiDay.endingHour - multiDay.startingHour;
    //             const width = hours * 100;
    //             return width.toFixed(2) + "%";
    //         } else if (multiDay.startsToday && !multiDay.endsToday) {
    //             const hours = multiDay.endingHour - bookingStart.getHours();
    //             const minutes = bookingStart.getMinutes();
    //             const width = hours * 100 - (minutes / 60) * 100;
    //             return width.toFixed(2) + "%";
    //         } else if (!multiDay.startsToday && multiDay.endsToday) {
    //             if (bookingEnd.getHours() < multiDay.startingHour) {
    //                 return "0%";
    //             } else if (bookingEnd.getHours() === multiDay.startingHour) {
    //                 if (bookingEnd.getMinutes() !== 0) {
    //                     return ((bookingEnd.getMinutes() / 60) * 100).toFixed(2) + "%";
    //                 }
    //             } else {
    //                 const hours = bookingEnd.getHours() - multiDay.startingHour;
    //                 const minutes = bookingEnd.getMinutes();
    //                 const width = hours * 100 + (minutes / 60) * 100;
    //                 return width.toFixed(2) + "%";
    //             }
    //         } else if (multiDay.startsToday && multiDay.endsToday) {
    //             //this ons is the default. We shouldn't ever use this
    //         }
    //     } else {
    //         const hours = bookingEnd.getHours() - bookingStart.getHours();
    //         const minutes = bookingEnd.getMinutes() - bookingStart.getMinutes();

    //         //get width in percentage
    //         const width = hours * 100 + (minutes / 60) * 100;

    //         return width.toFixed(2) + "%";
    //     }
    // }, [booking]);

    const startPosition = useMemo(() => {
        if (positioningMode === PositioningMode.HALF_AND_FULL_DAY) {
            // Start at the beginning of the cell for both full-day and morning half-day bookings
            return bookingStart.getHours() < 12 ? '0%' : '50%';

        }
        else if (positioningMode === PositioningMode.HOURLY_SEGMENTS) {
            const startHour = Math.max(bookingStart.getHours(), DAY_START_HOUR);
            const startPosition = ((startHour - DAY_START_HOUR) / TOTAL_SEGMENTS) * 100;
            return `${startPosition}%`;
        }
    }, [bookingStart]);

    const bookingWidth = useMemo(() => {
        if (positioningMode === PositioningMode.HALF_AND_FULL_DAY) {
            const isFullDay = bookingStart.getHours() < 12 && bookingEnd.getHours() > 12;

            // Check for negligible duration
            const durationMinutes = (bookingEnd.getTime() - bookingStart.getTime()) / (1000 * 60);
            if (durationMinutes <= 0) {
                return '0%';
            }

            return isFullDay ? '100%' : '50%';

        }
        else if (positioningMode === PositioningMode.HOURLY_SEGMENTS) {
            const startHour = Math.max(bookingStart.getHours(), DAY_START_HOUR);
            const endHour = Math.min(bookingEnd.getHours(), DAY_END_HOUR);
            const width = ((endHour - startHour) / TOTAL_SEGMENTS) * 100;
            return `${Math.max(0, width)}%`;
        }
    }, [bookingStart, bookingEnd]);

    const getStyleColors = () => {
        const { confirmed, rescheduled, bookingType } = booking;

        switch (bookingType) {
            case BookingType.BUFFER_DAY:
                setBackgroundColor("bg-blue-400");
                setBackgroundColorHover("bg-blue-500");
                return;
            case BookingType.TIME_OFF:
                setBackgroundColor("bg-yellow-400");
                setBackgroundColorHover("bg-yellow-500");
                return;

            case BookingType.NON_FIELD_WORK:
                setBackgroundColor("bg-orange-400");
                setBackgroundColorHover("bg-orange-500");
                return;
            default:
                break;
        }

        if (confirmed) {
            setBackgroundColor("bg-green-400");
            setBackgroundColorHover("bg-green-500");
        }
        else if (rescheduled) {
            setBackgroundColor("bg-red-400");
            setBackgroundColorHover("bg-red-500");
        }
        else {
            setBackgroundColor("bg-gray-400");
            setBackgroundColorHover("bg-gray-500");
        }
    }

    const renderTimeLabel = () => {
        const relevantBookingTypes = [
            BookingType.IN_HOME_DESIGN_CONSULTATION,
            BookingType.PHONE_DESIGN_CONSULTATION,
            BookingType.REPAIR,
            BookingType.WARRANTY_REPAIR,
        ];

        if (relevantBookingTypes.includes(booking.bookingType)) {
            return (
                <div className="truncate text-xs font-light">{timeLabel}</div>
            );
        }
    }

    const renderInstallLabelOptions = () => {
        if (booking.bookingType === BookingType.INSTALL) {
            return (
                <>
                    <div className="truncate text-xs font-light">{booking.install?.ftSold && `${booking.install.ftSold}'`}</div>
                    <div className="truncate text-xs font-light">{booking.install?.houseLevel}</div>
                </>
            );
        }
    }

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        getStyleColors();
    }, [booking]);

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={
                booking.bookingType !== BookingType.EXTERNAL && booking.bookingType !== BookingType.UNAVAILABLE
                    ? () => onBookingClick({ data: { booking, row } })
                    : undefined
            }
            className={`absolute top-0 bottom-0 h-full flex items-center justify-center ${bookingWidth === "0%" ? "hidden" : ""}`}
            style={{
                left: isHovered ? '0%' : startPosition,
                width: isHovered ? '100%' : bookingWidth,
                zIndex: isHovered ? 10 : 2,
                transition: 'width 0.3s ease, left 0.3s ease'
            }}
        >
            <div
                className={`w-full flex flex-col rounded-lg ${backgroundColor} hover:${backgroundColorHover} border ${borderColor} hover:shadow-lg transform duration-100 ${textColor} cursor-pointer shadow-md`}
                style={{ height: "95%" }}
            >
                <div className="truncate text-xs font-light">{`${booking.title}`}</div>
                {renderInstallLabelOptions()}
                {renderTimeLabel()}
            </div>
            {booking.notifyCustomer && (
                <div className="absolute bottom-0 right-0 p-1">
                    <FontAwesomeIcon icon={faBell} color="#FFD700" size="sm" />
                </div>
            )}
        </div>
    );
}
