import { IProductVariant, IShippingItem, VariantAttribute } from "@common.abstractions";
import { faChevronRight, faShoppingBag, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./_sideCart.scss";

export default function SideCart2(props: {
    shippingItems: IShippingItem[];
    visible: boolean;
    closeSideCart: () => void;
    openSideCart: () => void;
    deleteCartItem: (index: number) => void;
    editQty: (index: number, itemQty: number) => void;
    loaded: boolean;
    handleShipping: () => void
    slide: number
}) {
    const { shippingItems, visible, closeSideCart, openSideCart, deleteCartItem, editQty, loaded, handleShipping, slide } = props;

    return (
        <>
            {loaded ? (
                <div className={`side_cart_wrapper ${visible ? "show" : ""}`}>
                    <div onClick={closeSideCart} className="close_side_cart_wrapper">
                        <div className="close_side_cart">
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                        <div className="side_cart_title">Cart</div>
                    </div>
                    <div className="products_wrapper">
                        {shippingItems.length ?
                            shippingItems.map((item, index) => {
                                return (
                                    <div key={index} className="flex items-end relative mx-4 my-8">
                                        <div
                                            className="absolute top-0 right-1 text-gray-500 hover:text-gray-600 cursor-pointer"
                                            onClick={() => deleteCartItem(index)}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </div>
                                        <div className="product_info">
                                            <div className="text-gray-500 text-md leading-tight">
                                                {item.title}
                                            </div>
                                            <div className="product_variants_wrapper">
                                                {(item.productVariant as IProductVariant).attributes.length
                                                    ? ((item.productVariant as IProductVariant).attributes as VariantAttribute[]).map((attr) => {
                                                        return (
                                                            <div key={attr.name + attr.value} className="product_variant leading-none">
                                                                <span className="text-gray-500 text-sm mr-1 leading-none">{`${attr.name}:`}</span>
                                                                <span className="text-gray-500 text-sm leading-none">{`${attr.value}`}</span>
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                            </div>
                                        </div>
                                        <div className="qty_wrapper ml-4">
                                            <input
                                                className="w-16 text-gray-600 text-md border-gray-300 p-1 border"
                                                type="number"
                                                value={item.quantity}
                                                onChange={(e) => {
                                                    editQty(index, parseInt(e.target.value))
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            }) : (
                                <div className="text-gray-500 mt-5 text-center">There are no items in cart</div>
                            )}
                    </div>

                    {slide === 1 ?
                        <button className="checkout_process_button button next checkout" onClick={() => handleShipping()}>
                            Shipping Options
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button> :
                        <button className="checkout_process_button button next checkout" onClick={() => handleShipping()}>
                            Product Catalog
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>}
                </div>
            ) : null}

            {!visible ? (
                <div className="side_cart_min" onClick={openSideCart}>
                    {
                        shippingItems && (
                            <div className="bg-white shadow-md absolute rounded-full h-6 w-6 flex items-center justify-center -top-1 -right-1 text-xs text-gray-600">{shippingItems.length}</div>
                        )
                    }
                    <FontAwesomeIcon icon={faShoppingBag} />
                </div>
            ) : (
                <div className={`side_cart_background`} onClick={closeSideCart}></div>
            )}
        </>
    );
}