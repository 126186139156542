import { BookingType } from "@common.abstractions";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@frontend/components/misc/button/Button";
import { Modal } from "@frontend/components/misc/modal";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm, useWatch } from "react-hook-form";
import Select from "react-select";
import { FormInputType, FormData } from "../models/formInput";
import { formatDateStringWithDayOfWeek } from "@common.tools";

type Props = {
    top?: number;
    left?: number;
    visible: boolean;
    closeModal: any;
    user: { value: number; label: string };
    startDate: Date;
    formData: FormData[];
    modalTitle: string;
    onSubmit: any;
    onClick?: () => void;
};

export function DailyNoteModal(props: Props) {
    const {
        top,
        left,
        visible,
        closeModal,
        startDate,
        user,
        formData,
        onSubmit,
    } = props;

    const {
        register,
        control,
        // watch,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<any>({
        mode: "onSubmit",
        reValidateMode: "onChange",
        criteriaMode: "firstError",
        shouldFocusError: true,
    });

    const renderFormInputs = (formData: FormData) => {
        switch (formData.formInputType) {
            case FormInputType.INPUT:
                return (
                    <input
                        id={formData.name}
                        {...register(formData.name, { required: formData.required })}
                        className={`w-full input ${errors[formData.name] ? "ring-red-400" : ""}`}
                        placeholder={formData.placeholder}
                        defaultValue={formData.defaultValue}
                        onChange={(e) => setValue(formData.name, e.target.value)}
                    />
                );
            case FormInputType.TEXTAREA:
                return (
                    <textarea
                        id={formData.name}
                        {...register(formData.name, { required: formData.required })}
                        className={`w-full input ${errors[formData.name] ? "ring-red-400" : ""}`}
                        placeholder={formData.placeholder}
                        defaultValue={formData.defaultValue}
                        onChange={(e) => setValue(formData.name, e.target.value)}
                    />
                );
            case FormInputType.SELECT:
                return (
                    <Controller
                        control={control}
                        name={formData.name}
                        rules={{
                            required: formData.required,
                        }}
                        render={({ field }) => (
                            <Select
                                id={formData.name}
                                className="quotes_date_dropdown w-full"
                                onKeyDown={formData.onKeyDown}
                                isLoading={formData.isLoading}
                                placeholder={formData.placeholder}
                                options={formData.options}
                                {...field}
                                onChange={(e) => {
                                    // Call the original onChange from the 'field' object first
                                    field.onChange(e);
                                    // Then call your custom onChange function here
                                    formData.onChange && formData.onChange(e);
                                }}
                            />
                        )}
                    />
                );
            case FormInputType.CHECKBOX:
                return (
                    <input
                        id={formData.name}
                        type="checkbox"
                        {...register(formData.name, { required: formData.required })}
                        className={`text-gray-400 ${errors[formData.name] ? "ring-red-400" : ""}`}
                        onChange={(e) => setValue(formData.name, e.target.checked)}
                        defaultChecked={formData.defaultValue}
                    />
                );
            case FormInputType.TEXT:
                return <p className="text-gray-400">{formData.defaultValue}</p>;
        }
    };

    const formatDate = (date: Date) => {
        const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];

        const month = String(date.getMonth()).padStart(2,'0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = String(date.getFullYear()).padStart(2, '0');
        const dayOfWeek = days[date.getDay()];

        return `${month}/${day}/${year} ${dayOfWeek}`;
    };

    const setDefaultFormValues = () => {
        formData.forEach((el) => {
            // My attempt to ensure that the startDate remains unaffected by changes to the quoteRequest after it has been modified by the user.
            if (el.defaultValue && el.name !== "startDate" && el.name !== "endDate") {
                setValue(el.name, el.defaultValue);
            }
        });
    };

    const createBooking = (data: any) => {
        onSubmit(data);
    };

    // const renderBookingTypeOptions = () => {
    //     if (calendarType === 'Operations') {
    //         return [
    //             { label: BookingType.INSTALL, value: BookingType.INSTALL },
    //             { label: BookingType.REPAIR, value: BookingType.REPAIR },
    //             { label: BookingType.THIRD_PARTY_REPAIR, value: BookingType.THIRD_PARTY_REPAIR, },
    //         ];
    //     } else if (calendarType === 'Consultation') {
    //         return [
    //             {
    //                 label: BookingType.IN_HOME_DESIGN_CONSULTATION,
    //                 value: BookingType.IN_HOME_DESIGN_CONSULTATION,
    //             },
    //             {
    //                 label: BookingType.PHONE_DESIGN_CONSULTATION,
    //                 value: BookingType.PHONE_DESIGN_CONSULTATION,
    //             },
    //         ];
    //     }
    //     return [];
    // };

    // useEffect(() => {
    //     if ((watchBookingType as { label: string; value: string })?.value) {
    //         onBookingTypeChange((watchBookingType as { label: string; value: string }).value);
    //     }
    // }, [watchBookingType]);

    useEffect(() => {
        if (user) {
            setValue("user", user);
        } else {
            setValue("user", 0);
        }
    }, [user]);

    // useEffect(() => {
    //     if (watchStartDate && watchEndDate) {
    //         if (watchBookingType?.value === BookingType.IN_HOME_DESIGN_CONSULTATION) {
    //             const newEndDate = new Date(watchStartDate);
    //             newEndDate.setTime(newEndDate.getTime() + 1.5 * 60 * 60 * 1000); // 1.5 hours in milliseconds
    //             if (watchEndDate && newEndDate.getTime() !== watchEndDate.getTime()) {
    //                 setValue("endDate", newEndDate);
    //             }
    //         } else if (watchBookingType?.value === BookingType.PHONE_DESIGN_CONSULTATION) {
    //             const newEndDate = new Date(watchStartDate);
    //             newEndDate.setTime(newEndDate.getTime() + 45 * 60 * 1000); // 45 minutes in milliseconds
    //             if (watchEndDate && newEndDate.getTime() !== watchEndDate.getTime()) {
    //                 setValue("endDate", newEndDate);
    //             }
    //         }
    //     }
    // }, [watchStartDate, watchEndDate, watchBookingType, setValue]);

    useEffect(() => {
        if (!props.visible) {
            reset();
        }
    }, [props.visible]);

    useEffect(() => {
        setDefaultFormValues();
    }, [formData]);

    // useEffect(() => {
    //     setValue("startDate", startDate);
    // }, [startDate]);

    // useEffect(() => {
    //     setValue("endDate", endDate);
    // }, [endDate]);

    return (
        <Modal visible={visible} closeModal={closeModal} top={top} left={left}>
            <form onSubmit={handleSubmit(createBooking)}>
                <h2 className="px-6 py-4 text-xl text-gray-500">{user.label} - {formatDateStringWithDayOfWeek(startDate)}</h2>
                <div className="h-px bg-blue-500" />
                <div className="max-w-full overflow-y-auto">
                    <div className="flex flex-col p-4">
                        {formData.map((el, i) => {
                            return (
                                <div className="flex py-3 flex-col" key={i}>
                                    {el.label && (
                                        <label className="text-gray-500 text-xs pb-2" htmlFor={el.name}>{el.label}
                                        </label>
                                    )}
                                    <div className="text-gray-600 flex-grow relative">
                                        {renderFormInputs(el)}
                                        <div className="text-xs text-red-400 absolute top-full">
                                            {errors[el.name]?.message?.toString()}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        <div className="py-8 w-full flex items-center justify-center">
                            <Button onClick={closeModal} className="mr-2" style="outline" color="gray">
                                Cancel
                            </Button>
                            <Button type="submit" style="solid" color="blue">
                                Save
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal>
    );
}
