export namespace PortalEvent {
    export enum Lead {
        CREATED = "lead.created",
        CREATEDANY = "lead.createdAny",
    }
    export enum QuoteRequest {
        UNSERVICEABLE = "quoteRequest.unserviceable",
        DIY = "quoteRequest.diy"
    }
}

export const PortalEventDescriptions = {
    [PortalEvent.Lead.CREATED]: "Fires when a new lead is received for webhook owner's company through form submission. Currently doesn't fire on leads from other sources.",
    [PortalEvent.Lead.CREATEDANY]: "Fires when any new lead is received through form submission. Currently doesn't fire on leads from other sources.",
    [PortalEvent.QuoteRequest.UNSERVICEABLE]: "Fires when a new unserviceable quote request is received",
    [PortalEvent.QuoteRequest.DIY]: "Fires when a new diy quote request is received",
};

export type PortalEvent =
    | PortalEvent.Lead
    | PortalEvent.QuoteRequest

