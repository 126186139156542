import {
    AdminGrantsPreset, BranchManagerGrantsPreset, DealerGrantsPreset, DeveloperGrantsPreset, int, Scope,
    SuccessGrantsPreset, InstallerGrantsPreset, SalesGrantsPreset
} from "@common.abstractions";
import Button from "@frontend/components/misc/button/Button";
import { Loading } from "@frontend/components/misc/loading";
import { Modal } from "@frontend/components/misc/modal";
import AuthContext from "@frontend/contexts/AuthContext";
import { RetrievePortalScopes } from "@frontend/services/scopes";
import { RetrieveUserGrants } from "@frontend/services/users";
import { useContext, useEffect, useState } from "react";

interface IUserPermissionsModalProps {
    userId: int;
    saveUpdatedGrants: (scopeIds: Array<number>) => void;
    closeModal: () => void;
}

export default function UserPermissionsModal(props: IUserPermissionsModalProps) {
    const authContext = useContext(AuthContext);
    const [scopes, setScopes] =
        useState<Array<{ scope: { id: number; moniker: string; description: string }; selected: boolean, initialState: boolean }>>();

    const loadGrantEditData = async () => {
        setScopes(undefined);
        const userGrantsPromise = RetrieveUserGrants(props.userId);
        const portalScopesPromise = RetrievePortalScopes();
        const userGrantsResponse = await userGrantsPromise;
        if (!userGrantsResponse) return;
        const portalScopesResponse = await portalScopesPromise;
        if (!portalScopesResponse.success) return;

        const grantedScopeIds = new Set(userGrantsResponse.map((g) => g.id));
        const loadedScopes = portalScopesResponse.data.map((s) => {
            const selected = grantedScopeIds.has(s.id);
            return {
                scope: s,
                selected,
                initialState: selected
            };
        });
        setScopes(loadedScopes);
    };

    const applyPreset = (presetMonikers: Array<Scope>) => {
        if (scopes === undefined) return;
        const preset = new Set(presetMonikers.map(m => m.toLowerCase()));
        for (const s of scopes) {
            s.selected = preset.has(s.scope.moniker.toLowerCase());
        }

        setScopes([...scopes]);
    }

    const setPresetGrants = (preset: "admin" | "dealer" | "branch manager" | "dev" | 'sales' | 'installers' | 'success') => {
        switch (preset) {
            case "admin":
                applyPreset(AdminGrantsPreset);
                break;
            case "dealer":
                applyPreset(DealerGrantsPreset);
                break;
            case "dev":
                applyPreset(DeveloperGrantsPreset);
                break;
            case "branch manager":
                applyPreset(BranchManagerGrantsPreset);
                break;
            case "sales":
                applyPreset(SalesGrantsPreset);
                break;
            case "installers":
                applyPreset(InstallerGrantsPreset);
                break;
            case "success":
                applyPreset(SuccessGrantsPreset);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        loadGrantEditData();
    }, []);

    if (scopes == undefined) return <Loading fullscreen={true} />;

    const getScopeBGColor = (s: { selected: boolean, initialState: boolean }) => {
        const { selected, initialState } = s;
        if (selected === initialState) return '';
        if (selected) return 'bg-green-100'
        else return 'bg-red-100'
    }

    return (
        <Modal closeModal={props.closeModal} visible={true}>
            <div className="flex flex-col overflow-hidden">
                {authContext?.hasAnyGrant(Scope.User.UPDATE_ANY) && (
                    <div className="flex px-4 mt-8">
                        <Button
                            type="button"
                            rounded
                            className="mr-2"
                            style={"outline"}
                            color="blue"
                            onClick={() => setPresetGrants("branch manager")}
                        >
                            Branch Manager
                        </Button>
                        <Button
                            type="button"
                            rounded
                            className="mr-2"
                            style={"outline"}
                            color="blue"
                            onClick={() => setPresetGrants("dealer")}
                        >
                            Dealer
                        </Button>
                        <Button
                            type="button"
                            rounded
                            className="mr-2"
                            style={"outline"}
                            color="blue"
                            onClick={() => setPresetGrants("dev")}
                        >
                            Developer
                        </Button>
                        <Button
                            type="button"
                            rounded
                            className="mr-2"
                            style={"outline"}
                            color="blue"
                            onClick={() => setPresetGrants("admin")}
                        >
                            Admin
                        </Button>
                        <Button
                            type="button"
                            rounded
                            className="mr-2"
                            style={"outline"}
                            color="blue"
                            onClick={() => setPresetGrants("sales")}
                        >
                            Sales
                        </Button>
                        <Button
                            type="button"
                            rounded
                            className="mr-2"
                            style={"outline"}
                            color="blue"
                            onClick={() => setPresetGrants("installers")}
                        >
                            Installers
                        </Button>
                        <Button
                            type="button"
                            rounded
                            className="mr-2"
                            style={"outline"}
                            color="blue"
                            onClick={() => setPresetGrants("success")}
                        >
                            Success
                        </Button>
                        <Button
                            type="button"
                            rounded
                            style={"solid"}
                            color="blue"
                            onClick={() =>
                                props.saveUpdatedGrants(scopes.filter((s) => s.selected).map((s) => s.scope.id))
                            }
                        >
                            Save Permissions
                        </Button>
                    </div>
                )}
                <div className="flex flex-col overflow-y-scroll p-4 my-4 ">
                    {scopes.map((s) => {
                        return (
                            <label
                                key={s.scope.id}
                                className={"cursor-pointer block p-2 mb-4 duration-200 rounded-sm " + getScopeBGColor(s)}
                            >
                                <div className="text-gray-600 capitalize">
                                    {s.scope.moniker}
                                    {/* {grant.replace(/([A-Z])/g, " $1").replace(/\./g, " ")} */}
                                    <input
                                        className="ml-2"
                                        type="checkbox"
                                        checked={s.selected}
                                        onChange={(e) => {
                                            s.selected = e.target.checked;
                                            setScopes([...scopes]);
                                        }}
                                    />
                                </div>
                                <div className="text-sm text-gray-500">{s.scope.description}</div>
                            </label>
                        );
                    })}
                </div>
            </div>
        </Modal>
    );
}
