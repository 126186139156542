import React from "react";
import { Link } from "react-router-dom";
interface IButton {
    onClick?: () => any;
    type?: "button" | "submit" | "reset" | undefined;
    style: "outline" | "soft" | "solid" | undefined;
    children: any;
    color: string | "blue" | "green" | "yellow" | "red" | "gray";
    className?: string;
    rounded?: boolean;
    link?: string;
}

export default function Button(props: IButton) {
    const { onClick, type, style, children, color, className, rounded, link } = props;

    return link ? (
        <Link to={link}>
            <button
                className={`
                ${
                    style === "outline"
                        ? color === "blue"
                            ? "border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white"
                            : color === "green"
                            ? "border border-green-500 text-green-500 hover:bg-green-500 hover:text-white"
                            : color === "yellow"
                            ? "border border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white"
                            : color === "red"
                            ? "border border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                            : color === "gray"
                            ? "border border-gray-200 text-gray-500 hover:bg-gray-500 hover:text-white"
                            : ""
                        : ""
                }
                ${
                    style === "solid"
                        ? color === "blue"
                            ? "border border-transparent bg-blue-500 text-white"
                            : color === "green"
                            ? "border border-transparent bg-green-500 text-white"
                            : color === "yellow"
                            ? "border border-transparent bg-yellow-500 text-white"
                            : color === "red"
                            ? "border border-transparent bg-red-500 text-white"
                            : color === "gray"
                            ? "border border-transparent bg-gray-500 text-white"
                            : ""
                        : ""
                }
                ${
                    style === "soft"
                        ? color === "blue"
                            ? "border border-transparent bg-blue-200 text-blue-500"
                            : color === "green"
                            ? "border border-transparent bg-green-200 text-green-500"
                            : color === "yellow"
                            ? "border border-transparent bg-yellow-200 text-yellow-500"
                            : color === "red"
                            ? "border border-transparent bg-red-200 text-red-500"
                            : color === "gray"
                            ? "border border-transparent bg-gray-200 text-gray-500"
                            : ""
                        : ""
                }
                ${className} ${
                    rounded ? "rounded-full" : ""
                } flex items-center justify-center py-1 px-3 text-sm rounded-sm whitespace-nowrap duration-100 outline-none focus:outline-none 
            `}
                type={type ?? "button"}
                onClick={onClick ? onClick : undefined}
            >
                {children}
            </button>
        </Link>
    ) : (
        <button
            className={`
                ${
                    style === "outline"
                        ? color === "blue"
                            ? "border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white"
                            : color === "green"
                            ? "border border-green-500 text-green-500 hover:bg-green-500 hover:text-white"
                            : color === "yellow"
                            ? "border border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-white"
                            : color === "red"
                            ? "border border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                            : color === "gray"
                            ? "border border-gray-200 text-gray-500 hover:bg-gray-500 hover:text-white "
                            : ""
                        : ""
                }
                ${
                    style === "solid"
                        ? color === "blue"
                            ? "border border-transparent bg-blue-500 text-white"
                            : color === "green"
                            ? "border border-transparent bg-green-500 text-white"
                            : color === "yellow"
                            ? "border border-transparent bg-yellow-500 text-white"
                            : color === "red"
                            ? "border border-transparent bg-red-500 text-white"
                            : color === "gray"
                            ? "border border-transparent bg-gray-500 text-white"
                            : ""
                        : ""
                }
                ${
                    style === "soft"
                        ? color === "blue"
                            ? "border border-transparent bg-blue-200 text-blue-500"
                            : color === "green"
                            ? "border border-transparent bg-green-200 text-green-500"
                            : color === "yellow"
                            ? "border border-transparent bg-yellow-200 text-yellow-500"
                            : color === "red"
                            ? "border border-transparent bg-red-200 text-red-500"
                            : color === "gray"
                            ? "border border-transparent bg-gray-200 text-gray-500"
                            : ""
                        : ""
                }
                ${className} ${
                rounded ? "rounded-full" : ""
            } flex items-center justify-center py-1 px-3 text-sm rounded-sm whitespace-nowrap duration-100 outline-none focus:outline-none 
            `}
            type={type ?? "button"}
            onClick={onClick ? onClick : undefined}
        >
            {children}
        </button>
    );
}
