import { Base64 } from 'js-base64';

export interface IPaginationCursorV1 {
    offset: number;
}

export interface IPaginationRequest {
    cursor?: string; // Omitted in first request
    direction: 'forward' | 'backward';
    count: number;
}

export interface IPaginationResponse {
    begin_cursor?: string; // Omitted if the resources loaded are the beginning of the collection
    end_cursor?: string; // Omitted if there are no more resources in the collection
    count: number;
}

export interface IPaginatedResourceResponse<ResourceType> {
    pagination?: IPaginationResponse,
    values: Array<ResourceType>
}

export function tryEncodePaginationRequestData(paginationRequest?: IPaginationRequest) {
    if (!paginationRequest) return undefined;
    return Base64.encode(JSON.stringify(paginationRequest))
}

export function tryDecodePaginationRequestData(data?: string): IPaginationRequest | undefined {
    if (!data) return undefined;
    return JSON.parse(Base64.decode(data));
}

export function tryEncodePaginationResponseData(paginationRequest?: IPaginationResponse) {
    if (!paginationRequest) return undefined;
    return Base64.encode(JSON.stringify(paginationRequest))
}

export function tryDecodePaginationResponseData(data?: string): IPaginationResponse | undefined {
    if (!data) return undefined;
    return JSON.parse(Base64.decode(data));
}

export function tryEncodePaginationCursor(cur?: IPaginationCursorV1) {
    if (!cur) return undefined;
    return Base64.encode(JSON.stringify(cur));
}

export function tryDecodePaginationCursor(data?: string): IPaginationCursorV1 | undefined {
    if (!data) return undefined;
    return JSON.parse(Base64.decode(data));
}
