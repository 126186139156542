import { ICreateWarrantyFootage, IWarrantyFootage } from "@common.abstractions";
import { BACKEND_URL } from "@frontend/environment";
import axios from "axios";



export async function CreateWarrantyFootage(params: ICreateWarrantyFootage): Promise<IWarrantyFootage | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/warrantyFootage`, { ...params }, { withCredentials: true });
        if (response.status === 201) return response.data as IWarrantyFootage;

        console.log('Failed to create warranty footage with code: ', response.status, response.data)
        return undefined
    }
    catch (err: any) {
        console.log('Creating Warranty Footage threw an exception: ', err);
        return undefined;
    }
}