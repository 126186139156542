import ReactLoading from "react-loading";

export default function Loading(props: { text?: string,fullscreen?: boolean; center?: boolean }) {
    const { fullscreen, center, text } = props;

    if (fullscreen) {
        return (
            <div className="loader_fullscreen">
                <ReactLoading type={"bubbles"} color={"rgb(200,200,200"} height={70} width={70}/>
            </div>
        );
    } else if (center) {
        return <ReactLoading className="inset-center" type={"bubbles"} color={"rgb(200,200,200"} height={70} width={70}/>;
    } else {
        return <ReactLoading className="margin_center" type={"bubbles"} color={"rgb(200,200,200"} height={70} width={70} />;
    }
}
