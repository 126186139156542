import {
    int,
    IProduct,
    IProductVariant,
    VariantAttribute,
    IWarranty,
    ICreateWarrantyItemParams,
    IUpdateWarrantyItem,
    IUpdateWarrantyParams,
} from "@common.abstractions";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RetrieveProducts } from "@frontend/services/products";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Page } from "../misc/layout/Page";
import TopBar from "../misc/top-bar/TopBar";
import Select from "react-select";
import { CreateWarrantyItem, DeleteWarrantyItem, RetrieveWarrantyById, SendWarrantyEmail, UpdateWarranty, UpdateWarrantyItem } from "@frontend/services/warranty";
import { useParams } from "react-router-dom";
import { FileUploader, IFileUploader } from "@frontend/lib/uploader/uploader";
import { ImageUpload } from "../leads/create/ImageUpload";
import { CreateWarrantyImage } from "@frontend/services/upload-warranty-image";
import { useNavigate } from "react-router-dom-v5-compat";

export default function WarrantyCreate() {
    const [products, setProducts] = useState<IProduct[]>();
    const [itemName, setItemName] = useState<string>();
    const [quantity, setQuantity] = useState<int>(int(1));
    const [failureDescription, setFailureDescription] = useState<string>();
    const params = useParams<{ leadId: string, warrantyId: string }>();
    const [leadId] = useState<int>(int.parse(params.leadId));
    const [warrantyId] = useState<int>(int.parse(params.warrantyId));
    const [warranty, setWarranty] = useState<IWarranty>();

    const [imageUploadsState, setImageUploadsState] = useState<{ uploader: IFileUploader; file: File }[]>([]);
    const imageUploadsRef = useRef<Array<{ uploader: IFileUploader; file: File }>>([]);
    const hiddenFileInput = React.useRef<HTMLInputElement>(null);
    const supportedFileTypes = ["image/jpeg", "image/png", "image/gif"];

    const [hasVariants, setHasVariants] = useState<boolean>(false);
    const [dropDowns, setDropDowns] = useState<{ name: string; values: string[] }[]>();
    const [selected, setSelected] = useState<IProduct>();
    const [selectedCombo, setSelectedCombo] = useState<VariantAttribute[]>([]);
    const [selectedVariant, setSelectedVariant] = useState<IProductVariant>();
    const [variantExists, setVariantExists] = useState<boolean>(true);

    const navigate= useNavigate();

    const loadWarranty = async () => {
        const response = await RetrieveWarrantyById(warrantyId);
        setWarranty(response);
    }

    const loadProducts = async () => {
        const retrievedProducts = await RetrieveProducts({
            relations: ["variants"],
        });
        if (!retrievedProducts) {
            console.log('Could not load products');
            return;
        }

        const listedProducts = retrievedProducts.filter((el) => el.listed);
        listedProducts.sort((a, b) => {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;

            return 0;
        });
        setProducts(listedProducts);
    };

    const checkHasVariants = (product: IProduct) => {
        if (product && product.variants) {
            setSelectedVariant(product.variants[0] as IProductVariant);
            if (product.variants.length === 1) {
                if ((product.variants[0] as IProductVariant).attributes.length === 0) {
                    setHasVariants(false);
                } else {
                    createVariantDropdown(product);
                    setHasVariants(true);
                }
            } else {
                createVariantDropdown(product);
                setHasVariants(true);
            }
        }
    };

    useEffect(() => {
        loadProducts();
        loadWarranty();
    }, []);

    const handleChange = (val: int) => {
        const productId = val;
        const product = products?.find((el) => el.id === productId);

        if (product) {
            setSelected(product);
            setItemName(product.title);
            checkHasVariants(product);
            createVariantDropdown(product);
        }
    };

    const createVariantDropdown = (product: IProduct) => {
        const tempDropdowns: {
            name: string;
            values: string[];
        }[] = [];

        (product.variants as IProductVariant[]).forEach((el, i) => {
            el.attributes.forEach((attr, index) => {
                if (!tempDropdowns[index]) {
                    //Add the name to the object at array[i] if that index doesn't exist yet
                    tempDropdowns.push({ name: attr.name, values: [] });
                }
                if (!tempDropdowns[index].values.some((val) => val === attr.value)) {
                    //push the value to the values array in the object at array[i] if doesn't exist already
                    tempDropdowns[index].values.push(attr.value);
                }
            });
        });

        setDropDowns(tempDropdowns);

        const tempSelectedCombo: { name: string; value: string }[] = [];

        tempDropdowns.forEach((el, i) => {
            if (!tempSelectedCombo[i]) {
                tempSelectedCombo.push({ name: el.name, value: el.values[0] });
            } else {
                tempSelectedCombo[i].name = el.name;
                tempSelectedCombo[i].value = el.values[0];
            }
        });

        setSelectedCombo(tempSelectedCombo);
    };

    const handleComboChange = (value: string, i: number) => {
        const tempSelectedCombo = [...selectedCombo];
        tempSelectedCombo[i].value = value;

        setSelectedCombo(tempSelectedCombo);
        let variantExists: boolean = false;

        // Checks if the tempSelected Combo exists as a variant
        (selected!.variants as IProductVariant[]).find((el) => {
            if (el.attributes.length === tempSelectedCombo.length) {
                if (!el.attributes.some((val, index) => val.value !== tempSelectedCombo[index].value)) {
                    setSelectedVariant(el);
                    variantExists = true;
                }
            }
        });

        setVariantExists(variantExists);
    };

    const addItem = async () => {
        if (!itemName || !quantity) {
            toast.error("Please select Name and Quantity to add a product");
            return;
        }

        const itemParams: ICreateWarrantyItemParams = {
            quantity: quantity,
            item: {
                name: itemName,
                variant: selectedCombo
            }
        };
        const warrantyItem = await CreateWarrantyItem(warrantyId, itemParams)
        if (!warrantyItem) {
            toast.error("Error adding warranty item");
        }
        loadWarranty();
    };

    const deleteItem = async (itemId: int) => {
        await DeleteWarrantyItem(itemId);
        loadWarranty();
    }

    const handleUpdate = async (itemId: int, params: IUpdateWarrantyItem) => {
        const updateRes = await UpdateWarrantyItem(itemId, params)
        if (!updateRes) {
            toast.error('could not update item');
            return
        }
    }

    const sendWarrantyEmail = async (warrantyId: int) => {
        const response = await SendWarrantyEmail(warrantyId);
        if (!response) {
            console.log('Error sending warranty email');
            return;
        }
    }

    const addImageUpload = (imageUpload: { uploader: IFileUploader; file: File }) => {
        imageUploadsRef.current.push(imageUpload);
        setImageUploadsState([...imageUploadsRef.current]);
    }

    const removeImageUpload = (imageUpload: { uploader: IFileUploader; file: File }) => {
        imageUploadsRef.current.splice(imageUploadsRef.current.indexOf(imageUpload), 1);
        setImageUploadsState([...imageUploadsRef.current]);
    }

    const compareFiles = (a: File, b: File) => a.name === b.name && a.size === b.size && a.type === b.type;

    const imageSelectedHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const rawFiles = Array.from(event.target.files);
            event.target.value = "";
            const files = rawFiles
                .filter((file) => supportedFileTypes.includes(file.type))
                .filter((file) => !imageUploadsRef.current.filter((upload) => compareFiles(file, upload.file)).length) // No duplicates
                .filter((file) => file.size <= 3000000);

            const bigFiles = rawFiles.filter((file) => file.size > 3000000);
            bigFiles.forEach((file) => toast.warn(`File: ${file.name} is too big!`, { autoClose: 5000 }));

            const unsupportedFiles = rawFiles.filter((file) => !supportedFileTypes.includes(file.type));
            unsupportedFiles.forEach((file) => toast.warn(`File: ${file.name} is not a supported file type.`, { autoClose: 5000 }));

            const uploads = files.map(async (file) => {
                const upload = await CreateWarrantyImage(file.name, warrantyId);
                if (!upload) return undefined; // TODO: Handle failure
                const uploader = new FileUploader(upload.id, file, upload.uploadUrl);
                return { uploader, file };
            });

            const confirmeduploads = (await Promise.all(uploads)).filter((val) => val !== undefined) as {
                uploader: FileUploader;
                file: File;
            }[];

            confirmeduploads.forEach((upload) => {
                upload.uploader.start();
                addImageUpload(upload);
                upload.uploader.addOnCancelledListener(() => removeImageUpload(upload));
            });
        }
    };

    const submitForm = async () => {
        const submittedDate = new Date();
        const updatedParams: IUpdateWarrantyParams = {
            description: failureDescription,
            submittedDate
        }

        if (!failureDescription) {
            toast.error('Description needed before warranty submission');
            return;
        }

        const updatedResponse = await UpdateWarranty(warrantyId, updatedParams)
        if (!updatedResponse) {
            toast.error('Error submitting warranty');
            return;
        }

        toast.success('Claim submitted');
        sendWarrantyEmail(warrantyId);

        navigate(`/leads/${leadId}`);
    }

    return (
        <Page>
            <TopBar title="Warranty Creation" />
            <form>
                <label>Product Name</label>
                <Select
                    className="quotes_date_dropdown mb-3"
                    menuPosition="fixed"
                    options={products?.map((el) => ({
                        value: el.id,
                        label: el.title,
                    }))}
                    onChange={(val) => handleChange(int.parse(val?.value))}
                />

                {hasVariants ? (
                    <div className="flex mb-3">
                        {dropDowns?.map((el, i) => {
                            return (
                                <div
                                    key={`${el.name} + ${el.values.join("")} `}
                                    className="flex flex-col mr-5"
                                >
                                    <label>{el.name}</label>
                                    <select
                                        className="text-gray-600 text-md border-gray-300 p-1 border"
                                        onChange={(e) => handleComboChange(e.target.value, i)}
                                    >
                                        {el.values.map((value, index) => {
                                            return (
                                                <option key={index} value={value}>
                                                    {value}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            );
                        })}
                    </div>
                ) : null}

                <label>Quantity</label>
                <input
                    type="number"
                    value={quantity}
                    onChange={(e) => setQuantity(int(parseInt(e.target.value)))}
                    min={1}
                    className="border-gray-300 p-1 border text-gray-600 text-md w-20 mb-3"
                ></input>

                <button
                    type="button"
                    className="bg-blue-500 rounded-3xl w-40 mb-3 text-center text-white"
                    onClick={addItem}
                >
                    Add Product
                </button>

                <h1>Products Added</h1>
                <hr></hr>

                <div className="products_wrapper">
                    {warranty?.items.map((item) => {
                        return (
                            <div key={item.id} className="flex items-end relative mx-4 my-8">
                                <div
                                    className="absolute top-0 right-1 text-gray-500 hover:text-gray-600 cursor-pointer"
                                    onClick={() => deleteItem(item.id)}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </div>
                                <div className="product_info">
                                    <div className="text-gray-500 text-md leading-tight">
                                        {item.item.name}
                                    </div>
                                    <div className="product_variants_wrapper">
                                        {item.item.variant?.map((variant) => (
                                            <div key={variant.name + variant.value} className="product_variant leading-none">
                                                <span className="text-gray-500 text-sm mr-1 leading-none">{`${variant.name}:`}</span>
                                                <span className="text-gray-500 text-sm leading-none">{`${variant.value}`}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="qty_wrapper ml-4">
                                    <input
                                        className="w-16 text-gray-600 text-md border-gray-300 p-1 border"
                                        min="0"
                                        type="number"
                                        defaultValue={item.quantity}
                                        onChange={(e) => handleUpdate(item.id, { quantity: int(parseInt(e.target.value)) })}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="flex-1 flex flex-col mr-5">
                    <label className="text-gray-500" htmlFor="propertyPhotos">
                        Property Photos
                    </label>
                    <div className="text-center relative">
                        <div className="text-center py-10 border-gray-300 border mt-2 text-gray-500">
                            {imageUploadsState.length === 0 && "No Photos"}

                            {imageUploadsState.length > 0 &&
                                imageUploadsState.map((upload) => {
                                    return <ImageUpload key={upload.uploader.id} fileUploader={upload.uploader} file={upload.file} />;
                                })}
                        </div>

                        <button type="button" className="btn bg-theme_gold text-white mb-3 mt-3" onClick={() => hiddenFileInput!.current!.click()}>
                            Select Photos
                        </button>

                        <input type="file" style={{ display: "none" }} ref={hiddenFileInput} multiple onChange={imageSelectedHandler} />
                    </div>
                </div>

                <div className="mt-3">
                    <h1>Detailed Description of Failure</h1>
                    <textarea
                        className="border-solid border-2 rounded-md h-36 w-full p-4 text-base resize-none"
                        value={failureDescription}
                        onChange={(e) => setFailureDescription(e.target.value)}
                    ></textarea>
                </div>

                <button
                    className="bg-blue-500 text-white p-2 rounded-3xl w-32"
                    type="button"
                    onClick={submitForm}
                >
                    Submit
                </button>
            </form>
        </Page>
    );
}
