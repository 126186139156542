import { BookingType, int } from "@common.abstractions";
import { faTimes, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@frontend/components/misc/button/Button";
import { Modal } from "@frontend/components/misc/modal";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm, useWatch } from "react-hook-form";
import Select from "react-select";
import { FormInputType, FormData } from "../models/formInput";

type Props = {
    top?: number;
    left?: number;
    disableChangingType?: boolean;
    visible: boolean;
    closeModal: any;
    bookingType?: string;
    user?: { value: number; label: string };
    title?: string;
    startDate?: Date;
    endDate?: Date;
    formData: FormData[];
    modalTitle: string;
    onSubmit: any;
    onDelete?: any;
    onBookingTypeChange: any;
    notificationLastSent?: Date;
    onClick?: () => void;
    showButton?: boolean;
    calendarType: string;
    handleAddSelect?: () => void;
    handleRemoveSelect?: () => void;
    additionalSelects?: number;
    bookingId?: int;
    installNotes?: string;
    footage?: string;
    houseLevel?: string;
    typeOfLights?: string;
    openFootageModal?: () => void;
    repairJobCompleted?: () => void;

    //HANDLE MULTIDAY
    multiDay?: boolean;
    handleMultiDay?: (e: boolean) => void;
    additionalDays?: { startDate: Date; endDate: Date }[];
    addDates?: () => void;
    removeDates?: (index: number) => void;
    onDateChange?: (index: number, type: 'startDate' | 'endDate', date: Date) => void;
};

export function BookingModal(props: Props) {
    const {
        top,
        left,
        visible,
        closeModal,
        bookingType,
        startDate,
        endDate,
        title,
        user,
        formData,
        onSubmit,
        onDelete,
        onBookingTypeChange,
        modalTitle,
        disableChangingType,
        notificationLastSent,
        showButton,
        calendarType,
        additionalSelects,
        handleAddSelect,
        handleRemoveSelect,
        bookingId,
        installNotes,
        houseLevel,
        footage,
        typeOfLights,
        openFootageModal,
        repairJobCompleted,

        //HANDLE MULTIDAY
        multiDay,
        handleMultiDay,
        additionalDays,
        addDates,
        removeDates,
        onDateChange,
    } = props;

    const {
        register,
        control,
        // watch,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<any>({
        mode: "onSubmit",
        reValidateMode: "onChange",
        criteriaMode: "firstError",
        shouldFocusError: true,
    });

    const watchBookingType = useWatch({ name: "bookingType", control });
    const watchBookableUser = useWatch({ name: "user", control });
    const watchStartDate = useWatch({ name: "startDate", control });
    const watchEndDate = useWatch({ name: "endDate", control });

    const renderFormInputs = (formData: FormData) => {
        switch (formData.formInputType) {
            case FormInputType.INPUT:
                return (
                    <input
                        id={formData.name}
                        {...register(formData.name, { required: formData.required })}
                        className={`w-full input ${errors[formData.name] ? "ring-red-400" : ""}`}
                        placeholder={formData.placeholder}
                        defaultValue={formData.defaultValue}
                        onChange={(e) => setValue(formData.name, e.target.value)}
                    />
                );
            case FormInputType.TEXTAREA:
                return (
                    <textarea
                        id={formData.name}
                        {...register(formData.name, { required: formData.required })}
                        className={`w-full input ${errors[formData.name] ? "ring-red-400" : ""}`}
                        placeholder={formData.placeholder}
                        defaultValue={formData.defaultValue}
                        onChange={(e) => setValue(formData.name, e.target.value)}
                    />
                );
            case FormInputType.SELECT:
                return (
                    <Controller
                        control={control}
                        name={formData.name}
                        rules={{
                            required: formData.required,
                        }}
                        render={({ field }) => (
                            <div className="relative">
                                <Select
                                    id={formData.name}
                                    className="quotes_date_dropdown w-full"
                                    onKeyDown={formData.onKeyDown}
                                    isLoading={formData.isLoading}
                                    placeholder={formData.placeholder}
                                    options={formData.options}
                                    {...field}
                                    onChange={(e) => {
                                        // Call the original onChange from the 'field' object first
                                        field.onChange(e);
                                        // Then call your custom onChange function here
                                        formData.onChange && formData.onChange(e);
                                    }}
                                />
                                {!bookingId && formData.name === "user" && (
                                    <div className="absolute top-0 right-0 flex items-center h-full">
                                        <button
                                            className="px-2 bg-blue-500 text-white rounded-l-lg hover:bg-blue-600"
                                            type="button"
                                            onClick={handleAddSelect}>
                                            <FontAwesomeIcon icon={faPlus} />
                                        </button>

                                        {additionalSelects ?
                                            additionalSelects > 0 && (
                                                <button className="px-2 bg-red-500 text-white rounded-r-lg hover:bg-red-600"
                                                    type="button"
                                                    onClick={handleRemoveSelect}>
                                                    <FontAwesomeIcon icon={faMinus} />
                                                </button>
                                            )
                                            : null
                                        }
                                    </div>
                                )}
                            </div>
                        )}
                    />
                );
            case FormInputType.CHECKBOX:
                return (
                    <input
                        id={formData.name}
                        type="checkbox"
                        {...register(formData.name, { required: formData.required })}
                        className={`text-gray-400 ${errors[formData.name] ? "ring-red-400" : ""}`}
                        onChange={(e) => setValue(formData.name, e.target.checked)}
                        defaultChecked={formData.defaultValue}
                    />
                );
            case FormInputType.TEXT:
                return <p className="text-gray-400">{formData.defaultValue}</p>;
        }
    };

    const renderModalTitle = () => {
        if (bookingId) return <h2 className="px-6 py-4 text-xl text-gray-500">Appointment Details - {bookingId}</h2>
        return <h2 className="px-6 py-4 text-xl text-gray-500">Appointment Details</h2>
    }

    const renderDateFields = () => {
        return (
            <div>
                {additionalDays?.map((day, index) => (
                    <div key={index} className="date-range mb-4">
                        {/* Start Date */}
                        <div className="mb-2">
                            <label htmlFor={`additionalDays[${index}].startDate`} className="block text-sm font-medium text-gray-700">
                                Start Date
                            </label>
                            <Controller
                                control={control}
                                name={`additionalDays[${index}].startDate`}
                                rules={{ required: "Start date is required" }}
                                render={({ field, fieldState }) => (
                                    <DatePicker
                                        {...field}
                                        selected={field.value}
                                        onChange={(date) => date && onDateChange && onDateChange(index, 'startDate', date)}
                                        showTimeSelect
                                        excludeTimes={[]}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        wrapperClassName="w-full"
                                        className={`input text-gray-600 text-sm sm:text-base ${fieldState.error ? "ring-red-400" : ""}`}
                                        timeIntervals={15}
                                    />
                                )}
                            />
                        </div>

                        {/* End Date */}
                        <div className="mb-4">
                            <label htmlFor={`additionalDays[${index}].endDate`} className="block text-sm font-medium text-gray-700">
                                End Date
                            </label>
                            <Controller
                                control={control}
                                name={`additionalDays[${index}].endDate`}
                                rules={{ required: "End date is required" }}
                                render={({ field, fieldState }) => (
                                    <DatePicker
                                        {...field}
                                        selected={field.value}
                                        onChange={(date) => date && onDateChange && onDateChange(index, 'endDate', date)}
                                        showTimeSelect
                                        excludeTimes={[]}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        wrapperClassName="w-full"
                                        className={`input text-gray-600 text-sm sm:text-base ${fieldState.error ? "ring-red-400" : ""}`}
                                        timeIntervals={15}
                                    />
                                )}
                            />
                        </div>

                        {/* Remove Date Range Button */}
                        {additionalDays.length > 1 && (
                            <button
                                onClick={() => removeDates && removeDates(index)}
                                className="text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded"
                            >
                                Remove
                            </button>
                        )}
                    </div>
                ))}

                {/* Add Date Range Button */}
                <button
                    onClick={addDates}
                    className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    Add Date Range
                </button>
            </div>
        );
    };

    const setDefaultFormValues = () => {
        if (bookingType) setValue("bookingType", { value: bookingType, label: bookingType });
        if (title) setValue("title", title);
        if (installNotes) setValue("installNotes", installNotes);
        if (houseLevel) setValue("houseLevel", houseLevel);
        if (footage) setValue("footage", footage);
        if (typeOfLights) setValue("typeOfLights", typeOfLights);

        formData.forEach((el) => {
            // My attempt to ensure that the startDate remains unaffected by changes to the quoteRequest after it has been modified by the user.
            if (el.defaultValue && el.name !== "startDate" && el.name !== "endDate") {
                setValue(el.name, el.defaultValue);
            }
        });
    };

    const createBooking = (data: any) => {
        onSubmit(data);
    };

    const renderBookingTypeOptions = () => {
        if (calendarType === 'Operations') {
            return [
                { label: BookingType.INSTALL, value: BookingType.INSTALL },
                { label: BookingType.REPAIR, value: BookingType.REPAIR },
                { label: BookingType.WARRANTY_REPAIR, value: BookingType.WARRANTY_REPAIR },
                { label: BookingType.BUFFER_DAY, value: BookingType.BUFFER_DAY },
                { label: BookingType.TIME_OFF, value: BookingType.TIME_OFF },
                { label: BookingType.NON_FIELD_WORK, value: BookingType.NON_FIELD_WORK }
            ];
        } else if (calendarType === 'Consultation') {
            return [
                {
                    label: BookingType.IN_HOME_DESIGN_CONSULTATION,
                    value: BookingType.IN_HOME_DESIGN_CONSULTATION,
                },
                {
                    label: BookingType.PHONE_DESIGN_CONSULTATION,
                    value: BookingType.PHONE_DESIGN_CONSULTATION,
                },
            ];
        }
        return [];
    };

    useEffect(() => {
        if ((watchBookingType as { label: string; value: string })?.value) {
            onBookingTypeChange((watchBookingType as { label: string; value: string }).value);
        }
    }, [watchBookingType]);

    useEffect(() => {
        if (user) {
            setValue("user", user);
        } else {
            setValue("user", 0);
        }
    }, [user]);

    useEffect(() => {
        if (watchStartDate && watchEndDate) {
            if (watchBookingType?.value === BookingType.IN_HOME_DESIGN_CONSULTATION) {
                const newEndDate = new Date(watchStartDate);
                newEndDate.setTime(newEndDate.getTime() + 1.5 * 60 * 60 * 1000); // 1.5 hours in milliseconds
                if (watchEndDate && newEndDate.getTime() !== watchEndDate.getTime()) {
                    setValue("endDate", newEndDate);
                }
            } else if (watchBookingType?.value === BookingType.PHONE_DESIGN_CONSULTATION) {
                const newEndDate = new Date(watchStartDate);
                newEndDate.setTime(newEndDate.getTime() + 45 * 60 * 1000); // 45 minutes in milliseconds
                if (watchEndDate && newEndDate.getTime() !== watchEndDate.getTime()) {
                    setValue("endDate", newEndDate);
                }
            }
            else if (watchBookingType?.value === BookingType.REPAIR || watchBookingType?.value === BookingType.INSTALL || watchBookingType?.value === BookingType.WARRANTY_REPAIR) {
                // prevent start and end date from reverting to default values if they have been updated by the user
                const newStartDate = new Date(watchStartDate);
                const newEndDate = new Date(watchEndDate);

                if (newStartDate.getTime() !== watchStartDate.getTime()) {
                    setValue("startDate", newStartDate);
                }
                if (newEndDate.getTime() !== watchEndDate.getTime()) {
                    setValue("endDate", newEndDate);
                }
            }
        }
    }, [watchStartDate, watchEndDate, watchBookingType, setValue]);

    useEffect(() => {
        if (!props.visible) {
            reset();
        }
    }, [props.visible]);

    useEffect(() => {
        setDefaultFormValues();
    }, [formData]);

    useEffect(() => {
        setValue("startDate", startDate);
    }, [startDate]);

    useEffect(() => {
        setValue("endDate", endDate);
    }, [endDate]);

    useEffect(() => {
        additionalDays?.forEach((day, index) => {
            setValue(`additionalDays[${index}].startDate`, day.startDate);
            setValue(`additionalDays[${index}].endDate`, day.endDate);
        });
    }, [additionalDays]);

    return (
        <Modal visible={visible} closeModal={closeModal} top={top} left={left}>
            <div className="sm:p-2 max-w-full mx-auto overflow-auto">
                {renderModalTitle()}
                <div className="h-px bg-blue-500" />
                <div className="overflow-y-auto">
                    <div className="flex flex-col p-4 sm:p-2">
                        {/* Type Field */}
                        <div className="flex py-3 flex-col">
                            <label className="text-gray-500 text-xs mb-2 sm:text-sm">Type*</label>
                            <div className="text-gray-600">
                                <Controller
                                    control={control}
                                    name={"bookingType"}
                                    rules={{ required: "required" }}
                                    render={({ field }) => (
                                        <Select
                                            className="w-full text-sm sm:text-base"
                                            isDisabled={disableChangingType}
                                            placeholder="Select a Booking Type"
                                            options={renderBookingTypeOptions()}
                                            {...field}
                                        />
                                    )}
                                />
                                <div className="text-xs text-red-400 absolute top-full sm:text-sm">
                                    {errors.bookingType?.message?.toString()}
                                </div>
                            </div>
                        </div>
                        {/* Start Date Field */}
                        <div className="flex py-3 flex-col">
                            <label className="text-gray-500 text-xs mb-2 sm:text-sm">Start*</label>
                            <div className="text-gray-600 flex flex-grow relative">
                                <Controller
                                    control={control}
                                    name="startDate"
                                    rules={{ required: "required" }}
                                    render={({ field, fieldState }) => (
                                        <DatePicker
                                            selected={field.value}
                                            showTimeSelect
                                            excludeTimes={[]}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            wrapperClassName="w-full"
                                            className={`input text-gray-600 text-sm sm:text-base ${errors.startDate ? "ring-red-400" : ""}`}
                                            timeIntervals={5}
                                            {...field}
                                        />
                                    )}
                                />
                                <div className="text-xs text-red-400 absolute top-full sm:text-sm">
                                    {errors.startDate?.message?.toString()}
                                </div>
                            </div>
                        </div>

                        {/* End Date Field */}
                        <div className="flex py-3 flex-col">
                            <label className="text-gray-500 text-xs mb-2 sm:text-sm">End*</label>
                            <div className="text-gray-600 flex flex-grow relative">
                                <Controller
                                    control={control}
                                    name="endDate"
                                    rules={{ required: "required" }}
                                    render={({ field, fieldState }) => (
                                        <DatePicker
                                            selected={field.value}
                                            showTimeSelect
                                            excludeTimes={[]}
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            wrapperClassName="w-full"
                                            className={`input text-gray-600 text-sm sm:text-base ${errors.endDate ? "ring-red-400" : ""}`}
                                            timeIntervals={5}
                                            {...field}
                                        />
                                    )}
                                />
                                <div className="text-xs text-red-400 absolute top-full sm:text-sm">
                                    {errors.endDate?.message?.toString()}
                                </div>
                            </div>
                        </div>

                        {!bookingId && (
                            <div className="flex py-3 flex-col">
                                <label className="text-gray-500 text-xs mb-2 sm:text-sm">Multi Day</label>
                                <div className="text-gray-600 flex flex-grow relative">
                                    <input
                                        type="checkbox"
                                        checked={multiDay}
                                        className="text-gray-400"
                                        onChange={(e) => handleMultiDay && handleMultiDay(e.target.checked)}
                                    />
                                </div>
                            </div>
                        )}

                        {multiDay && renderDateFields()}

                        {/* Additional Form Data Fields */}
                        {formData.map((el, i) => (
                            <div className="flex py-3 flex-col" key={i}>
                                {el.label && (
                                    <label className="text-gray-500 text-xs pb-2 sm:text-sm" htmlFor={el.name}>
                                        {el.label}
                                    </label>
                                )}
                                <div className="text-gray-600 flex-grow relative">
                                    {renderFormInputs(el)}
                                    <div className="text-xs text-red-400 absolute top-full sm:text-sm">
                                        {errors[el.name]?.message?.toString()}
                                    </div>
                                </div>
                            </div>
                        ))}

                        {/* Title Field */}
                        <div className="flex py-3 flex-col">
                            <label className="text-gray-500 text-xs mb-2 sm:text-sm">Title*</label>
                            <div className="text-gray-600 w-full relative">
                                <input
                                    {...register("title", { required: "required" })}
                                    className={`w-full input text-sm sm:text-base ${errors["title"] ? "ring-red-400" : ""}`}
                                    defaultValue={title}
                                    onChange={(e) => setValue("title", e.target.value)}
                                />
                                <div className="text-xs text-red-400 absolute top-full sm:text-sm">
                                    {errors.title?.message?.toString()}
                                </div>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="py-8 w-full flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-2">
                            {showButton && (
                                <Button onClick={props.onClick} className="mr-2" style="outline" color="yellow">
                                    Send Confirmation
                                </Button>
                            )}
                            {onDelete && (
                                <Button onClick={onDelete} className="mr-2" style="outline" color="red">
                                    Delete <FontAwesomeIcon className="ml-2" icon={faTimes} />
                                </Button>
                            )}
                            <Button onClick={closeModal} className="mr-2" style="outline" color="gray">
                                Cancel
                            </Button>
                            <Button className="mr-2" type="button" onClick={handleSubmit(createBooking)} style="solid" color="blue">
                                Save
                            </Button>
                            {bookingType === BookingType.INSTALL && bookingId && (
                                <Button className="mr-2" type="button" style="solid" color="yellow" onClick={openFootageModal}>
                                    Enter Footage
                                </Button>)
                            }
                            {bookingType === BookingType.REPAIR || bookingType === BookingType.WARRANTY_REPAIR && bookingId && (
                                <Button className="mr-2" type="button" style="solid" color="yellow" onClick={repairJobCompleted}>
                                    Mark as Completed
                                </Button>)
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
