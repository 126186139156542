import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { BookingType, IRepair, RepairType, Scope } from '@common.abstractions'
import { DeleteRepair, ManuallyCreateRepair, RetrieveRepair, RetrieveRepairs, UpdateRepair, RetrieveCount } from '../../services/repairs';
import TopBar from '../misc/top-bar/TopBar';
import { TableTabBar } from '../misc/table';
import CustomTable from '../misc/table/CustomTable';
import Button from '../misc/button/Button';
import AuthContext from '@frontend/contexts/AuthContext';
import { GetDate, formatMoney } from '@common.tools';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Loading } from '../misc/loading';
import SearchBar from '../misc/search-bar/SearchBar';
import { Page } from '../misc/layout';
import Select from 'react-select';
import { Modal } from '../misc/modal';
import { toast } from 'react-toastify';
import { Between, Exists, LessThan, Not } from '@common.api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCalendarCheck, faCalendarMinus, faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Row } from 'react-table';
import { useHistory } from 'react-router-dom';

enum RepairTab {
  ALL = 'All',
  SCHEDULED = 'Scheduled',
  NOT_SCHEDULED = 'Not Scheduled',
}

export default function Repairs() {
  const history = useHistory();
  const allRepairs = useRef<IRepair[]>();
  const scheduledRepairs = useRef<IRepair[]>();
  const notScheduledRepairs = useRef<IRepair[]>();
  const [displayedRepairs, setDisplayedRepairs] = useState<IRepair[]>([]);
  const cursor = useRef<string>();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<RepairTab>(RepairTab.ALL)
  const [searchCriteria, setSearchCriteria] = useState<string>('');

  const [timeFrame, setTimeFrame] = useState<{
    start: Date;
    end: Date;
    name: string;
  }>({ start: GetDate.nDaysAgo(30), end: GetDate.tomorrow(), name: "last30Days" });

  const countAll = useRef<number | undefined>(0);
  const countScheduled = useRef<number | undefined>(0);
  const countNotScheduled = useRef<number | undefined>(0);

  const [repairDateModal, setRepairDateModal] = useState(false);
  const [repairDate, setRepairDate] = useState<Date | undefined>(undefined);
  const [selectedRepair, setSelectedRepair] = useState<IRepair | undefined>(undefined);
  const [jobCompletedDateModal, setJobCompletedDateModal] = useState(false);
  const [jobCompletedDate, setJobCompletedDate] = useState<Date | undefined>(undefined);
  const [addRepairModal, setAddRepairModal] = useState(false);
  const [pandaDocId, setPandaDocId] = useState<string | undefined>(undefined);
  const [repairOptionsModal, setRepairOptionsModal] = useState(false);

  const authContext = useContext(AuthContext);

  const loadRepairs = async (num: number) => {
    setLoading(true);
    console.log(`Loading ${num} more repairs`);

    if (allRepairs.current?.length && !cursor.current) {
      console.log('No more repairs to load');
      return;
    }
    const response = await RetrieveRepairs(
      {
        relations: ['repairRep', 'branch', 'bookings'],
        filters: {
          createdAt: Between(timeFrame.start, timeFrame.end),
        }
      },
      {
        direction: 'forward',
        count: num,
        cursor: cursor.current
      }
    )
    if (!response) {
      console.warn('No response from load repairs call');
      setLoading(false);
      return;
    }
    if (!response.pagination) {
      console.error('Response missing pagination information');
      return;
    }
    cursor.current = response.pagination.end_cursor;

    const repairs = response.values;

    //Temp fix for duplicate repairs rendering
    const uniqueRepairs = repairs.filter((repair) => {
      return !allRepairs.current?.find((existingRepair) => existingRepair.id === repair.id);
    });

    allRepairs.current = [...(allRepairs.current ?? []), ...uniqueRepairs];
    renderDisplayedRepairs(selectedTab);
    setLoading(false);
  }

  const loadAll = () => {
    loadRepairs(100);
    if (cursor.current) {
      setTimeout(() => loadAll, 200);
    }
  }

  const renderDisplayedRepairs = (tab: RepairTab) => {
    switch (tab) {
      case RepairTab.ALL:
        setDisplayedRepairs(allRepairs.current ?? []);
        break;
      case RepairTab.SCHEDULED:
        setDisplayedRepairs(allRepairs.current?.filter((repair) => repair.repairDate && (new Date(repair.repairDate) > new Date())) ?? []);
        break;
      case RepairTab.NOT_SCHEDULED:
        setDisplayedRepairs(allRepairs.current?.filter((repair) => !repair.repairDate) ?? []);
        break;

      default:
        setDisplayedRepairs(allRepairs.current ?? []);
        break;
    }
  }

  const clearCounts = () => {
    countAll.current = undefined;
    countScheduled.current = undefined;
    countNotScheduled.current = undefined;
  }

  const openRepairDateModal = (repair: IRepair) => {
    setSelectedRepair(repair);
    setRepairDateModal(true);
  }
  const closeRepairDateModal = () => {
    setRepairDateModal(false);
    setSelectedRepair(undefined)
  }

  const clearRepairDate = async () => {
    if (!selectedRepair) return;

    if (selectedRepair.bookings.length) {
      const confirm = window.confirm('This repair is booked. Changing the date requires updating the calendar booking too. Continue?');
      if (!confirm) return;
    }

    const response = await UpdateRepair(selectedRepair.id, { repairDate: null });
    if (!response) {
      toast.error('Failed to clear repair date');
      return;
    }
    const updatedRepair = allRepairs.current?.find((repair) => repair.id === selectedRepair.id);
    updatedRepair && Object.assign(updatedRepair, response);

    renderDisplayedRepairs(selectedTab);
    toast.success('Repair date cleared');
    closeRepairDateModal();
  }

  const addRepairDate = async () => {
    if (!selectedRepair || !repairDate) return;

    if (selectedRepair.bookings.length) {
      const confirm = window.confirm('This repair is booked. Changing the date requires updating the calendar booking too. Continue?');
      if (!confirm) return;
    }


    const response = await UpdateRepair(selectedRepair.id, { repairDate });
    if (!response) return toast.error('Failed to add repair date');

    const updatedRepair = allRepairs.current?.find((repair) => repair.id === selectedRepair.id);
    updatedRepair && Object.assign(updatedRepair, response);

    renderDisplayedRepairs(selectedTab);
    toast.success('Repair date scheduled');
    closeRepairDateModal();
  }

  const openJobCompletedModal = (repair: IRepair) => {
    setSelectedRepair(repair);
    setJobCompletedDateModal(true);
  }
  const closeJobCompletedModal = () => {
    setSelectedRepair(undefined);
    setJobCompletedDateModal(false);
  }

  const clearJobCompletedDate = async () => {
    if (!selectedRepair) return;
    const response = await UpdateRepair(selectedRepair.id, { jobCompletedDate: null });
    if (!response) return toast.error('Failed to clear job completed date');

    const updatedRepair = allRepairs.current?.find((repair) => repair.id === selectedRepair.id);
    updatedRepair && Object.assign(updatedRepair, response);

    renderDisplayedRepairs(selectedTab);
    toast.success('Job Completed date cleared');
    closeJobCompletedModal();
  }

  const addJobCompletedDate = async () => {
    if (!selectedRepair) return;

    const response = await UpdateRepair(selectedRepair.id, { jobCompletedDate });
    if (!response) return toast.error('Failed to add job completed date');

    const updatedRepair = allRepairs.current?.find((repair) => repair.id === selectedRepair.id);
    updatedRepair && Object.assign(updatedRepair, response);

    renderDisplayedRepairs(selectedTab);
    toast.success('Saved');
    closeJobCompletedModal();
  }

  const openAddRepairModal = () => {
    setAddRepairModal(true);
  }
  const closeAddRepairModal = () => {
    setAddRepairModal(false);
    setPandaDocId(undefined);
  }

  const addRepairFromPandaDoc = async () => {
    if (!pandaDocId) return toast.error('Please enter a pandadoc id');
    const response = await ManuallyCreateRepair(pandaDocId);
    if (!response) return toast.error('Error creating repair from pandadoc');

    allRepairs.current = [response, ...allRepairs.current ?? []];

    renderDisplayedRepairs(selectedTab);
    toast.success('Repair created');
    setPandaDocId(undefined);
    closeAddRepairModal();
  }

  const openRepairOptionsModal = (repair: IRepair) => {
    setSelectedRepair(repair);
    setRepairOptionsModal(true);
  }
  const closeRepairOptionsModal = () => {
    setSelectedRepair(undefined);
    setRepairOptionsModal(false);
  }

  const deleteRepair = async () => {
    if (!selectedRepair) return;

    if (window.confirm('Are you sure you want to delete this repair?')) {
      const response = await DeleteRepair(selectedRepair.id);
      if (!response) return toast.error('Failed to delete repair');

      allRepairs.current = allRepairs.current?.filter((el) => el.id !== selectedRepair.id);

      renderDisplayedRepairs(selectedTab);
      toast.success('Repair deleted');
      closeRepairOptionsModal();
    }
  }

  const clearLoadedRepairs = () => {
    allRepairs.current = [];
  }

  const loadCounts = async () => {
    const allCount = await RetrieveCount({ createdAt: Between(timeFrame.start, timeFrame.end) });
    if (allCount) countAll.current = allCount;

    const scheduledCount = await RetrieveCount({ createdAt: Between(timeFrame.start, timeFrame.end), repairDate: Not(LessThan(new Date())) });
    if (scheduledCount) countScheduled.current = scheduledCount;

    const notScheduledCount = await RetrieveCount({ repairDate: Not(Exists()), createdAt: Between(timeFrame.start, timeFrame.end) });
    if (notScheduledCount) countNotScheduled.current = notScheduledCount;
  }

  //Custom Table Functions
  const columns = useMemo(() =>
    [{
      Header: () => null,
      id: 'expander',
      Cell: ({ row }: { row: Row<any> }) => (
        <span
          {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <FontAwesomeIcon size="lg" icon={faCaretDown} /> : <FontAwesomeIcon size="lg" icon={faCaretRight} />}
        </span>
      ),
    },
    {
      Header: 'ID',
      accessor: (row: IRepair) => row,
      Cell: ({ cell: { value } }: { cell: { value: IRepair } }) => (
        <Button
          rounded
          color={value.stripeId === null ? "red" : "gray"}
          style="soft"
          onClick={() => {
            if (authContext?.hasAnyGrant(Scope.Installs.DELETE_ANY, Scope.Installs.UPDATE_ANY)) {
              openRepairOptionsModal(value);
            }
          }}
        >
          {value.id}
        </Button>
      ),
    },
    {
      Header: "Transaction Date",
      accessor: (row: IRepair) => new Date(row.transactionDate).toLocaleDateString(),
    },
    {
      Header: "Branch",
      accessor: (row: IRepair) => row.branch.name,
    },
    {
      Header: "Customer",
      accessor: (row: IRepair) => row,
      Cell: ({ cell: { value } }: { cell: { value: IRepair } }) => (
        <div className="p-4 leading-none">
          <div className="flex justify-between">
            <div>
              {value.customer.firstName} {value.customer.lastName}
            </div>
          </div>
          <div className="text-gray-400 text-xs">
            {value.customer.address}
          </div>
          <div className="text-gray-400 text-xs">
            {value.customer.email}
          </div>
        </div>
      ),
    },
    {
      Header: "Repair Date",
      accessor: (row: IRepair) => row,
      Cell: ({ cell: { value } }: { cell: { value: IRepair } }) => (
        <Button
          rounded
          style="soft"
          onClick={() => openRepairDateModal(value)}
          color={repairDateColor(value)}
        >
          {value.repairDate
            ? new Date(value.repairDate).toLocaleDateString()
            : "Not Scheduled"}

          {renderCalendarIcon(value)}

        </Button>
      ),
    },
    {
      Header: "Job Completed",
      accessor: (row: IRepair) => row,
      Cell: ({ cell: { value } }: { cell: { value: IRepair } }) => (
        <Button
          rounded
          style="soft"
          color={value.jobCompletedDate ? "green" : "gray"}
          onClick={() => openJobCompletedModal(value)}
        >
          {value.jobCompletedDate ? new Date(value.jobCompletedDate).toLocaleDateString() : "Not completed"}
        </Button>
      ),
    },
    {
      Header: "Total Sale",
      accessor: (row: IRepair) => formatMoney(row.total),
    },
    ], []);

  const data = useMemo(() => displayedRepairs || [], [displayedRepairs]);

  const renderRowSubComponent = ({ row }: { row: Row<IRepair> }) => {
    const repair = row.original;

    return (
      <div className='flex flex-col items-center justify-center bg-gray-100 p-4 border rounded-lg shadow-md'>
        <textarea
          className='w-full p-2 mb-4 border rounded-lg resize-none text-sm'
          defaultValue={repair.notes || ''}
          placeholder='Add notes'
          onBlur={(e) => handleNotesChange(e, row)}
        />
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg text-sm"
          onClick={() => scheduleRepair(row)}
        >
          Schedule
        </button>
      </div>
    );
  }

  const handleNotesChange = async (e: React.FocusEvent<HTMLTextAreaElement>, row: Row<IRepair>) => {
    const newNotes = e.target.value;
    const updatedRepair = await UpdateRepair(row.original.id, { notes: newNotes });
    if (!updatedRepair) {
      toast.error('Failed to update notes');
      return;
    }
    row.original.notes = updatedRepair.notes;
  }

  const scheduleRepair = async (row: Row<IRepair>) => {
    //copying logic from installs scheduling
    const repair = await RetrieveRepair(row.original.id);
    if (!repair) {
      toast.error('Error scheduling repair');
      return;
    }

    const bookingType = repair.type === RepairType.WARRRANTY ? BookingType.WARRANTY_REPAIR : BookingType.REPAIR;

    const data = {
      repair,
      bookingType,
    }

    sessionStorage.setItem('repair', JSON.stringify(data));
    history.push('/calendar');
  }

  const repairDateColor = (repair: IRepair) => {
    const bookings = repair.bookings;
    // Check if bookings is defined and has elements
    if (bookings && bookings.length > 0) {
      const booking = bookings[0];
      if (booking.confirmed) return 'green';
      else if (booking.rescheduled) return 'red';
      else return 'blue';
    }
    return 'gray';
  }

  const renderCalendarIcon = (repair: IRepair): React.ReactNode => {
    const bookings = repair.bookings;
    if (!bookings?.length) return null;

    const isAnyBookingConfirmed = bookings.some(booking => booking.confirmed);
    if (isAnyBookingConfirmed) {
      return (
        <div className="ml-2">
          <FontAwesomeIcon icon={faCalendarCheck} />
        </div>
      );
    }

    const isAnyBookingRescheduled = bookings.some(booking => booking.rescheduled);
    if (isAnyBookingRescheduled) {
      return (
        <div className="ml-2">
          <FontAwesomeIcon icon={faCalendarMinus} />
        </div>
      );
    }
    return (
      <div className="ml-2">
        <FontAwesomeIcon icon={faCalendar} />
      </div>
    );
  }

  useEffect(() => {
    cursor.current = undefined;
    clearLoadedRepairs();
    loadRepairs(100);
    loadCounts();
  }, [timeFrame]);

  useEffect(() => {
    renderDisplayedRepairs(selectedTab)
  }, [selectedTab])

  return (
    <Page>

      <TopBar title='repairs'>
        {authContext?.hasAnyGrant(Scope.Installs.CREATE_ANY) && (
          <Button className="mr-4 text-sm" type="button" onClick={() => openAddRepairModal()} color="blue" style="outline" rounded>
            Add Repair
          </Button>
        )}

        <SearchBar className='mr-8 w-96' search={setSearchCriteria} placeholder='Search Repairs' />

        <Select
          className="quotes_date_dropdown mr-8"
          defaultValue={{
            value: {
              start: GetDate.nDaysAgo(30),
              end: GetDate.tomorrow(),
              name: "last30Days",
            },
            label: "Last 30 Days",
          }}
          options={[
            {
              value: {
                start: GetDate.january(),
                end: GetDate.tomorrow(),
                name: "yearToDate",
              },
              label: "Year to Date",
            },
            {
              value: {
                start: GetDate.nDaysAgo(30),
                end: GetDate.tomorrow(),
                name: "last30Days",
              },
              label: "Last 30 Days",
            },
            {
              value: {
                start: new Date(),
                end: new Date(),
                name: "custom",
              },
              label: "Custom Range",
            },

            {
              value: {
                start: GetDate.currentMonth(),
                end: GetDate.tomorrow(),
                name: "monthToDate",
              },
              label: "Month to Date",
            },
            {
              value: {
                start: GetDate.everlightsBirth(),
                end: GetDate.tomorrow(),
                name: "allTime",
              },
              label: "All Time",
            },
            {
              value: {
                start: GetDate.january(2023),
                end: GetDate.endOfYear(2023),
                name: "2023",
              },
              label: "2023",
            },
            {
              value: {
                start: GetDate.january(2022),
                end: GetDate.endOfYear(2022),
                name: "2022",
              },
              label: "2022",
            },
            {
              value: {
                start: GetDate.january(2021),
                end: GetDate.endOfYear(2021),
                name: "2021",
              },
              label: "2021",
            },
            {
              value: {
                start: GetDate.january(),
                end: GetDate.february(),
                name: "january",
              },
              label: "January",
            },
            {
              value: {
                start: GetDate.february(),
                end: GetDate.march(),
                name: "february",
              },
              label: "February",
            },
            {
              value: {
                start: GetDate.march(),
                end: GetDate.april(),
                name: "march",
              },
              label: "March",
            },
            {
              value: {
                start: GetDate.april(),
                end: GetDate.may(),
                name: "april",
              },
              label: "April",
            },
            {
              value: {
                start: GetDate.may(),
                end: GetDate.june(),
                name: "may",
              },
              label: "May",
            },
            {
              value: {
                start: GetDate.june(),
                end: GetDate.july(),
                name: "june",
              },
              label: "June",
            },
            {
              value: {
                start: GetDate.july(),
                end: GetDate.august(),
                name: "july",
              },
              label: "July",
            },
            {
              value: {
                start: GetDate.august(),
                end: GetDate.september(),
                name: "august",
              },
              label: "August",
            },
            {
              value: {
                start: GetDate.september(),
                end: GetDate.october(),
                name: "september",
              },
              label: "September",
            },
            {
              value: {
                start: GetDate.october(),
                end: GetDate.november(),
                name: "october",
              },
              label: "October",
            },
            {
              value: {
                start: GetDate.november(),
                end: GetDate.december(),
                name: "november",
              },
              label: "November",
            },
            {
              value: {
                start: GetDate.december(),
                end: GetDate.nextJanuary(),
                name: "december",
              },
              label: "December",
            },
          ]}
          onChange={(val) => {
            if (val?.value.name === "custom") {
              setTimeFrame({
                start: timeFrame.start,
                end: timeFrame.end,
                name: "custom",
              });
              clearCounts();
              return;
            }
            setTimeFrame(val!.value);
            clearCounts();
          }}
        />
      </TopBar>

      <Modal visible={repairDateModal} closeModal={closeRepairDateModal}>
        <form className="flex flex-col p-8">
          <div className="text-center text-gray-500 text-lg mb-4">
            Schedule Appointment Date
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-sm mb-1">
              Appointment Date
            </label>
            <input
              type="date"
              className="input"
              onChange={(e) =>
                setRepairDate(new Date(Date.parse(e.target.value)))
              }
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="mr-4 btn bg-gray-400 text-white mt-8"
              onClick={() => closeRepairDateModal()}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => clearRepairDate()}
              className="mr-4 btn text-gray-500 mt-8"
            >
              Clear
            </button>
            <button
              type="button"
              onClick={() => addRepairDate()}
              className="btn bg-theme_blue text-white mt-8"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>

      <Modal visible={jobCompletedDateModal} closeModal={closeJobCompletedModal}>
        <form className="flex flex-col p-8">
          <div className="text-center text-gray-500 text-lg mb-4">
            Job Completed Date
          </div>
          <div className="flex flex-col">
            <label className="text-gray-500 text-sm mb-1">
              Completed Date
            </label>
            <input
              type="date"
              className="input"
              onChange={(e) =>
                setJobCompletedDate(new Date(Date.parse(e.target.value)))
              }
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="mr-4 btn bg-gray-400 text-white mt-8"
              onClick={() => closeJobCompletedModal()}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => clearJobCompletedDate()}
              className="mr-4 btn text-gray-500 mt-8"
            >
              Clear
            </button>
            <button
              type="button"
              onClick={() => addJobCompletedDate()}
              className="btn bg-theme_blue text-white mt-8"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>

      <Modal visible={addRepairModal} closeModal={closeAddRepairModal}>
        <form className="flex flex-col p-8">
          <div className="text-center text-gray-500 text-lg mb-4">Add Repair</div>
          <div>
            <div className="flex flex-col my-2">
              <label className="text-gray-500 text-sm mb-1">
                Pandadoc Document Id
                <span className="text-gray-500 text-xs ml-2">
                  *only required if pandadoc was copied
                </span>
              </label>
              <input
                className="input"
                value={pandaDocId}
                min={0}
                placeholder="required"
                onChange={(e) => setPandaDocId(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="flex justify-end">
              <button
                type="button"
                className="mr-4 btn bg-gray-400 text-white mt-8"
                onClick={closeAddRepairModal}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={addRepairFromPandaDoc}
                className="btn bg-theme_blue text-white mt-8"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Modal>

      <Modal visible={repairOptionsModal} closeModal={closeRepairOptionsModal}>
        <form className="flex flex-col p-8">
          <div className="text-center text-gray-500 text-lg mb-4">
            Update Repair
          </div>
          <div className="flex justify-evenly">
            {authContext?.hasAnyGrant(Scope.Installs.DELETE_ANY) && (
              <button
                type="button"
                className="mr-0 btn mt-8 shadow-none text-gray-500"
                onClick={() => deleteRepair()}
              >
                Delete
              </button>
            )}
            <button
              type="button"
              className="mr-4 btn bg-gray-400 text-white mt-8"
              onClick={() => closeRepairOptionsModal()}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>

      <TableTabBar
        tabOptions={{
          tabs: [
            {
              name: RepairTab.ALL,
              quantity: countAll.current
            },
            {
              name: RepairTab.SCHEDULED,
              quantity: countScheduled.current
            },
            { name: RepairTab.NOT_SCHEDULED, quantity: countNotScheduled.current }
          ],
          active: selectedTab,
          onClick: setSelectedTab
        }}
      />

      <div id='table-scroll-div' className='h-full w-full max-w-full overflow-auto relative'>
        <InfiniteScroll
          dataLength={displayedRepairs.length}
          next={() => loadRepairs(100)}
          hasMore={!!cursor.current}
          loader={
            <div>
              <p style={{ textAlign: "center" }}>
                {loading ? (
                  <b>Loading more repairs...</b>
                ) : (
                  <Button
                    className="mx-auto my-8"
                    type="button"
                    color="blue"
                    style="outline"
                    rounded
                    onClick={() => loadRepairs(100)}
                  >
                    Load More
                  </Button>
                )}
              </p>
              <p style={{ textAlign: "center" }}>
                <Button
                  className="mx-auto my-8"
                  type="button"
                  color="blue"
                  style="outline"
                  rounded
                  onClick={() => loadAll()}
                >
                  Load All
                </Button>
              </p>
            </div>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>No more repairs to load</b>
            </p>
          }
          scrollThreshold={"80px"}
          scrollableTarget={"table-scroll-div"}
        >

          <CustomTable
            columns={columns}
            data={data}
            searchCriteria={searchCriteria}
            renderRowSubComponent={renderRowSubComponent}
          />

        </InfiniteScroll>
      </div>

      {displayedRepairs ? (
        displayedRepairs.length ? null : (
          <div className="text-gray-500 inset-center top-72">
            No repairs
          </div>
        )
      ) : (
        <Loading center />
      )}
    </Page>
  )
}
