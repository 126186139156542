import { CompanyType, ILeadCloseRate } from "@common.abstractions";
import { calcPercentage } from "@common.tools";
import { faArrowDown, faArrowUp, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RetrieveLeadCloseRates } from "@frontend/services/analytics";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

type Props = {
    timeFrame: {
        start: Date;
        end: Date;
    };
    titleSubstrLength: number;
    filterEverlights: boolean;
    filterBestToWorst: boolean;
    previewSize?: number;
    detailedView?: boolean;
    onClick?: () => void;
};

export default function LeadCloseRate(props: Props) {
    const { timeFrame, titleSubstrLength, filterEverlights, filterBestToWorst, previewSize, detailedView } = props;

    const [leadCloseRates, setLeadCloseRates] = useState<ILeadCloseRate[]>();
    const [filteredLeadCloseRates, setFilteredLeadCloseRates] = useState<ILeadCloseRate[]>();
    const [leadCloseRatesTotals, setLeadCloseRatesTotals] = useState<{
        totalCloseRate: number;
        totalPercentTrend: number;
        trendGrowth: boolean;
        totalLeadsSent: number;
        totalLeadsClosed: number;
    }>();
    const [sortAsc, setSortAsc] = useState<boolean>(false);

    const sortName = () => {
        if (sortAsc === false) {
            let tempLeadCloseRates = leadCloseRates;

            tempLeadCloseRates?.sort((a, b) => {
                let x = a.companyName.toLowerCase();
                let y = b.companyName.toLowerCase();
                if (x < y) {
                    return -1
                }
                if (x > y) {
                    return 1
                }
                return 0
            })

            setFilteredLeadCloseRates(tempLeadCloseRates);
            setSortAsc(true);

            return;
        }

        let tempLeadCloseRates = leadCloseRates;

        tempLeadCloseRates?.sort((a, b) => {
            let x = a.companyName.toLowerCase();
            let y = b.companyName.toLowerCase();
            if (x < y) {
                return 1
            }
            if (x > y) {
                return -1
            }
            return 0
        })

        setSortAsc(false);
        setFilteredLeadCloseRates(tempLeadCloseRates);
    }

    const loadLeadCloseRate = async () => {
        let response = await RetrieveLeadCloseRates(timeFrame);

        if (response) {
            let tempTotalCloseRateCurrent: number = 0;
            let tempTotalCloseRatePrevious: number = 0;
            let tempTotalPercentTrend: number = 0;
            let tempTrendGrowth: boolean;
            let tempTotalLeadsSent: number = 0;
            let tempTotalLeadsClosed: number = 0;

            response.map((el) => {
                tempTotalCloseRateCurrent += calcPercentage(el.current.leadsCloseNumber, el.current.leadsCount);
                tempTotalCloseRatePrevious += calcPercentage(el.previous.leadsCloseNumber, el.previous.leadsCount);
                tempTotalLeadsSent += el.current.leadsCount;
                tempTotalLeadsClosed += el.current.leadsCloseNumber;
            });

            tempTotalCloseRateCurrent = Number((tempTotalCloseRateCurrent / response.length).toFixed(2));
            tempTotalCloseRatePrevious = Number((tempTotalCloseRatePrevious / response.length).toFixed(2));
            tempTotalPercentTrend = Math.abs(Number((tempTotalCloseRateCurrent - tempTotalCloseRatePrevious).toFixed(2)));
            tempTrendGrowth = tempTotalCloseRateCurrent - tempTotalCloseRatePrevious > 0 ? true : false;

            const tempLeadCloseRatesTotals: { totalCloseRate: number, totalPercentTrend: number, trendGrowth: boolean, totalLeadsSent: number, totalLeadsClosed: number } = {
                totalCloseRate: tempTotalCloseRateCurrent,
                totalPercentTrend: tempTotalPercentTrend,
                trendGrowth: tempTrendGrowth,
                totalLeadsSent: tempTotalLeadsSent,
                totalLeadsClosed: tempTotalLeadsClosed,
            };

            setLeadCloseRatesTotals(tempLeadCloseRatesTotals);
            setLeadCloseRates(response);

            let tempLeadCloseRates = response;

            if (!filterEverlights) {
                response = response.filter((el) => el.companyType !== CompanyType.BRANCH);
            }

            if (filterBestToWorst) {
                tempLeadCloseRates.sort((a, b) => {
                    return calcPercentage(b.current.leadsCloseNumber, b.current.leadsCount) - calcPercentage(a.current.leadsCloseNumber, a.current.leadsCount);
                });
            } else {
                tempLeadCloseRates.sort((a, b) => {
                    return calcPercentage(a.current.leadsCloseNumber, b.current.leadsCount) - calcPercentage(b.current.leadsCloseNumber, a.current.leadsCount);
                });
            }

            if (previewSize && !detailedView) {
                setFilteredLeadCloseRates(tempLeadCloseRates.slice(0, previewSize));
            } else {
                setFilteredLeadCloseRates(tempLeadCloseRates.slice());
            }
            return;
        }

        toast.error("Error loading lead close rates");
    };

    const sortLeadCloseRate = () => {
        if (!leadCloseRates) return;
        let tempLeadCloseRates = leadCloseRates;

        if (!filterEverlights) {
            tempLeadCloseRates = tempLeadCloseRates.filter((el) => el.companyType !== CompanyType.BRANCH);
        }

        if (filterBestToWorst) {
            tempLeadCloseRates = tempLeadCloseRates.sort((a, b) => {
                return calcPercentage(b.current.leadsCloseNumber, b.current.leadsCount) - calcPercentage(a.current.leadsCloseNumber, a.current.leadsCount);
            });
        } else {
            tempLeadCloseRates = tempLeadCloseRates.sort((a, b) => {
                return calcPercentage(a.current.leadsCloseNumber, a.current.leadsCount) - calcPercentage(b.current.leadsCloseNumber, b.current.leadsCount);
            });
        }

        if (previewSize && !detailedView) {
            setFilteredLeadCloseRates(tempLeadCloseRates.slice(0, previewSize));
        } else {
            setFilteredLeadCloseRates(tempLeadCloseRates.slice());
        }
    };

    useEffect(() => {
        loadLeadCloseRate();
    }, [timeFrame]);

    useEffect(() => {
        sortLeadCloseRate();
    }, [filterBestToWorst, filterEverlights]);

    return !detailedView ? (
        <div
            onClick={props.onClick}
            className="p-5 shadow-md hover:shadow-lg duration-theme h-60 opacity-70 hover:opacity-100 cursor-pointer bg-white"
        >
            <div className="text-gray-500 mb-3">Lead Close Rate</div>
            <div className="flex items-end mb-4">
                <div className="text-3xl text-gray-700 font-bold flex">{leadCloseRatesTotals?.totalCloseRate}%</div>
                {leadCloseRatesTotals?.trendGrowth ? (
                    <div className="flex items-center ml-2 mr-5  text-theme_green">
                        <FontAwesomeIcon className="text-xs" icon={faArrowUp} />
                        <span className="ml-1">{leadCloseRatesTotals?.totalPercentTrend}%</span>
                    </div>
                ) : (
                    <div className="flex items-center ml-2 mr-5  text-theme_red">
                        <FontAwesomeIcon className="text-xs" icon={faArrowDown} />
                        <span className="ml-1">{leadCloseRatesTotals?.totalPercentTrend}%</span>
                    </div>
                )}
                <div className="text-xl text-gray-700 font-bold flex">
                    <div className="relative" style={{ minWidth: "25px" }}>
                        {leadCloseRatesTotals?.totalLeadsClosed}
                        <div className="absolute bottom-full text-xs whitespace-nowrap">closed</div>
                    </div>
                    <div className="mx-2">/</div>
                    <div className="relative">
                        {leadCloseRatesTotals?.totalLeadsSent}
                        <div className="absolute bottom-full text-xs whitespace-nowrap">sent</div>
                    </div>
                </div>
            </div>
            {filteredLeadCloseRates ? (
                filteredLeadCloseRates.map((el, i) => {
                    return (
                        <div key={el.companyId} className={`flex flex-col border-gray-200 ${i === filteredLeadCloseRates.length - 1 ? "" : "border-b"}`}>
                            <div className="flex justify-between py-2">
                                <div className="text-gray-600">
                                    {el.companyName.length > titleSubstrLength ? el.companyName.substring(0, titleSubstrLength) + " . . ." : el.companyName}
                                </div>
                                <div className="flex">
                                    <div className="inline-flex items-center mr-3 font-semibold text-gray-700">
                                        {calcPercentage(el.current.leadsCloseNumber, el.current.leadsCount)}%
                                    </div>
                                    <div className="inline-flex items-center w-14">
                                        {calcPercentage(el.current.leadsCloseNumber, el.current.leadsCount) -
                                            calcPercentage(el.previous.leadsCloseNumber, el.previous.leadsCount) >
                                            0 ? (
                                            <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                        ) : (
                                            <FontAwesomeIcon className="text-xs text-theme_red" icon={faArrowDown} />
                                        )}
                                        <span className="ml-1 text-gray-500">
                                            {(Math.abs(
                                                calcPercentage(el.current.leadsCloseNumber, el.current.leadsCount) -
                                                calcPercentage(el.previous.leadsCloseNumber, el.previous.leadsCount)
                                            )).toFixed(2)}
                                            %
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div>Loading...</div>
            )}
        </div>
    ) : (
        <div className="h-full flex flex-col">
            <div className="text-gray-500 mb-3 mt-3">Lead Close Rate</div>
            <div className="flex items-end mb-4">
                <div className="text-3xl text-gray-700 font-bold flex">{leadCloseRatesTotals?.totalCloseRate}%</div>
                {leadCloseRatesTotals?.trendGrowth ? (
                    <div className="flex items-center ml-2 mr-5  text-theme_green">
                        <FontAwesomeIcon className="text-xs" icon={faArrowUp} />
                        <span className="ml-1">{leadCloseRatesTotals?.totalPercentTrend}%</span>
                    </div>
                ) : (
                    <div className="flex items-center ml-2 mr-5  text-theme_red">
                        <FontAwesomeIcon className="text-xs" icon={faArrowDown} />
                        <span className="ml-1">{leadCloseRatesTotals?.totalPercentTrend}%</span>
                    </div>
                )}
                <div className="text-xl text-gray-700 font-bold flex">
                    <div className="relative" style={{ minWidth: "25px" }}>
                        {leadCloseRatesTotals?.totalLeadsClosed}
                        <div className="absolute bottom-full text-xs whitespace-nowrap">closed</div>
                    </div>
                    <div className="mx-2">/</div>
                    <div className="relative">
                        {leadCloseRatesTotals?.totalLeadsSent}
                        <div className="absolute bottom-full text-xs whitespace-nowrap">sent</div>
                    </div>
                </div>
            </div>
            {filteredLeadCloseRates ? (
                <>
                    <div className="p-2 mb-4 flex border-b border-gray-300">
                        <div className="text-gray-600 text-lg w-80">Name
                            <FontAwesomeIcon className="cursor-pointer" icon={faSort} onClick={sortName} />
                        </div>
                        <div className="text-gray-600 text-lg flex-1">Type</div>
                        <div className="text-gray-600 text-lg flex-1">Leads Closed</div>
                        <div className="text-gray-600 text-lg flex-1">Total Leads</div>
                        <div className="text-gray-600 text-lg flex-1">Rate</div>
                    </div>
                    <div className="flex flex-col overflow-y-scroll h-full">
                        {filteredLeadCloseRates.map((el, i) => {
                            return (
                                <Link
                                    to={`/companies/${el.companyId}`}
                                    key={el.companyId}
                                    className={`flex flex-col hover:bg-gray-200 border-gray-100 duration-200 ${i === filteredLeadCloseRates.length - 1 ? "" : "border-b"
                                        }`}
                                >
                                    <div className="flex justify-between p-2">
                                        <div className="text-gray-600 w-80">
                                            {el.companyName.length > titleSubstrLength
                                                ? el.companyName.substring(0, titleSubstrLength) + " . . ."
                                                : el.companyName}
                                        </div>
                                        <div className="text-gray-600 flex-1 capitalize">{el.companyType}</div>
                                        <div className="text-gray-600 flex-1 capitalize">{el.current.leadsCloseNumber}</div>
                                        <div className="text-gray-600 flex-1 capitalize">{el.current.leadsCount}</div>
                                        <div className="flex flex-1">
                                            <div className="inline-flex items-center mr-3 font-semibold text-gray-700">
                                                {calcPercentage(el.current.leadsCloseNumber, el.current.leadsCount)}%
                                            </div>
                                            <div className="inline-flex items-center w-14">
                                                {calcPercentage(el.current.leadsCloseNumber, el.current.leadsCount) -
                                                    calcPercentage(el.previous.leadsCloseNumber, el.previous.leadsCount) >
                                                    0 ? (
                                                    <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                                ) : (
                                                    <FontAwesomeIcon className="text-xs text-theme_red" icon={faArrowDown} />
                                                )}
                                                <span className="ml-1 text-gray-500">
                                                    {Math.abs(
                                                        calcPercentage(el.current.leadsCloseNumber, el.current.leadsCount) -
                                                        calcPercentage(el.previous.leadsCloseNumber, el.previous.leadsCount)
                                                    ).toFixed(2)}
                                                    %
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
}
