import React from 'react'

interface IProps {
    children: any
}

export function TableRowWrapper(props: IProps) {
    const {children} = props

    return (
        <tbody className="bg-white border-r border-l border-b border-gray-200 border-collapse">
            {children}
        </tbody>
    )
}
