import axios from "axios";
import { BACKEND_URL } from "../environment";
import { Query, serializeQuery } from "@common.api";
import { IContractorJob } from "@common.abstractions";

export async function RetrieveContractorJobs(query?: Query<IContractorJob>): Promise<IContractorJob[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/contractorJobs/`, {
            withCredentials: true, params: query, paramsSerializer: (q: any) => serializeQuery(q),
        });

        if (response.status === 200) return response.data as IContractorJob[];
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}