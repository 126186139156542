export namespace Scope {
    export enum Lead {
        READ = "portal/lead.read",
        READ_ANY = "portal/lead.readAny",
        CREATE = "portal/lead.create",
        CREATE_ANY = "portal/lead.createAny",
        UPDATE = "portal/lead.update",
        UPDATE_ANY = "portal/lead.updateAny",
        DELETE = "portal/lead.delete",
        DELETE_ANY = "portal/lead.deleteAny",
        MANUAL_MARK_CONVERTED = "portal/lead.manualMarkConverted",
        MANUAL_MARK_LOST = "portal/lead.manualMarkLost",
        CHILD_READ_ANY = "portal/lead.childReadAny",
    }

    export enum Report {
        READ = "portal/report.read",
        READ_ANY = "portal/report.readAny",
    }

    export enum Order {
        READ = "portal/order.read",
        READ_ANY = "portal/order.readAny",
        DEALER_READ = "portal/order.dealerRead",
        CREATE = "portal/order.create",
        CREATE_ANY = "portal/order.createAny",
        UPDATE = "portal/order.update",
        UPDATE_ANY = "portal/order.updateAny",
        DELETE = "portal/order.delete",
        PROCESS_ANY = "portal/order.processAny"
    }

    export enum Warranty {
        READ = 'portal/warranty.read',
        READ_ANY = 'portal/warranty.readAny',
        CREATE = 'portal/warranty.create',
        CREATE_ANY = 'portal/warranty.createAny',
        UPDATE = 'portal/warranty.update',
        UPDATE_ANY = 'portal/warranty.updateAny',
        DELETE = 'portal/warranty.delete',
        DELETE_ANY = 'portal/warranty.deleteAny'
    }

    export enum Company {
        READ = "portal/company.read",
        READ_ANY = "portal/company.readAny",
        CREATE_ANY = "portal/company.createAny",
        UPDATE = "portal/company.update",
        UPDATE_ANY = "portal/company.updateAny",
        DELETE_ANY = "portal/company.deleteAny",
    }

    export enum User {
        READ = "portal/user.read",
        READ_ANY = "portal/user.readAny",
        CREATE_ANY = "portal/user.createAny",
        UPDATE_ANY = "portal/user.updateAny",
        DELETE_ANY = "portal/user.deleteAny",
    }

    export enum Product {
        READ = "portal/product.read",
        READ_ANY = "portal/product.readAny",
        CREATE_ANY = "portal/product.createAny",
        UPDATE_ANY = "portal/product.updateAny",
        DELETE_ANY = "portal/product.deleteAny",
    }

    export enum QuoteRequest {
        READ = "portal/quoteRequest.read",
        READ_ANY = "portal/quoteRequest.readAny",
        CREATE_ANY = "portal/quoteRequest.createAny",
        UPDATE = "portal/quoteRequest.update",
        UPDATE_ANY = "portal/quoteRequest.updateAny", //WE REALLY DONT NEED THIS BECAUSE ASSIGNING AND UNASSIGNING A LEAD IS DELETING AND CREATING A LEAD
        DELETE_ANY = "portal/quoteRequest.deleteAny",
        AUTOASSIGN = "portal/quoteRequest.autoAssign",
    }

    export enum Contact {
        READ = "portal/contact.read",
        READ_ANY = "portal/contact.readAny",
        CREATE_ANY = "portal/contact.createAny",
        UPDATE_ANY = "portal/contact.updateAny",
        DELETE_ANY = "portal/contact.deleteAny",
    }

    export enum Finances {
        READ_ANY = "portal/finances.readAny",
    }

    export enum Installs {
        READ_ANY = "portal/installs.readAny",
        UPDATE_ANY = "portal/installs.updateAny",
        READ = "portal/installs.read",
        UPDATE = "portal/installs.update",
        CREATE_ANY = "portal/installs.createAny",
        DELETE_ANY = "portal/installs.deleteAny",
        MARK_AS_PAID = "portal/installs.markAsPaid",
        ADJUST_OPEN_BALANCE = "portal/installs.adjustOpenBalance",
    }

    export enum TaxRules {
        READ_ANY = "portal/taxRules.readAny",
        UPDATE_ANY = "portal/taxRules.updateAny",
        CREATE_ANY = "portal/taxRules.createAny",
        DELETE_ANY = "portal/taxRules.deleteAny",
    }

    export enum EmailPreferences {
        READ = "portal/emailPreferences.read",
        READ_ANY = "portal/emailPreferences.readAny",
        UPDATE = "portal/emailPreferences.update",
        UPDATE_ANY = "portal/emailPreferences.updateAny",
        CREATE_ANY = "portal/emailPreferences.createAny",
    }

    export enum Booking {
        READ = "portal/booking.read",
        READ_ANY = "portal/booking.readAny",
        UPDATE = "portal/booking.update",
        UPDATE_ANY = "portal/booking.updateAny",
        CREATE = "portal/booking.create",
        CREATE_ANY = "portal/booking.createAny",
        DELETE = "portal/booking.delete",
        DELETE_ANY = "portal/booking.deleteAny",
    }

    export enum BookableUser {
        READ = "portal/bookableUser.read",
        READ_ANY = "portal/bookableUser.readAny",
        UPDATE = "portal/bookableUser.update",
        UPDATE_ANY = "portal/bookableUser.updateAny",
        CREATE = "portal/bookableUser.create",
        CREATE_ANY = "portal/bookableUser.createAny",
        DELETE = "portal/bookableUser.delete",
        DELETE_ANY = "portal/bookableUser.deleteAny",
    }

    export enum PostInstall {
        READ = 'portal/postInstall.read',
        CREATE = 'portal/postInstall.create',
        UPDATE = 'portal/postInstall.update',
        READ_ANY = 'portal/postInstall.readAny',
        UPDATE_ANY = 'portal/postInstall.updateAny',
        DELETE_ANY = 'portal/postInstall.deleteAny',
    }

    export enum Sms {
        SEND_AS_SELF = "portal/sms.sendAsSelf"
    }

    export enum Repair {
        READ_ANY = "portal/repairs.readAny",
        UPDATE_ANY = "portal/repairs.updateAny",
        READ = "portal/repairs.read",
        UPDATE = "portal/repairs.update",
        CREATE_ANY = "portal/repairs.createAny",
        DELETE_ANY = "portal/repairs.deleteAny",
    }
}

export const GrantDescriptions = {
    [Scope.Lead.READ]:
        "Permission to view leads assigned to user's company. You do need this as well as the Quote Request Read Grant to have full access to your leads",
    [Scope.Lead.READ_ANY]: "Permission to view leads assigned to any company",
    [Scope.Lead.UPDATE]: "Permission to update leads assigned to user's company",
    [Scope.Lead.UPDATE_ANY]: "Permission to update leads assigned to any company",
    [Scope.Lead.CREATE]: "Permission to create leads assigned to user's company",
    [Scope.Lead.CREATE_ANY]: "Permission to create leads assigned to any company",
    [Scope.Lead.DELETE]: "Permission to delete leads assigned to user's company",
    [Scope.Lead.DELETE_ANY]: "Permission to delete leads assigned to any company",
    [Scope.Lead.MANUAL_MARK_LOST]: "Permission to manually mark a lead assigned to the branch as lost",
    [Scope.Lead.MANUAL_MARK_CONVERTED]: "BRANCH MANAGER ONLY: Permission to manually mark a lead assigned to the branch as converted",
    [Scope.Lead.CHILD_READ_ANY]: "Permission to view leads of child companies",

    [Scope.Report.READ]: "Permission to view reports (analytics dashboard) assigned to user's company",
    [Scope.Report.READ_ANY]: "Permission to view reports (analytics dashboard) assigned to any company",

    [Scope.Order.READ]: "Permission to view orders from user's company",
    [Scope.Order.READ_ANY]: "Admin permission to view all orders and their own dealer's orders",
    [Scope.Order.DEALER_READ]: "Dealer Manager permission to view their dealer's orders",
    [Scope.Order.CREATE]: "Permission to create orders for user's company",
    [Scope.Order.CREATE_ANY]: "Permission to create orders for any company",
    [Scope.Order.UPDATE]: "Permission to update orders for user's company",
    [Scope.Order.UPDATE_ANY]: "Permission to update orders from any company",
    [Scope.Order.DELETE]: "Permission to delete orders from user's company",

    [Scope.Warranty.CREATE]: "Permisson to create warranty for company's assigned lead",
    [Scope.Warranty.CREATE_ANY]: "Permisson to create warranty for any company's lead",
    [Scope.Warranty.UPDATE]: 'Permission to update warranty items',
    [Scope.Warranty.UPDATE_ANY]: "Permisson to approve, reject or edit warranty",
    [Scope.Warranty.READ]: "Permisson to view created warranties for company's assigned lead",
    [Scope.Warranty.READ_ANY]: "Permission to view all warranties for all companies assigned leads",

    [Scope.Company.READ]: "Permission to view company information (dealer, branch) of which the user is assigned",
    [Scope.Company.READ_ANY]: "Permission to view company information (dealer, branch) of any company",
    [Scope.Company.UPDATE]: "Permission to update company information (dealer, branch) of which the user is assigned",
    [Scope.Company.UPDATE_ANY]: "Permission to update company information (dealer, branch) of any company",
    [Scope.Company.CREATE_ANY]: "Permission to create a new company (dealer, branch)",
    [Scope.Company.DELETE_ANY]: "Permission to delete any company (dealer, branch)",

    [Scope.User.READ]: "Permission to view users assigned to user's company",
    [Scope.User.READ_ANY]: "Permission to view users assigned to any company",
    [Scope.User.UPDATE_ANY]: "Permission to update users assigned to any company",
    [Scope.User.CREATE_ANY]: "Permission to create users assigned to any company",
    [Scope.User.DELETE_ANY]: "Permission to delete users assigned to any company",

    [Scope.Product.READ]: "Permission to view products",
    [Scope.Product.READ_ANY]: "Permission to view all products, including unlisted products",
    [Scope.Product.UPDATE_ANY]: "Permission to update products",
    [Scope.Product.CREATE_ANY]: "Permission to create products",
    [Scope.Product.DELETE_ANY]: "Permission to delete products",

    [Scope.QuoteRequest.READ]:
        "Permission to view Quote Requests (Leads) assigned to user's company. You do you need this as well as the Lead Read Grant to have full access to your leads",
    [Scope.QuoteRequest.READ_ANY]: "Permission to view quote request",
    [Scope.QuoteRequest.UPDATE]:
        "Permission for a dealer to be able to to edit a quote request when the lead is assigned to them. Only gives permission to edit optional fields.",
    [Scope.QuoteRequest.UPDATE_ANY]: "Allows user to edit optional fields from any quote request.",
    [Scope.QuoteRequest.CREATE_ANY]: "Doesn't do anything. This grant will probably be deleted",
    [Scope.QuoteRequest.DELETE_ANY]: "Permission to delete a quote request, including leads associated with the quote request",

    [Scope.Contact.READ]: "DISABLED: Permission to view contacts of user's company's leads",
    [Scope.Contact.READ_ANY]: "Permission to view all contacts",
    [Scope.Contact.UPDATE_ANY]: "DISABLED: Permission to update any contact",
    [Scope.Contact.CREATE_ANY]: "DISABLED: Permission to create contacts products",
    [Scope.Contact.DELETE_ANY]: "DISABLED: Permission to delete any contact",

    // [Grant.Finances.READ_ANY]: "Permission to view all finance records",

    [Scope.Installs.READ]: "Permission to view paid install agreements of user's branch",
    [Scope.Installs.READ_ANY]: "Permission to view paid install agreements of any branch",
    [Scope.Installs.UPDATE]: "Permission to update paid install agreements of user's branch",
    [Scope.Installs.UPDATE_ANY]: "Permisison to update paid install agreements of any branch",
    [Scope.Installs.CREATE_ANY]: "DEVELOPER ONLY: Permission to manually add an install from a signed pandadoc",
    [Scope.Installs.DELETE_ANY]: "DEVELOPER ONLY: Permission to manually delete an install from a signed pandadoc",
    [Scope.Installs.MARK_AS_PAID]: "Permission to mark an install as paid",
    [Scope.Installs.ADJUST_OPEN_BALANCE]: "Permission to adjust the open balance of an install",

    [Scope.TaxRules.READ_ANY]: "DEVELOPER ONLY: Permission to view tax rules for everlight branches",
    [Scope.TaxRules.UPDATE_ANY]: "DEVELOPER ONLY: Permission to update tax rules for everlight branches",
    [Scope.TaxRules.CREATE_ANY]: "DEVELOPER ONLY: Permission to create tax rules for everlight branches",
    [Scope.TaxRules.DELETE_ANY]: "DEVELOPER ONLY: Permission to delete tax rules for everlight branches",

    [Scope.EmailPreferences.READ]: "Permission to view email preferences of users within company",
    [Scope.EmailPreferences.READ_ANY]: "Permission to view anyone's email preferences",
    [Scope.EmailPreferences.UPDATE]: "Permission to update email preferences of users within company",
    [Scope.EmailPreferences.UPDATE_ANY]: "Permission to update anyone's email preferences",
    [Scope.EmailPreferences.CREATE_ANY]: "DEVELOPER ONLY: creates default email preferences for all existing users without email preferences",

    [Scope.Booking.READ]: "BRANCH USER ONLY: View Calendar for own branch",
    [Scope.Booking.READ_ANY]: "BRANCH USER ONLY: View Calendar for any branch",
    [Scope.Booking.UPDATE]: "BRANCH USER ONLY: Update Calendar for own branch",
    [Scope.Booking.UPDATE_ANY]: "BRANCH USER ONLY: Update Calendar for any branch",
    [Scope.Booking.DELETE]: "BRANCH USER ONLY: Delete Calendar Events for own branch",
    [Scope.Booking.DELETE_ANY]: "BRANCH USER ONLY: Delete Calendar Events for any branch",
    [Scope.Booking.CREATE]: "BRANCH USER ONLY: Create Calendar Events for own branch",
    [Scope.Booking.CREATE_ANY]: "BRANCH USER ONLY: Create Calendar Events for any branch",

    [Scope.BookableUser.READ]: "BRANCH USER ONLY: View users that can be scheduled on calendar for own branch",
    [Scope.BookableUser.READ_ANY]: "BRANCH USER ONLY: View users that can be scheduled on calendar for any branch",
    [Scope.BookableUser.UPDATE]: "BRANCH USER ONLY: Update users scheduling options for own branch",
    [Scope.BookableUser.UPDATE_ANY]: "BRANCH USER ONLY: Update users scheduling options for any branch",
    [Scope.BookableUser.DELETE]: "BRANCH USER ONLY: Remove user from being able to be scheduled on calendar events for own branch",
    [Scope.BookableUser.DELETE_ANY]: "BRANCH USER ONLY: Remove user from being able to be scheduled on calendar events for any branch",
    [Scope.BookableUser.CREATE]: "BRANCH USER ONLY: Allow user to be able to be scheduled on calendar events for own branch",
    [Scope.BookableUser.CREATE_ANY]: "BRANCH USER ONLY: Allow user to be able to be scheduled on calendar events for any branch",

    [Scope.QuoteRequest.AUTOASSIGN]: "DEVELOPER ONLY: permission to run a auto assignment scan on all quote requests",
    [Scope.Order.PROCESS_ANY]: "DEVELOPER ONLY: Permission to manually process (verify payment was made) orders from any company",

    [Scope.PostInstall.CREATE]: 'Permission to allow installer to enter linear footage for install job',
    [Scope.PostInstall.READ]: 'Permission to view post install data that an installer had submitted',
    [Scope.PostInstall.UPDATE]: 'Permission to override prior submitted post install',
    [Scope.PostInstall.READ_ANY]: 'Permission to view post install data for all install jobs',
    [Scope.PostInstall.UPDATE_ANY]: 'Permission to override prior submitted post install for any install job',
    [Scope.PostInstall.DELETE_ANY]: 'Permission to delete post install data that an installer had submitted for any install job',

    [Scope.Sms.SEND_AS_SELF]: "Permission to send an sms text using one's own dialpad user id",

    [Scope.Repair.READ]: "Permission to view paid repair agreements of user's branch",
    [Scope.Repair.READ_ANY]: "Permission to view paid repair agreements of any branch",
    [Scope.Repair.UPDATE]: "Permission to update paid repair agreements of user's branch",
    [Scope.Repair.UPDATE_ANY]: "Permisison to update paid repair agreements of any branch",
    [Scope.Repair.CREATE_ANY]: "DEVELOPER ONLY: Permission to manually add an repair from a signed pandadoc",
    [Scope.Repair.DELETE_ANY]: "DEVELOPER ONLY: Permission to manually delete an repair from a signed pandadoc",
};

export const DealerGrantsPreset: Scope[] = [
    Scope.Lead.READ,
    Scope.Lead.CREATE,
    Scope.Lead.UPDATE,
    Scope.Lead.MANUAL_MARK_LOST,

    Scope.Report.READ,

    Scope.Order.READ,
    Scope.Order.CREATE,
    Scope.Order.UPDATE,

    Scope.Warranty.CREATE,
    Scope.Warranty.READ,
    Scope.Warranty.UPDATE,

    Scope.Company.READ,
    Scope.Company.UPDATE,

    Scope.QuoteRequest.READ,
    Scope.QuoteRequest.UPDATE,

    Scope.EmailPreferences.READ,
    Scope.EmailPreferences.UPDATE,

    Scope.User.READ,
    Scope.Product.READ,
    Scope.Contact.READ,
];

export const BranchManagerGrantsPreset: Scope[] = [
    Scope.BookableUser.READ,
    Scope.BookableUser.UPDATE,
    Scope.BookableUser.DELETE,
    Scope.BookableUser.CREATE,

    Scope.Booking.READ,
    Scope.Booking.UPDATE,
    Scope.Booking.DELETE,
    Scope.Booking.CREATE,

    Scope.Lead.READ,
    Scope.Lead.CREATE,
    Scope.Lead.UPDATE,
    Scope.Lead.MANUAL_MARK_CONVERTED,
    Scope.Lead.MANUAL_MARK_LOST,

    Scope.Order.READ,
    Scope.Order.CREATE,
    Scope.Order.UPDATE,

    Scope.Company.READ,
    Scope.Company.UPDATE,
    Scope.Company.UPDATE_ANY,

    Scope.QuoteRequest.READ,
    Scope.QuoteRequest.UPDATE,

    Scope.EmailPreferences.READ,
    Scope.EmailPreferences.UPDATE,

    Scope.User.READ,
    Scope.Product.READ,
    Scope.Contact.READ,

    Scope.Installs.READ,
    Scope.Installs.UPDATE,

    Scope.PostInstall.CREATE,
    Scope.PostInstall.READ,
    Scope.PostInstall.UPDATE,
];

export const DeveloperGrantsPreset: Scope[] = [
    Scope.BookableUser.READ,
    Scope.BookableUser.READ_ANY,
    Scope.BookableUser.UPDATE,
    Scope.BookableUser.UPDATE_ANY,
    Scope.BookableUser.DELETE,
    Scope.BookableUser.DELETE_ANY,
    Scope.BookableUser.CREATE,
    Scope.BookableUser.CREATE_ANY,

    Scope.Booking.READ,
    Scope.Booking.READ_ANY,
    Scope.Booking.UPDATE,
    Scope.Booking.UPDATE_ANY,
    Scope.Booking.DELETE,
    Scope.Booking.DELETE_ANY,
    Scope.Booking.CREATE,
    Scope.Booking.CREATE_ANY,

    Scope.Lead.READ,
    Scope.Lead.READ_ANY,
    Scope.Lead.CREATE,
    Scope.Lead.CREATE_ANY,
    Scope.Lead.DELETE_ANY,
    Scope.Lead.DELETE,
    Scope.Lead.UPDATE_ANY,
    Scope.Lead.UPDATE,
    Scope.Lead.MANUAL_MARK_CONVERTED,
    Scope.Lead.MANUAL_MARK_LOST,

    Scope.Report.READ,
    Scope.Report.READ_ANY,

    Scope.Sms.SEND_AS_SELF,

    Scope.Order.READ,
    Scope.Order.READ_ANY,
    Scope.Order.CREATE,
    Scope.Order.UPDATE,
    Scope.Order.UPDATE_ANY,
    Scope.Order.DELETE,

    Scope.Warranty.READ,
    Scope.Warranty.READ_ANY,
    Scope.Warranty.CREATE,
    Scope.Warranty.CREATE_ANY,
    Scope.Warranty.UPDATE,
    Scope.Warranty.UPDATE_ANY,

    Scope.Company.READ,
    Scope.Company.READ_ANY,
    Scope.Company.CREATE_ANY,
    Scope.Company.UPDATE,
    Scope.Company.UPDATE_ANY,
    Scope.Company.DELETE_ANY,

    Scope.User.READ,
    Scope.User.READ_ANY,
    Scope.User.CREATE_ANY,
    Scope.User.UPDATE_ANY,
    Scope.User.DELETE_ANY,

    Scope.Product.READ,
    Scope.Product.READ_ANY,
    Scope.Product.CREATE_ANY,
    Scope.Product.UPDATE_ANY,
    Scope.Product.DELETE_ANY,

    Scope.QuoteRequest.READ,
    Scope.QuoteRequest.READ_ANY,
    Scope.QuoteRequest.CREATE_ANY,
    Scope.QuoteRequest.UPDATE,
    Scope.QuoteRequest.UPDATE_ANY,
    Scope.QuoteRequest.DELETE_ANY,

    Scope.Contact.READ,
    Scope.Contact.READ_ANY,
    Scope.Contact.CREATE_ANY,
    Scope.Contact.UPDATE_ANY,
    Scope.Contact.DELETE_ANY,

    Scope.Finances.READ_ANY,

    Scope.Order.PROCESS_ANY, //Only for developers
    Scope.QuoteRequest.AUTOASSIGN, //Only for Developers

    Scope.Installs.READ,
    Scope.Installs.READ_ANY,
    Scope.Installs.UPDATE,
    Scope.Installs.UPDATE_ANY,
    Scope.Installs.CREATE_ANY, //Only for developers
    Scope.Installs.DELETE_ANY, //Only for developers
    Scope.Installs.MARK_AS_PAID,

    Scope.TaxRules.READ_ANY,
    Scope.TaxRules.UPDATE_ANY, //Only for developers
    Scope.TaxRules.CREATE_ANY, //Only for developers
    Scope.TaxRules.DELETE_ANY,

    Scope.EmailPreferences.READ,
    Scope.EmailPreferences.READ_ANY,
    Scope.EmailPreferences.UPDATE,
    Scope.EmailPreferences.UPDATE_ANY,
    Scope.EmailPreferences.CREATE_ANY, //Only for developers

    Scope.PostInstall.CREATE,
    Scope.PostInstall.READ,
    Scope.PostInstall.UPDATE,
    Scope.PostInstall.READ_ANY,
    Scope.PostInstall.UPDATE_ANY,
    Scope.PostInstall.DELETE_ANY,
];

export const AdminGrantsPreset: Scope[] = [
    Scope.BookableUser.READ,
    Scope.BookableUser.READ_ANY,
    Scope.BookableUser.UPDATE,
    Scope.BookableUser.UPDATE_ANY,
    Scope.BookableUser.DELETE,
    Scope.BookableUser.DELETE_ANY,
    Scope.BookableUser.CREATE,
    Scope.BookableUser.CREATE_ANY,

    Scope.Booking.READ,
    Scope.Booking.READ_ANY,
    Scope.Booking.UPDATE,
    Scope.Booking.UPDATE_ANY,
    Scope.Booking.DELETE,
    Scope.Booking.DELETE_ANY,
    Scope.Booking.CREATE,
    Scope.Booking.CREATE_ANY,

    Scope.Lead.READ,
    Scope.Lead.READ_ANY,
    Scope.Lead.CREATE,
    Scope.Lead.CREATE_ANY,
    Scope.Lead.DELETE_ANY,
    Scope.Lead.DELETE,
    Scope.Lead.UPDATE_ANY,
    Scope.Lead.UPDATE,
    Scope.Lead.MANUAL_MARK_CONVERTED,
    Scope.Lead.MANUAL_MARK_LOST,

    Scope.Report.READ,
    Scope.Report.READ_ANY,

    Scope.Order.READ,
    Scope.Order.READ_ANY,
    Scope.Order.CREATE,
    Scope.Order.UPDATE,
    Scope.Order.UPDATE_ANY,
    Scope.Order.DELETE,

    Scope.Warranty.READ,
    Scope.Warranty.READ_ANY,
    Scope.Warranty.CREATE,
    Scope.Warranty.CREATE_ANY,
    Scope.Warranty.UPDATE,
    Scope.Warranty.UPDATE_ANY,

    Scope.Company.READ,
    Scope.Company.READ_ANY,
    Scope.Company.CREATE_ANY,
    Scope.Company.UPDATE,
    Scope.Company.UPDATE_ANY,
    Scope.Company.DELETE_ANY,

    Scope.User.READ,
    Scope.User.READ_ANY,
    Scope.User.CREATE_ANY,
    Scope.User.UPDATE_ANY,
    Scope.User.DELETE_ANY,

    Scope.Product.READ,
    Scope.Product.READ_ANY,
    Scope.Product.CREATE_ANY,
    Scope.Product.UPDATE_ANY,
    Scope.Product.DELETE_ANY,

    Scope.QuoteRequest.READ,
    Scope.QuoteRequest.READ_ANY,
    Scope.QuoteRequest.CREATE_ANY,
    Scope.QuoteRequest.UPDATE,
    Scope.QuoteRequest.UPDATE_ANY,
    Scope.QuoteRequest.DELETE_ANY,

    Scope.Contact.READ,
    Scope.Contact.READ_ANY,
    Scope.Contact.CREATE_ANY,
    Scope.Contact.UPDATE_ANY,
    Scope.Contact.DELETE_ANY,

    Scope.Finances.READ_ANY,

    Scope.TaxRules.READ_ANY,

    Scope.Installs.CREATE_ANY,
    Scope.Installs.READ,
    Scope.Installs.READ_ANY,
    Scope.Installs.UPDATE,
    Scope.Installs.UPDATE_ANY,
    Scope.Installs.DELETE_ANY,
    Scope.Installs.MARK_AS_PAID,
    Scope.Installs.ADJUST_OPEN_BALANCE,

    Scope.EmailPreferences.READ,
    Scope.EmailPreferences.READ_ANY,
    Scope.EmailPreferences.UPDATE,
    Scope.EmailPreferences.UPDATE_ANY,
];

export const InstallerGrantsPreset: Scope[] = [
    Scope.Installs.READ,

    Scope.Company.UPDATE,

    Scope.PostInstall.CREATE,
    Scope.PostInstall.READ,
    Scope.PostInstall.UPDATE,

    Scope.Booking.READ,
    Scope.Booking.READ_ANY
];

export const SuccessGrantsPreset: Scope[] = [
    Scope.Lead.READ,
    Scope.Lead.READ_ANY,
    Scope.Lead.CREATE,
    Scope.Lead.CREATE_ANY,
    Scope.Lead.DELETE_ANY,
    Scope.Lead.DELETE,
    Scope.Lead.UPDATE_ANY,
    Scope.Lead.UPDATE,

    Scope.Company.READ,
    Scope.Company.READ_ANY,
    Scope.Company.UPDATE_ANY,

    Scope.User.READ,
    Scope.User.READ_ANY,

    Scope.QuoteRequest.READ,
    Scope.QuoteRequest.READ_ANY,
    Scope.QuoteRequest.CREATE_ANY,
    Scope.QuoteRequest.UPDATE,
    Scope.QuoteRequest.UPDATE_ANY,
    Scope.QuoteRequest.DELETE_ANY,

    Scope.Installs.READ_ANY,
    Scope.Installs.UPDATE_ANY,
    Scope.Installs.READ,
    Scope.Installs.UPDATE,
    Scope.Installs.CREATE_ANY,

    Scope.EmailPreferences.READ,

    Scope.Booking.READ,
    Scope.Booking.READ_ANY,
    Scope.Booking.UPDATE,
    Scope.Booking.UPDATE_ANY,
    Scope.Booking.CREATE,
    Scope.Booking.CREATE_ANY,
    Scope.Booking.DELETE,
    Scope.Booking.DELETE_ANY,

    Scope.BookableUser.READ,
    Scope.BookableUser.READ_ANY,
    Scope.BookableUser.UPDATE,
    Scope.BookableUser.UPDATE_ANY,
    Scope.BookableUser.DELETE,
    Scope.BookableUser.DELETE_ANY,
    Scope.BookableUser.CREATE,
    Scope.BookableUser.CREATE_ANY,
];

export const SalesGrantsPreset: Scope[] = [
    Scope.Lead.READ,
    Scope.Lead.UPDATE,
    Scope.Lead.CREATE,
    Scope.Lead.MANUAL_MARK_CONVERTED,
    Scope.Lead.MANUAL_MARK_LOST,

    Scope.Company.READ,
    Scope.Company.READ_ANY,
    Scope.Company.UPDATE,

    Scope.User.READ,

    Scope.Product.READ,

    Scope.QuoteRequest.READ,
    Scope.QuoteRequest.UPDATE,

    Scope.Installs.READ,

    Scope.EmailPreferences.READ,

    Scope.Booking.READ,
    Scope.Booking.UPDATE,
    Scope.Booking.CREATE,
    Scope.Booking.DELETE
];

export type Scope =
    | Scope.Lead
    | Scope.Order
    | Scope.Company
    | Scope.User
    | Scope.Product
    | Scope.QuoteRequest
    | Scope.Contact
    | Scope.Report
    | Scope.Finances
    | Scope.Installs
    | Scope.TaxRules
    | Scope.EmailPreferences
    | Scope.BookableUser
    | Scope.Booking
    | Scope.Warranty
    | Scope.PostInstall
    | Scope.Sms
    | Scope.Repair;