import axios from 'axios';
import { Filters, IPaginatedResourceResponse, IPaginationRequest, Query, serializeQuery, tryDecodePaginationResponseData } from '@common.api';
import { IRepair, IUpdateRepairParams, int } from '@common.abstractions';
import { BACKEND_URL } from '../environment';
import { getPaginationHeaders } from './helpers/pagination-helpers';

export async function RetrieveRepairs(query?: Query<IRepair>, pagination?: IPaginationRequest): Promise<IPaginatedResourceResponse<IRepair> | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/repairs`, {
            params: query,
            paramsSerializer: (q: any) => serializeQuery(q),
            headers: getPaginationHeaders(pagination),
            withCredentials: true
        });

        if (response.status === 200) {
            return {
                pagination: tryDecodePaginationResponseData(response.headers['pagination']),
                values: response.data as IRepair[]
            }
        };
        console.log('Installs failed to load with response code: ', response.status);
        return undefined;
    } catch (err: any) {
        console.log('Loading repairs threw an exception: ', err);
        return undefined;
    }
}

export async function UpdateRepair(id: int, params: IUpdateRepairParams): Promise<IRepair | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/repairs/${id}`, params, { withCredentials: true });
        if (response.status === 200) return response.data as IRepair;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function ManuallyCreateRepair(documentId: string): Promise<IRepair | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/repairs`, { documentId }, { withCredentials: true });
        if (response.status === 201) return response.data as IRepair;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteRepair(id: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/repairs/${id}`, { withCredentials: true });
        if (response.status === 200) return true;
        return false;
    } catch (err: any) {
        console.log(err);
        return false;
    }
}

export async function RetrieveRepair(id: int): Promise<IRepair | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/repairs/${id}`, { withCredentials: true });
        if (response.status === 200) return response.data as IRepair;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveRepairsByContactName(name: string, query?: Query<IRepair>): Promise<IRepair[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/repairs/contacts/${name}`, { params: query, paramsSerializer: (val: any) => serializeQuery(val), withCredentials: true });
        if (response.status === 200) return response.data as IRepair[];

        console.log('Repairs failed to load with response code: ', response.status);
        return undefined;
    }
    catch (err) {
        console.log('Loading repairs threw an exception: ', err);
        return undefined;
    }
}

export async function RetrieveCount(filters: Filters<IRepair>): Promise<number> {
    try {
        const response = await axios.get(`${BACKEND_URL}/repairs/count`, {
            withCredentials: true, params: { filters },
            paramsSerializer: (val: any) => serializeQuery(val)
        });
        if (response.status === 200) return response.data as number;
        return 0;
    } catch (err: any) {
        console.log(err);
        return 0;
    }
}