import { int } from "@common.abstractions";
import { ICreateBankAccountParams } from "@common.api";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import AuthContext, { IUserAuthContext } from "@frontend/contexts/AuthContext";
import { CreateCompanyBankAccount, VerifyBankAccount } from "@frontend/services/companies";
import { useContext, useState } from "react";
import { toast } from "react-toastify";

export default function ACHTransfer() {
    const authContext = useContext(AuthContext as React.Context<IUserAuthContext>);
    const [accountName, setAccountName] = useState<string>();
    const [accountHolderType, setAccountHolderType] = useState<"individual" | "company">("individual");
    const [routingNumber, setRoutingNumber] = useState<string>();
    const [accountNumber, setAccountNumber] = useState<string>();
    const [amount1String, setAmount1String] = useState<string>();
    const [amount2String, setAmount2String] = useState<string>();
    const [verifyCounter, setVerifyCounter] = useState<number>(0)

    const handleBankSubmit = async (e: any) => {
        e.preventDefault();

        if (!routingNumber || !accountName || !accountNumber) {
            toast.error("Please fill out all fields");
            return;
        }
        if (RegExp("\s").test(routingNumber)) {
            toast.error("Please remove any whitespace");
            return;
        }
        if (!RegExp("^[0-9]{9}$").test(routingNumber)) {
            toast.error("Please enter valid Routing Number");
            return;
        }
        if (!RegExp("^[0-9]{4,14}$").test(accountNumber)) {
            toast.error("Please enter valid Accounting Number");
            return;
        }

        const params: ICreateBankAccountParams = {
            accountHolderName: accountName,
            accountHolderType: "individual",
            accountNumber: accountNumber,
            routingNumber: routingNumber,
        };

        const response = await CreateCompanyBankAccount(authContext!.user!.company!.id, params);

        if (response) {
            authContext.refreshUser();
            toast("Success");
            return;
        }

        toast.error("Error Submitting ACH Information");
    };

    const handleVerify = async (e: any) => {
        if (verifyCounter >= 3) return;
        e.preventDefault();

        if (!amount1String || !amount2String) {
            toast.error("Please insert both values from your bank");
            return;
        }

        let amount1 = parseFloat(amount1String);
        let amount2 = parseFloat(amount2String);

        if (isNaN(amount1) || isNaN(amount2)) {
            toast.error("Please check the numbers you entered");
            return;
        }

        amount1 = int.round(amount1 * 100);
        amount2 = int.round(amount2 * 100);

        const response = await VerifyBankAccount(authContext!.user!.company!.id, {
            amounts: [amount1, amount2],
        });

        if (response) {
            toast("Success");
            authContext.refreshUser();
            return;
        }

        if (verifyCounter === 2) {
            //because now theymessed up 3 times and we're about to increment it
            toast.error("You've inserted the incorrect amount 3 times, please contact Everlights");
        }

        setVerifyCounter(verifyCounter + 1);

    };

    const handleAmountChange = (amount: string) => {
        amount = amount.replace(/\.0/i, "");
        amount = amount.replace(/[^\d]/g, "");

        if (amount.length === 1) {
            amount = ".0" + amount;
        } else if (amount.length === 2) {
            amount = "." + amount;
        } else if (amount.length >= 3) {
            amount = amount.substring(0, amount.length - 2) + "." + amount.substring(amount.length - 2, amount.length);
        }

        return amount;
    };

    return (
        <div className="h-full w-full flex flex-col">
            <TopBar title="ACH Transfer" />

            {!authContext!.user!.company!.bankAccount ? (
                <form className="inset-center bg-white shadow-md py-10 w-96">
                    <div className="text-xl text-gray-600 text-center pb-4">Bank Account Information</div>
                    <div className="text-sm text-gray-500 text-center pb-4">
                        After successful submittion, 2 small amounts will be deposited (later withdrawn) into your account. Later you will be asked to input
                        these amounts to verify ownership of the checking account.
                    </div>
                    <div className="flex mb-5">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-500">Name on Account</label>
                            <input required={true} onChange={(e) => setAccountName(e.target.value)} className="input" type="text" />
                        </div>
                    </div>
                    <div className="flex mb-5">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-500">Type of Account</label>
                            <select onChange={(e) => setAccountHolderType(e.target.value as "individual" | "company")} className="text-gray-600 input pl-2">
                                <option value={"individual"}>Individual</option>
                                <option value={"company"}>Company</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex mb-5">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-500">Routing Number</label>
                            <input required={true} pattern="^[0-9]{9}" onChange={(e) => setRoutingNumber(e.target.value)} className="input" type="text" />
                        </div>
                    </div>
                    <div className="flex mb-5">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-500">Account Number</label>
                            <input required={true} pattern="^[0-9]{4,14}$" onChange={(e) => setAccountNumber(e.target.value)} className="input" type="text" />
                        </div>
                    </div>
                    <button type="submit" onClick={(e) => handleBankSubmit(e)} className="btn bg-theme_blue text-white">
                        Submit
                    </button>
                    <div className="text-gray-500 text-sm text-center mt-5">*Account must be a checking account</div>
                </form>
            ) : !authContext!.user!.company!.bankAccount.verifiedDate ? (
                <form className="w-72 inset-center bg-white shadow-md py-10">
                    <div className="text-xl text-gray-600 text-center pb-4">Verify Your Account</div>
                    <div className="text-sm text-gray-500 text-center pb-4">Check your bank account for 2 small amounts that were deposited</div>
                    <div className="flex mb-5">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-500">Amount 1</label>
                            <input
                                key={"amount1String"}
                                required={true}
                                value={amount1String}
                                placeholder={"required"}
                                onChange={(e) => setAmount1String(handleAmountChange(e.target.value))}
                                className="input"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="flex mb-5">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-500">Amount 2</label>
                            <input
                                key="amount2String"
                                required={true}
                                value={amount2String}
                                placeholder={"required"}
                                onChange={(e) => setAmount2String(handleAmountChange(e.target.value))}
                                className="input"
                                type="text"
                            />
                        </div>
                    </div>
                    {
                        verifyCounter >= 3 ? (
                            <div className="btn cursor-not-allowed bg-gray-300 text-white">
                                Submit
                            </div>
                        ) : (
                            <button type="submit" onClick={(e) => handleVerify(e)} className="btn bg-theme_blue text-white">
                                Submit
                            </button>
                        )
                    }
                </form>
            ) : (
                <div className="text-lg text-gray-500 uppercase mb-6 inset-center bg-white p-20 shadow-md">You're all set up for ACH Transfers</div>
            )}
        </div>
    );
}
