import AuthContext from '../../contexts/AuthContext'
import React, { useEffect, useState, SVGProps } from 'react'
import TopBar from '../misc/top-bar/TopBar';
import { ICreatePostInstall, ICreateRepairsCompleted, ICreateWarrantyFootage, IInstall, int } from '@common.abstractions';
import { RetrieveInstalls } from '@frontend/services/installs';
import Select from "react-select";
import { toast } from 'react-toastify';
import { CreatePostInstall } from '@frontend/services/post-install';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Between } from '@common.api';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { CreateWarrantyFootage } from '@frontend/services/warranty-footage';
import { CreateRepairsCompleted } from '@frontend/services/repairs-completed';

type CaretIconProps = {
    height?: number;
    width?: number;
    "aria-label"?: string;
    className?: string;
} & SVGProps<SVGSVGElement>;

export default function InstallerTool() {
    const authContext = React.useContext(AuthContext);
    const history = useHistory();

    const [installs, setInstalls] = useState<IInstall[]>();
    // const [lightsInstalledList, setLightInstalledList] = useState<{ ft: int; serialNumber: string | null; }[]>([]);
    const [serialNumber, setSerialNumber] = useState<string | undefined>();
    const [ftInstalled, setFtInstalled] = useState<string>('');
    const [warrantyFtInstalled, setWarrantyFtInstalled] = useState<string>('');
    const [repairsCompletedCount, setRepairsCompletedCount] = useState<string>('');
    const [installId, setInstallId] = useState<int>();
    const [jobCompleted, setJobCompleted] = useState<boolean>(false);

    const loadInstalls = async () => {
        const today = new Date();
        const last14Days = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000);
        const next14Days = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);

        const response = await RetrieveInstalls({
            filters: {
                installDate: Between(last14Days, next14Days),
            }
        });
        if (!response) {
            console.log('Could not retrieve installs');
            return;
        }
        setInstalls(response.values);
    }

    const submitInstallFootage = async () => {
        if (!ftInstalled || !installId) {
            toast.error('Error - please fill out all fields');
            return;
        }
        const params: ICreatePostInstall = {
            lightsInstalled: [{
                ft: int.parse(ftInstalled),
                serialNumber: null
            }],
            installerAccountId: authContext!.user?.accountId!,
            installId: installId,
            collectedPayment: null,
            paymentNotes: null,
        }
        const response = await CreatePostInstall(params, jobCompleted);
        if (!response) {
            toast.error('Error - submission failed');
            return;
        }

        toast.success('Your submission has been received');
        // setLightInstalledList([]);
        setFtInstalled('');

        if (jobCompleted) {
            history.push(`/installerTool/${response.id}/checklist`)
        }
    }

    const submitWarrantyFootage = async () => {
        if (!warrantyFtInstalled) {
            toast.error('Error - please fill out all fields');
            return;
        }
        const params: ICreateWarrantyFootage = {
            warrantyFeetInstalled:int.parse(warrantyFtInstalled),
            installerAccountId: authContext!.user?.accountId!,
        }
        const response = await CreateWarrantyFootage(params);
        if (!response) {
            toast.error('Error - submission failed');
            return;
        }

        toast.success('Your submission has been received');
        // setLightInstalledList([]);
        setWarrantyFtInstalled('');
    }

    const submitRepairsCompletedCount = async () => {
        if (!repairsCompletedCount) {
            toast.error('Error - please fill out all fields');
            return;
        }
        const params: ICreateRepairsCompleted = {
            repairsCompleted:int.parse(repairsCompletedCount),
            installerAccountId: authContext!.user?.accountId!,
        }
        const response = await CreateRepairsCompleted(params);
        if (!response) {
            toast.error('Error - submission failed');
            return;
        }

        toast.success('Your submission has been received');
        // setLightInstalledList([]);
        setRepairsCompletedCount('');
    }

    // const createLightsInstalled = (serialNumber: string | null, ft: int) => {
    //     const lightsInstalled = {
    //         serialNumber: serialNumber ? serialNumber : null,
    //         ft,
    //     };

    //     setLightInstalledList([...lightsInstalledList, lightsInstalled]);
    //     setSerialNumber('');
    //     setFtInstalled('');
    // };

    // const deleteLightsInstalled = (lightsInstall: { ft: int; serialNumber: string | null; }) => {
    //     const newList = lightsInstalledList.filter((el) => el !== lightsInstall);
    //     setLightInstalledList(newList);
    // };

    const toggleJobCompleted = () => {
        jobCompleted ? setJobCompleted(false) : setJobCompleted(true);
    }

    useEffect(() => {
        loadInstalls();
    }, [])

    return (
        <div className="h-full w-full overflow-auto">
            <TopBar title="Footage Tracker" />
            {/* <div className="flex flex-col md:flex-col mt-5 p-2 text-xl items-center bg-white rounded-md shadow">
                <AccordionControl/>
            </div> */}
            <div className="flex flex-col md:flex-col mt-5 p-2 text-xl items-center bg-white rounded-none shadow">
                <AccordionMenu 
                    installs={installs}
                    installId={installId}
                    setInstallId={setInstallId}
                    ftInstalled={ftInstalled}
                    setFtInstalled={setFtInstalled}
                    toggleJobCompleted={toggleJobCompleted}
                    submitInstallFootage={submitInstallFootage}
                    warrantyFtInstalled={warrantyFtInstalled}
                    setWarrantyFtInstalled={setWarrantyFtInstalled}
                    submitWarrantyFootage={submitWarrantyFootage}
                    repairsCompletedCount={repairsCompletedCount}
                    setRepairsCompletedCount={setRepairsCompletedCount}
                    submitRepairsCompletedCount={submitRepairsCompletedCount}
                />
            </div>
   
                {/* {lightsInstalledList.map((el, index) => (
                    <div key={index} className="flex flex-col md:flex-row items-center justify-between p-2 mt-3 bg-white rounded-md shadow text-gray-600">
                        <div className="w-full flex flex-col md:flex-row md:items-center">
                            <div className="flex mb-2 md:mb-0 md:mr-4 items-center">
                                <label className="italic mr-2">SN</label>
                                <input
                                    className="bg-gray-200 text-center w-full md:w-32 mx-2 border border-gray-300 rounded-md text-gray-500 cursor-not-allowed"
                                    value={el.serialNumber || ''}
                                    readOnly />
                            </div>

                            <div className="flex items-center">
                                <label className="italic mr-2">ft</label>
                                <input
                                    className="bg-gray-200 text-center w-full md:w-32 mx-2 border border-gray-300 rounded-md text-gray-500 cursor-not-allowed"
                                    value={el.ft}
                                    readOnly />
                            </div>
                        </div>

                        <button className="text-red-500 hover:text-red-700 mt-2 md:mt-0">
                            <FontAwesomeIcon icon={faTrash} onClick={() => deleteLightsInstalled(el)} />
                        </button>
                    </div>
                ))} */}
        </div>
    );
}


const AccordionMenu = (...props: any) => {
    const { installs, installId, setInstallId, ftInstalled, setFtInstalled, toggleJobCompleted, submitInstallFootage,
        warrantyFtInstalled, setWarrantyFtInstalled, submitWarrantyFootage,
        repairsCompletedCount, setRepairsCompletedCount, submitRepairsCompletedCount
     } = props[0];
    const [activeElement, setActiveElement] = useState<string>('installFootage');


    const handleClick = (value: string) => {
        if (value === activeElement) {
            setActiveElement("");
        } else {
            setActiveElement(value);
        }
    }

    return (
        <>
        <div className='w-100'>
            <input 
                id='installFootage' 
                type='checkbox'
                checked={activeElement==="installFootage"}
                onChange={() => setActiveElement(activeElement)}
                className="peer sr-only" />
            <label 
                htmlFor='installFootage'
                className={classNames("w-100 flex justify-center items-center bg-blue-100",
                    "hover:bg-blue-500 peer-checked:bg-blue-500",
                    "transition-colors duration-1000 ease-in-out"
                )}
                onClick={() => handleClick("installFootage")}
            >
                Install Footage
                <CaretIcon
                    height={20}
                    width={20} 
                    className={classNames("ml-4", {
                    "rotate-180": (activeElement==="installFootage"),
                    })}
                />
            </label>
            
            <div className={classNames("overflow-hidden h-0 bg-white shadow rounded-sm",
                "peer-checked:h-[225px] peer-checked:overflow-hidden",
                "transition-[height] duration-1000 ease-in-out"
                )}
            >
            <div className="flex flex-col md:flex-col mt-0 p-2 text-xl bg-white w-full h-full">
                <div className="flex flex-row items-center mb-2 md:mb-0 md:mr-4mn bg-white">
                    <Select
                        className="w-80 md:w-96 mb-5 border border-gray-300 rounded-md"
                        placeholder="Search..."
                        options={installs?.map((el: IInstall) => ({
                            value: el.id,
                            label: `(${el.id}) ${el.customer.firstName} ${el.customer.lastName} - ${el.customer.address}`,
                        }))}
                        onChange={(val:any) => setInstallId(val?.value)}
                    />
                </div>

                <div className="flex flex-row items-center mb-2 md:mb-0 md:mr-4mn bg-white">
                    <label className="text-gray-600 text-lg mr-20">Footage Installed</label>
                    <input
                        type="number"
                        className="text-center bg-gray-100 border border-gray-300 rounded-md w-full md:w-32"
                        value={ftInstalled}
                        onChange={(e) => {
                            setFtInstalled(e.target.value);
                            
                        }}
                    />
                </div>

                {activeElement==="installFootage" && ftInstalled && installId ? (
                <div className="flex flex-col items-center mt-0 bg-white shadow-lg py-4 px-6 w-full md:max-w-md mx-auto">
                    <div className='flex items-center mb-4'>
                        <label className='text-gray-700 text-lg font-medium mr-2'>Job completed?</label>
                        <input
                            className='form-checkbox h-4 w-4 text-blue-500 rounded border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                            type='checkbox'
                            onChange={toggleJobCompleted} />
                    </div>

                    <button
                        onClick={submitInstallFootage}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
                    >
                        Submit
                    </button>
                </div>
            ) : null}

            </div>
            </div>
        </div>


        <div className='w-100'>
            <input 
                id='warrantyFootage' 
                type='checkbox'
                checked={activeElement==="warrantyFootage"}
                onChange={() => setActiveElement(activeElement)}
                className="peer sr-only" />
            <label 
                htmlFor='warrantyFootage'
                className={classNames("w-100 flex justify-center items-center bg-blue-100",
                    "hover:bg-blue-500 peer-checked:bg-blue-500",
                    "transition-colors duration-1000 ease-in-out"
                )}
                onClick={() => handleClick("warrantyFootage")}
            >
                Warranty Footage
                <CaretIcon
                    height={20}
                    width={20} 
                    className={classNames("ml-4", {
                    "rotate-180": (activeElement==="warrantyFootage"),
                    })}
                />
            </label>
            
            <div className={classNames("overflow-hidden h-0 bg-white shadow rounded-sm",
                "peer-checked:h-[130px] peer-checked:overflow-hidden",
                "transition-[height] duration-1000 ease-in-out"
                )}
            >
            <div className="flex flex-col md:flex-col mt-0 p-2 text-xl bg-white w-full h-full">
                <div className="flex flex-row items-center w-80 md:w-96 mb-2 md:mb-0 md:mr-4mn bg-white">
                    <label className="text-gray-600 text-lg mr-20">Footage Installed</label>
                    <input
                        type="number"
                        className="text-center bg-gray-100 border border-gray-300 rounded-md w-full md:w-32"
                        value={warrantyFtInstalled}
                        onChange={(e) => setWarrantyFtInstalled(e.target.value)}
                    />
                </div>

                {activeElement==="warrantyFootage" && warrantyFtInstalled ? (
                <div className="flex flex-col items-center mt-0 bg-white shadow-lg py-4 px-6 w-full md:max-w-md mx-auto">
                    <button
                        onClick={submitWarrantyFootage}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
                    >
                        Submit
                    </button>
                </div>
            ) : null}
            </div>
            </div>
        </div>


        <div className='w-100'>
            <input 
                id='repairsCompleted' 
                type='checkbox'
                checked={activeElement==="repairsCompleted"}
                onChange={() => setActiveElement(activeElement)}
                className="peer sr-only" />
            <label 
                htmlFor='repairsCompleted'
                className={classNames("w-100 flex justify-center items-center bg-blue-100",
                    "hover:bg-blue-500 peer-checked:bg-blue-500",
                    "transition-colors duration-1000 ease-in-out"
                )}
                onClick={() => handleClick("repairsCompleted")}
            >
                Repairs Completed
                <CaretIcon
                    height={20}
                    width={20} 
                    className={classNames("ml-4", {
                    "rotate-180": (activeElement==="repairsCompleted"),
                    })}
                />
            </label>
            
            <div className={classNames("overflow-hidden h-0 bg-white shado rounded-sm",
                "peer-checked:h-[130px] peer-checked:overflow-hidden",
                "transition-[height] duration-1000 ease-in-out"
                )}
            >
            <div className="flex flex-col md:flex-col mt-0 p-2 text-xl bg-white w-full h-full">
                <div className="flex flex-row items-center w-80 md:w-96 mb-2 md:mb-0 md:mr-4mn ">
                    <label className="text-gray-600 text-lg mr-20">Repairs Completed</label>
                    <input
                        type="number"
                        className="text-center bg-gray-100 border border-gray-300 rounded-md w-full md:w-32"
                        value={repairsCompletedCount}
                        onChange={(e) => setRepairsCompletedCount(e.target.value)}
                    />
                </div>

                {activeElement==="repairsCompleted" && repairsCompletedCount ? (
                    <div className="flex flex-col items-center mt-0 bg-white shadow-lg py-4 px-6 w-full md:max-w-md mx-auto">
                        <button
                            onClick={submitRepairsCompletedCount}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded transition duration-300 ease-in-out transform hover:-translate-y-1"
                        >
                            Submit
                        </button>
                    </div>
                ) : null}
            </div>
            </div>
        </div>
    </>
    );
};


const CaretIcon = ({
    height = 80,
    width = 80,
    "aria-label": ariaLabel,
    className,
    ...props
   }: CaretIconProps) => {
    return (
    <svg
        aria-label={ariaLabel}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        fill="none"
        viewBox="0 0 24 24"
        className={className}
        {...props}
    >
    <path
        fill="#212121"
        d="M5.161 10.073C4.454 9.265 5.028 8 6.101 8h11.797c1.074 0 1.648 1.265.94 2.073l-5.521 6.31a1.75 1.75 0 0 1-2.634 0l-5.522-6.31ZM6.653 9.5l5.159 5.896a.25.25 0 0 0 .376 0l5.16-5.896H6.652Z"
    />
    </svg>  
    );      
};