import axios from 'axios';
import { ICreateInstallerCheckList, ICreatePostInstall, ICreateRepairCheckList, IInstallerCheckList, int, IPostInstall, IQuestions, IRepairCheckList, IUpdatePostInstall } from '@common.abstractions';
import { BACKEND_URL } from '@frontend/environment';
import { Query, serializeQuery } from '@common.api';

export async function CreatePostInstall(params: ICreatePostInstall, completed: boolean): Promise<IPostInstall | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/postInstall`, { ...params, completed }, { withCredentials: true });
        if (response.status === 201) return response.data as IPostInstall;

        console.log('Failed to create post Install with code: ', response.status, response.data)
        return undefined
    }
    catch (err: any) {
        console.log('Creating Post Install threw an exception: ', err);
        return undefined;
    }
}

export async function UpdatePostInstall(postInstallId: int, params: IUpdatePostInstall): Promise<IPostInstall | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/postInstall/${postInstallId}`, params, { withCredentials: true });
        if (response.status === 201) return response.data as IPostInstall;

        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function DeletePostInstall(postInstallId: int): Promise<boolean | undefined> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/postInstall/${postInstallId}`, { withCredentials: true });
        if (response.status === 200) return true;

        return false;
    } catch (err: any) {
        console.log(err.toString());
        return undefined;
    }
}

export async function RetrieveQuestions(query?: Query<IQuestions>): Promise<IQuestions[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/postInstall/questions`, {
            withCredentials: true,
            params: query,
            paramsSerializer: (q: any) => serializeQuery(q)
        });
        if (response.status === 200) return response.data as IQuestions[];

        return undefined;
    } catch (err: any) {
        console.log(err.toString());
        return undefined;
    }
}

export async function CreateInstallerCheckList(params: ICreateInstallerCheckList): Promise<IInstallerCheckList | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/postInstall/checklist`, params, { withCredentials: true });
        if (response.status === 201) return response.data as IInstallerCheckList;

        return undefined;
    } catch (err: any) {
        console.log(err.toString());
        return undefined;
    }
}

export async function CreateRepairCheckList(params: ICreateRepairCheckList): Promise<IRepairCheckList | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/postInstall/repairCheckList`, params, { withCredentials: true });
        if (response.status === 201) return response.data as IRepairCheckList;

        return undefined;
    } catch (err: any) {
        console.log(err.toString());
        return undefined;
    }
}

//TODO: move to finance service module
export async function RequestSunLightPayment(projectIds: string) {
    try {
        const response = await axios.post(`${BACKEND_URL}/finance/sunlight/requestPayment`, { projectIds }, { withCredentials: true });
        if (response.status === 200) return response.data;
        return undefined;
    } catch (err: any) {
        console.log(err.toString());
        return undefined;
    }
}