import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import EverlightsLogo from "../../assets/LogoWithText.png";
import EverlightsLogoIconOnly from "../../assets/LogoColor.png";

import "./_navbar.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCalendarAlt,
    faCalendarCheck,
    faChartBar,
    faCog,
    faHome,
    faJournalWhills,
    faMapMarkedAlt,
    faSignOutAlt,
    faSms,
    faToolbox,
    faTools,
    faUsers,
    faBullseye,
    faLightbulb
} from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../contexts/AuthContext";
import { Scope, IUser } from "@common.abstractions";
import { ENVIRONMENT, VERSION } from "@frontend/environment";

export interface INavbarProps {
    user: IUser;
    logout: () => Promise<{ success: boolean; message?: string }>;
}

export default function Navbar(props: INavbarProps) {
    const authContext = useContext(AuthContext);
    const [navbarClass, setNavbarClass] = useState("navbar hide");
    const [navbarToggleClass, setNavbarToggleClass] = useState("navbar_block close");
    const [environmentStyle, setEnvironmentStyle] = useState<{ backgroundColor: string }>();

    const toggleNav = () => {
        if (navbarClass === "navbar open") {
            setNavbarClass("navbar hide");
            setNavbarToggleClass("navbar_block close");
            window.localStorage.setItem("navbar_toggle", "close");
        } else {
            setNavbarClass("navbar open");
            setNavbarToggleClass("navbar_block open");
            window.localStorage.setItem("navbar_toggle", "open");
        }
    };

    useEffect(() => {
        switch (ENVIRONMENT) {
            case "DEVELOPMENT":
                setEnvironmentStyle({
                    backgroundColor: "rgb(255,190,34)",
                });
                break;
            case "STAGING":
                setEnvironmentStyle({
                    backgroundColor: "rgb(0,0,255)",
                });
                break;
            case "PRODUCTION":
                setEnvironmentStyle({
                    backgroundColor: "rgb(255,255,255)",
                });
                break;
        }

        if (typeof window !== "undefined") {
            const checkWindowWidth = () => {
                const windowWidth = window.innerWidth;
                const localStorageValue = window.localStorage.getItem("navbar_toggle");

                if (windowWidth > 1275) {
                    setNavbarClass(
                        localStorageValue === "close" ? "navbar hide" : "navbar open"
                    );
                    setNavbarToggleClass(
                        localStorageValue === "close"
                            ? "navbar_block close"
                            : "navbar_block open"
                    );
                } else {
                    setNavbarClass("navbar hide");
                    setNavbarToggleClass("navbar_block close");
                }
            };

            checkWindowWidth();
            window.addEventListener("resize", checkWindowWidth);

            return () => {
                window.removeEventListener("resize", checkWindowWidth);
            };
        }
    }, []);

    return (
        <>
            <div className={navbarToggleClass}></div>
            <nav className={navbarClass} style={environmentStyle}>
                <div className="navbar_top" onClick={toggleNav} style={{ backgroundColor: "white" }}>
                    <div className="navbar_logo">
                        <img src={navbarClass === "navbar open" ? EverlightsLogo : EverlightsLogoIconOnly} alt="Logo" />
                    </div>
                </div>

                <div className="nav_list">
                    {authContext?.hasAnyGrant(Scope.Report.READ_ANY) && (
                        <Link to="/dashboard">
                            <FontAwesomeIcon icon={faHome} />
                            <span>Dashboard</span>
                        </Link>
                    )}

                    {authContext?.hasAnyGrant(Scope.Report.READ) && !authContext?.hasAnyGrant(Scope.Report.READ_ANY) && (
                        <Link to='/dealer/dashboard'>
                            <FontAwesomeIcon icon={faHome} />
                            <span>Dashboard</span>
                        </Link>
                    )}

                    {authContext?.hasAnyGrant(Scope.Report.READ_ANY) && (
                        <Link to='/dashboard/installs'>
                            <FontAwesomeIcon icon={faChartBar} />
                            <span>Branch Dash</span>
                        </Link>
                    )}

                    {authContext?.hasAnyGrant(Scope.Company.READ_ANY) && (
                        <Link to="/companies">
                            <FontAwesomeIcon icon={faUsers} />
                            <span>Dealers</span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.QuoteRequest.READ_ANY) && (
                        <Link to="/quoteRequests">
                            <FontAwesomeIcon icon={faJournalWhills} />
                            <span>Quotes</span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.Product.READ_ANY) && (
                        <Link to="/products">
                            <FontAwesomeIcon icon={faLightbulb} />
                            <span>Products</span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.Company.READ_ANY) && (
                        <Link to="/map">
                            <FontAwesomeIcon icon={faMapMarkedAlt} />
                            <span>Map</span>
                        </Link>
                    )}

                    {authContext?.hasAnyGrant(Scope.Lead.READ, Scope.Lead.CHILD_READ_ANY) && (
                        <Link to="/leads">
                            <FontAwesomeIcon icon={faBullseye} />
                            <span>Leads</span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.Order.READ, Scope.Lead.CHILD_READ_ANY) && (
                        <Link to="/orders">
                            <FontAwesomeIcon icon={faBook} />
                            <span>Orders </span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.Company.UPDATE) && (
                        <Link to="/account">
                            <FontAwesomeIcon icon={faCog} />
                            <span>Account</span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.Installs.READ) && (
                        <Link to="/installs">
                            <FontAwesomeIcon icon={faCalendarCheck} />
                            <span>Installs</span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.Installs.READ) && (
                        <Link to="/repairs">
                            <FontAwesomeIcon icon={faTools} />
                            <span>Repairs</span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.User.READ) && (
                        <Link to="/users">
                            <FontAwesomeIcon icon={faUsers} />
                            <span>Users</span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.Booking.READ) && (
                        <Link to="/toastCalendar">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                            <span>Consult Calendar</span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.Booking.READ) && (
                        <Link to="/calendar">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                            <span>Install Calendar</span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.PostInstall.READ) && (
                        <Link to="/installerTool">
                            <FontAwesomeIcon icon={faToolbox} />
                            <span>Footage Tracker</span>
                        </Link>
                    )}
                    {authContext?.hasAnyGrant(Scope.Sms.SEND_AS_SELF) && (
                        <Link to="/sms">
                            <FontAwesomeIcon icon={faSms} />
                            <span>Sms Sender</span>
                        </Link>
                    )}
                </div>
                <div className="navbar_bottom">
                    <h6>{`${props.user.firstname}`}</h6>
                    <button className="button outline" onClick={props.logout}>
                        <span>Logout</span>
                    </button>
                    <FontAwesomeIcon icon={faSignOutAlt} onClick={props.logout} />
                </div>
                <span className="version text-gray-500 text-sm mb-2">{VERSION}</span>
            </nav>
        </>
    );
}