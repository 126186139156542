import { IS3UploadResponse } from "@common.abstractions";
import { IS3UploadRequestParams } from "@common.abstractions";
import { IProductImage, ICreateProductImageParams } from "@common.abstractions";
import axios from "axios";
import {BACKEND_URL} from "@frontend/environment";

const CreateProductImage = async (
    filename: string
    ) : Promise<  (IProductImage & {
            uploadUrl: string;
        })
        | undefined
      > => {
        try {
            console.log(`Called CreateProductImage(${filename})`);
            const params = {filename} as ICreateProductImageParams;
    
            const response = await axios.post(`${BACKEND_URL}/products/images`, params);
    
            if (response.status === 201) {
                console.log(response.data);
                return response.data as IProductImage & {
                    uploadUrl: string;
                };
            } 
            console.log("Failed to save product image with code: ", response.status, response.data);
            return undefined;
    
        } catch (err: any) {
            console.log("Upload Image error", err);
            return undefined;
        }
    }

export default CreateProductImage