import React from 'react'

export type PartnerProps = {
    totalGoal: number;
    totalProgress: number;
    totalPercent: number;
    ftSold: number;
    onClick(): void;
}

export default function PartnerSales(props: PartnerProps) {
    return (
        <>
            <div className="p-5 shadow-md hover:shadow-lg duration-theme h-auto opacity-70 hover:opacity-100" onClick={props.onClick}>
                <h2 className="text-gray-500 mb-3">Partner Monthly Target </h2>
                <div className="flex items-end mb-4">
                    <div className="text-3xl text-gray-700 font-bold">{props.totalPercent}%</div>
                    <div className="text-xl text-gray-700 font-bold flex ml-5">
                        <div className="relative" style={{ minWidth: "30px" }}>
                            {(props.totalProgress)}
                            <div className="absolute bottom-full text-xs whitespace-nowrap">progress</div>
                        </div>
                        <div className="mx-3">/</div>
                        <div className="relative">
                            {props.totalGoal}
                            <div className="absolute bottom-full text-xs whitespace-nowrap">target</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-5 shadow-md hover:shadow-lg duration-theme h-auto opacity-70 hover:opacity-100">
                <div className="flex">
                    <h2 className="text-gray-500 mb-3">Partner Ft Sold </h2>
                    <p className="text-3xl text-gray-700 font-bold ml-3">{props.ftSold}</p>
                </div>
            </div>
        </>
    )
}