import { int } from "./primitives"

export enum LeadStatus {
    THIRD_PARTY_PROSPECT = "3rd Party Prospect",
}

export interface IHubspotEvent {
    header: object,
    body: object
}


export interface IHubspotUser {
    id: string,
    email: string,
    roleIds: string[],
    primaryTeamId?: string,
    superAdmin: boolean
}


export interface IHubspotOwner {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    userId: string,
    createdAt: Date,
    updatedAt: Date,
    archived: boolean
}


export interface IHubspotContact {
    id: string,
    properties: {
        createdate: Date,
        email: string,
        firstname: string,
        hs_object_id: string,
        lastmodifieddate: Date,
        lastname: string
    },
    createdAt: Date,
    updatedAt: Date,
    archived: boolean
}

export interface ICreateHubspotCommunication {
    id: string,
    properties: {
        hs_communication_channel_type: string,
        hs_communication_logged_from: string,
        hs_createdate: Date,
        hs_lastmodifieddate: Date,
        hs_object_id: string,
        hubspot_owner_id: string
    },
    create_at: Date,
    updated_at: Date,
    archived: boolean,
    associations: {
        contacts: {
            results: [{
                id: string,
                type: string
            }]
        }
    }
}

export interface IHubspotCommunication {
    id: int,
    hubspot_communication_id: string,
    hs_communication_channel_type: string,
    hs_communication_logged_from: string,
    hs_createdate: Date,
    hs_lastmodifieddate: Date,
    hs_object_id: string,
    hubspot_owner_id: string,
    create_at: Date,
    updated_at: Date,
    archived: boolean,
    associations: {
        contacts: {
            results: [{
                id: string,
                type: string
            }]
        }
    } 
}