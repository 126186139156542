import React, { useEffect } from "react";
import { ICompany } from "@common.abstractions";
import { RetrieveLeads, RetrieveLeadsCount } from "../../../services/leads";
import { Link } from "react-router-dom";
import { Equals } from "@common.api";
import { faFlag, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CompanyRow(props: { company: ICompany }) {
  const company = props.company;
  const [numLeads, setNumLeads] = React.useState<number>();
  const [numConvertedLeads, setNumConvertedLeads] = React.useState<number>();
  const [conversionRate, setConversionRate] = React.useState<number>();
  const [unopenedCount, setUnopenedCount] = React.useState<number>();
  const [tileColorClass, setTileColorClass] = React.useState("row_undefined");

  const determineColorClass = (numLeads: number, conversionRate: number) => {
    if (numLeads === 0 || company.suspended) {
      return setTileColorClass("text-gray-500");
    } else if (conversionRate < 20) {
      return setTileColorClass("text-theme_red");
    } else if (conversionRate < 33.6) {
      return setTileColorClass("text-theme_orange");
    } else {
      return setTileColorClass("text-theme_green");
    }
  };

  // const loadLeads = async () => {
  //   let tempLeadsCount = 0;
  //   let tempLeadsClosed = 0;
  //   let tempCloseRate = 0;
  //   let tempUnopenedCount = 0;

  //   console.log("getting leads from CompanyRow.tsx");

  //   const tempLeads = await RetrieveLeads({
  //     filters: {
  //       company: {
  //         id: Equals(company.id),
  //       },
  //     },
  //   },             
  //   {
  //     direction: "forward",
  //     count: 1,
  //     // count: num,
  //     cursor: undefined,
  // });

  //   if (tempLeads) {
  //     tempLeadsCount = tempLeads.values.length;
  //     tempUnopenedCount = tempLeads.values.filter((el) => !el.viewedDate).length;
  //     tempLeadsClosed = tempLeads.values.filter((el) => el.vConverted).length;
  //     if (tempLeadsCount === 0 || tempLeadsClosed === 0) {
  //       tempCloseRate = 0;
  //     } else {
  //       tempCloseRate = Number(
  //         ((tempLeadsClosed / tempLeadsCount) * 100).toFixed(2)
  //       );
  //     }
  //   }

  //   setNumLeads(tempLeadsCount);
  //   setNumConvertedLeads(tempLeadsClosed);
  //   setConversionRate(tempCloseRate);
  //   setUnopenedCount(tempUnopenedCount);

  //   determineColorClass(tempLeadsCount, tempCloseRate);
  // };

  useEffect(() => {
    setNumLeads(company.totalLeads);
    setNumConvertedLeads(company.closedLeads);
    setConversionRate(Number(((company.closedLeads! / company.totalLeads!) * 100).toFixed(2)));

    //loadLeads();
  }, []);

  const currentPackage = company.packages?.find((el) => new Date(el.startDate) < new Date() && new Date(el.endDate) > new Date());

  const renderCompanyNameColumn = (company: ICompany) => {
    if (company.suspended && !company.deletedAt) {
      return (
        <div>
          <FontAwesomeIcon icon={faFlag} size='sm' color='rgb(245, 158, 11)' />
          {company.name}
        </div>
      );
    }
    else if (company.deletedAt) {
      return (
        <div>
          <FontAwesomeIcon icon={faTrash} size='sm' color='rgb(255, 0, 0)' />
          {company.name}
        </div>
      );
    }
    else {
      return (
        <div>{company.name}</div>
      );
    }
  }

  return (
    <Link key={company.id} to={`/companies/${company.id}/`}>
      <div className="company_row row item relative">

        <div id='company-name'>
          {renderCompanyNameColumn(company)}
        </div>

        <div id="company-location">
          {company.address.state}, {company.address.city}
        </div>

        <div id="company-package">{currentPackage ? currentPackage.name : "No active package"}</div>

        <div id="dicount-percent">
          {currentPackage
            ? currentPackage.discountPercent + "%"
            : "No active discount"}
        </div>

        {numConvertedLeads === undefined ||
          numLeads === undefined ||
          conversionRate === undefined ? (
          <>
            <div>
              <h5>Loading...</h5>
            </div>
            <div>
              <h5>Loading...</h5>
            </div>
            <div>
              <h5>Loading...</h5>
            </div>
          </>
        ) : (
          <>
            <div id="company-leads">{numLeads ? <>{numLeads}</> : <>0</>}</div>

            <div id="company-lead-closed">
              {numConvertedLeads ? <>{numConvertedLeads}</> : <>0</>}
            </div>

            <div id="company-conversion" className={tileColorClass}>
              {conversionRate ? <>{conversionRate.toFixed(2)}%</> : <>0%</>}
            </div>

          </>
        )}
      </div>
    </Link>
  );
}
