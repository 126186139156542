import { VERSION } from "@frontend/environment";
import { getFormattedErrorString } from "@frontend/services/api-response";
import { getDepartments, getUsers, SendSms } from "@frontend/services/sms";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast, ToastContainer, useToastContainer } from "react-toastify";
import LogoWithText from "../../assets/LogoWithText.png";
import Button from "../misc/button/Button";
import Select from "react-select";

function tryExtractPhoneNumber(data: string): string | undefined {
    const trimmed = data.trim();
    const numbersOnly = trimmed.replace(/\D/g, "");
    if (numbersOnly.length < 10 || numbersOnly.length > 11) return undefined;
    return numbersOnly;
}

function tryExtractPhoneNumbers(data: string): Array<string> | undefined {
    const split = data.split(",").reverse(); // Reverse so we fail on the last entry early.
    const numbers: Array<string> = [];
    for (const e of split) {
        const number = tryExtractPhoneNumber(e);
        if (number === undefined) return undefined;
        numbers.push(number);
    }

    return numbers;
}

export default function SmsPage() {
    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    const [error, setError] = useState<string>();

    const [departmentOptions, setDepartmentOptions] = useState<Array<{ label: string; value?: string }>>([]);
    const [userOptions, setUserOptions] = useState<Array<{ label: string; value?: string }>>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<{label: string; value?: string}>();

    const defaultDepartment = { label: "None", value: undefined };
    const defaultUser = { label: "Current User", value: undefined };
    const defaultNonUser = { label: "Department", value: "Department" };

    const loadDepartments = async () => {
        const res = await getDepartments();

        if (!res.success) {
            toast.error("Failed to load department options!");
            return [];
        }
        const newDepartmentOptions: Array<{ label: string; value?: string }> = res.data.map((e) => {
            return { label: e.name, value: e.id };
        });
        newDepartmentOptions.unshift(defaultDepartment);
        setDepartmentOptions(newDepartmentOptions);
        setSelectedDepartment(defaultDepartment);
    };

    const loadUsers = async (selectedDepartement: string | undefined) => {
        const res = await getUsers(selectedDepartement);

        if (!res.success) {
            toast.error("Failed to load department options!");
            return [];
        }

        let newUserOptions: Array<{ label: string; value?: string }> = [];
        if(res.data) {
            newUserOptions = res.data.map((e) => {
                return { label: e.name, value: e.id };
            });
        }
        newUserOptions.unshift(defaultUser);
        newUserOptions.unshift(defaultNonUser);
        setUserOptions(newUserOptions);
    }

    const departmentChangeHandler = (value: { label: string; value?: string }) => {
        setSelectedDepartment(value);
    }

    useEffect(() => {
        loadDepartments();
        loadUsers(selectedDepartment?.value);
    }, []);

    useEffect(() => {
        loadUsers(selectedDepartment?.value);
    }, [selectedDepartment]);

    return (
        <div className="w-full h-full max-w-full">
            <div className="login_form">
                <form
                    onSubmit={handleSubmit(async (vals) => {
                        console.log(JSON.stringify(vals));
                        setError(undefined);
                        const numbers = tryExtractPhoneNumbers(vals.numbers);
                        if (!numbers) {
                            toast.error("Invalid phone number found!");
                            return;
                        }
                        const message = vals.message as string | undefined;
                        if (!message) {
                            toast.error("No message!");
                            return;
                        }

                        const department = vals.department;
                        if (!department) {
                            toast.error("No department!");
                            return;
                        }

                        const user = vals.user;
                        if (!user) {
                            toast.error("No user!");
                            return;
                        }

                        const id = toast.loading("Sending...");
                        const response = await SendSms(numbers, message, department.value, user.value);

                        if (!response.success) {
                            toast.update(id, { render: "Failed!", type: "error", isLoading: false, autoClose: 2000 });
                            setError(`Message failed to send\n${getFormattedErrorString(response)}`);
                            return;
                        }

                        if (response.data.failed.length !== 0) {
                            toast.update(id, { render: "Send Incomplete!", type: "warning", isLoading: false, autoClose: 2000 });
                            setError("Message failed to send to:\n" + response.data.failed.join(", "));
                            return;
                        }

                        toast.update(id, { render: "Message sent!", type: "success", isLoading: false, autoClose: 2000 });
                    })}
                >
                    <div className="flex mb-4">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-500 text-sm mb-1">Send from department</label>
                            <Controller
                                control={control}
                                name="department"
                                defaultValue={defaultDepartment}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={departmentOptions}
                                        onChange={(value) => {field.onChange(value); departmentChangeHandler(value);}}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className="flex mb-4">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-500 text-sm mb-1">Send from user</label>
                            <Controller
                                control={control}
                                name="user"
                                defaultValue={defaultNonUser}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        options={userOptions}
                                    />
                                )}
                            />
                                                       {/* <Select
                                className="quotes_date_dropdown mr-8"
                                defaultValue={{
                                    value: 0,
                                    label: "Current User",
                                }}
                                options={[
                                    {
                                        value: 0,
                                        label: "Current User",
                                    },
                                    {
                                        value: 6382682446004224,
                                        label: "Aaron Bloom",
                                    },
                                    {
                                        value: 5964685398016000,
                                        label: "Amanda Sanchez",
                                    },
                                    {
                                        value: 5279639053828096,
                                        label: "Chris Muegge",
                                    },
                                    {
                                        value: 6416126625480704,
                                        label: "Matthew Heinz",
                                    },
                                ]}
                            />  */}
                        </div>
                    </div>

                    <div className="flex mb-4">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-500 text-sm mb-1">Recipients (comma separated)</label>
                            <textarea
                                rows={4}
                                className="input"
                                placeholder="1111111111,2222222222,...."
                                {...register("numbers", {
                                    required: true,
                                    validate: {
                                        valid: (v) => tryExtractPhoneNumbers(v) !== undefined,
                                    },
                                })}
                                aria-invalid={errors.numbers ? "true" : "false"}
                            />
                            {errors.numbers?.type === "required" && (
                                <p className="text-red-600" role="alert">
                                    At least one recipient is required
                                </p>
                            )}
                            {errors.numbers?.type === "valid" && (
                                <p className="text-red-600" role="alert">
                                    One or more numbers are invalid.
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="flex mb-4">
                        <div className="flex flex-col w-full">
                            <label className="text-gray-500 text-sm mb-1">Message</label>
                            <textarea
                                rows={4}
                                className="input"
                                placeholder="Your message here..."
                                {...register("message", { required: true })}
                                aria-invalid={errors.message ? "true" : "false"}
                            />
                            {errors.message?.type === "required" && (
                                <p className="text-red-600" role="alert">
                                    Message is required
                                </p>
                            )}
                        </div>
                    </div>

                    <Button type="submit" color="blue" style="solid">
                        Send
                    </Button>
                    {/* <div className="mx-auto text-gray-500 mt-4">
                        <Link to="forgotPassword">
                            Forgot Password
                        </Link>
                    </div> */}
                    {error !== undefined && (
                        <div className="p-4 my-4 bg-red-500 rounded-lg text-white" role="alert">
                            {error.split("\n").map((l) => (
                                <p>{l}</p>
                            ))}
                        </div>
                    )}
                </form>
            </div>
            <div className="absolute bottom-1 right-1 text-gray-500">Version: {VERSION}</div>
        </div>
    );
}
