import axios from "axios";
import { BACKEND_URL } from "../environment";
import { Query, serializeQuery } from "@common.api";
import { IBookableUser, ICreateBookableUserParams, int, IUpdateBookableUserParams } from "@common.abstractions";

export async function CreateBookableUser(params: ICreateBookableUserParams): Promise<IBookableUser | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/bookableUsers`, params, { withCredentials: true });
        if (response.status === 201) return response.data as IBookableUser;
        return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveBookableUser(bookableUserId: int): Promise<IBookableUser | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/bookableUsers/${bookableUserId}`, { withCredentials: true });
        if (response.status === 200) return response.data as IBookableUser;
        return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveBookableUserByUserId(userId: int): Promise<IBookableUser | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/bookableUsers/user/${userId}`, { withCredentials: true });
        if (response.status === 200) return response.data as IBookableUser;
        return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveBookableUsers(query?: Query<IBookableUser>): Promise<IBookableUser[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/bookableUsers`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200) return response.data as IBookableUser[];
        return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export async function UpdateBookableUser(bookableUserId: int, params: IUpdateBookableUserParams): Promise<IBookableUser | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/bookableUsers/${bookableUserId}`, params, { withCredentials: true });
        if (response.status === 200) return response.data as IBookableUser;
        return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteBookableUser(bookableUserId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/bookableUsers/${bookableUserId}`, { withCredentials: true });
        return response.status === 200;
    } catch (err) {
        console.log(err);
        return false;
    }
}
