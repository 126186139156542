import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetShippingQuotes, SetShipping, SetShippingAddress } from "@frontend/services/orders";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import "./_shipping.scss";

import { Loading } from "../../misc/loading/";
import { Address, int, IShippingAddress, ShippingOption, ShippingQuote } from "@common.abstractions";
import { formatMoney } from "@common.tools";
import { RetrieveCompany, RetrieveShippingAddresses } from "@frontend/services/companies";
import AuthContext from "@frontend/contexts/AuthContext";
import { toast } from "react-toastify";
import { Page } from "@frontend/components/misc/layout";
import TopBar from "@frontend/components/misc/top-bar/TopBar";

export default function Shipping() {
    const authContext = useContext(AuthContext);
    const params = useParams<{ orderId: string }>();
    const [orderId] = useState<int>(int.parse(params.orderId));
    const [shippingQuotes, setShippingQuotes] = useState<ShippingQuote[]>();
    const [showNextButton, setShowNextButton] = useState<boolean>(false);
    const [settingShippingOption, setSettingShippingOption] = useState<boolean>(false);
    const [shippingOptionIndex, setShippingOptionIndex] = useState<number>();
    const [shippingAddresses, setShippingAddresses] = useState<IShippingAddress[]>();
    const [selectedShippingAddress, setSelectedShippingAddress] = useState<IShippingAddress>();

    const loadShippingAddresses = async () => {
        const response = await RetrieveShippingAddresses(authContext!.user!.company!.id);
        if (!response) {
            toast.error("Error loading shipping addresses");
            setShippingAddresses([]);
            return;
        }

        if (response.length === 0) {
            alert("You do not have any shipping addresses. Please add them on the account -> shipping addresses page");
            setShippingAddresses([]);
            return;
        }

        setShippingAddresses(response);
        await SetShippingAddress(orderId, response[0].id);

        const tempShippingQuotes = await GetShippingQuotes(orderId);
        if (tempShippingQuotes) {
            setShippingQuotes(tempShippingQuotes);
            return;
        }
    };

    const loadShippingQuotes = async () => {
        if (!selectedShippingAddress) return
        setShippingQuotes(undefined);

        const response = await SetShippingAddress(orderId, selectedShippingAddress.id);
        if (!response) {
            toast.error("Error setting shipping address");
            return;
        }

        const tempShippingQuotes = await GetShippingQuotes(orderId);
        if (tempShippingQuotes) {
            setShippingQuotes(tempShippingQuotes);
            return;
        }

        alert("There are no shipping options for the products in your cart. Please contact us directly.");
    };
    const handleShippingOptionChange = async (i: number) => {
        let shippingOption: ShippingOption;
        setSettingShippingOption(true);
        setShowNextButton(true);
        setShippingOptionIndex(i);

        if (shippingQuotes) {
            if (shippingQuotes[i]) {
                shippingOption = {
                    name: shippingQuotes[i].name,
                    carrierCode: shippingQuotes[i].carrierCode,
                    serviceCode: shippingQuotes[i].serviceCode,
                    packageTypeCode: shippingQuotes[i].packageTypeCode,
                };

                const setShippingRes = await SetShipping(orderId, shippingOption);

                if (setShippingRes) {
                    console.log("4");
                    setSettingShippingOption(false);
                    return;
                }
            }
        }
    };

    useEffect(() => {
        loadShippingAddresses();
    }, []);

    useEffect(() => {
        loadShippingQuotes();
    }, [selectedShippingAddress]);

    return (
        <Page>
            <TopBar title="Shipping" />
            <div className="flex flex-col items-center px-4">
                <div className="w-full max-w-lg">
                    {shippingAddresses ? (
                        shippingAddresses.length ? (
                            <>
                                <h2 className="text-lg font-semibold mb-4 text-center">Select a Shipping Option</h2>
                                <div className="flex flex-col items-center">
                                    <select
                                        className="w-full my-4 p-2 border border-gray-300 rounded"
                                        onChange={(e) => setSelectedShippingAddress({ ...shippingAddresses[Number(e.target.value)] })}
                                    >
                                        {shippingAddresses.map((el, i) => (
                                            <option key={`shipping address ${i}`} value={i}>
                                                {el.streetAddress} {el.city}, {el.state} {el.zip}
                                            </option>
                                        ))}
                                    </select>

                                    {shippingQuotes ? (
                                        shippingQuotes.length ? (
                                            <div className="flex flex-col md:flex-row flex-wrap justify-center">
                                                {shippingQuotes.map((quote, index) => (
                                                    <label
                                                        key={quote.name}
                                                        className={`p-4 border rounded m-2 text-center cursor-pointer transition-transform duration-200
                                                ${shippingOptionIndex === index ? "bg-orange-500 text-white transform translate-y-[-10px] scale-105" : "bg-white text-gray-600"}
                                                hover:bg-orange-500 hover:text-white hover:transform hover:translate-y-[-10px] hover:scale-105`}
                                                        onClick={() => handleShippingOptionChange(index)}
                                                        aria-label={`Shipping option ${quote.name}`}
                                                    >
                                                        <div>{quote.name}</div>
                                                        <div>{formatMoney(quote.cost)}</div>
                                                        <input
                                                            className="mt-2"
                                                            type="radio"
                                                            name="shippingOption"
                                                            checked={shippingOptionIndex === index}
                                                            onChange={() => handleShippingOptionChange(index)}
                                                        />
                                                    </label>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="text-center text-gray-500">No Shipping Options</div>
                                        )
                                    ) : (
                                        <Loading />
                                    )}
                                    <h4 className="text-center mt-4 text-gray-600">
                                        Note: None of these are an "expedited" shipping option. If you need your order expedited, please reach out to your EverLights representative to check pricing and availability.
                                    </h4>
                                </div>
                            </>
                        ) : (
                            <div className="text-center text-gray-500 w-full">
                                No shipping address, add them on the shipping addresses page under the account tab.
                            </div>
                        )
                    ) : (
                        <Loading center />
                    )}
                </div>

                {showNextButton && (
                    settingShippingOption ? (
                        <Loading center />
                    ) :
                        // (
                        //     <Link to={`/orders/${orderId}/checkout/`} className="mt-6 px-4 py-2 bg-blue-500 text-white rounded flex items-center">
                        //         Review Order
                        //         <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                        //     </Link>
                        // )
                        (
                            <Link to={`/orders/${orderId}/payment/`} className="mt-6 px-4 py-2 bg-blue-500 text-white rounded flex items-center">
                                Payment Options
                                <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                            </Link>
                        )
                )}
            </div>
        </Page>
    );
}
