import React from 'react'

export default function ProgressBar2(props: {
    count: number,
    max: number
}) {
    const { count, max } = props
    return (
        <div className="w-96 bg-gray-200 rounded-full">
            <div
                className="bg-gradient-to-r from-green-400 to-blue-500 hover:from-pink-500 hover:to-yellow-500 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                style={{ width: `${(count / max) * 100}%` }}
            > {((count / max) * 100).toFixed()}%
            </div>
        </div>
    )
}
