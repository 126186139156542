import { int } from '@common.abstractions';
import axios from 'axios';
import { EventEmitter } from 'events';

export interface IFileUploader {
    addOnStartedListener: (listener: () => void) => void;
    addOnProgressListener: (listener: (percent: number) => void) => void;
    addOnCompletedListener: (listener: () => void) => void;
    addOnCancelledListener: (listener: () => void) => void;
    addOnFailListener: (listener: () => void) => void;

    start: () => void;
    cancel: () => void;
    inProgress: () => boolean;

    id: int;
    filename: string;
    started: boolean;
    uploadPercent: number;
    completed: boolean;
    cancelled: boolean;
    failed: boolean;
    err?: string;
}

export class FileUploader implements IFileUploader {
    private events: EventEmitter = new EventEmitter();

    private file: File;
    private url: string;
    private cancelTokenSource = axios.CancelToken.source();

    constructor(
        id: int,
        file: File,
        url: string,
    ) {
        this.id = id;
        this.filename = file.name;
        this.file = file;
        this.url = url;
    }

    addOnStartedListener = (listener: () => void) =>
        this.events.on('UploadStarted', listener);

    addOnProgressListener = (listener: (percent: number) => void) =>
        this.events.on('UploadProgress', listener);

    addOnCompletedListener = (listener: () => void) =>
        this.events.on('UploadCompleted', listener);

    addOnCancelledListener = (listener: () => void) =>
        this.events.on('UploadCancelled', listener);

    addOnFailListener = (listener: () => void) =>
        this.events.on('UploadFailed', listener);

    start = () => {
        if (this.cancelled) return;

        this.started = true;
        this.events.emit('UploadStarted');

        const handleUploadProgress = (progressEvent: ProgressEvent) => {
            this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.events.emit('UploadProgress', this.uploadPercent);
        }

        //axios.put(this.url, this.file, { headers: { 'Authorization': "", 'Content-Type': this.file.type }, onUploadProgress: handleUploadProgress, cancelToken: this.cancelTokenSource.token })
        var instance = axios.create();
        delete instance.defaults.headers.common['Authorization'];
        instance.put(this.url, this.file, { headers: { 'Content-Type': this.file.type }, onUploadProgress: handleUploadProgress, cancelToken: this.cancelTokenSource.token }
        ).then(res => {
            if (res.status === 200) {
                if (this.uploadPercent !== 100) {
                    this.uploadPercent = 100;
                    this.events.emit('UploadProgress', 100);
                }
                this.completed = true;
                this.events.emit('UploadCompleted');
            }
            else {
                this.failed = false;
                this.events.emit('UploadFailed');
                if (res.data)
                    this.err = res.data;
            }
        })
        .catch(err => {
            this.failed = false;
            this.events.emit('UploadFailed');
            this.err = err;
        })
    }

    cancel = () => {
        this.cancelTokenSource.cancel();
        this.cancelled = true;
        this.events.emit('UploadCancelled');
    };

    inProgress = () => this.started && !this.completed && !this.cancelled && !this.failed;

    id: int;
    filename: string;
    started: boolean = false;
    uploadPercent: number = 0;
    completed: boolean = false;
    cancelled: boolean = false;
    failed: boolean = false;
    err?: string;
}
