import { float, IDiscountPackage, int, IOrder, IOrderItem, IProduct, IProductVariant, VariantAttribute } from "@common.abstractions";
import { calculateDiscount, formatMoney } from "@common.tools";
import { faChevronRight, faShoppingBag, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthContext from "@frontend/contexts/AuthContext";
import { DeleteOrderItem, RetrieveOrder, UpdateOrderItem } from "@frontend/services/orders";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import "./_sideCart.scss";

export default function SideCart(props: {
    orderId: int;
    addedItem: IOrderItem | undefined;
    visible: boolean;
    closeSideCart: () => void;
    openSideCart: () => void;
    loaded: boolean;
}) {
    const authContext = useContext(AuthContext);
    const { addedItem, orderId, visible, closeSideCart, openSideCart, loaded } = props;
    const [order, setOrder] = useState<IOrder>();
    const [orderSubtotal, setOrderSubtotal] = useState<string>();
    const [discountPackage, setDiscountPackage] = useState<IDiscountPackage | undefined>();
    const navigate = useNavigate();

    const loadOrder = async () => {
        const tempOrder = await RetrieveOrder(orderId);
        setOrder(tempOrder);
    };

    const handleAddItem = async (addedItem: IOrderItem) => {
        if (addedItem && order) {
            const productVariant = addedItem.productVariant as IProductVariant;
            const product = productVariant.product as IProduct;
            const minPurchaseQuantity = product.minPurchaseQuantity || 0;

            if (addedItem.quantity < minPurchaseQuantity) {
                alert(`Minimum purchase quantity for this item is ${minPurchaseQuantity}`);
                await handleDeleteItem(addedItem.id); // Delete the item if it doesn't meet the minimum purchase quantity
                return;
            }

            // Check marketing spend date contraint
            const d = new Date();
            const currentMonth = d.getMonth();
            const currentYear = d.getFullYear();
            const currentDay = d.getDate();

            const monthAttribute = productVariant.attributes.find((el) => el.name === 'Month')?.value;
            const yearAttribute = productVariant.attributes.find((el) => el.name === 'Year')?.value;

            if (monthAttribute && yearAttribute) {
                const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                const productMonth = monthNames.indexOf(monthAttribute);
                const productYear = parseInt(yearAttribute);

                if (productYear === currentYear && (currentDay > 15 && productMonth === (currentMonth + 1) % 12)) {
                    alert('Cannot add marketing spend for the next month past the 15th of the current month.');
                    await handleDeleteItem(addedItem.id);
                    return;
                }
            }

            const tempOrder: IOrder = { ...order };
            let match: boolean = false;

            tempOrder.items = tempOrder.items?.map((el) => {
                if ((el.productVariant as IProductVariant).id === productVariant.id) {
                    match = true;
                    return addedItem;
                }
                return el;
            });

            if (!match) {
                tempOrder.items?.push(addedItem);
            }

            setOrder(tempOrder);
        }
    };

    const handleUpdateItem = async (qty: int, itemId: int, index: number) => {
        const item = order?.items?.find((el) => el.id === itemId);
        if (!item) return;

        const productVariant = item.productVariant as IProductVariant;
        const product = productVariant.product as IProduct;
        const minPurchaseQuantity = product.minPurchaseQuantity || 0;

        if (qty < minPurchaseQuantity) {
            alert(`Minimum purchase quantity for this item is ${minPurchaseQuantity}`);
            return;
        }

        if (qty === 0) {
            handleDeleteItem(itemId);
            return;
        }

        const updateRes = await UpdateOrderItem(orderId, itemId, { quantity: qty });

        if (updateRes && order) {
            const tempOrder: IOrder = { ...order };

            tempOrder.items = tempOrder.items?.map((el) => {
                if (el.id === itemId) {
                    el.quantity = qty;
                }
                return el;
            });

            setOrder(tempOrder);
        }
    };

    const handleDeleteItem = async (itemId: int) => {
        const deleteRes = await DeleteOrderItem(orderId, itemId);

        if (!order) return;

        if (deleteRes) {
            let tempOrder = { ...order };
            const tempOrderItems = { ...order }.items?.filter((el) => el.id !== itemId);
            tempOrder.items = tempOrderItems;
            setOrder(tempOrder as IOrder);
            return;
        }
    };

    const initializeDiscountPackage = () => {
        if (authContext?.user?.company?.packages) {
            const currentDiscountPackage = authContext.user.company.packages.find((el) => new Date(el.startDate) < new Date() && new Date)
            setDiscountPackage(currentDiscountPackage);
        }
    };

    const calculateItemTotal = (item: IOrderItem) => {
        if (!item.productVariant) return "NOT WORKING";
        if (discountPackage) {
            if (item.productVariant.retailPrice) {
                return formatMoney(calculateDiscount(float(item.productVariant.retailPrice * item.quantity), discountPackage.discountPercent));
            } else {
                return formatMoney(
                    calculateDiscount(float((item.productVariant.product as IProduct).retailPrice * item.quantity), discountPackage.discountPercent)
                );
            }
        } else {
            if (item.productVariant.retailPrice) {
                return formatMoney(item.productVariant.retailPrice * item.quantity);
            } else {
                return formatMoney((item.productVariant.product as IProduct).retailPrice * item.quantity);
            }
        }
    };
    const calculateSubtotal = () => {
        console.log("calculatesubtotal", order);
        if (!order?.items?.length) {
            setOrderSubtotal(formatMoney(0));
            return;
        }

        const amounts = order.items.map((item) => {
            const variant = item.productVariant;
            if (!variant) throw new Error("Variant not loaded");

            if (variant.retailPrice) return item.quantity * variant.retailPrice;

            const product = variant.product;
            if (!product) throw new Error("Product not loaded");

            return item.quantity * product.retailPrice;
        });

        amounts.length &&
            (discountPackage
                ? setOrderSubtotal(formatMoney(calculateDiscount(float(amounts.reduce((prev, cur) => prev + cur)), discountPackage.discountPercent)))
                : setOrderSubtotal(formatMoney(amounts.reduce((prev, cur) => prev + cur))));
    };

    const orderItemAmounts = () => {
        let amount = 0;
        if (!order?.items?.length) {
            return amount;
        }

        order.items.forEach((item) => amount = amount + item.quantity);
        return amount;
    }

    const handleShippingOptions = () => {
        if (authContext?.user?.company?.id !== order?.lead.company?.id) {
            toast.error('Cannot add shipping options for another company');
            return;
        }
        navigate(`/orders/${orderId}/shipping/`);
    }

    useEffect(() => {
        loadOrder();
        initializeDiscountPackage();
    }, []);

    useEffect(() => {
        if (addedItem) {
            handleAddItem(addedItem);
        }
    }, [addedItem]);

    useEffect(() => {
        calculateSubtotal();
    }, [order]);

    return (
        <>
            {loaded ? (
                <div className={`side_cart_wrapper ${visible ? "show" : ""}`}>
                    <div onClick={closeSideCart} className="close_side_cart_wrapper">
                        <div className="close_side_cart">
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                        <div className="side_cart_title">Cart</div>
                    </div>
                    <div className="products_wrapper">
                        {order?.items?.length ?
                            order.items.map((item, index) => {
                                return (
                                    <div key={item.id} className="flex items-end relative mx-4 my-8">
                                        <div
                                            className="absolute top-0 right-1 text-gray-500 hover:text-gray-600 cursor-pointer"
                                            onClick={() => handleDeleteItem(item.id)}
                                        >
                                            <FontAwesomeIcon icon={faTimes} />
                                        </div>
                                        <div className="product_info">
                                            <div className="text-gray-500 text-md leading-tight">
                                                {((item.productVariant as IProductVariant).product as IProduct).title}
                                            </div>
                                            <div className="text-theme_green text-md">{calculateItemTotal(item)}</div>
                                            <div className="product_variants_wrapper">
                                                {(item.productVariant as IProductVariant).attributes.length
                                                    ? ((item.productVariant as IProductVariant).attributes as VariantAttribute[]).map((attr) => {
                                                        return (
                                                            <div key={attr.name + attr.value} className="product_variant leading-none">
                                                                <span className="text-gray-500 text-sm mr-1 leading-none">{`${attr.name}:`}</span>
                                                                <span className="text-gray-500 text-sm leading-none">{`${attr.value}`}</span>
                                                            </div>
                                                        );
                                                    })
                                                    : null}
                                            </div>
                                        </div>
                                        <div className="qty_wrapper ml-4">
                                            <input
                                                className="w-16 text-gray-600 text-md border-gray-300 p-1 border"
                                                min="0"
                                                type="number"
                                                value={item.quantity}
                                                onChange={(e) => handleUpdateItem(int.parse(e.target.value), item.id, index)}
                                            />
                                        </div>
                                    </div>
                                );
                            }) : (
                                <div className="text-gray-500 mt-5 text-center">There are no items in cart</div>
                            )}
                    </div>

                    <div className="sidebar_total">{orderSubtotal}</div>
                    <button className="checkout_process_button button next checkout" onClick={handleShippingOptions}>
                        Shipping Options
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            ) : null}
            {!visible ? (
                <div className="side_cart_min" onClick={openSideCart}>
                    {
                        order?.items && (
                            <div className="bg-white shadow-md absolute rounded-full h-6 w-6 flex items-center justify-center -top-1 -right-1 text-xs text-gray-600">{orderItemAmounts()}</div>
                        )
                    }
                    <FontAwesomeIcon icon={faShoppingBag} />
                </div>
            ) : (
                <div className={`side_cart_background`} onClick={closeSideCart}></div>
            )}
        </>
    );
}
