import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { RetrieveQuoteRequest, DeleteQuoteRequest, UpdateQuoteRequest, RetrieveQuoteRequestImageBlob } from "../../../services/quote-requests";

import { SelectDealerPopup } from "../../misc/select-dealer-popup";
import { CreateLead, DeleteLead, RetrieveLeads } from "../../../services/leads";

import Viewer from "react-viewer";
import { Modal } from "@frontend/components/misc/modal";
import { toast } from "react-toastify";
import AuthContext from "../../../contexts/AuthContext";
import {
    BuildingType,
    Scope,
    ICreateLeadParams,
    ILead,
    InstallationType,
    int,
    IQuoteRequest,
    IUpdateQuoteRequestParams,
    ProductLine,
} from "@common.abstractions";
import { formatBuildingType, formatInstallationType, formatPhoneNumber, formatProductLine, getGoogleMapsUrl } from "@common.tools";
import { Loading } from "@frontend/components/misc/loading";
import { Equals } from "@common.api";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import Button from "@frontend/components/misc/button/Button";
import { Table } from "@frontend/components/misc/table";
import {MultiSelect} from "react-multi-select-component";
import { Page } from "@frontend/components/misc/layout/Page";
import { useNavigate } from "react-router-dom-v5-compat";

interface IActivityEventProps {
    when?: Date;
    what: string;
}

const ActivityEvent = (props: IActivityEventProps) => {
    return (
        <div className="flex my-2">
            <div className="flex-col">
                <div className="text-gray-600">{props.what}</div>
                <div className="text-gray-500 text-xs">{props.when ? new Date(props.when).toLocaleDateString() : null}</div>
            </div>
        </div>
    );
};

interface IActivityProps {
    events: IEvent[];
}

const Activity = (props: IActivityProps) => {
    return (
        <>
            {props.events.length === 0 && <h3 className="m-auto">No events</h3>}
            {props.events.length > 0 && (
                <div className="flex flex-col">
                    {props.events.map((event, i) => (
                        <ActivityEvent key={i} when={event.when} what={event.what} />
                    ))}
                </div>
            )}
        </>
    );
};

enum Tab {
    ACTIVITY = "Activity",
    IMAGES = "Images",
    QUOTE_REQUEST = "Quote Request",
}

interface IEvent {
    when?: Date;
    what: string;
}

export default function QuoteRequestDetail() {
    const authContext = useContext(AuthContext);
    const navigate= useNavigate();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [modalVisibility1, setModalVisibility1] = useState(false);
    const [modalVisibility2, setModalVisibility2] = useState(false);
    const [modalVisibility3, setModalVisibility3] = useState(false);
    const params = useParams<any>();
    const quoteRequestId = int.parse(params.quoteRequestId);
    const [quoteRequest, setQuoteRequest] = useState<IQuoteRequest>();
    const [leads, setLeads] = useState<ILead[]>();
    const [currentLead, setCurrentLead] = useState<ILead>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.QUOTE_REQUEST);
    const [imageContainer, setImageContainer] = useState<HTMLDivElement>();

    const [formBuildingType, setFormBuildingType] = useState<BuildingType>();
    const [formInstallationType, setFormInstallationType] = useState<InstallationType>();
    const [formProductLines, setFormProductLines] = useState<{ value: ProductLine; label: string }[]>();
    const [formQuotedAreas, setFormQuotedAreas] = useState<{ value: string; label: string }[]>();
    const [formAdditionalInfo, setFormAdditionalInfo] = useState<string>();
    const [images, setImages] = useState<{src: any}[]>();

    function onImageContainer(val: HTMLDivElement) {
        if (!imageContainer && val) setImageContainer(val);
    }

    const loadQuoteRequest = async () => {
        setLoading(true);
        const response = await RetrieveQuoteRequest(quoteRequestId);
        if (response) {
            setQuoteRequest(response);
            if (response.productLines) {
                setFormProductLines(
                    response.productLines.map((el) => {
                        switch (el) {
                            case ProductLine.CLASSIC:
                                return {
                                    value: ProductLine.CLASSIC,
                                    label: "Classic",
                                };
                            case ProductLine.COLOR_CHANGING:
                                return {
                                    value: ProductLine.COLOR_CHANGING,
                                    label: "Color Changing",
                                };
                        }
                    })
                );
            }
            if (response.quotedAreas) {
                setFormQuotedAreas(
                    response.quotedAreas.map((el) => {
                        switch (el) {
                            case "front":
                                return {
                                    value: "front",
                                    label: "Front Side",
                                };
                            case "back":
                                return {
                                    value: "back",
                                    label: "Back Side",
                                };
                            case "right":
                                return {
                                    value: "right",
                                    label: "Right Side",
                                };
                            case "left":
                                return {
                                    value: "left",
                                    label: "Left Side",
                                };
                            case "other":
                                return {
                                    value: "other",
                                    label: "Other",
                                };
                            default:
                                return {
                                    value: "",
                                    label: "",
                                };
                        }
                    })
                );
            }
            setFormAdditionalInfo(response.additionalInfo ?? undefined);
            setFormBuildingType(response.buildingType ?? undefined);
            setFormInstallationType(response.installationType ?? undefined);
        }
        setLoading(false);
    };

    const loadQuoteRequestLeads = async () => {
        const tempLeads = await RetrieveLeads({
            filters: {
                quoteRequest: {
                    id: Equals(quoteRequestId),
                },
            },
            relations: ['company']
        });

        if (!tempLeads) {
            return setLeads([]);
        }
        setLeads(tempLeads.values);
        setCurrentLead(getCurrentLead(tempLeads.values));
    };

    function getCurrentLead(leads: ILead[]) {
        if (!leads || leads.length === 0) return undefined;

        const tempCurrentLead = leads.reduce((a, b) => {
            return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
        });

        console.log(tempCurrentLead);
        if (tempCurrentLead.deletedAt) return undefined;

        console.log(tempCurrentLead);
        return tempCurrentLead;
    }

    const getEvents = () => {
        const hist: IEvent[] = [];
        if (!quoteRequest || !leads) return hist;

        hist.push({
            when: quoteRequest?.createdAt,
            what: "Quote request submitted",
        });

        leads.forEach((lead) => {
            const company = lead.company;
            const companyName = company ? company.name : "NA";

            hist.push({
                when: lead.createdAt,
                what: `Assigned to: ${companyName}`,
            });

            if (lead.viewedDate)
                hist.push({
                    when: lead.viewedDate,
                    what: `Opened by: ${companyName}`,
                });

            if (lead.orders?.length)
                hist.push({
                    when: undefined, // TODO: Make this actually work
                    what: `Order created by: ${companyName}`,
                });

            if (lead.customerContract)
                hist.push({
                    when: lead.customerContract.createdAt,
                    what: `Contract created for: ${companyName}`,
                });

            if (lead?.customerContract?.signedDate)
                hist.push({
                    when: lead.customerContract?.signedDate,
                    what: `Converted by: ${companyName}`,
                });

            if (lead.deletedAt)
                hist.push({
                    when: lead.deletedAt,
                    what: `Revoked for: ${companyName}`,
                });
        });

        return hist.reverse();
    };

    const loadImages = async () => {
        if (images || !quoteRequest?.images) return;

        const imgs = [];

        for (const image of quoteRequest.images) {
            const res = await RetrieveQuoteRequestImageBlob(image.id);
            if (res) imgs.push(res);
        }
        
        setImages(imgs.map(i => {return {src: URL.createObjectURL(i)}}));
    };

    const assignDealer = (dealerId: int) => {
        if (!quoteRequest) {
            toast.error("Something went wrong");
            return;
        }

        setModalVisibility3(false);

        const params: ICreateLeadParams = {
            companyId: dealerId,
            quoteRequestId: quoteRequest.id,
        };

        CreateLead(params).then((lead) => {
            loadQuoteRequest();
            setCurrentLead(lead);
            toast("Assigned to Dealer");
        });
    };

    const deleteQuoteRequest = async () => {
        if (await DeleteQuoteRequest(quoteRequestId)) navigate(-1);
    };

    const deleteLead = async () => {
        if (await DeleteLead(currentLead!.id)) {
            loadQuoteRequest();
            setCurrentLead(undefined);
            setModalVisibility2(false);
            toast("Deleted Successfully");
        }
    };

    const updateQuoteRequest = async () => {
        let productLines: ProductLine[] | undefined = undefined;
        if (formProductLines) {
            productLines = formProductLines.map((el) => el.value);
        }
        let quotedAreas: string[] | undefined = undefined;
        if (formQuotedAreas) {
            quotedAreas = formQuotedAreas.map((el) => el.value);
        }

        const updateParams: IUpdateQuoteRequestParams = {
            buildingType: formBuildingType,
            installationType: formInstallationType,
            productLines,
            quotedAreas,
            additionalInfo: formAdditionalInfo,
        };

        const response = await UpdateQuoteRequest(quoteRequestId, updateParams);
        if (!response) {
            toast.error("Unable to update quote request");
            return;
        }

        setQuoteRequest(response);
        if (response.productLines) {
            setFormProductLines(
                response.productLines.map((el) => {
                    switch (el) {
                        case ProductLine.CLASSIC:
                            return {
                                value: ProductLine.CLASSIC,
                                label: "Classic",
                            };
                        case ProductLine.COLOR_CHANGING:
                            return {
                                value: ProductLine.COLOR_CHANGING,
                                label: "Color Changing",
                            };
                    }
                })
            );
        }
        if (response.quotedAreas) {
            setFormQuotedAreas(
                response.quotedAreas.map((el) => {
                    switch (el) {
                        case "front":
                            return {
                                value: "front",
                                label: "Front Side",
                            };
                        case "back":
                            return {
                                value: "back",
                                label: "Back Side",
                            };
                        case "right":
                            return {
                                value: "right",
                                label: "Right Side",
                            };
                        case "left":
                            return {
                                value: "left",
                                label: "Left Side",
                            };
                        case "other":
                            return {
                                value: "other",
                                label: "Other",
                            };
                        default:
                            return {
                                value: "",
                                label: "",
                            };
                    }
                })
            );
        }

        setEditMode(false);
        toast("Saved");
    };

    const cancelQuoteRequestEdit = () => {
        if (!quoteRequest) return;
        setFormBuildingType(quoteRequest.buildingType ? quoteRequest.buildingType : undefined);
        setFormInstallationType(quoteRequest.installationType ? quoteRequest.installationType : undefined);
        setFormProductLines(
            quoteRequest.productLines
                ? quoteRequest.productLines.map((el) => {
                      switch (el) {
                          case ProductLine.CLASSIC:
                              return {
                                  value: ProductLine.CLASSIC,
                                  label: "Classic",
                              };
                          case ProductLine.COLOR_CHANGING:
                              return {
                                  value: ProductLine.COLOR_CHANGING,
                                  label: "Color Changing",
                              };
                      }
                  })
                : undefined
        );
        setFormQuotedAreas(
            quoteRequest.quotedAreas
                ? quoteRequest.quotedAreas.map((el) => {
                      switch (el) {
                          case "front":
                              return {
                                  value: "front",
                                  label: "Front Side",
                              };
                          case "back":
                              return {
                                  value: "back",
                                  label: "Back Side",
                              };
                          case "right":
                              return {
                                  value: "right",
                                  label: "Right Side",
                              };
                          case "left":
                              return {
                                  value: "left",
                                  label: "Left Side",
                              };
                          case "other":
                              return {
                                  value: "other",
                                  label: "Other",
                              };
                          default:
                              return {
                                  value: "",
                                  label: "",
                              };
                      }
                  })
                : undefined
        );

        setFormAdditionalInfo(quoteRequest.additionalInfo ?? "");
        setEditMode(false);
    };

    useEffect(() => {
        if (selectedTab === Tab.IMAGES) loadImages();
    }, [selectedTab]);

    useEffect(() => {
        loadQuoteRequest();
        loadQuoteRequestLeads();
    }, []);

    if (isLoading) return <Loading center />;

    if (!quoteRequest) return <div className="inset-center text-gray-500">Quote request not found!</div>;

    return (
        <Page>
            <Modal closeModal={() => setModalVisibility1(false)} visible={modalVisibility1}>
                <div className="padding">
                    <h2 className="title form_title">Are you sure?</h2>
                    <div className="modal_text">
                        You are about to permanently delete this quote request and all accompanying leads. Are you sure you want to proceed?
                    </div>
                    <div className="button_wrapper">
                        <button className="button delete" onClick={() => deleteQuoteRequest()}>
                            Delete Quote Request
                        </button>
                        <button className="button cancel" onClick={() => setModalVisibility1(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal closeModal={() => setModalVisibility2(false)} visible={modalVisibility2}>
                <div className="padding">
                    <h2 className="title form_title">Unassign Lead</h2>
                    <div className="modal_text">
                        Do you want to <strong>revoke</strong> this lead, keeping history and making it count against the dealer close rate, or{" "}
                        <strong>delete</strong> this lead and all history (won't count against dealer close rate)?
                    </div>
                    <div className="button_wrapper">
                        <button className="button delete" onClick={() => deleteLead()}>
                            Unassign
                        </button>
                        <button className="button cancel" onClick={() => setModalVisibility1(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal closeModal={() => setModalVisibility3(false)} visible={modalVisibility3}>
                <div className="padding">
                    <h2 className="title form_title">Select a Dealer</h2>
                    <SelectDealerPopup onDealerSelected={(dealerId) => assignDealer(dealerId)} onCancel={() => setModalVisibility3(false)} />
                </div>
            </Modal>

            <TopBar
                title="Quote Request"
                subtitle={
                    quoteRequest?.contact
                        ? ` from ${quoteRequest.contact.firstname} ${quoteRequest.contact.lastname}${
                              currentLead?.company?.name ? ", assigned to " + currentLead.company.name : ""
                          }`
                        : undefined
                }
            >
                {authContext?.hasAnyGrant(Scope.QuoteRequest.DELETE_ANY) && (
                    <Button className="mr-4" rounded type="button" style="outline" color="red" onClick={() => setModalVisibility1(true)}>
                        Delete Quote Request
                    </Button>
                )}
                {authContext?.hasAnyGrant(Scope.QuoteRequest.UPDATE_ANY) && (
                    <>
                        {currentLead && !currentLead.vConverted && (
                            <Button rounded type="button" style="outline" color="blue" onClick={() => setModalVisibility2(true)}>
                                Unassign from Dealer/Branch
                            </Button>
                        )}
                        {!currentLead && (
                            <Button rounded type="button" style="outline" color="blue" onClick={() => setModalVisibility3(true)}>
                                Assign to Dealer/Branch
                            </Button>
                        )}
                    </>
                )}
            </TopBar>

            <Table
                tabOptions={{
                    tabs: [{ name: Tab.QUOTE_REQUEST }, { name: Tab.ACTIVITY }, { name: Tab.IMAGES }],
                    active: selectedTab,
                    onClick: setSelectedTab,
                }}
            >
                <div ref={onImageContainer} className="relative min-h-full z-0 bg-white p-8">
                    {selectedTab === Tab.ACTIVITY && quoteRequest && leads && <Activity events={getEvents()} />}
                    {selectedTab === Tab.IMAGES && (
                        <>
                            {imageContainer && images ? (
                                <>
                                {
                                    images.length ? (
                                        <Viewer
                                            visible={true}
                                            images={ images }
                                            container={imageContainer}
                                            noNavbar={true}
                                            noClose={true}
                                            zoomSpeed={0.6}
                                            noImgDetails={true}
                                            scalable={false}
                                            minScale={1}
                                        />
                                    ) : (
                                        <div className="text-center w-full px-16 py-32 text-gray-500">
                                            No Images
                                        </div>
                                    )
                                }
                                </>
                            ) : (
                                <div className="text-center w-full px-16 py-32 text-gray-500">
                                    Loading...
                                </div>
                            )}
                        </>
                    )}
                    {selectedTab === Tab.QUOTE_REQUEST && quoteRequest.contact && quoteRequest ? (
                        <div className="flex w-full h-full max-h-full overflow-hidden">
                            <div className="flex flex-col h-full w-full m-0.5max-h-full overflow-auto">
                                {editMode
                                    ? authContext?.hasAnyGrant(Scope.QuoteRequest.UPDATE, Scope.QuoteRequest.UPDATE_ANY) && (
                                          <div className="mb-4 flex">
                                              <Button
                                                  className="w-40 mr-4"
                                                  style="outline"
                                                  type="button"
                                                  rounded
                                                  color="blue"
                                                  onClick={() => updateQuoteRequest()}
                                              >
                                                  Save Updates
                                              </Button>
                                              <Button
                                                  className="w-40"
                                                  style="outline"
                                                  type="button"
                                                  rounded
                                                  color="blue"
                                                  onClick={() => cancelQuoteRequestEdit()}
                                              >
                                                  Cancel
                                              </Button>
                                          </div>
                                      )
                                    : authContext?.hasAnyGrant(Scope.QuoteRequest.UPDATE, Scope.QuoteRequest.UPDATE_ANY) && (
                                          <Button className="w-40 mb-4" style="outline" type="button" rounded color="blue" onClick={() => setEditMode(true)}>
                                              Edit Quote Request
                                          </Button>
                                      )}
                                <div className="flex my-2">
                                    <div className="flex-col">
                                        <label className="text-xs text-gray-500">First Name</label>
                                        <div className="text-gray-600">{quoteRequest.contact.firstname}</div>
                                    </div>
                                    <div className="ml-8 flex-col">
                                        <label className="text-xs text-gray-500">Last Name</label>
                                        <div className="text-gray-600">{quoteRequest.contact.lastname}</div>
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="flex-col">
                                        <label className="text-xs text-gray-500">Phone</label>
                                        <div className="text-gray-600">{formatPhoneNumber(quoteRequest.contact.phone)}</div>
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="flex-col">
                                        <label className="text-xs text-gray-500">Email</label>
                                        <div className="text-gray-600">{quoteRequest.contact.email}</div>
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="flex-col">
                                        <label className="text-xs text-gray-500">Address</label>
                                        <div className="text-gray-600">
                                            <a
                                                className="text-blue-500 hover:text-blue-700 text-sm"
                                                target="_blank"
                                                href={getGoogleMapsUrl(quoteRequest.address)}
                                            >
                                                {quoteRequest.address.streetAddress}, {quoteRequest.address.city}, {quoteRequest.address.state}{" "}
                                                {quoteRequest.address.zip}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="flex-col">
                                        <label className="text-xs text-gray-500">Building Type</label>
                                        <div className="text-gray-600">
                                            {editMode ? (
                                                <select
                                                    defaultValue={formBuildingType}
                                                    className="input m-0.5"
                                                    onChange={(e) => setFormBuildingType(e.target.value as BuildingType)}
                                                >
                                                    <option value={undefined}>Select</option>
                                                    <option value={BuildingType.COMMERCIAL}>{BuildingType.COMMERCIAL}</option>
                                                    <option value={BuildingType.RESIDENTIAL}>{BuildingType.RESIDENTIAL}</option>
                                                </select>
                                            ) : quoteRequest.buildingType ? (
                                                formatBuildingType(quoteRequest.buildingType)
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="flex-col">
                                        <label className="text-xs text-gray-500">Installation Type</label>
                                        <div className="text-gray-600">
                                            {editMode ? (
                                                <select
                                                    defaultValue={formInstallationType}
                                                    className="input m-0.5"
                                                    onChange={(e) => setFormInstallationType(e.target.value as InstallationType)}
                                                >
                                                    <option value={undefined}>Select</option>
                                                    <option value={InstallationType.DIY}>{InstallationType.DIY}</option>
                                                    <option value={InstallationType.PROFESSIONAL}>{InstallationType.PROFESSIONAL}</option>
                                                </select>
                                            ) : quoteRequest.installationType ? (
                                                formatInstallationType(quoteRequest.installationType)
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="flex-col">
                                        <label className="text-xs text-gray-500">Product Lines</label>
                                        <div className="text-gray-600">
                                            {editMode ? (
                                                <div className="flex w-72">
                                                    <div className="flex items-center w-full">
                                                        <MultiSelect
                                                            options={[
                                                                {
                                                                    label: "Classic",
                                                                    value: ProductLine.CLASSIC,
                                                                },
                                                                {
                                                                    label: "Color Changing",
                                                                    value: ProductLine.COLOR_CHANGING,
                                                                },
                                                            ]}
                                                            value={formProductLines ? formProductLines : []}
                                                            onChange={setFormProductLines}
                                                            hasSelectAll={false}
                                                            labelledBy={"Select"}
                                                            disableSearch={true}
                                                            className="w-full"
                                                        />
                                                    </div>
                                                </div>
                                            ) : quoteRequest.productLines ? (
                                                quoteRequest.productLines.map((el) => formatProductLine(el)).join(", ")
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="flex-col">
                                        <label className="text-xs text-gray-500">Quoted Areas</label>
                                        <div className="text-gray-600 capitalize">
                                            {editMode ? (
                                                <div className="flex w-72">
                                                    <div className="flex items-center w-full">
                                                        <MultiSelect
                                                            options={[
                                                                {
                                                                    label: "Front Side",
                                                                    value: "front",
                                                                },
                                                                {
                                                                    label: "Back Side",
                                                                    value: "back",
                                                                },
                                                                {
                                                                    label: "Right Side",
                                                                    value: "right",
                                                                },
                                                                {
                                                                    label: "Left Side",
                                                                    value: "left",
                                                                },
                                                                {
                                                                    label: "Other",
                                                                    value: "other",
                                                                },
                                                            ]}
                                                            value={formQuotedAreas ? formQuotedAreas : []}
                                                            onChange={setFormQuotedAreas}
                                                            hasSelectAll={false}
                                                            labelledBy={"Select"}
                                                            disableSearch={true}
                                                            className="w-full"
                                                        />
                                                    </div>
                                                </div>
                                            ) : quoteRequest.quotedAreas ? (
                                                quoteRequest.quotedAreas.map((el) => el).join(", ")
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex my-2">
                                    <div className="flex-col">
                                        <label className="text-xs text-gray-500">Additional Info</label>
                                        <div className="text-gray-600">
                                            {editMode ? (
                                                <textarea
                                                    className="input m-0.5"
                                                    placeholder="optional"
                                                    value={formAdditionalInfo}
                                                    onChange={(e) => setFormAdditionalInfo(e.target.value)}
                                                />
                                            ) : quoteRequest.additionalInfo ? (
                                                quoteRequest.additionalInfo
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </Table>
        </Page>
    );
}
