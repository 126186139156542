import { ICompany } from "./company";
import { IContact } from "./contact";
import { IGlitch } from "./glitch";
import { ILead } from "./lead";
import { Address, BuildingType, InstallationType, int, ProductLine } from "./primitives";

export enum QuoteRequestOrigin {
    WEBSITE_FORM = "website_form",
    WEBSITE_FORM_SHORTENED = "website_form_shortened",
    WEBSITE_FORM_COMMERCIAL = "website_form_commercial",
    DEALER_MANUAL_ENTRY = "dealer_manual_entry",
    PHONE_CALL_MANUAL_ENTRY = "phone_call_manual_entry",
}

export interface IAutoAssignment {
    id: int;
    quoteRequest?: IQuoteRequest;
    exclusiveCandidates?: ICompany[] | null;
    captureCandidates?: ICompany[] | null;
    lead?: ILead | null;
    glitch?: IGlitch | null;
    completedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
}

export interface IS3UploadRequestParams {
    filename: string;
    s3id?: string;
}
export interface ICreateQuoteRequestImageParams extends IS3UploadRequestParams {}

export interface IS3UploadResponse {
    id: int;
    s3id: string;
    filename: string;
    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
    uploadUrl?: string;
}

export interface IQuoteRequestImage extends IS3UploadResponse {
    quoteRequest?: IQuoteRequest;
}

export interface IUpdateQuoteRequestParams {
    productLines?: ProductLine[] | null;
    buildingType?: BuildingType | null;
    installationType?: InstallationType | null;
    quotedAreas?: string[] | null;
    additionalInfo?: string | null;
}

export interface ICreateQuoteRequestParams {
    address: Address;
    productLines?: ProductLine[] | null;
    buildingType?: BuildingType | null;
    installationType?: InstallationType | null;
    quotedAreas?: string[] | null;
    additionalInfo?: string | null;
    origin: QuoteRequestOrigin;
}

export interface IQuoteRequest {
    id: int;
    autoAssignment?: IAutoAssignment[];
    contact?: IContact;
    address: Address;
    productLines?: ProductLine[] | null;
    buildingType?: BuildingType | null;
    installationType?: InstallationType | null;
    quotedAreas?: string[] | null;
    additionalInfo?: string | null;
    images?: IQuoteRequestImage[];
    leads?: ILead[];
    vConverted?: boolean;
    vStatus?: 'assigned' | 'retracted' | 'conflict' | 'invalid' | 'processing' | 'unserviceable'
    origin: QuoteRequestOrigin;
    expiredAt: Date | null;
    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
}
