import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { SmallPopup } from "../../misc/small-popup.tsx/SmallPopup";
import { RetrieveQuoteRequests } from "../../../services/quote-requests";
import { RetrieveContact, MarkInvalid, MarkValid, DeleteContact } from "../../../services/contacts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { IContact, int, IQuoteRequest } from "@common.abstractions";
import { capitalize, formatBuildingType, formatInstallationType, formatPhoneNumber, formatProductLine } from "@common.tools";
import { Loading } from "@frontend/components/misc/loading";
import { Equals } from "@common.api";
import { useNavigate } from "react-router-dom-v5-compat";

const QuoteRequestElement = (props: { quoteRequest: IQuoteRequest }) => {
    let quotedAreasList: string;
    if (props.quoteRequest.quotedAreas?.length) quotedAreasList = props.quoteRequest.quotedAreas.map((area) => capitalize(area)).join(", ");
    else quotedAreasList = "None";

    return (
        <>
            <div>{props.quoteRequest.buildingType ? formatBuildingType(props.quoteRequest.buildingType) + "Building" : ""}</div>
            <div>
                {props.quoteRequest.installationType ? formatInstallationType(props.quoteRequest.installationType) + "Installation" : ""}
            </div>
            <div>{props.quoteRequest.productLines ? props.quoteRequest.productLines.map((pl) => formatProductLine(pl)).join(", ") : ""}</div>
            <div>{quotedAreasList}</div>
            <div>{props.quoteRequest.additionalInfo}</div>
        </>
    );
};

interface IQuoteRequestsProps {
    contactId: int;
}

const QuoteRequests = (props: IQuoteRequestsProps) => {
    const { contactId } = props;
    const [quoteRequests, setQuoteRequests] = React.useState<Array<IQuoteRequest>>();

    const loadQuoteRequests = async () => {
        let response = await RetrieveQuoteRequests({
            filters: {
                contact: {
                    id: Equals(contactId),
                },
            },
        })
        if (!response) {
            setQuoteRequests([]);
            return;
        }
        setQuoteRequests(response.values);
    };

    useEffect(() => {
        loadQuoteRequests();
    }, [])

    return quoteRequests ? (
        quoteRequests.length ? (
            <>
                <div className="row header">
                    <div>
                        Building
                        <FontAwesomeIcon icon={faSort} />
                    </div>
                    <div>
                        Install
                        <FontAwesomeIcon icon={faSort} />
                    </div>
                    <div>
                        Products
                        <FontAwesomeIcon icon={faSort} />
                    </div>
                    <div>
                        Areas
                        <FontAwesomeIcon icon={faSort} />
                    </div>
                    <div>
                        Info
                        <FontAwesomeIcon icon={faSort} />
                    </div>
                </div>
                <div className="rows_wrapper">
                    {quoteRequests.map((quoteRequest) => (
                        <Link key={quoteRequest.id} className="row item" to={`/quoteRequests/${quoteRequest.id}`}>
                            <QuoteRequestElement quoteRequest={quoteRequest} />
                        </Link>
                    ))}
                </div>
            </>
        ) : (
            <div className="inset-center text-gray-500">No Quote Requests</div>
        )
    ) : (
        <Loading center />
    );
};

export default function Contact() {
    const navigate= useNavigate();
    const params = useParams<{ contactId: string }>();
    const [contactId] = useState<int>(int.parse(params.contactId));
    const [contact, setContact] = useState<IContact>();

    const [deletePopupShown, setDeletePopupShown] = useState(false);

    const loadContact = async () => {
        const tempContact = await RetrieveContact(contactId);
        console.log(tempContact);
        if (tempContact) {
            setContact(tempContact);
        }
    };

    const deleteContact = async () => {
        if (await DeleteContact(contactId)) {
            navigate(-1);
        }
    };

    useEffect(() => {
        loadContact();
    }, []);

    return (
        <div className="page">
            {deletePopupShown && (
                <SmallPopup title="Are you sure?">
                    <div>You are about to permanently delete this contact and all accompanying quote requests and leads. Are you sure you want to proceed?</div>
                    <div className="text-center">
                        <button className="mx-2 mt-3 btn btn-danger" onClick={() => deleteContact()}>
                            Delete Contact
                        </button>
                        <button className="mx-2 mt-3 btn btn-secondary" onClick={() => setDeletePopupShown(false)}>
                            Cancel
                        </button>
                    </div>
                </SmallPopup>
            )}
            <div className="flex flex-col max-h-full h-full">
                <div className="top_bar">
                    <div className="flex items-end">
                        <h1 className="text-2xl text-gray-500 uppercase">{contact ? `${contact.firstname} ${contact.lastname}` : "Contact"}</h1>
                        {contact && (
                            <div className="ml-2">
                                <a className="ml-2 text-blue-500 hover:text-blue-700" href={`tel:${formatPhoneNumber(contact.phone)}`}>
                                    {formatPhoneNumber(contact.phone)}
                                </a>
                                <a className="ml-2 text-blue-500 hover:text-blue-700 " href={`mailto:${contact.email}`}>
                                    {contact.email}
                                </a>
                            </div>
                        )}
                    </div>
                    <div className="right">
                        <button className="button delete" onClick={() => setDeletePopupShown(true)}>
                            Delete
                        </button>
                    </div>
                </div>

                {contact ? <QuoteRequests contactId={contactId} /> : <Loading center />}
            </div>
        </div>
    );
}
