export enum FormInputType {
    INPUT = "input",
    SELECT = "select",
    CHECKBOX = "checkbox",
    TEXTAREA = "textarea",
    TEXT = "text"
}

export type InputFormData = {
    formInputType: FormInputType.INPUT;
    label?: string;
    name: string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => any;
    required?: string;
    placeholder?: string;
    defaultValue?: string;
};

export type TextAreaFormData = {
    formInputType: FormInputType.TEXTAREA;
    label?: string;
    name: string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => any;
    required?: string;
    placeholder?: string;
    defaultValue?: string;
};

export type SelectFormData = {
    formInputType: FormInputType.SELECT;
    label?: string;
    name: string;
    isLoading?: boolean;
    onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => any;
    required?: string;
    placeholder?: string;
    defaultValue?: { value: any; label: string };
    options: { value: any; label: string }[];
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
};

export type CheckboxFormData = {
    formInputType: FormInputType.CHECKBOX;
    label?: string;
    name: string;
    required?: string;
    defaultValue?: boolean;
};

export type TextFormData = {
    formInputType: FormInputType.TEXT;
    name: string;
    defaultValue?: string;
    required?: string;
    label?: string;
}

export type FormData = InputFormData | SelectFormData | CheckboxFormData | TextAreaFormData | TextFormData;
