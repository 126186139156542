export function objectsToCSV<T extends object>(
    rows: Array<T>,
    columns: { [column: string]: (d: T) => string  }
): string {
    const columnNames = [];
    for (const col in columns) {
        columnNames.push(`"${col}"`);
    }
    const csvRows: string[] = [columnNames.join(",")];
    for (const row of rows) {
        const csvRowCells: string[] = [];
        for (const col in columns) {
            const converter = columns[col];
            if (!converter) continue;
            csvRowCells.push(`"${converter(row)}"`);
        }
        csvRows.push(csvRowCells.join(","));
    }

    return csvRows.join("\r\n");
}
