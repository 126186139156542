import "datejs";

import React, { useEffect, useState } from "react";

import AppRoutes from "./AppRoutes";
import { Route, Switch } from "react-router-dom";
import GoogleAuthRedirect from "../account/google-auth-redirect/GoogleAuthRedirect";
import AuthContextProvider from "@frontend/contexts/AuthContextProvider";

const App = () => {
    return (
        <AuthContextProvider>
            <AppRoutes />
                <Switch>
                    <Route path="/calendarAuth" component={GoogleAuthRedirect} />
                </Switch>
            <div id="custom-modal-root"></div>
        </AuthContextProvider>
    );
};

export default App;
