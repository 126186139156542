import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useContext } from "react";
import "./_dealerTab.scss";
import AuthContext from "@frontend/contexts/AuthContext";
import { Scope } from "@common.abstractions"

export default function DealerTab({ map, dealer, edit, create }) {
    const authContext = useContext(AuthContext);

    const [titleSubstrLength] = useState(34);
    if (!dealer.address.geoPoint) {
        return null;
    }

    const expandAccordion = (e, level) => {
        if (e.currentTarget.className.includes("open")) {
            e.currentTarget.className = `tab_title ${level}`;
        } else {
            if (e.currentTarget.className.includes("level_1")) {
                document.querySelectorAll(`.tab_title.${level}.open`).forEach((el) => {
                    el.className = `tab_title ${level}`;
                });
            }
            e.currentTarget.className = `tab_title ${level} open`;
        }
    };

    const goToCoordinates = (dealer, serviceArea, index) => {
        const zoom = 8;
        const headquarters = dealer.address.geoPoint.latlng;

        if (serviceArea === "headquarters") {
            map.setView(headquarters, zoom);
        } else if (serviceArea === "exclusiveServiceArea") {
            if (dealer.exclusiveServiceAreas[index].type === "polygon") {
                let center = dealer.exclusiveServiceAreas[index].area.vertices[0];
                map.setView(center, zoom);
            } else if (dealer.exclusiveServiceAreas[index].type === "circle") {
                let center = dealer.exclusiveServiceAreas[index].area.center;
                map.setView(center, zoom);
            }
        } else if (serviceArea === "captureServiceArea") {
            if (dealer.captureServiceAreas[index].type === "polygon") {
                let center = dealer.captureServiceAreas[index].area.vertices[0];
                map.setView(center, zoom);
            } else if (dealer.captureServiceAreas[index].type === "circle") {
                let center = dealer.captureServiceAreas[index].area.center;
                map.setView(center, zoom);
            }
        }
    };

    return (
        <div className="accordion_wrapper">
            <div
                className="tab_title level_1"
                onClick={(e) => {
                    expandAccordion(e, "level_1");
                    goToCoordinates(dealer, "headquarters");
                }}
            >
                <div className="text-gray-600 flex justify-between items-center">
                    <div className="pl-2 ">
                        {dealer.name.length > titleSubstrLength ? dealer.name.substring(0, titleSubstrLength) + " . . ." : dealer.name}
                    </div>
                    <div className="text-gray-400 text-sm px-2">
                        {dealer.address.city}, {dealer.address.state}
                    </div>
                </div>
            </div>

            {authContext.hasAnyGrant(Scope.Company.UPDATE_ANY) &&
                <div className="accordion_tab">
                    <div className="tab_title level_2 open" onClick={(e) => expandAccordion(e, "level_2")}>
                        <div className="flex justify_content_space_between stretch_width">
                            <span>Exclusive Service Area(s)</span>
                            <div className="tab_icon" onClick={() => edit(dealer, "exclusiveServiceArea")}>
                                <FontAwesomeIcon icon={faEdit} />
                            </div>
                        </div>
                    </div>
                    <div className={`accordion_tab`}>
                        {dealer.exclusiveServiceAreas &&
                            dealer.exclusiveServiceAreas.map((_, i) => {
                                return (
                                    <div key={"accordion exclusive" + i} className="tab_content" onClick={() => goToCoordinates(dealer, "exclusiveServiceArea", i)}>
                                        <span>Exclusive Area {i + 1}</span>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="tab_title level_2 open" onClick={(e) => expandAccordion(e, "level_2")}>
                        <div className="flex justify_content_space_between stretch_width">
                            <span>Capture Service Area(s)</span>
                            <div className="tab_icon" onClick={() => edit(dealer, "captureServiceArea")}>
                                <FontAwesomeIcon icon={faEdit} />
                            </div>
                        </div>
                    </div>
                    <div className={`accordion_tab`}>
                        {dealer.captureServiceAreas &&
                            dealer.captureServiceAreas.map((_, i) => {
                                return (
                                    <div key={"accordion capture" + i} className="tab_content" onClick={() => goToCoordinates(dealer, "captureServiceArea", i)}>
                                        <span>Capture Area {i + 1}</span>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            }
        </div>
    );
}
