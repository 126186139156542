export interface ApiResponse_old<Type> {
    responseCode: number;
    responseContent?: Type | string;
}

export interface ApiErrorResponse {
    success: false;
    statusCode?: number;
    body?: any;
    error?: any;
}

export interface ApiSuccessResponse<T> {
    success: true;
    // statusCode?: number; // Omit this unless I need it somewhere
    data: T;
}

export function getFormattedErrorString(errorResponse: ApiErrorResponse) {
    const info: Array<string> = [];
    if (errorResponse.statusCode) info.push(`Code: ${errorResponse.statusCode}`);
    if (errorResponse.body) info.push(`Details: ${errorResponse.body}`);
    if (errorResponse.error) info.push(`Error: ${errorResponse.error}`);
    return info.join('\n');
}

export type ApiResponse<T> = ApiSuccessResponse<T> | ApiErrorResponse;