import { IBookableUser } from "./bookable-user";
import { ICompany } from "./company";
import { IEmailPreferences } from "./email-preferences";
import { IPostInstall } from "./post-install";
import { int } from "./primitives";

export interface ICreateUserParams {
    accountId: int;
    email: string;
    firstname: string;
    lastname: string;
    phone?: string;
}

export interface IUser {
    accountId: int;
    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;

    company?: ICompany;
    email: string;
    firstname: string;
    lastname: string;
    phone: string | null;
    emailPreferences: IEmailPreferences;
    bookableUser?: IBookableUser;

    postInstall?: IPostInstall[];

    getNameString(): string;
}

//These should all be negative numbers to not conflict with auth users
export enum AuditUser {
    UNKNOWN = 0, //should only be used for records before tracking was implemented
    PORTAL = -1,
    PANDADOC = -2,
    STRIPE = -3,
    MACU = -4,
    SUNLIGHT = -5
}