import axios from "axios";
import { BACKEND_URL } from "../environment";
import { Query, serializeQuery } from "@common.api";
import { Scope, int } from "@common.abstractions";
import { IUser } from "@common.abstractions";
import { ApiResponse } from "./api-response";

export async function CreateUser(email: string, companyId: int): Promise<IUser | undefined> {
    try {
        const params = { email, companyId };
        const response = await axios.post(`${BACKEND_URL}/users`, params, { withCredentials: true });
        if (response.status === 201) return response.data as IUser;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveUser(userId?: int, token?: string): Promise<IUser | undefined> {
    try {
        if (token) {
            const response = await axios.get(`${BACKEND_URL}/users/${userId}`, { params: { token: token } });
            if (response.status === 200) return response.data as IUser;
            return undefined;
        }

        const response = await axios.get(`${BACKEND_URL}/users/${userId ?? "me"}`, { withCredentials: true });
        if (response.status === 200) return response.data as IUser;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveUserGrants(
    userId: int
): Promise<Array<{ id: number; moniker: string; description: string }> | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/users/${userId}/grants`);
        if (response.status === 200)
            return response.data as Array<{ id: number; moniker: string; description: string }>;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveUsers(query?: Query<IUser>): Promise<IUser[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/users`, {
            params: query,
            paramsSerializer: (q: any) => serializeQuery(q),
            withCredentials: true,
        });
        // if (response.status === 200) return (response.data as any[]).map((val) => val as IUser);
        if (response.status === 200) return response.data as IUser[];
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function UpdateUserGrants(
    userId: int,
    grants: Array<number>
): Promise<
    ApiResponse<{
        added: { success: Array<number>; fail: Array<number> };
        removed: { success: Array<number>; fail: Array<number> };
    }>
> {
    try {
        const response = await axios.put(`${BACKEND_URL}/users/${userId}/grants`, grants, { withCredentials: true });
        if (response.status === 200) return {success: true, data: response.data}
        return {success: false, statusCode: response.status, body: response.data};
    } catch (err: any) {
        console.log(err);
        return {success: false, error: err};
    }
}

export async function SetPassword(
    userId: int,
    token: string,
    params: { password: string }
): Promise<IUser | undefined> {
    try {
        const response = await axios.put(`${BACKEND_URL}/users/${userId}/password`, params, {
            params: { token: token },
            withCredentials: true,
        });
        if (response.status === 201) return response.data as IUser;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function ResendActivationLink(userId: int): Promise<boolean> {
    try {
        const response = await axios.post(
            `${BACKEND_URL}/users/${userId}/resendActivationEmail`,
            {},
            { withCredentials: true }
        );
        if (response.status === 201) return true;
        return false;
    } catch (err: any) {
        console.log(err);
        return false;
    }
}

export async function DeleteUser(userId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/users/${userId}`, { withCredentials: true });
        return response.status === 204;
    } catch (err: any) {
        console.log(err);
        return false;
    }
}
