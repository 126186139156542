import React, { useContext, useEffect, useState } from 'react'
import { Scope, ICreateWarrantyItemParams, int, IProduct, IProductVariant, IUpdateWarrantyItem, IWarranty, VariantAttribute } from '@common.abstractions'
import TopBar from '../misc/top-bar/TopBar'
import { CreateWarrantyItem, DeleteWarrantyItem, RetrieveWarrantyById, UpdateWarranty, UpdateWarrantyItem } from '@frontend/services/warranty';
import { useParams } from 'react-router-dom';
import Button from '../misc/button/Button';
import { Page } from '../misc/layout/Page';
import AuthContext from '@frontend/contexts/AuthContext';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { RetrieveProducts } from '@frontend/services/products';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_URL } from '@frontend/environment';
import Viewer from 'react-viewer';

export default function Warranty() {
    const [products, setProducts] = useState<IProduct[]>();
    const [itemName, setItemName] = useState<string>();
    const [quantity, setQuantity] = useState<int>();
    const [warranty, setWarranty] = useState<IWarranty>();
    const params = useParams<{ warrantyId: string }>();
    const authContext = useContext(AuthContext);
    const [warrantyId] = useState<int>(int.parse(params.warrantyId));
    const [editMode, setEditMode] = useState<boolean>();
    const [imageContainer, setImageContainer] = useState<HTMLDivElement>();

    const [testdropDowns, setTestDropDowns] = useState<{ name: string; values: string[] }[]>();
    const [hasVariants, setHasVariants] = useState<boolean>();
    const [selected, setSelected] = useState<IProduct>();
    const [selectedCombo, setSelectedCombo] = useState<VariantAttribute[]>([]);
    const [selectedVariant, setSelectedVariant] = useState<IProductVariant>();
    const [variantExists, setVariantExists] = useState<boolean>(true);

    const loadProducts = async () => {
        const retrievedProducts = await RetrieveProducts({
            relations: ["variants"],
        });

        const listedProducts = retrievedProducts?.filter((el) => el.listed);
        listedProducts?.sort((a, b) => {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
            return 0;
        });

        setProducts(listedProducts);
    };

    const handleComboChange = (value: string, i: number) => {
        let tempSelectedCombo = [...selectedCombo];
        tempSelectedCombo[i].value = value;

        setSelectedCombo(tempSelectedCombo);
        let variantExists: boolean = false;

        // Checks if the tempSelected Combo exists as a variant
        (selected!.variants as IProductVariant[]).find((el) => {
            if (el.attributes.length === tempSelectedCombo.length) {
                if (!el.attributes.some((val, index) => val.value !== tempSelectedCombo[index].value)) {
                    setSelectedVariant(el);
                    variantExists = true;
                }
            }
        });
        setVariantExists(variantExists);
    };

    const handleChange = (val: int) => {
        const productId = val;
        const product = products?.find((el) => el.id === productId);

        if (product) {
            setSelected(product);
            setItemName(product?.title);
            checkHasVariants(product);
            testCreateVariantDropdown(product);
        }
    };

    const checkHasVariants = (product: IProduct) => {
        if (product && product.variants) {
            setSelectedVariant(product.variants[0] as IProductVariant);

            if (product.variants.length === 1) {
                if ((product.variants[0] as IProductVariant).attributes.length === 0) {
                    setHasVariants(false);
                } else {
                    testCreateVariantDropdown(product);
                    setHasVariants(true);
                }
            } else {
                testCreateVariantDropdown(product);
                setHasVariants(true);
            }
        }
    };

    const testCreateVariantDropdown = (product: IProduct) => {
        const tempDropdowns: {
            name: string;
            values: string[];
        }[] = [];

        (product.variants as IProductVariant[]).forEach((el, i) => {
            el.attributes.forEach((attr, index) => {
                if (!tempDropdowns[index]) {
                    //Add the name to the object at array[i] if that index doesn't exist yet
                    tempDropdowns.push({ name: attr.name, values: [] });
                }
                if (!tempDropdowns[index].values.some((val) => val === attr.value)) {
                    //push the value to the values array in the object at array[i] if doesn't exist already
                    tempDropdowns[index].values.push(attr.value);
                }
            });
        });
        setTestDropDowns(tempDropdowns);

        const tempSelectedCombo: { name: string; value: string }[] = [];

        tempDropdowns.forEach((el, i) => {
            if (!tempSelectedCombo[i]) {
                tempSelectedCombo.push({ name: el.name, value: el.values[0] });
            } else {
                tempSelectedCombo[i].name = el.name;
                tempSelectedCombo[i].value = el.values[0];
            }
        });

        setSelectedCombo(tempSelectedCombo);
    };

    const addItem = async () => {
        if (!itemName || !quantity) {
            toast.error("Please select Name and Quantity to add a product");
            return;
        }

        const itemParams: ICreateWarrantyItemParams = {
            quantity: quantity,
            item: {
                name: itemName,
                variant: selectedCombo
            }
        };

        const warrantyItem = await CreateWarrantyItem(warrantyId, itemParams)
        if (!warrantyItem) {
            toast.error('Error adding item');
        }
        loadWarranty();
    }

    const loadWarranty = async () => {
        const response = await RetrieveWarrantyById(warrantyId);
        if (!response) {
            console.log('Could not retrieve warranty')
        }
        setWarranty(response);
    }

    const getWarrantyImages = () => {
        const tempImages = warranty?.images?.filter((el) => !el.deletedAt);
        const images = tempImages?.map((image) => {
            return { src: `${BACKEND_URL}/warranty/images/${image.id}` };
        })
        return images ?? [];
    }

    function onImageContainer(val: HTMLDivElement) {
        if (!imageContainer && val) setImageContainer(val);
    }

    const approveWarranty = async () => {
        if (window.confirm("Are you sure you want to approve this warranty? You will not be able to decline or edit once the warranty is approved.") === true) {
            const approvedDate = new Date();
            const response = await UpdateWarranty(warrantyId, { approvedDate: approvedDate });
            if (!response) {
                toast.error('Something went wrong');
                return;
            }
            toast('Warranty Approved');
            window.location.href = `/leads/${warranty?.lead.id}`
        }
    }

    const rejectWarranty = async () => {
        if (window.confirm("Are you sure you want to decline this warranty? You will not be able to approve or edit once the warranty is declined.") === true) {
            const rejectedDate = new Date();
            const response = await UpdateWarranty(warrantyId, { rejectedDate: rejectedDate });
            if (!response) {
                toast.error('Something went wrong');
                return;
            }
            toast('Warranty rejected');
            window.location.href = `/leads/${warranty?.lead.id}`
        }
    }

    const deleteItem = async (itemId: int) => {
        await DeleteWarrantyItem(itemId);
        loadWarranty();
    }

    const handleUpdate = async (itemId: int, params: IUpdateWarrantyItem) => {
        const updateRes = await UpdateWarrantyItem(itemId, params)
        if (!updateRes) {
            toast.error('could not update item');
            return;
        }
    }

    const finishEdit = () => {
        setEditMode(false);
        loadWarranty();
    }

    const setWarrantyStatus = () => {
        if (warranty?.approvedDate) return <span className="text-green-600">Approved</span>
        else if (warranty?.rejectedDate) return <span className="text-red-600">Declined</span>
        return <span className="text-gray-600">Pending</span>
    }

    useEffect(() => {
        loadWarranty();
        loadProducts();
    }, [])

    return (
        <Page>
            <TopBar title='Warranty Review'>
                {authContext?.hasAnyGrant(Scope.Warranty.UPDATE_ANY) && (!warranty?.approvedDate) && (!warranty?.rejectedDate) &&
                    <div className='flex'>
                        <Button style='outline' className='mr-4' rounded color='green' onClick={approveWarranty}>Approve</Button>
                        <Button style='outline' className='mr-4' rounded color='red' onClick={rejectWarranty}>Decline</Button>
                    </div>
                }
                {warranty?.lead && (
                    <Button link={`/leads/${warranty.lead.id}`} className="mr-4" rounded style="outline" color="blue"> View Lead</Button>
                )}
            </TopBar>

            {editMode
                ? authContext?.hasAnyGrant(
                    Scope.Warranty.UPDATE_ANY,
                ) && (
                    <div className="mb-4 flex">
                        <Button
                            className="w-40"
                            style="outline"
                            type="button"
                            rounded
                            color="blue"
                            onClick={finishEdit}
                        >
                            Finish Edit
                        </Button>
                    </div>
                )
                : authContext?.hasAnyGrant(
                    Scope.Warranty.UPDATE_ANY,
                ) && (!warranty?.approvedDate) && (!warranty?.rejectedDate) && (
                    <Button
                        className="w-40 mb-4"
                        style="outline"
                        type="button"
                        rounded
                        color="blue"
                        onClick={() => {
                            setEditMode(true)
                        }}
                    >
                        Edit Warranty
                    </Button>
                )}

            <div className='flex flex-grow overflow-auto'>
                {warranty && (
                    <div className="flex flex-col w-96 relative mr-16">
                        <div className="p-3 text-gray-600">
                            <div className='mb-3'>
                                <h2 className="text-xl">Warranty Summary</h2>
                            </div>
                            <div className="flex justify-between mb-3">
                                <span>Submitted Date</span>
                                <span>{warranty.submittedDate ? new Date(warranty.submittedDate).toLocaleDateString() : "Pending"}</span>
                            </div>
                            <div className="flex justify-between mb-3">
                                <span>Approval Status </span>
                                {setWarrantyStatus()}
                            </div>
                            <div className="flex justify-between mb-3">
                                <span>Description</span>
                                <span className='text-sm'>{warranty.description}</span>
                            </div>
                        </div>

                        <div
                            ref={onImageContainer}
                            className="relative min-h-full z-0 p-8 bg-white"
                        >
                            <>
                                {imageContainer && warranty?.images?.length ? (
                                    <Viewer
                                        visible={true}
                                        images={getWarrantyImages()}
                                        container={imageContainer}
                                        noNavbar={true}
                                        noClose={true}
                                        zoomSpeed={0.6}
                                        noImgDetails={true}
                                        scalable={false}
                                        minScale={1}
                                        zIndex={5}
                                        className="w-full"
                                    />
                                ) : (
                                    warranty?.images?.length === 0 && (
                                        <div className="text-center w-full px-16 py-32 text-gray-500">
                                            No Images
                                        </div>
                                    )
                                )}
                            </>
                        </div>
                    </div>
                )}

                <div className="products_wrapper">
                    {warranty?.items.map((item) => {
                        if (editMode) {
                            return (
                                <div key={item.id} className="flex items-end relative mx-4 my-8">
                                    <div
                                        className="absolute top-0 right-1 text-gray-500 hover:text-gray-600 cursor-pointer"
                                        onClick={() => deleteItem(item.id)}
                                    >
                                        <FontAwesomeIcon icon={faTimes} />
                                    </div>
                                    <div className="product_info">
                                        <div className="text-gray-500 text-md leading-tight">
                                            {item.item.name}
                                        </div>
                                        <div className="product_variants_wrapper">
                                            {item.item.variant?.map((variant) => (
                                                <div key={variant.name + variant.value} className="product_variant leading-none">
                                                    <span className="text-gray-500 text-sm mr-1 leading-none">{`${variant.name}:`}</span>
                                                    <span className="text-gray-500 text-sm leading-none">{`${variant.value}`}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="qty_wrapper ml-4">
                                        <input
                                            className="w-16 text-gray-600 text-md border-gray-300 p-1 border"
                                            min="0"
                                            type="number"
                                            defaultValue={item.quantity}
                                            onChange={(e) => handleUpdate(item.id, { quantity: int(parseInt(e.target.value)) })}
                                        />
                                    </div>
                                </div>
                            );
                        }
                        return (
                            <div key={item.id} className="flex items-end relative mx-4 my-8">
                                <div className="product_info">
                                    <div className="text-gray-500 text-md leading-tight">
                                        {item.item.name}
                                    </div>
                                    <div className="product_variants_wrapper">
                                        {item.item.variant?.map((variant) => (
                                            <div key={variant.name + variant.value} className="product_variant leading-none">
                                                <span className="text-gray-500 text-sm mr-1 leading-none">{`${variant.name}:`}</span>
                                                <span className="text-gray-500 text-sm leading-none">{`${variant.value}`}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="text-gray-500 text-sm mr-1 leading-none">
                                    <span>x{item.quantity}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {editMode &&
                    <form>
                        <label>Product Name</label>
                        <Select
                            className="quotes_date_dropdown mb-3"
                            menuPosition="fixed"
                            options={products?.map((el) => ({
                                value: el.id,
                                label: el.title,
                            }))}
                            onChange={(val) => handleChange(int.parse(val?.value))}
                        />
                        {hasVariants ? (
                            <div className="flex mb-3">
                                {testdropDowns?.map((el, i) => {
                                    return (
                                        <div
                                            key={`${el.name} + ${el.values.join("")} `}
                                            className="flex flex-col mr-5"
                                        >
                                            <label>{el.name}</label>
                                            <select
                                                className="text-gray-600 text-md border-gray-300 p-1 border"
                                                onChange={(e) => handleComboChange(e.target.value, i)}
                                            >
                                                {el.values.map((value) => {
                                                    return (
                                                        <option key={value} value={value}>
                                                            {value}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}

                        <label>Quantity</label>
                        <input
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(int(parseInt(e.target.value)))}
                            min={1}
                            className="border-gray-300 p-1 border text-gray-600 text-md w-20 mb-3"
                        ></input>

                        <button
                            type="button"
                            className="bg-blue-500 rounded-3xl w-40 mb-3 text-center text-white"
                            onClick={addItem}
                        >
                            Add Product
                        </button>
                    </form>
                }
            </div>
        </Page>
    )
}