import { IDiscountPackage, int, IOrder, IProduct, IProductVariant, VariantAttribute } from "@common.abstractions";
import { calculateDiscount, formatMoney } from "@common.tools";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@frontend/components/misc/button/Button";
import { Page } from "@frontend/components/misc/layout";
import { Loading } from "@frontend/components/misc/loading";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import AuthContext from "@frontend/contexts/AuthContext";
import { ManualRecoverLostLead } from "@frontend/services/leads";
import { RetrieveOrder, SubmitOrder } from "@frontend/services/orders";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
// import "./_checkout.scss";

export default function Checkout() {
    const authContext = useContext(AuthContext);
    const params = useParams<{ orderId: string }>();
    const [orderId] = useState<int>(int.parse(params.orderId));
    const [order, setOrder] = useState<IOrder>();
    const navigate = useNavigate();
    const [discountPackage, setDiscountPackage] = useState<IDiscountPackage | undefined>();
    const [openLeadLostModal, setOpenLeadLostModal] = useState<boolean>(false);

    const initializeDiscountPackage = () => {
        if (authContext?.user?.company?.packages) {
            const currentDiscountPackage = authContext.user.company.packages.find((el) => new Date(el.startDate) < new Date() && new Date)
            setDiscountPackage(currentDiscountPackage);
        }
    };

    const loadOrder = async () => {
        const tempOrder = await RetrieveOrder(orderId);

        if (tempOrder) {
            setOrder(tempOrder);
            console.log(tempOrder);
            return;
        }
        console.error("Order didn't load");
    };

    const handleOrder = async () => {
        if (order?.lead.manualMarkLostDate) {
            if (window.confirm("Lead on this order is marked as lost. Would you like to recover the lead and place the order?")) {
                if (!(await ManualRecoverLostLead(order.lead.id))) {
                    toast.error("Something went wrong recovering the lead");
                    return;
                }
            } else {
                return;
            }
        }

        const orderRes = await SubmitOrder(orderId);

        if (orderRes) {
            toast("Order Submitted");

            setTimeout(() => {
                navigate(`/orders/${orderId}/`, { replace: true });
            }, 1000);
        }
    };

    useEffect(() => {
        loadOrder();
        initializeDiscountPackage();
    }, []);

    return (
        <Page>
            <TopBar
                title="Checkout"
                subtitle={
                    order?.lead.quoteRequest?.contact ? `for ${order?.lead.quoteRequest.contact.firstname} ${order?.lead.quoteRequest.contact.lastname}` : ""
                }
            >
                {order?.lead && (
                    <Button type="button" color="blue" style="outline" rounded link={`/leads/${order.lead.id}`}>
                        View Lead
                    </Button>
                )}
            </TopBar>
            <div className="flex flex-col md:flex-row flex-grow overflow-hidden">
                {order ? (
                    <div className="flex flex-col w-full md:w-96 relative mb-8 md:mb-0 md:mr-16">
                        <div className="p-3 text-gray-600">
                            <div>
                                <h2 className="text-xl">Order Summary</h2>
                            </div>
                            <br />
                            <div className="flex justify-between">
                                <span>Subtotal</span>
                                <span>{order.vSubtotal ? formatMoney(order.vSubtotal) : "NA"}</span>
                            </div>
                            <br />
                            <div className="flex justify-between">
                                <span>Shipping ({order.shipping!.name})</span>
                                <span>{formatMoney(order.shipping!.cost)}</span>
                            </div>
                            <br />

                            {order.paymentMethod && (
                                <div className="flex justify-between">
                                    <span>Convenience Fee ({order.paymentMethod!.name})</span>
                                    <span>{formatMoney(order.paymentMethod!.cost)}</span>
                                </div>
                            )}

                            <br />
                            <div className="flex justify-between">
                                <span>Dealer Discount</span>
                                <span>- {order.vDiscountAmount ? formatMoney(order.vDiscountAmount) : "NA"}</span>
                            </div>
                            <br />
                            <hr />
                            <br />
                            <div className="flex justify-between">
                                <span>Order Total</span>
                                <span>{order.vTotal ? formatMoney(order.vTotal) : "NA"}</span>
                            </div>
                        </div>
                        <Button style='outline' color='blue' className="mt-4 px-4 py-2" rounded onClick={handleOrder}>
                            Place Order
                        </Button>
                    </div>
                ) : (
                    <Loading center />
                )}
                <div className="flex flex-col flex-grow overflow-y-auto md:pl-10">
                    <h2 className="text-xl p-3">Order Items</h2>
                    {order?.items &&
                        order.items.map((item) => {
                            if (!item.productVariant || !item.productVariant.product) return;

                            return (
                                <div key={item.id} className="flex flex-col md:flex-row items-start md:items-end relative mx-4 my-4 bg-gray-100 p-4 rounded-lg">
                                    <div className="flex flex-col flex-grow">
                                        <div className="text-gray-500 text-md leading-tight">
                                            {item.productVariant.product.title}
                                        </div>
                                        {discountPackage ? (
                                            <>
                                                <div className="text-gray-400 line-through">
                                                    {formatMoney(
                                                        item.productVariant.retailPrice
                                                            ? item.productVariant.retailPrice
                                                            : item.productVariant.product.retailPrice
                                                    )}
                                                </div>
                                                <div className="flex items-end">
                                                    <div className="">
                                                        {formatMoney(
                                                            calculateDiscount(
                                                                item.productVariant.retailPrice
                                                                    ? item.productVariant.retailPrice
                                                                    : item.productVariant.product.retailPrice,
                                                                discountPackage.discountPercent
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="ml-2"> x {item.quantity}</div>
                                                    <div className="ml-2 text-xl text-theme_blue">
                                                        {formatMoney(
                                                            calculateDiscount(
                                                                item.productVariant.retailPrice
                                                                    ? item.productVariant.retailPrice
                                                                    : item.productVariant.product.retailPrice,
                                                                discountPackage.discountPercent
                                                            ) * item.quantity
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="flex items-end">
                                                <div className="">
                                                    {formatMoney(
                                                        item.productVariant.retailPrice
                                                            ? item.productVariant.retailPrice
                                                            : item.productVariant.product.retailPrice
                                                    )}
                                                </div>
                                                <div className="ml-2"> x {item.quantity}</div>
                                                <div className="ml-2 text-xl text-theme_blue">
                                                    {formatMoney(
                                                        (item.productVariant.retailPrice
                                                            ? item.productVariant.retailPrice
                                                            : item.productVariant.product.retailPrice) * item.quantity
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div className="mt-2">
                                            {item.productVariant.attributes.length
                                                ? item.productVariant.attributes.map((attr) => (
                                                    <div key={attr.name + attr.value} className="leading-none">
                                                        <span className="text-gray-500 text-sm mr-1 leading-none">{`${attr.name}:`}</span>
                                                        <span className="text-gray-500 text-sm leading-none">{`${attr.value}`}</span>
                                                    </div>
                                                ))
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </Page>
    );
}
