import { Address, float, int } from "../abstractions";

export function calcPercentage(numerator: number, denominator: number): number {
    if (numerator === 0 || denominator === 0) return 0;
    const res = Number(((numerator * 100) / denominator).toFixed(2));
    return res;
}

export function patchObject(dest: any, src: any, params?: { patchNullAndUndefined: boolean }) {
    const changes = getPatchList(dest, src, params);
    changes.forEach((key) => (dest[key] = src[key]));
}

export function getPatchList(dest: any, src: any, params?: { patchNullAndUndefined: boolean }): string[] {
    let differences: string[] = [];
    Object.keys(src)
        .filter((key) => key in dest)
        .forEach((key) => {
            if (params && !params.patchNullAndUndefined) {
                if (src[key] != dest[key] && src[key] !== undefined && src[key] !== null) differences.push(key);
            } else {
                if (src[key] != dest[key]) differences.push(key);
            }
        });
    return differences;
}

export function calculateDiscount(retailValue: float | int, discountPercent: float): float {
    const discountPrice = float.parse((retailValue - retailValue * (discountPercent / 100)).toFixed(2));
    return discountPrice;
}

export function copyKeys(dest: any, src: any, keys: string[]) {
    keys.forEach((key) => (dest[key] = src[key]));
}

export const nameof = <T>(name: keyof T): string => name as string;

export function getGoogleMapsUrl(address: Address) {
    return `https://maps.google.com/?q=${address.streetAddress}, ${address.city}, ${address.state}, ${address.zip}`;
}

export function addressEquals(add1: Address, add2: Address) {
    if (add1 === add2) return true;

    return (
        add1.streetAddress === add2.streetAddress &&
        add1.city === add2.city &&
        add1.state === add2.state &&
        add1.zip === add2.zip &&
        add1.country === add2.country
    );
}

export function isString(obj: any): obj is string {
    return typeof obj === "string";
}

export function isDate(obj: any): obj is Date {
    return typeof obj === "object" && "add" in obj;
}

export function first<T>(arr: T[], check: (el: T) => boolean) {
    for (var i = 0; i < arr.length; i++) {
        if (check(arr[i])) return arr[i];
    }
    return undefined;
}

export function last<T>(arr: T[]) {
    if (arr.length === 0) return undefined;
    return arr[arr.length - 1];
}

export function groupBy<T, K>(
    arr: T[],
    selector: (el: T) => K,
    comparer: undefined | ((a: K, b: K) => boolean) = undefined
) {
    const res: { key: K; values: T[] }[] = [];
    const comp = comparer ?? ((a: K, b: K) => a === b);

    for (var i = 0; i < arr.length; i++) {
        const key = selector(arr[i]);
        const group = first(res, (el) => comp(el.key, key));
        if (!group) res.push({ key, values: [arr[i]] });
        else group.values.push(arr[i]);
    }

    return res.map((v) => v.values);
}

export function groupByReturnKeys<T, K>(
    arr: T[],
    selector: (el: T) => K,
    comparer: undefined | ((a: K, b: K) => boolean) = undefined
) {
    const res: { key: K; values: T[] }[] = [];
    const comp = comparer ?? ((a: K, b: K) => a === b);

    for (var i = 0; i < arr.length; i++) {
        const key = selector(arr[i]);
        const group = first(res, (el) => comp(el.key, key));
        if (!group) res.push({ key, values: [arr[i]] });
        else group.values.push(arr[i]);
    }

    return res;
}

export function merge<T, R>(
    master: T[],
    other: T[],
    comparer: (a: T, b: T) => boolean,
    merger: (a: T, b?: T) => R
): R[] {
    const res = [] as R[];

    for (let cur of master) {
        const firstMatch = first(other, (el) => comparer(cur, el));
        if (firstMatch) {
            res.push(merger(cur, firstMatch));
        }
    }

    return res;
}

export function exists<T>(arr: T[], check: (el: T) => boolean) {
    for (var i = 0; i < arr.length; i++) {
        if (check(arr[i])) return true;
    }
    return false;
}

export function where<T>(arr: T[], check: (el: T) => boolean) {
    let res: T[] = [];
    for (var i = 0; i < arr.length; i++) {
        if (check(arr[i])) res.push(arr[i]);
    }
    return res;
}

export function max<T>(arr: T[], AgtB: (a: T, b: T) => any) {
    if (arr.length === 0) return undefined;
    let max: T = arr[0];
    for (var i = 1; i < arr.length; i++) {
        if (AgtB(arr[i], max)) max = arr[i];
    }
    return max;
}

export const dateDiff = (first: Date, second: Date) => {
    return Math.round((new Date(second).getTime() - new Date(first).getTime()) / (1000 * 60 * 60 * 24));
};

export function getPercentageChange(oldNumber: int, newNumber: int) {
    var decreaseValue = oldNumber - newNumber;

    return Math.abs((decreaseValue / oldNumber) * 100);
}

export function chunk<T>(arr: Array<T>, chunkSize: number): Array<Array<T>> {
    if (chunkSize <= 0) throw "Invalid chunk size";
    var R = [];
    for (var i = 0, len = arr.length; i < len; i += chunkSize) R.push(arr.slice(i, i + chunkSize));
    return R;
}

export const getRandomHexColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const monthMapper = (monthName: string): number => {
    const months = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12,
    }
    return months[monthName as keyof typeof months];
}

//Start Sanitization
const isEmpty = (value: any) => value == null || value === "";

const removeEmpty = (obj: any) => Object.keys(obj).forEach((key) => isEmpty(obj[key]) && delete obj[key]);

export { isEmpty, removeEmpty };
//End Sanitization
