import { ICompany } from "./company"
import { int } from "./primitives"


export enum ACCOUNT_TYPE {
    NONE = "NONE",
    BUSINESS = "BUSINESS",
    PERSON = "PERSON"
};

export enum PAYMENT_STATUS {
    PAID = "PAID",
    VOID = "VOID",
    SCHEDULED = "SCHEDULED",
    CANCELED = "CANCELED",
    INITIATED = "INITIATED"
};

export enum INVOICE_STATUS {
    PAID_IN_FULL = "PAID_IN_FULL",
    OPEN = "OPEN",
    PARTIAL_PAYMENT = "PARTIAL_PAYMENT",
    SCHEDULED = "SCHEDULED"
};

export interface IBillContact {
    firstName?: string,
    lastName?: string
};


export interface IBillAddress {
    line1: string,
    line2?: string,
    city: string,
    stateOrProvince?: string,
    zipOrPostalCode: string,
    country: string
};


export interface IBillCustomer {
    id?: string,
    name: string,
    companyName?: string,
    contact?: IBillContact,
    email: string,
    phone: string,
    description?: string,
    invoiceCurrency?: string,//"USD"
    accountType?: ACCOUNT_TYPE,
    paymentTermId?: string, //
    accountNumber?: string,
    billingAddress?: IBillAddress,
    shippingAddress?: IBillAddress,
    balance?: { amount: number },
    createdTime?: Date,
    updatedTime?: Date
}

export interface IBillInvoiceLineItem {
    description?: string,
    price: number,
    quantity: int
}

export interface IBillPayment {
    id?: string,
    amount?: number,
    status?: PAYMENT_STATUS,
    date?: Date
}
export interface IBillInvoice {
    id?: string,
    archived?: boolean,
    invoiceNumber?: string,
    invoiceDate?: Date,
    dueDate?: Date,
    customerId?: string,
    totalAmount?: number,
    status: INVOICE_STATUS,
    createdTime?: Date,
    updatedTime?: Date,
    invoiceLineItems?: [IBillInvoiceLineItem],
    payments?: [IBillPayment]
    processingOptions: { sendEmail: boolean },
}

export interface IBillService {

}

export interface IBillSendInvoiceParams {
    replyTo: {
        userId: string //BILL-generated ID of the user sending the invoice
    },
    recipient: {
        to: string[], //email address of the recipient
    },
}

export interface IBillInvoice {
    readonly bill_service: IBillService;
    createCustomerFromCompany(company: ICompany): Promise<string | undefined>;
    // createBankAccount(params: ICreateBankAccountParams): Promise<string>;
    // verifyBankAccount(params: IVerifyBankAccountParams): Promise<boolean>;
    createNewInvoice(customerId: string, orderId: int, lineItems: IBillInvoiceLineItem[]): Promise<IBillInvoice | undefined>;
    // addItemToInvoice(
    //     invoiceId: string,
    //     customerId: string,
    //     description: string,
    //     price: number,
    //     quantity: number,
    //     discountable?: boolean
    // ): Promise<Stripe.Response<Stripe.InvoiceItem>>;
    // finalizeInvoice(invoiceId: string, audoAdvance?: boolean): Promise<Stripe.Response<Stripe.Invoice>>;
    // setPaymentMethod(invoiceId: string, paymentMethodCode: PaymentMethodOption["paymentMethodCode"], default_payment_method?: string): Promise<Stripe.Response<Stripe.Invoice>>;
    // markInvoiceAsPaid(invoiceId: string): Promise<Stripe.Response<Stripe.Invoice>>;
    // createCharge(params: ICreateChargeParams): Promise<Stripe.Response<Stripe.Charge>>;
    // getInvoice(invoiceId: string): Promise<Stripe.Response<Stripe.Invoice>>;

}