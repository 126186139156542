import { VERSION } from "@frontend/environment";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import LogoWithText from "../../assets/LogoWithText.png";
import Button from "../misc/button/Button";

interface ILoginPageProps {
    onSubmit: (emailAddress: string, password: string) => Promise<{ success: boolean; err?: string }>;
}

export default function LoginPage(props: ILoginPageProps) {
    const { handleSubmit, register } = useForm();

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-white">
            <div className="w-full max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl p-4">
                <img className="mx-auto mb-6" src={LogoWithText} alt="Logo" />

                <form
                    className="space-y-6 w-full min-w-0"
                    onSubmit={handleSubmit(async (vals) => {
                        const { success, err } = await props.onSubmit(vals.email, vals.password);
                        if (!success) {
                            toast.error(err);
                        }
                    })}
                >
                    <div>
                        <label className="block text-gray-700 text-sm mb-2">Email</label>
                        <input
                            className="w-full px-3 py-3 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            type="text"
                            placeholder="Email Address"
                            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
                        />
                    </div>

                    <div>
                        <label className="block text-gray-700 text-sm mb-2">Password</label>
                        <input
                            className="w-full px-3 py-3 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            type="password"
                            placeholder="Password"
                            {...register('password', { required: true, maxLength: 100 })}
                        />
                    </div>

                    <Button type="submit" color="blue" style="solid" className="w-full py-3 text-base">
                        Login
                    </Button>
                </form>
            </div>
            <div className="absolute bottom-4 right-4 text-gray-500 text-xs">Version: {VERSION}</div>
        </div>
    );
}
