import axios from "axios";
import { BACKEND_URL } from "../environment";
import { ICreateBankAccountParams, IVerifyBankAccountParams, Query, serializeQuery } from "@common.api";
import {
    ICreateDiscountPackageParams,
    ICreateCompanyParams,
    ICompany,
    IUpdateCompanyParams,
    int,
    BankAccount,
    ICreateShippingAddressParams,
    IShippingAddress,
} from "@common.abstractions";

export async function CreateCompany(companyParams: ICreateCompanyParams, discountPackageParams: ICreateDiscountPackageParams): Promise<ICompany | undefined> {
    try {
        const params = { ...companyParams, discountPackage: discountPackageParams };
        const response = await axios.post(`${BACKEND_URL}/companies`, params, { withCredentials: true });
        if (response.status === 201) return response.data;
        console.log(params);
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveCompany(companyId: int): Promise<ICompany | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/companies/${companyId}`, { withCredentials: true });
        if (response.status === 200) return response.data as ICompany;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveCompanies(query?: Query<ICompany>, includeLeadMetrics: boolean = false, includeDeleted: boolean = false): Promise<ICompany[] | undefined> {
    try {
        axios.defaults.headers['include-lead-metrics'] = includeLeadMetrics;
        axios.defaults.headers['include-deleted'] = includeDeleted;

        const response = await axios.get(`${BACKEND_URL}/companies`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        console.log(axios.defaults.headers);
        delete axios.defaults.headers.common['include-lead-metrics'];
        delete axios.defaults.headers.common['include-deleted'];

        if (response.status === 200) return response.data as ICompany[];
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveCompaniesForDashboard(query?: Query<ICompany>, timeFrame?: { startDate: Date, endDate: Date }): Promise<ICompany[] | undefined> {
    try {
        let squery: string = '';
        if (query) {
            squery = serializeQuery(query);
        }
        const params = { query: query, timeFrame: JSON.stringify(timeFrame) };
        //axios.defaults.headers['timeframe'] = timeFrame;
        const response = await axios.get(`${BACKEND_URL}/companies/dashboard`, { params: params, withCredentials: true });
        //console.log(axios.defaults.headers);
        //delete axios.defaults.headers.common['timeframe'];

        if (response.status === 200) return response.data as ICompany[];
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function UpdateCompany(companyId: int, update: IUpdateCompanyParams): Promise<ICompany | undefined> {
    try {
        console.log(update);
        const response = await axios.patch(`${BACKEND_URL}/companies/${companyId}`, update, { withCredentials: true });
        if (response.status === 200) return response.data as ICompany;
        console.log(response.status);
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteCompany(companyId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/companies/${companyId}`, { withCredentials: true });
        return response.status === 204;
    } catch (err: any) {
        console.log(err);
        return false;
    }
}

export async function RetrieveShippingAddresses(companyId: int): Promise<IShippingAddress[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/companies/${companyId}/shippingAddresses`, { withCredentials: true });
        if (response.status === 200) return response.data as IShippingAddress[];

        return undefined;

    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function CreateShippingAddress(companyId: int, params: ICreateShippingAddressParams): Promise<IShippingAddress | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/companies/${companyId}/shippingAddresses`, params, { withCredentials: true });
        if (response.status === 201) return response.data as IShippingAddress;
        return undefined
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteShippingAddress(companyId: int, shippingAddressId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/companies/${companyId}/shippingAddresses/${shippingAddressId}`, { withCredentials: true });
        return response.status === 204;
    } catch (err: any) {
        console.log(err);
        return false;
    }
}

export async function CreateCompanyBankAccount(companyId: int, bankAccount: ICreateBankAccountParams): Promise<BankAccount | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/companies/${companyId}/bankAccount`, bankAccount, { withCredentials: true });
        if (response.status === 201) return response.data;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function VerifyBankAccount(companyId: int, params: IVerifyBankAccountParams): Promise<BankAccount | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/companies/${companyId}/bankAccount/verify`, params, { withCredentials: true });
        if (response.status === 201) return response.data;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}
