import { IInstall, int, IQuoteRequest, IRepair, IUser } from ".";
import { IBookableUser } from "./bookable-user";
import { IThirdPartyRepair } from "./third-party-repair";
import { ISMSHistory } from "./sms-history";

export type IExternalBooking = {
    bookableUserId: int;
    start: Date;
    end: Date;
    title: string;
    notes: string | null;
};

export enum BookingType {
    IN_HOME_DESIGN_CONSULTATION = "In Home Design Consultation",
    PHONE_DESIGN_CONSULTATION = "Phone Design Consultation",
    INSTALL = "Install",
    REPAIR = "Repair",
    EXTERNAL = "External",
    UNAVAILABLE = "Unavailable",
    OTHER = "Other",
    THIRD_PARTY_REPAIR = 'Third Party Repair',
    DAILY_NOTE = "Daily Note",
    BUFFER_DAY = "Buffer Day",
    TIME_OFF = "Time Off",
    NON_FIELD_WORK = "Non Field Work",
    WARRANTY_REPAIR = "Warranty Repair",
}

export interface IUpdateBookingParams {
    bookingType?: BookingType;

    start?: Date;
    end?: Date;

    deletedAt?: Date | null

    title?: string;
    notes?: string | null;
    location?: string;

    confirmed?: Date | null;
    rescheduled?: Date | null;
    completed?: Date | null;
    noShow?: Date | null;
    notifyCustomer?: Date | null;

    timeZone?: {
        zoneName: string;
        abbreviation: string;
    }

    bookableUserId?: int
    quoteRequestId?: int | null;
    installId?: int | null;
    repairId?: int | null;
    schedulerId?: int;
}

export interface ICreateBookingParams {
    bookingType: BookingType;

    start: Date;
    end: Date;

    title: string;
    notes?: string | null;

    location?: string;

    confirmed?: Date | null;
    completed?: Date | null;
    noShow?: Date | null;
    notifyCustomer?: Date | null;

    timeZone: {
        zoneName: string;
        abbreviation: string;
    }

    bookableUserId: int
    quoteRequestId: int | null;
    installId: int | null;
    thirdPartyRepairId?: int | null;
    repairId: int | null;
    schedulerId: int;
}

export interface IBooking {
    id: int;
    uuid: string;

    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;

    bookingType: BookingType;

    location?: string | null;

    start: Date;
    end: Date;

    title: string;
    notes: string | null;

    notifyCustomer: Date | null;
    confirmed: Date | null;
    rescheduled: Date | null;
    completed: Date | null;
    noShow: Date | null;

    timeZone: {
        zoneName: string;
        abbreviation: string;
    }
    googleCalendarEventId?: string | null

    bookableUser?: IBookableUser
    quoteRequest?: IQuoteRequest | null;
    install?: IInstall | null;
    thirdPartyRepair?: IThirdPartyRepair | null;
    repair?: IRepair | null;
    smsHistory?: ISMSHistory[];
    scheduler: IUser
}

export interface ICalendarEventParams extends IBooking {
    calendarLocation?: string;
}