import React from 'react'

type Props = {
    search: React.Dispatch<React.SetStateAction<string>>;
    className?: string;
    placeholder: string;
}

export default function SearchBar(props: Props) {
    const {search, className, placeholder} = props

    return (
        <input className={`${className} input`} type="text" placeholder={placeholder} onChange={(e) => search(e.target.value)}/>
    )
}
