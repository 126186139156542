import React, { useEffect, useState } from 'react'
import { Modal } from '@frontend/components/misc/modal'
import { CompanyType, IBranchSalesGoal, ICompany, ICreateBranchSalesGoal, int, IUpdateBranchSalesGoal } from '@common.abstractions'
import { RetrieveCompany } from '@frontend/services/companies';
import { useParams } from 'react-router-dom';
import TopBar from '@frontend/components/misc/top-bar/TopBar';
import { Page } from '@frontend/components/misc/layout/Page';
import { toast } from 'react-toastify';
import { CreateBranchGoal, DeleteBranchGoal, RetrieveGoalById, UpdateGoal, RetrieveBranchGoals } from '@frontend/services/branch-goals';
import { formatWholeMoney, calcPercentage } from '@common.tools';
import { useForm } from 'react-hook-form';
import { Loading } from '@frontend/components/misc/loading';

export default function Goals() {
  const [company, setCompany] = useState<ICompany>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openPrevModal, setOpenPrevModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const params = useParams<{ companyId: string }>();
  const [companyId] = useState<int>(int.parse(params.companyId));
  const [goals, setGoals] = useState<IBranchSalesGoal[]>();
  const [goalToUpdate, setGoalToUpdate] = useState<IBranchSalesGoal>();
  const { handleSubmit, register } = useForm();
  const [isLoading, setLoading] = useState<boolean>(false);

  const getGoals = async () => {
    setLoading(true);
    const response = await RetrieveBranchGoals(companyId);
    if (!response) {
      console.log('Could not retrieve goals');
      return;
    }

    response.sort((a, b) => {
      const x = new Date(a.startDate).getTime();
      const y = new Date(b.startDate).getTime();

      return y - x
    });

    setGoals(response);
    setLoading(false);
  }

  const getCompany = async () => {
    const response = await RetrieveCompany(companyId);
    setCompany(response);
  }

  const closeGoalModal = () => {
    setOpenModal(false);
  }

  const openGoalModal = () => {
    setOpenModal(true);
  }

  const closePrevModal = () => {
    setOpenPrevModal(false);
  }

  const openPreviousModal = () => {
    setOpenPrevModal(true)
  }

  const openEditGoalModal = async (goalId: int) => {
    const retrievedGoal = await RetrieveGoalById(goalId);
    if (!retrievedGoal) {
      toast.error('Something went wrong');
      return;
    }
    setGoalToUpdate(retrievedGoal);
    setEditModal(true);
  }

  const closeEditModal = () => {
    setEditModal(false);
  }

  const createGoal = async (values: any) => {
    const withoutCommas = values.salesGoal.replaceAll(',', '');
    const goal = int(withoutCommas * 100);
    let created;

    const today = new Date();

    const createParams = (startDate: Date, endDate: Date, salesGoal: int) => {
      return {
        startDate,
        endDate,
        salesGoal
      }
    }

    if (!goals?.length) {
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDay = new Date(new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth() + 1, 0).setHours(23, 59, 59, 0));

      const params = createParams(firstDayOfMonth, lastDay, goal);
      created = await CreateBranchGoal(companyId, params);
    }

    else {
      const previousMonthGoal = goals[0];
      const previousMonthStart = new Date(previousMonthGoal.startDate);

      const firstDayOfNextMonth = new Date(new Date(previousMonthStart.getFullYear(), previousMonthStart.getMonth() + 1, 1));
      const lastDayOfMonth = new Date(firstDayOfNextMonth.getFullYear(), firstDayOfNextMonth.getMonth() + 1, 0).setHours(23, 59, 59, 0)

      const params = createParams(firstDayOfNextMonth, new Date(lastDayOfMonth), goal);
      created = await CreateBranchGoal(companyId, params);
    }

    if (!created) {
      toast.error('Something went wrong');
      return;
    }

    setOpenModal(false);
    toast.success('Goal Added');
    getGoals();
  }

  const createPrevGoal = async (values: any) => {
    if (goals?.length) {
      const withoutCommas = values.salesGoal.replaceAll(',', '');
      const goal = int(withoutCommas * 100);

      const firstMonthGoal = goals[goals.length - 1];
      const firstMonthGoalStart = new Date(firstMonthGoal.startDate);

      const prevMonth = new Date(firstMonthGoalStart.getFullYear(), firstMonthGoalStart.getMonth() - 1, 1);
      const firstDayOfNextMonth = new Date(prevMonth.getFullYear(), prevMonth.getMonth(), 1);
      const lastDayOfNextMotnh = new Date(new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 0).setHours(23, 59, 59, 0));

      const params: ICreateBranchSalesGoal = {
        salesGoal: goal,
        startDate: firstDayOfNextMonth,
        endDate: lastDayOfNextMotnh
      };

      const companyGoal = await CreateBranchGoal(companyId, params);
      if (!companyGoal) {
        toast.error('Something went wrong');
        return;
      }

      setOpenPrevModal(false);
      toast.success('Goal Added');
      getGoals();
    }
  }

  const deleteGoal = async (goalId: int) => {
    const confirmDeletion = window.confirm('Are you sure you want to delete this goal?');
    if (confirmDeletion) {
      const deletedGoal = await DeleteBranchGoal(goalId);
      if (!deletedGoal) {
        toast.error('Something went wrong');
        return;
      }
      toast.success('Goal Deleted');
      getGoals();
    }
  }

  const updateGoal = async (values: any) => {
    const removeCommas = values.salesGoal.replace(',', '');
    const convertedGoal = int(removeCommas * 100);

    const updateParams: IUpdateBranchSalesGoal = {
      salesGoal: convertedGoal
    };

    const tempGoal = goalToUpdate;
    if (tempGoal) {
      const response = await UpdateGoal(tempGoal.id, updateParams);

      if (!response) {
        toast.error('Something went wrong');
        return;
      }
    }

    toast.success('Goal Saved');
    setEditModal(false);
    getGoals();
  }

  const setClassColor = (goalProgress: int, goal: int) => {
    if (calcPercentage(goalProgress, goal) >= 100)
      return 'text-green-500'

    else if (calcPercentage(goalProgress, goal) >= 80)
      return 'text-theme_green'

    else if (calcPercentage(goalProgress, goal) >= 70) {
      return 'text-theme_gold'
    }
    else if (calcPercentage(goalProgress, goal) >= 60) {
      return 'text-theme_orange'
    }
    else if (calcPercentage(goalProgress, goal) <= 59) {
      return 'text-theme_red'
    }
  }

  useEffect(() => {
    getCompany();
    getGoals();
  }, []);

  if (isLoading) return <Loading center />;

  if (company?.companyType === CompanyType.DEALER) return <div className="inset-center text-gray-500">Cannot add target for a Dealer</div>;

  return (
    <Page>
      <TopBar title={company ? company.name + " Targets" : "Company not found"} />
      <div className='overflow-auto'>
        <div className='flex justify-center m-3'>
          {goals?.length ?
            <button
              type='button'
              className='border-solid rounded border-2 p-3 border-black hover:bg-theme_blue hover:text-white'
              onClick={openPreviousModal}
            >Prev Month
            </button>
            : undefined
          }
          <button
            type='button'
            className='border-solid rounded border-2 p-3 border-black hover:bg-theme_blue hover:text-white ml-3'
            onClick={openGoalModal}> {goals?.length ? 'Next Month' : `Add goal for ${new Date().toLocaleDateString('en-us', { month: "short" })}`}
          </button>
        </div>

        {goals?.map((goal) =>
          <div key={goal.id} id='goal-container' className='flex justify-center items-center mb-3'>
            <div id='goal' className='flex border-black border-solid border-2 rounded w-3/4 p-4 justify-evenly'>
              <h2>{new Date(goal.startDate).toLocaleDateString('en-us', { year: "numeric", month: "short" })}</h2>
              <span>Target: {formatWholeMoney(goal.salesGoal)}</span>
              <span>Achieved: {goal.progress ? formatWholeMoney(goal.progress) : '$0'} </span>
              <span className={goal.progress ? setClassColor(int(goal.progress), goal.salesGoal) : 'text-black-500'}>
                Percent: {goal.progress ? `${calcPercentage(goal.progress, goal.salesGoal)}%` : '0%'}
              </span>
              <button
                type='button'
                className='rounded p-3 border-black border-2 border-solid hover:bg-theme_gold hover:text-white'
                onClick={() => openEditGoalModal(goal.id)}
              >Edit
              </button>
              <button
                type='button'
                className='rounded p-3 border-black border-2 border-solid hover:bg-theme_red hover:text-white'
                onClick={() => deleteGoal(goal.id)}
              >Delete
              </button>
            </div>
          </div>
        )}

        {/* EDIT MODAL */}
        <Modal visible={editModal} closeModal={closeEditModal}>
          <form onSubmit={handleSubmit(updateGoal)}>
            <h1 className='text-xl mb-3 underline font-semibold'>Edit Target</h1>
            <div className='flex mb-3'>
              <label>Target Amount:</label>
              <input
                className='ml-3 border-gray-200 border-2'
                type='text'
                {...register('salesGoal', { required: 'Required' })} />
            </div>
            <div className='flex justify-center mb-3'>
              <button
                type='submit'
                className='border-solid border-black border-2 p-1 rounded hover:bg-theme_blue hover:text-white'>Submit
              </button>
            </div>
          </form>
        </Modal>

        {/* Create Modal */}
        <Modal visible={openModal} closeModal={closeGoalModal}>
          <form onSubmit={handleSubmit(createGoal)}>
            <h1 className='text-xl mb-3 underline font-semibold'>Create Target</h1>
            <div className='flex mb-3'>
              <label>Target Amount:</label>
              <input
                className='ml-3 border-gray-200 border-2'
                type='text'
                {...register('salesGoal', { required: 'Required' })} />
            </div>
            <div className='flex justify-center mb-3'>
              <button
                type='submit'
                className='border-solid border-black border-2 p-1 rounded hover:bg-theme_blue hover:text-white'>Submit
              </button>
            </div>
          </form>
        </Modal>

        {/* Prev Create Modal */}
        <Modal visible={openPrevModal} closeModal={closePrevModal}>
          <form onSubmit={handleSubmit(createPrevGoal)}>
            <h1 className='text-xl mb-3 underline font-semibold'>Create Prev Target</h1>
            <div className='flex mb-3'>
              <label>Target Amount:</label>
              <input
                className='ml-3 border-gray-200 border-2'
                type='text'
                {...register('salesGoal', { required: 'Required' })} />
            </div>
            <div className='flex justify-center mb-3'>
              <button
                type='submit'
                className='border-solid border-black border-2 p-1 rounded hover:bg-theme_blue hover:text-white'>Submit
              </button>
            </div>
          </form>
        </Modal>

      </div>
    </Page>
  );
}