import React from "react";

interface IProps {
    data: { title: string; onClick?: () => any }[];
    topBorder?: boolean;
}

export function TableHeader(props: IProps) {
    const { data, topBorder } = props;

    return (
        <thead className={`border-r border-l border-b border-gray-200 ${topBorder ? "border-t" : ""}`}>
            <tr className="text-gray-500 text-sm font-thin bg-white">
                {data.map((el, i) => (
                    <th
                        key={`table header ${i}`}
                        onClick={el.onClick ? el.onClick : undefined}
                        className={`bg-white top-0 pl-2 pr-2 sm:pl-4 sm:pr-4 border-collapse leading-none sticky py-2 sm:py-4 font-normal text-xs sm:text-sm ${el.onClick ? "hover:text-gray-600" : ""}`}
                    >
                        {el.title}
                        <div className="absolute top-full w-full left-0 h-px bg-gray-200"></div>
                    </th>
                ))}
            </tr>
        </thead>
    );
}