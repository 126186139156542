import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RetrieveContacts, RetrieveContactsCount } from "../../services/contacts";
import { BadgeStatus, TabBar } from "../misc/tab-bar/TabBar";
import { Exists, Not, Sorts } from "@common.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";

import "./_contacts.scss";
import { Loading } from "../misc/loading";
import { IContact } from "@common.abstractions";
import { formatPhoneNumber, handleSort } from "@common.tools";
import TopBar from "../misc/top-bar/TopBar";
import SearchBar from "../misc/search-bar/SearchBar";
import { Table, TableHeader, TableRow, TableRowWrapper } from "../misc/table";

enum ContactTab {
    ALL = "All",
    NOT_CONVERTED = "Not Converted",
    CONVERTED = "Converted",
}

export default function Contacts() {
    const [mounted, setMounted] = useState<boolean>(false);
    const [initialLoad, setInitialLoad] = useState<boolean>(false);

    const [countAll, setCountAll] = useState<number>(0);
    const [countConverted, setCountConverted] = useState<number>(0);
    const [countNotConverted, setCountNotConverted] = useState<number>(0);

    const [contacts, setContacts] = useState<IContact[]>();
    const [selectedTab, setSelectedTab] = useState<ContactTab>(ContactTab.ALL);
    const [searchCriteria, setSearchCriteria] = useState<string>("");

    let loadContacts = async () => {
        let response: IContact[] | undefined;

        switch (selectedTab) {
            case ContactTab.ALL:
                response = await RetrieveContacts();
                break;
            case ContactTab.CONVERTED:
                response = await RetrieveContacts();
                if (!response) break;
                response = response.filter(el => el.vConverted);
                break;
            case ContactTab.NOT_CONVERTED:
                response = await RetrieveContacts();
                if (!response) break;
                response = response.filter(el => !el.vConverted);
                break;
            default:
                response = await RetrieveContacts();
                break;
        }

        if (!response) {
            setContacts([]);
            return;
        }

        response.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        setContacts(response);
        setInitialLoad(true);
    };

    const loadCounts = async () => {
        let tempCountAll = await RetrieveContactsCount();
        if (tempCountAll === undefined) {
            return;
        }

        let tempCountConverted = 0;
        let tempCountNotConverted = 0;
        const contacts = await RetrieveContacts();
        contacts?.forEach(el => {
            if (el.vConverted) {
                tempCountConverted++
            } else {
                tempCountNotConverted++
            }
        })

        setCountAll(tempCountAll);
        setCountConverted(tempCountConverted);
        setCountNotConverted(tempCountNotConverted);
    };

    const searchFilter = (entity: IContact) => {
        const s = searchCriteria.toLowerCase();
        if (
            searchCriteria === "" ||
            entity.firstname.toLowerCase().includes(s) ||
            entity.lastname.toLowerCase().includes(s) ||
            (entity.firstname.toLowerCase() + " " + entity.lastname.toLowerCase()).includes(s) ||
            entity.phone.toLowerCase().includes(s)
        ) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (initialLoad) {
            loadContacts();
        }
    }, [selectedTab]);

    useEffect(() => {
        if (initialLoad) {
            if (mounted) {
                // loadCounts()
                //dont need this because there isn't a way to update contacts from current view
            } else {
                setMounted(true);
            }
        }
    }, [contacts])

    useEffect(() => {
        loadContacts();
        loadCounts();
    }, []);


    return (
        <div className="w-full h-full max-w-full">
            <div className="flex flex-col h-full w-full max-w-full overflow-hidden">
                <TopBar title="Contacts">
                        <SearchBar placeholder="Search Contacts" search={setSearchCriteria} className="mr-8 w-96" />
                </TopBar>

                <Table
                    tabOptions={{
                        tabs: [
                            { name: ContactTab.ALL, quantity: countAll },
                            { name: ContactTab.CONVERTED, quantity: countConverted },
                            { name: ContactTab.NOT_CONVERTED, quantity: countNotConverted },
                        ],
                        active: selectedTab,
                        onClick: setSelectedTab,
                    }}
                >
                    <TableHeader data={[{ title: "Created" }, { title: "Name" }, { title: "Phone" }, { title: "Email" }]} />
                    <TableRowWrapper>
                        {contacts?.length
                            ? contacts.map(
                                  (contact, i) =>
                                      searchFilter(contact) && (
                                          <TableRow
                                              key={`contact key ${i}`}
                                              data={[
                                                  {
                                                      text: { title: new Date(contact.createdAt).toLocaleDateString() },
                                                  },
                                                  {
                                                      text: { title: `${contact.firstname} ${contact.lastname}` },
                                                  },
                                                  {
                                                      text: { title: contact.phone },
                                                  },
                                                  {
                                                      text: { title: contact.email },
                                                  },
                                              ]}
                                          />
                                      )
                              )
                            : null}
                    </TableRowWrapper>
                </Table>

                {contacts ? contacts.length ? null : <div className="text-gray-500 inset-center top-72">No Contacts</div> : <Loading center />}

            </div>
        </div>
    );
}
