// export January Date.today().addDays(-30)

export class GetDate {
    private static readonly portalStartYear = 2020; //2020
    private static readonly portalStartMonth = 8; //September //index starts at 8
    private static readonly portalStartDay = 23; //23

    /**
     * Returns 2021 at 12am (0:00);
     * @static
     * @returns Date
     */
    static portalsBirth() {
        const month = new Date(GetDate.portalStartYear, GetDate.portalStartMonth, GetDate.portalStartDay, 0, 0, 0, 0);
        return month;
    }

    static everlightsBirth() {
        //this is a day that's guarenteed to be before any quote;
        const month = new Date(2010, GetDate.portalStartMonth, GetDate.portalStartDay, 0, 0, 0, 0);
        return month;
    }

    static isBeforePortalsBirth(date: Date) {
        return date < GetDate.portalsBirth();
    }

    /**
     * Returns daylights savings date of november 2am (0:00) (even though it should be 2 am of current timezone, we'll have to add logic for that when we use this);
     * @static
     * @returns Date
     */
    static getDaylightsSavingNovember() {
        const november = new Date();
        november.setMonth(10);
        november.setHours(2, 0, 0);
        const day = new Date(november.setDate(1));

        switch (day.getDay()) {
            case 0:
                //great!
                break;
            case 1:
                day.setDate(7);
                break;
            case 2:
                day.setDate(6);
                break;
            case 3:
                day.setDate(5);
                break;
            case 4:
                day.setDate(4);
                break;
            case 5:
                day.setDate(3);
                break;
            case 6:
                day.setDate(2);
                break;
        }

        return day;
    }

    /**
     * Returns daylights savings date of march 2am (0:00) (even though it should be 2 am of current timezone, we'll have to add logic for that when we use this);
     * @static
     * @returns Date
     */
    static getDaylightsSavingdMarch() {
        const march = new Date();
        march.setMonth(2);
        march.setHours(2, 0, 0);
        const day = new Date(march.setDate(7));

        switch (day.getDay()) {
            case 0:
                //great!
                break;
            case 1:
                day.setDate(14);
                break;
            case 2:
                day.setDate(13);
                break;
            case 3:
                day.setDate(12);
                break;
            case 4:
                day.setDate(11);
                break;
            case 5:
                day.setDate(10);
                break;
            case 6:
                day.setDate(9);
                break;
        }

        return day;
    }

    /**
     * Returns today's date at 12am (0:00);
     * @static
     * @returns Date
     */
    static today() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
    }

    /**
     * Returns today's date at 12am (0:00);
     * @static
     * @returns Date
     */
    static tonight() {
        const tonight = new Date();
        tonight.setHours(23, 59, 59, 0);
        return tonight;
    }

    /**
     * Returns yesterdays's date at 12am (0:00);
     * @static
     * @returns Date
     */
    static yesterday() {
        const date = new Date();
        date.setDate(date.getDate() - 1);
        date.setHours(0, 0, 0, 0);
        return date;
    }

    /**
     * Returns yesterdays's date at 12am (0:00);
     * @static
     * @returns Date
     */
    static twoDaysAgo() {
        const date = new Date();
        date.setDate(date.getDate() - 2);
        date.setHours(0, 0, 0, 0);
        return date;
    }

    /**
     * Returns date at 12am (0:00) of n days ago;
     * @static
     * @returns Date
     */
    static nDaysAgo(numDays: number) {
        const date = new Date();
        date.setDate(date.getDate() - numDays);
        date.setHours(0, 0, 0, 0);
        return date;
    }

    /**
     * Returns tomorrow's date at 12am (0:00);
     * @static
     * @returns Date
     */
    static tomorrow(daysOffset?: number) {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + (daysOffset ?? 1));
        tomorrow.setHours(0, 0, 0, 0);
        return tomorrow;
    }

    /**
     * Returns the 1st of January of next year at 12am (0:00);
     * @static
     * @returns Date
     */
    static nextJanuary(year?: number) {
        const today = new Date();
        const month = new Date(year ? year + 1 : today.getFullYear() + 1, 0, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns the 1st of current month at 12am (0:00);
     * @static
     * @returns Date
     */
    static currentMonth() {
        const today = new Date();
        const month = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns January 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static january(year?: number): Date {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 0, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns February 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static february(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 1, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns March 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static march(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 2, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns April 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static april(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 3, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns May 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static may(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 4, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns June 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static june(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 5, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns July 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static july(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 6, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns August 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static august(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 7, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns September 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static september(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 8, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns October 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static october(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 9, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns November 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static november(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 10, 1, 0, 0, 0, 0);
        return month;
    }

    /**
     * Returns December 1st of current year at 12am (0:00);
     * @static
     * @params {number} year
     * @returns Date
     */
    static december(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 11, 1, 0, 0, 0, 0);
        return month;
    }

    static endOfYear(year?: number) {
        const today = new Date();
        const fullYear = year ? year : today.getFullYear();
        const month = new Date(fullYear, 11, 31, 23, 59, 59, 999);
        return month;
    };

    /**
     * Returns upcoming day of week 12am (0:00);
     * @static
     * @params {number} day of week. sunday: 0, monday: 1, ..., friday: 5 ...
     * @returns Date
     */
    static upcomingDayOfWeek(dayOfWeek: number) {
        const day = new Date();
        day.setDate(day.getDate() + ((dayOfWeek + 7 - day.getDay()) % 7 || 7));
        day.setHours(0, 0, 0);
        return day;
    }

    /**
     * Returns array of objects, containing the start and end date of every year since the birth of portal (2021).
     * @static
     * @returns {start: Date, end: Date}[]
     */
    static years() {
        let tempYears: number[] = [];
        let years: { start: Date; end: Date }[] = [];

        const currentYear = new Date().getFullYear();
        const numYears = currentYear - GetDate.portalStartYear;

        for (let i = 0; i <= numYears; i++) {
            tempYears.push(GetDate.portalStartYear + i);
        }

        tempYears.sort((a: number, b: number) => b - a);
        tempYears.map((year) => {
            years.push({
                start: new Date(year, 0, 1, 0, 0, 0, 0),
                end: new Date(year + 1, 0, 1, 0, 0, 0, 0)
            });
        });

        return years;
    }
}
