import axios from "axios";
import { ILead, IQuoteRequestImage } from "@common.abstractions";
import { BACKEND_URL } from "../environment";
import { ICreateQuoteRequestImageParams } from "@common.abstractions";
import {ICreateManualLeadParams} from '@common.api';

export async function CreateQuoteRequestImage(
    filename: string
): Promise<
    | (IQuoteRequestImage & {
          uploadUrl: string;
      })
    | undefined
> {
    try {
        const params = { filename } as ICreateQuoteRequestImageParams;
        const response = await axios.post(`${BACKEND_URL}/quoteRequests/images`, params);
        if (response.status === 201)
            return response.data as IQuoteRequestImage & {
                uploadUrl: string;
            };

        console.log("Failed to save quote request with code: ", response.status, response.data);
        return undefined;
    } catch (err: any) {
        console.log("Creating quote request threw an exception: ", err);
        return undefined;
    }
}

export async function CreateManualLead(params: ICreateManualLeadParams): Promise<ILead | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/leads`, params, {withCredentials: true});
        if (response.status === 201) return response.data as ILead;

        console.log("Failed to submit quote request with code: ", response.status, response.data);
        return undefined;
    } catch (err: any) {
        console.log("Creating quote request threw an exception: ", err);
        return undefined;
    }
}
