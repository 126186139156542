import React, { useEffect, useRef, useState, useContext } from "react";
import Calendar from "@toast-ui/react-calendar";
import { TZDate } from "@toast-ui/calendar";
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Between, Equals, Exists, In } from "@common.api";
import { CreateBooking, DeleteBooking, RetrieveBookings, UpdateBooking } from "@frontend/services/bookings";
import { toast } from "react-toastify";
import { BookingType } from '@common.abstractions'
import { RetrieveBookableUser, RetrieveBookableUsers } from "@frontend/services/bookable-users";
import { BookingModal, DailyNoteModal } from "./components";
import { FormInputType } from "./models/formInput";
import { int } from '@common.abstractions';
import { RetrieveQuoteRequest, RetrieveQuoteRequestsByContactName } from "@frontend/services/quote-requests";
import AuthContext from "@frontend/contexts/AuthContext";
import { Scope, CompanyType } from "@common.abstractions";
import Select from "react-select";
import { RetrieveCompanies } from "@frontend/services/companies";
import { RetrieveGoogleCalendarEvents, CreateGoogleCalendarEvent, DeleteGoogleCalendarEvent, UpdateGoogleCalendarEvent } from "@frontend/services/google-calendar";
import { formatPhoneNumber, getDayOfWeekString } from '@common.tools';

export default function ToastCalendar() {
    const authContext = useContext(AuthContext);
    const cal = useRef(null);
    const [displayedDateRange, setDisplayedDateRange] = useState({ start: new TZDate(null), end: new TZDate(null) });
    const [bookings, setBookings] = useState();
    const [bookableUsers, setBookableUsers] = useState([]);
    //const [filteredUsers, setFilteredUsers] = useState([]);
    const [checkedUserIds, setCheckedUserIds] = useState([]);
    const [calendars, setCalendars] = useState();
    const [activeView, setActiveView] = useState('week');
    const [showSalesReps, setShowSalesReps] = useState(false);
    const [quoteRequests, setQuoteRequests] = useState([]);
    const [branches, setBranches] = useState();

    const [externalBookings, setExternalBookings] = useState();
    const [googleCalendarEvents, setGoogleCalendarEvents] = useState();

    const [combinedEvents, setCombinedEvents] = useState([]);

    const [timeZoneOffset, setTimeZoneOffset] = useState(0);
    //const [selectedBranchId, setSelectedBranchId] = useState();
    const [selectedBranch, setSelectedBranch] = useState();
    //const [changeTimeZoneOnce, setChangetimeZoneOnce] = useState(false);
    //const [companyId, setCompanyId] = useState(null);

    const [quoteRequestLoading, setQuoteRequestLoading] = useState(false);
    const [bookingType, setBookingType] = useState();
    const [bookingModalOpen, setBookingModalOpen] = useState(false);
    const [dailyNoteModalOpen, setDailyNoteModalOpen] = useState(false);
    const [modalSelectedBookableUser, setModalSelectedBookableUser] = useState();
    const [modalSelectedBooking, setModalSelectedBooking] = useState();
    const [modalSelectedStartDate, setModalSelectedStartDate] = useState();
    const [modalSelectedEndDate, setModalSelectedEndDate] = useState();
    const [modalSelectedBookingLocation, setModalSelectedBookingLocation] = useState();
    const [modalTitle, setModalTitle] = useState("Booking");
    const [bookingTitle, setBookingTitle] = useState('');

    const getCalendarInstance = () => cal.current?.getInstance();

    const loadSalesReps = async (branchId) => {
        let response = await RetrieveBookableUsers({
            relations: ['user', 'user.company'],

        })
        response = response.filter((el) => el.bookableTypes.some(type => [BookingType.IN_HOME_DESIGN_CONSULTATION, BookingType.PHONE_DESIGN_CONSULTATION].includes(type)));

        if (!response) {
            toast.error('Error loading sales reps');
            return;
        }
        if (branchId) {
            response = response.filter((el) => el.user.company.id === branchId);
        }
        setBookableUsers(response);
    }

    const loadBookings = async () => {
        if (!displayedDateRange || !displayedDateRange.start || !displayedDateRange.end
            || displayedDateRange.start <= new TZDate('1970-01-01') || displayedDateRange.end < + new TZDate('1970-01-01')) {
            return;
        }
        const bookingDateStart = new Date(new Date(displayedDateRange.start).setHours(0, 0, 0, 0));
        const bookingDateEnd = new Date(new Date(displayedDateRange.end).setHours(23, 59, 59, 59));

        let calendarUsers = bookableUsers

        let filter = {
            start: Between(bookingDateStart, bookingDateEnd),
            bookingType: In([BookingType.IN_HOME_DESIGN_CONSULTATION, BookingType.PHONE_DESIGN_CONSULTATION, BookingType.DAILY_NOTE]),
        }

        if (checkedUserIds?.length > 0) {
            filter.bookableUser = In(bookableUsers.filter(user => checkedUserIds.includes(user.id)).map(user => { return user.id }));
            calendarUsers = calendarUsers.filter(user => checkedUserIds.includes(user.id)).map(user => { return user });
        }
        else if (selectedBranch) {
            filter.bookableUser = { user: { company: { id: Equals(selectedBranch.id) } } };
            calendarUsers = calendarUsers.filter((b) => b.user.company.id === selectedBranch.id)

        }

        let response = await RetrieveBookings({
            filters: filter,
            relations: ["quoteRequest", "quoteRequest.contact", "bookableUser", "thirdPartyRepair", "smsHistory", "bookableUser.user", "bookableUser.user.company"],
        });


        if (!response) {
            toast.warning("No appointments found for selected user(s)");
            setBookings();
            return;
        }

        const saved_headers = response.filter((el) => el.bookingType === BookingType.DAILY_NOTE);

        const calendars = calendarUsers.map((bookable_user) => {
            let calendar = {};
            const primaryColor = bookable_user.calendarColor;
            calendar = {
                id: bookable_user.id.toString(),
                name: `${bookable_user.user.firstname} ${bookable_user.user.lastname}`,
                color: '#FFFFFF',
                backgroundColor: primaryColor,
                borderColor: primaryColor,
                dragBackgroundColor: primaryColor,
            };

            return calendar;
        })
        setCalendars(calendars);

        let count = calendarUsers.length;
        console.log(`distinct users: ${count}`);
        let w = (100 / count);
        let w2 = `${w.toFixed(1)}%`


        const headers = loadHeaders(calendarUsers, displayedDateRange, saved_headers);

        //load the user availability
        const availability = await loadAvailability(calendarUsers);



        if (activeView != 'month') {
            response.forEach((el) => {
                (el.calendarId = el.bookableUser.id?.toString()) // Add calendarId to booking interface?
                let offset = calendarUsers.findIndex(dist => dist.id == el.bookableUser.id);

                if (el.confirmed) {
                    el.customStyle = {
                        borderColor: '#00FF00',
                        borderWidth: '2px',
                        width: w2,
                        left: `${w * offset}%`,

                    }
                }
                else if (el.rescheduled) {
                    el.customStyle = {
                        borderColor: '#FF0000',
                        borderWidth: '2px',
                        width: w2,
                        left: `${w * offset}%`,
                    }
                }
                else {
                    el.customStyle = {
                        borderColor: '#FFFF00',
                        borderWidth: '2px',
                        width: w2,
                        left: `${w * offset}%`,
                    }
                }
            });
        }

        if (selectedBranch) {
            const timeZone = selectedBranch.timeZone;
            const localDate = new Date();
            const newTimeZoneDate = new Date(new Date(localDate).toLocaleString("en-US", { timeZone: timeZone.zoneName }));
            const newTimeZoneOffSet = localDate.getUTCHours() - newTimeZoneDate.getUTCHours();

            response = response.map((el) => {
                el.start = new Date(new Date(el.start).toLocaleString("en-US", { timeZone: timeZone.zoneName }));
                el.end = new Date(new Date(el.end).toLocaleString("en-US", { timeZone: timeZone.zoneName }));

                return el;
            });

            setTimeZoneOffset(newTimeZoneOffSet);
        }
        else {
            const newTimeZoneOffSet = 0;

            response = response.map((el) => {
                el.start = new Date(new Date(el.start).addHours(newTimeZoneOffSet));
                el.end = new Date(new Date(el.end).addHours(newTimeZoneOffSet));
                return el;
            });

            setTimeZoneOffset(newTimeZoneOffSet);
        }

        // TODO: Move this into its own function?
        if (bookableUsers) {
            const tempGoogleCalendarEvents = [];
            let userCollection = bookableUsers;

            if (checkedUserIds?.length > 0) {
                userCollection = bookableUsers.filter(user => checkedUserIds.includes(user.id));
            }

            await Promise.all(
                userCollection.map(async (el) => {
                    if (el.googleCalendarRefreshToken) {
                        const eventsResponse = await loadGoogleCalendarEvents(el.id, bookingDateStart, bookingDateEnd);
                        if (eventsResponse) {
                            tempGoogleCalendarEvents.push(eventsResponse);
                        }
                    }
                })
            );
            setGoogleCalendarEvents(tempGoogleCalendarEvents);
        }

        response = [...response, ...headers, ...availability];

        setBookings(response);
    };

    const loadAvailability = async (calendarUsers) => {
        if (activeView != 'month') {
            const availability = [];
            const blocks = calendarUsers.map((bookable_user, index) => {
                if (bookable_user.user.deletedAt) return; // Don't load availability for deleted users
                let count = calendarUsers.length;
                let offset = index; //bookableUsers.findIndex(dist => dist.id == bookable_user.id);
                let w = (100 / count);
                let w2 = `${w.toFixed(1)}%`
                const primaryColor = bookable_user.calendarColor;
                for (let day = new Date(displayedDateRange.start); day <= new Date(displayedDateRange.end); day.setDate(day.getDate() + 1)) {
                    let d = Date.UTC(day.getUTCFullYear(), day.getUTCMonth(), day.getUTCDate(), day.getUTCHours(), day.getUTCMinutes(), day.getUTCSeconds(), day.getUTCMilliseconds());

                    const availableTimes = bookable_user.availability[getDayOfWeekString(d).toLowerCase()].availableTimes;

                    d = new Date(d);
                    let start = d;
                    let end = d;
                    let blockTime;

                    if (availableTimes.length > 0) {
                        for (let i = 0; i < availableTimes.length; i++) {
                            let endHours = new Date(availableTimes[i].start).getHours();
                            let endMinutes = new Date(availableTimes[i].start).getMinutes();
                            end = new Date(d.getTime() + ((-timeZoneOffset + endHours) * 3600000) + (endMinutes * 60000));

                            if (i === 0) {
                                start = new Date(d.getTime() + ((-timeZoneOffset + 6) * 3600000));
                            }

                            blockTime = {
                                id: 'block'.concat(':', bookable_user.id.toString(), ':', start.getTime().toString()),
                                backgroundColor: '#949494',
                                bookableUser: bookable_user,
                                calendarId: bookable_user.id.toString(),
                                title: bookable_user.user.firstname,
                                location: "Unavailable",
                                start: start,
                                end: end,
                                isReadOnly: true
                            }
                            availability.push(blockTime);

                            let startHours = new Date(availableTimes[i].end).getHours();
                            let startMinutes = new Date(availableTimes[i].end).getMinutes();
                            start = new Date(d.getTime() + ((-timeZoneOffset + startHours) * 3600000) + (startMinutes * 60000));
                        }

                        end = new Date(d.getTime() + ((-timeZoneOffset + 19) * 3600000));

                        blockTime = {
                            id: 'block'.concat(':', bookable_user.id.toString(), ':', start.getTime().toString()),
                            backgroundColor: '#949494',
                            bookableUser: bookable_user,
                            calendarId: bookable_user.id.toString(),
                            title: bookable_user.user.firstname,
                            location: "Unavailable",
                            start: start,
                            end: end,
                            isReadOnly: true
                        }
                    }
                    else {
                        start = new Date(start.getTime() + ((-timeZoneOffset + 6) * 3600000));
                        end = new Date(end.getTime() + ((-timeZoneOffset + 19) * 3600000));
                        //no availability, block the whole day
                        blockTime = {
                            id: 'block'.concat(':', bookable_user.id.toString(), ':', start.getTime().toString()),
                            backgroundColor: '#949494',
                            bookableUser: bookable_user,
                            calendarId: bookable_user.id.toString(),
                            title: bookable_user.user.firstname,
                            location: "Unavailable",
                            start: start,
                            end: end,
                            isReadOnly: true
                        }
                    }
                    availability.push(blockTime);
                }
            });

            return availability;
        } else {
            return [];
        }
    };

    const loadHeaders = (calendarUsers, displayedDateRange, saved_headers) => {
        const headings = [];

        const headers = calendarUsers.map((bookable_user, index) => {
            if (bookable_user.user.deletedAt) return; // Don't load headers for deleted users
            let count = calendarUsers.length;
            let offset = index;
            let w = (100 / count);
            let w2 = `${w.toFixed(1)}%`
            const primaryColor = bookable_user.calendarColor;
            for (let day = new Date(displayedDateRange.start); day <= new Date(displayedDateRange.end); day.setDate(day.getDate() + 1)) {
                const d = Date.UTC(day.getUTCFullYear(), day.getUTCMonth(), day.getUTCDate(), day.getUTCHours(), day.getUTCMinutes(), day.getUTCSeconds(), day.getUTCMilliseconds())
                const tempDate =
                    day.toLocaleString("default", { year: "numeric" }) + "-"
                    + (day.toLocaleString("default", { month: "2-digit" })) + "-"
                    + day.toLocaleString("default", { day: "2-digit" }) + "T00:00:00.000Z";
    
                const existing_header = saved_headers.find((booking) => booking.bookableUser.id == bookable_user.id && new Date(booking.start).setHours(0, 0, 0, 0) === day.valueOf());
    
                let booking = undefined;
                if (!existing_header) {
                    booking = {
                        id: bookable_user.id.toString().concat(':', day.getTime().toString()),
                        bookableUser: bookable_user,
                        calendarId: bookable_user.id.toString(),
                        title: bookable_user.user.firstname,
                        category: 'allday',
                        start: d,
                        end: d,
                        isAllday: true,
                    }
                } else {
                    booking = {
                        id: existing_header.id,
                        bookableUser: bookable_user,
                        calendarId: bookable_user.id.toString(),
                        title: existing_header.title,
                        category: 'allday',
                        start: existing_header.start,
                        end: existing_header.end,
                        isAllday: true,
    
                    }
                }
                headings.push(booking);
            }
        });
        return headings;
    };

    const loadBranches = async () => {
        if (authContext?.hasAnyGrant(Scope.Company.READ_ANY)) {
            const response = await RetrieveCompanies({
                filters: {
                    companyType: Equals(CompanyType.BRANCH),
                },
            });
            if (!response) {
                toast.error("Error loading branches");
                return;
            }

            setBranches(response);
        } else {
            setBranches([authContext.user.company]);
        }
    };

    const moveToNextOrPrevRange = (offset) => {
        const calendarInstance = getCalendarInstance();
        if (calendarInstance) {
            if (offset === -1) {
                calendarInstance.prev();
            } else if (offset === 1) {
                calendarInstance.next();
            }
        }
        setDisplayedDateRange({ start: calendarInstance.getDateRangeStart(), end: calendarInstance.getDateRangeEnd() })
    };

    const startDate = new Date(displayedDateRange.start).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    const endDate = new Date(displayedDateRange.end).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit'
    });

    const handleClickNextButton = () => {
        moveToNextOrPrevRange(1);
    };

    const handleClickPrevButton = () => {
        moveToNextOrPrevRange(-1);
    };

    const handleTodayButtonClick = () => {
        const calendarInstance = getCalendarInstance();
        calendarInstance.today();
        setDisplayedDateRange({ start: calendarInstance.getDateRangeStart(), end: calendarInstance.getDateRangeEnd() })
    };

    const handleViewClick = (view) => {
        const calendarInstance = getCalendarInstance();
        calendarInstance.changeView(view);
        setActiveView(view);
        setDisplayedDateRange({ start: calendarInstance.getDateRangeStart(), end: calendarInstance.getDateRangeEnd() });
    };

    const handleOnClickEvent = () => {
        if (!selectedBranch) return alert('You are in view only mode. Please select a branch to update and delete bookings');

        const cal = getCalendarInstance();
        cal.on('clickEvent', async (e) => {
            const { id } = e.event;

            if (id.toString().includes('block')) {
                return;
            }

            if (!id) {
                toast.warning('Cannot edit an external event');
                return;
            }

            if (!e.event.isAllday) {
                const bookingToUpdate = bookings.find((booking) => booking.id === id);
                openBookingModalUpdate(bookingToUpdate);
            } else {
                const bookingToUpdate = bookings.find((booking) => booking.id === id);
                openDailyNoteModalUpdate(bookingToUpdate);
            }
        });
    };

    const handleOnSelectDateTime = () => {
        if (!selectedBranch) return alert('You are in view only mode. Please select a branch to create bookings');

        const cal = getCalendarInstance();
        cal.on('selectDateTime', (e) => {
            if (!e.isAllday) {
                setModalSelectedStartDate((e.start))
                setModalSelectedEndDate((e.end))
                openBookingModal();
            }
        });
        //openBookingModal();
    };

    const saveBooking = async (data) => {
        if (!bookings) {
            toast.error("Bookings haven't loaded yet");
            return;
        }

        if (data.user === 0) {
            toast.error("Must select a user for the appointment");
            return;
        }

        if (!data.quoteRequestId) {
            toast.error("Must select a quote request id");
            return;
        }

        if (new Date(data.startDate) > new Date(data.endDate)) {
            toast.error("End date cannot be before start date");
            return;
        }

        let tempTimeZone = undefined;
        if (selectedBranch?.timeZone) {
            tempTimeZone = selectedBranch.timeZone;
        } else if (authContext?.user?.company?.timeZone) {
            tempTimeZone = authContext?.user?.company?.timeZone;
        } else {
            toast.error("No branch is selected and user's company has no timezone");
            return;
        }

        const schedulerId = authContext.user.accountId;

        const createParams = {
            title: data.title,
            bookingType: data.bookingType.value,
            start: new Date(new Date(data.startDate).addHours(timeZoneOffset)),
            end: new Date(new Date(data.endDate).addHours(timeZoneOffset)),
            notes: data.notes ?? null,
            bookableUserId: data.user.value,
            installId: null,
            quoteRequestId: data.quoteRequestId?.value ?? null,
            confirmed: data.confirmed ? new Date() : null,
            timeZone: tempTimeZone,
            thirdPartyRepairId: null,
            location: modalSelectedBookingLocation,
            schedulerId: schedulerId,
        };

        // // Check for overlapping bookings
        // const hasOverlappingBookings = bookings.some((booking) => {
        //     return (
        //         booking.bookableUser.id === data.user.value &&
        //         doBookingsOverlap(booking, createParams)
        //     );
        // });
        // if (hasOverlappingBookings) {
        //     toast.warning("The new appointment overlaps with an existing appointment");
        //     return;
        // }

        const bookingResponse = await CreateBooking(createParams);
        if (!bookingResponse) {
            toast.error("Error saving appointment");
            return;
        }

        if (!bookingResponse.bookableUser?.id) {
            toast.error("Bookable user not return in booking response");
            return;
        }

        //We saved the start and end to the database with timeZoneOffset
        bookingResponse.start = new Date(bookingResponse.start).addHours(timeZoneOffset * -1);
        bookingResponse.end = new Date(bookingResponse.end).addHours(timeZoneOffset * -1);

        // Update the booking with the corresponding calendarId
        bookingResponse.calendarId = bookingResponse.bookableUser.id.toString();

        toast("Saved");
        closeBookingModal();
        loadBookings();
    };

    const updateBooking = async (data) => {
        if (!bookings || !modalSelectedBooking) {
            toast.error("Bookings haven't loaded yet");
            return;
        }

        if (data.user === 0) {
            toast.error("Must select a user for the appointment");
            return;
        }

        if (new Date(data.startDate) > new Date(data.endDate)) {
            toast.error("End date cannot be before start date");
            return;
        }

        if (!modalSelectedBooking.bookableUser?.id) {
            toast.error("Bookable User not loaded");
            return;
        }

        let tempTimeZone = undefined;
        if (selectedBranch?.timeZone) {
            tempTimeZone = selectedBranch.timeZone;
        } else if (authContext?.user?.company?.timeZone) {
            tempTimeZone = authContext?.user?.company?.timeZone;
        } else {
            toast.error("No branch is selected and user's company has no timezone");
            return;
        }

        const updateParams = {
            title: data.title,
            bookingType: data.bookingType.value,
            start: new Date(new Date(data.startDate).addHours(timeZoneOffset)),
            end: new Date(new Date(data.endDate).addHours(timeZoneOffset)),
            notes: data.notes && data.notes.trim().length ? data.notes : null,
            bookableUserId: data.user.value,
            quoteRequestId: data.quoteRequestId?.value ?? null,
            confirmed: data.confirmed ? new Date() : null,
            timeZone: tempTimeZone,
            notifyCustomer: data.notifyCustomer ? new Date() : null,
            location: modalSelectedBooking.location
        };

        if (modalSelectedBooking.bookableUser.id !== data.user.value) {
            //DELETE BOOKING FIRST
            const deleteResponse = await DeleteBooking(modalSelectedBooking.id);
            if (!deleteResponse) {
                toast.error("Error updating booking");
                return;
            }

            if (!modalSelectedBooking.bookableUser) {
                toast.error("Bookable user not loaded on booking");
                return;
            }

            if (!data.quoteRequestId) {
                toast.error("Must select a quote request id");
                return;
            }

            const googleCalendarDeleteResponse = await DeleteGoogleCalendarEvent(modalSelectedBooking.bookableUser.id, modalSelectedBooking.id);
            if (!googleCalendarDeleteResponse) {
                toast.error(`Google Calendar Integration Failed. Failed to delete from ${modalSelectedBooking.bookableUser.user?.firstname}'s Calendar`);
            }

            //CREATE NEW BOOKING
            const createParams = {
                title: data.title,
                bookingType: data.bookingType.value,
                start: new Date(new Date(data.startDate).addHours(timeZoneOffset)),
                end: new Date(new Date(data.endDate).addHours(timeZoneOffset)),
                notes: data.notes ?? null,
                bookableUserId: data.user.value,
                installId: data.installId?.value ?? null,
                repairId: data.repairId?.value ?? null,
                quoteRequestId: data.quoteRequestId?.value ?? null,
                confirmed: data.confirmed ? new Date() : null,
                timeZone: tempTimeZone,
                thirdPartyRepairId: modalSelectedBooking.thirdPartyRepair?.id ?? null,
                notifyCustomer: data.notifyCustomer ? new Date() : null,
                location: modalSelectedBooking.location ?? null
            };

            const bookingResponse = await CreateBooking(createParams);
            if (!bookingResponse) {
                toast.error("Error saving appointment");
                return;
            }

            if (!bookingResponse.bookableUser?.id) {
                toast.error("Bookable user not return in booking response");
                return;
            }

            const googleCalendarResponse = await CreateGoogleCalendarEvent(bookingResponse.bookableUser.id, createParams, bookingResponse.id);
            if (!googleCalendarResponse) {
                toast.error(`Google Calendar Integration Failed. Failed to create new event on ${bookingResponse.bookableUser.user?.firstname}'s Calendar`);
            }

            //We saved the start and end to the database with timeZoneOffset
            bookingResponse.start = new Date(bookingResponse.start).addHours(timeZoneOffset * -1);
            bookingResponse.end = new Date(bookingResponse.end).addHours(timeZoneOffset * -1);

            // Update the booking with the corresponding calendarId
            bookingResponse.calendarId = bookingResponse.bookableUser.id.toString();

            // setBookings([...bookings.filter((el) => (el.id === modalSelectedBooking.id ? false : el)), bookingResponse]);
        } else {
            //UPDATE NORMALLY

            // Check for overlapping bookings
            // const hasOverlappingBookings = bookings.some((booking) => {
            //     // Prevent overlapping with itself
            //     if (booking.id !== modalSelectedBooking.id) {
            //         return (
            //             booking.bookableUser.id === data.user.value &&
            //             doBookingsOverlap(booking, updateParams)
            //         );
            //     }
            // });
            // if (hasOverlappingBookings) {
            //     toast.warning("The new appointment overlaps with an existing appointment");
            //     return;
            // }

            const response = await UpdateBooking(modalSelectedBooking.id, updateParams);
            if (!response) {
                toast.error("Error saving appointment");
                return;
            }

            if (!modalSelectedBooking.bookableUser) {
                toast.error("Bookable user not loaded on booking");
                return;
            }

            //Update response is now handled by the backend
            // const googleCalendarResponse = await UpdateGoogleCalendarEvent(modalSelectedBooking.bookableUser.id, modalSelectedBooking.id, updateParams);
            // if (!googleCalendarResponse) {
            //     toast.error("Google Calendar Integration Failed. Failed to Update");
            // }

            //We saved the start and end to the database with timeZoneOffset
            response.start = new Date(response.start).addHours(timeZoneOffset * -1);
            response.end = new Date(response.end).addHours(timeZoneOffset * -1);

            response.calendarId = modalSelectedBooking.bookableUser.id.toString();
        }
        toast("Updated");
        closeBookingModal();
        loadBookings();
    };

    const deleteBooking = async () => {
        if (!bookings || !modalSelectedBooking) {
            toast.error("Bookings haven't loaded yet");
            return;
        }

        if (!window.confirm("Are you sure you want to delete this booking?")) return;

        const response = await DeleteBooking(modalSelectedBooking.id);
        if (!response) {
            toast.error("Error deleting booking");
            return;
        }

        if (!modalSelectedBooking.bookableUser) {
            toast.error("Bookable user not loaded on booking");
            return;
        }

        const googleCalendarResponse = await DeleteGoogleCalendarEvent(modalSelectedBooking.bookableUser.id, modalSelectedBooking.id);
        if (!googleCalendarResponse) {
            toast.error("Google Calendar Integration Failed. Failed to Delete");
        }

        // setBookings([...bookings.filter((el) => (el.id === modalSelectedBooking.id ? false : el))]);
        loadBookings();
        toast.success("Deleted Booking");
        closeBookingModal();
    };

    const updateDailyNote = async (data) => {
        const updateParams = {
            title: data.notes ? modalSelectedBooking.bookableUser.user.firstname.concat(': ', data.notes) : modalSelectedBooking.bookableUser.user.firstname,
            bookingType: BookingType.DAILY_NOTE,
            start: modalSelectedBooking.start,
            end: modalSelectedBooking.end,
            notes: data.notes && data.notes.trim().length ? data.notes : null,
            bookableUserId: data.user.value,
            timeZone: {
                "zoneName": "UTC",
                "abbreviation": "UTC"
            },
            schedulerId: authContext.user.accountId
        };

        let response = undefined;
        let newBooking = false;

        if (modalSelectedBooking.id.toString().includes(':')) {
            response = await CreateBooking(updateParams);
            newBooking = true;
        } else {
            if (data.notes) {
                response = await UpdateBooking(modalSelectedBooking.id, updateParams);
            }
            else {
                response = await DeleteBooking(modalSelectedBooking.id);
            }
        }

        if (!response) {
            toast.error("Error saving notes");
            return;
        }
        // response.category = 'allday';
        // response.isAllday = true;
        // response.calendarId = modalSelectedBooking.bookableUser.id.toString();

        // if(newBooking) {
        //     setBookings([...bookings.filter((el) => (el.id === modalSelectedBooking.id ? false : el)), response]);
        // } else {
        //     setBookings([...bookings.map((el) => (el.id === response.id ? response : el))]);
        // }

        toast("Updated");
        loadBookings();
        closeDailyNoteModal();
    };

    const onModalBookingTypeChange = (type) => {
        setBookingType(type);
        if (modalSelectedBookableUser) {
            const user = bookableUsers?.find((el) => el.id === modalSelectedBookableUser.value && el.bookableTypes.includes(type));
            if (!user) {
                setModalSelectedBookableUser(undefined);
            }
        }
    };

    const openBookingModal = () => {
        setModalTitle("New Booking");
        setBookingModalOpen(true);
    };

    const closeBookingModal = () => {
        setModalTitle("Booking");
        setModalSelectedBooking(undefined);
        setBookingType(undefined);
        setBookingModalOpen(false);
        setBookingTitle('');
        setModalSelectedBookingLocation('');
    };

    const openBookingModalUpdate = async (modalSelectedBooking) => {
        setModalTitle("Update Booking");
        setModalSelectedBooking(modalSelectedBooking);
        setModalSelectedBookableUser({ label: modalSelectedBooking.bookableUser.user.firstname + " " + modalSelectedBooking.bookableUser.user.lastname, value: modalSelectedBooking.bookableUser.id });
        setBookingModalOpen(true);
    };

    const closeDailyNoteModal = () => {
        setModalSelectedBooking(undefined);
        setBookingType(undefined);
        setDailyNoteModalOpen(false);
    };

    const openDailyNoteModalUpdate = async (modalSelectedBooking) => {
        setModalSelectedBooking(modalSelectedBooking);
        setModalSelectedBookableUser({ label: modalSelectedBooking.bookableUser.user.firstname + " " + modalSelectedBooking.bookableUser.user.lastname, value: modalSelectedBooking.bookableUser.id });
        setDailyNoteModalOpen(true);
    };

    const onEnterSearchQuoteRequest = async (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const input = (e.target.value).toString();

            if (input === "") return;
            else if (/^\d+$/.test(input)) {
                setQuoteRequestLoading(true);

                //retrieve by id
                const response = await RetrieveQuoteRequest(int.parse(input));

                if (!response) {
                    setQuoteRequests([]);
                    setQuoteRequestLoading(false);
                    return;
                }

                setQuoteRequests([response]);
                setQuoteRequestLoading(false);
            } else {
                setQuoteRequestLoading(true);

                //retrieve by name
                const response = await RetrieveQuoteRequestsByContactName(input);
                response?.sort((a, b) => {
                    if (a.id < b.id) return 1;
                    else if (a.id > b.id) return -1;
                    else return 0;
                })

                if (!response) {
                    setQuoteRequests([]);
                    setQuoteRequestLoading(false);
                    return;
                }

                setQuoteRequests(response);
                setQuoteRequestLoading(false);
            }
        }
    };

    const handleSalesRepHover = () => {
        setShowSalesReps(true);
    }

    const handleSalesRepHoverExit = () => setShowSalesReps(false);

    const handleBranchOptionsChange = (value) => {
        setCheckedUserIds();
        setSelectedBranch(branches.find(branch => { return branch.id === value.value }));
    };

    const doBookingsOverlap = (booking1, booking2) => {
        const start1 = new Date(booking1.start);
        const end1 = new Date(booking1.end);
        const start2 = new Date(booking2.start);
        const end2 = new Date(booking2.end);

        if ((start1 < start2 && start2 < end1) ||
            (start1 < end2 && end2 <= end1) ||
            (start2 <= start1 && end2 >= end1)) {
            console.log('Overlap');
        }
        return (
            (start1 < start2 && start2 < end1) ||
            (start1 < end2 && end2 <= end1) ||
            (start2 <= start1 && end2 >= end1)
        );
    }

    const loadGoogleCalendarEvents = async (bookableUserId, start, end) => {
        const response = await RetrieveGoogleCalendarEvents(bookableUserId, start, end);
        if (!response) {
            toast.error("Could not load Google Calendar Events");
            return;
        }

        return response.map((el) => ({ ...el, bookableUserId }));
    };

    const convertGoogleCalendarEventsToBookings = async () => {
        if (!googleCalendarEvents) return;
        let tempExternalBookings = [];

        for (const googleResponse of googleCalendarEvents) {
            if (!googleResponse) continue;

            for (const event of googleResponse) {
                if (event.extendedProperties) continue;

                if (!event.start?.dateTime || !event.end?.dateTime) {
                    console.log("HAS NO START OR END TIME", event);
                    continue;
                }
                // Fetch bookableUser so we can get the companyId for branch filtering
                const bookableUser = await RetrieveBookableUser(event.bookableUserId);

                tempExternalBookings.push({
                    companyId: bookableUser.user.company.id,
                    bookableUserId: event.bookableUserId,
                    start: new Date(event.start.dateTime),
                    end: new Date(event.end.dateTime),
                    title: event.summary ?? "External Event",
                    notes: event.description ?? null,
                    calendarId: event.bookableUserId.toString(),
                });
            }
        }
        if (selectedBranch) {
            const timeZone = selectedBranch.timeZone;

            tempExternalBookings = tempExternalBookings.map((booking) => {
                const start = new Date(booking.start);
                const end = new Date(booking.end);

                // Convert start and end times to the local time zone of the selected branch
                const localStartTime = new Date(start.toLocaleString("en-US", { timeZone: timeZone.zoneName }));
                const localEndTime = new Date(end.toLocaleString("en-US", { timeZone: timeZone.zoneName }));

                return {
                    ...booking,
                    start: localStartTime,
                    end: localEndTime,
                };
            });

            // Filter bookings based on the companyId of the selected branch
            tempExternalBookings = tempExternalBookings.filter((el) => el.companyId === selectedBranch.id);
        }
        if (bookableUsers.length > 0) {
            let userCollection = bookableUsers;
            if (checkedUserIds?.length > 0) {
                userCollection = bookableUsers.filter(user => checkedUserIds.includes(user.id));
            }
            tempExternalBookings = tempExternalBookings.filter((el) => userCollection.some((user) => user.id === el.bookableUserId));
        }

        setExternalBookings(tempExternalBookings);
    };

    const calculateCombinedEvents = () => {
        if (bookings && externalBookings) {
            const combined = [...bookings, ...externalBookings];
            setCombinedEvents(combined);
        }
    };

    const onSelectQuoteRequest = (e) => {
        const selectedQuote = quoteRequests.find((el) => el.id === e.value);
        const addressLabel = `${selectedQuote.contact?.firstname} ${selectedQuote.contact?.lastname} - ${selectedQuote.address.streetAddress} ${selectedQuote.address.city}, ${selectedQuote.address.state} ${selectedQuote.address.zip}`;
        const phoneLabel = `${selectedQuote.contact?.firstname} ${selectedQuote.contact?.lastname} - ${formatPhoneNumber(selectedQuote.contact?.phone)}`;

        let location = undefined;
        let title;
        switch (bookingType) {
            case BookingType.IN_HOME_DESIGN_CONSULTATION:
                location = selectedQuote.address.city;
                title = addressLabel;
                break;
            case BookingType.PHONE_DESIGN_CONSULTATION:
                location = formatPhoneNumber(selectedQuote.contact?.phone);
                title = phoneLabel;
                break;
            default:
                break;
        }
        setModalSelectedBookingLocation(location);
        setBookingTitle(title);
    };

    const handleCheckboxChange = (userId) => {
        if (checkedUserIds?.length > 0) {
            if (checkedUserIds.includes(userId)) {
                setCheckedUserIds((prevIds) => prevIds.filter((id) => id !== userId));
            } else {
                setCheckedUserIds((prevIds) => [...prevIds, userId]);
            }
        } else {
            setCheckedUserIds([userId]);
        }
    };

    useEffect(() => {
        const calendarInstance = cal.current?.getInstance();

        loadBranches();
        if (calendarInstance) {

            setDisplayedDateRange({
                start: calendarInstance.getDateRangeStart(),
                end: calendarInstance.getDateRangeEnd()
            });

        }
    }, []);

    useEffect(() => {
        loadSalesReps(selectedBranch?.id);
    }, [selectedBranch]);


    useEffect(() => {
        loadBookings();
    }, [displayedDateRange, bookableUsers, checkedUserIds]);

    useEffect(() => {
        convertGoogleCalendarEventsToBookings();
    }, [googleCalendarEvents, selectedBranch]);

    useEffect(() => {
        calculateCombinedEvents();
    }, [bookings, externalBookings]);

    const branchOptions = [
        {
            label: "All Branches",
            value: null
        },
        ...(branches?.map((el) => ({
            label: el.name,
            value: el.id
        })) || [])
    ];

    const formatTime = (date) => {
        const hour = String(date.getHours()).padStart(2, '0');
        const minute = String(date.getMinutes()).padStart(2, '0');

        return `${hour}:${minute}`;
    };

    return (
        <div className="App">
            <div className="flex items-center justify-between p-4 bg-gray-100">
                <div className="flex items-center space-x-4 justify-start">
                    <button
                        onClick={handleTodayButtonClick}
                        className="px-2 py-2 border border-gray-300 rounded-full text-sm w-20 hover:border-gray-400"
                    >
                        Today
                    </button>
                    <div className="flex space-x-2 items-center">
                        <button
                            className="rounded-full border border-gray-300 flex items-center justify-center w-8 h-8 hover:border-gray-400"
                            onClick={handleClickPrevButton}
                        >
                            <FontAwesomeIcon size="xs" icon={faChevronLeft} className="text-gray-500" />
                        </button>
                        <button
                            className="rounded-full border border-gray-300 flex items-center justify-center w-8 h-8 hover:border-gray-400"
                            onClick={handleClickNextButton}
                        >
                            <FontAwesomeIcon size="xs" icon={faChevronRight} className="text-gray-500" />
                        </button>
                    </div>
                </div>
                <div className="flex items-center justify-center">
                    <h1 className="font-bold text-lg font-sans">
                        {startDate.replace(/\//g, '.')} ~ {endDate.replace(/\//g, '.')}
                    </h1>
                </div>
                <div className="flex items-center space-x-4 justify-end text-sm">
                    <Select
                        options={branchOptions}
                        value={selectedBranch ? branchOptions.find(branch => { return branch.value === selectedBranch.id }) : {
                            label: "All Branches",
                            value: null
                        }}
                        defaultValue={selectedBranch ? { label: selectedBranch.name, value: selectedBranch.id } : {
                            label: "All Branches",
                            value: null
                        }}
                        onChange={(value) => { handleBranchOptionsChange(value) }}
                        className="w-40"
                    />
                    <div className="relative">
                        <span
                            className="cursor-pointer text-gray-600 hover:text-gray-800"
                            onMouseEnter={handleSalesRepHover}
                        >
                            Sales Reps
                        </span>
                        {showSalesReps && (
                            <div className="absolute right-0 mt-2 bg-white border border-gray-300 rounded shadow-md z-10">
                                <div className="py-1" onMouseLeave={handleSalesRepHoverExit}
                                >
                                    {/* Render sales reps list */}
                                    {bookableUsers &&
                                        bookableUsers
                                            .filter((rep) => !rep.user.deletedAt)
                                            .map((rep) => (
                                                <div key={rep.id} className="flex items-center p-2 hover:bg-gray-100 cursor-pointer">
                                                    <input
                                                        className="mr-2"
                                                        type="checkbox"
                                                        checked={checkedUserIds?.includes(rep.id)}
                                                        onChange={() => handleCheckboxChange(rep.id)}
                                                    >
                                                    </input>
                                                    <div className={'mr-2 h-5 w-5'} style={{ backgroundColor: rep.calendarColor }}></div>
                                                    <span className="text-sm text-gray-600">{rep.user.firstname}</span>
                                                </div>
                                            ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex space-x-4">
                        <button
                            onClick={() => handleViewClick('day')}
                            className={`px-2 py-2 rounded-md ${activeView === 'day' ? 'bg-theme_gold text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300 transition-colors duration-300'}`}
                        >
                            Day
                        </button>
                        <button
                            onClick={() => handleViewClick('week')}
                            className={`px-2 py-2 rounded-md ${activeView === 'week' ? 'bg-theme_gold text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300 transition-colors duration-300'}`}
                        >
                            Week
                        </button>
                        <button
                            onClick={() => handleViewClick('month')}
                            className={`px-2 py-2 rounded-md ${activeView === 'month' ? 'bg-theme_gold text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300 transition-colors duration-300'}`}
                        >
                            Month
                        </button>
                    </div>
                </div>
            </div>
            <div className="px-4 pb-4">
                <Calendar
                    ref={cal}
                    height="800px"
                    view="week"
                    taskView="true"
                    week={{
                        workweek: false,
                        eventView: ['time', 'allday'],
                        taskView: false,
                        hourStart: 6,
                        hourEnd: 19,
                        showNowIndicator: false,
                    }}
                    month={{
                        isAlways6Weeks: false,
                        narrowWeekend: true,
                    }}
                    calendars={calendars}
                    events={combinedEvents}
                    onClickEvent={handleOnClickEvent}
                    onSelectDateTime={handleOnSelectDateTime}
                    template={{
                        allday(event) {
                            return `<i class="fa fa-refresh">${event.title}</i>`;
                        },
                        time(event) {
                            const { start, end, title, location } = event;
                            let s = formatTime(start);
                            return `<span class-"toastui-calendar-template-time"><strong>${s}</strong><br>
                                <span>${location}</span><br>
                                <span>${title}</span></span>`;
                        },
                        // time(event) {
                        //      return `<span class-"toastui-calendar-template-time"><strong>${event.start}</strong><br>
                        //         <span>${event.title}</span></span>`;
                        // }
                        // alldayTitle() {
                        //   return "users";
                        // }
                    }}
                />
            </div>
            {bookingModalOpen && (
                <BookingModal
                    bookingId={modalSelectedBooking?.id}
                    calendarType="Consultation"
                    closeModal={closeBookingModal}
                    modalTitle={modalTitle}
                    visible={bookingModalOpen}
                    bookingType={modalSelectedBooking?.bookingType ?? undefined}
                    user={modalSelectedBookableUser ?? undefined}
                    title={modalSelectedBooking?.title ?? bookingTitle}
                    startDate={modalSelectedBooking?.start ? new Date(modalSelectedBooking.start) : modalSelectedStartDate}
                    endDate={modalSelectedBooking?.end ? new Date(modalSelectedBooking.end) : modalSelectedEndDate}
                    onBookingTypeChange={onModalBookingTypeChange}
                    disableChangingType={!!modalSelectedBooking}
                    onSubmit={modalSelectedBooking ? updateBooking : saveBooking}
                    onDelete={modalSelectedBooking && deleteBooking}
                    formData={
                        [
                            {
                                name: "quoteRequestId",
                                label: "Quote Request*",
                                placeholder: "Start Typing to Search",
                                required: "required",
                                defaultValue: modalSelectedBooking?.quoteRequest
                                    ? {
                                        label: `(${modalSelectedBooking.quoteRequest.id}) ${modalSelectedBooking.quoteRequest.contact?.firstname} ${modalSelectedBooking.quoteRequest.contact?.lastname} - ${modalSelectedBooking.quoteRequest.address.streetAddress} ${modalSelectedBooking.quoteRequest.address.city}, ${modalSelectedBooking.quoteRequest.address.state} ${modalSelectedBooking.quoteRequest.address.zip}`,
                                        value: modalSelectedBooking.quoteRequest.id,
                                    }
                                    : undefined,
                                onKeyDown: onEnterSearchQuoteRequest,
                                onChange: onSelectQuoteRequest,
                                formInputType: FormInputType.SELECT,
                                isLoading: quoteRequestLoading,
                                options: quoteRequests.map((el) => {
                                    return {
                                        label: `(${el.id}) ${el.contact?.firstname} ${el.contact?.lastname} - ${el.address.streetAddress} ${el.address.city}, ${el.address.state} ${el.address.zip}`,
                                        value: el.id,
                                    };
                                }),
                            },
                            {
                                name: "user",
                                label: "Sales Rep*",
                                placeholder: "Start Typing to Search",
                                required: "required",
                                formInputType: FormInputType.SELECT,
                                defaultValue: modalSelectedBookableUser,
                                options:
                                    bookableUsers
                                        ?.filter((el) => el.bookableTypes.includes(BookingType.IN_HOME_DESIGN_CONSULTATION || BookingType.PHONE_DESIGN_CONSULTATION) && !el.user.deletedAt)
                                        .map((el) => {
                                            return {
                                                label: `(${el.id}) ${el.user?.firstname} ${el.user?.lastname}`,
                                                value: el.id,
                                            };
                                        }) ?? [],
                            },
                            {
                                name: "confirmed",
                                label: "Confirmed?",
                                formInputType: FormInputType.CHECKBOX,
                                defaultValue: modalSelectedBooking?.confirmed !== undefined ? !!modalSelectedBooking.confirmed : false,
                            },
                            {
                                formInputType: FormInputType.TEXT,
                                name: "notifyCustomer",
                                defaultValue: modalSelectedBooking?.notifyCustomer ? new Date(modalSelectedBooking.notifyCustomer).toLocaleDateString() : undefined,
                                label: "SMS Last Sent",
                            },
                            {
                                formInputType: FormInputType.TEXTAREA,
                                name: "notes",
                                defaultValue: modalSelectedBooking?.notes ? modalSelectedBooking.notes : undefined,
                                label: "Notes",
                            },
                        ]
                    }
                />
            )}

            {dailyNoteModalOpen && (
                <DailyNoteModal
                    calendarType="Consultation"
                    closeModal={closeDailyNoteModal}
                    visible={dailyNoteModalOpen}
                    user={modalSelectedBookableUser}
                    startDate={modalSelectedBooking?.start ? new Date(modalSelectedBooking.start) : modalSelectedStartDate}
                    onSubmit={updateDailyNote}
                    formData={
                        [
                            {
                                formInputType: FormInputType.TEXTAREA,
                                name: "notes",
                                defaultValue: modalSelectedBooking?.notes ? modalSelectedBooking.notes : undefined,
                                label: "Notes",
                            },
                        ]
                    }
                />
            )}
        </div>
    );
}