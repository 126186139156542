import {
    BookingType,
    Scope,
    IAvailability,
    IBookableUser,
    IEmailPreferences,
    int,
    IUser,
    ICompany,
    CompanyType,
} from "@common.abstractions";
import { GetDate } from "@common.tools";
import Button from "@frontend/components/misc/button/Button";
import { Page } from "@frontend/components/misc/layout";
import { Loading } from "@frontend/components/misc/loading";
import { Modal } from "@frontend/components/misc/modal";
import { Table, TableHeader, TableRow, TableRowWrapper } from "@frontend/components/misc/table";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import AuthContext from "@frontend/contexts/AuthContext";
import { CreateBookableUser, RetrieveBookableUserByUserId, UpdateBookableUser } from "@frontend/services/bookable-users";
import { UpdateEmailPreferences } from "@frontend/services/email-preferences";
import { RetrieveUser, UpdateUserGrants, DeleteUser } from "@frontend/services/users";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import UserPermissionsModal from "../user-permissions-modal/UserPermissionsModal";
import { useNavigate } from "react-router-dom-v5-compat";
import { RetrieveCompanies } from "@frontend/services/companies";
import { Equals } from "@common.api";

export default function User() {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const params = useParams<{ userId: string }>();
    const [userId] = useState<int>(int.parse(params.userId));
    const [user, setUser] = useState<IUser>();
    const [bookableUser, setBookableUser] = useState<IBookableUser>();

    const [userGrants, setUserGrants] = useState<Array<{ id: number; moniker: string; description: string }>>([]);
    const [portalScopes, setPortalScopes] = useState<Array<{ id: number; moniker: string; description: string }>>([]);
    const [emailPreferences, setEmailPreferences] = useState<IEmailPreferences>();
    const [bookableTypes, setBookableTypes] = useState<BookingType[]>([]);
    const [availability, setAvailability] = useState<IAvailability>({
        monday: { availableTimes: [] },
        tuesday: { availableTimes: [] },
        wednesday: { availableTimes: [] },
        thursday: { availableTimes: [] },
        friday: { availableTimes: [] },
        saturday: { availableTimes: [] },
        sunday: { availableTimes: [] },
    });
    const [branchManager, setBranchManager] = useState<boolean>(false);
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [scheduledLocations, setScheduledLocations] = useState<int[]>([]);

    const [permissionModalVisible, setPermissionModalVisible] = useState<boolean>(false);
    const [emailPreferencesModalVisible, setEmailPreferencesModalVisible] = useState<boolean>(false);
    const [bookingTypesModalVisible, setBookingTypesModalVisible] = useState<boolean>(false);
    const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false);

    const closeDeleteUserModal = () => {
        setDeleteUserModal(false);
    }

    const openDeleteUserModal = () => {
        setDeleteUserModal(true);
    }

    const deleteCompanyUser = () => {
        DeleteUser(userId).then(() => navigate('/users'));
        toast.success('User Deleted');
    }

    const loadUser = async () => {
        const user = await RetrieveUser(userId);
        if (!user) return;

        setUser(user);
        setEmailPreferences(user.emailPreferences);
    };

    const loadBookableUser = async () => {
        const response = await RetrieveBookableUserByUserId(userId);
        if (!response) return;
        setBookableUser(response);

        if (response) {
            setBookableTypes(response.bookableTypes);
            setAvailability(response.availability);
            setBranchManager(response.isBranchManager);
            setScheduledLocations(response.scheduledLocations)
        }
    };

    const closeEmailPreferencesModal = () => {
        setEmailPreferencesModalVisible(false);
    };

    const closeBookingTypesModal = () => {
        setBookingTypesModalVisible(false);

        if (bookableUser) {
            setBookableTypes(bookableUser.bookableTypes);
            setAvailability(bookableUser.availability);
            setBranchManager(bookableUser.isBranchManager);
            setScheduledLocations(bookableUser.scheduledLocations)
        } else {
            setBookableTypes([]);
            setAvailability({
                monday: { availableTimes: [] },
                tuesday: { availableTimes: [] },
                wednesday: { availableTimes: [] },
                thursday: { availableTimes: [] },
                friday: { availableTimes: [] },
                saturday: { availableTimes: [] },
                sunday: { availableTimes: [] },
            });
            setBranchManager(false);
            setScheduledLocations([]);
        }
    };

    const updateEmailPreferences = async () => {
        if (!emailPreferences) return;

        const response = await UpdateEmailPreferences(emailPreferences.id, {
            dailyReport: emailPreferences.dailyReport,
            dealerManagerOrderNotification: emailPreferences.dealerManagerOrderNotification,
            newLead: emailPreferences.newLead,
            weeklyReport: emailPreferences.weeklyReport,
            warranty: emailPreferences.warranty,
            monthlyReport: emailPreferences.monthlyReport,
            salesNotification: emailPreferences.salesNotification,
            salesNotificationAll: emailPreferences.salesNotificationAll,
            postInstallDiscrepancy: emailPreferences.postInstallDiscrepancy,
            postInstallDiscrepancyAll: emailPreferences.postInstallDiscrepancyAll,
            marketingSpend: emailPreferences.marketingSpend,
        });

        if (response) {
            toast("Saved");
            authContext?.refreshUser();
        }

        closeEmailPreferencesModal();
    };

    const saveUpdatedGrants = async (grants: Array<number>) => {
        const response = await UpdateUserGrants(userId, grants);
        if (!response.success) {
            toast.error("Failed to update user grants");
            return;
        }
        if (response.data.added.fail.length !== 0 || response.data.removed.fail.length !== 0) {
            toast.warn("Failed to grant some permissions, please try again");
            return;
        }
        toast.success("User grants updated!");
        setPermissionModalVisible(false);
    };

    const updateBookableUser = async () => {
        if (!bookableTypes) {
            toast.error("Please fill out booking types");
            return;
        }

        let response: IBookableUser | undefined = undefined;

        if (bookableUser) {
            response = await UpdateBookableUser(bookableUser.id, { bookableTypes, availability, isBranchManager: branchManager, scheduledLocations: scheduledLocations });
        } else {
            response = await CreateBookableUser({
                availability,
                bookableTypes,
                userId,
                isBranchManager: branchManager,
                scheduledLocations: scheduledLocations
            });
        }

        if (!response) {
            toast.error("Something went wrong");
            return;
        }

        closeBookingTypesModal();
        setBookableUser(response);
        setBookableTypes(response.bookableTypes);
        setAvailability(response.availability);
        setBranchManager(response.isBranchManager);
        setScheduledLocations(response.scheduledLocations);
        toast("Updated");
    };

    const setUpdatedEmailPreferences = (option: "dealerManagerOrderNotification" | "monthlyReport" | "weeklyReport" | "dailyReport" | "newLead" | "warranty" | "salesNotification" | "salesNotificationAll" | 'postInstallDiscrepancy' | 'postInstallDiscrepancyAll' | 'marketingSpend', checked: boolean) => {
        if (!emailPreferences) return;

        const tempEmailPreferences = { ...emailPreferences };

        if (checked) {
            tempEmailPreferences[option] = true;
        } else if (!checked) {
            tempEmailPreferences[option] = false;
        }

        setEmailPreferences(tempEmailPreferences);
    };

    const setUpdatedBookingTypes = (type: BookingType, checked: boolean) => {
        let tempBookingTypes = [...bookableTypes];

        if (checked) {
            if (!tempBookingTypes.includes(type)) {
                tempBookingTypes.push(type);
            }
        } else {
            if (tempBookingTypes.includes(type)) {
                tempBookingTypes = tempBookingTypes.filter((el) => el !== type);
            }
        }

        setBookableTypes(tempBookingTypes);
    };

    const setUpdatedAvailability = (
        day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday",
        index: number,
        startDate?: Date,
        endDate?: Date
    ) => {
        let tempAvailability = { ...availability };

        if (startDate) {
            tempAvailability[day].availableTimes = tempAvailability[day].availableTimes.map((el, i) => {
                if (i === index) {
                    el.start = startDate;
                }
                return el;
            });
        } else if (endDate) {
            tempAvailability[day].availableTimes = tempAvailability[day].availableTimes.map((el, i) => {
                if (i === index) {
                    el.end = endDate;
                }
                return el;
            });
        }

        setAvailability(tempAvailability);
    };

    const addAvailabilityRow = (day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday") => {
        const tempAvailability = { ...availability };

        if (
            //if they already clicked to add availability, but haven't filled out the fields yet
            tempAvailability[day].availableTimes.some(
                (el) =>
                    el.start.toLocaleString() === GetDate.portalsBirth().toLocaleString() || el.end.toLocaleString() === GetDate.portalsBirth().toLocaleString()
            )
        )
            return;

        if (tempAvailability[day]) {
            tempAvailability[day].availableTimes.push({ start: GetDate.portalsBirth(), end: GetDate.portalsBirth() });
        } else {
            tempAvailability[day] = { availableTimes: [{ start: GetDate.portalsBirth(), end: GetDate.portalsBirth() }] };
        }
        setAvailability(tempAvailability);
    };

    const deleteAvailabilityRow = (day: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday", index: number) => {
        const tempAvailability = { ...availability };
        tempAvailability[day].availableTimes = tempAvailability[day].availableTimes.filter((_, i) => i !== index);
        setAvailability(tempAvailability);
    };

    const fetchBranches = async () => {
        const branches = await RetrieveCompanies({
            filters: {
                companyType: Equals(CompanyType.BRANCH)
            }
        })
        if (!branches) return;
        setCompanies(branches);
    }

    useEffect(() => {
        loadUser();
        loadBookableUser();
        fetchBranches();
    }, []);

    return (
        <Page>
            <TopBar title={user ? user.firstname + " " + user.lastname : ""}>
                {authContext?.hasAnyGrant(Scope.User.UPDATE_ANY) ? (
                    <Button type="button" className="ml-4" style="outline" rounded color="blue" onClick={() => setPermissionModalVisible(true)}>
                        Permissions
                    </Button>
                ) : null}

                {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE, Scope.EmailPreferences.UPDATE_ANY) ? (
                    <Button type="button" className="ml-4" style="outline" rounded color="blue" onClick={() => setEmailPreferencesModalVisible(true)}>
                        Email Preferences
                    </Button>
                ) : null}

                {authContext?.hasAnyGrant(Scope.BookableUser.UPDATE, Scope.BookableUser.UPDATE_ANY) ? (
                    <Button type="button" className="ml-4" style="outline" rounded color="blue" onClick={() => setBookingTypesModalVisible(true)}>
                        Booking Settings
                    </Button>
                ) : null}

                {authContext?.hasAnyGrant(Scope.User.UPDATE_ANY) ? (
                    <Button style="outline" className="ml-4" color="yellow" rounded link={`/users/${user?.accountId}/edit`}>
                        Edit User
                    </Button>

                ) : null}

                {authContext?.hasAnyGrant(Scope.User.DELETE_ANY) ? (
                    <Button style="outline" className="ml-4" color="red" rounded onClick={openDeleteUserModal}>
                        Delete User
                    </Button>

                ) : null}

            </TopBar>

            {!user ? (
                <Loading center />
            ) : (
                <>
                    <Modal visible={deleteUserModal} closeModal={closeDeleteUserModal} >
                        <div className="p-4">
                            <div className="text-2xl text-gray-500">Are you sure?</div>
                            <div className="text-gray-500 py-4">
                                You are about to permanently delete this user.
                            </div>
                            <div className="flex justify-end">
                                <div className="btn bg-theme_blue text-white" onClick={deleteCompanyUser} >
                                    Delete User
                                </div>
                                <div className="btn bg-gray-400 text-white ml-4" onClick={closeDeleteUserModal}>
                                    Cancel
                                </div>
                            </div>
                        </div>
                    </Modal>

                    {permissionModalVisible &&
                        <UserPermissionsModal
                            userId={userId}
                            closeModal={() => setPermissionModalVisible(false)}
                            saveUpdatedGrants={(g) => saveUpdatedGrants(g)}
                        />
                    }

                    <Modal closeModal={closeEmailPreferencesModal} visible={emailPreferencesModalVisible}>
                        <div className="flex flex-col max-w-full">
                            {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE, Scope.EmailPreferences.UPDATE_ANY) && (
                                <div className="flex px-4 mt-8">
                                    <Button type="button" rounded style={"solid"} color="blue" onClick={updateEmailPreferences}>
                                        Save Email Preferences
                                    </Button>
                                </div>
                            )}
                            <div className="flex flex-col p-4 my-4 ">
                                <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                    <div className="text-gray-600 capitalize">
                                        New Lead
                                        <input
                                            className="ml-2"
                                            type="checkbox"
                                            checked={emailPreferences?.newLead}
                                            onChange={(e) => setUpdatedEmailPreferences("newLead", e.target.checked)}
                                        />
                                    </div>
                                </label>
                                {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE_ANY, Scope.QuoteRequest.READ_ANY, Scope.Order.READ_ANY) && (
                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            Monthly Report
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={emailPreferences?.monthlyReport}
                                                onChange={(e) => setUpdatedEmailPreferences("monthlyReport", e.target.checked)}
                                            />
                                        </div>
                                    </label>
                                )}
                                {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE_ANY, Scope.QuoteRequest.READ_ANY, Scope.Order.READ_ANY) && (
                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            Weekly Report
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={emailPreferences?.weeklyReport}
                                                onChange={(e) => setUpdatedEmailPreferences("weeklyReport", e.target.checked)}
                                            />
                                        </div>
                                    </label>
                                )}
                                {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE_ANY, Scope.QuoteRequest.READ_ANY, Scope.Order.READ_ANY) && (
                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            Daily Report
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={emailPreferences?.dailyReport}
                                                onChange={(e) => setUpdatedEmailPreferences("dailyReport", e.target.checked)}
                                            />
                                        </div>
                                    </label>
                                )}
                                {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE_ANY, Scope.Report.READ_ANY) && (
                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            Marketing Spend
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={emailPreferences?.marketingSpend}
                                                onChange={(e) => setUpdatedEmailPreferences("marketingSpend", e.target.checked)}
                                            />
                                        </div>
                                    </label>
                                )}
                                {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE_ANY, Scope.Order.READ_ANY) && (
                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            Dealer Manager Order Notification
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={emailPreferences?.dealerManagerOrderNotification}
                                                onChange={(e) => setUpdatedEmailPreferences("dealerManagerOrderNotification", e.target.checked)}
                                            />
                                        </div>
                                    </label>
                                )}
                                {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE_ANY, Scope.Warranty.READ_ANY) && (
                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            Warranty
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={emailPreferences?.warranty}
                                                onChange={(e) => setUpdatedEmailPreferences("warranty", e.target.checked)}
                                            />
                                        </div>
                                    </label>
                                )}
                                {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE_ANY, Scope.Installs.READ_ANY) && (
                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            User Branch Sale Notifications ONLY
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={emailPreferences?.salesNotification}
                                                onChange={(e) => setUpdatedEmailPreferences("salesNotification", e.target.checked)}
                                            />
                                        </div>
                                    </label>
                                )}
                                {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE_ANY, Scope.Installs.READ_ANY) && (
                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            ALL Branch Sale Notifications
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={emailPreferences?.salesNotificationAll}
                                                onChange={(e) => setUpdatedEmailPreferences("salesNotificationAll", e.target.checked)}
                                            />
                                        </div>
                                    </label>
                                )}
                                {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE_ANY, Scope.Installs.READ_ANY) && (
                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            User Branch Post Install Discrepancy
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={emailPreferences?.postInstallDiscrepancy}
                                                onChange={(e) => setUpdatedEmailPreferences("postInstallDiscrepancy", e.target.checked)}
                                            />
                                        </div>
                                    </label>
                                )}
                                {authContext?.hasAnyGrant(Scope.EmailPreferences.UPDATE_ANY, Scope.Installs.READ_ANY) && (
                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            ALL Branch Post Install Discrepancy
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={emailPreferences?.postInstallDiscrepancyAll}
                                                onChange={(e) => setUpdatedEmailPreferences("postInstallDiscrepancyAll", e.target.checked)}
                                            />
                                        </div>
                                    </label>
                                )}
                            </div>
                        </div>
                    </Modal>
                    <Modal closeModal={closeBookingTypesModal} visible={bookingTypesModalVisible}>
                        {authContext?.hasAnyGrant(Scope.BookableUser.UPDATE, Scope.BookableUser.UPDATE_ANY) && (
                            <div className="flex flex-col overflow-auto" style={{ minWidth: "500px" }}>
                                <div className="flex px-4 mt-8">
                                    <Button type="button" rounded style={"solid"} color="blue" onClick={updateBookableUser}>
                                        Update Booking Settings
                                    </Button>
                                </div>
                                <div className="flex flex-col p-4 mt-4 ">
                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            {BookingType.INSTALL}
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={bookableTypes.includes(BookingType.INSTALL)}
                                                onChange={(e) => setUpdatedBookingTypes(BookingType.INSTALL, e.target.checked)}
                                            />
                                        </div>
                                    </label>

                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            {BookingType.IN_HOME_DESIGN_CONSULTATION}
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={bookableTypes.includes(BookingType.IN_HOME_DESIGN_CONSULTATION)}
                                                onChange={(e) => setUpdatedBookingTypes(BookingType.IN_HOME_DESIGN_CONSULTATION, e.target.checked)}
                                            />
                                        </div>
                                    </label>

                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-4 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            {BookingType.PHONE_DESIGN_CONSULTATION}
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={bookableTypes.includes(BookingType.PHONE_DESIGN_CONSULTATION)}
                                                onChange={(e) => setUpdatedBookingTypes(BookingType.PHONE_DESIGN_CONSULTATION, e.target.checked)}
                                            />
                                        </div>
                                    </label>

                                    <label className="cursor-pointer block hover:bg-gray-200 p-2 mb-42 duration-200">
                                        <div className="text-gray-600 capitalize">
                                            {BookingType.REPAIR}
                                            <input
                                                className="ml-2"
                                                type="checkbox"
                                                checked={bookableTypes.includes(BookingType.REPAIR)}
                                                onChange={(e) => setUpdatedBookingTypes(BookingType.REPAIR, e.target.checked)}
                                            />
                                        </div>
                                    </label>
                                </div>

                                <div className="ml-6 mb-2 flex">
                                    <label className="text-gray-600">Branch Manager</label>
                                    <input
                                        className="ml-2"
                                        type="checkbox"
                                        checked={branchManager}
                                        onChange={(e) => setBranchManager(e.target.checked)}
                                    />
                                </div>

                                <div className="h-0.5 bg-gray-300 w-full"></div>
                                <div className="flex flex-col p-4 my-2">
                                    <div className="flex flex-col">
                                        <div className="w-full flex items-center">
                                            <label className="text-gray-600">Monday</label>
                                            <FontAwesomeIcon
                                                onClick={() => addAvailabilityRow("monday")}
                                                className="cursor-pointer ml-2 text-lg text-gray-500 hover:text-blue-500"
                                                icon={faPlusSquare}
                                            />
                                        </div>
                                        {availability?.monday.availableTimes.map((el, i) => (
                                            <div key={`availability monday ${i}`} className="flex items-center">
                                                <div className="flex py-3 flex-col mr-4">
                                                    <label className="text-gray-500 text-xs mb-2">Start*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.start).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.start).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("monday", i, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600 w-48`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex py-3 flex-col">
                                                    <label className="text-gray-500 text-xs mb-2">End*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.end).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.end).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("monday", i, undefined, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon
                                                        onClick={() => deleteAvailabilityRow("monday", i)}
                                                        className="cursor-pointer ml-2 mt-6 text-lg text-gray-400 hover:text-blue-500"
                                                        icon={faMinusSquare}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="w-full flex items-center">
                                            <label className="text-gray-600">Tuesday</label>
                                            <FontAwesomeIcon
                                                onClick={() => addAvailabilityRow("tuesday")}
                                                className="cursor-pointer ml-2 text-lg text-gray-500 hover:text-blue-500"
                                                icon={faPlusSquare}
                                            />
                                        </div>
                                        {availability?.tuesday.availableTimes.map((el, i) => (
                                            <div key={`availability tuesday ${i}`} className="flex items-center">
                                                <div className="flex py-3 flex-col mr-4">
                                                    <label className="text-gray-500 text-xs mb-2">Start*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.start).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.start).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("tuesday", i, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600 w-48`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex py-3 flex-col">
                                                    <label className="text-gray-500 text-xs mb-2">End*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.end).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.end).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("tuesday", i, undefined, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon
                                                        onClick={() => deleteAvailabilityRow("tuesday", i)}
                                                        className="cursor-pointer ml-2 mt-6 text-lg text-gray-400 hover:text-blue-500"
                                                        icon={faMinusSquare}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="w-full flex items-center">
                                            <label className="text-gray-600">Wednesday</label>
                                            <FontAwesomeIcon
                                                onClick={() => addAvailabilityRow("wednesday")}
                                                className="cursor-pointer ml-2 text-lg text-gray-500 hover:text-blue-500"
                                                icon={faPlusSquare}
                                            />
                                        </div>
                                        {availability?.wednesday.availableTimes.map((el, i) => (
                                            <div key={`availability wednesday ${i}`} className="flex items-center">
                                                <div className="flex py-3 flex-col mr-4">
                                                    <label className="text-gray-500 text-xs mb-2">Start*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.start).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.start).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("wednesday", i, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600 w-48`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex py-3 flex-col">
                                                    <label className="text-gray-500 text-xs mb-2">End*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.end).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.end).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("wednesday", i, undefined, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon
                                                        onClick={() => deleteAvailabilityRow("wednesday", i)}
                                                        className="cursor-pointer ml-2 mt-6 text-lg text-gray-400 hover:text-blue-500"
                                                        icon={faMinusSquare}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="w-full flex items-center">
                                            <label className="text-gray-600">Thursday</label>
                                            <FontAwesomeIcon
                                                onClick={() => addAvailabilityRow("thursday")}
                                                className="cursor-pointer ml-2 text-lg text-gray-500 hover:text-blue-500"
                                                icon={faPlusSquare}
                                            />
                                        </div>
                                        {availability?.thursday.availableTimes.map((el, i) => (
                                            <div key={`availability thursday ${i}`} className="flex items-center">
                                                <div className="flex py-3 flex-col mr-4">
                                                    <label className="text-gray-500 text-xs mb-2">Start*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.start).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.start).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("thursday", i, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600 w-48`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex py-3 flex-col">
                                                    <label className="text-gray-500 text-xs mb-2">End*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.end).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.end).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("thursday", i, undefined, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon
                                                        onClick={() => deleteAvailabilityRow("thursday", i)}
                                                        className="cursor-pointer ml-2 mt-6 text-lg text-gray-400 hover:text-blue-500"
                                                        icon={faMinusSquare}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="w-full flex items-center">
                                            <label className="text-gray-600">Friday</label>
                                            <FontAwesomeIcon
                                                onClick={() => addAvailabilityRow("friday")}
                                                className="cursor-pointer ml-2 text-lg text-gray-500 hover:text-blue-500"
                                                icon={faPlusSquare}
                                            />
                                        </div>
                                        {availability?.friday.availableTimes.map((el, i) => (
                                            <div key={`availability friday ${i}`} className="flex items-center">
                                                <div className="flex py-3 flex-col mr-4">
                                                    <label className="text-gray-500 text-xs mb-2">Start*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.start).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.start).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("friday", i, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600 w-48`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex py-3 flex-col">
                                                    <label className="text-gray-500 text-xs mb-2">End*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.end).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.end).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("friday", i, undefined, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon
                                                        onClick={() => deleteAvailabilityRow("friday", i)}
                                                        className="cursor-pointer ml-2 mt-6 text-lg text-gray-400 hover:text-blue-500"
                                                        icon={faMinusSquare}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="w-full flex items-center">
                                            <label className="text-gray-600">Saturday</label>
                                            <FontAwesomeIcon
                                                onClick={() => addAvailabilityRow("saturday")}
                                                className="cursor-pointer ml-2 text-lg text-gray-500 hover:text-blue-500"
                                                icon={faPlusSquare}
                                            />
                                        </div>
                                        {availability?.saturday.availableTimes.map((el, i) => (
                                            <div key={`availability saturday ${i}`} className="flex items-center">
                                                <div className="flex py-3 flex-col mr-4">
                                                    <label className="text-gray-500 text-xs mb-2">Start*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.start).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.start).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("saturday", i, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600 w-48`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex py-3 flex-col">
                                                    <label className="text-gray-500 text-xs mb-2">End*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.end).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.end).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("saturday", i, undefined, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon
                                                        onClick={() => deleteAvailabilityRow("saturday", i)}
                                                        className="cursor-pointer ml-2 mt-6 text-lg text-gray-400 hover:text-blue-500"
                                                        icon={faMinusSquare}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="flex flex-col">
                                        <div className="w-full flex items-center">
                                            <label className="text-gray-600">Sunday</label>
                                            <FontAwesomeIcon
                                                onClick={() => addAvailabilityRow("sunday")}
                                                className="cursor-pointer ml-2 text-lg text-gray-500 hover:text-blue-500"
                                                icon={faPlusSquare}
                                            />
                                        </div>
                                        {availability?.sunday.availableTimes.map((el, i) => (
                                            <div key={`availability sunday ${i}`} className="flex items-center">
                                                <div className="flex py-3 flex-col mr-4">
                                                    <label className="text-gray-500 text-xs mb-2">Start*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.start).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.start).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("sunday", i, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600 w-48`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="flex py-3 flex-col">
                                                    <label className="text-gray-500 text-xs mb-2">End*</label>
                                                    <div className="text-gray-600 flex flex-grow relative">
                                                        <DatePicker
                                                            value={
                                                                new Date(el.end).toLocaleString() === GetDate.portalsBirth().toLocaleString()
                                                                    ? undefined
                                                                    : new Date(el.end).toLocaleTimeString([], { hour: "numeric", minute: "numeric" })
                                                            }
                                                            onChange={(date) => setUpdatedAvailability("sunday", i, undefined, date as Date)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            dateFormat="MMMM d, yyyy h:mm"
                                                            wrapperClassName="w-full"
                                                            className={`input text-gray-600`}
                                                            timeIntervals={30}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <FontAwesomeIcon
                                                        onClick={() => deleteAvailabilityRow("sunday", i)}
                                                        className="cursor-pointer ml-2 mt-6 text-lg text-gray-400 hover:text-blue-500"
                                                        icon={faMinusSquare}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div>
                                    <h2>Scheduled Locations:</h2>
                                    {companies.map((company) => (
                                        <div key={company.id}>
                                            <label>{company.name}</label>
                                            <input
                                                type="checkbox"
                                                checked={scheduledLocations.includes(company.id)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setScheduledLocations(prevScheduledLocations => [...prevScheduledLocations, company.id]);
                                                    } else {
                                                        setScheduledLocations(prevScheduledLocations =>
                                                            prevScheduledLocations.filter((el) => el !== company.id)
                                                        );
                                                    }
                                                }} />
                                        </div>
                                    ))}
                                </div>

                            </div>
                        )}
                    </Modal>
                    <Table>
                        <TableHeader
                            topBorder
                            data={[{ title: "Created" }, { title: "Name" }, { title: "Email" }, { title: "Phone" }, { title: "Company" }, { title: "Status" }]}
                        />
                        <TableRowWrapper>
                            <TableRow
                                data={[
                                    { text: { title: new Date(user.createdAt).toLocaleDateString() } },
                                    { text: { title: `${user.firstname} ${user.lastname}` } },
                                    { text: { title: user.email } },
                                    //{ text: { title: formatPhoneNumber(user.phone) } },
                                    { text: { title: user.company?.name ?? "" } },
                                    // {
                                    //     text: {
                                    //         title: authContext?.hasAnyGrant(Grant.User.UPDATE_ANY)
                                    //             ? user.vActivated
                                    //                 ? "Active"
                                    //                 : "Resend Activation Link"
                                    //             : "Not Activated",
                                    //         onClick: authContext?.hasAnyGrant(Grant.User.UPDATE_ANY)
                                    //             ? user.vActivated
                                    //                 ? undefined
                                    //                 : () => ResendActivationLink(user.id)
                                    //             : undefined,
                                    //         color: "gray",
                                    //     },
                                    // },
                                ]}
                            />
                        </TableRowWrapper>
                    </Table>
                </>
            )}
        </Page>
    );
}
