import { CompanyType, Scope } from "@common.abstractions";
import AuthContext from "@frontend/contexts/AuthContext";
import { BACKEND_URL, GOOGLE_CALENDAR_CLIENT_ID, GOOGLE_CALENDAR_REDIRECT_URI } from "@frontend/environment";
import { AuthGoogleCalendar } from "@frontend/services/google-calendar";
import QueryString from "qs";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../misc/button/Button";
import { Page } from "../misc/layout";
import TopBar from "../misc/top-bar/TopBar";

export default function Account() {
    const authContext = useContext(AuthContext);

    const linktoGoogleAuth = () => {
        const baseUrl = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?`;
        const params = {
            redirect_uri: GOOGLE_CALENDAR_REDIRECT_URI,
            response_type: "code permission",
            scope: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
            client_id: GOOGLE_CALENDAR_CLIENT_ID,
            access_type: "offline",
            prompt: "consent",
            include_granted_scopes: "true",
            flowName: "GeneralOAuthFlow",
        };

        return baseUrl + QueryString.stringify(params);
    };

    return (
        <Page>
            <TopBar title="Account" />
            {authContext?.hasAnyGrant(Scope.Company.UPDATE) && (
                <>
                    <Button className="mb-4 text-md w-72" link="/account/ACH" style="solid" color="blue">
                        ACH Transfer
                    </Button>
                    <Button className="mb-4 text-md w-72" link="/account/addresses" style="solid" color="blue">
                        Address
                    </Button>
                    {authContext.user?.company?.companyType === CompanyType.BRANCH && authContext.hasAnyGrant(Scope.BookableUser.UPDATE) ? (
                        <div className="ml-1 mb-4 text-md w-72">
                            <a className="mb-4 text-md w-72" href={linktoGoogleAuth()}>
                                Connect Google Calendar
                            </a>
                        </div>
                    ) : null}
                </>
            )}
        </Page>
    );
}
