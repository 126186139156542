import { int } from "@common.abstractions";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LogoWithText from "../../assets/LogoWithText.png";

export default function PasswordReset() {
    const navigate= useNavigate();
    const location = useLocation();
    const params = QueryString.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    const routeParams = useParams<{ userId: string }>();
    const [userId] = useState<int>(int.parse(routeParams.userId));
    const [token] = useState<string | undefined>(params.token as string);
    const [tokenValid, setTokenValid] = useState<boolean>(false);
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");

    // const validateToken = async () => {
    //     if (token) {
    //         const response = await CheckResetPasswordToken(userId, token);
    //         if (response) {
    //             setTokenValid(true);
    //         }
    //     }
    // };
    const handleSubmit = (args: any) => alert("Not currently supported") 
    // const handleSubmit = async (e: any) => {
    //     e.preventDefault();
    //     if (newPassword !== newPasswordConfirm) {
    //         toast.error("Make sure the passwords match");
    //         return;
    //     }
    //     if (!newPassword.length || newPassword.length < 6 || newPassword.length > 100) {
    //         toast.error("Password must be at least 6 characters");
    //         return
    //     }
    //     if (token && tokenValid) {
    //         const response = await SetNewPassword(userId, token, newPassword);
    //         if (response) {
    //             toast("New Password Saved");
    //             setTimeout(() => {
    //                 navigate("/");
    //             }, 200);
    //         } else {
    //             toast("Error setting new password");
    //         }
    //     }
    // };

    // useEffect(() => {
    //     validateToken();
    // }, []);

    return !tokenValid ? (
        !tokenValid && <div className="inset-center text-gray-500">Your reset password link has expired</div>
    ) : (
        <div className="h-screen relative">
            <div className="inset-center max-w-md flex flex-col items-center -my-10">
                <img src={LogoWithText} alt="Logo" />
                <form>
                    <div className="form_group">
                        <div>
                            <label>New Password</label>
                            <input
                                className="form-control"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                type="password"
                                placeholder="required"
                            />
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label>Confirm New Password</label>
                            <input
                                className="form-control"
                                value={newPasswordConfirm}
                                onChange={(e) => setNewPasswordConfirm(e.target.value)}
                                type="password"
                                placeholder="required"
                            />
                        </div>
                    </div>

                    <button onClick={(e) => handleSubmit(e)} className="btn bg-theme_blue text-white mt-6">
                        Save New Password
                    </button>
                </form>
            </div>
        </div>
    );
}
