import React from 'react'
import { formatWholeMoney } from '@common.tools';

type ShopifyTotalSales = {
    totals: {
        totalGoal: number;
        totalProgress: number;
        totalPercent: number;
    }
    daysSinceLastPurchase: number | undefined;
    totalFtSold: number;
    onClick(): void;
}

export default function ShopifySales(props: ShopifyTotalSales) {
    return (
        <>
            <div className="p-5 shadow-md hover:shadow-lg duration-theme h-auto opacity-70 hover:opacity-100 cursor-pointer"
                onClick={props.onClick}
            >
                <h2 className="text-gray-500 mb-3">DIY Monthly Target </h2>
                <div className="flex items-end mb-4">
                    <div className="text-3xl text-gray-700 font-bold">{(props.totals.totalPercent).toFixed(2)}%</div>
                    <div className="text-xl text-gray-700 font-bold flex ml-5">
                        <div className="relative" style={{ minWidth: "30px" }}>
                            {formatWholeMoney(props.totals.totalProgress * 100)}
                            <div className="absolute bottom-full text-xs whitespace-nowrap">progress</div>
                        </div>
                        <div className="mx-3">/</div>
                        <div className="relative">
                            {formatWholeMoney(props.totals.totalGoal * 100)}
                            <div className="absolute bottom-full text-xs whitespace-nowrap">target</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-5 shadow-md hover:shadow-lg duration-theme h-auto opacity-70 hover:opacity-100 cursor-pointer">
                <h2 className="text-gray-500 mb-3">DIY Days Since Last Order </h2>
                <div className="inline-flex items-center w-14">
                    <span className="ml-1 text-gray-500">{props.daysSinceLastPurchase}</span>
                </div>
            </div>

            <div className="p-5 shadow-md hover:shadow-lg duration-theme h-auto opacity-70 hover:opacity-100">
                <div className="flex">
                    <h2 className="text-gray-500 mb-3">Total DIY Ft Sold </h2>
                    <p className="text-3xl text-gray-700 font-bold ml-3">{props.totalFtSold}</p>
                </div>
            </div>
        </>
    )
}