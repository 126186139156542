import {
    BookingType,
    CompanyType,
    IExternalBooking,
    IAvailability,
    IBookableUser,
    IBooking,
    ICompany,
    ICreateBookingParams,
    int,
    IUpdateBookingParams,
    Scope,
    IInstall,
    IRepair,
    float,
    ICreatePostInstall,
    FinancingCompany,
    RepairType,
} from "@common.abstractions";
import { Between, Equals, GreaterThan, In, LessThan, Not } from "@common.api";
import { Page } from "@frontend/components/misc/layout";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import AuthContext from "@frontend/contexts/AuthContext";
import { RetrieveBookableUsers } from "@frontend/services/bookable-users";
import { CreateBooking, DeleteBooking, RetrieveBookings, SendBookingConfirmationEmail, UpdateBooking } from "@frontend/services/bookings";
import { RetrieveCompanies } from "@frontend/services/companies";
import {
    CreateGoogleCalendarEvent,
    DeleteGoogleCalendarEvent,
    RetrieveGoogleCalendarEvents,
    UpdateGoogleCalendarEvent,
} from "@frontend/services/google-calendar";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BookingModal, Calendar as CalendarWrapper } from "./components";
import { FormInputType } from "./models/formInput";
import { RowData } from "./models/rowData";
import { RetrieveInstall, RetrieveInstallsByContactName } from "@frontend/services/installs";
import { RetrieveRepair, RetrieveRepairs, RetrieveRepairsByContactName, UpdateRepair } from "@frontend/services/repairs";
import FootageModal from "./components/FootageModal";
import { CreatePostInstall, RequestSunLightPayment } from "@frontend/services/post-install";
import { useHistory } from "react-router-dom";

export function Calendars() {
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const [bookableUsers, setBookableUsers] = useState<IBookableUser[]>([]);
    const [bookings, setBookings] = useState<IBooking[]>();
    const [externalBookings, setExternalBookings] = useState<IExternalBooking[]>();
    const [branches, setBranches] = useState<ICompany[]>();
    const [initialLoadGoogleCalendarEvents, setInitialLoadGoogleCalendarEvents] = useState<boolean>(false);
    const [googleCalendarEvents, setGoogleCalendarEvents] = useState<any[]>();
    const [selectedCalendarDate, setSelectedCalendarDate] = useState<Date>(new Date());
    const [timeZoneOffset, setTimeZoneOffset] = useState<number>(0);
    const [selectedBranch, setSelectedBranch] = useState<ICompany>();

    const [bookingType, setBookingType] = useState<BookingType>();
    const [bookingModalOpen, setBookingModalOpen] = useState<boolean>(false);
    const [selectedBookableUser, setSelectedBookableUser] = useState<{ label: string; value: number }>(); //TODO: MAKE SALES REP
    const [selectedBooking, setSelectedBooking] = useState<IBooking>();
    const [selectedStartDate, setSelectedStartDate] = useState<Date>(); //This is for when you click on a cell
    const [selectedEndDate, setSelectedEndDate] = useState<Date>(); //This is for when you click on a cell
    const [modalTitle, setModalTitle] = useState<string>("Booking");

    const [displayedDateRange, setDisplatedDateRange] = useState<{ start: Date; end: Date }>();
    const [installLoading, setInstallLoading] = useState<boolean>(false);
    const [installs, setInstalls] = useState<IInstall[]>([]);
    const [additionalSelects, setAdditionalSelects] = useState<number>(0);
    const [modalSelectedBookingLocation, setModalSelectedBookingLocation] = useState<string | undefined>('');
    const [bookingTitle, setBookingTitle] = useState<string>('');
    const [repairs, setRepairs] = useState<IRepair[]>([]);
    const [repairLoading, setRepairLoading] = useState(false);
    const [showConfirmationButton, setShowConfirmationButton] = useState(false);
    const [bookingNotes, setBookingNotes] = useState<string>('');
    const [footage, setFootage] = useState<int | null>();
    const [houseLevel, setHouseLevel] = useState<float | null>();
    const [typeOfLights, setTypeOfLights] = useState<string>('');

    const [sessionInstall, setSessionInstall] = useState<IInstall>();
    const [sessionRepair, setSessionRepair] = useState<IRepair>();

    const [footageModalOpen, setFootageModalOpen] = useState<boolean>(false);
    const [serialNumber, setSerialNumber] = useState<string | undefined>('');
    const [ftInstalled, setFtInstalled] = useState<int>(int(0));
    const [lightsInstalledList, setLightsInstalledList] = useState<{ serialNumber: string | null, ft: int }[]>([]);
    const [jobCompleted, setJobCompleted] = useState<boolean>(false);

    const [isMultiDay, setIsMultiDay] = useState<boolean>(false);
    const [additionalDays, setAdditionalDays] = useState<{ startDate: Date, endDate: Date }[]>([]);

    const handleWeekChange = (start: Date, end: Date) => {
        setDisplatedDateRange({ start, end });
    };

    const loadSalesReps = async (companyId?: int) => {
        let response = await RetrieveBookableUsers({
            relations: ['user', 'user.company'],
        });
        if (!response) {
            toast.error('Error loading sales reps');
            return;
        }

        if (companyId) {
            response = response.filter((bookableUser) => bookableUser.scheduledLocations.includes(companyId));
        }
        else {
            response = response.filter((el) => el.bookableTypes.some(type => type === BookingType.INSTALL || type === BookingType.REPAIR));
        }
        setBookableUsers(response);
    }

    const filterSalesReps = async (companyId: int) => {
        if (companyId === 0) {
            loadSalesReps();

            if (branches && bookings) {
                setBookings([
                    ...bookings.map((el) => {
                        el.start = new Date(new Date(el.start).addHours(timeZoneOffset));
                        el.end = new Date(new Date(el.end).addHours(timeZoneOffset));
                        return el;
                    }),
                ]);
            }

            toast(`Switched to back to local timezone`);
            setSelectedBranch(undefined);
            setTimeZoneOffset(0);
        } else {
            loadSalesReps(companyId);
            if (branches && bookings) {
                //Convert availability to that company's timezone
                const selectedBranch = branches.find((el) => el.id === companyId);
                if (!selectedBranch) {
                    toast.warning("Unable to change timezone to match that of the company selected");
                    return;
                } else if (!selectedBranch.timeZone) {
                    toast.warning("Selected company has no timezone to switch to");
                    return;
                }

                const timeZone = selectedBranch.timeZone;
                const localDate = new Date();
                const newTimeZoneDate = new Date(new Date(localDate).toLocaleString("en-US", { timeZone: timeZone.zoneName }));

                setBookings([
                    ...bookings.map((el) => {
                        el.start = new Date(new Date(el.start).addHours(timeZoneOffset).toLocaleString("en-US", { timeZone: timeZone.zoneName }));
                        el.end = new Date(new Date(el.end).addHours(timeZoneOffset).toLocaleString("en-US", { timeZone: timeZone.zoneName }));
                        return el;
                    }),
                ]);

                setSelectedBranch(selectedBranch);
                setTimeZoneOffset(localDate.getUTCHours() - newTimeZoneDate.getUTCHours() - timeZoneOffset); //might have to add +

                toast(`Switched to timezone: ${timeZone.zoneName}`);
            }
        }
    };

    const openBookingModal = (rect: DOMRect, startDate: Date, endDate: Date, row: { label: string; value: number }) => {
        setModalTitle("New Booking");
        setSelectedBookableUser({ label: row.label, value: row.value });
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
        setBookingModalOpen(true);
        setShowConfirmationButton(false);
    };

    const closeBookingModal = () => {
        setModalTitle("Booking");
        setSelectedBooking(undefined);
        setBookingTitle('')
        setBookingType(undefined);
        setBookingModalOpen(false);
        setModalSelectedBookingLocation('');
        setShowConfirmationButton(false);
        setBookingNotes('');
        setFootage(int(0));
        setHouseLevel(float(0));
        setTypeOfLights('');
        setAdditionalDays([]);
        setIsMultiDay(false);
    };

    const onEnterSearchInstall = async (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const input = e.target.value

            if (input === "") return;
            else if (/^\d+$/.test(input)) {
                setInstallLoading(true);

                //retrieve by id
                const response = await RetrieveInstall(int.parse(input));
                if (!response) {
                    setInstallLoading(false);
                    return;
                }

                setInstalls([response]);
                setInstallLoading(false);
            } else {
                setInstallLoading(true);

                const response = await RetrieveInstallsByContactName(input);
                if (!response) {
                    setInstallLoading(false);
                    return;
                }

                setInstalls(response);
                setInstallLoading(false);
            }
        }
    };

    const loadBranches = async () => {
        if (authContext?.hasAnyGrant(Scope.Company.READ_ANY)) {
            const response = await RetrieveCompanies({
                filters: {
                    companyType: Equals(CompanyType.BRANCH),
                },
            });
            if (!response) {
                toast.error("Error loading branches");
                return;
            }

            setBranches(response);
        } else {
            setBranches([authContext!.user!.company!]);
        }
    };

    const loadBookings = async () => {
        let response = await RetrieveBookings({
            filters: {
                start: Between(displayedDateRange!.start, displayedDateRange!.end),
                bookingType: In([BookingType.INSTALL, BookingType.REPAIR, BookingType.BUFFER_DAY, BookingType.TIME_OFF, BookingType.NON_FIELD_WORK, BookingType.WARRANTY_REPAIR]),
            },
            relations: ["install", "install.branch", "bookableUser", "thirdPartyRepair", "smsHistory", "bookableUser.user", "bookableUser.user.company", "repair", "repair.branch"],
        });

        if (!response) {
            toast.error("Error loading bookings");
            return;
        }

        if (selectedBranch) {
            const timeZone = selectedBranch.timeZone;
            const localDate = new Date();
            const newTimeZoneDate = new Date(new Date(localDate).toLocaleString("en-US", { timeZone: timeZone?.zoneName }));
            const newTimeZoneOffSet = localDate.getUTCHours() - newTimeZoneDate.getUTCHours();

            response = response.map((el) => {
                el.start = new Date(new Date(el.start).toLocaleString("en-US", { timeZone: timeZone?.zoneName }));
                el.end = new Date(new Date(el.end).toLocaleString("en-US", { timeZone: timeZone?.zoneName }));

                return el;
            });

            setTimeZoneOffset(newTimeZoneOffSet);
        }
        else {
            const newTimeZoneOffSet = 0;

            response = response.map((el) => {
                el.start = new Date(new Date(el.start).addHours(newTimeZoneOffSet));
                el.end = new Date(new Date(el.end).addHours(newTimeZoneOffSet));
                return el;
            });

            setTimeZoneOffset(newTimeZoneOffSet);
        }

        setBookings(response);
    };

    const saveBooking = async (data: any) => {
        // Capture the bookableUserIds of additional select fields
        const additionalInstallerIds = Array.from({ length: additionalSelects }).map((_, index) => {
            const fieldName = `additionalSelect_${index}`;
            return data[fieldName].value;
        });
        const bookableUserIds = [data.user.value, ...additionalInstallerIds];
        const schedulerId = authContext!.user!.accountId;

        if (!bookings) {
            toast.error("Bookings haven't loaded yet");
            return;
        }

        if (data.user === 0) {
            toast.error("Must select a user for the appointment");
            return;
        }

        let tempTimeZone = selectedBranch?.timeZone || authContext?.user?.company?.timeZone;
        if (!tempTimeZone) {
            toast.error("No branch is selected and user's company has no timezone");
            return;
        }

        const createBookingForDate = async (startDate: Date, endDate: Date, userId: int, uuid?: string) => {
            const createParams = {
                title: data.title,
                bookingType: data.bookingType.value,
                start: new Date(startDate).addHours(timeZoneOffset),
                end: new Date(endDate).addHours(timeZoneOffset),
                notes: data.notes ?? null,
                bookableUserId: userId,
                installId: data.installId?.value ?? null,
                repairId: data.repairId?.value ?? null,
                quoteRequestId: null,
                confirmed: data.confirmed ? new Date() : null,
                timeZone: tempTimeZone,
                thirdPartyRepairId: null,
                location: modalSelectedBookingLocation,
                schedulerId: schedulerId
            };

            const bookingResponse = await CreateBooking(createParams, uuid);
            if (bookingResponse) {
                // Adjust the timezone back for display or further processing
                bookingResponse.start = new Date(bookingResponse.start).addHours(-timeZoneOffset);
                bookingResponse.end = new Date(bookingResponse.end).addHours(-timeZoneOffset);

                return bookingResponse;
            } else {
                throw new Error("Error saving appointment");
            }
        };

        try {
            // Create the main booking and fetch the UUID from the response
            let mainBookingResponse = await createBookingForDate(data.startDate, data.endDate, bookableUserIds[0]);
            let mainBookingUUID = mainBookingResponse.uuid;

            // Use the mainBookingUUID for all other installers on the initial date
            for (const userId of bookableUserIds.slice(1)) {
                await createBookingForDate(data.startDate, data.endDate, userId, mainBookingUUID);
            }

            // Only attempt to process additional days if they are defined and not empty
            const additionalDays = data.additionalDays ?? [];
            for (const day of additionalDays) {
                let dayBookingResponse = await createBookingForDate(day.startDate, day.endDate, bookableUserIds[0]);
                let dayBookingUUID = dayBookingResponse.uuid;

                for (const userId of bookableUserIds.slice(1)) {
                    await createBookingForDate(day.startDate, day.endDate, userId, dayBookingUUID);
                }
            }
            toast("All bookings saved successfully!");
            closeBookingModal();
            clearStorage();
            loadBookings();
        } catch (error: any) {
            console.error("Failed to create bookings: ", error);
            toast.error("Error saving bookings: " + error.message);
        }
    };

    const updateBooking = async (data: any) => {
        if (!bookings || !selectedBooking) {
            toast.error("Bookings haven't loaded yet");
            return;
        }

        if (data.user === 0) {
            toast.error("Must select a user for the appointment");
            return;
        }

        if (new Date(data.startDate) > new Date(data.endDate)) {
            toast.error("End date cannot be before start date");
            return;
        }

        if (!selectedBooking.bookableUser?.id) {
            toast.error("Bookable User not loaded");
            return;
        }

        let tempTimeZone = undefined;
        if (selectedBranch?.timeZone) {
            tempTimeZone = selectedBranch.timeZone;
        } else if (authContext?.user?.company?.timeZone) {
            tempTimeZone = authContext?.user?.company?.timeZone;
        } else {
            toast.error("No branch is selected and user's company has no timezone");
            return;
        }

        const updateParams: IUpdateBookingParams = {
            title: data.title,
            bookingType: data.bookingType.value,
            start: new Date(new Date(data.startDate).addHours(timeZoneOffset)),
            end: new Date(new Date(data.endDate).addHours(timeZoneOffset)),
            notes: data.notes ?? null,
            bookableUserId: data.user.value,
            installId: data.installId?.value ?? null,
            repairId: data.repairId?.value ?? null,
            quoteRequestId: data.quoteRequestId?.value ?? null,
            confirmed: data.confirmed ? new Date() : null,
            timeZone: tempTimeZone,
            notifyCustomer: data.notifyCustomer ? new Date() : null,
            location: selectedBooking.location!,
            schedulerId: authContext!.user!.accountId
        };

        if (selectedBooking.bookableUser.id !== data.user.value) {
            //DELETE BOOKING FIRST
            const deleteResponse = await DeleteBooking(selectedBooking.id);
            if (!deleteResponse) {
                toast.error("Error updating booking");
                return;
            }

            if (!selectedBooking.bookableUser) {
                toast.error("Bookable user not loaded on booking");
                return;
            }


            const googleCalendarDeleteResponse = await DeleteGoogleCalendarEvent(selectedBooking.bookableUser.id, selectedBooking.id);
            if (!googleCalendarDeleteResponse) {
                toast.error(`Google Calendar Integration Failed. Failed to delete from ${selectedBooking.bookableUser.user?.firstname}'s Calendar`);
            }

            //CREATE NEW BOOKING
            const params: ICreateBookingParams = {
                title: data.title,
                bookingType: data.bookingType.value,
                start: new Date(new Date(data.startDate).addHours(timeZoneOffset)),
                end: new Date(new Date(data.endDate).addHours(timeZoneOffset)),
                notes: data.notes ?? null,
                bookableUserId: data.user.value,
                installId: data.installId?.value ?? null,
                repairId: data.repairId?.value ?? null,
                quoteRequestId: data.quoteRequestId?.value ?? null,
                confirmed: data.confirmed ? new Date() : null,
                timeZone: tempTimeZone,
                thirdPartyRepairId: selectedBooking.thirdPartyRepair?.id ?? null,
                notifyCustomer: data.notifyCustomer ? new Date() : null,
                location: selectedBooking.location!,
                schedulerId: authContext!.user!.accountId
            };

            const bookingResponse = await CreateBooking(params);
            if (!bookingResponse) {
                toast.error("Error saving appointment");
                return;
            }

            if (!bookingResponse.bookableUser?.id) {
                toast.error("Bookable user not return in booking response");
                return;
            }

            const googleCalendarResponse = await CreateGoogleCalendarEvent(bookingResponse.bookableUser.id, params, bookingResponse.id);
            if (!googleCalendarResponse) {
                toast.error(`Google Calendar Integration Failed. Failed to create new event on ${selectedBooking.bookableUser.user?.firstname}'s Calendar`);
            }

            //We saved the start and end to the database with timeZoneOffset
            bookingResponse.start = new Date(bookingResponse.start).addHours(timeZoneOffset * -1);
            bookingResponse.end = new Date(bookingResponse.end).addHours(timeZoneOffset * -1);
            // setBookings([...bookings.filter((el) => (el.id === selectedBooking.id ? false : el)), bookingResponse]);
        } else {
            //UPDATE NORMALLY
            const response = await UpdateBooking(selectedBooking.id, updateParams);
            if (!response) {
                toast.error("Error saving appointment");
                return;
            }

            if (!selectedBooking.bookableUser) {
                toast.error("Bookable user not loaded on booking");
                return;
            }
            const googleCalendarResponse = await UpdateGoogleCalendarEvent(selectedBooking.bookableUser.id, selectedBooking.id, updateParams);
            if (!googleCalendarResponse) {
                toast.error("Google Calendar Integration Failed. Failed to Update");
            }

            //We saved the start and end to the database with timeZoneOffset
            response.start = new Date(response.start).addHours(timeZoneOffset * -1);
            response.end = new Date(response.end).addHours(timeZoneOffset * -1);

            // setBookings([...bookings.map((el) => (el.id === response.id ? response : el))]);
        }

        toast("Updated");
        closeBookingModal();
        loadBookings();
    };

    const deleteBooking = async () => {
        if (!bookings || !selectedBooking) {
            toast.error("Bookings haven't loaded yet");
            return;
        }

        if (!window.confirm("Are you sure you want to delete this booking?")) return;

        const response = await DeleteBooking(selectedBooking.id);
        if (!response) {
            toast.error("Error deleting booking");
            return;
        }

        if (!selectedBooking.bookableUser) {
            toast.error("Bookable user not loaded on booking");
            return;
        }
        const googleCalendarResponse = await DeleteGoogleCalendarEvent(selectedBooking.bookableUser.id, selectedBooking.id);
        if (!googleCalendarResponse) {
            toast.error("Google Calendar Integration Failed. Failed to Delete");
        }

        // setBookings([...bookings.filter((el) => (el.id === selectedBooking.id ? false : el))]);
        toast.success("Deleted Booking");
        closeBookingModal();
        loadBookings();
    };

    const onBookingTypeChange = (type: BookingType) => {
        setBookingType(type);
        if (selectedBookableUser) {
            const user = bookableUsers?.find((el) => el.id === selectedBookableUser.value);
            if (!user) {
                setSelectedBookableUser(undefined);
            }
        }
        if (type === BookingType.BUFFER_DAY) setBookingTitle('Buffer Day');
        else if (type === BookingType.TIME_OFF) setBookingTitle('Time Off');
        else if (type === BookingType.NON_FIELD_WORK) setBookingTitle('Non Field Work');
        else setBookingTitle('');
    };

    const openBookingModalUpdate = async ({ data }: { data: { row: RowData; booking: IBooking } }) => {
        setModalTitle("Update Booking");
        setSelectedBooking(data.booking);
        setSelectedBookableUser({ label: data.row.label, value: data.row.value as number });
        setBookingModalOpen(true);
        setShowConfirmationButton(true);


        if (data.booking.install) {
            setBookingNotes(data.booking.install.notes ?? '');
            setFootage(data.booking.install.ftSold);
            setHouseLevel(data.booking.install.houseLevel);
            setTypeOfLights(data.booking.install.typeOfLights ?? '');
        }
    };

    const convertGoogleCalendarEventsToBookings = () => {
        if (!googleCalendarEvents) return;
        const tempExternalBookings: IExternalBooking[] = [];

        googleCalendarEvents.forEach((googleResponse) => {
            if (!googleResponse) return;
            googleResponse.forEach((event: any) => {
                if (event.extendedProperties) return;

                if (!event.start?.dateTime || !event.end?.dateTime) {
                    console.log("HAS NO START OR END TIME", event);
                    return;
                }

                tempExternalBookings.push({
                    bookableUserId: event.bookableUserId,
                    start: new Date(event.start.dateTime),
                    end: new Date(event.end.dateTime),
                    title: event.summary ?? "External Event",
                    notes: event.description ?? null,
                });
            });
        });

        setExternalBookings(tempExternalBookings);
    };

    const onSelectInstall = (e: any) => {
        const selectedInstall = installs.find((el) => el.id === e.value);
        if (!selectedInstall) {
            toast.error('Error selecting install');
            return;
        }

        const customerLabel = `${selectedInstall?.customer.firstName} ${selectedInstall?.customer.lastName}`;
        const location = selectedInstall?.customer.address;

        setModalSelectedBookingLocation(location);
        setBookingTitle(customerLabel);
        setBookingNotes(selectedInstall.notes ?? '')
        setFootage(selectedInstall.ftSold ?? int(0));
        setHouseLevel(selectedInstall.houseLevel ?? float(0));
        setTypeOfLights(selectedInstall.typeOfLights ?? '');
    };

    const onSelectRepair = (e: any) => {
        const selectedRepair = repairs.find((el) => el.id === e.value);

        const customerLabel = `${selectedRepair?.customer.firstName} ${selectedRepair?.customer.lastName}`;
        const location = selectedRepair?.customer.address;

        setBookingNotes(selectedRepair?.notes ?? '');
        setModalSelectedBookingLocation(location);
        setBookingTitle(customerLabel);
    };

    const onEnterSearchRepair = async (e: any) => {
        if (e.key === "Enter") {
            e.preventDefault();
            const input = e.target.value

            if (input === "") return;
            else if (/^\d+$/.test(input)) {
                setRepairLoading(true);

                //retrieve by id
                const response = await RetrieveRepair(int.parse(input));
                if (!response) {
                    setRepairLoading(false);
                    return;
                }

                setRepairs([response]);
                setRepairLoading(false);
            } else {
                setRepairLoading(true);

                const response = await RetrieveRepairsByContactName(input);
                if (!response) {
                    setRepairLoading(false);
                    return;
                }

                setRepairs(response);
                setRepairLoading(false);
            }
        }
    };

    const handleAddSelect = () => {
        setAdditionalSelects((prev) => prev + 1);
    }

    const handleRemoveSelect = () => {
        if (additionalSelects > 0) {
            setAdditionalSelects((prev) => prev - 1);
        }
    }

    const manualSendConfirmation = async () => {
        if (!selectedBooking) return;
        if (window.confirm('Are you sure you want to send a confirmation email to the customer?')) {
            const response = await SendBookingConfirmationEmail(selectedBooking.id);
            if (!response) {
                toast.error('Error sending confirmation email');
                return;
            }
            toast.success('Confirmation email sent');
            closeBookingModal();
            loadBookings();
        }
    }

    const retrieveSessionStorage = () => {
        if (bookingType === BookingType.INSTALL) {
            const item = sessionStorage.getItem('install');
            if (item) {
                const data = JSON.parse(item);
                const customerLabel = `${data.install.customer.firstName} ${data.install.customer.lastName}`;
                const location = data.install.customer.address;

                setModalSelectedBookingLocation(location);
                setBookingTitle(customerLabel);
                setFootage(data.install.ftSold ?? int(0));
                setHouseLevel(data.install.houseLevel ?? float(0));
                setTypeOfLights(data.install.typeOfLights ?? '');
                setBookingNotes(data.install.notes ?? '');

                setSessionInstall(data.install);
            }
        } else if (bookingType === BookingType.REPAIR || bookingType === BookingType.WARRANTY_REPAIR) {
            const item = sessionStorage.getItem('repair');
            if (item) {
                const data = JSON.parse(item);
                const customerLabel = `${data.repair.customer.firstName} ${data.repair.customer.lastName}`;
                const location = data.repair.customer.address;

                setModalSelectedBookingLocation(location);
                setBookingTitle(customerLabel);
                setBookingNotes(data.repair.notes ?? '');

                setSessionRepair(data.repair);
            }
        }
    };

    const renderInstallFormValue = () => {
        if (selectedBooking?.install) {
            return {
                label: `(${selectedBooking.install.id}) ${selectedBooking.install.customer.firstName} ${selectedBooking.install.customer.lastName} - ${selectedBooking.install.customer.address}`,
                value: selectedBooking.install.id
            }
        }
        else if (sessionInstall) {
            return {
                label: `(${sessionInstall.id}) ${sessionInstall.customer.firstName} ${sessionInstall.customer.lastName} - ${sessionInstall.customer.address}`,
                value: sessionInstall.id
            }
        }
        return undefined;
    }

    const renderRepairFormValue = () => {
        if (selectedBooking?.repair) {
            return {
                label: `(${selectedBooking.repair.id}) ${selectedBooking.repair.customer.firstName} ${selectedBooking.repair.customer.lastName} - ${selectedBooking.repair.customer.address}`,
                value: selectedBooking.repair.id
            }
        }
        else if (sessionRepair) {
            return {
                label: `(${sessionRepair.id}) ${sessionRepair.customer.firstName} ${sessionRepair.customer.lastName} - ${sessionRepair.customer.address}`,
                value: sessionRepair.id
            }
        }
        return undefined;
    }

    const openFootageModal = () => {
        setFootageModalOpen(true);
        setBookingModalOpen(false);
    }

    const closeFootageModal = () => {
        setFootageModalOpen(false);
        setSerialNumber('');
        setFtInstalled(int(0));
        setLightsInstalledList([]);
    }

    const submitFootage = async () => {
        if (!selectedBooking?.install) {
            console.error('Can only enter footage for installs');
            return;
        }

        const params: ICreatePostInstall = {
            lightsInstalled: lightsInstalledList,
            installerAccountId: selectedBooking.bookableUser?.user?.accountId!,
            collectedPayment: null,
            installId: selectedBooking.install.id,
            paymentNotes: null,
        }
        const response = await CreatePostInstall(params, jobCompleted);
        if (!response) return toast.error('Error saving footage');

        toast.success('Footage saved');
        setLightsInstalledList([]);

        if (selectedBooking.install.financingCompany === FinancingCompany.SUNLIGHT_FINANCIAL_SERVICES) {
            if (!selectedBooking.install.loanNumber) return
            await RequestSunLightPayment(selectedBooking.install.loanNumber);
        }
        if (jobCompleted) {
            history.push(`/installerTool/${response.id}/checklist`)
        }
    }

    const createLightsInstalled = (serialNumber: string | null, ft: int) => {
        if (ft <= 0) {
            console.error('Invalid input for lights installed');
            return;
        }

        const newEntry = { serialNumber: serialNumber, ft: ft };

        setLightsInstalledList([...lightsInstalledList, newEntry]);
        setSerialNumber('');
        setFtInstalled(int(0));
    };

    const deleteLightsInstalled = (lightsInstall: { ft: int, serialNumber: string | null }) => {
        setLightsInstalledList(lightsInstalledList.filter((el) => el !== lightsInstall));
    };

    const repairCompleted = async () => {
        const confirm = window.confirm('Do you want to mark this repair as completed?')
        if (confirm) {
            await UpdateRepair(selectedBooking?.repair?.id!, { jobCompletedDate: new Date() });
            history.push(`/repair/${selectedBooking?.repair?.id}/checklist`);
        }
    }

    const handleMultiDay = (isChecked: boolean) => {
        setIsMultiDay(isChecked);
        if (!isChecked) {
            setAdditionalDays([]);
        }
    };

    const addAdditionalDate = () => {
        let newStartDate, newEndDate;

        if (additionalDays.length > 0) {
            // Get the last endDate in the list to calculate the next start date
            const baseStartDate = new Date(selectedStartDate!);
            const baseEndDate = new Date(selectedEndDate!);

            // Calculate the difference in days to add
            const daysToAdd = additionalDays.length + 1; // +1 because you start counting from the next day after the initial

            newStartDate = new Date(baseStartDate.setDate(baseStartDate.getDate() + daysToAdd));
            newEndDate = new Date(baseEndDate.setDate(baseEndDate.getDate() + daysToAdd));
        } else {
            // Use selectedStartDate and selectedEndDate for the first additional day
            newStartDate = new Date(new Date(selectedStartDate!).setDate(new Date(selectedStartDate!).getDate() + 1));
            newEndDate = new Date(new Date(selectedEndDate!).setDate(new Date(selectedEndDate!).getDate() + 1));
        }

        setAdditionalDays([...additionalDays, { startDate: newStartDate, endDate: newEndDate }]);
    };

    const removeAdditionalDate = (index: number) => {
        setAdditionalDays(additionalDays.filter((_, idx) => idx !== index));
    };

    const handleDateChange = (index: number, type: string, date: Date) => {
        const updatedDays = additionalDays.map((day, idx) => {
            if (idx === index) {
                return { ...day, [type]: date };
            }
            return day;
        });
        setAdditionalDays(updatedDays);
    };

    const clearStorage = () => {
        window.sessionStorage.removeItem('install');
        window.sessionStorage.removeItem('repair');
        setSessionInstall(undefined);
        setSessionRepair(undefined);
    }

    useEffect(() => {
        retrieveSessionStorage();
    }, [bookingType])

    useEffect(() => {
        loadBranches();
        loadSalesReps();
        retrieveSessionStorage();
    }, []);

    useEffect(() => {
        loadBookings();
    }, [displayedDateRange]);

    useEffect(() => {
        convertGoogleCalendarEventsToBookings();
    }, [googleCalendarEvents]);


    const [changeTimeZoneOnce, setChangetimeZoneOnce] = useState<boolean>(false);
    useEffect(() => {
        if (bookings) {
            if (!authContext?.hasAnyGrant(Scope.Company.READ_ANY)) {
                if (!changeTimeZoneOnce) {
                    filterSalesReps(authContext!.user!.company!.id);
                    setChangetimeZoneOnce(true);
                }
            }
        }
    }, [bookings]);

    return (
        <Page>
            <TopBar title="Operations Calendar"></TopBar>

            {selectedCalendarDate && (
                <CalendarWrapper
                    onWeekChange={handleWeekChange}
                    mode='week'
                    data={{
                        rows: bookableUsers
                            ? bookableUsers.map((bookableUser: IBookableUser) => {
                                return {
                                    label: `${bookableUser?.user?.firstname} ${bookableUser?.user?.lastname}`,
                                    value: bookableUser.id,
                                    bookings: bookings?.filter((el) => el.bookableUser?.id === bookableUser.id),
                                    externalBookings: externalBookings?.filter((el) => el.bookableUserId === bookableUser.id),
                                    // unavailability: getUnavailableDays(bookableUser.availability) ?? [],
                                };
                            })
                            : [],
                    }}
                    onCellClick={
                        selectedBranch ? openBookingModal : () => alert("You are in view only mode, select a branch to create, update, and delete bookings")
                    }
                    onBookingClick={
                        selectedBranch
                            ? openBookingModalUpdate
                            : () => alert("You are in view only mode, select a branch to create, update, and delete bookings")
                    }
                    onDateChange={setSelectedCalendarDate}
                    selectedDate={selectedCalendarDate}
                    filter={
                        branches
                            ? branches.length > 1
                                ? {
                                    onChange: filterSalesReps,
                                    options: [{ label: "All Branches", value: 0 }, ...branches.map((el) => ({ label: el.name, value: el.id }))],
                                }
                                : {
                                    onChange: filterSalesReps,
                                    options: [{ label: branches[0].name, value: branches[0].id }],
                                }
                            : undefined
                    }
                />
            )}

            {bookingModalOpen && (
                <BookingModal
                    installNotes={bookingNotes}
                    bookingId={selectedBooking?.id}
                    calendarType="Operations"
                    closeModal={closeBookingModal}
                    modalTitle={modalTitle}
                    visible={bookingModalOpen}
                    bookingType={selectedBooking?.bookingType ?? bookingType}
                    user={selectedBookableUser ?? undefined}
                    title={selectedBooking?.title ?? bookingTitle}
                    startDate={selectedBooking?.start ? new Date(selectedBooking.start) : selectedStartDate}
                    endDate={selectedBooking?.end ? new Date(selectedBooking.end) : selectedEndDate}
                    onBookingTypeChange={onBookingTypeChange}
                    disableChangingType={!!selectedBooking}
                    onSubmit={selectedBooking ? updateBooking : saveBooking}
                    onDelete={selectedBooking && deleteBooking}
                    showButton={showConfirmationButton}
                    additionalSelects={additionalSelects}
                    handleAddSelect={handleAddSelect}
                    handleRemoveSelect={handleRemoveSelect}
                    onClick={manualSendConfirmation}
                    openFootageModal={openFootageModal}
                    repairJobCompleted={repairCompleted}
                    //MULTIDAY PROPS
                    multiDay={isMultiDay}
                    handleMultiDay={handleMultiDay}
                    additionalDays={additionalDays}
                    addDates={addAdditionalDate}
                    removeDates={removeAdditionalDate}
                    onDateChange={handleDateChange}

                    formData={
                        bookingType === BookingType.INSTALL ?
                            [
                                {
                                    name: "installId",
                                    label: "Install*",
                                    placeholder: "Start Typing to Search",
                                    required: "required",
                                    defaultValue: renderInstallFormValue(),
                                    onKeyDown: onEnterSearchInstall,
                                    onChange: onSelectInstall,
                                    formInputType: FormInputType.SELECT,
                                    isLoading: installLoading,
                                    options: installs.map((el) => ({
                                        label: `(${el.id}) ${el.customer.firstName} ${el.customer.lastName} - ${el.customer.address}`,
                                        value: el.id,
                                    })),
                                },
                                {
                                    name: "user",
                                    label: "Installer*",
                                    placeholder: "Start Typing to Search",
                                    required: "required",
                                    formInputType: FormInputType.SELECT,
                                    defaultValue: selectedBookableUser,
                                    options:
                                        bookableUsers
                                            ?.filter((el) => el.bookableTypes.includes(BookingType.INSTALL))
                                            .map((el) => {
                                                return {
                                                    label: `(${el.id}) ${el.user?.firstname} ${el.user?.lastname}`,
                                                    value: el.id,
                                                };
                                            }) ?? [],
                                },
                                //Add additional Select fields for capturing multiple selected users (Only if it's a new booking)
                                ...(!selectedBooking ? Array.from({ length: additionalSelects }).map((_, index) => ({
                                    name: `additionalSelect_${index}`,
                                    label: "Additional Installer",
                                    formInputType: FormInputType.SELECT,
                                    placeholder: "Start Typing to Search",
                                    options: bookableUsers
                                        ?.filter((el) => el.bookableTypes.includes(BookingType.INSTALL) && !el.user!.deletedAt)
                                        .map((el) => {
                                            return {
                                                label: `(${el.id}) ${el.user?.firstname} ${el.user?.lastname}`,
                                                value: el.id,
                                            };
                                        }) ?? [],
                                })) : []), {
                                    name: "confirmed",
                                    label: "Confirmed?",
                                    formInputType: FormInputType.CHECKBOX,
                                    defaultValue: selectedBooking?.confirmed !== undefined ? !!selectedBooking.confirmed : false,
                                },
                                {
                                    formInputType: FormInputType.TEXT,
                                    name: "notifyCustomer",
                                    defaultValue: selectedBooking?.notifyCustomer ? new Date(selectedBooking.notifyCustomer).toLocaleDateString() : undefined,
                                    label: "Confirmation Sent Date",
                                },
                                {
                                    formInputType: FormInputType.TEXTAREA,
                                    name: "notes",
                                    defaultValue: selectedBooking?.notes ? selectedBooking.notes : bookingNotes,
                                    label: "Notes",
                                },
                                {
                                    formInputType: FormInputType.TEXT,
                                    name: "footage",
                                    defaultValue: footage?.toString(),
                                    label: "Footage",
                                },
                                {
                                    formInputType: FormInputType.TEXT,
                                    name: "houseLevel",
                                    defaultValue: houseLevel?.toString(),
                                    label: "House Level",
                                },
                                {
                                    formInputType: FormInputType.TEXT,
                                    name: "typeOfLights",
                                    defaultValue: typeOfLights,
                                    label: "Type of Lights",
                                },
                            ]
                            : bookingType === BookingType.REPAIR || bookingType === BookingType.WARRANTY_REPAIR ?
                                [
                                    {
                                        name: "repairId",
                                        label: "Repair*",
                                        placeholder: "Start Typing to Search",
                                        required: "required",
                                        defaultValue: renderRepairFormValue(),
                                        onKeyDown: onEnterSearchRepair,
                                        onChange: onSelectRepair,
                                        formInputType: FormInputType.SELECT,
                                        isLoading: repairLoading,
                                        options: repairs.map((el) => {
                                            return {
                                                label: `(${el.id}) ${el.customer.firstName} ${el.customer.lastName} - ${el.customer.address}`,
                                                value: el.id,
                                            };
                                        }),
                                    },
                                    {
                                        name: "user",
                                        label: "Installer*",
                                        placeholder: "Start Typing to Search",
                                        required: "required",
                                        formInputType: FormInputType.SELECT,
                                        defaultValue: selectedBookableUser,
                                        options:
                                            bookableUsers
                                                ?.filter((el) => el.bookableTypes.includes(BookingType.REPAIR) && !el.user?.deletedAt)
                                                .map((el) => {
                                                    return {
                                                        label: `(${el.id}) ${el.user?.firstname} ${el.user?.lastname}`,
                                                        value: el.id,
                                                    };
                                                }) ?? [],
                                    },
                                    //Add additional Select fields for capturing multiple selected users (Only if it's a new booking)
                                    ...(!selectedBooking ? Array.from({ length: additionalSelects }).map((_, index) => ({
                                        name: `additionalSelect_${index}`,
                                        label: "Additional Installer",
                                        formInputType: FormInputType.SELECT,
                                        placeholder: "Start Typing to Search",
                                        options: bookableUsers
                                            ?.filter((el) => el.bookableTypes.includes(BookingType.REPAIR) && !el.user!.deletedAt)
                                            .map((el) => {
                                                return {
                                                    label: `(${el.id}) ${el.user?.firstname} ${el.user?.lastname}`,
                                                    value: el.id,
                                                };
                                            }) ?? [],
                                    })) : []), {
                                        name: "confirmed",
                                        label: "Confirmed?",
                                        formInputType: FormInputType.CHECKBOX,
                                        defaultValue: selectedBooking?.confirmed !== undefined ? !!selectedBooking.confirmed : false,
                                    },
                                    {
                                        formInputType: FormInputType.TEXT,
                                        name: "notifyCustomer",
                                        defaultValue: selectedBooking?.notifyCustomer ? new Date(selectedBooking.notifyCustomer).toLocaleDateString() : undefined,
                                        label: "Confirmation Sent Date",
                                    },
                                    {
                                        formInputType: FormInputType.TEXTAREA,
                                        name: "notes",
                                        defaultValue: selectedBooking?.notes ? selectedBooking.notes : bookingNotes,
                                        label: "Notes",
                                    },
                                ]
                                : bookingType === BookingType.BUFFER_DAY || bookingType === BookingType.TIME_OFF || bookingType === BookingType.NON_FIELD_WORK ? [
                                    {
                                        name: "user",
                                        label: "Installer*",
                                        placeholder: "Start Typing to Search",
                                        required: "required",
                                        formInputType: FormInputType.SELECT,
                                        defaultValue: selectedBookableUser,
                                        options:
                                            bookableUsers
                                                ?.filter((el) => el.bookableTypes.includes(BookingType.INSTALL) && !el.user?.deletedAt)
                                                .map((el) => {
                                                    return {
                                                        label: `(${el.id}) ${el.user?.firstname} ${el.user?.lastname}`,
                                                        value: el.id,
                                                    };
                                                }) ?? [],
                                    },
                                    //Add additional Select fields for capturing multiple selected users
                                    ...Array.from({ length: additionalSelects }).map((_, index) => ({
                                        name: `additionalSelect_${index}`,
                                        label: "Additional Installer",
                                        formInputType: FormInputType.SELECT,
                                        placeholder: "Start Typing to Search",
                                        options: bookableUsers
                                            ?.filter((el) => el.bookableTypes.includes(BookingType.INSTALL))
                                            .map((el) => {
                                                return {
                                                    label: `(${el.id}) ${el.user?.firstname} ${el.user?.lastname}`,
                                                    value: el.id,
                                                };
                                            }) ?? [],
                                    })),
                                ]
                                    : []
                    }
                />
            )}

            {footageModalOpen && (
                <FootageModal
                    bookingTitle={selectedBooking?.title!}
                    modalVisibility={footageModalOpen}
                    install={selectedBooking!.install!}
                    modalExit={closeFootageModal}
                    submitFootage={submitFootage}
                    serialNumber={serialNumber ? serialNumber : ''}
                    setSerialNumber={setSerialNumber}
                    ftInstalled={ftInstalled!}
                    setFtInstalled={setFtInstalled}
                    footageList={lightsInstalledList}
                    createLightsInstalled={createLightsInstalled}
                    deleteLightsInstalled={deleteLightsInstalled}
                    jobCompleted={jobCompleted}
                    setJobCompleted={setJobCompleted}
                />
            )}
        </Page>
    );
}
