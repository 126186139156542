import { BACKEND_URL } from "@frontend/environment";
import axios from "axios";
import { IBranchSalesGoal, ICreateBranchSalesGoal, int, IUpdateBranchSalesGoal } from '@common.abstractions'
import { Query, serializeQuery } from "@common.api";

export async function RetrieveAllGoals(query?: Query<IBranchSalesGoal>): Promise<IBranchSalesGoal[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/companies/salesGoal/all`, {
            params: query, withCredentials: true, paramsSerializer: (q: any) => serializeQuery(q),
        });
        if (response.status === 200) return response.data as IBranchSalesGoal[];
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveBranchGoals(companyId: int): Promise<IBranchSalesGoal[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/companies/${companyId}/salesGoals`, { withCredentials: true });
        if (response.status === 200) return response.data as IBranchSalesGoal[];
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function CreateBranchGoal(companyId: int, params: ICreateBranchSalesGoal): Promise<IBranchSalesGoal | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/companies/${companyId}/salesGoals`, params, { withCredentials: true });
        if (response.status === 201) return response.data as IBranchSalesGoal;
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteBranchGoal(goalId: int): Promise<boolean | undefined> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/companies/salesGoal/${goalId}`, { withCredentials: true });
        if (response.status === 200) return true;
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return false;
    }
}

export async function RetrieveGoalById(goalId: int): Promise<IBranchSalesGoal | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/companies/salesGoal/${goalId}`, { withCredentials: true })
        if (response.status === 200) return response.data as IBranchSalesGoal;
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function UpdateGoal(goalId: int, params: IUpdateBranchSalesGoal): Promise<IBranchSalesGoal | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/companies/salesGoal/${goalId}`, params, { withCredentials: true });
        if (response.status === 200) return response.data as IBranchSalesGoal
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function GoalProgress(companyId: int, timeframe: { startDate: Date, endDate: Date }): Promise<int | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/companies/:${companyId}/salesGoal/progress?startDate=${timeframe.startDate}&endDate=${timeframe.endDate}`, { withCredentials: true });
        if (response.status === 200) return response.data as int
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined
    }
}

export async function DaysSinceLastSale(companyId: int): Promise<number | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/companies/daysSinceLastSale/${companyId}`);
        if (response.status === 200) return response.data as number;
        return undefined
    }
    catch (err: any) {
        console.log(err);
        return undefined
    }
}

export async function RetrieveTotalFtSold(companyId: int, timeFrame: { startDate: Date, endDate: Date }): Promise<int | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/companies/${companyId}/totalFtSold?startDate=${timeFrame.startDate.toISOString()}&endDate=${timeFrame.endDate.toISOString()}`, { withCredentials: true });
        if (response.status === 200) return response.data as int;

        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}