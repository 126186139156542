import axios from 'axios';
import { BACKEND_URL } from '../environment';
import { tryDecodePaginationResponseData, tryEncodePaginationRequestData, IPaginatedResourceResponse, IPaginationRequest, Query, serializeQuery } from '@common.api';
import { Filters } from '@common.api';
import { int } from '@common.abstractions';
import { ICreateLeadParams, ILead } from '@common.abstractions';
import { getPaginationHeaders } from './helpers/pagination-helpers';

export async function CreateLead(params: ICreateLeadParams): Promise<ILead | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/leads`, params, { withCredentials: true });
        if (response.status === 201)
            return response.data as ILead;

        console.log("Failed to create lead: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Create lead request threw an exception: ', err);
        return undefined;
    }
}

export async function RetrieveLead(leadId: int): Promise<ILead | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/leads/${leadId}`, { withCredentials: true });
        if (response.status === 200)
            return response.data as ILead;
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteLead(leadId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/leads/${leadId}`, { withCredentials: true });
        if (response.status === 204)
            return true;
        return false;
    }
    catch (err: any) {
        console.log(err);
        return false;
    }
}

// export async function RetrieveLeads(query: Query<ILead>): Promise<ILead[] | undefined> {
//     try {
//         const response = await axios.get(`${BACKEND_URL}/leads`, { params: query, paramsSerializer: (val: any) => serializeQuery(val), withCredentials: true });
//         if (response.status === 200)
//             return (response.data as any[]).map(val => val as ILead);

//         console.log('Leads failed to load with response code: ', response.status);
//         return undefined;
//     }
//     catch (err: any) {
//         console.log('Loading leads threw an exception: ', err);
//         return undefined;
//     }
// }

export async function RetrieveLeads(query: Query<ILead>, pagination?: IPaginationRequest): Promise<IPaginatedResourceResponse<ILead> | undefined> {
    try {
        const response = await axios.get(
            `${BACKEND_URL}/leads`,
            {
                params: query,
                paramsSerializer: (val: any) => serializeQuery(val),
                headers: getPaginationHeaders(pagination),
                withCredentials: true
            });

        if (response.status === 200) {
            return {
                pagination: tryDecodePaginationResponseData(response.headers["pagination"]),
                values: response.data as ILead[]
            };
        }

        console.log('Leads failed to load with response code: ', response.status);
        return undefined;
    }
    catch (err: any) {
        console.log('Loading leads threw an exception: ', err);
        return undefined;
    }
}

export async function RetrieveLeadsCount(filters: Filters<ILead>): Promise<number | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/leads/count`, { params: { filters }, paramsSerializer: (val: any) => serializeQuery(val), withCredentials: true });
        if (response.status === 200) {
            return response.data;
        }

        console.log('Leads count failed to load with response code: ', response.status);
        return undefined;
    }
    catch (err: any) {
        console.log('Loading leads count threw an exception: ', err);
        return undefined;
    }
}


export async function MarkLeadViewed(leadId: int): Promise<boolean> {
    try {
        const response = await axios.put(`${BACKEND_URL}/leads/${leadId}/viewed`, null, { withCredentials: true });
        return (response.status === 200)
    }
    catch (err: any) {
        console.log('Marking lead viewed threw an exception: ', err);
        return false;
    };
}

export async function ManualMarkLeadConverted(leadId: int): Promise<boolean> {
    try {
        const response = await axios.put(`${BACKEND_URL}/leads/${leadId}/manualMarkConverted`, null, { withCredentials: true });
        return (response.status === 200)
    }
    catch (err: any) {
        console.log('Marking lead converted threw an exception: ', err);
        return false;
    };
}

export async function ManualMarkLeadLost(leadId: int): Promise<boolean> {
    try {
        const response = await axios.put(`${BACKEND_URL}/leads/${leadId}/manualMarkLost`, null, { withCredentials: true });
        return (response.status === 200)
    }
    catch (err: any) {
        console.log('Marking lead lost threw an exception: ', err);
        return false;
    };
}

export async function ManualRecoverLostLead(leadId: int): Promise<boolean> {
    try {
        const response = await axios.put(`${BACKEND_URL}/leads/${leadId}/manualRecoverLost`, null, { withCredentials: true });
        return (response.status === 200)
    }
    catch (err: any) {
        console.log('Recovering lost lead threw an exception: ', err);
        return false;
    };
}

export async function SendThirdPartyContract(leadId: int): Promise<boolean> {
    try {
        const response = await axios.post(`${BACKEND_URL}/leads/${leadId}/sendAcknowledgement`, null, { withCredentials: true });
        return response.status === 201;
    }
    catch (err: any) {
        console.log(err);
        return false;
    }
}