import axios from "axios";
import { BACKEND_URL } from "../environment";
import { Query, serializeQuery } from "@common.api";
import { ICreateProductImageParams, ICreateProductParams, ICreateProductVariantParams, int, IProduct, IProductCategory, IProductImage, IProductVariant, IShippingGroup } from "@common.abstractions";

export async function CreateProduct(productParams: ICreateProductParams, variants: ICreateProductVariantParams[], categoryIds: int[], shippingGroupId: int | null
    , productImage: ICreateProductImageParams | null): Promise<IProduct | undefined> {
    try {
        const params = { ...productParams, variants, categoryIds, shippingGroupId, productImage }
        const response = await axios.post(`${BACKEND_URL}/products`, params, { withCredentials: true });
        if (response.status === 201)
            return response.data as IProduct;

        console.log("Failed to save product with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Creating product threw an exception: ', err);
        return undefined;
    }
}

// export async function UpdateProduct(productId: int, params: IUpdateProductParams): Promise<Product | undefined> {
//     try {
//         const response = await axios.patch(`${BACKEND_URL}/products/${productId}`, params, { withCredentials: true });
//         if (response.status === 200)
//             return new Product(response.data);

//         console.log("Failed to update product with code: ", response.status, response.data);
//         return undefined;
//     }
//     catch (err: any) {
//         console.log('Updating product threw an exception: ', err);
//     }
// }

export async function ArchiveProduct(productId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/products/${productId}`, { withCredentials: true });
        return response.status === 204
    }
    catch (err: any) {
        console.log('Archiving product threw an exception: ', err);
        return false
    }
}

export async function RetrieveProduct(productId: int): Promise<IProduct | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/products/${productId}`, { withCredentials: true });
        if (response.status === 200)
            return response.data as IProduct;
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveProducts(query?: Query<IProduct>): Promise<IProduct[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/products`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200)
            return (response.data as any[]).map(val => val as IProduct);
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveShippingGroups(query?: Query<IShippingGroup>): Promise<IShippingGroup[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/products/shippingGroups`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200)
            return (response.data as any[]).map(val => val as IShippingGroup);
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveProductCategories(query?: Query<IProductCategory>): Promise<IProductCategory[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/products/categories`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200)
            return (response.data as any[]).map(val => val as IProductCategory);
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveProductVariants(query?: Query<IProductVariant>): Promise<IProductVariant[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/products/variants`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200)
            return (response.data as any[]).map(val => val as IProductVariant);
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

const defaultAxios = axios.create({headers: {}});
delete defaultAxios.defaults.headers.common["Authorization"];
export async function RetrieveProductImageBlob(imageId: number): Promise<any | undefined> {
    try {
        const getLinkRes = await axios.get(`${BACKEND_URL}/products/images/${imageId}/link`);
        if (getLinkRes.status !== 200) {
            console.log('Product image link failed to load with response code: ', getLinkRes.status);
            return undefined;
        }

        const link = getLinkRes.data.link;
        if (!link) {
            console.log('Product image link failed to load. No link in body: ', getLinkRes.data);
            return undefined;
        }

        const getImageBlob = await defaultAxios.get(link, { responseType: "blob" });
        if (getImageBlob.status !== 200) {
            console.log('Product image blob failed to load with response code: ', getLinkRes.status);
            return undefined;
        }

        return getImageBlob.data;
    }
    catch (err) {
        console.log('Loading product image threw an exception: ', err);
        return undefined;
    }
}