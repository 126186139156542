import { int } from "./primitives"


export interface ISquareEvent {
    header: object,
    body: object
}

export enum SquareBookingType {
    CREATE = "booking.created",
    UPDATE = "booking.updated"
}

export enum SquareBookingStatus {
    PENDING = "PENDING",
    CANCELLED_BY_CUSTOMER = "CANCELLED_BY_CUSTOMER",
    CANCELLED_BY_SELLER = "CANCELLED_BY_SELLER",
    DECLINED = "DECLINED",
    ACCEPTED = "ACCEPTED",
    NO_SHOW = "NO_SHOW"
}

export enum SquareTeamMemberStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE"
}

export interface ISquareBooking {
    id?: int,
    square_booking_id: string,
    source: string, 
    all_day: boolean,

    deleted_at?: Date | null,
    created_at?: Date,
    updated_at?: Date,
    start_at: Date,

    status: SquareBookingStatus,
    appointment_segments: ISquareAppointmentSegments[],
    
    location_id: string | null,
    location_type: string | null,

    creator_details: {
        creator_type: string,
        team_member_id: string,
    }
    transition_time_minutes: int,

    square_customer_id: string,    
}

export interface ISquareAppointmentSegments {
    team_member_id: string,
    any_team_member: boolean,
    duration_minutes: int,
    intermission_minutes: int,
    service_variation_id: string,
    service_variation_version: Number
}

export interface ISquareBookingEvent {
    event_id: string,
    merchant_id: string,
    location_id: string,
    type: SquareBookingType,
    created_at: Date,
    data: {
        id: string,
        type: string,
        object: {
            booking: ISquareEventBooking;
        }
    }
}

export interface ISquareEventBooking {
    id: string,
    source: string,
    status: SquareBookingStatus,
    all_day: boolean,

    start_at: Date,
    created_at?: Date,
    updated_at?: Date,

    customer_id: string,
    location_id: string,
    location_type: string,

    creator_details: {
        creator_type: string,
        team_member_id: string,
    }

    appointment_segments: ISquareAppointmentSegments[],
    transition_time_minutes: int  
}

export interface ISquareTeamMemberEvent {
    type: string,
    event_id: string,
    created_at: Date,
    data: {
        type: string,
        id: string,
        object: {
            team_member: ISquareEventTeamMember
        }
    }
}

export interface ISquareCustomerEvent {
    type: string,
    event_id: string,
    created_at: Date,
    data: {
        type: string,
        id: string,
        deleted?: boolean,
        object: {
            customer: ISquareEventCustomer
        }
    }
}

export interface ISquareEventCustomer {
    id: string,

    created_at?: Date,
    updated_at?: Date,

    given_name: string,
    family_name: string,
    nickname: string | null,
    company_name: string | null,
    email_address: string,
    address: ISquareAddress,
    phone_number: string,
    birthday: string | null, 
    note: string | null,
    preferences: {
        email_unsubscribed: boolean
    },
    group_ids: string[],
    segment_ids: string [] | null,
}

export interface ISquareTeamMember {
    id?: int, //this is the square id
    square_team_member_id: string,

    deleted_at?: Date | null,
    created_at?: Date,
    updated_at?: Date,

    status: SquareTeamMemberStatus,
    given_name: string,
    family_name: string,
    email_address: string,
    phone_number: string,
}

export interface ISquareEventTeamMember {
    id: string,

    created_at?: Date,
    updated_at?: Date,

    status: SquareTeamMemberStatus,
    given_name: string,
    family_name: string,
    email_address: string,
    phone_number: string,
}

export interface ISquareCatalogItem {
    id: int,
    square_catalog_item_id: string,
    type: string,

    is_deleted: boolean,
    created_at?: Date,
    updated_at?: Date,

    image_id: string | null,
    item_data: ISquareEventCatalogItemData | null,
}

export interface ISquareEventCatalogItem {
    id: string,
    type: string,

    is_deleted: boolean,
    created_at?: Date,
    updated_at?: Date,

    image_id: string | null,
    item_data: ISquareEventCatalogItemData,
    family_name: string,
    email_address: string,
    phone_number: string,
}

export interface ISquareEventCatalogItemData {
    name: string,
    is_taxable: boolean,
    visibility: string,
    tax_ids: string[],
    variations: ISquareEventCatalogVariation[],
    product_type: string,
}

export interface ISquareEventCatalogVariation{
    id: string,
    type: string,
    updated_at: Date,
    created_at: Date,
    is_deleted: false,
    item_variation_data: {
        item_id: string,
        name: string,
        pricing_type: string,
        price_money: {
            amount: int,
            currency: string
        },
    }
    
}

export interface ISquareAddress {
    address_line_1: string,
    address_line_2?: string,
    address_line_3?: string,
    locality: string,
    sublocality?: string,
    sublocality_2?: string,
    sublocality_3?: string,
    administrative_district_level_1: string,
    administrative_district_level_2?: string,
    administrative_district_level_3?: string,
    postal_code: string,
    country: string,
    first_name?: string,
    last_name?: string
}

export interface ISquareCustomer {
    id?: int,
    square_customer_id: string,

    deleted_at?: Date | null,
    created_at?: Date,
    updated_at?: Date,

    given_name: string | null,
    family_name: string | null,
    email_address: string | null,
    address: ISquareAddress,
    phone_number: string | null,
    preferences?: {
        email_unsubscribed: boolean
    },
    group_ids?: string[] | null,
    segment_ids?: string [] | null,
}