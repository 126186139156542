import React from "react";

type Props = {
    children: any;
    className?: string;
};

export function Page(props: Props) {
    const { children, className } = props;

    return (
        <div className={`w-full h-full max-w-full ${className ?? ""}`}>
            <div className="flex flex-col h-full max-w-full overflow-hidden">
                {children}
            </div>
        </div>
    )
}
