import React from "react";
import './_tabbar.scss';
import SummaryItem from "../summary/SummaryItem";

interface ITabProps {
    name: string;
    selected: boolean;
    onClick: () => void;
    actionBubble?: IBadgeParams;
    noValue?: boolean
}

function Tab(props: ITabProps) {
    function getBackgroundColor(badgeStatus: BadgeStatus) {
        switch (badgeStatus) {
            case BadgeStatus.Bad:
                return "red text";
            case BadgeStatus.Good:
                return "green text";
            case BadgeStatus.Info:
                return "blue text";
            case BadgeStatus.Warn:
                return "gold text";
        }
    }

    return (
         <SummaryItem 
                title={props.name} 
                value={props.actionBubble && props.actionBubble.count ? props.actionBubble.count : 0} 
                className={(props.selected ? "selected tab " : "tab ")}
                textColor={(props.actionBubble ? getBackgroundColor(props.actionBubble.status) : "")}
                onClick={() => props.onClick()}
                noValue={props.noValue}
            /> 
    );
}

{/*             {props.name}
            {props.actionBubble && props.actionBubble.count !== undefined && (
                <span
                    style={{
                        backgroundColor: getBackgroundColor(
                            props.actionBubble.status
                        ),
                    }}
                >
                    {props.actionBubble.count}
                </span> */}

export enum BadgeStatus {
    Good,
    Bad,
    Warn,
    Info,
}

export interface IBadgeParams {
    count?: number;
    status: BadgeStatus;
}

export interface ITabBarProps<T> {
    values: Array<[string, T, IBadgeParams?]>;
    selected: T;
    onTabClicked: (selection: T) => void;
    className?: string;
    style?: React.CSSProperties;
    noValue?: boolean;
}

export function TabBar<T>(props: ITabBarProps<T>) {
    return (
        <>
            {props.values.map((value) => {
                return (
                    <Tab
                        key={value[0]}
                        name={value[0]}
                        selected={value[1] === props.selected}
                        onClick={() => props.onTabClicked(value[1])}
                        actionBubble={value[2]}
                        noValue={props.noValue}
                    />
                );
            })}
        </>
    );
}
