import React from "react";

interface IProps {
    tabOptions?: {
        tabs: {
            name: string;
            quantity?: number;
            amount?: string | number;
        }[];
        active: string
        onClick: (name: string) => void;
    };
    children: any;
    className?: string;
}

export function TabBar2(props: IProps) {
    const { tabOptions, children, className } = props;

    return (
        <>
            {tabOptions && (
                <div className="w-full border-b border-gray-200 text-theme_gold flex items-end overflow-x-auto">
                    {tabOptions.tabs.map((tab, i) => (
                        <button
                            key={`tabOptions ${i}`}
                            type="button"
                            onClick={() => tabOptions.onClick(tab.name)}
                            className={`ml-2 whitespace-nowrap flex items-center px-6 rounded-tl-lg rounded-tr-lg outline-none focus:outline-none transform translate-y-1 ${tab.name === tabOptions.active ? "bg-white text-theme_gold py-3 border-gray-200 border-l border-t border-r" : "text-xs bg-gray-200 text-gray-500 py-2"
                                }`}
                        >
                            <div>{tab.name}</div>
                            {tab.quantity !== undefined && (
                                <div
                                    className={`ml-2 flex h-7 justify-center items-center text-xs rounded-full px-3 ${tab.name === tabOptions.active ? "bg-gray-200 text-gray-500" : "bg-gray-400 text-gray-100"
                                        }`}
                                >
                                    {tab.quantity}
                                </div>
                            )}
                            {tab.amount !== undefined && (
                                <div className={`ml-2 flex items-center text-xs ${tab.name === tabOptions.active ? "text-md" : "text-xs"}`}>{tab.amount}</div>
                            )}
                        </button>
                    ))}
                </div>
            )}
            <div className={`h-full w-full max-w-full overflow-auto relative ${className ? className : ""}`}>
                {children}
            </div>
        </>
    );
}
