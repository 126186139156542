import axios from 'axios';
import { BACKEND_URL } from '../environment';
import { Query, serializeQuery, Filters, IPaginationRequest, IPaginatedResourceResponse, tryDecodePaginationResponseData } from "@common.api";
import { ICreateQuoteRequestParams, int, IQuoteRequestFormCallParams, IUpdateQuoteRequestParams } from '@common.abstractions';
import { IQuoteRequest } from '@common.abstractions';
import { getPaginationHeaders } from './helpers/pagination-helpers';

export async function RetrieveQuoteRequest(quoteRequestId: int): Promise<IQuoteRequest | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/quoteRequests/${quoteRequestId}`, { withCredentials: true });
        if (response.status === 200)
            return response.data as IQuoteRequest;
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteQuoteRequest(quoteRequestId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/quoteRequests/${quoteRequestId}`, { withCredentials: true });
        if (response.status === 204)
            return true;
        return false;
    }
    catch (err: any) {
        console.log(err);
        return false;
    }
}

export async function CreateQuoteRequest(params: IQuoteRequestFormCallParams): Promise<IQuoteRequest | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/forms/quoteRequest/phone`, params, { withCredentials: true });
        if (response.status === 201) return response.data as IQuoteRequest;
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function UpdateQuoteRequest(quoteRequestId: int, params: IUpdateQuoteRequestParams): Promise<IQuoteRequest | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/quoteRequests/${quoteRequestId}`, params, { withCredentials: true });
        if (response.status === 200) return response.data as IQuoteRequest;
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveQuoteRequests(query?: Query<IQuoteRequest>, pagination?: IPaginationRequest): Promise<IPaginatedResourceResponse<IQuoteRequest> | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/quoteRequests`, {
            params: query,
            paramsSerializer: (val: any) => serializeQuery(val),
            headers: getPaginationHeaders(pagination),
            withCredentials: true
        });

        if (response.status === 200) {
            return {
                pagination: tryDecodePaginationResponseData(response.headers['pagination']),
                values: response.data as IQuoteRequest[]
            }
        }

        console.log('Quote requests failed to load with response code: ', response.status);
        return undefined;
    }
    catch (err: any) {
        console.log('Loading quote requests threw an exception: ', err);
        return undefined;
    }
}

export async function RetrieveQuoteRequestsByContactName(name: string, query?: Query<IQuoteRequest>): Promise<IQuoteRequest[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/quoteRequests/contacts/${name}`, { params: query, paramsSerializer: (val: any) => serializeQuery(val), withCredentials: true });
        if (response.status === 200)
            return response.data as IQuoteRequest[];

        console.log('Quote requests failed to load with response code: ', response.status);
        return undefined;
    }
    catch (err) {
        console.log('Loading quote requests threw an exception: ', err);
        return undefined;
    }
}

const defaultAxios = axios.create({headers: {}});
delete defaultAxios.defaults.headers.common["Authorization"];

export async function RetrieveQuoteRequestImageBlob(imageId: number): Promise<any | undefined> {
    try {
        const getLinkRes = await axios.get(`${BACKEND_URL}/quoteRequests/images/${imageId}/link`);
        if (getLinkRes.status !== 200) {
            console.log('Quote request image link failed to load with response code: ', getLinkRes.status);
            return undefined;
        }

        const link = getLinkRes.data.link;
        if (!link) {
            console.log('Quote request image link failed to load. No link in body: ', getLinkRes.data);
            return undefined;
        }

        const getImageBlob = await defaultAxios.get(link, { responseType: "blob" });
        if (getImageBlob.status !== 200) {
            console.log('Quote request image blob failed to load with response code: ', getLinkRes.status);
            return undefined;
        }

        return getImageBlob.data;
    }
    catch (err) {
        console.log('Loading quote request image threw an exception: ', err);
        return undefined;
    }
}

export async function RetrieveQuoteRequestsCount(filters: Filters<IQuoteRequest>): Promise<number | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/quoteRequests/count`, { params: { filters }, paramsSerializer: (val: any) => serializeQuery(val), withCredentials: true });
        if (response.status === 200) {
            return response.data;
        }

        console.log('Quote requests count failed to load with response code: ', response.status);
        return undefined;
    }
    catch (err: any) {
        console.log('Loading quote requests count threw an exception: ', err);
        return undefined;
    }
}