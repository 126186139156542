import { CompanyType, float, IMOQ, int, IOrder } from "@common.abstractions";
import { calcPercentage } from "@common.tools";
import { RetrieveOrders } from "@frontend/services/orders";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Between, Equals } from "@common.api";
import { RetrieveCompanies } from "@frontend/services/companies";
import { Loading } from "@frontend/components/misc/loading";

type Props = {
    timeFrame?: {
        start: Date;
        end: Date;
    };
    titleSubstrLength: number;
    filterEverlights: boolean;
    filterBestToWorst: boolean;
    previewSize?: number;
    detailedView?: boolean;
    onClick?: () => void;
};

export default function MOQ(props: Props) {
    const { timeFrame, titleSubstrLength, filterEverlights, filterBestToWorst, previewSize, detailedView, onClick } = props;

    const [dealerData, setDealerData] = useState<{
        totalFtPurchased: number | undefined;
        moq: float | undefined;
        companyName: string;
        companyType: CompanyType;
        companyId: int;
    }[]>();

    const [totals, setTotals] = useState<{ totalPurchased: number; totalMoq: number; totalPercent: number }>();

    const ordersMOQ = (order: IOrder) => {
        let totalMoq = 0
        order.items?.forEach((item) => {
            if (!item.productVariant?.product?.moqValue) return;

            totalMoq += int(item.quantity * item.productVariant.product.moqValue);
        })
        return totalMoq;
    }

    const dealers = async () => {
        try {
            const companies = await RetrieveCompanies({
                filters: {
                    companyType: Equals(CompanyType.DEALER),
                    suspended: Equals(false)
                },
                relations: ['packages', 'packages.orders']
            });
            if (!companies) return;

            // Map over the companies to create an array of dealer data
            const allData = await Promise.all(
                companies.map(async (dealer) => {

                    const tempLatestPackage = dealer.packages?.find(
                        (el) => new Date(el.startDate) < new Date() && new Date(el.endDate) > new Date()
                    );
                    const moq = tempLatestPackage?.moq;

                    const submittedOrders = await RetrieveOrders({
                        filters: {
                            submittedDate: Between(timeFrame!.start, timeFrame!.end),
                            lead: {
                                company: {
                                    id: Equals(dealer.id)
                                }
                            },
                        },
                        relations: ['lead', 'lead.company', 'items', 'items.productVariant', 'items.productVariant.product']
                    });

                    const totalFtPurchased = submittedOrders?.reduce((acc, order) => {
                        return acc + ordersMOQ(order);
                    }, 0);

                    // Create an object to store the dealer's data
                    return {
                        submittedOrders,
                        totalFtPurchased,
                        moq,
                        companyName: dealer.name,
                        companyType: dealer.companyType,
                        companyId: dealer.id
                    };
                })
            );

            // Sort dealerData by totalFtPurchased or companyName
            allData.sort((a, b) => {
                if (filterBestToWorst) {
                    if (a.totalFtPurchased! < b.totalFtPurchased!) {
                        return 1
                    }
                    if (a.totalFtPurchased! > b.totalFtPurchased!) {
                        return -1
                    }
                    return 0
                } else {
                    let x = a.companyName.toLowerCase();
                    let y = b.companyName.toLowerCase();

                    if (x < y) {
                        return -1
                    }
                    if (x > y) {
                        return 1
                    }
                    return 0
                }
            });

            setDealerData(allData);
            getTotals(allData)
        } catch (error) {
            console.log(error);
        }
    };

    const getTotals = (dealerData: {
        totalFtPurchased: number | undefined;
        moq: float | undefined;
        companyName: string;
        companyType: CompanyType;
        companyId: int;
    }[]) => {
        const totalPurchased = dealerData.reduce((acc, dealer) => acc + dealer.totalFtPurchased!, 0);
        const totalMoq = dealerData.reduce((acc, dealer) => {
            if (!dealer.moq) return acc;
            return acc + dealer.moq!;
        }, 0);

        const totalPercent = calcPercentage(totalPurchased, totalMoq);

        const totals = {
            totalPurchased,
            totalMoq,
            totalPercent
        }
        setTotals(totals)
    }

    useEffect(() => {
        dealers()
    }, [timeFrame, filterBestToWorst]);

    return !detailedView ? (
        <div onClick={onClick} className="p-5 shadow-md hover:shadow-lg duration-theme h-60 opacity-70 hover:opacity-100 cursor-pointer overflow-hidden">
            <div className="text-gray-500 mb-3">MOQ</div>
            <div className="flex items-end mb-4">
                <div className="text-3xl text-gray-700 font-bold">{totals?.totalPercent}%</div>
                <div className="text-xl text-gray-700 font-bold flex ml-5">
                    <div className="relative" style={{ minWidth: "30px" }}>
                        {totals?.totalPurchased}
                        <div className="absolute bottom-full text-xs">Total Purchased</div>
                    </div>
                    <div className="mx-3">/</div>
                    <div className="relative">
                        {totals?.totalMoq}
                        <div className="absolute bottom-full text-xs">MOQ</div>
                    </div>
                </div>
            </div>
            {dealerData ? (
                dealerData.map((el, i) => {
                    return (
                        <div key={el.companyId} className={`flex flex-col border-gray-200 ${i === dealerData.length - 1 ? "" : "border-b"}`}>
                            <div className="flex justify-between py-2">
                                <div className="text-gray-600">
                                    {el.companyName.length > titleSubstrLength ? el.companyName.substring(0, titleSubstrLength) + " . . ." : el.companyName}
                                </div>
                                <div className="flex">
                                    <div className="inline-flex items-center mr-3 font-semibold text-gray-700">
                                        {el.totalFtPurchased} /{el.moq}
                                    </div>
                                    <div className="inline-flex items-center w-14">
                                        <span className="ml-1 text-gray-500">{calcPercentage(el.totalFtPurchased!, el.moq!)}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })
            ) : (
                <Loading/>
            )}
        </div>
    ) : (
        <div className="h-full flex flex-col">
            <div className="text-gray-500 mb-3">MOQ</div>
            <div className="flex items-end mb-4">
                <div className="text-3xl text-gray-700 font-bold">{totals?.totalPercent}%</div>
                <div className="text-xl text-gray-700 font-bold flex ml-5">
                    <div className="relative" style={{ minWidth: "30px" }}>
                        {totals?.totalPurchased}
                        <div className="absolute bottom-full text-xs">Total Purchased</div>
                    </div>
                    <div className="mx-3">/</div>
                    <div className="relative">
                        {totals?.totalMoq}
                        <div className="absolute bottom-full text-xs">MOQ</div>
                    </div>
                </div>
            </div>
            {dealerData ? (
                <>
                    <div className="p-2 mb-4 flex border-b border-gray-300">
                        <div className="text-gray-600 text-lg w-80">Name
                        </div>
                        <div className="text-gray-600 text-lg flex-1">Type</div>
                        <div className="text-gray-600 text-lg flex-1">Ft Purchased</div>
                        <div className="text-gray-600 text-lg flex-1">MOQ </div>
                        <div className="text-gray-600 text-lg flex-1">% Complete</div>
                    </div>
                    <div className="flex flex-col overflow-y-scroll h-full">
                        {dealerData.map((el, i) => {
                            return (
                                <Link
                                    to={`/companies/${el.companyId}`}
                                    key={el.companyId}
                                    className={`flex flex-col hover:bg-gray-200 border-gray-100 duration-200 ${i === dealerData.length - 1 ? "" : "border-b"
                                        }`}
                                >
                                    <div className="flex justify-between p-2">
                                        <div className="text-gray-600 w-80">
                                            {el.companyName.length > titleSubstrLength
                                                ? el.companyName.substring(0, titleSubstrLength) + " . . ."
                                                : el.companyName}
                                        </div>
                                        <div className="text-gray-600 flex-1 capitalize">{el.companyType}</div>
                                        <div className="text-gray-600 flex-1 capitalize">{el.totalFtPurchased}</div>
                                        <div className="text-gray-600 flex-1 capitalize">{el.moq}</div>
                                        <div className="flex flex-1">
                                            <div className="inline-flex items-center mr-3 font-semibold text-gray-700">
                                                {el.totalFtPurchased}/{el.moq}
                                            </div>
                                            <div className="inline-flex items-center w-14">
                                                <span className="ml-1 text-gray-500">{calcPercentage(el.totalFtPurchased!, el.moq!)}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </>
            ) : (
                <Loading />
            )}
        </div>
    );
}
