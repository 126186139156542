import qs from "qs";
import { Filters } from "./filters";
import { Relations } from "./relations";
import { Sorts } from "./sorts";

export type Query<T extends object> = {
    filters?: Filters<T>;
    sorts?: Sorts<T>;
    relations?: Relations<T>;
}

export function serializeQuery<T extends object>(query: Query<T>) {
    const res: any = {};
    res.filters = query.filters;
    res.sorts = query.sorts;
    res.relations = query.relations;

    return qs.stringify(res);
}