import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { SetPassword, RetrieveUser } from "../../services/users";
import QueryString from "qs";
import { int, IUser } from "@common.abstractions";
import { formatPhoneNumber } from "@common.tools";
import { Loading } from "../misc/loading";
import LogoWithText from "../../assets/LogoWithText.png";
import AuthContext from "@frontend/contexts/AuthContext";
import { useNavigate, Navigate } from "react-router-dom-v5-compat";

export default () => {
    const [errMessage, setErrMessage] = useState<string>();
    const [user, setUser] = useState<IUser>();
    const [success, setSuccess] = useState(false);
    const params = useParams<{ userId: string }>();
    const [userId] = useState<int>(int.parse(params.userId));
    const location = useLocation();
    const navigate= useNavigate();
    const authContext = useContext(AuthContext);
    const {
        handleSubmit,
        getValues,
        register,

        formState: {
            errors,
        },
    } = useForm();

    const query = QueryString.parse(location.search, { ignoreQueryPrefix: true });

    const token = query.token as string | undefined;
    const loadUser = () => {
        RetrieveUser(userId, token).then((user) => {
            if (user) setUser(user);
            // TODO: Set some load error flag
        });
    };

    useEffect(loadUser, []);

    if (!token) {
        // TODO: Show some error message?
        return <Navigate to={`/`} />;
    }

    if (success) return <Navigate to={`/`} />;

    const submit = (values: any) => {
        const params = values;

        SetPassword(userId, token, params).then((user) => {
            if (!user) return setErrMessage("Failed");

            authContext?.login(user.email, params.password);

            setTimeout(() => {
                navigate("/");
            }, 200);
        });
    };

    if (!user) return <Loading center />;

    return (
        <div className="h-screen w-screen flex justify-center items-center">
            <div style={{ transform: "translateY(-10%)" }} className="flex-col justify-center items-center max-w-lg">
                <img className="max-w-full" src={LogoWithText} alt="Logo" />
                <form onSubmit={handleSubmit(submit)}>
                    {/* User Info */}
                    <div className="form_group flex-wrap">
                        <div className="flex flex-col items-center">
                            <label>Email</label>
                            <div className="text-gray-500 text-lg">{`${user.email}`}</div>
                        </div>
                        {/* <div className="flex flex-col items-center">
                            <label>Phone</label>
                            <div className="text-gray-500 text-lg">{formatPhoneNumber(user.phone)}</div>
                        </div> */}
                    </div>
                    <div className="form_group">
                        <div>
                            <label>Create Password</label>
                            <input
                                className={`my-3 form-control ${errors.password && "is-invalid"}`}
                                type="password"
                                placeholder="Password"
                                {...register('password', { required: "Password is required" })} />
                            {errors.password && <div className="text-theme_red">{errors.password.message?.toString()}</div>}
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label>Confirm Password</label>
                            <input
                                className={`my-3 form-control ${errors.confirmPassword && "is-invalid"}`}
                                type="password"
                                placeholder="Confirm Password"
                                {...register(
                                    'confirmPassword',
                                    { validate: (value) => value === getValues("password") || "Passwords don't match" },
                                )} />
                            {errors.confirmPassword && <div className="text-theme_red">{errors.confirmPassword.message?.toString()}</div>}
                        </div>
                    </div>

                    {errMessage && <div className="text-theme_red mb-5">{`${errMessage}`}</div>}

                    <button type="submit" className="btn bg-theme_blue text-white">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};
