import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { createJsxJsxClosingFragment } from "typescript";
import "./_modal.scss";

const Modal = (props: { top?: number; left?: number; visible: boolean; closeModal: () => void; children: any; className?: string }) => {
    const { top, left, visible, closeModal, children, className } = props;

    const myModalRef = useRef<any>(null);

    const [calculatedTop, setCalculatedTop] = useState<string | undefined>(top ? `${top}px` : undefined);
    const [calculatedLeft, setCalculatedLeft] = useState<string | undefined>(left ? `${left + 35}px` : undefined);

    const calculateTop = () => {
        if (myModalRef.current) {
            const rect = myModalRef.current.getBoundingClientRect();

            if ((rect.top + rect.height) >= window.innerHeight) {
                setCalculatedTop(top ? `${top - rect.height - 35}px` : undefined);
                return;
            }
        }
    };

    const calculateLeft = () => {
        if (myModalRef.current) {
            const rect = myModalRef.current.getBoundingClientRect();

            if ((rect.left + 600 + 15) >= window.innerWidth) {
                setCalculatedLeft(left ? `${left - 600 - 15}px` : undefined);
            } else {
                setCalculatedLeft(left ? `${left + 35}px` : undefined);
            }
        }
    };

    useEffect(() => {
        calculateTop();
        calculateLeft();
    }, [])

    if (!visible) {
        return null;
    } else {
        return (
            <>
                <div
                    ref={myModalRef}
                    className={`modal_foreground ${className} ${visible ? (top && left ? "show-slide" : "show") : ""} ${!top || !left ? "inset-center" : ""} rounded-3xl`}
                    // style={{ top: calculatedTop, left: calculatedLeft, position: "fixed" }}
                >
                    <div onClick={closeModal} className="absolute top-3 right-5">
                        <FontAwesomeIcon icon={faTimes} className="text-xl text-gray-400 hover:text-gray-500 duration-100 cursor-pointer" />
                    </div>
                    {children}
                </div>
                <div onClick={closeModal} className={`modal_background ${visible ? "show" : ""}`} />
            </>
        );
    }
};

export default Modal;
