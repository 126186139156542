import { Scope, ICompany, int, IUser } from "@common.abstractions";
import { Equals } from "@common.api";
import { formatPhoneNumber } from "@common.tools";
import AuthContext from "@frontend/contexts/AuthContext";
import { RetrieveCompanies } from "@frontend/services/companies";
import { ResendActivationLink, RetrieveUsers } from "@frontend/services/users";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import Select from "react-select";
import { toast } from "react-toastify";
import Button from "../misc/button/Button";
import { Page } from "../misc/layout";
import { Loading } from "../misc/loading";
import SearchBar from "../misc/search-bar/SearchBar";
import { Table, TableHeader, TableRow, TableRowWrapper } from "../misc/table";
import TopBar from "../misc/top-bar/TopBar";

export default function Users() {
    const authContext = useContext(AuthContext);
    const navigate= useNavigate();
    const [initialLoad, setInitialLoad] = useState<boolean>(false);
    const [users, setUsers] = useState<IUser[]>();
    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const [companies, setCompanies] = useState<ICompany[]>();
    const [filteredCompany, setFilteredCompany] = useState<{
        companyId: number;
    }>({ companyId: 0 });

    const loadUsers = async () => {
        const response = await RetrieveUsers({
            filters: {
                company: {
                    id: filteredCompany.companyId ? Equals(int(filteredCompany.companyId)) : undefined,
                },
            },
            relations: ["company"]
        });

        if (!response) {
            return;
        }
        setUsers(response);
        setInitialLoad(true);
    };

    const loadCompanies = async () => {
        if (!authContext?.hasAnyGrant(Scope.User.READ_ANY, Scope.Company.READ_ANY)) return;

        const response = await RetrieveCompanies();

        if (!response) {
            return;
        }

        setCompanies(response);
        setInitialLoad(true);
    };

    const resendActivationLink = async (userId: int) => {
        const response = await ResendActivationLink(userId);

        if (response) {
            toast("Activation email sent");
            return;
        }

        toast.error("Activation email could not send");
    };

    const searchFilter = (entity: IUser) => {
        const s = searchCriteria.toLowerCase();

        if (
            searchCriteria === "" ||
            (entity.firstname.toLowerCase() + " " + entity.lastname.toLowerCase()).includes(s) ||
            // entity.phone.includes(s) ||
            entity.email.toLowerCase().includes(s)
        ) {
            return true;
        }
    };

    useEffect(() => {
        loadUsers();
        loadCompanies();
    }, []);

    useEffect(() => {
        if (initialLoad) {
            loadUsers();
        }
    }, [filteredCompany]);

    return (
        <Page>
            <TopBar title="Users">
                <SearchBar className="ml-8 w-96" search={setSearchCriteria} placeholder="Search Users" />

                {authContext?.hasAnyGrant(Scope.User.READ_ANY, Scope.Company.READ_ANY) && (
                    <Select
                        className="ml-8 quotes_date_dropdown"
                        isLoading={!companies}
                        defaultValue={{
                            value: {
                                companyId: 0,
                            },
                            label: "All Companies",
                        }}
                        options={
                            companies
                                ? [
                                    {
                                        value: {
                                            companyId: 0,
                                        },
                                        label: "All Companies",
                                    },
                                    ...companies.map((el) => ({
                                        value: {
                                            companyId: el.id,
                                        },
                                        label: el.name,
                                    })),
                                ]
                                : undefined
                        }
                        onChange={(val) => setFilteredCompany(val!.value)}
                    />
                )}
            </TopBar>

            <Table>
                <TableHeader
                    topBorder
                    data={[
                        { title: "Created" },
                        { title: "Name" },
                        { title: "Email" },
                        { title: "Phone" },
                        { title: "Company" },
                        // { title: "Status" },
                        { title: "" },
                    ]}
                />
                <TableRowWrapper>
                    {users?.length
                        ? users.map(
                            (user, i) =>
                                searchFilter(user) && (
                                    <TableRow
                                        key={`user key ${i}`}
                                        data={[
                                            { text: { title: new Date(user.createdAt).toLocaleDateString() } },
                                            { text: { title: `${user.firstname} ${user.lastname}` } },
                                            { text: { title: user.email } },
                                            { text: { title: user.phone ? formatPhoneNumber(user.phone) : "N/A" } },
                                            { text: { title: user.company?.name ?? "" } },
                                            // {
                                            //     text: {
                                            //         title: authContext?.hasAnyGrant(Grant.User.UPDATE_ANY)
                                            //             ? user.vActivated
                                            //                 ? "Active"
                                            //                 : "Resend Activation Link"
                                            //             : "Not Activated",
                                            //         onClick: authContext?.hasAnyGrant(Grant.User.UPDATE_ANY)
                                            //             ? user.vActivated
                                            //                 ? undefined
                                            //                 : () => resendActivationLink(user.id)
                                            //             : undefined,
                                            //         color: authContext?.hasAnyGrant(Grant.User.UPDATE_ANY) ? (user.vActivated ? "gray" : "red") : "gray",
                                            //     },
                                            // },
                                            {
                                                text: {
                                                    title: "View User",
                                                    color: "blue",
                                                    onClick: () => navigate(`/users/${user.accountId}`),
                                                },
                                            },
                                        ]}
                                    />
                                )
                        )
                        : null}
                </TableRowWrapper>
            </Table>

            {users ? users.length ? null : <div className="text-gray-500 inset-center top-72">No users</div> : <Loading center />}
        </Page>
    );
}
