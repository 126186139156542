import { Scope, IUser } from "@common.abstractions";
import {
    clearTokens,
    getAuthorization,
    getTokenGrants,
    refreshAuthorization,
} from "@frontend/services/token-service";
import { RetrieveUser } from "@frontend/services/users";
import React from "react";

interface UserWithGrants extends IUser{
    grants: string[]
}

export interface IUserAuthContext {
    user?: UserWithGrants;
    checkLoginStatus(): Promise<{ success: boolean; user?: IUser; err?: string }>;
    login(email: string, password: string): Promise<{ success: boolean; user?: IUser; err?: string }>;
    logout(): Promise<{ success: boolean; err?: string }>;
    refreshUser(): void;
    hasAnyGrant(...grants: Scope[]): boolean;
    loading: boolean;
}

export class UserAuthContext implements IUserAuthContext {
    private setAuthContext: (context: IUserAuthContext) => void;
    public user?: UserWithGrants;
    public loading: boolean = false;

    constructor(setAuthContext: (context: IUserAuthContext) => void, user?: UserWithGrants) {
        this.setAuthContext = setAuthContext;
        this.user = user;
    }

    private setUser(user?: IUser & { grants: string[] }) {
        this.setAuthContext(new UserAuthContext(this.setAuthContext, user));
    }

    public async checkLoginStatus(): Promise<{ success: boolean; user?: IUser | undefined; err?: string | undefined }> {
        const grants = getTokenGrants();
        if (!grants) return { success: false, err: "No tokens found" };

        this.loading = true;

        const user = await RetrieveUser();

        this.loading = false;

        if (!user) {
            clearTokens();
            return { success: false, err: "Unable to retrieve user info" };
        }

        this.setUser({ ...user, grants });
        return { success: true, user };
    }

    public async login(
        email: string,
        password: string
    ): Promise<{ success: boolean; user?: IUser | undefined; err?: string | undefined }> {
        if (!(await getAuthorization(email, password))) {
            return { success: false, err: "Authorization failed" };
        }

        const grants = getTokenGrants();
        if (!grants) return { success: false, err: "No tokens found after login" };

        const user = await RetrieveUser();

        if (!user) {
            clearTokens();
            return { success: false, err: "Unable to retrieve user info" };
        }

        this.setUser({ ...user, grants });
        return { success: true, user };
    }

    public async logout(): Promise<{ success: boolean; err?: string | undefined }> {
        clearTokens();
        this.setUser(undefined);
        return { success: true };
    }

    public async refreshUser(): Promise<{ success: boolean; user?: IUser | undefined; err?: string | undefined }> {
        if (!(await refreshAuthorization())) return { success: false, err: "Failed to refresh auth" };

        const grants = getTokenGrants();
        if (!grants) return { success: false, err: "No tokens found" };

        const user = await RetrieveUser();

        if (!user) {
            clearTokens();
            return { success: false, err: "Unable to retrieve user info" };
        }

        this.setUser({ ...user, grants });
        return { success: true, user };
    }

    public hasAnyGrant(...grants: Scope[]): boolean {
        if (!this.user) return false;
        for (let grant of grants) if (this.user.grants.includes(grant.toLowerCase())) return true;
        return false;
    }
}

const AuthContext = React.createContext<IUserAuthContext | undefined>(undefined);

export default AuthContext;
