import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetPaymentMethodQuotes, SetPaymentMethod } from "@frontend/services/orders";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import "./_payment.scss";

import ReactLoading from "react-loading";
import { int, PaymentMethodOption, PaymentMethodQuote } from "@common.abstractions";
import { formatMoney } from "@common.tools";
import { Loading } from "@frontend/components/misc/loading";
import { Page } from "@frontend/components/misc/layout";
import TopBar from "@frontend/components/misc/top-bar/TopBar";

export default function Payment() {
    const params = useParams<{ orderId: string }>();
    const [orderId] = useState<int>(int.parse(params.orderId));
    const [paymentQuotes, setPaymentQuotes] = useState<PaymentMethodQuote[]>();
    const [paymentOptionSelected, setPaymentOptionSelected] = useState<boolean>(false);

    const [showNextButton, setShowNextButton] = useState<boolean>(false);
    const [settingPaymentOption, setSettingPaymentOption] = useState<boolean>(false);
    const [paymentOptionIndex, setPaymentOptionIndex] = useState<number>();

    const getPaymentQuotes = async () => {
        const tempPaymentQuotes = await GetPaymentMethodQuotes(orderId);

        if (tempPaymentQuotes) {
            setPaymentQuotes(tempPaymentQuotes);
            return;
        }

        alert("There are no shipping options for the products in your cart. Please contact us directly.");
    };
    const handlePaymentOptionChange = async (i: number) => {
        let paymentOption: PaymentMethodOption;
        setSettingPaymentOption(true);
        setShowNextButton(true);
        setPaymentOptionIndex(i);

        if (paymentQuotes) {
            if (paymentQuotes[i]) {
                paymentOption = {
                    name: paymentQuotes[i].name,
                    paymentMethodCode: paymentQuotes[i].paymentMethodCode,
                };

                const setPaymentRes = await SetPaymentMethod(orderId, paymentOption);

                if (setPaymentRes) {
                    setSettingPaymentOption(false);
                    return;
                }
                console.log("There was an error changing the shipping option");
            }
        }
    };

    useEffect(() => {
        getPaymentQuotes();
    }, []);

    return (
        <Page>
            <TopBar title="Payment Option" />
            <div className="flex flex-col items-center px-4">
                <div className="w-full max-w-lg">
                    <h2 className="text-lg font-semibold mb-4 text-center">Select a Payment Option</h2>
                    <div className="flex flex-col items-center">
                        {paymentQuotes ? (
                            paymentQuotes.length ? (
                                <div className="flex flex-col md:flex-row flex-wrap justify-center">
                                    {paymentQuotes.map((quote, index) => (
                                        <label
                                            key={quote.name}
                                            className={`p-4 border rounded m-2 text-center cursor-pointer transition-transform duration-200
                                    ${paymentOptionIndex === index ? "bg-orange-500 text-white transform translate-y-[-10px] scale-105" : "bg-white text-gray-600"}
                                    hover:bg-orange-500 hover:text-white hover:transform hover:translate-y-[-10px] hover:scale-105`}
                                            onClick={() => handlePaymentOptionChange(index)}
                                            aria-label={`Payment option ${quote.name}`}
                                        >
                                            <div>{quote.name}</div>
                                            <div>{`Convenience fee: ${formatMoney(quote.cost)}`}</div>
                                            <input
                                                className="mt-2"
                                                type="radio"
                                                name="paymentOption"
                                                checked={paymentOptionIndex === index}
                                                onChange={() => handlePaymentOptionChange(index)}
                                            />
                                        </label>
                                    ))}
                                </div>
                            ) : (
                                <div className="text-center text-gray-500">No Payment Options</div>
                            )
                        ) : (
                            <Loading />
                        )}
                    </div>
                </div>

                {showNextButton && (
                    settingPaymentOption ? (
                        <Loading center />
                    ) : (
                        <Link to={`/orders/${orderId}/checkout/`} className="mt-6 px-4 py-2 bg-blue-500 text-white rounded flex items-center">
                            Review Order
                            <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
                        </Link>
                    )
                )}
            </div>
        </Page>
    );
}
