import React, { useEffect } from "react";
import { Route, useLocation, Switch } from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import AuthContext from "../../contexts/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/_main.scss";

import LoggedInRoutes from "./LoggedInRoutes";

/*General*/
import { Login } from "../login";
import { Navbar } from "../navbar";

/*Admin*/

/* Dealer */
import { ActivationPage } from "../activation";
import { ForgotPassword, PasswordReset } from "../password-reset";
import loading = toast.loading;
import {Loading} from "@frontend/components/misc/loading";

const AppRoutes = () => {
    const authContext = React.useContext(AuthContext);
    const location = useLocation();

    if (!authContext) return <div className="inset-center gray-500">Internal Error</div>;

    if( authContext.loading) return <Loading center />;

    if (!authContext.user) {
        return (
            <>
                <Switch location={location} key={location.pathname}>
                    <Route exact path="/:userId/activate" component={ActivationPage} />
                    <Route exact path="/:userId/passwordReset" component={PasswordReset} />
                    <Route exact path="/forgotPassword" component={ForgotPassword} />
                    <Route exact path="*">
                        <Login onSubmit={(email, password) => authContext.login(email, password)} />
                    </Route>
                </Switch>

                <ToastContainer
                    position={"top-right"}
                    autoClose={2000}
                    hideProgressBar={false}
                    closeOnClick={false}
                    pauseOnFocusLoss={true}
                />
            </>
        );
    }

    return (
        <div className="wrapper">
            <Navbar logout={() => authContext.logout()} user={authContext.user} />
            <LoggedInRoutes />
            <ToastContainer
                position={"top-right"}
                autoClose={2000}
                hideProgressBar={false}
                closeOnClick={false}
                pauseOnFocusLoss={true}
            />
        </div>
    );
};

export default AppRoutes;
