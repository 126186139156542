import { time } from "console";

export const HoursIn12Format = (startHour: number, endHour: number) => {
    if (startHour >= endHour) return; //start hour can never be 24

    const hours: { time: string; value: number }[] = [];
    let hour = startHour;

    while (hour < endHour) {
        switch (hour) {
            case 0:
                hours.push({ time: "12 AM", value: 0 });
                break;
            case 12:
                hours.push({ time: "12 PM", value: 720 });
                break;
            default:
                hours.push({ time: (hour % 12).toString() + (hour > 11 ? " PM" : " AM"), value: hour * 60 });
        }

        hour++;
    }

	return hours;
};

export const TimesIn12Format = [
    { time: "12 AM", value: 0 },
    { time: "12:15 AM", value: 15 },
    { time: "12:30 AM", value: 30 },
    { time: "12:45 AM", value: 45 },
    { time: "1 AM", value: 60 },
    { time: "1:15 AM", value: 75 },
    { time: "1:30 AM", value: 90 },
    { time: "1:45 AM", value: 105 },
    { time: "2 AM", value: 120 },
    { time: "2:15 AM", value: 135 },
    { time: "2:30 AM", value: 150 },
    { time: "2:45 AM", value: 165 },
    { time: "3 AM", value: 180 },
    { time: "3:15 AM", value: 195 },
    { time: "3:30 AM", value: 210 },
    { time: "3:45 AM", value: 225 },
    { time: "4 AM", value: 240 },
    { time: "4:15 AM", value: 255 },
    { time: "4:30 AM", value: 270 },
    { time: "4:45 AM", value: 285 },
    { time: "5 AM", value: 300 },
    { time: "5:15 AM", value: 315 },
    { time: "5:30 AM", value: 330 },
    { time: "5:45 AM", value: 345 },
    { time: "6 AM", value: 360 },
    { time: "6:15 AM", value: 375 },
    { time: "6:30 AM", value: 390 },
    { time: "6:45 AM", value: 405 },
    { time: "7 AM", value: 420 },
    { time: "7:15 AM", value: 435 },
    { time: "7:30 AM", value: 450 },
    { time: "7:45 AM", value: 465 },
    { time: "8 AM", value: 480 },
    { time: "8:15 AM", value: 495 },
    { time: "8:30 AM", value: 510 },
    { time: "8:45 AM", value: 525 },
    { time: "9 AM", value: 540 },
    { time: "9:15 AM", value: 555 },
    { time: "9:30 AM", value: 570 },
    { time: "9:45 AM", value: 585 },
    { time: "10 AM", value: 600 },
    { time: "10:15 AM", value: 615 },
    { time: "10:30 AM", value: 630 },
    { time: "10:45 AM", value: 645 },
    { time: "11 AM", value: 660 },
    { time: "11:15 AM", value: 675 },
    { time: "11:30 AM", value: 690 },
    { time: "11:45 AM", value: 705 },
    { time: "12 PM", value: 720 },
    { time: "12:15 PM", value: 735 },
    { time: "12:30 PM", value: 750 },
    { time: "12:45 PM", value: 765 },
    { time: "1 PM", value: 780 },
    { time: "1:15 PM", value: 795 },
    { time: "1:30 PM", value: 810 },
    { time: "1:45 PM", value: 825 },
    { time: "2 PM", value: 840 },
    { time: "2:15 PM", value: 855 },
    { time: "2:30 PM", value: 870 },
    { time: "2:45 PM", value: 885 },
    { time: "3 PM", value: 900 },
    { time: "3:15 PM", value: 915 },
    { time: "3:30 PM", value: 930 },
    { time: "3:45 PM", value: 945 },
    { time: "4 PM", value: 960 },
    { time: "4:15 PM", value: 975 },
    { time: "4:30 PM", value: 990 },
    { time: "4:45 PM", value: 1005 },
    { time: "5 PM", value: 1020 },
    { time: "5:15 PM", value: 1035 },
    { time: "5:30 PM", value: 1050 },
    { time: "5:45 PM", value: 1065 },
    { time: "6 PM", value: 1080 },
    { time: "6:15 PM", value: 1095 },
    { time: "6:30 PM", value: 1110 },
    { time: "6:45 PM", value: 1125 },
    { time: "7 PM", value: 1140 },
    { time: "7:15 PM", value: 1155 },
    { time: "7:30 PM", value: 1170 },
    { time: "7:45 PM", value: 1185 },
    { time: "8 PM", value: 1200 },
    { time: "8:15 PM", value: 1215 },
    { time: "8:30 PM", value: 1230 },
    { time: "8:45 PM", value: 1245 },
    { time: "9 PM", value: 1260 },
    { time: "9:15 PM", value: 1275 },
    { time: "9:30 PM", value: 1290 },
    { time: "9:45 PM", value: 1305 },
    { time: "10 PM", value: 1320 },
    { time: "10:15 PM", value: 1335 },
    { time: "10:30 PM", value: 1350 },
    { time: "10:45 PM", value: 1365 },
    { time: "11 PM", value: 1380 },
    { time: "11:15 PM", value: 1395 },
    { time: "11:30 PM", value: 1410 },
    { time: "11:45 PM", value: 1425 },
];
