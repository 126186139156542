import React, { useContext, useEffect, useState } from 'react'
import CheckList from './CheckList'
import TopBar from '../misc/top-bar/TopBar'
import { AnswerType, IAnswerOptions, ICreateRepairCheckList, IQuestions, QuestionType, int } from '@common.abstractions'
import { useParams, useHistory } from 'react-router-dom'
import { CreateRepairCheckList, RetrieveQuestions } from '@frontend/services/post-install'
import { Equals } from '@common.api'
import { toast } from 'react-toastify'
import Select from 'react-select'
import AuthContext from '@frontend/contexts/AuthContext'

export default function RepairCheckList() {
    const history = useHistory()
    const authContext = useContext(AuthContext);

    const [questions, setQuestions] = useState<IQuestions[]>([])
    const { repairId } = useParams<{ repairId: string }>()
    const [userResponses, setUserResponses] = useState<{ question: string; answer: string }[]>([]);
    const [dropdownFreeFormResponses, setDropdownFreeFormResponses] = useState<{ [key: number]: string }>({});


    const loadQuestions = async () => {
        const response = await RetrieveQuestions({
            filters: {
                type: Equals(QuestionType.REPAIR),
            },
            relations: ['answerOptions']
        })
        if (!response) return [];
        response.sort((a, b) => a.id - b.id);

        setQuestions(response);
    }

    const handleDropdownFreeFormSelection = (questionId: int, selectedValue: string) => {
        setUserResponses((prevResponses) => {
            const selectedQuestion = questions.find((q) => q.id === questionId);

            // Remove any previous responses for the same question
            const updatedResponses = prevResponses.filter(response => response.question !== selectedQuestion?.text);

            const answer = selectedQuestion?.answerOptions?.find((option) => option.text === selectedValue);
            if (answer) {
                updatedResponses.push({
                    question: selectedQuestion!.text || '',
                    answer: answer.text,
                });
            }

            return updatedResponses;
        });

        setDropdownFreeFormResponses({
            ...dropdownFreeFormResponses,
            [questionId]: selectedValue,
        });
    }

    const handleDropdownSelection = (questionId: int, selectedValue: string) => {
        const selectedQuestion = questions.find((q) => q.id === questionId);

        setUserResponses((prevResponses) => {
            if (selectedQuestion) {
                const answer = selectedQuestion.answerOptions?.find((option) => option.text === selectedValue);
                if (answer) {
                    // Remove any previous responses for the same question
                    const updatedResponses = prevResponses.filter(response => {
                        return response.question !== selectedQuestion.text;
                    });

                    // Add the final response.
                    updatedResponses.push({
                        question: selectedQuestion.text,
                        answer: answer.text,
                    });

                    return updatedResponses;
                }
            }

            // For other values or if the answer is not found, no change in responses.
            return prevResponses;
        });
    }

    const handleFreeFormInput = (questionId: int, inputValue: string) => {
        setUserResponses((prevResponses) => {
            const selectedQuestion = questions.find((q) => q.id === questionId);

            if (selectedQuestion) {
                // Remove any previous responses for the same question
                const updatedResponses = prevResponses.filter(response => {
                    return response.question !== selectedQuestion.text;
                });

                // Add the final response.
                updatedResponses.push({
                    question: selectedQuestion.text,
                    answer: inputValue,
                });

                return updatedResponses;
            }

            // For other values, no change in responses.
            return prevResponses;
        });
    }

    const handleYesNoSelection = (questionId: int, selectedValue: string) => {
        setUserResponses((prevResponses) => {
            const updatedResponses = prevResponses.filter(response => {
                // Remove any previous responses for the same question
                return response.question !== questions.find(q => q.id === questionId)?.text;
            });

            updatedResponses.push({
                question: questions.find(q => q.id === questionId)?.text || '',
                answer: selectedValue,
            });

            return updatedResponses;
        });
    }


    const renderInput = (question: IQuestions) => {
        const containerStyle = 'w-full mb-2 flex flex-col items-center'

        switch (question.answerType) {
            case AnswerType.DD:
                return (
                    <div className={containerStyle} key={question.id} >
                        <Select
                            className='w-full sm:w-2/3 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                            onChange={(val) => handleDropdownSelection(question.id, val!.value)}
                            options={
                                question.answerOptions?.sort((a, b) => a.id - b.id)
                                    .map((option: IAnswerOptions) => ({
                                        value: option.text,
                                        label: option.text,
                                    }))
                            }
                        />
                    </div>
                );
            case AnswerType.DD_FF:
                return (
                    <div className={containerStyle}>
                        <Select
                            className='w-full sm:w-2/3 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                            onChange={(val) => {
                                const selectedValue = val?.value;
                                handleDropdownFreeFormSelection(question.id, selectedValue!);

                                if (selectedValue === 'Yes') {
                                    setUserResponses((prevResponses) => [
                                        ...prevResponses,
                                        {
                                            question: question.text,
                                            answer: 'Yes',
                                        },
                                    ]);
                                } else if (selectedValue === 'No') {
                                    const selectedQuestion = questions.find((q) => q.id === question.id);
                                    if (selectedQuestion) {
                                        const answer = selectedQuestion.answerOptions?.find((option) => option.text === selectedValue);
                                        if (answer) {
                                            setUserResponses((prevResponses) => [
                                                ...prevResponses,
                                                {
                                                    question: selectedQuestion.text,
                                                    answer: answer.text,
                                                },
                                            ]);
                                        }
                                    }
                                }
                            }}
                            options={[
                                {
                                    value: 'Yes',
                                    label: 'Yes',
                                },
                                {
                                    value: 'No',
                                    label: 'No',
                                }
                            ]}
                        />
                        {dropdownFreeFormResponses[question.id] === 'Yes' && question.id === 20 &&
                            <div className='w-full mt-2'>
                                <label className='text-sm block mb-1'>Did you show the customer the repaired and working lights?</label>
                                <input
                                    className='w-full px-3 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                                    type="text"
                                    onBlur={(e) => handleFreeFormInput(question.id, e.target.value)}
                                />
                            </div>}
                        {dropdownFreeFormResponses[question.id] === 'No' && question.id === 20 &&
                            <div className='w-full mt-2'>
                                <label className='text-sm block mb-1'>Did you speak with the customer on next steps before leaving?</label>
                                <input
                                    className='w-full px-3 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                                    type="text"
                                    onBlur={(e) => handleFreeFormInput(question.id, e.target.value)}
                                />
                            </div>}
                    </div>
                );
            case AnswerType.FF:
                return (
                    <div className={containerStyle} key={question.id}>
                        <input
                            className='w-full sm:w-2/3 px-3 py-2 border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                            type="text"
                            onBlur={(e) => handleFreeFormInput(question.id, e.target.value)}
                        />
                    </div>
                );
            case AnswerType.YN:
                return (
                    <div className={containerStyle} key={question.id} >
                        <Select
                            className='w-full sm:w-2/3 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
                            options={[
                                {
                                    value: 'Yes',
                                    label: 'Yes',
                                },
                                {
                                    value: 'No',
                                    label: 'No',
                                }
                            ]}
                            onChange={(val) => handleYesNoSelection(question.id, val!.value)}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    const submitResponse = async () => {
        const params: ICreateRepairCheckList = {
            repairId: int.parse(repairId),
            response: userResponses,
            repairTechId: authContext?.user?.accountId!
        };

        const response = await CreateRepairCheckList(params);
        if (!response) return toast.error('Failed to submit responses');
        toast.success('Responses submitted successfully');

        history.push('/calendar');
    }

    useEffect(() => {
        loadQuestions();
    }, [])

    return (
        <div className='w-full h-full flex flex-col'>
            <TopBar title='Repair Checklist' />
            <CheckList
                questions={questions}
                renderInput={renderInput}
                handleResponseSubmission={submitResponse}
            />
        </div>
    )
}
