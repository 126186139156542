import { CompanyType, IInstallsAnalytics, ITotalSpent } from "@common.abstractions";
import { calcPercentage, formatMoney, GetDate } from "@common.tools";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@frontend/components/misc/button/Button";
import { Loading } from "@frontend/components/misc/loading";
import SearchBar from "@frontend/components/misc/search-bar/SearchBar";
import { Table, TableHeader, TableRow, TableRowWrapper } from "@frontend/components/misc/table";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import { RetrieveInstallsAnalytics, RetrieveTotalSpent } from "@frontend/services/analytics";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { isTemplateLiteralTypeNode } from "typescript";

export default function BranchInstalls() {
    const [filteredInstallAnalytics, setFilteredInstallAnalytics] = useState<IInstallsAnalytics>();
    const [exporting, setExporting] = useState<boolean>(false);
    const [installAnalytics, setInstallAnalytics] = useState<IInstallsAnalytics>();
    const [searchCriteria, setSearchCriteria] = useState<string>("");

    const [timeFrame, setTimeFrame] = useState<{
        start: Date;
        end: Date;
    }>({ start: GetDate.january(), end: GetDate.nextJanuary() });

    const loadInstalls = async () => {
        let response = await RetrieveInstallsAnalytics(timeFrame);
        if (!response) {
            toast.error("Error loading Branch Installs");
        }

        setInstallAnalytics(response);
        setFilteredInstallAnalytics(response);
    };

    const exportInstalls = async () => {
        setExporting(true);
        let response = await RetrieveInstallsAnalytics(timeFrame);

        if (!response) return;

        let csv: string = "";

        //Create Header
        csv += "Branch,";

        csv += "Sales Tax (Q1),";
        csv += "Gross Revenue (Q1),";
        csv += "Num Sales (Q1),";

        csv += "Sales Tax (Q2),";
        csv += "Gross Revenue (Q2),";
        csv += "Num Sales (Q2),";

        csv += "Sales Tax (Q3),";
        csv += "Gross Revenue (Q3),";
        csv += "Num Sales (Q3),";

        csv += "Sales Tax (Q4),";
        csv += "Gross Revenue (Q4),";
        csv += "Num Sales (Q4),";

        csv += "Total Sales Tax,";
        csv += "Total Gross Revenue,";
        csv += "Total Num Sales\r\n";

        //Empty totals until you get to the last 3 columns
        csv += '"' + "Totals" + '",';

        csv += '"' + "" + '",';
        csv += '"' + "" + '",';
        csv += '"' + "" + '",';

        csv += '"' + "" + '",';
        csv += '"' + "" + '",';
        csv += '"' + "" + '",';

        csv += '"' + "" + '",';
        csv += '"' + "" + '",';
        csv += '"' + "" + '",';

        csv += '"' + "" + '",';
        csv += '"' + "" + '",';
        csv += '"' + "" + '",';

        csv += '"' + formatMoney(response.grandTotalSalesTax) + '",';
        csv += '"' + formatMoney(response.grandTotalGrossRevenue) + '",';
        csv += '"' + response.grandTotalNumInstalls + '"\r\n';

        //Body
        response.companies.map((company) => {
            csv += '"' + company.companyName + '",';

            csv += '"' + formatMoney(company.q1.salesTax) + '",';
            csv += '"' + formatMoney(company.q1.grossRevenue) + '",';
            csv += '"' + formatMoney(company.q1.numInstalls) + '",';

            csv += '"' + formatMoney(company.q2.salesTax) + '",';
            csv += '"' + formatMoney(company.q2.grossRevenue) + '",';
            csv += '"' + formatMoney(company.q2.numInstalls) + '",';

            csv += '"' + formatMoney(company.q3.salesTax) + '",';
            csv += '"' + formatMoney(company.q3.grossRevenue) + '",';
            csv += '"' + formatMoney(company.q3.numInstalls) + '",';

            csv += '"' + formatMoney(company.q4.salesTax) + '",';
            csv += '"' + formatMoney(company.q4.grossRevenue) + '",';
            csv += '"' + formatMoney(company.q4.numInstalls) + '",';

            csv += '"' + formatMoney(company.totalSalesTax) + '",';
            csv += '"' + formatMoney(company.totalGrossRevenue) + '",';
            csv += '"' + company.totalNumInstalls + '"\r\n';
        });

        let encodedCSV = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);

        let downloadLink = document.createElement("a");
        downloadLink.download = `Install_Pivot_Chart_${new Date().toLocaleDateString()}.csv`;
        downloadLink.href = encodedCSV;
        downloadLink.click();

        setExporting(false);
    };

    const searchFilter = (entity: string) => {
        const s = searchCriteria.toLowerCase();

        if (searchCriteria === "" || entity.toLowerCase().includes(s)) {
            return true;
        }
    };

    useEffect(() => {
        loadInstalls();
    }, [timeFrame]);

    return (
        <div className="w-full h-full max-w-full">
            <div className="flex flex-col h-full w-full max-w-full overflow-hidden">
                <TopBar title="Installations">
                    <Button className="mr-8 text-sm" type="button" onClick={exportInstalls} color="blue" style="outline" rounded>
                        Export CSV
                    </Button>
                    <Link to="/installs">
                        <Button className="mr-8 text-sm" type="button" color="blue" style="outline" rounded>
                            Itemized View
                        </Button>
                    </Link>
                    <SearchBar className="mr-8 w-96" search={setSearchCriteria} placeholder="Search Company" />
                    <Select
                        className="quotes_date_dropdown"
                        defaultValue={
                            {
                                value: {
                                    start: GetDate.january(),
                                    end: GetDate.tomorrow()
                                },
                                label: 'Year to Date'
                            }
                        }
                        options={
                            GetDate.years().map(el => (
                                {
                                    value: {
                                        start: el.start,
                                        end: el.end
                                    },
                                    label:  `Year - ${el.start.getFullYear()}`
                                }
                            ))
                        }
                        onChange={(val) => setTimeFrame(val!.value)}
                    />
                </TopBar>
                <Table>
                    <TableHeader
                        data={[
                            { title: "Branch" },
                            { title: "Sales Tax (Q1)" },
                            { title: "Gross Revenue (Q1)" },
                            { title: "Num Sales (Q1)" },
                            { title: "Sales Tax (Q2)" },
                            { title: "Gross Revenue (Q2)" },
                            { title: "Num Sales (Q2)" },
                            { title: "Sales Tax (Q3)" },
                            { title: "Gross Revenue (Q3)" },
                            { title: "Num Sales (Q3)" },
                            { title: "Sales Tax (Q4)" },
                            { title: "Gross Revenue (Q4)" },
                            { title: "Num Sales (Q4)" },
                            { title: "Total Sales Tax" },
                            { title: "Total Gross Revenue" },
                            { title: "Total Num Sales" },
                        ]}
                    />
                    <TableRowWrapper>
                        {filteredInstallAnalytics ? (
                            <TableRow
                                data={[
                                    { text: { title: "Totals" } },
                                    { text: { title: "" } },
                                    { text: { title: "" } },
                                    { text: { title: "" } },
                                    { text: { title: "" } },
                                    { text: { title: "" } },
                                    { text: { title: "" } },
                                    { text: { title: "" } },
                                    { text: { title: "" } },
                                    { text: { title: "" } },
                                    { text: { title: "" } },
                                    { text: { title: "" } },
                                    { text: { title: "" } },
                                    { text: { title: formatMoney(filteredInstallAnalytics.grandTotalSalesTax), color: "blue" } },
                                    { text: { title: formatMoney(filteredInstallAnalytics.grandTotalGrossRevenue), color: "blue" } },
                                    { text: { title: filteredInstallAnalytics.grandTotalNumInstalls, color: "blue" } },
                                ]}
                            />
                        ) : null}
                        {filteredInstallAnalytics?.companies.length
                            ? filteredInstallAnalytics.companies.map(
                                  (company, i) =>
                                      searchFilter(company.companyName) && (
                                          <TableRow
                                              key={`install key ${i}`}
                                              data={[
                                                  { text: { title: company.companyName } },
                                                  { text: { title: formatMoney(company.q1.salesTax) } },
                                                  { text: { title: formatMoney(company.q1.grossRevenue) } },
                                                  { text: { title: company.q1.numInstalls } },
                                                  { text: { title: formatMoney(company.q2.salesTax) } },
                                                  { text: { title: formatMoney(company.q2.grossRevenue) } },
                                                  { text: { title: company.q2.numInstalls } },
                                                  { text: { title: formatMoney(company.q3.salesTax) } },
                                                  { text: { title: formatMoney(company.q3.grossRevenue) } },
                                                  { text: { title: company.q3.numInstalls } },
                                                  { text: { title: formatMoney(company.q4.salesTax) } },
                                                  { text: { title: formatMoney(company.q4.grossRevenue) } },
                                                  { text: { title: company.q4.numInstalls } },
                                                  { text: { title: formatMoney(company.totalSalesTax), color: "gray" } },
                                                  { text: { title: formatMoney(company.totalGrossRevenue), color: "gray" } },
                                                  { text: { title: company.totalNumInstalls, color: "gray" } },
                                              ]}
                                          />
                                      )
                              )
                            : null}
                    </TableRowWrapper>
                </Table>
            </div>

            {exporting && <Loading fullscreen />}
        </div>
    );
}
