import axios from "axios";
import { BACKEND_URL } from "../environment";
import { ApiResponse } from "./api-response";

export async function RetrievePortalScopes(): Promise<ApiResponse<Array<{ id: number; moniker: string; description: string }>>> {
    try {
        const response = await axios.get(`${BACKEND_URL}/scopes`, { withCredentials: true });
        if (response.status === 200) return {success: true, data: response.data};
        return {success: false, statusCode: response.status, body: response.data};
    } catch (err: any) {
        console.log(err);
        return {success: false, error: err};
    }
}

