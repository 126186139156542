import React from 'react'
import { IQuestions } from '@common.abstractions'

export type CheckListProps = {
    questions: IQuestions[];
    renderInput: (question: IQuestions) => JSX.Element | null;
    handleResponseSubmission: () => void;
}

export default function CheckList(props: CheckListProps) {
    const { questions, renderInput, handleResponseSubmission } = props
    return (
        <div className='flex flex-col justify-center items-center flex-1 overflow-y-auto px-2 py-4 md:p-4'>
            <div className="w-full max-w-lg mx-auto bg-white shadow-md rounded-lg p-4 md:p-6 overflow-y-auto">
                {questions.map((question, index) => (
                    <div key={question.id} className={`mb-4 p-4 ${index % 2 === 0 ? 'bg-blue-100' : 'bg-gray-100'} rounded-md`}>
                        <label className='block text-sm md:text-md font-semibold mb-2'>{question.text}</label>
                        {renderInput(question)}
                    </div>
                ))}
                <button
                    type="button"
                    onClick={handleResponseSubmission}
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mt-4 w-full transition duration-300"
                >
                    Submit
                </button>
            </div>
        </div>

    )
}
