import { BACKEND_URL } from "@frontend/environment";
import axios from "axios";
import { ICreateWarrantyItemParams, int, IUpdateWarrantyItem, IUpdateWarrantyParams, IWarranty, IWarrantyItem } from '@common.abstractions'
import { Query, serializeQuery } from '@common.api';

export async function CreateWarranty(params: { leadId: int }): Promise<IWarranty | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/warranty`, params, { withCredentials: true })
        if (response.status === 201) return response.data as IWarranty;

        console.log('Failed to create warranty with code', response.status, response.data)
        return undefined;
    }
    catch (err: any) {
        console.log('Creating warranty threw an exeption ', err);
        return undefined;
    }
}

export async function RetrieveWarrantyDocs(query?: Query<IWarranty>): Promise<IWarranty[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/warranty`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true })
        if (response.status === 200) return response.data as IWarranty[];

        console.log('Failed to retrieve warranties', response.status, response.data);
        return undefined;

    }
    catch (err: any) {
        console.log('Retrieving warranties threw an exception: ', err);
        return undefined;
    }
}

export async function RetrieveWarrantyById(warrantyId: int): Promise<IWarranty | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/warranty/${warrantyId}`, { withCredentials: true })
        if (response.status === 200) return response.data as IWarranty;

        console.log('Failed to retrieve warranty', response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Retrieving warranty threw an exception: ', err);
        return undefined;
    }
}

export async function UpdateWarranty(warrantyId: int, params: IUpdateWarrantyParams): Promise<IWarranty | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/warranty/${warrantyId}`, params, { withCredentials: true });
        if (response.status === 200) return response.data as IWarranty;

        console.log('Failed to update warranty with code: ', response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Updating warranty threw an exception: ', err)
    }
}

export async function CreateWarrantyItem(warrantyId: int, params: ICreateWarrantyItemParams): Promise<IWarrantyItem | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/warranty/${warrantyId}/items`, params, { withCredentials: true });
        if (response.status === 201)
            return response.data as IWarrantyItem;

        console.log("Failed to create warranty item with code: ", response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Creating warranty item threw an exception: ', err);
        return undefined;
    }
}

export async function DeleteWarrantyItem(itemId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/warranty/items/${itemId}`, { withCredentials: true });
        return response.status === 200
    }
    catch (err: any) {
        console.log('Updating warranty item threw an exception: ', err)
        return false;
    }
}

export async function UpdateWarrantyItem(itemId: int, params: IUpdateWarrantyItem): Promise<IWarrantyItem | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/warranty/items/${itemId}`, params, { withCredentials: true });
        if (response.status === 200) return response.data as IWarrantyItem

        console.log('Failed to update warranty item with code: ', response.status, response.data);
        return undefined
    }
    catch (err: any) {
        console.log('Updating warranty item threw an exception: ', err)
    }
}

export async function SendWarrantyEmail(warrantyId: int): Promise<boolean> {
    try {
        const response = await axios.post(`${BACKEND_URL}/warranty/${warrantyId}/email`, null, { withCredentials: true });
        return response.status === 201;
    }
    catch (err: any) {
        console.log(err);
        return false;
    }
}