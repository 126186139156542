import axios from "axios";
import { BACKEND_URL } from "../environment";
import { ApiResponse } from "./api-response";

export async function SendSms(
    recipients: Array<string>,
    message: string,
    departmentId?: string,
    userId?: string,
): Promise<ApiResponse<{ succeeded: Array<string>; failed: Array<string> }>> {
    try {
        const response = await axios.post(`${BACKEND_URL}/sms`, { recipients, message, departmentId, userId }, { withCredentials: true });
        if (response.status === 200) return {success: true, data: response.data};
        return {success: false, statusCode: response.status, body: response.data};
    } catch (err: any) {
        console.log(err);
        return {success: false, error: err};
    }
}

export async function getDepartments(): Promise<ApiResponse<Array<{ name: string; id: string }>>> {
    try {
        const response = await axios.get(`${BACKEND_URL}/sms/departments`, { withCredentials: true });

        if (response.status === 200) return {success: true, data: response.data};
        return {success: false, statusCode: response.status, body: response.data};
    } catch (err: any) {
        console.log(err);
        return {success: false, error: err};
    }
}

export async function getUsers(departmentId?: string): Promise<ApiResponse<Array<{ name: string; id: string }> | undefined>> {
    try {
        if(!departmentId || departmentId === "0") {
            return { success: true, data: undefined };
        }

        const deptId = departmentId ?? "0";
        const response = await axios.get(`${BACKEND_URL}/sms/users`, { params: { departmentId: deptId}, withCredentials: true });

        if (response.status === 200) return {success: true, data: response.data};
        return {success: false, statusCode: response.status, body: response.data};
    } catch (err: any) {
        console.log(err);
        return {success: false, error: err};
    }
}

