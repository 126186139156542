import { Modal } from "@frontend/components/misc/modal";
import { IInstall, int } from "@common.abstractions";
import Button from "@frontend/components/misc/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

type FootageModalProps = {
    install: IInstall;
    submitFootage: () => void;
    modalExit: () => void;
    modalVisibility: boolean;

    serialNumber: string;
    setSerialNumber: (serialNumber: string | undefined) => void;
    ftInstalled: int;
    setFtInstalled: (ft: int) => void;
    createLightsInstalled: (serialNumber: string | null, ft: int) => void;
    footageList: { serialNumber: string | null, ft: int }[];
    deleteLightsInstalled: (lightsInstall: {
        ft: int;
        serialNumber: string | null;
    }) => void;
    jobCompleted: boolean;
    setJobCompleted: (jobCompleted: boolean) => void;
    bookingTitle: string;
}

export default function FootageModal(props: FootageModalProps) {
    const {
        install, submitFootage, modalExit, modalVisibility,
        serialNumber, setSerialNumber, ftInstalled, setFtInstalled,
        createLightsInstalled, footageList, deleteLightsInstalled, jobCompleted,
        setJobCompleted, bookingTitle
    } = props;

    return (
        <Modal visible={modalVisibility} closeModal={modalExit}>
            <div className="p-4 max-w-md mx-auto">
                <h1 className="text-md font-semibold mb-4 text-center">{bookingTitle}</h1>
                <div className="flex flex-wrap justify-between mb-4">
                    <div className="flex flex-col mb-2 md:mb-0 md:mr-2">
                        <label className="mb-1 text-sm">SN</label>
                        <input
                            className="p-1 text-sm border border-gray-300 rounded w-full"
                            value={serialNumber}
                            onChange={(e) => setSerialNumber(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col mb-2 md:mb-0 md:mr-2">
                        <label className="mb-1 text-sm">ft</label>
                        <input
                            className="p-1 text-sm border border-gray-300 rounded w-full"
                            value={ftInstalled}
                            onChange={(e) => setFtInstalled(int.parse(e.target.value) || int(0))}
                        />
                    </div>
                    <button className="p-2 text-sm bg-blue-500 text-white rounded w-full md:w-auto" onClick={() => createLightsInstalled(serialNumber, int.parse(ftInstalled) || int(0))}>
                        <FontAwesomeIcon icon={faPlus} size="xs" />
                    </button>
                </div>

                {footageList?.map((list, index) => (
                    <div key={index} className="flex flex-col md:flex-row justify-between items-center mb-2">
                        <div className="flex flex-row justify-start items-center mb-2 md:mb-0 md:mr-4">
                            <label className="w-1/3 md:w-auto text-sm font-bold mr-2">SN:</label>
                            <input className="p-1 text-sm border border-gray-300 rounded w-2/3 md:w-auto" value={list.serialNumber || ''} readOnly />
                        </div>
                        <div className="flex flex-row justify-start items-center mb-2 md:mb-0 md:mr-4">
                            <label className="w-1/3 md:w-auto text-sm font-bold mr-2">ft:</label>
                            <input className="p-1 text-sm border border-gray-300 rounded w-2/3 md:w-auto" value={list.ft} readOnly />
                        </div>
                        <button className="p-2 bg-red-500 text-white rounded" onClick={() => deleteLightsInstalled(list)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                ))}

                {footageList.length ? (
                    <div className="flex flex-col md:flex-row justify-center items-center mt-4 mb-4">
                        <div className="flex items-center mb-2 md:mb-0 md:mr-4">
                            <label className="font-bold text-sm mr-2">Job Completed</label>
                            <input type="checkbox" className="transform scale-110" onChange={(e) => setJobCompleted(e.target.checked)} />
                        </div>
                        <Button type='button' onClick={submitFootage} style="soft" color="yellow">
                            Submit
                        </Button>
                    </div>
                ) : null}
            </div>
        </Modal>
    );
}
