import React, { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { IFileUploader, FileUploader } from "../../../lib/uploader";
import { CreateManualLead, CreateQuoteRequestImage } from "../../../services/upload-quote-requests";
import { ImageUpload } from "./ImageUpload";
import { removeEmpty, USStates } from "@common.tools";
import { toast } from "react-toastify";
import { BuildingType, ProductLine } from "@common.abstractions";
import { getStateCodeByStateName, sanitizeStateCode } from "us-state-codes";
import { ICreateManualLeadParams } from "@common.api";
import AuthContext from "@frontend/contexts/AuthContext";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import { useNavigate } from "react-router-dom-v5-compat";

export default function CreateLead() {
    const {
        handleSubmit,
        register,
        getValues,
        setError,
        reset,

        formState: {
            errors,
        },
    } = useForm({ mode: "onChange" });
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [imageUploadsState, setImageUploadsState] = useState<{ uploader: IFileUploader; file: File }[]>([]);

    const imageUploadsRef = useRef<Array<{ uploader: IFileUploader; file: File }>>([]);

    const [submitting, setSubmitting] = useState(false);
    const [totalUploadPercent, setTotalUploadPercent] = useState(0);

    const hiddenFileInput = React.useRef<HTMLInputElement>(null);

    const [waitingForUploads, setWaitingForUploads] = React.useState(false);

    const supportedFileTypes = ["image/jpeg", "image/png", "image/gif"];

    function addImageUpload(imageUpload: { uploader: IFileUploader; file: File }) {
        imageUploadsRef.current.push(imageUpload);
        setImageUploadsState([...imageUploadsRef.current]);
    }

    function removeImageUpload(imageUpload: { uploader: IFileUploader; file: File }) {
        imageUploadsRef.current.splice(imageUploadsRef.current.indexOf(imageUpload), 1);
        setImageUploadsState([...imageUploadsRef.current]);
    }

    const compareFiles = (a: File, b: File) => a.name === b.name && a.size === b.size && a.type === b.type;

    const imageSelectedHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const rawFiles = Array.from(event.target.files);
            event.target.value = "";
            const files = rawFiles
                .filter((file) => supportedFileTypes.includes(file.type))
                .filter((file) => !imageUploadsRef.current.filter((upload) => compareFiles(file, upload.file)).length) // No duplicates
                .filter((file) => file.size <= 3000000);

            const bigFiles = rawFiles.filter((file) => file.size > 3000000);
            bigFiles.forEach((file) => toast.warn(`File: ${file.name} is too big!`, { autoClose: 5000 }));

            const unsupportedFiles = rawFiles.filter((file) => !supportedFileTypes.includes(file.type));
            unsupportedFiles.forEach((file) => toast.warn(`File: ${file.name} is not a supported file type.`, { autoClose: 5000 }));

            const uploads = files.map(async (file) => {
                const upload = await CreateQuoteRequestImage(file.name);
                if (!upload) return undefined; // TODO: Handle failure
                const uploader = new FileUploader(upload.id, file, upload.uploadUrl);
                return { uploader, file };
            });

            const confirmeduploads = (await Promise.all(uploads)).filter((val) => val !== undefined) as {
                uploader: FileUploader;
                file: File;
            }[];

            confirmeduploads.forEach((upload) => {
                upload.uploader.start();
                addImageUpload(upload);
                upload.uploader.addOnCancelledListener(() => removeImageUpload(upload));
            });
        }
    };

    function updateTotalPercent(uploads: Array<IFileUploader>) {
        const numUploads = uploads.length;
        const totalPercent = uploads.map((upload) => upload.uploadPercent).reduce((a, b) => a + b, 0);
        setTotalUploadPercent(totalPercent / numUploads);
        return totalPercent / numUploads;
    }

    function getStateCode(value: string) {
        const sanitizedStateCode = sanitizeStateCode(value);
        if (sanitizedStateCode) return sanitizedStateCode;

        const convertedStateCode = getStateCodeByStateName(value);
        return convertedStateCode;
    }

    const submit = (values: any) => {
        try {
            const images = imageUploadsState.map((upload) => upload.uploader);
            updateTotalPercent(images);
            if (images.filter((val) => val.inProgress).length !== 0) setWaitingForUploads(true);
            setSubmitting(true);
            values = {
                ...values,
                propertyImages: images.filter((img) => !img.cancelled).map((val) => val.id),
                country: "USA",
            };
            console.log("uncleaned: ", values);
            removeEmpty(values);
            values.phone = values.phone.match(/\d+/g).join("");

            const stateCode = sanitizeStateCode(values.state);

            const validatedState = getStateCode(values.state);
            if (!validatedState) {
                setError("state", { type: "manual", message: "State is invalid" });
                setSubmitting(false);
                return;
            }

            if (images.some((val) => !val.completed)) {
                toast.error("Images still uploading");
                return;
            }

            values.state = validatedState;

            const params: ICreateManualLeadParams = {
                quoteRequest: {
                    additionalInfo: values.additionalInfo,
                    buildingType: values.buildingType,
                    address: {
                        city: values.city,
                        country: values.country,
                        geoPoint: null,
                        state: values.state,
                        streetAddress: values.streetAddress,
                        zip: values.zip,
                    },
                    contact: {
                        email: values.email,
                        firstname: values.firstname,
                        lastname: values.lastname,
                        phone: values.phone,
                    },
                    imageIds: values.propertyImages,
                    productLines: values.productLines,
                    quotedAreas: values.quotedAreas,
                },
                companyId: authContext!.user!.company!.id,
            };
            console.log("validated: ", params);

            CreateManualLead(params)
                .then((lead) => {
                    if (lead) {
                        navigate(`/leads/${lead.id}`, { replace: true });
                    }
                })
                .catch((err) => {
                    toast.error("Submission failed! Please try again.");
                });
        } catch (err: any) {
            toast.error(`Submission failed! Error: ${err}`, { autoClose: 10000 });
        }
    };


    return (
        <div className="flex flex-col h-full w-full px-4 overflow-y-auto overflow-x-hidden">
            <TopBar title="New Lead" />
            <div className="flex flex-col w-full h-full items-center">
                <div className="flex flex-col md:flex-row w-full mb-2 flex-wrap">
                    <div className="flex-1 flex flex-col md:mr-5 mb-2 md:mb-0">
                        <label className="text-gray-500" htmlFor="firstname">
                            First name
                        </label>
                        <input
                            id="firstname"
                            placeholder="required"
                            className={`input ${errors.firstname && "border-red-500 b-1"}`}
                            type="text"
                            {...register('firstname', { required: true })}
                            autoComplete="given-name" />
                        {errors.firstname && errors.firstname.type === "required" && (
                            <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>
                        )}
                    </div>

                    <div className="flex-1 flex flex-col">
                        <label className="text-gray-500" htmlFor="lastname">
                            Last name
                        </label>
                        <input
                            id="lastname"
                            placeholder="required"
                            className={`input ${errors.lastname && "border-red-500 b-1"}`}
                            type="text"
                            {...register('lastname', { required: true })}
                            autoComplete="family-name" />
                        {errors.lastname && errors.lastname.type === "required" && (
                            <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>
                        )}
                    </div>
                </div>

                <div className="flex flex-col md:flex-row w-full mb-2 flex-wrap">
                    <div className="flex-1 flex flex-col md:mr-5 mb-2 md:mb-0">
                        <label className="text-gray-500" htmlFor="phone">
                            Phone number
                        </label>
                        <input
                            id="phone"
                            placeholder="required"
                            className={`input ${errors.phone && "border-red-500 b-1"}`}
                            type="tel"
                            {...register('phone', {
                                required: true,
                                validate: (val) => val?.match(/\d+/g)?.join("")?.length === 10,
                            })}
                            autoComplete="tel" />
                        {errors.phone && errors.phone.type === "required" && (
                            <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>
                        )}
                        {errors.phone && errors.phone.type === "validate" && (
                            <div className="text-red-500 text-sm mt-1 ml-1">Phone number is invalid</div>
                        )}
                    </div>

                    <div className="flex-1 flex flex-col">
                        <label className="text-gray-500" htmlFor="email">
                            Email
                        </label>
                        <input
                            id="email"
                            placeholder="required"
                            className={`input ${errors.email && "border-red-500 b-1"}`}
                            type="email"
                            {...register('email', {
                                required: true,
                                pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            })}
                            autoComplete="email" />
                        {errors.email && errors.email.type === "required" && (
                            <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>
                        )}
                        {errors.email && errors.email.type === "pattern" && (
                            <div className="text-red-500 text-sm mt-1 ml-1">Email address is invalid</div>
                        )}
                    </div>
                </div>

                <div className="flex flex-col md:flex-row w-full mb-2 flex-wrap">
                    <div className="flex-1 flex flex-col md:mr-5 mb-2 md:mb-0">
                        <label className="text-gray-500" htmlFor="streetAddress">
                            Street address
                        </label>
                        <input
                            id="streetAddress"
                            placeholder="required"
                            className={`input ${errors.streetAddress && "border-red-500 b-1"}`}
                            type="text"
                            {...register('streetAddress', { required: true })}
                            autoComplete="street-address" />
                        {errors.streetAddress && errors.streetAddress.type === "required" && (
                            <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>
                        )}
                    </div>

                    <div className="flex-1 flex flex-col">
                        <label className="text-gray-500" htmlFor="city">
                            City
                        </label>
                        <input
                            id="city"
                            placeholder="required"
                            className={`input ${errors.city && "border-red-500 b-1"}`}
                            type="text"
                            {...register('city', { required: true })}
                            autoComplete="address-level2" />
                        {errors.city && errors.city.type === "required" && (
                            <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>
                        )}
                    </div>
                </div>

                <div className="flex flex-col md:flex-row w-full mb-8 flex-wrap">
                    <div className="flex-1 flex flex-col md:mr-5 mb-2 md:mb-0">
                        <label className="text-gray-500" htmlFor="state">
                            State
                        </label>
                        <select
                            className={`input ${errors.state && "border-red-500 b-1"}`}
                            {...register('state', { required: true })}>
                            <option value={undefined} className="text-gray-500">--Select--</option>
                            {USStates.map((el) => (
                                <option key={el.abbr} value={el.abbr}>{el.name}</option>
                            ))}
                        </select>
                        {errors.state && (
                            <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>
                        )}
                    </div>

                    <div className="flex-1 flex flex-col">
                        <label className="text-gray-500" htmlFor="zip">
                            Postal Code
                        </label>
                        <input
                            id="zip"
                            placeholder="required"
                            className={`input ${errors.zip && "border-red-500 b-1"}`}
                            type="text"
                            {...register('zip', { required: true })}
                            autoComplete="postal-code" />
                        {errors.zip && errors.zip.type === "required" && (
                            <div className="text-red-500 text-sm mt-1 ml-1">This field is required</div>
                        )}
                    </div>
                </div>

                <div className="flex flex-col md:flex-row w-full mb-2 flex-wrap">
                    <div className="flex-1 flex flex-col md:mr-5 mb-2 md:mb-0">
                        <div className="mb-5">
                            <label className="text-gray-500" htmlFor="productLines">
                                Product Lines
                            </label>
                            <div className="form-check">
                                <input
                                    id="productLines.color_changing"
                                    className={`mr-2 ${errors.productLines && "border-red-500 b-1"}`}
                                    type="checkbox"
                                    {...register('productLines', { validate: () => getValues("productLines").length !== 0 })}
                                    value={ProductLine.COLOR_CHANGING} />
                                <label className="text-gray-800" htmlFor="productLines.color_changing">
                                    Color Changing
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    id="productLines.classics"
                                    className={`mr-2 ${errors.productLines && "border-red-500 b-1"}`}
                                    type="checkbox"
                                    {...register('productLines', { validate: () => getValues("productLines").length !== 0 })}
                                    value={ProductLine.CLASSIC} />
                                <label className="text-gray-800" htmlFor="productLines.classics">
                                    Classic (warm white)
                                </label>
                                {errors.productLines && errors.productLines.type === "validate" && (
                                    <div className="text-red-500 text-sm mt-1 ml-1">Selection is required</div>
                                )}
                            </div>
                        </div>
                        <div className="mb-5">
                            <label className="text-gray-500" htmlFor="buildingType">
                                Residential or Commercial property?
                            </label>
                            <div className="form-check">
                                <input
                                    id="buildingType.residential"
                                    className={`mr-2 ${errors.buildingType && "border-red-500 b-1"}`}
                                    type="radio"
                                    {...register('buildingType', { validate: () => getValues("buildingType").length !== 0 })}
                                    value={BuildingType.RESIDENTIAL} />
                                <label className="text-gray-800" htmlFor="buildingType.residential">
                                    Residential
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    id="buildingType.commercial"
                                    className={`mr-2 ${errors.buildingType && "border-red-500 b-1"}`}
                                    type="radio"
                                    {...register('buildingType', { validate: () => getValues("buildingType").length !== 0 })}
                                    value={BuildingType.COMMERCIAL} />
                                <label className="text-gray-800" htmlFor="buildingType.commercial">
                                    Commercial
                                </label>
                                {errors.buildingType && errors.buildingType.type === "validate" && (
                                    <div className="text-red-500 text-sm mt-1 ml-1">Selection is required</div>
                                )}
                            </div>
                        </div>
                        <div className="mb-5">
                            <div>
                                <label className="text-gray-500" htmlFor="quotedAreas">
                                    Install Location
                                </label>
                                <div className="form-check">
                                    <input
                                        id="quotedAreas.front"
                                        className={`mr-2 ${errors.quotedAreas && "border-red-500 b-1"}`}
                                        type="checkbox"
                                        {...register('quotedAreas', { validate: () => getValues("quotedAreas").length !== 0 })}
                                        value="front" />
                                    <label className="text-gray-800" htmlFor="quotedAreas.front">
                                        Front
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        id="quotedAreas.left"
                                        className={`mr-2 ${errors.quotedAreas && "border-red-500 b-1"}`}
                                        type="checkbox"
                                        {...register('quotedAreas', { validate: () => getValues("quotedAreas").length !== 0 })}
                                        value="left" />
                                    <label className="text-gray-800" htmlFor="quotedAreas.left">
                                        Left Side
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        id="quotedAreas.right"
                                        className={`mr-2 ${errors.quotedAreas && "border-red-500 b-1"}`}
                                        type="checkbox"
                                        {...register('quotedAreas', { validate: () => getValues("quotedAreas").length !== 0 })}
                                        value="right" />
                                    <label className="text-gray-800" htmlFor="quotedAreas.right">
                                        Right Side
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        id="quotedAreas.back"
                                        className={`mr-2 ${errors.quotedAreas && "border-red-500 b-1"}`}
                                        type="checkbox"
                                        {...register('quotedAreas', { validate: () => getValues("quotedAreas").length !== 0 })}
                                        value="back" />
                                    <label className="text-gray-800" htmlFor="quotedAreas.back">
                                        Back
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input
                                        id="quotedAreas.other"
                                        className={`mr-2 ${errors.quotedAreas && "border-red-500 b-1"}`}
                                        type="checkbox"
                                        {...register('quotedAreas', { validate: () => getValues("quotedAreas").length !== 0 })}
                                        value="other" />
                                    <label className="text-gray-800" htmlFor="quotedAreas.other">
                                        Other
                                    </label>
                                </div>
                            </div>
                            {errors.quotedAreas && errors.quotedAreas.type === "validate" && (
                                <div className="text-red-500 text-sm mt-1 ml-1">Selection is required</div>
                            )}
                        </div>
                    </div>

                    <div className="flex-1 flex flex-col md:mr-5">
                        <label className="text-gray-500" htmlFor="propertyPhotos">
                            Property Photos
                        </label>
                        <div className="text-center relative">
                            <div className="text-center py-10 border-gray-300 border mt-2 text-gray-500">
                                {imageUploadsState.length === 0 && "No Photos"}
                                {imageUploadsState.length > 0 &&
                                    imageUploadsState.map((upload) => (
                                        <ImageUpload key={upload.uploader.id} fileUploader={upload.uploader} file={upload.file} />
                                    ))}
                            </div>
                            <button
                                type="button"
                                className="btn bg-theme_gold text-white mt-3"
                                onClick={() => hiddenFileInput!.current!.click()}
                            >
                                Select Photos
                            </button>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                ref={hiddenFileInput}
                                multiple
                                onChange={imageSelectedHandler}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex w-full mb-2">
                    <div className="flex-1 flex flex-col">
                        <label className="text-gray-500" htmlFor="additionalInfo">
                            Additional Notes
                        </label>
                        <textarea
                            id="additionalInfo"
                            {...register('additionalInfo')}
                            className="input"
                            placeholder="optional"
                            rows={3}
                        />
                    </div>
                </div>

                <button type="button" onClick={handleSubmit(submit)} className="btn max-w-md px-14 my-10 bg-theme_blue text-white">
                    Submit
                </button>
            </div>
        </div>
    );
}
