import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom-v5-compat";

type Props = {
    title: string;
    subtitle?: string;
    children?: any;
    backButton?: boolean;
};

export default function TopBar(props: Props) {
    const { title, subtitle, children } = props;
    const navigate = useNavigate();

    return (
        <div className="flex flex-col sm:flex-row justify-between mb-5 p-2 sm:p-1 flex-wrap w-full">
            <div className="flex items-end mb-3 sm:mb-0 flex-col sm:flex-row">
                <button type="button" className="text-gray-500 text-xs sm:text-sm mr-0 sm:mr-4 mb-2 sm:mb-0 self-center hover:text-blue-500 duration-200 focus:outline-none" onClick={() => navigate(-1)}>
                    <FontAwesomeIcon icon={faChevronCircleLeft} />
                    <span className="ml-2">Back</span>
                </button>
                <div className="flex flex-col items-center sm:flex-row sm:items-end w-full sm:w-auto">
                    <h1 className="text-xl sm:text-2xl text-gray-500 uppercase text-center sm:text-left">{title}</h1>
                    {subtitle && <div className="ml-0 sm:ml-2 text-gray-500 text-sm sm:text-md text-center sm:text-left">{subtitle}</div>}
                </div>            </div>
            <div className="flex flex-col sm:flex-row items-center w-full sm:w-auto justify-end">
                {children && children}
            </div>
        </div>
    );
}