import React, { useEffect, useState } from "react";
import './_selectDealerPopup.scss';
import Select from 'react-select';
import { RetrieveCompanies } from "../../../services/companies";
import { ICompany, int } from "@common.abstractions";

export interface ISelectableDealer {
    id: int;
    name: string;
}

export interface ISelectableOption<T> {
    value: T;
    label: string;
}

export interface ISelectDealerPopupProps {
    onDealerSelected: (id: int) => void;
    onCancel: () => void;
}

export default function SelectDealerPopup(props: ISelectDealerPopupProps) {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [dealers, setDealers] = useState<ICompany[]>();

    const loadDealers = () => {
        setLoading(true);
        RetrieveCompanies()
            .then(companies => {
                setDealers(companies);
                setLoading(false);
            })
            .catch(err => {
                console.log("Loading quote requests failed: ", err);
                setLoading(false);
            })
    }

    useEffect(loadDealers, []);

    const dealersToOptions = (dealers: ICompany[]): ISelectableOption<int>[] =>
        dealers.map(dealer => { return { value: dealer.id!, label: dealer.name }; });

    let selectedId: int;

    const handleChange = (selectedOption: any) => {
        selectedId = selectedOption.value;
    };

    return (
        <div style={{width: "400px", maxWidth: "80vw"}}>
            <div style={{marginBottom: "15px"}}>
            {
                !dealers &&
                <Select isLoading />
            }
            {
                dealers &&
                <Select options={dealersToOptions(dealers)} onChange={handleChange} />
            }</div>

            <div className="button_wrapper">
                <button type="button" className="btn bg-theme_blue text-white" onClick={() => props.onDealerSelected(selectedId)}>Assign</button>
                <button type="button" className="ml-4 btn bg-gray-400 text-white" onClick={() => props.onCancel()}>Cancel</button>
            </div>
        </div>
    );
};
