import { useState, useEffect, useContext } from "react";
import { Route, useParams, Switch } from "react-router-dom";
import { DeleteCompany, RetrieveCompany, UpdateCompany } from "../../../services/companies";
import { RetrieveLeadsCount } from "../../../services/leads";
import CompanyLeadsListView from "../company-leads/CompanyLeads";
import "./_company.scss";
import { Modal } from "@frontend/components/misc/modal";
import { Loading } from "@frontend/components/misc/loading";
import { CompanyType, Scope, ICompany, int, ICompanyNotes, ICompanyNotesCreateParams, ICompanyNotesUpdateParams } from "@common.abstractions";
import { getGoogleMapsUrl, formatPhoneNumber, formatBuildingType, formatProductLine, formatProductCertification, formatMoney } from "@common.tools";
import { Equals } from "@common.api";
import { toast } from "react-toastify";
import AuthContext from "@frontend/contexts/AuthContext";
import { Page } from "@frontend/components/misc/layout";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import SearchBar from "@frontend/components/misc/search-bar/SearchBar";
import Button from "@frontend/components/misc/button/Button";
import { IDiscountPackage } from "@common.abstractions";
import { useNavigate } from "react-router-dom-v5-compat";
import { RetrieveCompanyNotes, UpdateCompanyNote, DeleteCompanyNote, CreateCompanyNotes } from "../../../services/company-notes";

export default function Company(props: { match?: any }) {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const params = useParams<{ companyId: string }>();
    const [companyId] = useState<int>(int.parse(params?.companyId));
    const [company, setCompany] = useState<ICompany>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [numLeads, setNumLeads] = useState<number>();
    const [numConvertedLeads, setNumConvertedLeads] = useState<number>();
    const [closeRate, setCloseRate] = useState<number>();
    const [deletePopupShown, setDeletePopupShown] = useState(false);
    const [showCompanyDetails, setShowCompanyDetails] = useState(false);
    const [currentPackage, setCurrentPackage] = useState<IDiscountPackage>();
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [companyNotes, setCompanyNotes] = useState<ICompanyNotes[]>([]);
    const [note, setNote] = useState<string>("");
    const [updatedNote, setUpdatedNote] = useState<string>('');
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editingNoteId, setEditingNoteId] = useState<int>();

    const loadNumLeads = () => {
        RetrieveLeadsCount({
            company: {
                id: Equals(companyId),
            },
        }).then((count) => setNumLeads(count));
    };
    useEffect(loadNumLeads, [company]);

    const loadNumConvertedLeads = () => {
        RetrieveLeadsCount({
            company: {
                id: Equals(companyId),
            },
            // ["convertedDate", { $exists: true }],
        }).then((count) => setNumConvertedLeads(count));
    };
    useEffect(loadNumConvertedLeads, [company]);

    const calculateConversionRate = () => {
        if (numLeads === undefined || numConvertedLeads === undefined) return;
        const conversionRate = (numConvertedLeads / numLeads) * 100;
        setCloseRate(conversionRate);
    };
    useEffect(calculateConversionRate, [numLeads, numConvertedLeads]);

    const loadCompany = async () => {
        setLoading(true);
        const company = await RetrieveCompany(companyId);
        setCompany(company);
        setLoading(false);

        let tempLatestPackage = company?.packages?.find((el) => new Date(el.startDate) < new Date() && new Date(el.endDate) > new Date());
        setCurrentPackage(tempLatestPackage);
    };

    const suspend = async () => {
        const response = await UpdateCompany(companyId, { suspended: true });
        if (response) {
            setCompany(response);
            toast("Company Suspended");
            return;
        }

        toast.error("Error Suspending Company");
    };

    const unsuspend = async () => {
        const response = await UpdateCompany(companyId, { suspended: false });
        if (response) {
            setCompany(response);
            toast("Company Unsuspended");
            return;
        }

        toast.error("Error Unsuspending Company");
    };

    const deleteDealer = () => {
        DeleteCompany(companyId).then(() => navigate("/companies"));
    };

    const openShowNotesModal = () => {
        setShowNotesModal(true);
    };
    const closeShowNotesModal = () => {
        setShowNotesModal(false);
        setNote("");
        setIsEditing(false);
        setNote("");
    };

    const loadCompanyNotes = async () => {
        const notes = await RetrieveCompanyNotes({
            filters: {
                company: {
                    id: Equals(companyId)
                }
            },
            relations: ['company', 'lastUpdatedBy']
        })
        if (!notes) return;
        notes.sort((a, b) => b.id - a.id);
        setCompanyNotes(notes);
    }

    const handleSave = async () => {
        const params: ICompanyNotesCreateParams = {
            userId: authContext?.user?.accountId!,
            companyId: companyId,
            note: note
        }
        const response = await CreateCompanyNotes(params);
        if (!response) {
            toast.error('Error creating company notes');
            return;
        }
        toast.success('Company notes created');
        loadCompanyNotes();
        setNote("");
        setUpdatedNote('');
    }

    const handleEdit = async (id: int) => {
        if (isEditing && editingNoteId === id) {
            // If already editing the same note, save the changes
            await updateNote(id);
        } else {
            // Activate editing mode for the note
            setIsEditing(true);
            setEditingNoteId(id);
        }
    };

    const updateNote = async (id: int) => {
        const params: ICompanyNotesUpdateParams = {
            note: updatedNote,
            lastUpdatedBy: authContext?.user,
        };
        const response = await UpdateCompanyNote(id, params);
        if (!response) {
            toast.error('Error updating company notes');
            return;
        }
        toast.success('Company notes updated');
        setIsEditing(false);
        setUpdatedNote('');
        setEditingNoteId(undefined);
        loadCompanyNotes();
    };

    const handleDelete = async (id: int) => {
        const confirm = window.confirm('Are you sure you want to delete this note?');
        if (!confirm) return;
        const response = await DeleteCompanyNote(id);
        if (!response) {
            toast.error('Error deleting company notes');
            return;
        }
        toast.success('Company notes deleted');
        loadCompanyNotes();
    }

    useEffect(() => {
        loadCompany()
        loadCompanyNotes()
    }, [])

    if (isLoading) return <Loading center />;

    if (!company) return <div className="inset-center text-gray-500">Company Not Found</div>;

    return (
        <Page>
            <Modal visible={deletePopupShown} closeModal={() => setDeletePopupShown(false)}>
                <div className="p-4">
                    <div className="text-2xl text-gray-500">Are you sure?</div>
                    <div className="text-gray-500 py-4">
                        You are about to permanently delete this dealer. Any leads currently assigned to this dealer will be unassigned.
                    </div>
                    <div className="flex justify-end">
                        <div className="btn bg-theme_blue text-white" onClick={() => deleteDealer()}>
                            Delete Dealer
                        </div>
                        <div className="btn bg-gray-400 text-white ml-4" onClick={() => setDeletePopupShown(false)}>
                            Cancel
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal visible={showCompanyDetails} closeModal={() => setShowCompanyDetails(false)}>
                {company && (
                    <div className="flex flex-col p-10 w-96">
                        <div className="text-center text-gray-600 text-2xl pb-4">Company Info</div>
                        <div>
                            <div className="text-gray-500">Phone Number</div>
                            {company.phone ? (
                                <div>
                                    <a className="ml-3 text-blue-500 hover:text-blue-700 text-sm" href={`tel:${formatPhoneNumber(company.phone)}`}>
                                        {formatPhoneNumber(company.phone)}
                                    </a>
                                </div>
                            ) : (
                                <div className="ml-3 text-sm text-gray-700">"N/A"</div>
                            )}
                        </div>
                        <div>
                            <div className="text-gray-500">Personal Number</div>
                            {company.personalPhone ? (
                                <div>
                                    <a className="ml-3 text-blue-500 hover:text-blue-700 text-sm" href={`tel:${formatPhoneNumber(company.personalPhone)}`}>
                                        {formatPhoneNumber(company.personalPhone)}
                                    </a>
                                </div>
                            ) : (
                                <div className="ml-3 text-sm text-gray-700">"N/A"</div>
                            )}
                        </div>
                        <div>
                            <div className="text-gray-500">Address</div>
                            <div>
                                <a className="ml-3 text-blue-500 hover:text-blue-700 text-sm" href={getGoogleMapsUrl(company.address)}>
                                    {company.address.streetAddress}, {company.address.city}, {company.address.state}, {company.address.zip}
                                </a>
                            </div>
                        </div>

                        <div>
                            <div className="text-gray-500">Email</div>
                            {company.email ? (
                                <div className="text-sm text-gray-700">{company.email}</div>
                            ) : <div className="ml-3 text-sm text-gray-700"> N/A </div>
                            }
                        </div>

                        <div>
                            <div className="text-gray-500">Website</div>
                            <div>
                                {company.websiteUrl ? (
                                    <a className="ml-3 text-blue-500 hover:text-blue-700 text-sm" href={company.websiteUrl}>
                                        {company.name} Website
                                    </a>
                                ) : (
                                    <div className="ml-3 text-sm text-gray-700">N/A</div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="text-gray-500">Facebook</div>
                            <div>
                                {company.facebookUrl ? (
                                    <a className="ml-3 text-blue-500 hover:text-blue-700 text-sm" href={company.facebookUrl}>
                                        {company.name} Facebook
                                    </a>
                                ) : (
                                    <div className="ml-3 text-sm text-gray-700">N/A</div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="text-gray-500">Instagram</div>
                            <div>
                                {company.instagramUrl ? (
                                    <a className="ml-3 text-blue-500 hover:text-blue-700 text-sm" href={company.instagramUrl}>
                                        {company.name} Instagram
                                    </a>
                                ) : (
                                    <div className="ml-3 text-sm text-gray-700">N/A</div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="text-gray-500">Building Types</div>
                            <div className="ml-3 text-sm text-gray-700">{company.buildingTypes.map((el) => formatBuildingType(el)).join(", ")}</div>
                        </div>
                        <div>
                            <div className="text-gray-500">Product Lines</div>
                            <div className="ml-3 text-sm text-gray-700">{company.productLines.map((el) => formatProductLine(el)).join(", ")}</div>
                        </div>
                        <div>
                            <div className="text-gray-500">Product Certifications</div>
                            <div className="ml-3 text-sm text-gray-700">{company.productCertifications.length ? company.productCertifications.map((el) => formatProductCertification(el)).join(", ") : "None"}</div>
                        </div>

                        <div>
                            <div className="text-gray-500">Dealer Manager</div>
                            <div className="ml-3 text-sm text-gray-700">{company.owner ? `${company.owner.firstname} ${company.owner?.lastname}` : 'Not assigned'}</div>
                        </div>
                        <div>
                            <div className="text-gray-500">Monthly Marketing Spend</div>
                            <div className="ml-3 text-sm text-gray-700">{company.monthlyMarketingSpend ? formatMoney(company.monthlyMarketingSpend) : 'N/A'}</div>
                        </div>
                        <div>
                            <div className="text-gray-500">Marketing Zip Codes</div>
                            <div className="ml-3 text-sm text-gray-700">{company.marketingZipCodes?.join(', ')}</div>
                        </div>

                        <div>
                            <div className="text-gray-500">Current Discount Package</div>
                            <div className="ml-3 text-sm text-gray-700">{currentPackage?.name}</div>
                        </div>
                        <div>
                            <div className="text-gray-500">Current Discount Package Start Date</div>
                            <div className="ml-3 text-sm text-gray-700"> {currentPackage?.startDate ? new Date(new Date(currentPackage.startDate).setDate(new Date(currentPackage.startDate).getDate())).toISOString().split("T")[0] : undefined}</div>
                        </div>
                        <div>
                            <div className="text-gray-500">Current Discount Package End Date</div>
                            <div className="ml-3 text-sm text-gray-700"> {currentPackage?.endDate ? new Date(new Date(currentPackage.endDate).setDate(new Date(currentPackage.endDate).getDate())).toISOString().split("T")[0] : undefined}</div>
                        </div>

                        {authContext?.hasAnyGrant(Scope.Company.UPDATE_ANY) && (
                            <div className="btn bg-theme_blue my-5 text-white" onClick={() => navigate("/companies/" + companyId + "/edit")}>
                                Edit
                            </div>
                        )}
                    </div>
                )}
            </Modal>

            <Modal visible={showNotesModal} closeModal={closeShowNotesModal}>
                <div className="overflow-auto bg-white p-6 rounded-lg">
                    <form>
                        <div className="mb-4">
                            <label htmlFor="note" className="block text-sm font-medium text-gray-700">
                                Note:
                            </label>
                            <textarea
                                className="mt-1 block w-full rounded-md border-gray-100 border-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                                value={note}
                                onChange={(e) => setNote(e.target.value)}
                            />
                        </div>
                        <button
                            type="button"
                            className="px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600"
                            onClick={handleSave}
                        >
                            Create Note
                        </button>
                    </form>

                    {companyNotes.map((logFile) => {
                        const isEditingLogFile = editingNoteId === logFile.id;

                        return (
                            <div key={logFile.id} className="border border-gray-200 rounded p-4 mb-4">
                                <div className="mb-2 border-b">
                                    {isEditingLogFile ? (
                                        <textarea
                                            className="mt-1 block w-full rounded-md border-gray-100 border-2 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 p-2"
                                            value={updatedNote}
                                            onChange={(e) => setUpdatedNote(e.target.value)}
                                        />
                                    ) : (
                                        <p>{logFile.note}</p>
                                    )}
                                </div>
                                <div className="flex justify-between mb-2">
                                    <p className="text-md italic">
                                        Updated by: {logFile.lastUpdatedBy.firstname} {logFile.lastUpdatedBy.lastname}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                        Updated at: {logFile.updatedAt ? new Date(new Date(logFile.updatedAt).setDate(new Date(logFile.updatedAt).getDate())).toISOString().split("T")[0] : undefined}
                                    </p>
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        className="px-2 py-1 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 mr-2"
                                        onClick={() => handleEdit(logFile.id)}
                                    >
                                        {isEditingLogFile ? 'Save' : 'Edit'}
                                    </button>
                                    {isEditingLogFile && (
                                        <>
                                            <button
                                                className="px-2 py-1 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 mr-2"
                                                onClick={() => {
                                                    setIsEditing(false);
                                                    setEditingNoteId(undefined);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => handleDelete(logFile.id)}
                                                className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
                                            >
                                                Delete
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Modal>

            <TopBar title={company ? company.name : "Dealer"}>
                <SearchBar className="w-96" placeholder="Search Leads" search={setSearchCriteria} />

                {company.companyType === CompanyType.BRANCH &&
                    <Button className="ml-4" rounded color="blue" style="outline" link={`/companies/${companyId}/goals`}>Goals</Button>
                }

                <Button className="ml-4" rounded color="blue" style="outline" onClick={openShowNotesModal}>Notes</Button>

                <Button className="ml-4" rounded color="blue" style="outline" onClick={() => setShowCompanyDetails(true)}>
                    Dealer Info
                </Button>
                <Button className="ml-4" rounded color="blue" style="outline" link={`/companies/${props?.match?.params?.companyId}/users/new`}>
                    Create User
                </Button>
                <Button className="ml-4" rounded color="blue" style="outline" link={`/companies/${props?.match?.params?.companyId}/edit`}>
                    Edit Company
                </Button>
                {company.suspended && (
                    <Button className="ml-4" rounded color="yellow" style="outline" onClick={unsuspend}>
                        Unsuspend
                    </Button>
                )}
                {!company.suspended && (
                    <Button className="ml-4" rounded color="yellow" style="outline" onClick={suspend}>
                        Suspend
                    </Button>
                )}
                <Button className="ml-4" rounded color="red" style="outline" onClick={() => setDeletePopupShown(true)}>
                    Delete
                </Button>
            </TopBar>

            <div className="flex w-full max-h-full calc-nav">
                <div className="flex flex-col flex-grow">
                    <Switch>
                        <Route
                            path={`/companies/${companyId}`}
                            render={() => <CompanyLeadsListView searchCriteria={searchCriteria} companyId={companyId} />}
                        />
                    </Switch>
                </div>
            </div>
        </Page>
    );
}
