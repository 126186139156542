import axios from 'axios';
import { ICompanyNotes, ICompanyNotesCreateParams, ICompanyNotesUpdateParams, int } from '@common.abstractions';
import { Query, serializeQuery } from '@common.api';
import { BACKEND_URL } from '@frontend/environment';

export async function RetrieveCompanyNotes(query?: Query<ICompanyNotes>): Promise<ICompanyNotes[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/companyNotes`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200) return response.data as ICompanyNotes[];
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function CreateCompanyNotes(params: ICompanyNotesCreateParams): Promise<ICompanyNotes | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/companyNotes`, params, { withCredentials: true });
        if (response.status === 201) return response.data as ICompanyNotes;
        return undefined;

    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteCompanyNote(id: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/companyNotes/${id}`, { withCredentials: true });
        if (response.status === 200) return true;
        return false;
    } catch (err: any) {
        console.log(err);
        return false;
    }
}

export async function UpdateCompanyNote(id: int, params: ICompanyNotesUpdateParams): Promise<ICompanyNotes | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/companyNotes/${id}`, params, { withCredentials: true });
        if (response.status === 200) return response.data as ICompanyNotes;
        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}