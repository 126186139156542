import { IBranchSalesGoal } from "./branch-goals";
import { ICompanyNotes } from "./company-notes";
import { IDiscountPackage } from "./discount-package";
import { IInstall } from "./installs";
import { ILead } from "./lead";
import { Address, BuildingType, float, int, LatLng, ProductCertification, ProductLine } from "./primitives";
import { IShippingAddress } from "./shipping-address";
import { IUser } from "./user";
import { IWarranty } from "./warranty";
import { IWebhook } from "./webhook";

export enum CompanyType {
    DEALER = 'dealer',
    BRANCH = 'branch'
}

export enum ServiceAreaType {
    POLYGON = 'polygon',
    CIRCLE = 'circle'
}

export type GeoPolygon = {
    vertices: LatLng[];
}

export type GeoCircle = {
    center: LatLng;
    radiusMeters: float;
}

export type ServiceArea = {
    type: ServiceAreaType;
    area: GeoCircle | GeoPolygon
}

export type BankAccount = {
    stripeBankAccountId: string;
    verifiedDate: Date | null;
    createdAt: Date;
}

export interface ICompany {
    id: int;
    companyType: CompanyType;
    name: string;
    phone: string; // marketing phone number.
    personalPhone: string | null;
    address: Address;
    email: string;
    shippingAddresses: IShippingAddress[];

    packages?: IDiscountPackage[];

    goals?: IBranchSalesGoal[];

    buildingTypes: BuildingType[];
    productLines: ProductLine[];
    productCertifications: ProductCertification[];

    exclusiveServiceAreas: ServiceArea[];
    captureServiceAreas: ServiceArea[];

    timeZone: { zoneName: string, abbreviation: string } | null;

    websiteUrl: string | null;
    facebookUrl: string | null;
    instagramUrl: string | null;

    stripeCustomerId: string | null;
    billCustomerId: string | null;
    bankAccount: BankAccount | null;

    owner?: IUser
    parentCompanyId?: int | null;
    isParentCompany: boolean;

    users?: IUser[];
    leads?: ILead[];
    totalLeads?: number;
    closedLeads?: number;
    warranty?: IWarranty[];
    installs?: IInstall[];
    webhooks?: IWebhook[];
    suspended: boolean;
    notes?: ICompanyNotes[];
    monthlyMarketingSpend: int | null
    marketingSpend: IMarketingSpend[];
    marketingZipCodes: string[] | null

    ftSold?: number
    daysSinceLastSale?: number

    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
}

export interface ICreateCompanyParams {
    companyType: CompanyType;
    name: string;
    phone: string;
    personalPhone?: string | null;
    address: Address;
    email: string;
    shippingAddresses?: Address[];

    buildingTypes?: BuildingType[];
    productLines?: ProductLine[];
    productCertifications?: ProductCertification[];

    timeZone?: { zoneName: string, abbreviation: string } | null;

    owner?: IUser;
    parentCompanyId?: int | null;
    isParentCompany?: boolean;

    exclusiveServiceAreas?: ServiceArea[];
    captureServiceAreas?: ServiceArea[];

    websiteUrl?: string | null;
    facebookUrl?: string | null;
    instagramUrl?: string | null;

    monthlyMarketingSpend?: int | null;
    marketingZipCodes?: string[] | null;
}

export interface IUpdateCompanyParams {
    companyType?: CompanyType;
    name?: string;
    phone?: string;
    personalPhone?: string | null;
    address?: Address;
    email?: string;

    buildingTypes?: BuildingType[];
    productLines?: ProductLine[];
    productCertifications?: ProductCertification[];

    exclusiveServiceAreas?: ServiceArea[];
    captureServiceAreas?: ServiceArea[];

    owner?: IUser;
    parentCompanyId?: int | null;
    isParentCompany?: boolean;

    timeZone?: { zoneName: string, abbreviation: string } | null;

    websiteUrl?: string | null;
    facebookUrl?: string | null;
    instagramUrl?: string | null;

    stripeCustomerId?: string | null;

    monthlyMarketingSpend?: int | null;
    marketingZipCodes?: string[] | null;

    suspended?: boolean;
}

export interface IMarketingSpend {
    id: int;
    company: ICompany;
    year: number;
    month: number;
    amount: int;
    orderId: int;
}

export interface ICreateMarketingSpend {
    companyId: int;
    year: number;
    month: number;
    amount: int;
    orderId: int;
}