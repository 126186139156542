import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProcessOrder, RetrieveOrder } from "@frontend/services/orders";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import AuthContext from "../../../contexts/AuthContext";

// import "./_orderReview.scss";
import Loading from "@frontend/components/misc/loading/Loading";
import { float, Scope, IDiscountPackage, int, IOrder, IOrderItem, IProduct, IProductVariant, VariantAttribute } from "@common.abstractions";
import { calculateDiscount, formatMoney } from "@common.tools";
import { Page } from "@frontend/components/misc/layout";
import TopBar from "@frontend/components/misc/top-bar/TopBar";
import Button from "@frontend/components/misc/button/Button";
import { useNavigate } from "react-router-dom-v5-compat";

export default function ReviewOrder() {
    const authContext = React.useContext(AuthContext);
    const params = useParams<{ orderId: string }>();
    const [orderId] = useState<int>(int.parse(params.orderId));
    const [order, setOrder] = useState<IOrder>();
    const navigate = useNavigate();
    const [orderBeingProcessed, setOrderBeingProcessed] = useState<boolean>(false);
    const [orderProcessed, setOrderProcessed] = useState<boolean>(false);
    const [discountPackage, setDiscountPackage] = useState<IDiscountPackage | undefined>();

    const initializeDiscountPackage = () => {
        if (authContext?.user?.company?.packages) {
            const currentDiscountPackage = authContext.user.company.packages.find((el) => new Date(el.startDate) < new Date() && new Date)
            setDiscountPackage(currentDiscountPackage);
        }
    };

    const loadOrder = async () => {
        const tempOrder = await RetrieveOrder(orderId);

        if (tempOrder) {
            setOrder(tempOrder);
            return;
        }

        toast.error("Couldn't load order");
    };

    const handleProcessOrder = async () => {
        setOrderBeingProcessed(true);
        const processOrderRes = await ProcessOrder(orderId);

        if (processOrderRes) {
            setOrderProcessed(true);
            setOrderBeingProcessed(false);
            toast("Order processed");
            return;
        }

        setOrderBeingProcessed(false);
        toast.error("Something went wrong");
    };

    const processOrderButtonHandler = () => {
        if (!order?.invoiceProcessedDate && !order?.paymentSuccessfulDate && !orderProcessed) {
            return (
                authContext?.hasAnyGrant(Scope.Order.PROCESS_ANY) &&
                (orderBeingProcessed ? (
                    <>
                        {/* <div className="btn bg-theme_blue text-white cursor-not-allowed bg-opacity-80">Process Order</div> */}
                        <Button className="cursor-not-allowed bg-opacity-80" rounded style="solid" color="blue">
                            Process Order
                        </Button>

                        <Loading fullscreen />
                    </>
                ) : (
                    <Button onClick={handleProcessOrder} rounded style="solid" color="blue">
                        Process Order
                    </Button>
                ))
            );
        } else if (
            order?.invoiceProcessedDate &&
            order?.invoiceUrl &&
            !order?.paymentSuccessfulDate &&
            authContext?.hasAnyGrant(Scope.Order.UPDATE)
        ) {
            return (
                <a href={order.invoiceUrl} target="_blank" className="ml-4 btn bg-theme_blue text-white rounded-full">
                    Make Payment
                </a>
            );
        } else {
            return null;
        }
    };

    const calculateItemTotal = (item: IOrderItem) => {
        if (!item.productVariant) return "NOT WORKING";
        if (discountPackage) {
            if (item.productVariant.retailPrice) {
                return formatMoney(calculateDiscount(float(item.productVariant.retailPrice * item.quantity), discountPackage.discountPercent));
            } else {
                return formatMoney(
                    calculateDiscount(float((item.productVariant.product as IProduct).retailPrice * item.quantity), discountPackage.discountPercent)
                );
            }
        } else {
            if (item.productVariant.retailPrice) {
                return formatMoney(item.productVariant.retailPrice * item.quantity);
            } else {
                return formatMoney((item.productVariant.product as IProduct).retailPrice * item.quantity);
            }
        }
    };

    useEffect(() => {
        loadOrder();
        initializeDiscountPackage();
    }, []);

    return (
        <Page>
            <TopBar
                title="Order Review"
                subtitle={
                    order?.lead.quoteRequest?.contact && authContext?.hasAnyGrant(Scope.Order.READ_ANY) ? `for ${order?.lead.company?.name}` : `for ${order?.lead.quoteRequest?.contact?.firstname} ${order?.lead.quoteRequest?.contact?.lastname}`}
            >
                {order?.lead && (
                    <Button
                        link={`/leads/${order.lead.id}`}
                        className="w-full sm:w-auto mb-2 sm:mb-0 sm:mr-4"
                        rounded
                        style="outline"
                        color="blue"
                    >
                        View Lead
                    </Button>
                )}
                {processOrderButtonHandler()}
            </TopBar>

            <div className="flex flex-col md:flex-row flex-grow overflow-hidden">
                {order && (
                    <div className="flex flex-col w-full md:w-96 relative mb-8 md:mb-0 md:mr-16">
                        <div className="p-3 text-gray-600">
                            <div>
                                <h2 className="text-xl">Order Summary</h2>
                            </div>
                            <br />
                            <div className="flex justify-between">
                                <span>Order Submitted Date</span>
                                <span>{order.submittedDate ? new Date(order.submittedDate).toLocaleDateString() : "Pending"}</span>
                            </div>
                            <br />
                            <div className="flex justify-between">
                                <span>Payment Date</span>
                                <span>{order.paymentSuccessfulDate ? new Date(order.paymentSuccessfulDate).toLocaleDateString() : "Pending"}</span>
                            </div>
                            <br />
                            <div className="flex justify-between">
                                <span>Shipped date</span>
                                <span>{order.shippedDate ? new Date(order.shippedDate).toLocaleDateString() : "Pending"}</span>
                            </div>
                            <br />
                            <div className="flex justify-between">
                                <span>Tracking Number(s)</span>
                                <span>{order.trackingNumbers.length ? order.trackingNumbers.map(n => <span key={n}>{n} <br /></span>) : "N/A"}</span>
                            </div>
                            {order.paymentSuccessfulDate && order.invoiceUrl && (
                                <>
                                    <br />
                                    <div className="flex justify-between">
                                        <span>Invoice</span>
                                        <a className="ml-2 text-gray-800" href={order.invoiceUrl} target="_blank">
                                            Stripe Invoice Link
                                        </a>
                                    </div>
                                </>
                            )}
                            <br />

                            {order.paymentMethod && (

                                <div className="flex justify-between">
                                    <span>Convenience Fee ({order.paymentMethod!.name})</span>
                                    <span>{order.paymentMethod?.cost ? formatMoney(order.paymentMethod.cost) : formatMoney(0)}</span>
                                </div>

                            )}

                            <br />
                            <div className="flex justify-between">
                                <span>Shipping</span>
                                <span>{order.shipping?.cost ? formatMoney(order.shipping.cost) : formatMoney(0)}</span>
                            </div>
                            <br />
                            <div className="flex justify-between">
                                <span>Order Subtotal</span>
                                <div className="flex">
                                    {order.vDiscountAmount && order.discountPackage ? (
                                        <div className="flex flex-col">
                                            <span className="mr-2 text-gray-400 line-through">{order.vSubtotal ? formatMoney(order.vSubtotal) : "N/A"}</span>
                                            <span className="text-theme_green">{`-${formatMoney(order.vDiscountAmount)}`}</span>
                                            <span>
                                                {order.vSubtotal
                                                    ? formatMoney(
                                                        order.vSubtotal - order.vDiscountAmount
                                                    )
                                                    : "N/A"}
                                            </span>
                                        </div>
                                    ) : (
                                        <span>{order.vSubtotal ? formatMoney(order.vSubtotal) : "N/A"}</span>
                                    )}
                                </div>
                            </div>
                            <br />
                            <hr />
                            <br />
                            <div className="flex justify-between">
                                <span>Order Total:</span>
                                <span>{order.vTotal ? formatMoney(order.vTotal) : "N/A"}</span>
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex flex-col flex-grow overflow-y-auto md:pl-10">
                    <h2 className="text-xl p-3">Order Items</h2>
                    {order?.items &&
                        order.items.map((item, index) => {
                            if (!item.productVariant) return;

                            if (!item.productVariant.product) return;
                            return (
                                <div key={item.id} className="flex flex-col md:flex-row items-start md:items-end relative mx-4 my-4 bg-gray-100 p-4 rounded-lg">
                                    <div className="flex flex-col flex-grow">
                                        <div className="text-gray-500 text-md leading-tight">
                                            {((item.productVariant as IProductVariant).product as IProduct).title}
                                        </div>

                                        {discountPackage ? (
                                            <>
                                                <div className="text-gray-400 line-through">
                                                    {formatMoney(
                                                        item.productVariant.retailPrice
                                                            ? item.productVariant.retailPrice
                                                            : item.productVariant.product.retailPrice
                                                    )}
                                                </div>
                                                <div className="flex items-end">
                                                    <div className="">
                                                        {formatMoney(
                                                            calculateDiscount(
                                                                item.productVariant.retailPrice
                                                                    ? item.productVariant.retailPrice
                                                                    : item.productVariant.product.retailPrice,
                                                                discountPackage.discountPercent
                                                            )
                                                        )}
                                                    </div>
                                                    <div className="ml-2"> x {item.quantity}</div>
                                                    <div className="ml-2 text-xl text-theme_blue">
                                                        {formatMoney(
                                                            calculateDiscount(
                                                                item.productVariant.retailPrice
                                                                    ? item.productVariant.retailPrice
                                                                    : item.productVariant.product.retailPrice,
                                                                discountPackage.discountPercent
                                                            ) * item.quantity
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="flex items-end">
                                                <div className="">
                                                    {formatMoney(
                                                        item.productVariant.retailPrice
                                                            ? item.productVariant.retailPrice
                                                            : item.productVariant.product.retailPrice
                                                    )}
                                                </div>
                                                <div className="ml-2"> x {item.quantity}</div>
                                                <div className="ml-2 text-xl text-theme_blue">
                                                    {formatMoney(
                                                        (item.productVariant.retailPrice
                                                            ? item.productVariant.retailPrice
                                                            : item.productVariant.product.retailPrice) * item.quantity
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        <div className="mt-2">
                                            {(item.productVariant as IProductVariant).attributes.length
                                                ? ((item.productVariant as IProductVariant).attributes as VariantAttribute[]).map((attr) => {
                                                    return (
                                                        <div key={attr.name + attr.value} className="leading-none">
                                                            <span className="text-gray-500 text-sm mr-1 leading-none">{`${attr.name}:`}</span>
                                                            <span className="text-gray-500 text-sm leading-none">{`${attr.value}`}</span>
                                                        </div>
                                                    );
                                                })
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </Page>
    );
}
