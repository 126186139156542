import { useEffect, useRef, useState } from "react";
import "./_productCatalog.scss";
import Modal from "../misc/modal/Modal";
import { toast } from "react-toastify";
import { ArchiveProduct, RetrieveProducts } from "../../services/products";
import { Exists, Filters, Not } from "@common.api";
import { Sorts } from "@common.api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Loading } from "../misc/loading";
import { DimensionalUnit, float, int, IProduct, WeightUnit } from "@common.abstractions";
import { handleSort } from "@common.tools";
import { Page } from "../misc/layout";
import TopBar from "../misc/top-bar/TopBar";
import Button from "../misc/button/Button";
import SearchBar from "../misc/search-bar/SearchBar";

export default function ProductsCatalogPage() {
    const modalSubmitButton = useRef<any>(null);
    const [products, setProducts] = useState<IProduct[]>([]);
    const [sortedProducts, setSortedProducts] = useState<IProduct[]>([]);
    const [searchCriteria, setSearchCriteria] = useState<string>("");

    const [loading, setLoading] = useState(true);
    const [modalVisibility, setModalVisibility] = useState<boolean>(false);

    const [productId, setProductId] = useState<int>(0 as int);
    const [productTitle, setProductTitle] = useState<string>("");
    const [productDescription, setProductDescription] = useState<string>("");
    const [productWeightUnit, setProductWeightUnit] = useState<WeightUnit>("oz" as WeightUnit);
    const [productDimUnit, setProductDimUnit] = useState<DimensionalUnit>("in" as DimensionalUnit);
    const [productWeight, setProductWeight] = useState<float>(0 as float);
    const [productHeight, setProductHeight] = useState<float>(0 as float);
    const [productWidth, setProductWidth] = useState<float>(0 as float);
    const [productLength, setProductLength] = useState<float>(0 as float);
    const [productRetailPrice, setProductRetailPrice] = useState<float>(0 as float);
    const [productDeclaredValue, setProductDeclaredValue] = useState<float>(0 as float);
    const [productMoqValue, setProductMoqValue] = useState<float>(0 as float);
    const [productListed, setProductListed] = useState<boolean>(false);

    const [modalType, setModalType] = useState<"update" | "create">("update");

    const psuedoModalSubmit = (e: any) => {
        e.preventDefault();

        if (modalSubmitButton && modalSubmitButton.current) {
            modalSubmitButton.current.click();
        }
    };
    const formErrorChecker = () => {
        if (
            !productTitle ||
            !productWeightUnit ||
            !productDimUnit ||
            !productWeight ||
            !productHeight ||
            !productWidth ||
            !productLength ||
            !productRetailPrice ||
            !productDeclaredValue ||
            !productMoqValue
        ) {
            toast.error("Please fill out all form fields");
            return false;
        }
        return true;
    };

    // Update Product
    /* 
    const handleUpdate = async (e: any) => {
        e.preventDefault();

        if (!formErrorChecker()) return;

        const tempProduct: ICreateProductParams = {
            title: productTitle,
            description: productDescription,
            weightUnit: productWeightUnit,
            dimUnit: productDimUnit,
            weight: productWeight,
            height: productHeight,
            width: productWidth,
            length: productLength,
            retailPrice: productRetailPrice,
            declaredValue: productDeclaredValue,
            listed: productListed,
            moqValue: productMoqValue
        };

        const product = await CreateProduct(tempProduct);
        if (!product) {
            toast.error("Update Failed!");
            return;
        }

        const tempProducts = [...products].map((el) => {
            if (el.id === productId) {
                el = product;
            }
            return el;
        });
        setProducts(tempProducts);

        const archiveResponse = await ArchiveProduct(productId);

        if (!archiveResponse) {
            toast.error("Update Failed!");
            return;
        }
        toast("Product Updated");
        closeModal();
    };
 */

    const handleDelete = async () => {
        const deletedProduct = await ArchiveProduct(productId);
        if (!deletedProduct) {
            toast.error("Something went wrong");
            return;
        }

        const tempProducts = [...products].filter((el) => el.id !== productId);
        setProducts(tempProducts);
        toast("Product Deleted");
        closeModal();
    };

    const openModal = (product: any, updateOrCreate: "update" | "create") => {
        if (updateOrCreate === "update") {
            setProductListed(product.listed);
        } else if ("create") {
            setProductListed(true);
        }

        setModalType(updateOrCreate);

        setProductId(product.id);
        setProductTitle(product.title);
        setProductDescription(product.description);
        product.weightUnit ? setProductWeightUnit(product.weightUnit) : setProductWeightUnit("oz" as WeightUnit);
        product.dimUnit ? setProductDimUnit(product.dimUnit) : setProductDimUnit("in" as DimensionalUnit);
        setProductWeight(product.weight);
        setProductHeight(product.height);
        setProductWidth(product.width);
        setProductLength(product.length);
        setProductRetailPrice(product.retailPrice);
        setProductDeclaredValue(product.declaredValue);

        setModalVisibility(true); //required
    };
    const closeModal = () => {
        setModalVisibility(false); //required
    };
    const loadProducts = async () => {
        const responseProduct = await RetrieveProducts({
            filters: {
                deletedAt: Not(Exists()),
            },
            relations: ["categories", "shippingGroup", "variants"]
        });

        if (!responseProduct) {
            setLoading(false);
            return;
        }

        setProducts(responseProduct);
        setSortedProducts(responseProduct);
        setLoading(false);
    };
    const searchFilter = (entity: IProduct) => {
        if (searchCriteria === "" || entity.title.toLowerCase().includes(searchCriteria.toLowerCase())) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        loadProducts();
    }, []);

    return (
        <Page>
            <TopBar title="products">
                <SearchBar placeholder="Search Products" className="w-72 mr-8 " search={setSearchCriteria} />
                <Button link="/products/new" className="mr-8 text-sm" type="button" color="blue" style="outline" rounded>
                    Add Product
                </Button>
            </TopBar>

            <div className="row header">
                <div onClick={() => setSortedProducts(handleSort(["title"], sortedProducts))}>
                    Product
                    <FontAwesomeIcon icon={faSort} />
                </div>
                <div onClick={() => setSortedProducts(handleSort(["description"], sortedProducts))}>
                    Description
                    <FontAwesomeIcon icon={faSort} />
                </div>
                <div onClick={() => setSortedProducts(handleSort(["weight"], sortedProducts))}>
                    Weight
                    <FontAwesomeIcon icon={faSort} />
                </div>
                <div onClick={() => setSortedProducts(handleSort(["retailPrice"], sortedProducts))}>
                    Retail Price
                    <FontAwesomeIcon icon={faSort} />
                </div>
            </div>
            <div className="rows_wrapper">
                {loading ? (
                    <Loading center />
                ) : (
                    <>
                        {sortedProducts.map((el, i) => {
                            if (searchFilter(el)) {
                                return (
                                    <Link key={"catalog_item" + el.id} className="row item" to={`/products/${el.id}`}>
                                        <div>{el.title}</div>
                                        <div className="description">{el.description}</div>
                                        <div>{`${el.weight} ${
                                            el.weightUnit === "oz" ? el.weightUnit : el.weight === 1 ? el.weightUnit : el.weightUnit + "s"
                                        }`}</div>
                                        <div>{"$" + (el.retailPrice / 100).toFixed(2)}</div>
                                    </Link>
                                );
                            }
                        })}
                    </>
                )}
            </div>
            <Modal closeModal={closeModal} visible={modalVisibility}>
                <h2 className="title form_title">{productTitle && productTitle.length > 0 ? productTitle : `Product`}</h2>
                <form className="product_catalog_edit_form" onSubmit={(e) => e.preventDefault}>
                    <div className="form_group">
                        <div>
                            <label>Listed</label>
                            <input type="checkbox" required onChange={() => setProductListed(!productListed)} checked={productListed} />
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label>Title:</label>
                            <input
                                onChange={(e) => setProductTitle(e.target.value)}
                                required={true}
                                type="text"
                                name="title"
                                defaultValue={productTitle}
                                placeholder="Title"
                            />
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label>Description:</label>
                            <textarea
                                onChange={(e) => setProductDescription(e.target.value)}
                                name="description"
                                defaultValue={productDescription}
                                placeholder="Description"
                            />
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label>Weight Unit:</label>
                            <select
                                onChange={(e) => setProductWeightUnit(e.target.value as WeightUnit)}
                                required={true}
                                name="weightUnit"
                                defaultValue={productWeightUnit}
                                placeholder="Weight Unit"
                            >
                                <option value="oz">oz</option>
                                <option value="kg">kg</option>
                                <option value="lb">lb</option>
                                <option value="g">g</option>
                            </select>
                        </div>
                        <div>
                            <label>Weight:</label>
                            <input
                                onChange={(e) => setProductWeight(0 as float)} //float.parse(e.target.value)
                                required={true}
                                type="number"
                                name="weight"
                                defaultValue={productWeight}
                                placeholder="Weight"
                            />
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label>Dimensions Unit:</label>
                            <select
                                onChange={(e) => setProductDimUnit(e.target.value as DimensionalUnit)}
                                name="dimUnit"
                                required={true}
                                defaultValue={productDimUnit}
                                placeholder="Dimension Unit"
                            >
                                <option value="in">in</option>
                                <option value="cm">cm</option>
                            </select>
                        </div>
                        <div>
                            <label>Length:</label>
                            <input
                                onChange={(e) => setProductLength(0 as float)} //float.parse(e.target.value)
                                required={true}
                                type="number"
                                name="length"
                                defaultValue={productLength}
                                placeholder="Length"
                            />
                        </div>
                        <div>
                            <label>Height:</label>
                            <input
                                onChange={(e) => setProductHeight(0 as float)} //float.parse(e.target.value)
                                required={true}
                                type="number"
                                name="height"
                                defaultValue={productHeight}
                                placeholder="Height"
                            />
                        </div>
                        <div>
                            <label>Width:</label>
                            <input
                                onChange={(e) => setProductWidth(0 as float)} //float.parse(e.target.value)
                                required={true}
                                type="number"
                                name="width"
                                defaultValue={productWidth}
                                placeholder="Width"
                            />
                        </div>
                    </div>
                    <div className="form_group">
                        <div>
                            <label>Retail Price:</label>
                            <input
                                onChange={(e) => setProductRetailPrice(0 as float)} //float.parse(e.target.value)
                                required={true}
                                className="money"
                                type="number"
                                name="retailPrice"
                                defaultValue={productRetailPrice}
                                placeholder="Retail Price"
                            />
                        </div>
                        <div>
                            <label>Declared Value:</label>
                            <input
                                onChange={(e) => setProductDeclaredValue(0 as float)} //float.parse(e.target.value)
                                required={true}
                                className="money"
                                type="number"
                                name="declaredValue"
                                defaultValue={productDeclaredValue}
                                placeholder="Declared Value"
                            />
                        </div>
                    </div>
                    <button type="submit" style={{ display: "none" }} onClick={(e) => psuedoModalSubmit(e)} />
                </form>
                {modalType === "update" ? (
                    <div className="button_wrapper">
                        {/* <div className="button primary form_button" ref={modalSubmitButton} onClick={handleUpdate}>
                                <FontAwesomeIcon icon={faCheck} />
                                Save
                            </div> */}
                        <div className="button delete form_button" onClick={handleDelete}>
                            Delete
                        </div>
                    </div>
                ) : // <div className="button primary form_button" ref={modalSubmitButton} onClick={handleCreate}>
                //     <FontAwesomeIcon icon={faPlus} />
                //     Save
                // </div>
                null}
            </Modal>
        </Page>
    );
}
