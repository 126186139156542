import { BACKEND_URL } from "@frontend/environment";
import axios from "axios";
import { ICreateDIYGoal, ICreatePartnerGoal, IDIYGoal, int, IPartnerGoal, IUpdateDIYGoal, IUpdatePartnerGoal } from "@common.abstractions";
import { Query, serializeQuery } from "@common.api";

export const RetrieveParterGoals = async (query?: Query<IPartnerGoal>): Promise<IPartnerGoal[] | undefined> => {
    try {
        const response = await axios.get(`${BACKEND_URL}/goals/partnerGoals`, { withCredentials: true, params: query, paramsSerializer: (q: any) => serializeQuery(q) });
        if (response.status === 200) return response.data as IPartnerGoal[];

        return undefined

    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export const RetrievePartnerGoalById = async (id: int): Promise<IPartnerGoal | undefined> => {
    try {
        const response = await axios.get(`${BACKEND_URL}/goals/partnerGoals/${id}`, { withCredentials: true });
        if (response.status === 200) return response.data as IPartnerGoal;

        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export const CreatePartnerGoal = async (params: ICreatePartnerGoal): Promise<IPartnerGoal | undefined> => {
    try {
        const response = await axios.post(`${BACKEND_URL}/goals/partnerGoals`, params, { withCredentials: true });
        if (response.status === 201) return response.data as IPartnerGoal;

        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export const UpdatePartnerGoal = async (id: int, params: IUpdatePartnerGoal): Promise<IPartnerGoal | undefined> => {
    try {
        const response = await axios.patch(`${BACKEND_URL}/goals/partnerGoals/${id}`, params, { withCredentials: true });
        if (response.status === 200) return response.data as IPartnerGoal;

        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export const DeletePartnerGoal = async (id: int): Promise<boolean | undefined> => {
    try {
        const response = await axios.delete(`${BACKEND_URL}/goals/partnerGoals/${id}`, { withCredentials: true });
        if (response.status === 204) return true;

    } catch (err: any) {
        console.log(err);
        return false;
    }
}

export const RetrieveDIYGoals = async (query?: Query<IDIYGoal>, timeFrame?: { startDate: Date, endDate: Date }): Promise<IDIYGoal[] | undefined> => {
    try {
        const params = { query: query, timeFrame: JSON.stringify(timeFrame) };

        const response = await axios.get(`${BACKEND_URL}/goals/diyGoals`, { withCredentials: true, params: params });
        if (response.status === 200) return response.data as IDIYGoal[];

        return undefined

    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export const RetrieveDIYGoalById = async (id: int): Promise<IDIYGoal | undefined> => {
    try {
        const response = await axios.get(`${BACKEND_URL}/goals/diyGoals/${id}`, { withCredentials: true });
        if (response.status === 200) return response.data as IDIYGoal;

        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export const CreateDIYGoal = async (params: ICreateDIYGoal): Promise<IDIYGoal | undefined> => {
    try {
        const response = await axios.post(`${BACKEND_URL}/goals/diyGoals`, params, { withCredentials: true });
        if (response.status === 201) return response.data as IDIYGoal;

        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export const UpdateDIYGoal = async (id: int, params: IUpdateDIYGoal): Promise<IDIYGoal | undefined> => {
    try {
        const response = await axios.patch(`${BACKEND_URL}/goals/diyGoals/${id}`, params, { withCredentials: true });
        if (response.status === 200) return response.data as IDIYGoal;

        return undefined;
    } catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export const DeleteDIYGoal = async (id: int): Promise<boolean | undefined> => {
    try {
        const response = await axios.delete(`${BACKEND_URL}/goals/diyGoals/${id}`, { withCredentials: true });
        if (response.status === 204) return true;

    } catch (err: any) {
        console.log(err);
        return false;
    }
}