import axios from "axios";
import { BACKEND_URL } from "../environment";
import { Query, serializeQuery } from "@common.api";
import { IBooking, ICreateBookingParams, int, IUpdateBookingParams } from "@common.abstractions";

export async function CreateBooking(params: ICreateBookingParams, passedUUid?: string): Promise<IBooking | undefined> {
    try {
        const payload = {
            params,
            uuid: passedUUid
        }

        const response = await axios.post(`${BACKEND_URL}/bookings`, payload, { withCredentials: true });
        if (response.status === 201) return response.data as IBooking;
        return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveBooking(bookingId: int): Promise<IBooking | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/bookings/${bookingId}`, { withCredentials: true });
        if (response.status === 200) return response.data as IBooking;
        return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveBookings(query?: Query<IBooking>): Promise<IBooking[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/bookings`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200) return response.data as IBooking[];
        return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export async function UpdateBooking(bookingId: int, params: IUpdateBookingParams): Promise<IBooking | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/bookings/${bookingId}`, params, { withCredentials: true });
        if (response.status === 200) return response.data as IBooking;
        return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteBooking(bookingId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/bookings/${bookingId}`, { withCredentials: true });
        return response.status === 200;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export async function SendBookingConfirmationEmail(bookingId: int): Promise<boolean> {
    try {
        const response = await axios.post(`${BACKEND_URL}/bookings/${bookingId}/sendConfirmation`, { withCredentials: true });
        if (response.status === 200) return true;

        return false;
    } catch (err: any) {
        console.log(err);
        return false;
    }
}