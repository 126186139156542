import { ICreateRepairsCompleted, ICreateWarrantyFootage, IRepairsCompleted, IWarrantyFootage } from "@common.abstractions";
import { BACKEND_URL } from "@frontend/environment";
import axios from "axios";



export async function CreateRepairsCompleted(params: ICreateRepairsCompleted): Promise<IRepairsCompleted | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/repairsCompleted`, { ...params }, { withCredentials: true });
        if (response.status === 201) return response.data as IRepairsCompleted;

        console.log('Failed to create repairs completed with code: ', response.status, response.data)
        return undefined
    }
    catch (err: any) {
        console.log('Creating repairs completed threw an exception: ', err);
        return undefined;
    }
}