type Comparator = "gt" | "lt" | "eq" | "bw" | "ex" | "!gt" | "!lt" | "!eq" | "!bw" | "!ex" | "in";

export type BaseFilterElement = {
    comp: Comparator;
    data: any | [any, any] | boolean | any[];
}

export interface FilterElement<T> extends BaseFilterElement {
    comp: Comparator;
    data: T | [T, T] | boolean | T[];
}

export type Filters<T> = {
    [Property in keyof T]?: FilterElement<T[Property]> | Filters<T[Property]>;
};

export interface IFilter {
    field: string;
    operator: string;
    value: string;
}

export function GreaterThan<T>(value: T): FilterElement<T> {
    return { comp: "gt", data: value };
}

export function LessThan<T>(value: T): FilterElement<T> {
    return { comp: "lt", data: value };
}

export function Equals<T>(value: T): FilterElement<T> {
    return { comp: "eq", data: value };
}

export function Between<T>(val1: T, val2: T): FilterElement<T> {
    return { comp: "bw", data: [val1, val2] };
}

export function Exists<T>(): FilterElement<T> {
    return { comp: "ex", data: true };
}

export function Not<T>(filter: FilterElement<T>): FilterElement<T> {
    return {
        comp: (filter.comp.includes("!") ? filter.comp.replace("!", "") : "!" + filter.comp) as Comparator,
        data: filter.data
    };
}

export function In<T>(values: T[]): FilterElement<T> {
    return {
        comp: "in",
        data: values
    };
}