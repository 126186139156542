import axios from "axios";
import { BACKEND_URL } from "../environment";
import { IBooking, ICreateBookingParams, int, IUpdateBookingParams } from "@common.abstractions";

export async function AuthGoogleCalendar(authCode: string): Promise<boolean> {
    try {
        const response = await axios.post(`${BACKEND_URL}/googleCalendar/auth`, { authCode }, { withCredentials: true });
        return response.status === 200;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export async function CreateGoogleCalendarEvent(bookableUserId: int, params: ICreateBookingParams, bookingId: int): Promise<boolean> {
    try {
        const response = await axios.post(`${BACKEND_URL}/googleCalendar/${bookableUserId}`, { ...params, bookingId }, { withCredentials: true });
        return response.status === 201;
    } catch (err) {
        console.log(err);
        return false;
    }
}

// export async function RetrieveGoogleCalendarEventByBookingId(bookableUserId: int, bookingId: int): Promise<IBooking | undefined> {
//     try {
//         const response = await axios.get(`${BACKEND_URL}/googleCalendar/${bookableUserId}`, { withCredentials: true });
//         if (response.status === 200) return response.data as IBooking;
//         return undefined;
//     } catch (err) {
//         console.log(err);
//         return undefined;
//     }
// }

export async function RetrieveGoogleCalendarEvents(bookableUserId: int, start?: Date, end?: Date): Promise<IBooking[] | undefined> {
    const query = { start, end };
    try {
        const response = await axios.get(`${BACKEND_URL}/googleCalendar/${bookableUserId}`, {
            params: query,
            withCredentials: true,
        });
        if (response.status === 200) return response.data as any[];
        return undefined;
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export async function UpdateGoogleCalendarEvent(bookableUserId: int, bookingId: int, params: IUpdateBookingParams): Promise<boolean> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/googleCalendar/${bookableUserId}/${bookingId}`, params, { withCredentials: true });
        return response.status === 200;
    } catch (err) {
        console.log(err);
        return false;
    }
}

export async function DeleteGoogleCalendarEvent(bookableUserId: int, bookingId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/googleCalendar/${bookableUserId}/${bookingId}`, { withCredentials: true });
        return response.status === 200;
    } catch (err) {
        console.log(err);
        return false;
    }
}
