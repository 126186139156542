import React, { useState, useEffect } from "react";
import CompanyRow from "./company-row/CompanyRow";
import { Link } from "react-router-dom";
import { RetrieveCompanies as RetrieveCompaniesService } from "../../services/companies";
import "./_companies.scss";
import { Loading } from "../misc/loading";
import { Address, CompanyType, ICompany } from "@common.abstractions";
import { convertState, groupBy } from "@common.tools";
import ReactSwitch from "react-switch";
import TopBar from "../misc/top-bar/TopBar";
import Button from "../misc/button/Button";
import SearchBar from "../misc/search-bar/SearchBar";
import { Table, TableRowWrapper } from "../misc/table";

enum CompanyTab {
    ALL = 'All',
    ACTIVE = 'Active',
    SUSPENDED = 'Suspended',
    DEACTIVATED = 'Deactivated',
    MARKETING = 'Marketing',
    NON_MARKETING = 'Non-Marketing',
}

export default function Companies() {
    const [companies, setCompanies] = useState<ICompany[]>();
    const [filteredCompanies, setFilteredCompanies] = useState<ICompany[]>();
    const [searchCriteria, setSearchCriteria] = useState<string>("");
    const [filterEverlights, setFilterEverlights] = useState<boolean>(window.localStorage.getItem("filterEverlights") === "false" ? false : true);

    const [displayCompanies, setDisplayCompanies] = useState<ICompany[]>();
    const [selectedTab, setSelectedTab] = useState<CompanyTab>(CompanyTab.ALL);

    const loadCompanies = async () => {
        let tempCompanies = await RetrieveCompaniesService({
            relations: ["packages"]
        }, true, true);
        if (tempCompanies) {
            tempCompanies = tempCompanies.sort((a: any, b: any) => a.name.localeCompare(b.name));
            tempCompanies = tempCompanies.sort((a: any, b: any) => a.address.state.localeCompare(b.address.state));
            setCompanies(tempCompanies);

            if (filterEverlights) {
                setFilteredCompanies(tempCompanies);
            } else {
                setFilteredCompanies(tempCompanies?.filter((el) => !(el.companyType === CompanyType.BRANCH)));
            }
        }
    };

    const setTabs = async () => {
        switch (selectedTab) {
            case CompanyTab.ALL:
                setDisplayCompanies(filteredCompanies);
                break;
            case CompanyTab.ACTIVE:
                setDisplayCompanies(filteredCompanies?.filter(el => !el.suspended && !el.deletedAt));
                break;
            case CompanyTab.SUSPENDED:
                setDisplayCompanies(filteredCompanies?.filter(el => el.suspended && !el.deletedAt));
                break;
            case CompanyTab.DEACTIVATED:
                setDisplayCompanies(filteredCompanies?.filter(el => el.deletedAt));
                break;
            case CompanyTab.MARKETING:
                setDisplayCompanies(filteredCompanies?.filter(el => el.monthlyMarketingSpend));
                break;
            case CompanyTab.NON_MARKETING:
                setDisplayCompanies(filteredCompanies?.filter(el => !el.monthlyMarketingSpend));
                break
            default:
                setDisplayCompanies(filteredCompanies);
                break;
        }
    }

    const handleEverlightsFilterToggle = (checked: boolean) => {
        setFilterEverlights(checked);

        if (checked) {
            window.localStorage.setItem("filterEverlights", "true");
            setFilteredCompanies(companies);
        } else {
            window.localStorage.setItem("filterEverlights", "false");
            setFilteredCompanies(companies?.filter((el) => !(el.companyType === CompanyType.BRANCH)));
        }
    };

    const handleSort = (props: (keyof ICompany)[]) => {
        if (filteredCompanies) {
            let sorted = [...filteredCompanies];

            let i = 0;
            while (props[i]) {
                const temp = (sorted as any).sort((a: any, b: any) => a[props[i]]?.localeCompare(b[props[i]]));
                sorted = temp;
                i++;
            }

            setCompanies(sorted as ICompany[]);
        }
    };

    const handleSortAddress = (props: (keyof Address)[]) => {
        if (filteredCompanies) {
            let sorted = [...filteredCompanies];

            let i = 0;
            while (props[i]) {
                const temp = (sorted as any).sort((a: any, b: any) => a.address[props[i]].localeCompare(b.address[props[i]]));
                sorted = temp;
                i++;
            }

            setCompanies(sorted as ICompany[]);
        }
    };

    const searchFilter = (entity: ICompany) => {
        const s = searchCriteria.toLowerCase();
        if (
            searchCriteria === "" ||
            entity.name.toLowerCase().includes(s) ||
            entity.address.city.toLowerCase().includes(s) ||
            entity.address.zip.toLowerCase().includes(s) ||
            entity.address.streetAddress.toLowerCase().includes(s) ||
            entity.address.state.toLowerCase().includes(s) ||
            (entity.packages && entity.packages.length > 0 && entity.packages[entity.packages.length - 1].name.toLowerCase().includes(s))
        ) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        setTabs()
    }, [selectedTab, filteredCompanies])

    useEffect(() => {
        loadCompanies();
    }, []);

    function getCompanyGroups(filteredCompanies: ICompany[]) {
        const val = groupBy(filteredCompanies, (company) => company.address.state).map((group) => {
            return {
                state: convertState(group[0].address.state, "name"),
                filteredCompanies: group,
            };
        });

        return val;
    }

    return (
        <div className="w-full h-full max-w-full">
            <div className="flex flex-col h-full w-full max-w-full overflow-hidden">
                <TopBar title="Dealers & Branches">
                    <Link to="/companies/create">
                        <Button className="mr-2" type="button" style="outline" color="blue" rounded>
                            Create Dealer or Branch
                        </Button>
                    </Link>
                    <Link to="/companies/dealerGoals">
                        <Button className="mr-2" type="button" style="outline" color="blue" rounded>
                            Dealer Targets
                        </Button>
                    </Link>
                    <Link to="/goals/diyGoals">
                        <Button className="mr-2" type="button" style="outline" color="blue" rounded>
                            DIY Targets
                        </Button>
                    </Link>
                    <Link to="/goals/partnerGoals">
                        <Button className="mr-2" type="button" style="outline" color="blue" rounded>
                            Partner Targets
                        </Button>
                    </Link>
                    <div className="flex flex-col items-center mr-8 ">
                        <div className="text-gray-500 text-sm">Everlights</div>
                        <ReactSwitch
                            offColor={"#aaa"}
                            onColor={"#3F87ED"}
                            boxShadow={"2px 2px 2px rgba(30,30,30,.2)"}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            width={40}
                            height={20}
                            onChange={(checked) => handleEverlightsFilterToggle(checked)}
                            checked={filterEverlights}
                        />
                    </div>
                    <SearchBar className="w-96 mr-8" search={setSearchCriteria} placeholder="Search Dealers" />
                </TopBar>

                <Table
                    tabOptions={{
                        tabs: [
                            { name: CompanyTab.ALL, quantity: filteredCompanies?.length },
                            { name: CompanyTab.ACTIVE, quantity: filteredCompanies?.filter((el) => !el.suspended && !el.deletedAt).length },
                            { name: CompanyTab.SUSPENDED, quantity: filteredCompanies?.filter((el) => el.suspended && !el.deletedAt).length },
                            { name: CompanyTab.DEACTIVATED, quantity: filteredCompanies?.filter((el) => el.deletedAt).length },
                            { name: CompanyTab.MARKETING, quantity: filteredCompanies?.filter((el) => el.monthlyMarketingSpend).length },
                            { name: CompanyTab.NON_MARKETING, quantity: filteredCompanies?.filter((el) => !el.monthlyMarketingSpend).length },
                        ],
                        active: selectedTab,
                        onClick: setSelectedTab
                    }}
                >
                    <div className="row header">
                        <div onClick={() => handleSort(["name"])}>
                            Dealer
                        </div>
                        <div onClick={() => handleSortAddress(["city", "state"])}>
                            Location
                        </div>
                        <div>Package</div>
                        <div>Discount</div>
                        <div>Leads</div>
                        <div>Closed</div>
                        <div>Close Rate</div>
                    </div>

                    <TableRowWrapper>
                        {displayCompanies ? (
                            displayCompanies.length ? (
                                getCompanyGroups(displayCompanies.filter((company) => searchFilter(company))).map((companyGroup, i) => {
                                    return (
                                        <>
                                            <div key={"companyGroup" + i} className="text-gray-400 text-sm py-2">
                                                {companyGroup.state}
                                            </div>
                                            {companyGroup.filteredCompanies.map((company) => (
                                                <CompanyRow key={company.id} company={company} />
                                            ))}
                                        </>
                                    );
                                })
                            ) : null
                        ) : (
                            <Loading center />
                        )}
                    </TableRowWrapper>

                </Table>

                {displayCompanies ? displayCompanies.length ? null : <div className="text-gray-500 inset-center top-72">No Companies</div> : <Loading center />}

            </div>
        </div>
    );
}
