import { BACKEND_URL } from "@frontend/environment";
import axios from "axios";
import { ICreateDealerGoalParams, IDealerGoal, int, IUpdateDealerGoalParams } from "@common.abstractions";
import { Query, serializeQuery } from "@common.api";

export async function RetrieveDealerGoals(query?: Query<IDealerGoal>): Promise<IDealerGoal[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/companies/dealerGoals`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
        if (response.status === 200) return response.data as IDealerGoal[]

        return undefined;
    }
    catch (err) {
        console.log(err);
        return undefined;
    }
}

export async function CreateDealerGoal(params: ICreateDealerGoalParams): Promise<IDealerGoal | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/companies/dealerGoals`, params, { withCredentials: true });
        if (response.status === 201) return response.data

        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function UpdateDealerGoal(goalId: int, params: IUpdateDealerGoalParams): Promise<IDealerGoal | undefined> {
    try {
        const response = await axios.patch(`${BACKEND_URL}/companies/dealerGoals/${goalId}`, params, { withCredentials: true })
        if (response.status === 200) return response.data;

        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteDealerGoal(goalId: int): Promise<boolean | undefined> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/companies/dealerGoals/${goalId}`, { withCredentials: true });
        if (response.status === 200) return true;

        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return false;
    }
}

export async function RetrieveDaysSinceLastPaidOrder(): Promise<number | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/companies/dealerGoals/daysSinceLastPaidOrder`, { withCredentials: true });
        if (response.status === 200) return response.data;

        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveDealerFtSold(timeFrame: { startDate: Date, endDate: Date }): Promise<number | undefined> {
    try {
        const startDate = timeFrame.startDate.toISOString()
        const endDate = timeFrame.endDate.toISOString()

        const response = await axios.get(`${BACKEND_URL}/companies/dealerGoals/totalFtSold?startDate=${startDate}&endDate=${endDate}`, { withCredentials: true });
        if (response.status === 200) return response.data;

        return undefined;

    } catch (error: any) {
        console.log(error);
        return undefined;
    }
}