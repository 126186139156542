import axios from "axios";
import { BACKEND_URL } from "../environment";
import { ILeadCloseRate, IMOQ, IInstallsAnalytics, ITotalSpent, int } from "@common.abstractions";

export async function RetrieveLeadCloseRates(timeframe: { start: Date; end: Date }): Promise<ILeadCloseRate[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/analytics/companies/leadCloseRate?startDate=${timeframe.start.toISOString()}&endDate=${timeframe.end.toISOString()}`, { withCredentials: true });

        // const response = await axios.get("/temp-responses/leadCloseRate.json");
        if (response.status === 200 && response.data) {
            return response.data as ILeadCloseRate[];
        }
        return undefined;
    } catch (err: any) {
        // console.log(err);
        return undefined;
    }
}

export async function RetrieveCompanyLeadCloseRates(companyId: int, timeframe: { start: Date, end: Date }) {
    try {
        const response = await axios.get(`${BACKEND_URL}/analytics/companies/leadCloseRate/${companyId}?startDate=${timeframe.start.toISOString()}&endDate=${timeframe.end.toISOString()}`, { withCredentials: true });

        if (response.status === 200 && response.data) {
            return response.data as { current: { assignedCount: number, closedCount: number }, previous: { assignedCount: number, closedCount: number } };
        }
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveTotalSpent(timeframe: { start: Date; end: Date }): Promise<ITotalSpent[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/analytics/companies/totalSpend?startDate=${timeframe.start.toISOString()}&endDate=${timeframe.end.toISOString()}`, { withCredentials: true });

        // const response = await axios.get("/temp-responses/totalSpent.json");
        if (response.status === 200 && response.data) {
            return response.data as ITotalSpent[];
        }
        return undefined;
    } catch (err: any) {
        // console.log(err);
        return undefined;
    }
}

export async function RetrieveCompanyTotalSpent(companyId: int, timeframe: { start: Date; end: Date }): Promise<{ current: number, previous: number } | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/analytics/companies/totalSpend/${companyId}?startDate=${timeframe.start.toISOString()}&endDate=${timeframe.end.toISOString()}`, { withCredentials: true });
        if (response.status === 200 && response.data) {
            return response.data
        }
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveMOQs(): Promise<IMOQ[] | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/analytics/companies/moqToDate`, { withCredentials: true });

        // const response = await axios.get("/temp-responses/moq.json");
        if (response.status === 200 && response.data) {
            return response.data as IMOQ[];
        }
        return undefined;
    } catch (err: any) {
        // console.log(err);
        return undefined;
    }
}

export async function RetrieveCompanyMOQ(companyId: int): Promise<{ moq: number, moqPurchased: number } | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/analytics/companies/moq/${companyId}`, { withCredentials: true })

        if (response.status === 200) return response.data;
    }
    catch (err: any) {
        return undefined
    }
}

export async function RetrieveInstallsAnalytics(timeframe: { start: Date; end: Date }): Promise<IInstallsAnalytics | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/analytics/companies/installsAnalytics?startDate=${timeframe.start.toISOString()}&endDate=${timeframe.end.toISOString()}`, { withCredentials: true });

        if (response.status === 200 && response.data) {
            return response.data as IInstallsAnalytics;
        }
        return undefined;
    } catch (err: any) {
        // console.log(err);
        return undefined;
    }
}
