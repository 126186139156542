import { int, IShopifyOrder } from "@common.abstractions";
import { BACKEND_URL } from "@frontend/environment";
import axios from "axios";

export async function RetrieveShopifyOrders(timeframe: { startDate: Date, endDate: Date }): Promise<IShopifyOrder[] | undefined> {
    try {
        const startDate = timeframe.startDate.toISOString()
        const endDate = timeframe.endDate.toISOString()

        const response = await axios.get(`${BACKEND_URL}/shopify/orders?created_at_min=${startDate}&created_at_max=${endDate}`, { withCredentials: true });
        if (response.status === 200) return response.data as IShopifyOrder[];
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function RetrieveShopifyTotalSales(timeframe: { startDate: Date, endDate: Date }): Promise<int | undefined> {
    try {
        const startDate = timeframe.startDate.toISOString()
        const endDate = timeframe.endDate.toISOString()

        const response = await axios.get(`${BACKEND_URL}/shopify/orders/totalSales?created_at_min=${startDate}&created_at_max=${endDate}`, { withCredentials: true });
        if (response.status === 200) return response.data as int;

        return undefined;
    }
    catch (err: any) {
        return undefined;
    }
}

export async function RetrieveShopifyOrderCount(timeframe: { startDate: Date, endDate: Date }): Promise<int | undefined> {
    try {
        const startDate = timeframe.startDate.toISOString()
        const endDate = timeframe.endDate.toISOString()

        const response = await axios.get(`${BACKEND_URL}/shopify/orders/count?created_at_min=${startDate}&created_at_max=${endDate}`, { withCredentials: true });
        if (response.status === 200) return response.data as int;

        return undefined;
    }
    catch (err: any) {
        return undefined;
    }
}

export async function RetrieveShopifyDaysSinceLastPurchase(): Promise<number | undefined> {
    try {
        const response = await axios.get(`${BACKEND_URL}/shopify/orders/daysSinceLastPurchase`, { withCredentials: true });
        if (response.status === 200) return response.data as number

        return undefined;
    }
    catch (err: any) {
        return undefined;
    }
}

export async function RetrieveShopifyFtSold(timeframe: { startDate: Date, endDate: Date }): Promise<int | undefined> {
    try {
        const startDate = timeframe.startDate.toISOString()
        const endDate = timeframe.endDate.toISOString()

        const response = await axios.get(`${BACKEND_URL}/shopify/orders/ftSold?created_at_min=${startDate}&created_at_max=${endDate}`, { withCredentials: true });
        if (response.status === 200) return response.data as int;

        return undefined;
    }
    catch (err: any) {
        return undefined;
    }
}