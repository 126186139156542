import axios from 'axios';
import { IWarrantyImage, ICreateWarrantyImageParams, int } from '@common.abstractions';
import { BACKEND_URL } from '../environment';

export async function CreateWarrantyImage(filename: string, warrantyId: int): Promise<IWarrantyImage & { uploadUrl: string } | undefined> {
    try {
        const params = { filename } as ICreateWarrantyImageParams;
        const response = await axios.post(`${BACKEND_URL}/warranty/${warrantyId}/images`, params);

        if (response.status === 201) {
            return response.data as IWarrantyImage & { uploadUrl: string };
        }
        console.log('Failed to save warranty image with code: ', response.status, response.data);
        return undefined;
    }
    catch (err: any) {
        console.log('Creating warranty image threw an exception ', err);
        return undefined;
    }
}

export async function MarkImageDeleted(imageId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/warranty/images/${imageId}`, { withCredentials: true });
        return response.status === 200;
    }
    catch (err: any) {
        console.log('Updating image threw an excepetion: ', err);
        return false;
    }
}