import { GeoPoint, IBookableUser, IBooking, IContractorJob, IQuoteRequest } from ".";
import { ICompany } from "./company";
import { int, float } from "./primitives";
import { IUser } from "./user";
import { IPostInstall } from "./post-install";

export enum ReminderEmailType {
    FIRST = 'FIRST',
    SECOND = 'SECOND',
    THIRD = 'THIRD'
}

export enum PaymentMethod {
    CASH = 'cash',
    CARD = 'card',
    FINANCE = 'finance'
}

export enum InstallType {
    PANDADOC = 'PandaDoc',
    CONTRACT = 'Contract',
}

export enum FinancingCompany {
    SUNLIGHT_FINANCIAL_SERVICES = 'sunlight_financial_services',
    MOUNTAIN_AMERICA_CU = 'mountain_america_cu',
    GREEN_SKY = 'green_sky',
}

export enum ChannelType {
    EDGE_J_STANDARD = 'Edge (J) Standard',
    EDGE_J_SPECIAL = 'Edge (J) Special',
    FLUSH_S_STANDARD = 'Flush (S) Standard',
    FLUSH_S_SPECIAL = 'Flush (S) Special',
    COMMERCIAL_J = 'Commercial J',
    COMMERCIAL_S = 'Commercial S',
}

export enum TypeOfLights {
    V3 = 'V3',
    V4 = 'V4',
    HYRST = 'HYRST',
    COMMERICIAL_V4 = 'Commercial V4',
    CLASSICS = 'Classics',
}

export interface IUpdateInstallParams {
    pandadoc_id?: string;
    auditUserId: number;
    openBalance?: int;
    stripeId?: string;
    installDate?: Date | null;
    warrantySent?: Date | null;
    warrantySigned?: Date | null;
    warrantyId?: string | null;
    invoiceSent?: Date | null;
    invoicePaid?: Date | null;
    invoiceId?: string | null;
    invoiceLink?: string | null;
    paymentLink?: string | null;
    billInvoiceId?: string | null;
    billCustomerId?: string | null;
    total?: int;
    reminderEmail?: { sentAt: Date, type: ReminderEmailType }[]
    jobCompletedDate?: Date | null;
    hubspotDealId?: string | null;
    hubspotContactId?: string | null;
    paymentMethod?: PaymentMethod | null;
    financingCompany?: FinancingCompany | null;
    houseLevel?: float | null;
    notes?: string | null;
    channelColor?: string | null;
    downPayment?: int | null;
    amountFinanced?: int | null;
    financingCharge?: int | null;
    loanNumber?: string | null;
    sales_rep?: string;
    channelType?: ChannelType | null;
    typeOfLights?: TypeOfLights | null;
    channel?: { ft: int | null, type: string | null, color: string | null }[] | null;
    bookableUsers?: IBookableUser[] | null;
}

export interface ICreateInstallParams {
    auditUserId: number;
    transactionDate: Date;
    pandaDocId: string | null;
    stripeId: string | null;
    branch: ICompany;
    salesRep: IUser | null;
    invoiceId: string | null;
    warrantyId: string | null;
    customer: {
        firstName: string;
        lastName: string | null;
        address: string;
        email: string;
        phone: string;
    };
    validatedAddress: string | null;
    warrantySent: Date | null;
    warrantySigned: Date | null;
    invoiceSent: Date | null;
    invoicePaid: Date | null;
    total: int;
    subtotal: int;
    downPayment: int;
    openBalance: int;
    salesTaxElligible: int;
    salesTaxInelligible: int;
    salesTax: int;
    cashDiscount: int;
    installDate: Date | null;
    createdAt: Date | undefined;
    paymentMethod: PaymentMethod | null;
    quoteRequest: IQuoteRequest | null;
    typeOfLights?: TypeOfLights | null;
    lotNumber?: string | null;
    ftSold?: int | null;
    contractorJobId?: int | null;
    hubspotContactId?: string | null;

    pandadocTotal: int | null;
    amountFinanced: int | null;
    financingCharge: int | null;
    loanNumber: string | null;
    financingCompany: FinancingCompany | null;

    rawDocument: any | null; // Use Interface?
    bookings?: IBooking[];
    channelType?: ChannelType | null;
    channel: { ft: int | null, type: string | null, color: string | null }[] | null;
}

export interface IInstall {
    id: int;
    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
    auditUserId: number;

    transactionDate: Date;
    pandaDocId: string | null;
    stripeId: string | null;
    invoiceId: string | null;
    billInvoiceId: string | null;
    billCustomerId: string | null;
    warrantyId: string | null;
    branch: ICompany;
    salesRep: IUser | null;
    customer: {
        firstName: string;
        lastName: string | null;
        address: string;
        geoPoint?: GeoPoint
        email: string;
        phone: string;
    };
    validatedAddress: string | null;
    warrantySent: Date | null;
    warrantySigned: Date | null;
    invoiceSent: Date | null;
    invoicePaid: Date | null;
    invoiceLink: string | null;
    paymentLink: string | null;
    total: int;
    subtotal: int;
    downPayment: int;
    openBalance: int;
    salesTaxElligible: int;
    salesTaxInelligible: int;
    salesTax: int;
    cashDiscount: int;
    installDate: Date | null;
    paymentMethod: PaymentMethod | null;
    ftSold: int | null;
    postInstalls: IPostInstall[]
    rawDocument: any; // Use Interface?
    reminderEmail: { sentAt: Date, type: ReminderEmailType }[]
    jobCompletedDate: Date | null;
    financingCompany: FinancingCompany | null;
    houseLevel: float | null;
    notes: string | null;
    channelColor: string | null;
    typeOfLights: TypeOfLights | null;
    lotNumber: string | null;
    contractorJob: IContractorJob | null;

    pandadocTotal: int | null;
    amountFinanced: int | null;
    financingCharge: int | null;
    loanNumber: string | null;

    bookings: IBooking[];
    channelType: ChannelType | null;
    channel: { ft: int | null, type: string | null, color: string | null }[] | null;
    bookableUsers: IBookableUser[] | null;
}
