import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading } from "@frontend/components/misc/loading";
import { useEffect, useState } from "react";
import { RowData } from "../models/rowData";
import Booking from "./Booking";
import { HoursIn12Format } from "./CalendarTimes";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { PositioningMode } from "./Booking";

import "react-datepicker/dist/react-datepicker.css";
import ExternalBooking from "./ExternalBooking";
import { IAvailability } from "@common.abstractions";
import { GetDate } from "@common.tools";
import UnavailableSlot from "./UnavailableSlot";
import React from "react";

type Props = {
    className?: string;
    children?: any;

    filter?: {
        options: { label: string; value: string | number }[];
        onChange: any; //function that runs when changed;
    };

    data: {
        rows: RowData[];
    };
    selectedDate: Date;
    // onCellClick?: (e: DOMRect, dateTime: Date, row: { label: string; value: number }) => any;
    onCellClick?: (rect: DOMRect, startDate: Date, endDate: Date, row: { label: string; value: number }) => any;

    onBookingClick?: any;
    onDateChange: any;
    mode: string; // "day" | "week" | "month"
    onWeekChange?: any;
};

interface DayDetails {
    day: string;  // Represents the day of the week (e.g., Mon, Tue, Wed)
    date: number; // Represents the day of the month
    month: number; // Represents the month (0 for January, 11 for December)
    year: number; // Represents the year
    timeSlots: { hour: number, value: number }[]; // Represents time slots for the day
}

export function Calendar(props: Props) {
    const { className, filter, data, onCellClick, onBookingClick, onDateChange, selectedDate, mode, onWeekChange } = props;
    const [initialLoad, setInitialLoad] = useState<boolean>(true);
    const [hours, setHours] = useState<DayDetails[]>([]);

    const [startTime, setStartTime] = useState<number>(
        window.localStorage.getItem("portal_startTime") ? Number(window.localStorage.getItem("portal_startTime")) : 0
    );
    const [endTime, setEndTime] = useState<number>(window.localStorage.getItem("portal_endTime") ? Number(window.localStorage.getItem("portal_endTime")) : 24);

    const [weekStart, setWeekStart] = useState<Date>(new Date());
    const [weekEnd, setWeekEnd] = useState<Date>(new Date());


    type PartOfDay = 'full day' | 'half day';
    const partsOfDay: PartOfDay[] = ['full day', 'half day'];

    const getSegmentTime = (partOfDay: PartOfDay): string => {
        const times: Record<PartOfDay, string> = {
            'full day': "08:00",
            'half day': "12:00"
        };
        return times[partOfDay] || "00:00";
    };

    useEffect(() => {
        if (mode === "week") {
            const currentDate = new Date(selectedDate);
            currentDate.setHours(0, 0, 0, 0); // Normalize time to avoid timezone issues
            // Adjust to start the week on Monday
            const dayOffset = currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1; // If Sunday (0), adjust by 6 to get last Monday, otherwise adjust to last Monday
            const startOfWeek = new Date(currentDate);
            startOfWeek.setDate(currentDate.getDate() - dayOffset);

            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(startOfWeek.getDate() + 6); // Complete the week

            setWeekStart(startOfWeek);
            setWeekEnd(endOfWeek);

            const newHours = [];
            for (let i = 0; i < 5; i++) { // Use 7 for full week coverage
                const dayDate = new Date(startOfWeek);
                dayDate.setDate(startOfWeek.getDate() + i);
                const day = dayDate.toLocaleDateString("en-US", { weekday: 'short' });
                const date = dayDate.getDate();
                const month = dayDate.getMonth();
                const year = dayDate.getFullYear();
                const timeSlots = Array.from({ length: 24 }, (_, hour) => ({ hour, value: i * 1440 + hour * 60 }));

                newHours.push({ day, date, month, year, timeSlots });
            }
            setHours(newHours);
            if (onWeekChange) {
                onWeekChange(startOfWeek, endOfWeek);
            }
        }
    }, [mode, selectedDate]);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getDateFormat = () => {
        return screenWidth > 768 ? "MMM d, yyyy" : "MM/dd";
    };

    return (
        <>
            <div className="flex items-center justify-between z-20 max-w-full mb-2 lg:w-full px-4 lg:px-0">
                <button
                    className="text-gray-700 bg-gray-100 hover:bg-gray-200 border-gray-300 border p-2 rounded-lg shadow-sm hover:shadow-md transition ease-in-out duration-150 w-20 text-sm"
                    onClick={() => onDateChange(new Date())}
                >
                    Today
                </button>
                {mode == "week" && (
                    <div className="flex-grow flex justify-center items-center">
                        <button
                            className="focus:outline-none ml-2 md:ml-4"
                            onClick={() => onDateChange(new Date(selectedDate.getTime() - 7 * 24 * 60 * 60 * 1000))}
                        >
                            <FontAwesomeIcon
                                className="text-md text-gray-500 hover:text-gray-400 transform hover:-translate-x-1 duration-150 cursor-pointer"
                                icon={faChevronLeft}
                            />
                        </button>

                        <div className="flex items-center">
                            <DatePicker
                                dateFormat={getDateFormat()}
                                onChange={(date) => onDateChange(date as Date)}
                                selected={weekStart}
                                className="input focus:ring-0 ring-0 text-gray-500 font-semibold text-lg text-center bg-transparent"
                            />
                            <span className="text-gray-500 font-bold text-lg mx-2">-</span>
                            <DatePicker
                                dateFormat={getDateFormat()}
                                onChange={(date) => onDateChange(date as Date)}
                                selected={weekEnd}
                                className="input focus:ring-0 ring-0 text-gray-500 font-semibold text-lg text-center bg-transparent"
                            />
                        </div>
                        <button
                            className="focus:outline-none mr-2 md:mr-4 "
                            onClick={() => onDateChange(new Date(selectedDate.getTime() + 7 * 24 * 60 * 60 * 1000))}
                        >
                            <FontAwesomeIcon
                                className="text-md text-gray-500 hover:text-gray-400 transform hover:translate-x-1 duration-150 cursor-pointer"
                                icon={faChevronRight}
                            />
                        </button>
                    </div>
                )}
            </div>
            <div className={`${className ?? ""} h-full w-full max-w-full overflow-auto relative`}>
                {!hours ? (
                    <Loading center />
                ) : (
                    <>
                        <table className="w-full">
                            <thead className="border-r border-l border-b border-t border-gray-200">
                                <tr className="text-gray-500 text-sm font-thin bg-white">
                                    {/* Filter or Placeholder Column */}
                                    {filter ? (
                                        <th className="top-0 border-collapse sticky z-10 w-6">
                                            <div className="h-full px-2 bg-white">
                                                <select
                                                    className="focus:outline-none bg-transparent h-full py-4 px-2"
                                                    onChange={(e) => filter.onChange(Number(e.target.value))}
                                                >
                                                    {filter.options.map((option) => (
                                                        <option key={`options ${option.value}`} value={option.value}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </th>
                                    ) : (
                                        <th className="bg-white top-0 z-10 border-collapse p-2 leading-none sticky py-4 font-normal text-xs"></th>
                                    )}

                                    {/* Days and Dates */}
                                    {hours.map((day) => (
                                        <th
                                            key={`day ${day.day}`}
                                            className="bg-white top-0 z-10 border-collapse p-2 leading-none sticky py-4 font-normal text-xs"
                                        >
                                            <div className="flex justify-center items-center">
                                                <h1 className={`mr-1 font-bold text-xl ${day.date === new Date().getDate() ? 'text-red-500' : ''} `}>{day.date}</h1>
                                                <h1>{day.day}</h1>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white border-r border-l border-b border-gray-200 border-collapse">
                                {data.rows.map((row, rowIndex) => (
                                    <tr key={`Calendar Row ${rowIndex}`} className="relative leading-none shadow-sm text-sm group hover:bg-gray-100">
                                        <td className="p-4 leading-none w-6 border border-gray-200">
                                            <div className="text-gray-500 group-hover:text-gray-800 text-sm">{row.label}</div>
                                        </td>

                                        {hours.map((day) => (
                                            <td key={`day ${day.date}-${day.month}-${day.year}`} className="relative border border-gray-200">
                                                <div className="relative px-4 py-6 w-full" style={{ minWidth: "100px" }}>
                                                    {row.bookings?.filter(booking => {
                                                        // Check if the booking is on this day
                                                        const bookingStart = new Date(booking.start);
                                                        return bookingStart.getDate() === day.date &&
                                                            bookingStart.getMonth() === day.month && // Use day.month instead of new Date(selectedDate).getMonth()
                                                            bookingStart.getFullYear() === day.year; // Use day.year instead of new Date(selectedDate).getFullYear()
                                                    }).map(filteredBooking => (
                                                        <Booking
                                                            positioningMode={PositioningMode.HOURLY_SEGMENTS}
                                                            key={`booking ${filteredBooking.id}`}
                                                            row={row}
                                                            booking={filteredBooking}
                                                            onBookingClick={onBookingClick}
                                                        />
                                                    ))}

                                                    {partsOfDay.map((partOfDay, index) => {
                                                        const segmentTime = getSegmentTime(partOfDay);
                                                        const hours = parseInt(segmentTime.split(':')[0]);
                                                        const minutes = parseInt(segmentTime.split(':')[1]);

                                                        // Create a new Date object using the day's month and year
                                                        const startDate = new Date(day.year, day.month, day.date, hours, minutes, 0, 0);

                                                        // Determine the duration of each segment
                                                        const endDate = new Date(startDate.getTime());
                                                        if (partOfDay === 'full day') {
                                                            endDate.setHours(17, 0, 0, 0); // Set end time to 5 PM
                                                        } else {
                                                            endDate.setHours(endDate.getHours() + 5); // Assuming half day is 5 hours duration
                                                        }
                                                        return (
                                                            <div
                                                                key={`${day.date}-${day.month}-${partOfDay}`}
                                                                onClick={(e) => {
                                                                    if (onCellClick) {
                                                                        onCellClick(e.currentTarget.getBoundingClientRect(), startDate, endDate, {
                                                                            label: row.label,
                                                                            value: row.value as number,
                                                                        });
                                                                    }
                                                                }}
                                                                className={`absolute w-1/2 h-full bottom-0 ${index > 0 ? 'border-l-0' : ''}`}
                                                                style={{ left: `${index / 3 * 100}%` }}
                                                            ></div>
                                                        );
                                                    })}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}
            </div>
        </>
    );
}
