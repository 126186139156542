import React, {useEffect, useState} from "react";
import AuthContext, {IUserAuthContext, UserAuthContext} from "@frontend/contexts/AuthContext";
import {Loading} from "@frontend/components/misc/loading";
import {refreshToken} from "@frontend/services/token-service";

const AuthContextProvider: React.FC<any> = (props) => {
    const [authContextData, setAuthContextData] = useState<IUserAuthContext>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const interval = setInterval(async () => {
            const success = await refreshToken();

            if(!success) {
                // Logging out
                authContextData?.logout();
            }

        }, 30 * 1_000);

        const auth = new UserAuthContext(setAuthContextData);
        setAuthContextData(auth);

        auth.checkLoginStatus().then(() => setLoading(false))

        return () => clearInterval(interval)

    }, []);

    if (loading) return <Loading center />;

    return (
        <>
            <AuthContext.Provider value={authContextData}>
                {props.children}
            </AuthContext.Provider>
        </>
    );
};

export default AuthContextProvider;