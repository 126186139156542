import { BACKEND_URL } from "@frontend/environment";
import axios from "axios";

export async function GetGeoPointFromAddressString(address: string) {
    try {
        const response = await axios.post(`${BACKEND_URL}/utils/getGeoPoint`, { address }, { withCredentials: true });
        if (response.status === 200) {
            return response.data as { type: string; latlng: [number, number] };
        }
		return undefined;
    } catch (err: any) {
        console.log("Error obtaining geopoint from address string", err);
        return undefined;
    }
}
