import axios from 'axios';
import { BACKEND_URL } from '../environment';
import { Query, serializeQuery } from '@common.api'
import { ICompany, ICreateDiscountPackageParams, IUpdateDiscountPackageParams, IDiscountPackage, int } from '@common.abstractions';

export async function CreateDiscountPackage(companyId: int, params: ICreateDiscountPackageParams): Promise<ICompany | undefined> {
    try {
        const response = await axios.post(`${BACKEND_URL}/companies/${companyId}/discountPackages`, params, { withCredentials: true });
        if (response.status === 201)
            return response.data as ICompany;
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function UpdateDealerPackage(dealerPackageId: int, params: IUpdateDiscountPackageParams): Promise<IDiscountPackage | undefined> {
    try {
        const response = await axios.put(`${BACKEND_URL}/companies/discountPackages/${dealerPackageId}`, params, { withCredentials: true })
        if (response.status === 200) {
            return response.data as IDiscountPackage
        }
        return undefined;
    }
    catch (err: any) {
        console.log(err);
        return undefined;
    }
}

export async function DeleteDealerPackage(dealerPackageId: int): Promise<boolean> {
    try {
        const response = await axios.delete(`${BACKEND_URL}/companies/discountPackages/${dealerPackageId}`, { withCredentials: true });
        if (response.status === 204) return true;
        return false;
    }
    catch (err: any) {
        console.log(err);
        return false;
    }
}

// export async function RetrieveDealerPackage(dealerPackageId: int): Promise<IDiscountPackage | undefined> {
//     try {
//         const response = await axios.get(`${BACKEND_URL}/dealerPackages/${dealerPackageId}`, { withCredentials: true });
//         if (response.status === 200)
//             return response.data as IDiscountPackage;
//         return undefined;
//     }
//     catch (err: any) {
//         console.log(err);
//         return undefined;
//     }
// }

// export async function RetrieveDealerPackages(query?: Query<IDiscountPackage>): Promise<IDiscountPackage[] | undefined> {
//     try {
//         const response = await axios.get(`${BACKEND_URL}/dealerPackages`, { params: query, paramsSerializer: (q: any) => serializeQuery(q), withCredentials: true });
//         if (response.status === 200)
//             return (response.data as any[]).map(val => val as IDiscountPackage);
//         return undefined;
//     }
//     catch (err: any) {
//         console.log(err);
//         return undefined;
//     }
// }
