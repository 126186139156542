import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { RetrieveCompanies } from "@frontend/services/companies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Dropdown } from "react-multi-select-component";
import Select from "react-select";
import { valueContainerCSS } from "react-select/src/components/containers";
import { toast } from "react-toastify";
import { RetrieveLeadCloseRates, RetrieveMOQs, RetrieveTotalSpent } from "@frontend/services/analytics";
import { calcPercentage, formatMoney, GetDate } from "@common.tools";
import ReactSwitch from "react-switch";
import { ILeadCloseRate, ITotalSpent, IMOQ, CompanyType } from "@common.abstractions";
import { MOQ, LeadCloseRate, TotalSpent } from '../components'

export default function AdminDashboard() {
    const [filterEverlights, setFilterEverlights] = useState<boolean>(window.localStorage.getItem("filterEverlights") === "false" ? false : true);
    const [filterBestToWorst, setFilterBestToWorst] = useState<boolean>(window.localStorage.getItem("filterBestToWorst") === "false" ? false : true);
    const [previewSize, setPreviewSize] = useState<number>(3);
    const [timeFrame, setTimeFrame] = useState<{
        start: Date;
        end: Date;
        name: string
    }>({ start: GetDate.currentMonth(), end: GetDate.tomorrow(), name: 'monthToDate' });
    const [titleSubstrLength, setTitleSubstrLength] = useState<number>(40);

    const handleTimeChange = (value: any) => {
        setTimeFrame({ start: value.start, end: value.end, name: value.name });
    };
    const changeTitleTextLength = () => {
        if (window.innerWidth > 1700) {
            setTitleSubstrLength(40);
        } else if (window.innerWidth <= 1700 && window.innerWidth > 1500) {
            setTitleSubstrLength(30);
        } else if (window.innerWidth <= 1500 && window.innerWidth > 1450) {
            setTitleSubstrLength(25);
        } else if (window.innerWidth <= 1450 && window.innerWidth > 1350) {
            setTitleSubstrLength(20);
        } else if (window.innerWidth <= 1350 && window.innerWidth > 1275) {
            setTitleSubstrLength(15);
        } else if (window.innerWidth <= 1275 && window.innerWidth > 1150) {
            setTitleSubstrLength(20);
        } else if (window.innerWidth <= 1150 && window.innerWidth > 1060) {
            setTitleSubstrLength(15);
        } else if (window.innerWidth <= 1060 && window.innerWidth > 1024) {
            setTitleSubstrLength(12);
        } else if (window.innerWidth <= 1024 && window.innerWidth > 930) {
            setTitleSubstrLength(30);
        } else if (window.innerWidth <= 930 && window.innerWidth > 875) {
            setTitleSubstrLength(25);
        } else if (window.innerWidth <= 875 && window.innerWidth > 800) {
            setTitleSubstrLength(20);
        } else if (window.innerWidth <= 800 && window.innerWidth > 768) {
            setTitleSubstrLength(15);
        } else if (window.innerWidth <= 768 && window.innerWidth > 600) {
            setTitleSubstrLength(45);
        } else if (window.innerWidth <= 600 && window.innerWidth && window.innerWidth > 500) {
            setTitleSubstrLength(30);
        } else if (window.innerWidth <= 500 && window.innerWidth && window.innerWidth > 420) {
            setTitleSubstrLength(20);
        } else if (window.innerWidth <= 420 && window.innerWidth) {
            setTitleSubstrLength(15);
        }
    };
    const handleEverlightsFilterToggle = (checked: boolean) => {
        setFilterEverlights(checked);
        if (checked) {
            window.localStorage.setItem("filterEverlights", "true");
        } else {
            window.localStorage.setItem("filterEverlights", "false");
        }
    };
    const handleBestToWorstFilterToggle = (checked: boolean) => {
        setFilterBestToWorst(checked);
        if (checked) {
            window.localStorage.setItem("filterBestToWorst", "true");
        } else {
            window.localStorage.setItem("filterBestToWorst", "false");
        }
    };

    useEffect(() => {
        window.addEventListener("resize", () => changeTitleTextLength());
        changeTitleTextLength();

        return window.removeEventListener("resize", () => changeTitleTextLength());
    }, []);

    const showLeadCloseRateDetails = () => {
        window.location.href = `dashboard/details/?dataType=Lead Close Rate&time=${timeFrame.name}&customStart=${timeFrame.start.toISOString().split('T')[0]}&customEnd=${timeFrame.end.toISOString().split('T')[0]}`

    }

    const showMOQDetails = () => {
        window.location.href = `dashboard/details/?dataType=MOQ`
    }

    const showTotalSpentDetails = () => {
        window.location.href = `dashboard/details/?dataType=Total Spent&time=${timeFrame.name}&customStart=${timeFrame.start.toISOString().split('T')[0]}&customEnd=${timeFrame.end.toISOString().split('T')[0]}`
    }

    return (
        <div className="page flex flex-col">

            <div className="flex justify-between mb-5 p-1 flex-wrap min-w-full">
                <h1 className="text-2xl text-gray-500 uppercase">Dashboard</h1>
                <div className="flex mb-5 items-center">
                    <div>
                        <Select
                            className="quotes_date_dropdown"
                            defaultValue={{
                                value: {
                                    start: GetDate.currentMonth(),
                                    end: GetDate.tomorrow(),
                                    name: "monthToDate",
                                },
                                label: "Month to Date",
                            }}
                            options={[
                                {
                                    value: {
                                        start: GetDate.january(),
                                        end: GetDate.tomorrow(),
                                        name: "yearToDate",
                                    },
                                    label: "Year to Date",
                                },
                                {
                                    value: {
                                        start: GetDate.currentMonth(),
                                        end: GetDate.tomorrow(),
                                        name: "monthToDate",
                                    },
                                    label: "Month to Date",
                                },
                                {
                                    value: {
                                        start: GetDate.everlightsBirth(),
                                        end: GetDate.tomorrow(),
                                        name: "allTime",
                                    },
                                    label: "All Time",
                                },
                                {
                                    value: {
                                        start: GetDate.january(2023),
                                        end: GetDate.endOfYear(2023),
                                        name: "2023"
                                    },
                                    label: '2023'
                                },
                                {
                                    value: {
                                        start: GetDate.january(2022),
                                        end: GetDate.endOfYear(2022),
                                        name: "2022"
                                    },
                                    label: '2022'
                                },
                                {
                                    value: {
                                        start: GetDate.january(2021),
                                        end: GetDate.endOfYear(2021),
                                        name: "2021"
                                    },
                                    label: '2021'
                                },
                                {
                                    value: {
                                        start: new Date(),
                                        end: new Date(),
                                        name: 'custom'
                                    },
                                    label: 'Custom Range'
                                },
                                {
                                    value: {
                                        start: GetDate.january(),
                                        end: GetDate.february(),
                                        name: "january",
                                    },
                                    label: "January",
                                },
                                {
                                    value: {
                                        start: GetDate.february(),
                                        end: GetDate.march(),
                                        name: "february",
                                    },
                                    label: "February",
                                },
                                {
                                    value: {
                                        start: GetDate.march(),
                                        end: GetDate.april(),
                                        name: "march",
                                    },
                                    label: "March",
                                },
                                {
                                    value: {
                                        start: GetDate.april(),
                                        end: GetDate.may(),
                                        name: "april",
                                    },
                                    label: "April",
                                },
                                {
                                    value: {
                                        start: GetDate.may(),
                                        end: GetDate.june(),
                                        name: "may",
                                    },
                                    label: "May",
                                },
                                {
                                    value: {
                                        start: GetDate.june(),
                                        end: GetDate.july(),
                                        name: "june",
                                    },
                                    label: "June",
                                },
                                {
                                    value: {
                                        start: GetDate.july(),
                                        end: GetDate.august(),
                                        name: "july",
                                    },
                                    label: "July",
                                },
                                {
                                    value: {
                                        start: GetDate.august(),
                                        end: GetDate.september(),
                                        name: "august",
                                    },
                                    label: "August",
                                },
                                {
                                    value: {
                                        start: GetDate.september(),
                                        end: GetDate.october(),
                                        name: "september",
                                    },
                                    label: "September",
                                },
                                {
                                    value: {
                                        start: GetDate.october(),
                                        end: GetDate.november(),
                                        name: "october",
                                    },
                                    label: "October",
                                },
                                {
                                    value: {
                                        start: GetDate.november(),
                                        end: GetDate.december(),
                                        name: "november",
                                    },
                                    label: "November",
                                },
                                {
                                    value: {
                                        start: GetDate.december(),
                                        end: GetDate.nextJanuary(),
                                        name: "december",
                                    },
                                    label: "December",
                                },
                            ]}
                            onChange={(val) => {
                                if (val?.value.name === 'custom') {
                                    setTimeFrame({ start: timeFrame.start, end: timeFrame.end, name: 'custom' })
                                    return;
                                }
                                handleTimeChange(val?.value);
                            }}
                        />
                    </div>
                    <div className="flex flex-col items-end ml-6">
                        <div className="text-gray-500 text-sm">Everlights</div>
                        <ReactSwitch
                            offColor={"#aaa"}
                            onColor={"#3F87ED"}
                            boxShadow={"2px 2px 2px rgba(30,30,30,.2)"}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            width={40}
                            height={20}
                            onChange={(checked) => handleEverlightsFilterToggle(checked)}
                            checked={filterEverlights}
                        />
                    </div>
                    <div className="flex flex-col items-end ml-6">
                        <div className="text-gray-500 text-sm">Sort by Best</div>
                        <ReactSwitch
                            offColor={"#aaa"}
                            onColor={"#3F87ED"}
                            boxShadow={"2px 2px 2px rgba(30,30,30,.2)"}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            width={40}
                            height={20}
                            onChange={(checked) => handleBestToWorstFilterToggle(checked)}
                            checked={filterBestToWorst}
                        />
                    </div>
                </div>
            </div>

            {timeFrame.name === 'custom' &&
                <div className="flex justify-end space-x-4">
                    <input
                        type="date"
                        className="flex border hover:border-input_hover text-center"
                        defaultValue={timeFrame.start.toISOString().split("T")[0]}
                        onBlur={(e) => {
                            const date = new Date(e.target.value).getTime();

                            if (isNaN(date)) {
                                return;
                            }
                            setTimeFrame({ start: new Date(date), end: timeFrame.end, name: 'custom' })
                        }}
                    />
                    <input
                        type="date"
                        className="flex border hover:border-input_hover text-center"
                        defaultValue={timeFrame.end.toISOString().split("T")[0]}
                        onBlur={(e) => {
                            const date = new Date(e.target.value).getTime();

                            if (isNaN(date)) {
                                return;
                            }
                            setTimeFrame({ start: timeFrame.start, end: new Date(date), name: 'custom' })
                        }}
                    />
                </div>
            }

            <div className="grid mb-5 gap-6 p-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-h-full overflow-y-auto">
                <LeadCloseRate timeFrame={timeFrame} titleSubstrLength={titleSubstrLength} filterEverlights={filterEverlights} filterBestToWorst={filterBestToWorst} previewSize={previewSize} onClick={showLeadCloseRateDetails} />
                <MOQ timeFrame={timeFrame} titleSubstrLength={titleSubstrLength} filterEverlights={filterEverlights} filterBestToWorst={filterBestToWorst} previewSize={previewSize} onClick={showMOQDetails} />
                <TotalSpent timeFrame={timeFrame} titleSubstrLength={titleSubstrLength} filterEverlights={filterEverlights} filterBestToWorst={filterBestToWorst} previewSize={previewSize} onClick={showTotalSpentDetails} />

                {/* 
                <Link to={`/dashboard/details/?dataType=Leads Contributed`} className="p-5 shadow-md hover:shadow-lg duration-theme h-60 opacity-70 hover:opacity-100 cursor-pointer">
                    <div className="text-gray-500 mb-3">Leads Contributed</div>
                    <div className="flex items-end mb-4">
                        <div className="text-3xl text-gray-700 font-bold">61</div>
                        <div className="flex items-center ml-2  text-theme_red">
                            <FontAwesomeIcon className="text-xs" icon={faArrowDown} />
                            <span className="ml-1">5%</span>
                        </div>
                    </div>
                    <div className="flex flex-col border-gray-200 border-b">
                        <div className="flex justify-between py-2">
                            <div className="text-gray-600">
                                {`EverLights St.George`.length > titleSubstrLength
                                    ? `EverLights St.George`.substring(0, titleSubstrLength) + " . . ."
                                    : `EverLights St.George`}
                            </div>
                            <div className="flex">
                                <div className="inline-flex items-center mr-3 font-semibold text-gray-700">34</div>
                                <div className="inline-flex items-center w-14">
                                    <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                    <span className="ml-1 text-gray-500">2.27%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col border-gray-200 border-b">
                        <div className="flex justify-between py-2">
                            <div className="text-gray-600">
                                {`Cache Valley Hac`.length > titleSubstrLength
                                    ? `Cache Valley Hac`.substring(0, titleSubstrLength) + " . . ."
                                    : `Cache Valley Hac`}
                            </div>
                            <div className="flex">
                                <div className="inline-flex items-center mr-3 font-semibold text-gray-700">12</div>
                                <div className="inline-flex items-center w-14">
                                    <FontAwesomeIcon className="text-xs text-theme_red" icon={faArrowDown} />
                                    <span className="ml-1 text-gray-500">7%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col border-gray-200">
                        <div className="flex justify-between py-2">
                            <div className="text-gray-600">
                                {`Infinity Power and Controls`.length > titleSubstrLength
                                    ? `Infinity Power and Controls`.substring(0, titleSubstrLength) + " . . ."
                                    : `Infinity Power and Controls`}
                            </div>
                            <div className="flex">
                                <div className="inline-flex items-center mr-3 font-semibold text-gray-700">10</div>
                                <div className="inline-flex items-center w-14">
                                    <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                    <span className="ml-1 text-gray-500">22%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link> */}
                {/* 
                <Link to={`/dashboard/details/?dataType=In vs Out of Territory Sales`} className="p-5 shadow-md hover:shadow-lg duration-theme h-60 opacity-70 hover:opacity-100 cursor-pointer">
                    <div className="text-gray-500 mb-3">In vs Out of Territory Sales</div>
                    <div className="flex items-end mb-4">
                        <div className="text-3xl text-gray-700 font-bold">32</div>
                        <div className="flex items-center ml-2  text-theme_green">
                            <FontAwesomeIcon className="text-xs" icon={faArrowUp} />
                            <span className="ml-1">21%</span>
                        </div>
                    </div>
                    <div className="flex flex-col border-gray-200 border-b">
                        <div className="flex justify-between py-2">
                            <div className="text-gray-600">
                                {`Outdoor Lighting Perspectives of Northbay, CA`.length > titleSubstrLength
                                    ? `Outdoor Lighting Perspectives of Northbay, CA`.substring(0, titleSubstrLength) + " . . ."
                                    : `Outdoor Lighting Perspectives of Northbay, CA`}
                            </div>
                            <div className="flex">
                                <div className="inline-flex items-center mr-3 font-semibold text-gray-700">7</div>
                                <div className="inline-flex items-center w-14">
                                    <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                    <span className="ml-1 text-gray-500">2.27%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col border-gray-200 border-b">
                        <div className="flex justify-between py-2">
                            <div className="text-gray-600">
                                {`Outdoor Lighting Perspectives - Reno`.length > titleSubstrLength
                                    ? `Outdoor Lighting Perspectives - Reno`.substring(0, titleSubstrLength) + " . . ."
                                    : `Outdoor Lighting Perspectives - Reno`}
                            </div>
                            <div className="flex">
                                <div className="inline-flex items-center mr-3 font-semibold text-gray-700">5</div>
                                <div className="inline-flex items-center w-14">
                                    <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                    <span className="ml-1 text-gray-500">22%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col border-gray-200">
                        <div className="flex justify-between py-2">
                            <div className="text-gray-600">
                                {`Loera's Landscape`.length > titleSubstrLength
                                    ? `Loera's Landscape`.substring(0, titleSubstrLength) + " . . ."
                                    : `Loera's Landscape`}
                            </div>
                            <div className="flex">
                                <div className="inline-flex items-center mr-3 font-semibold text-gray-700">4</div>
                                <div className="inline-flex items-center w-14">
                                    <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                    <span className="ml-1 text-gray-500">4.56%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>

                <Link to={`/dashboard/details/?dataType=Self Generated Closes`} className="p-5 shadow-md hover:shadow-lg duration-theme h-60 opacity-70 hover:opacity-100 cursor-pointer">
                    <div className="text-gray-500 mb-3">Self Generated Closes</div>
                    <div className="flex items-end mb-4">
                        <div className="text-3xl text-gray-700 font-bold flex">22</div>
                        <div className="flex items-center ml-2  text-theme_green">
                            <FontAwesomeIcon className="text-xs" icon={faArrowUp} />
                            <span className="ml-1">12%</span>
                        </div>
                    </div>
                    <div className="flex flex-col border-gray-200 border-b">
                        <div className="flex justify-between py-2">
                            <div className="text-gray-600">
                                {`Outdoor Lighting Perspectives of Northbay, CA`.length > titleSubstrLength
                                    ? `Outdoor Lighting Perspectives of Northbay, CA`.substring(0, titleSubstrLength) + " . . ."
                                    : `Outdoor Lighting Perspectives of Northbay, CA`}
                            </div>
                            <div className="flex">
                                <div className="inline-flex items-center mr-3 font-semibold text-gray-700">3</div>
                                <div className="inline-flex items-center w-14">
                                    <FontAwesomeIcon className="text-xs text-theme_red" icon={faArrowDown} />
                                    <span className="ml-1 text-gray-500">0%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col border-gray-200 border-b">
                        <div className="flex justify-between py-2">
                            <div className="text-gray-600">
                                {`Outdoor Lighting Perspectives - Reno`.length > titleSubstrLength
                                    ? `Outdoor Lighting Perspectives - Reno`.substring(0, titleSubstrLength) + " . . ."
                                    : `Outdoor Lighting Perspectives - Reno`}
                            </div>
                            <div className="flex">
                                <div className="inline-flex items-center mr-3 font-semibold text-gray-700">2</div>
                                <div className="inline-flex items-center w-14">
                                    <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                    <span className="ml-1 text-gray-500">50%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col border-gray-200">
                        <div className="flex justify-between py-2">
                            <div className="text-gray-600">
                                {`Loera's Landscape`.length > titleSubstrLength
                                    ? `Loera's Landscape`.substring(0, titleSubstrLength) + " . . ."
                                    : `Loera's Landscape`}
                            </div>
                            <div className="flex">
                                <div className="inline-flex items-center mr-3 font-semibold text-gray-700">2</div>
                                <div className="inline-flex items-center w-14">
                                    <FontAwesomeIcon className="text-xs text-theme_green" icon={faArrowUp} />
                                    <span className="ml-1 text-gray-500">100%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link> */}


            </div>
        </div>
    );
}
