import React from "react";

interface ITableTabBarProps {
    tabOptions: {
        tabs: {
            name: string;
            quantity?: any
            amount?: string | number;
        }[];
        active: string;
        onClick: any;
    };
}

export function TableTabBar(props: ITableTabBarProps) {
    const { tabOptions } = props;

    return (
        <div className="w-full border-b border-gray-200 text-theme_gold overflow-x-auto overflow-y-hidden">
            <div className="flex items-end pb-2">
                {tabOptions.tabs.map((tab, i) => (
                    <button
                        key={`tabOptions ${i}`}
                        type="button"
                        onClick={() => tabOptions.onClick(tab.name)}
                        className={`ml-2 mr-2 whitespace-nowrap flex items-center px-4 py-2 sm:px-3 sm:py-2 lg:px-4 lg:py-2 rounded-tl-lg rounded-tr-lg outline-none focus:outline-none transform translate-y-1 ${tab.name === tabOptions.active ? "bg-white text-theme_gold border-gray-200 border-l border-t border-r" : "text-xs bg-gray-200 text-gray-500"
                            }`}
                    >
                        <div className="">{tab.name}</div>
                        {tab.quantity !== undefined && (
                            <div
                                className={`ml-2 flex h-6 sm:h-5 lg:h-6 justify-center items-center text-xs rounded-full px-2 sm:px-2 lg:px-3 ${tab.name === tabOptions.active ? "bg-gray-200 text-gray-500" : "bg-gray-400 text-gray-100"
                                    }`}
                            >
                                {tab.quantity}
                            </div>
                        )}
                        {tab.amount !== undefined && (
                            <div className={`ml-2 flex items-center ${tab.name === tabOptions.active ? "text-md" : "text-xs"}`}>
                                {tab.amount}
                            </div>
                        )}
                    </button>
                ))}
            </div>
        </div>
    );
}

interface ITableProps {
    tabOptions?: {
        tabs: {
            name: string;
            quantity?: any;
            amount?: string | number;
        }[];
        active: string;
        onClick: any;
    };
    children: any;
    className?: string;
}

export function Table(props: ITableProps) {
    const { tabOptions, children, className } = props;

    return (
        <>
            {tabOptions &&
                <TableTabBar tabOptions={tabOptions} />
            }
            <div className={`h-full w-full max-w-full overflow-auto relative ${className ? className : ""}`}>
                <table className="text-left w-full relative border-collapse flex-grow">{children}</table>
            </div>
        </>
    );
}
