import Button from '@frontend/components/misc/button/Button'
import TopBar from '@frontend/components/misc/top-bar/TopBar'
import React, { useEffect, useState } from 'react'
import { CompanyType, ICompany, int, IProduct, IProductVariant, ICreateShippingAddressParams, IShippingItem, IShippingLabelItem, VariantAttribute } from '@common.abstractions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { RetrieveProduct, RetrieveProductCategories, RetrieveProducts } from '@frontend/services/products'
import { TableTabBar } from '@frontend/components/misc/table'
import { Modal } from '@frontend/components/misc/modal'
import { formatMoney } from '@common.tools'
import { toast } from 'react-toastify'
import { RetrieveCompanies, RetrieveCompany, RetrieveShippingAddresses } from '@frontend/services/companies'
import { Equals } from '@common.api'
import Select from 'react-select'
import SideCart2 from '../side-cart/SideCart2'
import { useNavigate } from "react-router-dom-v5-compat";
import { CreateShippingLabel } from '@frontend/services/orders'

type ShortProductCategory = {
    name: string,
    products: IProduct[]
};

export default function ShippingLabel() {
    let [activeSlide, setActiveSlide] = useState<number>(1);
    const navigate= useNavigate();

    const [categories, setCategories] = useState<ShortProductCategory[]>();
    const [selectedCategory, setSelectedCategory] = useState<ShortProductCategory>();
    const [modalVisibility, setModalVisibility] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<IProduct>();

    const [sideCartVisibility, setSideCartVisibility] = useState<boolean>(false);
    const [sideCartInitialLoad, setSideCartInitialLoad] = useState<boolean>(false);

    const [selectedCombo, setSelectedCombo] = useState<VariantAttribute[]>([]);
    const [selectedVariant, setSelectedVariant] = useState<IProductVariant>();
    const [variantExists, setVariantExists] = useState<boolean>(true);
    const [dropdowns, setDropdowns] = useState<{ name: string; values: string[] }[]>([]);
    const [hasVariants, setHasVariants] = useState<boolean>(false);
    const [selectedQty, setSelectedQty] = useState<int>(1 as int);

    const [shippingLabelItems, setShippingLabelItems] = useState<IShippingItem[]>([]);

    const [dealers, setDealers] = useState<ICompany[]>();
    const [dealer, setDealer] = useState<ICompany>();
    const [shippingAddress, setShippingAddress] = useState<ICreateShippingAddressParams[] | undefined>();
    const [submittedStreetAddress, setSubmittedStreetAddress] = useState<string>();
    const [submittedCity, setSubmittedCity] = useState<string>();
    const [submittedState, setSubmittedState] = useState<string>();
    const [submittedZip, setSubmittedZip] = useState<string>();
    const [submittedCountry, setSubmittedCountry] = useState<string>();
    const [submittedShippingAddress, setSubmittedShippingAddress] = useState<ICreateShippingAddressParams>();

    const handleSideCartActivation = () => {
        setSideCartVisibility(true);
        setSideCartInitialLoad(true);
    };

    const loadProductCategories = async () => {
        const response = await RetrieveProducts({
            relations: ['variants'],
        })
        if (!response) {
            console.log('Could not load products');
            return;
        }

        response.sort((a, b) => {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;

            return 0;
        });

        const allCategory: ShortProductCategory = {
            name: 'All',
            products: response
        }

        const tempProductCategories = await RetrieveProductCategories({
            relations: ['products', 'products.variants']
        }) as ShortProductCategory[] | undefined

        if (!tempProductCategories) {
            console.log('Failed to load product categories');
            return;
        }

        const productCategories = [allCategory, ...tempProductCategories];

        setCategories(productCategories);
        setSelectedCategory(allCategory);
    }

    const setTab = (name: string) => {
        const tabs = categories?.filter((el) => el.name === name);
        setSelectedCategory(tabs![0]);
    };

    const openModal = async (productId: int) => {
        setModalVisibility(true);
        const product = await RetrieveProduct(productId);
        if (!product) {
            toast.error('Something went wrong');
            return;
        }

        setSelectedProduct(product);
        checkHasVariants(product);
    }

    const checkHasVariants = (product: IProduct) => {
        if (product && product.variants) {
            setSelectedVariant(product.variants[0] as IProductVariant);

            if (product.variants.length === 1) {
                if ((product.variants[0] as IProductVariant).attributes.length === 0) {
                    setHasVariants(false);
                } else {
                    createVariantDropdowns(product);
                    setHasVariants(true);
                }
            } else {
                createVariantDropdowns(product);
                setHasVariants(true);
            }
        }
    };

    const createVariantDropdowns = (product: IProduct) => {
        let tempDropdowns: { name: string; values: string[] }[] = [];
        (product!.variants as IProductVariant[]).forEach((el, i) => {
            el.attributes.forEach((attr, index) => {
                if (!tempDropdowns[index]) {
                    //Add the name to the object at array[i] if that index doesn't exist yet
                    tempDropdowns.push({ name: attr.name, values: [] });
                }
                if (!tempDropdowns[index].values.some((val) => val === attr.value)) {
                    //push the value to the values array in the object at array[i] if doesn't exist already
                    tempDropdowns[index].values.push(attr.value);
                }
            });
        });

        setDropdowns(tempDropdowns);

        let tempSelectedCombo: { name: string; value: string }[] = [];

        tempDropdowns.forEach((el, i) => {
            if (!tempSelectedCombo[i]) {
                tempSelectedCombo.push({ name: el.name, value: el.values[0] });
            } else {
                tempSelectedCombo[i].name = el.name;
                tempSelectedCombo[i].value = el.values[0];
            }
        });

        setSelectedCombo(tempSelectedCombo);
    };

    const handleComboChange = (value: string, i: number) => {
        let tempSelectedCombo = [...selectedCombo];
        tempSelectedCombo[i].value = value;
        setSelectedCombo(tempSelectedCombo);
        let variantExists: boolean = false;

        //Checks if the tempSelected Combo exists as a variant
        (selectedProduct!.variants as IProductVariant[]).find((el) => {
            if (el.attributes.length === tempSelectedCombo.length) {
                if (
                    !el.attributes.some(
                        (val, index) => val.value !== tempSelectedCombo[index].value
                    )
                ) {
                    setSelectedVariant(el);
                    variantExists = true;
                }
            }
        });

        setVariantExists(variantExists);
    };

    const createShippingLabelOrderItem = async (shippingItemArr: IShippingItem[]) => {
        const shippingProductVarsQty = await Promise.all(shippingItemArr.map(async (item) => {
            const testing: IShippingLabelItem = {
                productVariantId: item.productVariant.id,
                quantity: item.quantity
            }
            return testing
        }))
        return shippingProductVarsQty
    }

    const addShippingItems = (selectedVariant: IProductVariant) => {
        if (!selectedVariant) toast.error('Could not add shipping item');

        const shippingLabelItem: IShippingItem = {
            title: selectedProduct?.title,
            productVariant: selectedVariant,
            quantity: selectedQty
        }

        setShippingLabelItems([...shippingLabelItems, shippingLabelItem]);
        setSelectedQty(int(1));
        setModalVisibility(false);
    }

    const deleteAddedItem = (itemIndex: number) => {
        if (itemIndex === undefined) {
            toast.error('Item does not exist');
            return;
        }
        const newList = shippingLabelItems.filter((el, index) => index !== itemIndex)
        setShippingLabelItems(newList);
    }

    const editItemQty = (itemIndex: number, itemQty: number) => {
        const item = shippingLabelItems.find((el, index) => itemIndex === index);
        if (!item) {
            console.log('Item does not exist');
            return;
        }

        if ((itemQty) === 0) {
            deleteAddedItem(itemIndex);
            return;
        }

        item.quantity = int(itemQty);

        setShippingLabelItems([...shippingLabelItems]);
    }

    const handleAddShippingAddressBtn = () => {
        if (activeSlide === 1) {
            setActiveSlide(2);
            setSideCartVisibility(false);
            return;
        }
        setActiveSlide(1);
        setDealer(undefined);
        setShippingAddress(undefined);
    }

    useEffect(() => {
        loadProductCategories();
    }, []);

    const loadDealers = async () => {
        const dealers = await RetrieveCompanies(
            {
                filters: {
                    companyType: Equals(CompanyType.DEALER)
                }
            }
        )
        if (!dealers) {
            console.log('Could not load companies');
            return;
        }
        setDealers(dealers);
    }

    const selectedDealer = async (companyId: int) => {
        const selectedDealer = await RetrieveCompany(companyId);
        if (!selectedDealer) {
            toast.error('Error selecting company');
            return;
        }
        setDealer(selectedDealer);

        const shippingAddresses: ICreateShippingAddressParams[] | undefined = await RetrieveShippingAddresses(selectedDealer.id);
        if (!shippingAddresses) {
            console.warn('Could not load shipping address');
        }

        setShippingAddress(shippingAddresses);
        setSubmittedCity(undefined);
        setSubmittedCountry(undefined);
        setSubmittedState(undefined);
        setSubmittedZip(undefined);
        setSubmittedStreetAddress(undefined);
        setSubmittedShippingAddress(undefined);
    }

    const getAddress = (address: ICreateShippingAddressParams | undefined) => {
        if (!address) {
            console.log('Failed to load address');
            return;
        }
        setSubmittedCity(address.city);
        setSubmittedCountry(address.country);
        setSubmittedState(address.state);
        setSubmittedZip(address.zip);
        setSubmittedStreetAddress(address.streetAddress);
    }

    const submitAddress = () => {
        if (!submittedStreetAddress || !submittedCity || !submittedState || !submittedZip || !submittedCountry) {
            toast.error('Missing required field(s)');
            return;
        }

        const formValues: ICreateShippingAddressParams = {
            streetAddress: submittedStreetAddress,
            city: submittedCity,
            state: submittedState,
            zip: submittedZip,
            country: submittedCountry
        }
        if (!formValues) {
            toast.error('Error adding address');
            return;
        }

        setSubmittedShippingAddress(formValues);
    }

    const submitShippingForm = async () => {
        if (!shippingLabelItems) {
            toast.error('Missing products')
            return;
        }

        if (!submittedShippingAddress) {
            toast.error('Missing address');
            return;
        }

        if (!dealer) {
            toast.error('Missing company');
            return;
        }

        const shipmentVar = await createShippingLabelOrderItem(shippingLabelItems);
        const response = await CreateShippingLabel({ items: shipmentVar, shippingAddress: submittedShippingAddress, company: dealer });

        if (!response) {
            toast.error('Create shipment error failed');
            return;
        }

        toast.success('Shipment created');
        navigate('/orders');
    }

    useEffect(() => {
        loadDealers();
    }, []);

    return (
        <div className='w-full h-full overflow-scroll'>
            <SideCart2
                handleShipping={handleAddShippingAddressBtn}
                deleteCartItem={deleteAddedItem}
                editQty={editItemQty}
                visible={sideCartVisibility}
                shippingItems={shippingLabelItems}
                closeSideCart={() => setSideCartVisibility(false)}
                openSideCart={() => handleSideCartActivation()}
                loaded={sideCartInitialLoad}
                slide={activeSlide}
            />
            {activeSlide === 1 && (
                <div>
                    <TopBar title="Create shipping label"></TopBar>
                    {categories && (
                        <TableTabBar
                            tabOptions={{
                                tabs: categories.map((c) => {
                                    return { name: c.name };
                                }),
                                active: selectedCategory ? selectedCategory.name : "none",
                                onClick: setTab,
                            }}
                        />
                    )}

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2 md:gap-4 overflow-y-auto">
                        {selectedCategory?.products?.map((product) => (
                            <div
                                key={product.id}
                                className="flex flex-col h-52 items-center justify-center bg-white cursor-pointer shadow-md hover:shadow-lg duration-200"
                            >
                                <div className="mb-4 px-4">
                                    <h3 className="text-lg text-gray-500 text-center">{product.title}</h3>
                                </div>
                                <Button
                                    className="mt-4"
                                    rounded
                                    style="outline"
                                    color="blue"
                                    onClick={() => openModal(product.id)}
                                >
                                    Select Options
                                    <FontAwesomeIcon
                                        className="ml-2 text-xs"
                                        icon={faChevronRight}
                                    />
                                </Button>
                            </div>
                        ))}
                    </div>

                    {selectedProduct ? (
                        <Modal
                            key={selectedProduct.id}
                            visible={modalVisibility}
                            closeModal={() => setModalVisibility(false)}
                        >
                            <div className="p-8 max-w-6xl">
                                <div>
                                    <h3 className="text-xl text-gray-600 text-center mb-3">{selectedProduct.title}</h3>
                                </div>
                                {selectedVariant?.description ? (
                                    <div className="text-sm text-gray-500 mb-3 overflow-y-auto max-h-96">
                                        {selectedVariant.description}
                                    </div>
                                ) : (
                                    <div className="text-sm text-gray-500 mb-3 overflow-y-auto max-h-96">
                                        {selectedProduct.description}
                                    </div>
                                )}
                                {hasVariants ? (
                                    <div className="flex mb-3">
                                        {dropdowns.map((el, i) => {
                                            return (
                                                <div
                                                    className="flex flex-col mr-5"
                                                    key={`${el.name} + ${el.values.join("")} `}
                                                >
                                                    <label className="text-gray-500 text-sm">{el.name}</label>
                                                    <select
                                                        className="text-gray-600 text-md border-gray-300 p-1 border"
                                                        onChange={(e) => handleComboChange(e.target.value, i)}
                                                    >
                                                        {el.values.map((el) => {
                                                            return (
                                                                <option key={el} value={el}>
                                                                    {el}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    {!variantExists ? (
                                                        <div className="text-theme_red text-sm">
                                                            Variant is not available
                                                        </div>
                                                    ) : null}
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}

                                <div className="flex flex-col mb-3 w-20">
                                    <label className="text-gray-500 text-sm">Amount</label>
                                    <input
                                        min="1"
                                        type="number"
                                        className="text-gray-600 text-md border-gray-300 p-1 border"
                                        value={selectedQty}
                                        onChange={(e) => setSelectedQty(int.parse(e.target.value))}
                                    />
                                </div>

                                <div className="flex flex-col mb-3">
                                    <label className="text-gray-500">Cost</label>
                                    <div>
                                        <div className="product_cost text-gray-500">
                                            {formatMoney(selectedProduct.retailPrice)}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex mb-3 items-end justify-between">
                                    <div className="flex flex-col">
                                        <label className="text-gray-500">Total</label>
                                        <div className="flex text-gray-800 text-xl">
                                            <div>
                                                {selectedVariant?.retailPrice
                                                    ? formatMoney(selectedVariant.retailPrice * selectedQty)
                                                    : formatMoney(
                                                        selectedProduct.retailPrice * selectedQty
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="btn bg-theme_blue ml-4 text-white text-sm"
                                        onClick={() => {
                                            if (selectedVariant) {
                                                addShippingItems(selectedVariant);
                                            }
                                        }}
                                    >
                                        Add to Cart
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    ) : null}
                </div>
            )}

            {activeSlide === 2 && (
                <div>
                    <TopBar title='Add shipping Address'></TopBar>
                    <div className='mt-8 flex justify-center'>
                        <Button className='text-base py-0 uppercase' rounded style="outline" color='blue' onClick={() => handleAddShippingAddressBtn()}>
                            <FontAwesomeIcon className='mr-2' icon={faChevronLeft} />
                            Product Catalog
                        </Button>
                    </div>

                    <div>
                        <label>Select Dealer</label>
                        <Select
                            className='quotes_data_dropdown mb-3'
                            menuPosition='fixed'
                            defaultValue={{
                                value: dealer?.id,
                                label: dealer?.name
                            }}
                            options={dealers?.map((el) => ({
                                value: el.id,
                                label: el.name,
                            }))}
                            onChange={(val) => {
                                if (val?.value) {
                                    selectedDealer(val?.value)
                                }
                            }}
                        />

                        {shippingAddress &&
                            <Select
                                className='quotes_data_dropdown mb-3'
                                menuPosition='fixed'
                                options={shippingAddress?.map((el) => ({
                                    value: el,
                                    label: `${el.streetAddress} ${el.city}, ${el.state} ${el.zip}`
                                }))}
                                onChange={(val) => {
                                    if (val) {
                                        getAddress(val.value);
                                    }
                                }}
                            />
                        }

                        <form>
                            <div className='form_group'>
                                <div>
                                    <label>Street Address</label>
                                    <input
                                        className='form-control'
                                        value={submittedStreetAddress}
                                        onChange={(e) => {
                                            setSubmittedStreetAddress(e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <label>City</label>
                                    <input
                                        className='form-control'
                                        value={submittedCity}
                                        onChange={(e) => {
                                            setSubmittedCity(e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <label>State</label>
                                    <input
                                        className='form-control'
                                        value={submittedState}
                                        onChange={(e) => {
                                            setSubmittedState(e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <label>Country</label>
                                    <input
                                        className='form-control'
                                        value={submittedCountry}
                                        onChange={(e) => {
                                            setSubmittedCountry(e.target.value);
                                        }}
                                    />
                                </div>
                                <div>
                                    <label>Zip Code</label>
                                    <input
                                        className='form-control'
                                        value={submittedZip}
                                        onChange={(e) => {
                                            setSubmittedZip(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </form>

                        <div className='flex justify-start'>
                            <Button type='button' color='blue' style='outline' onClick={() => submitAddress()}>Submit Address</Button>
                            <h2 className='ml-6'>{`Submitted Address: ${submittedShippingAddress?.streetAddress}, ${submittedShippingAddress?.city}, ${submittedShippingAddress?.state}, ${submittedShippingAddress?.country}, ${submittedShippingAddress?.streetAddress}, ${submittedShippingAddress?.zip}`}</h2>
                        </div>

                        <div className='flex justify-center mt-10'>
                            {(shippingLabelItems && submittedShippingAddress) &&
                                <Button className='uppercase' type='button' color='green' style='solid' onClick={() => submitShippingForm()}>Submit Form</Button >
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}