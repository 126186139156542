import { int } from '@common.abstractions';
import React from 'react'

type ftSoldProps = {
    data: {
        branchName: string;
        totalFtSold: int | undefined;
    }[],
    totals?: int;
}

export default function FtSold(props: ftSoldProps) {
    return (
        <div className="p-5 shadow-md hover:shadow-lg duration-theme h-auto opacity-70 hover:opacity-100">
            <div className="flex">
                <h2 className="text-gray-500 mb-3">Total Ft Sold </h2>
                <p className="text-3xl text-gray-700 font-bold ml-3">{(props.totals)}</p>
            </div>
            {props.data?.map((data, i) => {
                return (
                    <div key={i} className={`flex flex-col border-gray-200 border-b`}>
                        <div
                            className="flex justify-between py-2"
                        >
                            <div className="text-gray-600">{data.branchName}</div>
                            <div className="flex">
                                <div className="inline-flex items-center w-14">
                                    <span className="ml-1 text-gray-500">{data.totalFtSold}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}