export let BACKEND_URL: string;
export let ENVIRONMENT: string;
export const VERSION = "2.0.1";

export let GOOGLE_CALENDAR_CLIENT_ID: string;
export let GOOGLE_CALENDAR_REDIRECT_URI: string;

if (window.location.hostname.split(".")[0] === "portaldev") {
    console.log("\nSTAGING ENVIRONMENT\n\n");
    BACKEND_URL = "https://portaldev.myeverlights.com/api/v1";
    ENVIRONMENT = "STAGING";
    GOOGLE_CALENDAR_CLIENT_ID = "145988702214-sec3a71mbn33nb3l50vuk1ht8rvuckn5.apps.googleusercontent.com";
    GOOGLE_CALENDAR_REDIRECT_URI = "https://portaldev.myeverlights.com/app/calendarAuth";
} else if (window.location.hostname.split(".")[0] === "portal") {
    BACKEND_URL = "https://portal.myeverlights.com/api/v1";
    ENVIRONMENT = "PRODUCTION";
    GOOGLE_CALENDAR_CLIENT_ID = "145988702214-sec3a71mbn33nb3l50vuk1ht8rvuckn5.apps.googleusercontent.com";
    GOOGLE_CALENDAR_REDIRECT_URI = "https://portal.myeverlights.com/app/calendarAuth";
} else if (window.location.hostname === "localhost") {
    console.log("\nDEVELOPMENT ENVIRONMENT\n\n");
    BACKEND_URL = "http://localhost:8080/v1";
    ENVIRONMENT = "DEVELOPMENT";
    GOOGLE_CALENDAR_CLIENT_ID = "145988702214-sec3a71mbn33nb3l50vuk1ht8rvuckn5.apps.googleusercontent.com";
    GOOGLE_CALENDAR_REDIRECT_URI = "http://localhost:3000/app/calendarAuth";
} else {
    console.log("Location Hostname Invalid:", window.location.hostname);
}
