import { int } from ".";

export interface IUpdatePandaDocTemplateParams {
    templateId?: string;
    templateName?: string;
}

export interface ICreatePandaDocTemplateParams {
    templateId: string;
    companyId?: int | null;
    templateName: string;
}

export interface IPandaDocTemplate {
    id: int;
    deletedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;

    templateId: string;
    templateName: string;

    templateType: PandaDocTemplateType;
    templateProperties?: any;
}

export enum PandaDocTemplateType {
    LIGHTING_PROPOSAL = 'LightingProposal',
    REPAIR_PROPOSAL = 'RepairProposal',
    THIRD_PARTY_CONTRACT = 'ThirdPartyContract',
    WARRANTY = 'WarrantyRegistration',
    DEALER_PROGRAM_AGREEMENT = 'DealerProgramAgreement',
    CHANGE_ORDER = 'ChangeOrder',
    IGNORE = 'Ignore'
}