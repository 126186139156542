import { useContext, useEffect } from "react";
import AuthContext from "../../contexts/AuthContext";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { CreateCompany } from "../companies/create-company";
import { AdminDashboard, DealerDashboard } from "../dashboard";
import InstallsDash from "../dashboard/installs-dash/InstallsDash";

import { Companies, Company, UpdateCompany } from "../companies";
import { CreateCompanyUser } from "../users";
import UpdateUser from '../users/update-user/UpdateUser';
import { Contact, Contacts } from "../contacts";
import { Quote, Quotes } from "../quotes";
import { CreateProduct, Products } from "../products";
import { Checkout, CreateOrder, Orders, Payment, ReviewOrder, Shipping } from "../orders";
import ShippingLabel from "../orders/shipping/ShippingLabel";

import Goals from '../companies/company-goals/Goals'
import DealerGoals from "../companies/company-goals/DealerGoals";
import PartnerGoals from "../goals/PartnerGoals";
import DIYGoals from "../goals/DIYGoals";

import { AnimatePresence, motion } from "framer-motion";
import { Leaflet } from "../leaflet";
import { DashboardDetails } from "../dashboard/details";
import { Scope } from "@common.abstractions";
import { NotFound } from "../not-found";
import EverlightsLogoIconOnly from "../../assets/LogoColor.png";
import { ENVIRONMENT } from "@frontend/environment";
import { Lead, CreateLead } from "../leads";
import { Banner } from "../misc/banner";
import { Account, ACHTransfer } from "../account";
import { Finances } from "../finances/finances";
import Addresses from "../account/addresses/Addresses";
import Installs from "../installs/Installs";
import Leads from "../leads/Leads";
import BranchInstalls from "../installs/details/branchInstalls";
import Popup from "../misc/popup/Popup";
import Users from "../users/Users";
import User from "../users/user/User";
import EditProduct from "../products/edit-product/EditProduct";
import { Calendars } from "../calendar";
import GoogleAuthRedirect from "../account/google-auth-redirect/GoogleAuthRedirect";
import WarrantyCreate from "../warranty-claim/WarrantyCreate";
import Warranty from "../warranty-claim/Warranty";
import InstallerTool from "../installer-tool/InstallerTool";
import InstallerCheckList from "../installer-tool/InstallerCheckList";
import SmsPage from "../sms/Sms";
import OperationsCalendar from "../calendar/OperationsCalendar";
import ToastCalendar from "../calendar/ToastCalendar";
import Repairs from "../repairs/Repairs";
import RepairCheckList from "../installer-tool/RepairCheckList";

export default function LoggedInRoutes() {
    const location = useLocation();
    const authContext = useContext(AuthContext);

    const hasAnyGrant = (...grants: Scope[]) => {
        return authContext?.hasAnyGrant(...grants)
    }

    const loginStatusIntervalCheck = async () => {
        if (!authContext) return;
        const response = await authContext?.checkLoginStatus();
        if (response.err || !response.user || !response.success) {
            authContext?.logout();
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            loginStatusIntervalCheck();
        }, 15000);
        return () => clearInterval(interval);
    }, []);

    return (
        <AnimatePresence mode='wait'>
            <motion.div
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                key={location.pathname}
                className="page_transition_wrapper"
            >

                {/* <Banner hideKey="productPriceUpdate">
                    Attention Dealers! Please be informed that the product pricing for 2023 will be updated on April 1. If you want to take advantage of the 2022 pricing before then, please submit your orders by April 1.
                </Banner> */}

                <main className="main">
                    <Switch location={location}>
                        {/* Root */}
                        {hasAnyGrant(Scope.Report.READ_ANY) && <Route exact path="/"><Redirect to={'/dashboard'} /></Route>}
                        {hasAnyGrant(Scope.Company.READ) && <Route exact path="/"><Redirect to={'/dealer/dashboard'} /></Route>}

                        {/* Dashboards */}
                        {hasAnyGrant(Scope.Report.READ_ANY) && <Route exact path="/dashboard" component={AdminDashboard} />}
                        {hasAnyGrant(Scope.Report.READ) && <Route exact path="/dealer/dashboard" component={DealerDashboard} />}
                        {hasAnyGrant(Scope.Report.READ_ANY, Scope.Report.READ) && <Route exact path="/dashboard/details" component={DashboardDetails} />}
                        {hasAnyGrant(Scope.Report.READ_ANY) && <Route exact path="/dashboard/installs" component={InstallsDash} />}

                        {/* COMPANY ROUTES */}
                        {hasAnyGrant(Scope.Company.READ_ANY) && <Route exact path="/companies" component={Companies} />}
                        {hasAnyGrant(Scope.Report.READ_ANY) && <Route exact path='/companies/dealerGoals' component={DealerGoals} />}
                        {hasAnyGrant(Scope.Company.CREATE_ANY) && <Route exact path="/companies/create" component={CreateCompany} />}
                        {hasAnyGrant(Scope.Company.READ_ANY) && <Route exact path="/companies/:companyId" component={Company} />}
                        {hasAnyGrant(Scope.Installs.READ_ANY) && <Route exact path='/companies/:companyId/goals' component={Goals} />}
                        {hasAnyGrant(Scope.User.READ_ANY, Scope.User.UPDATE_ANY) && <Route exact path="/companies/:companyId/users" component={Company} />}
                        {hasAnyGrant(Scope.Company.CREATE_ANY) && <Route exact path="/companies/:companyId/users/new" component={CreateCompanyUser} />}
                        {hasAnyGrant(Scope.Company.READ_ANY) && <Route exact path="/companies/:companyId/leads" component={Company} />}
                        {hasAnyGrant(Scope.Company.UPDATE_ANY) && <Route exact path="/companies/:companyId/edit" component={UpdateCompany} />}
                        {hasAnyGrant(Scope.Company.READ) && <Route exact path="/leads" component={Leads} />}
                        {hasAnyGrant(Scope.Company.READ) && <Route exact path="/leads/create" component={CreateLead} />}
                        {hasAnyGrant(Scope.Company.READ) && <Route exact path="/leads/:leadId" component={Lead} />}

                        {/* GOALS */}
                        {hasAnyGrant(Scope.Report.READ_ANY) && <Route exact path="/goals/diyGoals" component={DIYGoals} />}
                        {hasAnyGrant(Scope.Report.READ_ANY) && <Route exact path="/goals/partnerGoals" component={PartnerGoals} />}

                        {/* WARRANTY ROUTES  */}
                        {hasAnyGrant(Scope.Warranty.CREATE) && <Route exact path="/leads/:leadId/warranty/:warrantyId" component={WarrantyCreate} />}
                        {hasAnyGrant(Scope.Warranty.READ) && <Route exact path="/warranty/:warrantyId" component={Warranty} />}

                        {/* USER ROUTES */}
                        {hasAnyGrant(Scope.User.READ, Scope.User.READ_ANY) && <Route exact path="/users" component={Users} />}
                        {hasAnyGrant(Scope.User.READ, Scope.User.READ_ANY) && <Route exact path="/users/:userId" component={User} />}
                        {hasAnyGrant(Scope.User.UPDATE_ANY) && <Route exact path="/users/:userId/edit" component={UpdateUser} />}

                        {/* CONTACT ROUTES */}
                        {hasAnyGrant(Scope.Contact.READ_ANY) && <Route exact path="/contacts" component={Contacts} />}
                        {hasAnyGrant(Scope.Contact.READ_ANY) && <Route exact path="/contacts/:contactId" component={Contact} />}

                        {/* QUOTE REQUESTS */}
                        {hasAnyGrant(Scope.QuoteRequest.READ_ANY) && <Route exact path="/quoteRequests" component={Quotes} />}
                        {hasAnyGrant(Scope.QuoteRequest.READ_ANY) && <Route exact path="/quoteRequests/:quoteRequestId" component={Quote} />}

                        {/* ORDER ROUTES */}
                        {hasAnyGrant(Scope.Order.READ_ANY, Scope.Order.READ, Scope.Lead.CHILD_READ_ANY) && <Route exact path="/orders" component={Orders} />}
                        {hasAnyGrant(Scope.Order.READ_ANY, Scope.Order.READ, Scope.Lead.CHILD_READ_ANY) && <Route exact path="/orders/:orderId/" component={ReviewOrder} />}
                        {hasAnyGrant(Scope.Order.READ_ANY, Scope.Order.READ) && <Route exact path="/orders/:orderId/create" component={CreateOrder} />}
                        {hasAnyGrant(Scope.Order.READ) && <Route exact path="/orders/:orderId/shipping" component={Shipping} />}
                        {hasAnyGrant(Scope.Order.READ) && <Route exact path="/orders/:orderId/payment" component={Payment} />}
                        {hasAnyGrant(Scope.Order.READ) && <Route exact path="/orders/:orderId/checkout" component={Checkout} />}
                        {hasAnyGrant(Scope.Order.CREATE_ANY) && <Route exact path='/shippingLabel' component={ShippingLabel} />}

                        {/* PRODUCTS ROUTES */}
                        {hasAnyGrant(Scope.Product.READ_ANY) && <Route exact path="/products" component={Products} />}
                        {hasAnyGrant(Scope.Product.CREATE_ANY) && <Route exact path="/products/new" component={CreateProduct} />}
                        {hasAnyGrant(Scope.Product.READ_ANY) && <Route exact path="/products/:productId" component={EditProduct} />}

                        {/* Dealer Map */}
                        {hasAnyGrant(Scope.Company.READ_ANY, Scope.Company.READ) && <Route exact path="/map" component={Leaflet} />}

                        {/* Account */}
                        {hasAnyGrant(Scope.Company.READ) && <Route exact path="/account" component={Account} />}
                        {hasAnyGrant(Scope.Company.UPDATE) && <Route exact path="/account/ACH" component={ACHTransfer} />}
                        {hasAnyGrant(Scope.Company.UPDATE) && <Route exact path="/account/addresses" component={Addresses} />}

                        {/* Finances */}
                        {hasAnyGrant(Scope.Finances.READ_ANY) && <Route exact path="/finances" component={Finances} />}

                        {/* Installs */}
                        {hasAnyGrant(Scope.Installs.READ) && <Route exact path="/installs" component={Installs} />}
                        {hasAnyGrant(Scope.Installs.READ_ANY) && <Route exact path="/installs/details" component={BranchInstalls} />}

                        {/* Calendar */}
                        {hasAnyGrant(Scope.Booking.READ) && <Route exact path="/calendar" component={Calendars} />}
                        {hasAnyGrant(Scope.Booking.READ) && <Route exact path="/operationsCalendar" component={OperationsCalendar} />}
                        {hasAnyGrant(Scope.Booking.READ) && <Route exact path="/toastCalendar" component={ToastCalendar} />}

                        {/* Installer Tool */}
                        {hasAnyGrant(Scope.PostInstall.READ) && <Route exact path="/installerTool" component={InstallerTool} />}
                        {hasAnyGrant(Scope.PostInstall.READ) && <Route exact path="/installerTool/:postInstallId/checklist" component={InstallerCheckList} />}
                        {hasAnyGrant(Scope.PostInstall.READ) && <Route exact path="/repair/:repairId/checklist" component={RepairCheckList} />}

                        {hasAnyGrant(Scope.Sms.SEND_AS_SELF) && <Route path="/sms" component={SmsPage} />}

                        {/* Repairs TODO: ADD GRANTS */}
                        {hasAnyGrant(Scope.Installs.READ) && <Route exact path="/repairs" component={Repairs} />}

                        {/* UNKNOWN ROUTE */}
                        <Route path="*" component={NotFound} />
                    </Switch>
                </main>
            </motion.div>
        </AnimatePresence>
    );
}
